import * as React from "react";
import Popper from "@mui/material/Popper";
import {
    IconButton,
    ClickAwayListener,
    Grow,
    Paper,
    MenuList,
    FormControl,
    FormGroup,
    Stack,
    FormControlLabel,
    Checkbox,
    Typography
} from "@mui/material";
import leafIconInactive from "assets/images/icons/leafIconInactive.svg";
import leafIconActive from "assets/images/icons/leafIconActive.svg";
import Unchecked from 'assets/images/icons/unchecked.svg';
import Checked from 'assets/images/icons/lightCheckedBox.svg';

export default function DietaryOptions({
    options = [
        { label: "Vegetarian", value: "VEGETARIAN" },
        { label: "Vegan", value: "VEGAN" },
        { label: "Gluten-free", value: "GLUTEN-FREE" },
        { label: "Dairy-free", value: "DAIRY-FREE" },
        { label: "Spicy", value: "SPICY" }
    ],
    haslength = false,
    checked = () => { },
    onChange = () => { },
    disabled = false
}) {
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };

    const handleClose = (event) => {
        if (anchorEl.current && anchorEl.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    };

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? "spring-popper" : undefined;

    return (
        <div>
            <IconButton aria-describedby={id} type="button" onClick={handleClick} sx={{ p: 0 }}>
                <img src={haslength ? leafIconActive : leafIconInactive} alt="leafIcon" width="20px" height="20px" />
            </IconButton>
            <Popper elevation={0} transition id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: "1350" }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                        }}
                    >
                        <Paper
                            elevation={0}
                            style={{
                                zIndex: "100",
                                boxShadow: "0px 0px 10px rgba(148, 148, 148, 0.3)"
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <FormControl>
                                        <FormGroup sx={{ p: 1 }}>
                                            {options.map((obj, index) => (
                                                <Stack key={index} direction="row">
                                                    <FormControlLabel
                                                        disabled={disabled}
                                                        sx={{ height: 28 }}
                                                        checked={checked(obj)}
                                                        key={index}
                                                        control={<Checkbox disableRipple
                                                            icon={<img src={Unchecked} style={{ width: '18px', height: '18px' }} alt="checked" />}
                                                            checkedIcon={
                                                                <img
                                                                    src={Checked}
                                                                    alt="checkbox"
                                                                    style={{ width: '18px', height: '18px' }}
                                                                />
                                                            } />}
                                                        label={
                                                            <Typography
                                                                fontSize="15px"
                                                                variant={checked(obj) ? "checkBoxSelected" : "checkBoxUnSelected"}
                                                            >
                                                                {obj.label}
                                                            </Typography>
                                                        }
                                                        onChange={() => onChange(obj)}
                                                    />
                                                </Stack>
                                            ))}
                                        </FormGroup>
                                    </FormControl>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}
