import React from "react";
import CreateMarketForm from "./CreateMarketForm";

const Createmarket = (props) => {
    const {handleIsEdited, selectedRow, handleSelectedRow, sideBarStateName = false} = props;

    return (
        <>
            <CreateMarketForm
                sideBarStateName={sideBarStateName}
                handleIsEdited={handleIsEdited}
                selectedRow={selectedRow}
                handleSelectedRow={handleSelectedRow}
            />
        </>
    );
};

export default Createmarket;
