import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import CustomizedTabs from "commonComponent/NewTab";
import {useNavigate, useSearchParams} from "react-router-dom";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import useTempDrawer from "hooks/useTempDrawer";
import Deliveries from "./Tabs/Deliveries/Deliveries";
import Drivers from "./Tabs/Drivers/Drivers";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {useSelector, useDispatch} from "react-redux";
import {setShowFilter} from "store/slices/paginationSlice";

const Delivery = () => {
    const {toggleDrawer} = useTempDrawer();
    const [value, setValue] = useState("customer");
    const [heading, setHeading] = useState("Deliveries");
    const [btnlabel, setBtnLebel] = useState("");

    const [search, setSearch] = useState("");
    // const [showFilter, setShowFilter] = useState(false);
    const showFilter = useSelector((state) => state?.pagination?.showFilter);
    const dispatch = useDispatch();
    const [urlSearchParams] = useSearchParams();

    const navigate = useNavigate();
    let tab = urlSearchParams.get("tab");
    const OrderId = urlSearchParams.get("orderId");
    const marketId = urlSearchParams.get("marketId");

    const handleButtonLabelandFilter = (type) => {
        switch (type) {
            case "drivers":
                setBtnLebel("Add new driver");
                setHeading("Drivers");
                break;

            default:
                setBtnLebel("");
                setHeading("Deliveries");
                break;
        }
    };

    useEffect(() => {
        handleButtonLabelandFilter(tab);
        toggleDrawer("right", false)();
    }, [window.location.search]);

    useEffect(() => {
        // is user edits the url query then we need to redirect it to default tab
        if (tab == null || (tab !== "deliveries" && tab !== "drivers")) {
            setValue("deliveries");
            let search = `?tab=${"deliveries"}`;
            if (marketId) {
                search = `?tab=${"deliveries"}&marketId=${marketId}`;
            }
            if (OrderId) {
                search = `?tab=${"deliveries"}&orderId=${OrderId}`;
            }
            navigate({
                pathname: window.location.pathname,
                search: search
            });
        }
    }, []);

    const handleTabChange = (event, newValue) => {
        setSearch("");
        setValue(newValue);

        let search = `?tab=${newValue}`;
        if (OrderId) {
            search = `?tab=${newValue}&orderId=${OrderId}`;
        }
        navigate({
            pathname: window.location.pathname,
            search: search
        });
    };

    const handleSearch = (val) => {
        setSearch(val);
    };

    const handleHeaderActions = (btntype) => (e) => {
        switch (btntype) {
            case "Add new driver": {
                toggleDrawer("right", "createDriver")(e);
                // setShowFilter(false);
                dispatch(setShowFilter(false));
                break;
            }
            case "none": {
                dispatch(setShowFilter(!showFilter));

                // setShowFilter(true);
                toggleDrawer("right", true)(e);
                break;
            }
            default: {
                break;
            }
        }
    };

    const TabsData = [
        WithPermission({module: "Deliveries", permission: ["read_permission"]}) && {
            value: "deliveries",
            label: "Deliveries",
            comp: () => <Deliveries search={search} showFilter={showFilter} />
        },
        WithPermission({module: "Drivers", permission: ["read_permission"]}) && {
            value: "drivers",
            label: "Drivers",
            comp: () => <Drivers search={search} showFilter={showFilter} />
        }
    ];

    useEffect(() => {
        if (TabsData.length) {
            if (!TabsData[0]?.value) {
                for (let i = 0; i < TabsData.length; i += 1) {
                    if (TabsData[i] !== null) {
                        tab = TabsData[i].value;
                        console.log(TabsData.length, "mne tadatga");
                        break;
                    }
                }
            }
        }
        setValue(tab);
        let search = `?tab=${tab}`;
        if (marketId) {
            search = `?tab=${tab}&marketId=${marketId}`;
        }
        if (OrderId) {
            search = `?tab=${tab}&orderId=${OrderId}`;
        }
        navigate({
            pathname: window.location.pathname,
            search: search
        });
    }, []);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TableTopFilterWrapper
                        lessmarginOnTop
                        pageheader={<PageHeader title={heading} />}
                        handleClick={handleHeaderActions}
                        handleSearch={handleSearch}
                        filterType="none"
                        onlyHeading={btnlabel !== "Add new driver"}
                        btnTitle={WithPermission({module: "Drivers", permission: "create_permission"}) && btnlabel}
                        onlyBtn
                    >
                        <CustomizedTabs tabsInfo={TabsData} value={value} handleChange={handleTabChange} />
                    </TableTopFilterWrapper>
                </Grid>
            </Grid>
        </>
    );
};

export default Delivery;
