import {Grid, Typography} from "@mui/material";
import React from "react";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import CustomizedTabs from "commonComponent/NewTab";
import useUserDetailController from "./UserDetail.controller";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import {CsvBuilder} from "filefy";
import Fields from "commonComponent/Apptable/getFieldsForDownload";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import User from "./Tabs/User/User";

const UserDetail = () => {
    const CC = useUserDetailController();
    const prm = useParams();
    const dispatch = useDispatch();
    const customerId = prm?.type;
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const tabname = searchParams.get("tab");
    const edit = searchParams.get("edit");
    const customerName = searchParams.get("userName");
    const showSearchandFilter = ["user", "account"];
    const showButton = ["user", "account"];
    const breadScrumsData = [{label: "Setting", href: "/settings?tab=user"}];
    const labelData = [
        {
            name: "View User",
            editName: "Edit User",
            url: "user"
        },
        {
            name: "View Account",
            editName: "Edit Account",
            url: "account"
        }
    ];
    const currentLabel = labelData.find((item) => item.url === tabname);

    if (!edit) {
        // Add "View User" breadcrumb only if it's not in edit mode
        breadScrumsData.push({
            label: "View user",
            isActive: true
        });
    }

    // if (tabname==="profile") {
    //     breadScrumsData.push({label: labelData.name, isActive: true});
    // }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TableTopFilterWrapper
                        onlyHeading={!showSearchandFilter.includes(tabname)}
                        {...(edit !== "true" && {onlyBtn: showButton.includes(tabname)})}
                        {...(currentLabel?.url === "user" &&
                            edit === "true" && {
                                onlyHeading: true
                            })}
                        {...(currentLabel?.url === "account" &&
                            edit === "true" && {
                                onlyHeading: true
                            })}
                        lessmarginOnTop
                        pageheader={
                            <PageHeader
                                title={customerName}
                                {...(CC.value && {
                                    breadsCrumData: breadScrumsData
                                })}
                            />
                        }
                        btnTitle={CC.label}
                        handleClick={CC.handleCreateLocation}
                        handleSearch={CC.handleSearch}
                        filterType="none"
                        orderFilter="orderFilter"
                        selectedCheckbox={CC.selectedCheckbox}
                    >
                        <User />
                        {/* <CustomizedTabs tabsInfo={CC.dummyData} value={CC.value} handleChange={CC.handleChange} /> */}
                    </TableTopFilterWrapper>
                </Grid>
            </Grid>
        </>
    );
};

export default UserDetail;
