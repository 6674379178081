import {fontSize, useTheme} from "@mui/system";

export const useStyles = () => {
    const theme = useTheme();
    const typography = theme?.typography;
    return {
        textColor: {color: theme?.palette?.text?.main},
        tableBackground: {
            backgroundColor: theme.palette.background.tableHeader,
            zIndex: 10,
            // tableHeader: theme.palette.background.tableHeader
        },
        filterText: {
            fontWeight: theme?.typography?.s1?.fontWeight,
            fontSize: theme?.typography?.s1?.fontSize,
            lineHeight: theme?.typography?.s1?.lineHeight
        },
        filterType: {
            fontWeight: theme?.typography?.p1?.fontWeight,
            fontSize: theme?.typography?.p1?.fontSize,
            lineHeight: theme?.typography?.p1?.lineHeight
        },
        drawer: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-end"
        },

        PaperWrapper: {
            maxWidth: "100%",
            // paddingBottom:"65px"
        },
        searchStyle: {
            margin: "10px 10px 10px auto"
        },
        actionButton: {
            iconButton: {
                p: 0
            },
            popover: {
                zIndex: "1350"
            },
            grow: {
                top: "center top",
                bottom: "center bottom"
            },
            paper: {
                zIndex: "100",
                boxShadow: "0px 0px 10px rgba(148, 148, 148, 0.3)"
            }
        },
        categoryDisplay: {
            mainTypo: {
                fontWeight: typography?.fontWeightRegular,
                fontSize: typography?.subMenuCaption?.fontSize
            },
            small: {
                cursor: "pointer",
                fontWeight: typography?.fontWeightMedium,
                fontSize: typography?.subMenuCaption?.fontSize,
                color: "#00ab3a"
            }
        },
        confirmDialog: {
            paper: {
                borderRadius: 16
            },
            dialog: {
                padding: 0
            },
            button: {
                marginRight: 20
            }
        }
    };
};
