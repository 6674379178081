import {Box, Grid, Typography} from "@mui/material";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {ErrorMessage} from "formik";
import ImageCropper from "commonComponent/ImageCropper/imageCropper";
import React from "react";

const PhotoSection = (props) => {
    const {formik, isEdit = true} = props;
    return (
        <>
            <Grid container>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <Box>
                            <Typography variant="headingOne">Photos</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant="checkBoxSelected" fontSize="12px">
                            Menu item thumbnail(plated photo)
                        </Typography>
                        <Box sx={{mt: 2, borderRadius: "4px", border: "1px solid #E0E2E7", padding: "1rem"}}>
                            <ImageCropper
                                isEdit={isEdit}
                                title={formik.values.itemThumbnail.length ? "Edit photo" : "Add a photo"}
                                multiPhoto={false}
                                image={formik.values.itemThumbnail}
                                handleChange={(list) => {
                                    formik.setFieldValue(`itemThumbnail`, list);
                                }}
                                handleError={(msg = "Minimum 155KB file size is required") => {
                                    formik.setTouched({itemThumbnail: true});
                                    setTimeout(() => formik.setErrors({itemThumbnail: msg}), 100);
                                }}
                            />
                        </Box>
                        <ErrorMessage name="itemThumbnail" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant="checkBoxSelected" fontSize="12px">
                            Item personalization photo
                        </Typography>
                        <Box sx={{mt: 2, borderRadius: "4px", border: "1px solid #E0E2E7", padding: "1rem"}}>
                            <ImageCropper
                                isEdit={isEdit}
                                title="Add a photo"
                                multiPhoto
                                image={formik.values.itemPersonalizationPhoto}
                                handleChange={(list) => {
                                    formik.setFieldValue(`itemPersonalizationPhoto`, list);
                                }}
                                handleError={(msg = "Minimum 155KB file size is required") => {
                                    formik.setTouched({itemPersonalizationPhoto: true});
                                    setTimeout(
                                        () =>
                                            formik.setErrors({
                                                itemPersonalizationPhoto: msg
                                            }),
                                        100
                                    );
                                }}
                            />
                        </Box>
                        <ErrorMessage name="itemPersonalizationPhoto" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PhotoSection;
