import React, {useEffect, useState} from "react";
import PopUp from "commonComponent/Popup/Popup";

function ReviewPublish({
    selectedMenus,
    setSelectedMenus,
    updatePopup,
    setUpdatePopup,
    onSave,
    handleClose,
    selectedCheckboxes,
    setSelectedCheckboxes,
    menuList,
    selectedRow
}) {
    const [initialCheckboxes, setInitialCheckboxes] = useState([]);
    const checkboxLabels = ["Menu page", "Home page", "Rate and review page"];
    const [selectedCheckBox, setSelectedCheckBox] = useState(null);

    // const handleUpdatePopup = () => {
    //     alert(updatePopup)
    //     setUpdatePopup(false);
    //     // setSelectedCheckboxes(initialCheckboxes); // Revert changes on popup close
    // };

    // const handleGetReviewData = async (row, type) => {
    //     instance = NetworkManager(API.CUSTOMER.REVIEWUNPUBLISH(row.order_review_id));

    //     const response = await instance.request(payload);
    // };
    const handleUpdateTableWare = async () => {
        const sortedCheckboxes = checkboxLabels.filter((label) => selectedCheckboxes.includes(label));
        onSave(sortedCheckboxes);
        setInitialCheckboxes(sortedCheckboxes); // Save the updated state
        setUpdatePopup(false); // Close the popup
    };

    const handleCheckboxChange = (checkboxId) => {
        setSelectedCheckboxes((prevSelected) => {
            if (prevSelected.includes(checkboxId)) {
                // If checkboxId is already in the array, filter it out
                return prevSelected.filter((id) => id !== checkboxId);
            } else {
                // If checkboxId is not in the array, add it
                return [...prevSelected, checkboxId];
            }
        });
    };

    return (
        <div>
            <PopUp
                open={updatePopup}
                title="Publish Review"
                description="Choose where to publish review"
                handleClose={handleClose}
                handleSubmit={updatePopup}
                handleCheckboxChange={handleCheckboxChange}
                selectedCheckboxes={selectedCheckboxes}
                checkboxLabels={checkboxLabels}
                reviewPopup
                selectedMenus={selectedMenus}
                setSelectedMenus={setSelectedMenus}
                menuList={menuList}
            />
        </div>
    );
}

export default ReviewPublish;
