import React, {useEffect, useState} from "react";

const defaultValues = {
    orderId: "",
    itemDetail: [],
    headerData: {
        customer: {
            name: "",
            email: "",
            phone: "",
            company: "",
            companyId: "",
            userId: null,
            customerAddresses: []
        },
        service: "Catering",
        orderType: "Delivery",
        deliveryTo: "",
        latitude: "",
        longitude: "",
        address_details: {},
        when: "",
        forHowMany: "",
        preferredDiscountType: "DISCOUNT",
        preferredDiscountValue: 0
    },
    selectedBrand: "",
    brandName: "",
    selectedMenu: "",
    kitchenLocationId: "",
    selectedKitchen: "",
    kitchenLatitude: "",
    kitchenLongitude: "",
    kitchenMinimumServiceFee: 0,
    kitchenList: [],
    categories: {},
    items: [],
    specialInstruction: "",
    kitchenNote: "",
    deliveryInstruction: "",
    drive_time: "",
    setup_time: "",
    leave_by: null,
    isTaxExempts: false,
    discountOrFee: {
        type: "Percentage",
        priceType: "Discount",
        value: 0,
        description: ""
    },
    serviceFees: {
        unitType: "percent",
        value: 0
    },
    salesTax: {value: 0},
    itemModifier: {},
    discountedPrice: 0,
    serviceTaxTotal: 0,
    orderPriceForAllItem: 0,
    salesTaxTotalPrice: 0,
    totalPriceAfterCalculation: 0,
    perPersonOrderItem: 0,
    perPersonTotal: 0,
    clickOnSaveDraft: false,
    isSaved: false,
    is_payment_initiated: false,
    paid_already: 0,
    orderStatus: "",
    chargedByCardOrHouse: "",
    customerCardId: "",
    orderHistory: [],
    tableware: true,
    selectedTableWare: ["Plates", "Cutlery with napkins", "Serving utensils"],
    tableware_plates: true,
    tableware_cutlery: true,
    serving_utensils: true,
    openTableWarePopup: false,
    marketIDForDiscount: "",
    discount: "",
    marketNameForDiscount: "",
    location_distance: "",
    discount_applied_coupon_id: "",
    advance_deposit_percent: "",
    advance_deposit_amount: null,
    is_advance_deposit_enabled: false,
    name: "",
    phone: "",
    company: "",
    addressChanged: false,
    serviceFeeAdded: false,
};

const useCreateOrderController = () => {
    const [initialValues, setInitialVlaues] = useState(defaultValues);

    useEffect(() => {
        setInitialVlaues(defaultValues);
    }, []);

    const handleInitialValues = (values) => {
        setInitialVlaues(values);
    };

    return {initialValues, handleInitialValues, defaultValues};
};

export default useCreateOrderController;
