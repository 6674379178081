import React, {useState} from "react";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import DateRangeIcon from "@mui/icons-material/DateRange";
import {Box, IconButton, Typography} from "@mui/material";
import {useStyles} from "./useStyles";
import dayjs from 'dayjs';

const FilterDatePicker = (props) => {
    const {valueBasic, setValueBasic, label,minDate=null, ...restProps} = props;
    const [open, setOpen] = useState(false);
    const style = useStyles(open);
    const openPicker = () => {
        setOpen((prev) => !prev);
    };
    
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="custom-date-picker">
                <DesktopDatePicker
                    {...restProps}
                    format="dd/mm/yyyy"
                    displayStaticWrapperAs="desktop"
                    minDate={minDate}
                    open={open}
                    onClose={openPicker}
                    PaperProps={{
                        sx: style?.paperProps
                    }}
                    renderInput={({inputRef, InputProps}) => (
                        <Box sx={style?.datePicker} onClick={openPicker}>
                            <Typography sx={style.datePickerText}>{label}</Typography>
                            <IconButton ref={inputRef} {...InputProps} onClick={openPicker}>
                                <DateRangeIcon color="secondary" onClick={openPicker} />
                            </IconButton>
                        </Box>
                    )}
                    value={dayjs(valueBasic)}
                    onChange={(newValue) => {
                        setValueBasic(newValue);
                    }}
                />
            </div>
        </LocalizationProvider>
    );
};

export default FilterDatePicker;
