import {
    Dialog,
    DialogActions,
    Grid,
    FormControlLabel,
    Checkbox,
    DialogContent,
    Stack,
    DialogContentText,
    DialogTitle,
    Slide,
    Radio,
    RadioGroup,
    Button,
    InputAdornment,
    Typography
} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import Cancel from "../../assets/images/icons/cancel.svg";
import React from "react";
import { Box } from "@mui/system";
import AnimateButton from "ui-component/extended/AnimateButton";
import styles from "./Driver.module.scss";
import RadioIcon from "assets/images/icons/Bg-Radio.svg";
import CheckedIcon from "assets/images/icons/checkedIconRadio.svg";
import { styled } from "@mui/material/styles";
import { Form, Formik, ErrorMessage } from "formik";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import { CustomMenuItemValidation } from "utils/validations/validations";
import { enforceFormatHandleDecimal } from "utils/helperFunctions/helpers";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import { dispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import { NetworkManager, API } from "network/core";


const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const BpCheckedIcon = ({ checked }) => (
    <svg width="18px" height="18px" viewBox="0 0 24 24" fontSize="36px">
        <circle cx="50%" cy="50%" r="11px" stroke={`${checked ? "#00ab3a" : "#A0A7B9"}`} fill="none" />
        {checked && <circle cx="50%" cy="50%" r="6px" stroke="#00ab3a" fill="#00ab3a" />}
    </svg>
);


const defaultInitialState = {
    // item Details
    name: "",
    sku: "",
    price: "",
    description: "",
};


const CustomMenuItem = (props) => {
    const {
        title,
        open,
        handleClose,
        hideBackdrop = false,
        buttonTitle = "Delete",
        noOnClose = false,
        handleCustomMenuData
    } = props;
    // const [selected, setSelected] = React.useState("customer_order");

    const handleAddItem = async (values, setSubmitting) => {

        setSubmitting(true);

        const instance = NetworkManager(API.ITEM.ADD);


        const payload = {
            name: values.name,
            price: values.price,
            sku: values.sku,
            description: values.description,
            "item_type":0,
        };


        const response = await instance.request(payload);

        if (response.success) {

            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    transition: "Fade",
                    close: true
                })
            );
            handleCustomMenuData(response)
            handleClose()

        } else {

            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }

        setSubmitting(false);

    };


    return (
        <Dialog
            open={open}
            {...(!noOnClose && { onClose: handleClose })}
            // TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            hideBackdrop={hideBackdrop}
            PaperProps={{ sx: { boxShadow: "0px 0px 8px rgba(0,0,0,0.2)", padding: "33px 23px 0px 23px", width: "421px", position: "relative" } }}
        >
            <Box sx={{ position: "absolute", right: "16px", top: "27px", cursor: "pointer" }} onClick={handleClose}>
                <img src={Cancel} alt="plus-icon" width="12px" height="12px" />
            </Box>
            <DialogTitle
                id="alert-dialog-title"
                className={styles.title1}
               
            >
                {title}
            </DialogTitle>
            <DialogContent sx={{ marginTop: "-15px", padding: "5px 5px" }}>

                <Stack sx={{ marginTop: "15px" }}>
                    <Grid item xs={12}>
                        <Formik
                            enableReinitialize
                            initialValues={defaultInitialState}
                            validationSchema={CustomMenuItemValidation}
                            onSubmit={(val, { setSubmitting }) => handleAddItem(val, setSubmitting)}
                        >
                            {(formik) => (
                                <Form>

                                    <Grid container spacing={3} item xs={12} >

                                        <Grid item xs={12}>
                                            <CustomTextField

                                                fullWidth
                                                label="Item name"
                                                inputProps={{ maxLength: 250 }}
                                                {...formik.getFieldProps("name")}
                                            />
                                            <ErrorMessage name="name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />

                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <CustomTextField
                                                fullWidth
                                                label="Price"
                                                onKeyDown={enforceFormatHandleDecimal}
                                                {...formik.getFieldProps("price")}
                                                inputProps={{
                                                    maxLength: 7
                                                }}
                                              
                                                sx={{
                                                    "&": {
                                                        "& .MuiOutlinedInput-root": {
                                  
                                                            "& .MuiOutlinedInput-input": {
                                                                paddingLeft:'4px !important'
                                                            }
                                                        },
                                                }
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start" sx={{ color: "#212121" }}>
                                                            $
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                            <ErrorMessage name="price" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <CustomTextField

                                                fullWidth
                                                label="SKU"
                                                inputProps={{ maxLength: 250 }}
                                                {...formik.getFieldProps("sku")}
                                            />
                                            <ErrorMessage name="sku" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField

                                                multiline
                                                minRows={2.5}
                                                sx={{ "&": { height: "fit-content !important" } }}
                                                fullWidth
                                                label="Description (250 characters max)"
                                                inputProps={{ maxLength: 250 }}
                                                {...formik.getFieldProps("description")}
                                            />
                                            <ErrorMessage name="description" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>

                                    </Grid>
                                    {/* <Grid
                                        item
                                        xs={12}
                                        sx={{ mt: 2, pb: 2, pt: 1, ml: '-2px', width: "100%", position: "sticky", bottom: 0, bgcolor: "#fff", zIndex: 1 }}
                                    >
                                        <ButtonGrouping
                                            btnprops={[
                                                {
                                                    btnTitle: "Cancel",
                                                    sx: (t) => ({ color: t.palette.error.dark }),
                                                    onClick: (e) => {
                                                        
                                                    }
                                                },
                                                {
                                                    btnTitle: "Save",
                                                    variant: "contained",
                                                    disabled: formik.isSubmitting,
                                                    sx: (t) => ({ color: t.palette.background.paper }),
                                                    onClick: formik.handleSubmit
                                                }
                                            ]}
                                        />
                                    </Grid> */}
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ mt: 7, pb: 2, pt: 1, ml: '-2px', width: "100%", position: "sticky", bottom: 0, bgcolor: "#fff", zIndex: 1 }}
                                    >
                                        <ButtonGrouping
                                        sx={{justifyContent:'space-between'}}
                                            btnprops={[
                                                {
                                                    btnTitle: "Cancel",
                                                    variant: "outlined",
                                                    onClick: handleClose,
                                                    sx: (t) => ({
                                                        color: "#00AB3A",
                                                        border: "1.5px solid #00AB3A",
                                                        height: "35px",
                                                        width: "178px",
                                                        fontSize: "0.875rem",
                                                        fontWeight: 500,
                                                       
                                                    })
                                                },
                                                {
                                                    btnTitle: "Submit",
                                                    variant: "contained",
                                                    onClick: formik.handleSubmit,
                                                    sx: (t) => ({
                                                        sx: (t) => ({ color: t.palette.background.paper }),
                                                        border: "1.5px solid #00AB3A",
                                                        height: "35px",
                                                        width: "178px",
                                                        fontSize: "0.875rem",
                                                        fontWeight: 500,
                                                        
                                                    })
                                                }
                                            ]}
                                        />
                                    </Grid>

                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default CustomMenuItem;
