import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import _debounce from "lodash/debounce";
import CustomTextField from "./CustomTextField";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function CustomSearchableSelect(props) {
    const {
        label,
        menuItems = [],
        multiple,
        mustAddCssClass,
        handleChange,
        handleInputChange,
        freeSolo,
        height = "15px",
        ...restprops
    } = props;
    const [value, setValue] = useState("");
    const [open, setOpen] = useState(false);
    const [array, setArray] = useState([]);
    const changeInput = _debounce((value) => {
        handleInputChange(value);
    }, 800);
    const selectAllObject = {
        label: "Select All",
        id: "selectAll"
    };
    useEffect(() => {
        if (!multiple) {
            setArray(menuItems);
        } else {
            setArray([selectAllObject, ...menuItems]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuItems]);
    return (
        <Box className={mustAddCssClass}>
            <FormControl fullWidth>
                <Autocomplete
                    {...restprops}
                    id={label}
                    open={open}
                    onClose={() => (!multiple ? setOpen(false) : null)}
                    onBlur={() => setOpen(false)}
                    freeSolo={freeSolo ?? false}
                    multiple={multiple}
                    options={array}
                    value={multiple ? [] : value}
                    onChange={(event, newValue) => {
                        if (event?.type === "change") {
                            setValue("");
                            setOpen(true);
                        }
                        if (event?.type === "click") {
                            handleChange(newValue);
                            setValue("");
                            setOpen(true);
                        }
                        if (event?.type === "keydown" && event?.which === 13) {
                            handleChange(newValue);
                            setValue("");
                            setOpen(true);
                        }
                    }}
                    onInputChange={(event, newValue) => {
                        if (event && event?.type === "change") {
                            changeInput(newValue);
                            setValue(newValue);
                            setOpen(true);
                        }
                    }}
                    popupIcon={
                        !freeSolo && (
                            <KeyboardArrowDownIcon
                                color="secondary"
                                onClick={() => {
                                    setOpen(true);
                                }}
                            />
                        )
                    }
                    placeholder={label}
                    disableClearable
                    inputValue={value}
                    clearOnBlur
                    renderInput={(params) => <CustomTextField {...params} placeholder={label} height={height} />}
                />
            </FormControl>
        </Box>
    );
}

CustomSearchableSelect.propTypes = {
    label: PropTypes.string,
    menuItems: PropTypes.array,
    multiple: PropTypes.bool,
    mustAddCssClass: PropTypes.string,
    handleChange: PropTypes.func,
    handleInputChange: PropTypes.func,
    freeSolo: PropTypes.bool,
    height: PropTypes.string
};
