import React from "react";
import {Grid, Pagination, TablePagination} from "@mui/material";
import AppTables from "commonComponent/Apptable";

const CommonTable = (props) => {
    const {
        withCheckbox,
        path,
        id,
        searchParam,
        data,
        actions,
        handleActionBtn,
        handleHeaderActions = null,
        header = [],
        keys = [],
        onRowsPerPageChange,
        onPageChange,
        handleSort,
        totalItems,
        rowsPerPage,
        currentPage,
        sortingLabel,
        loading = false,
        checkbox,
        setSelectedCheckbox = [],
        selectedCheckbox = [],
        notCaptilize,
        noPagination = false,
        height,
        multipleQuote = null,
        headerShow = true,
        leftSpace = true,
        currentTab = "",
        tableCellWidth = null,
        tab,
        handleDateChange,
        isReport = false,
        singleQuote = null,
        expiryQuote,
        handleMultiQuoteToggleState,
        handleMutiQuoteTogglePercentage
    } = props;
    return (
        <>
            <AppTables
                withCheckbox={withCheckbox}
                leftSpace={leftSpace}
                setSelectedCheckbox={setSelectedCheckbox}
                selectedCheckbox={selectedCheckbox}
                tableCellWidth={tableCellWidth}
                path={path}
                checkbox
                id={id}
                searchParam={searchParam}
                loading={loading}
                headers={header}
                notCaptilize
                keys={keys}
                data={data}
                height={height}
                currentTab={currentTab}
                rowsPerPageOptions={[25, 50, 100]}
                ReadOnly={false}
                ActionArray={actions}
                handleActionBtn={handleActionBtn}
                handleSort={handleSort}
                sortingLabel={sortingLabel}
                handleHeaderActions={handleHeaderActions}
                multipleQuote={multipleQuote}
                headerShow={headerShow}
                tab={tab}
                handleDateChange={handleDateChange}
                singleQuote={singleQuote}
                isReport={isReport}
                handleMultiQuoteToggleState={handleMultiQuoteToggleState}
                handleMutiQuoteTogglePercentage={handleMutiQuoteTogglePercentage}
            />
            {expiryQuote ? null : !noPagination ? (
                <Grid container justifyContent="flex-start" alignItems="center" sx={{position: "fixed", bottom: 0, bgcolor: "#fff"}}>
                    <Grid item>
                        <Pagination
                            shape="rounded"
                            disabled={loading}
                            page={currentPage ? currentPage + 1 : 1}
                            count={Math.ceil(totalItems / rowsPerPage)}
                            onChange={(val, number) => onPageChange(val, number - 1)}
                            color="primary"
                            style={{
                                cursor: loading ? "not-allowed" : "default"
                            }}
                            id="pagination"
                            showFirstButton
                            showLastButton
                            size="small"
                            sx={{
                                "&": {
                                    "& .MuiPagination-ul > li": {
                                        "& .MuiPaginationItem-root": {
                                            color: "#757575",
                                            fontWeight: 400
                                        },
                                        "& .MuiPaginationItem-root.Mui-selected": {
                                            color: "#616161 !important",
                                            backgroundColor: "#EFF0F3",
                                            fontWeight: 600
                                        }
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={totalItems}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            onPageChange={(val, number) => onPageChange(val, number)}
                            onRowsPerPageChange={(val) => onRowsPerPageChange(val)}
                            nextIconButtonProps={{style: {display: "none"}}}
                            backIconButtonProps={{style: {display: "none"}}}
                            sx={{
                                "& .MuiToolbar-root": {
                                    minHeight: "48px",
                                    maxHeight: "48px",
                                    "& .MuiTablePagination-selectLabel": {
                                        fontSize: "12px",
                                        color: "#616161"
                                    },
                                    "& .MuiTablePagination-displayedRows": {
                                        fontSize: "12px",
                                        color: "#616161",
                                        display: "none"
                                    },
                                    "& .MuiInputBase-root": {
                                        marginRight: 0,
                                        ".MuiInputBase-input": {
                                            fontSize: "14px",
                                            color: "#616161",
                                            fontWeight: 400
                                        }
                                    },
                                    "& .MuiTablePagination-actions": {
                                        marginLeft: 0
                                    }
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            ) : null}
        </>
    );
};

export default CommonTable;
