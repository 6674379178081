import {Box, Menu, Stack, Typography} from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomRadioButton from "commonComponent/Form/Fields/CustomRadioButton";
import { ErrorMessage} from "formik";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";

const Service = (props) => {
    const {Parentformik} = props;
    const {headerData} = Parentformik.values;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Stack
                nowrap
                sx={{minWidth: 0, cursor: "pointer", flexShrink: 20}}
                spacing={0.5}
                id="basic-button"
                className="muiStack_start"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <Typography variant="OrderSectionHeading" sx={{whiteSpace: "nowrap"}}>
                    Service
                </Typography>

                <Stack direction="row" spacing={1} mt='0px !important' alignItems="center">
                    <Typography
                        noWrap
                        variant="OrderSectionHeading"
                        fontWeight={400}
                        {...(!headerData?.service && {sx: (t) => ({color: t.palette.error.light})})}
                    >
                        {headerData?.service || "Add service"}
                    </Typography>
                    <KeyboardArrowDownIcon fontSize="small" />
                </Stack>
                <ErrorMessage
                    name="headerData.service"
                    render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                />
            </Stack>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
            >
                <Box px={2}>
                    <CustomRadioButton
                        options={[
                            {label: "Catering", value: "Catering"},
                            {label: "Home meals", value: "Home Meals"}
                            
                        ]}
                        value={headerData.service}
                        onChange={(e) => {
                            Parentformik.setFieldValue("headerData.service", e.target.value);
                            handleClose();
                        }}
                    />
                </Box>
            </Menu>
        </>
    );
};

export default Service;
