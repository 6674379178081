import { Box, Menu, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SymbolButton from "commonComponent/Buttons/SymbolButton";
import CustomerForm from "./CustomerForm";
import AddNewCustomerForm from "./AddNewCustomerForm";
import { ErrorMessage, Formik } from "formik";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import networkManager from "network/core/networkManager";
import { API } from "network/core";
import { convertToLocal, convertToUTC } from "utils/app-dates/dates";
import { ellipsizeText } from "commonComponent/Apptable/textUtils";
import WithPermission from "commonComponent/RBAC/permissionHoc";

const Customer = (props) => {
    const { Parentformik, customerName, customerId, editScreen } = props;
    const { headerData, orderStatus } = Parentformik.values;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openForm, setOpenForm] = useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        if (openForm) {
            setOpenForm(false);
        }
        setAnchorEl(null);
    };

    return (
        <>
            <Stack
                nowrap
                sx={{ minWidth: 0, ml: { xs: 2, md: 2, lg: 0 }, cursor: "pointer", flexShrink: 20 }}
                spacing={0.5}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                className="muiStack_start"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <Typography variant="OrderSectionHeading" sx={{ whiteSpace: "nowrap" }}>
                    Template
                </Typography>

                <Stack direction="row"  spacing={1} mt='0px !important' alignItems="center">
                    <Typography
                        noWrap
                        variant="OrderSectionHeading"
                        fontWeight={400}
                    // {...(!headerData.customer?.name && {sx: (t) => ({color: t.palette.error.light})})}
                    >
                        {ellipsizeText(headerData.template?.name || "Add template")}
                    </Typography>
                    <KeyboardArrowDownIcon fontSize="small" />
                </Stack>
                <ErrorMessage name="headerData.template.name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
            </Stack>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                onKeyDown={(e) => e.stopPropagation()}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}

                PaperProps={{
                    sx: {
                        '&.MuiPaper-root': {
                            overflowX: 'visible',
                            overflowY: 'visible'
                        }
                    }
                }}
            >
                {openForm ? (
                    <AddNewCustomerForm handleClose={handleClose} Parentformik={Parentformik} />
                ) : (
                    <CustomerForm Parentformik={Parentformik} handleClose={handleClose} customerName={customerName} customerId={customerId}>
                        {WithPermission({ module: "Customers", permission: ["create_permission"] }) && (
                            <Box onClick={() => setOpenForm(!openForm)} sx={{ maxWidth: "170px" }}>
                                <SymbolButton title="Add new template" />
                            </Box>
                        )}
                    </CustomerForm>
                )}
            </Menu>
        </>
    );
};

export default Customer;
