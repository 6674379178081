import useTempDrawer from "hooks/useTempDrawerModifier";
import {API, NetworkManager} from "network/core";
import {useEffect, useState} from "react";
import {dispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";

const defaultState = {
    newlyAdded: true,
    modifierGroupId: "",
    modifierGroupName: "",
    displayName: "",
    modifierOptions: [],
    requireSelection: true,
    allowMultiple: true,
    allowQuantityModification: false,
    minimum: "",
    maximum: "",
    modifierDescription: "",
    categoryId: "",
    split_modifiers: false
};

const useAddNewModifierGroupController = (props) => {
    const {
        handleIsEdited,
        selectedRow,
        handleSelectedRow,
        fromItemFlow = false,
        itemModifierIndex,
        itemMoidiferarrayHelper = () => {},
        getModifiersGroupList = () => {}
    } = props;

    const [initialState, setinitialState] = useState(defaultState);
    const [loading, setLoading] = useState(false);

    const {toggleDrawer} = useTempDrawer();

    const handleCreateNewModifierGroup = async (val, setSubmitting) => {
        setLoading(true);
        setSubmitting(true);
        const instance = NetworkManager(API.MODIFIERGROUP.ADD);
        const payload = {
            modifier_group_name: val.modifierGroupName,
            display_name: val.displayName,
            modifier_group_description: val?.modifierDescription,
            category_id: val?.categoryId
        };
        const response = await instance.request(payload);

        if (response.success) {
            const MG = response.data;

            const data = {
                newlyAdded: true,
                modifierGroupId: MG?.modifier_group_id,
                modifierGroupName: MG?.modifier_group_name,
                displayName: MG?.display_name,
                modifierOptions: [],
                requireSelection: true,
                allowMultiple: true,
                allowQuantityModification: false,
                minimum: "",
                maximum: "",
                modifierDescription: MG?.modifier_group_description,
                categoryId: MG?.category_id,
                split_modifiers: false
            };
            setinitialState(data);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setLoading(false);
        setSubmitting(false);
    };

    const handleModifierGroupUpdate = async (val, setSubmitting) => {
        const instance = NetworkManager(API.MODIFIERGROUP.UPDATE(val.modifierGroupId));
        const payload = {
            modifier_group_name: val.modifierGroupName,
            display_name: val.displayName,
            is_required: val.requireSelection,
            is_allow_multiple: val.allowMultiple,
            minimum_selections: val.minimum ? Number(val.minimum) : 0,
            maximum_selections: Number(val.maximum) === 0 ? 999 : val.maximum,
            allow_quantity_modification: val.allowQuantityModification,
            modifier_group_description: val?.modifierDescription,
            category_id: val?.categoryId,
            split_modifiers: val?.split_modifiers
        };

        const response = await instance.request(payload);

        if (response.success) {
            if (fromItemFlow) {
                const MD = response.data;
                const dataToReplace = {
                    label: MD?.modifier_group_name,
                    id: MD?.modifier_group_id,
                    modifier_group_id: MD?.modifier_group_id,
                    modifier_group_name: MD?.modifier_group_name,
                    createdAt: MD?.createdAt,
                    updatedAt: MD?.updatedAt,
                    modifierGroupModifier: MD?.modifierGroupModifier?.map((el) => ({
                        modifier_id: el?.modifier_id,
                        modifier: {
                            modifier_id: el?.modifier_id,
                            modifier_name: el?.modifier?.modifier_name,
                            price: el?.modifier?.price,
                            series_no: el?.modifier?.series_no
                        }
                    })),
                    modifierDescription: MD?.modifier_group_description,
                    categoryId: MD?.category_id
                };
                itemMoidiferarrayHelper.replace(itemModifierIndex, dataToReplace);
                getModifiersGroupList();
                toggleDrawer("right", true)();
            } else {
                toggleDrawer("right", false)();
            }

            handleIsEdited();
            handleSelectedRow(null);
            setinitialState(defaultState);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setLoading(false);
        setSubmitting(false);
    };

    const getExistingModifierGroupData = async () => {
        setLoading(true);
        const instance = NetworkManager(API.MODIFIERGROUP.GETMODIFIERDATABYID);

        const response = await instance.request({}, [`?sortBy=createdAt&orderBy=asc&modifier_group_id=${selectedRow?.modifier_group_id}`]);

        if (response.success) {
            const data = response.data.modifierList?.[0];
            const setData = {
                newlyAdded: false,
                modifierGroupId: data.modifier_group_id,
                modifierGroupName: data.modifier_group_name,
                displayName: data.display_name,
                modifierOptions: data.modifierGroupModifier
                    ?.sort((a, b) => a?.modifier?.series_no - b?.modifier?.series_no)
                    ?.map((md) => ({
                        isEdit: false,
                        id: md?.modifier_id,
                        name: md?.modifier?.modifier_name,
                        price: md?.modifier?.price,
                        series_no: md?.modifier?.series_no,
                        dietary: md?.modifier?.ModifierDietary.map((el) => el.modifier_dietary).filter((el) => el),
                        minimum_value: md?.modifier?.minimum_value || 0
                    })),
                requireSelection: data.is_required,
                allowMultiple: data.is_allow_multiple,
                allowQuantityModification: data.allow_quantity_modification,
                maximum: Number(data.maximum_selections) === 999 ? 0 : data.maximum_selections,
                modifierDescription: data?.modifier_group_description,
                categoryId: data?.category_id,
                split_modifiers: data?.split_modifiers
            };
            if (data.minimum_selections !== 0) {
                setData.minimum = data.minimum_selections;
            }
            setinitialState(setData);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setLoading(false);
    };

    useEffect(() => {
        if (selectedRow?.modifier_group_id) {
            getExistingModifierGroupData();
        } else {
            setinitialState(defaultState);
        }
    }, [selectedRow]);

    return {loading, initialState, setinitialState, defaultState, handleCreateNewModifierGroup, handleModifierGroupUpdate};
};

export default useAddNewModifierGroupController;
