import {useState} from "react";
import {useMenuModel} from "./menus.model";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

export const useMenuController = () => {
    const model = useMenuModel();
    const param = useParams();
    console.log(param, 999);
    const [brandList, setBrandList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [templateFilterList, setTemplateFilterList] = useState([]);
    const [menuList, setMenuList] = useState([]);
    const [cuisines, setCuisines] = useState([]);
    const filterChips = useSelector((state) => state?.pagination?.filterChips);

    const staticParams = {
        page: 1,
        pageSize: 1000,
        sortBy: "createdAt",
        orderBy: "desc"
    };

    const getBrandList = async () => {
        const response = await model.getBrandList(staticParams);
        let tempData = [];
        if (response?.success) {
            const BrandData = response.data.brandList
                ?.sort((a, b) => a?.brand_name?.localeCompare(b?.brand_name))
                ?.map((el) => ({id: `${el.brand_id}`, label: el.brand_name}));
            tempData = [...BrandData];
        }
        setBrandList(tempData);
    };

    const getCategoryList = async () => {
        const response = await model.getCategoryList(staticParams);
        let tempData = [];
        if (response?.success) {
            const BrandData = response.data.categoryList?.rows
                ?.sort((a, b) => a?.category_name?.localeCompare(b?.category_name))
                ?.map((el) => ({id: `${el.category_id}`, label: el.category_name}));
            tempData = [...BrandData];
        }
        setCategoryList(tempData);
    };

    const getTemplateList = async () => {
        let tempData = [];
        const response = await model.getTemplateFilterList(staticParams);
        if (response.success) {
            const BrandData = response.data.orderTemplate
                ?.sort((a, b) => a?.order_template_name?.localeCompare(b?.order_template_name))
                ?.map((el) => ({id: `${el.order_template_id}`, label: el.order_template_name}));
            tempData = [...BrandData];
        }
        setTemplateFilterList(tempData);
    };

    const getMenuList = async () => {
        let tempData = [];
        const response = await model.getMenuList(staticParams);
        if (response.success) {
            if (response.success) {
                const BrandData = response.data.menuList
                    ?.sort((a, b) => a?.menu?.menu_name?.localeCompare(b?.menu?.menu_name))
                    ?.map((el) => ({id: `${el.menu.menu_id}`, label: el.menu.menu_name}));
                tempData = [...BrandData];
            }
        }
        setMenuList(tempData);
    };

    const getCuisine = async () => {
        let tempData = [];
        const response = await model.getCuisine();

        if (response.success) {
            const cuisineData = response.data.brandList.map((el) => ({id: `${el.cusine}`, label: el.cusine}));

            tempData = [...new Set(cuisineData.map(JSON.stringify))].map(JSON.parse);
        }
        return setCuisines(tempData);
    };

    const getColumnData = (tab) => {
        switch (tab) {
            case "categories":
                return [
                    {key: "category_name", label: "Name"},
                    {key: "menu_type", label: "Type"},
                    {key: "description", label: "Description"}
                ];

            case "templates":
                return [
                    {key: "template_name", label: "Template"},
                    {key: "templateBrandName", label: "Brand"},
                    {key: "templateMenuName", label: "Menu"},
                    {key: "templateService", label: "Service"},
                    {key: "guest", label: "Guest"},
                    {key: "createdBy", label: "Created by"},
                    {key: "createdAt", label: "Created"}
                ];
            default:
                break;
        }
        return [];
    };

    const getFilterState = (tab) => {
        switch (tab) {
            case "brand":
                return [
                    // {
                    //     key: "brand_name",
                    //     label: "Brand",
                    //     type: "textfield"
                    // },
                    {
                        key: "cusine",
                        label: "Cuisine",
                        type: "multiselect",
                        getData: cuisines
                    },
                    {
                        key: "owner",
                        label: "Owner",
                        type: "textfield"
                    },
                    {
                        key: "services",
                        label: "Service",
                        type: "multiselect",
                        getData: () => [
                            {id: "CATERING", label: "Catering"},
                            {id: "HOME_MEALS", label: "Home meals"}
                        ]
                    },
                    {
                        key: "start_date",
                        label: "Created",
                        type: "createdAt"
                    },
                    // {
                    //     key: "start_date",
                    //     label: "Created",
                    //     type: "startDate"
                    // },
                    {
                        key: "is_active",
                        label: "Status",
                        type: "select",
                        getData: () => [
                            {id: "active", label: "Active"},
                            {id: "inactive", label: "Inactive"}
                        ]
                    }
                ];
            case "menu":
                return [
                    // {
                    //     key: "menu_name",
                    //     label: "Menu",
                    //     type: "textfield"
                    // },
                    {
                        key: "brand_id",
                        label: "Brand",
                        type: "multiselect",
                        getData: brandList
                    },
                    {
                        key: "service_name",
                        label: "Service",
                        type: "multiselect",
                        getData: () => [
                            {id: "CATERING", label: "Catering"},
                            {id: "HOME_MEALS", label: "Home meals"}
                        ]
                    },
                    {
                        key: "start_date",
                        label: "Created",
                        type: "createdAt"
                    },
                    {
                        key: "is_active",
                        label: "Status",
                        type: "select",
                        getData: () => [
                            {id: "active", label: "Active"},
                            {id: "inactive", label: "Inactive"}
                        ]
                    }
                ];
            case "menuItems":
                return [
                    // {
                    //     key: "item_name",
                    //     label: "Item name",
                    //     type: "textfield"
                    // },
                    {
                        key: "menu_name",
                        label: "Menu",
                        type: "textfield"
                    },
                    {
                        key: "service_name",
                        label: "Service",
                        type: "multiselect",
                        getData: () => [
                            {id: "CATERING", label: "Catering"},
                            {id: "HOME_MEALS", label: "Home meals"}
                        ]
                    },
                    {
                        key: "item_type",
                        label: "Type",
                        type: "multiselect",
                        getData: () => [
                            {id: "0", label: "Custom"},
                            {id: "1", label: "Regular"}
                        ]
                    },
                    {
                        key: "category",
                        label: "Category",
                        type: "multiselect",
                        getData: categoryList
                    },
                    {
                        key: "start_date",
                        label: "Created",
                        type: "createdAt"
                    },
                    {
                        key: "is_active",
                        label: "Status",
                        type: "select",
                        getData: () => [
                            {id: "active", label: "Active"},
                            {id: "inactive", label: "Inactive"}
                        ]
                    }
                ];
            case "modifierGroup":
                return [
                    // {
                    //     key: "modifier_group_name",
                    //     label: "Modifier group",
                    //     type: "textfield"
                    // },
                    {
                        key: "modifier_name",
                        label: "Modifiers",
                        type: "textfield"
                    },
                    {
                        key: "start_date",
                        label: "Created",
                        type: "createdAt"
                    },
                    {
                        key: "is_active",
                        label: "Status",
                        type: "select",
                        getData: () => [
                            {id: "active", label: "Active"},
                            {id: "inactive", label: "Inactive"}
                        ]
                    }
                ];
            case "templates":
                return [
                    {
                        key: "templateIds",
                        label: "Template",
                        type: "multiselect",
                        getData: templateFilterList
                    },
                    {
                        key: "menuIds",
                        label: "Menu",
                        type: "multiselect",
                        getData: menuList
                    },
                    {
                        key: "brandIds",
                        label: "Brand",
                        type: "multiselect",
                        getData: brandList
                    },
                    {
                        key: "services",
                        label: "Service",
                        type: "multiselect",
                        getData: () => [
                            {id: "CATERING", label: "Catering"},
                            {id: "HOME_MEALS", label: "Home meals"}
                        ]
                    }
                ];
            default:
                break;
        }
        return [];
    };
    return {getColumnData, getFilterState, getBrandList, getCategoryList, getCuisine, filterChips, getTemplateList, getMenuList};
};
