import { CookieKeys, CookieOptions } from "constants/cookiesKeys"
import { useCookies } from "react-cookie"

export function useUserSession() {
  const [cookies, setCookie, removeCookie,] = useCookies([CookieKeys.Auth])

  const setSession = (data) => {
    setCookie(CookieKeys.Auth, data.user.token.token, CookieOptions)
    setCookie(CookieKeys.REFRESH_TOKEN, data.user.token.refreshToken, CookieOptions)
    setCookie(CookieKeys.DEVICE_TOKEN,data.user.deviceToken,CookieOptions)
  }

  const deleteSession = () => {
    const cookieNames = Object.keys(cookies)
    cookieNames.forEach((cookie) => removeCookie(cookie, CookieOptions))
  }

  const isValidSession = () =>  cookies[CookieKeys.Auth] !== undefined

  return {
    setSession,
    deleteSession,
    isValidSession
  }
}
