import React, { useState } from 'react';
import Tooltip from "commonComponent/Tooltip";
import Typography from '@mui/material/Typography';


const SER = {
    HOME_MEALS: "Home Meals",
    CATERING: "Catering"
};

function getUniqueServiceNames(data) {
    const uniqueServiceNamesSet = new Set();
  
    data.forEach((item) => {
      item.menuCategory.forEach((menu) => {
        if (menu.brandMenu) {
          menu.brandMenu.forEach((brandMenu) => {
            if (brandMenu.brand && brandMenu.brand.brandServices) {
              brandMenu.brand.brandServices.forEach((service) => {
                uniqueServiceNamesSet.add(SER[service.service_name]);
              });
            }
          });
        }
      });
    });
  
    // Convert Set to Array if needed
    const uniqueServiceNamesArray = Array.from(uniqueServiceNamesSet);
  
    return uniqueServiceNamesArray;
  }

const ServiceDisplay = ({ menuData }) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    

    const menuCategoryItems = getUniqueServiceNames(menuData.menucategoryitem);
    // let count = 0

    // menuCategoryItems.forEach((item) => {
    //     item.menuCategory.forEach((row) => {
    //         if (row.brandMenu) {
    //             row.brandMenu.forEach(a => {

    //                 count += a.brand.brandServices.length

    //             })
    //         }
    //     })

    // })


    // // Function to extract service names from nested structure
    // const extractServiceNames = (menuCategory) => {
    //     return menuCategory.menuCategory
    //         .flatMap(menu => {
    //             if (menu && menu.brandMenu && menu.brandMenu[0] && menu.brandMenu[0].brand) {
    //                 const brand = menu.brandMenu[0].brand;
    //                 if (brand.brandServices && brand.brandServices.length > 0) {
    //                     return brand.brandServices.map(service => SER[service.service_name]);
    //                 }
    //             }
    //             return [];
    //         });
    // };





    // Display the first 2 service names for each menu category
    const initialServiceNames = 
        <div >
            <Typography variant="body3" sx={{ fontWeight: 400, fontSize: "11px" }}>
                {menuCategoryItems.join(', ')}
            </Typography>
        </div>
    
 
    // // Display "View more" button and tooltip
    // const tooltipContent = menuCategoryItems
    //     .map(category =>
    //         extractServiceNames(category).slice(2).join(', ')
    //     )
    //     .join(', ');

    return (
        <Typography variant="body3" sx={{ fontWeight: 400, fontSize: "11px" }}>
            <div style={{display:'flex'}}>
                {initialServiceNames}


                {/* {tooltipContent && (
                    <Tooltip
                        title={
                            <Typography variant="body3" sx={{ fontWeight: 400, fontSize: "11px" }}>
                                {tooltipContent}
                            </Typography>
                        }
                        arrow
                        followCursor
                        open={isTooltipOpen}
                        onOpen={() => setIsTooltipOpen(true)}
                        onClose={() => setIsTooltipOpen(false)}
                    >
                        <small style={{ cursor: "pointer", color: "#00ab3a", fontWeight: 500, fontSize: "11px" }}>
                            &nbsp;+{count - 2} more
                        </small>
                    </Tooltip>
                )} */}
            </div>
        </Typography>
    );
};


export default ServiceDisplay;