import {Grid, Typography, Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import Cards from "../Cards/Cards";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import Loader from "ui-component/Loader";
import Visa from "assets/images/card/visa.svg";
import Master from "assets/images/card/master.svg";
import Discover from "assets/images/card/discover.svg";
import American from "assets/images/card/american.svg";
import PayPal from "assets/images/card/PayPal.svg";
import ApplePay from "assets/images/card/ApplePay.svg";
import Other from "assets/images/card/other.svg";
import {getManagerName, getManagers} from "utils/helperFunctions/helpers";

function ProfileView() {
    const dispatch = useDispatch();
    const [customerData, setCustomerData] = useState([]);
    const [cardList, setCardList] = useState(null);
    const [defaultCard, setDefaultCard] = useState(null);
    const [houseAccountData, setHouseAccountData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [managers, setManagers] = useState([]);
    const prm = useParams();
    const CustomerId = prm?.type;

    useEffect(() => {
        getManagers(setManagers, setLoading);
    }, []);
    const cardLogo = (cardType) => {
        switch (cardType) {
            case "Visa":
                return Visa;
            case "AmericanExpress":
                return American;
            case "Discover":
                return Discover;
            case "MasterCard":
                return Master;
            case "PayPal":
                return PayPal;
            case "ApplePay":
                return ApplePay;
            // case "DinersClub":
            //     return Discover;
            default:
                return Other;
        }
    };

    const getHouseAccountData = async (profileId) => {
        const instance = NetworkManager(API.CARD.HOUSEACCOUNTDATA(profileId));
        let houseAccount = null;

        const response = await instance.request();
        if (response.success) {
            const data = response.data;
            if (data?.is_house_account_default) {
                setDefaultCard({
                    id: data?.customer_card_id,
                    label: (
                        <div style={{display: "flex", alignItems: "center"}}>
                            <img
                                src={cardLogo(data?.card_type)}
                                style={{
                                    width: 70,
                                    height: 35,
                                    objectFit: "contain",
                                    marginRight: 5
                                }}
                                alt={data?.card_type}
                            />
                            <Grid container>
                                <Grid item xs={9} display="flex" gap={1}>
                                    <Box sx={{marginBottom: "4px"}}>
                                        <Typography fontSize="0.875rem" sx={{fontWeight: 700}}>
                                            {/* {account?.billTo?.firstName} */}
                                            {data?.billing_first_name} {data?.billing_last_name}
                                        </Typography>
                                        <Typography variant="subtitle3">House account, {data?.check_number ? "Check" : "ACH"}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    )
                });
            } else {
                houseAccount = {
                    id: data?.customer_card_id,
                    label: (
                        <div style={{display: "flex", alignItems: "center", marginBottom: "8px"}}>
                            <img
                                src={cardLogo(data?.card_type)}
                                style={{
                                    width: 70,
                                    height: 35,
                                    objectFit: "contain",
                                    marginRight: 5
                                }}
                                alt={data?.card_type}
                            />
                            <Grid container>
                                <Grid item xs={9} display="flex" gap={1}>
                                    <Box sx={{marginBottom: "4px"}}>
                                        <Typography fontSize="0.875rem" sx={{fontWeight: 500}}>
                                            {/* {account?.billTo?.firstName} */}
                                            {/* {data?.billing_first_name} {data?.billing_last_name} */}
                                            House account
                                        </Typography>
                                        <Typography variant="subtitle3" sx={{fontWeight: 400}}>
                                            {data?.check_number ? "Check" : "ACH"}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    )
                };
            }

            setHouseAccountData(data);
        }
        return houseAccount;
    };

    const formatCardInfo = (cardInfo) => {
        const {cardNumber, expirationDate} = cardInfo;

        // Replace all 'X' with '•' in cardNumber and expirationDate
        const maskedCardNumber = cardNumber.replace(/X/g, "•");
        const maskedExpirationDate = expirationDate.replace(/X/g, "•");

        // Combine the formatted strings
        const formattedString = `${maskedCardNumber}  `;

        return formattedString;
    };

    const getCardList = async (profileId) => {
        const instance = NetworkManager(API.CUSTOMER.GETCARDLIST(`${profileId}`));
        const res = await instance.request();
        if (res.success) {
            const cardsList = res?.data?.profile?.paymentProfiles || [];
            const dataMapping = cardsList.map((el) => {
                const contructlabel = (data) => (data ? `${data?.cardType} ending with ${data?.cardNumber?.substr(-4)}` : "");
                if (el?.defaultPaymentProfile) {
                    setDefaultCard({
                        id: el?.customerPaymentProfileId,
                        label: (
                            <div style={{display: "flex", alignItems: "center"}}>
                                <img
                                    src={cardLogo(el?.payment?.creditCard?.cardType)}
                                    style={{
                                        width: 60,
                                        height: 35,
                                        objectFit: "contain",
                                        marginRight: 5
                                    }}
                                    alt={el?.payment?.creditCard?.cardType}
                                />
                                <Box>
                                    <Typography fontSize="0.875rem" sx={{fontWeight: 700}}>
                                        {el?.billTo?.firstName}
                                    </Typography>
                                    <Typography variant="subtitle3" sx={{fontWeight: 400}}>
                                        {formatCardInfo(el?.payment?.creditCard)}
                                    </Typography>
                                </Box>
                            </div>
                        )
                    });
                }
                return {
                    id: el?.customerPaymentProfileId,
                    label: (
                        <div style={{display: "flex", alignItems: "center", marginBottom: "8px"}}>
                            <img
                                src={cardLogo(el?.payment?.creditCard?.cardType)}
                                style={{
                                    width: 60,
                                    height: 35,
                                    objectFit: "contain",
                                    marginRight: 5
                                }}
                                alt={el?.payment?.creditCard?.cardType}
                            />
                            <Box sx={{marginBottom: el?.defaultPaymentProfile ? "8px" : ""}}>
                                <Typography fontSize="0.875rem" sx={{fontWeight: 500}}>
                                    {el?.billTo?.firstName}
                                </Typography>
                                <Typography variant="subtitle3" sx={{fontWeight: 400}}>
                                    {formatCardInfo(el?.payment?.creditCard)}
                                </Typography>
                            </Box>
                        </div>
                    )
                };
            });

            const houseAccount = await getHouseAccountData(profileId);
            if (houseAccount) {
                dataMapping.push(houseAccount);
            }

            setCardList([...dataMapping]);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
    };

    const getCustomerProfileData = async () => {
        const instance = NetworkManager(API.CUSTOMER.GETCUSTOMERDATA(`${CustomerId}`));
        const response = await instance.request();
        if (response.success) {
            setCustomerData(response?.data);

            setLoading(false);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            getCardList(response?.data?.user_id);
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    useEffect(() => {
        getCustomerProfileData();
    }, [CustomerId]);

    const customerDetailsData = [
        {label: "First name", value: customerData?.full_name || "-"},
        {label: "Last name", value: customerData?.last_name || "-"},
        {label: "Email", value: customerData?.email || "-"},
        {label: "Phone", value: customerData?.phone || "-"}
    ];

    const companyDetailsData = [
        {label: "Company name", value: customerData?.company?.company_name || "-"},
        {label: "Title / position", value: customerData?.designation || "-"}
    ];

    // const defaultAddress = customerData?.customerAddresses?.find((address) => address.is_default);

    let defaultAddress;
    let defaultPayment;

    if (customerData?.customerAddresses?.length > 0) {
        const firstAddress = customerData.customerAddresses[0];
        defaultAddress = customerData.customerAddresses.find((address) => address.is_default);

        if (defaultAddress) {
            defaultAddress = defaultAddress.delivery_address;
        } else if (firstAddress) {
            defaultAddress = firstAddress.delivery_address;
        } else {
            defaultAddress = "";
        }
    } else {
        defaultAddress = "";
    }

    if (defaultCard) {
        const firstAddress = customerData.customerCard[0];
        defaultPayment = customerData.customerCard.find((address) => address.is_default);

        if (defaultPayment) {
            defaultPayment = defaultPayment.card_type;
        } else {
            defaultPayment = "";
        }
    } else {
        defaultPayment = "";
    }

    const otherAddresses = customerData?.customerAddresses?.filter((address) => !address.is_default);

    const otherPayment = cardList?.filter((card) => defaultCard?.id !== card.id);

    const addressData = [
        {label: "Default", value: defaultAddress || "-"},
        {
            label: "Other addresses",
            value:
                otherAddresses?.length > 0
                    ? otherAddresses?.map((address, index) => <div key={index}>{address?.delivery_address || "-"}</div>)
                    : "-"
        }
    ];

    const paymentData = [
        {label: "Default", value: defaultCard ? defaultCard.label : "-"},
        {
            label: "Other cards",
            value: otherPayment?.length > 0 ? otherPayment?.map((card, index) => card?.label) : "-"
        }
    ];

    const salesTaxExemptData = [
        {label: "State", value: customerData?.state?.name || "-"},
        {label: "Tax exempt ID", value: customerData?.tax_exempt_id || "-"}
    ];
    const handleViewCertificateClick = () => {
        const certificateURL = customerData.sales_tax_doc_url;
        window.open(certificateURL, "_blank", "noreferrer");
    };
    return (
        <>
            {loading && <Loader />}
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{maxWidth: "80%"}}>
                        <Grid
                            container
                            sx={{
                                borderRadius: "4px",
                                border: "1px solid #DBDBDB",
                                padding: "16px"
                            }}
                        >
                            <Grid item xs={12}>
                                <Typography sx={{fontSize: "1.125rem", fontWeight: 500}} color="#020A11">
                                    Customer details
                                </Typography>
                                <Grid container mb={2}>
                                    {customerDetailsData.map((item, index) => (
                                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index} mt={1.5}>
                                            <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                                                {item.label}
                                            </Typography>
                                            <Typography sx={{fontSize: "0.875rem", fontWeight: 500}} color="#212121">
                                                {item.value}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Typography sx={{fontSize: "1.125rem", fontWeight: 500}} color="#020A11">
                                    Company details
                                </Typography>
                                <Grid container mt={1.5}>
                                    {companyDetailsData.map((item, index) => (
                                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index}>
                                            <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                                                {item.label}
                                            </Typography>
                                            <Typography sx={{fontSize: "0.875rem", fontWeight: 500}} color="#212121">
                                                {item.value !== "" ? item.value : "-"}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box sx={{display: "flex", gap: "16px", width: "100%", flexWrap: {xs: "wrap", sm: "nowrap"}}} mt={2}>
                            <Cards title="Address" data={addressData} />
                            <Cards title="Payment" data={paymentData} />
                        </Box>
                        <Box sx={{display: "flex", gap: "16px", width: "100%"}} mt={2}>
                            <Box
                                sx={{
                                    borderRadius: "4px",
                                    border: "1px solid #DBDBDB",
                                    padding: "16px",
                                    width: {xs: "100%"}
                                }}
                            >
                                <Typography sx={{fontSize: "1.125rem", fontWeight: 500}} color="#020A11">
                                    Sales tax exempt
                                </Typography>

                                <Grid container>
                                    {salesTaxExemptData.map((item, index) => (
                                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index} mt={1.5}>
                                            <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                                                {item.label}
                                            </Typography>
                                            <Typography sx={{fontSize: "0.875rem", fontWeight: 500}} color="#212121">
                                                {item.value !== "" ? item.value : "-"}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                                {console.log(customerData?.sales_tax_doc_url, "423423")}
                                {customerData.sales_tax_doc_url == null || customerData?.sales_tax_doc_url === "" ? (
                                    ""
                                ) : (
                                    <Typography
                                        sx={{fontSize: "0.938rem", fontWeight: 700, cursor: "pointer"}}
                                        color={(t) => t.palette.primary.main}
                                        mt={2}
                                        onClick={handleViewCertificateClick}
                                    >
                                        View sales tax exemption certificate
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{display: "flex", gap: "16px", width: "100%"}} mt={2}>
                            <Box
                                sx={{
                                    borderRadius: "4px",
                                    border: "1px solid #DBDBDB",
                                    padding: "16px",
                                    width: {xs: "100%"}
                                }}
                            >
                                <Typography sx={{fontSize: "1.125rem", fontWeight: 500}} color="#020A11">
                                    Account manager
                                </Typography>

                                <Grid container>
                                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3} mt={1.5}>
                                        <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                                            Account manager {/* {item.label} */}
                                        </Typography>
                                        <Typography sx={{fontSize: "0.875rem", fontWeight: 500}} color="#212121">
                                            {/* {item.value !== "" ? item.value : "-"} */}
                                            {getManagerName(customerData?.account_manager_id, managers)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default ProfileView;
