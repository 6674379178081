import React from "react";
import { Stack } from "@mui/material";
import { GuestCount } from "./sections/GuestCount";
import Customer from "./sections/Customer";
import Service from "./sections/Service";


const DelivarySection = (props) => {
    const { formik, orderId, customerName, customerId, editScreen } = props;
    const { headerData, selectedBrand } = formik.values

    return (
        <>
            <Stack direction="row" spacing={3} sx={{ flexWrap: 'wrap' }}>
                <Customer Parentformik={formik} customerName={customerName}
                    customerId={customerId} editScreen={editScreen} />
                <Service Parentformik={formik} />
               


                <GuestCount Parentformik={formik} />
            </Stack>
        </>
    );
};

export default DelivarySection;
