import React, {useState} from "react";
import {getHHMMTime, getformateDate} from "utils/app-dates/dates";
import {useParams} from "react-router-dom";
import {FormatCurrency, checkforPerfectNumber, formatFetchedPhoneNumber} from "utils/helperFunctions/helpers";
import useCreateLocationModal from "../createLocations/CreateLocation.modal";

const formSchema = {
    locationDetails: {},
    brandsmarket: [],
    hoursOfOperation: {
        standardHours: [
            {
                isChecked: false,
                day: "Sunday",
                startTime: "",
                endTime: ""
            },
            {
                isChecked: false,
                day: "Monday",
                startTime: "",
                endTime: ""
            },
            {
                isChecked: false,
                day: "Tuesday",
                startTime: "",
                endTime: ""
            },
            {
                isChecked: false,
                day: "Wednesday",
                startTime: "",
                endTime: ""
            },
            {
                isChecked: false,
                day: "Thursday",
                startTime: "",
                endTime: ""
            },
            {
                isChecked: false,
                day: "Friday",
                startTime: "",
                endTime: ""
            },
            {
                isChecked: false,
                day: "Saturday",
                startTime: "",
                endTime: ""
            }
        ],
        holidayClosures: []
    },
    temporaryClosures: {
        isActive: true,
        cloures: [
            {
                from: "",
                startingAt: "",
                through: "",
                endingAt: ""
            }
        ]
    },
    leadTime: {
        knowAboutOrders: "",
        cuttOffTime: ""
    },
    delivery: [],
    fees: {
        salesTaxRate: "",
        serviceFee: "",
        minimumServiceFee: ""
    }
};

const useLocationDetailController = () => {
    const prm = useParams();
    const {id, type} = prm;

    const [initialVlaues, setInitialValue] = React.useState(formSchema);
    const [marketList, setMarketList] = React.useState([]);
    const [brandList, setBrandList] = React.useState([]);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [totalPage, setTotalPage] = React.useState();
    const [holidays, setHolidays] = React.useState([]);
    const [isEdit, setIsEdit] = React.useState(false);
    const [readOnly, setReadyOnly] = useState(false);
    const [loading, setLoading] = React.useState(true);
    const [locationName, setLocationName] = React.useState("Location view");

    const CLM = useCreateLocationModal({setLoading});

    // holidayList
    const BASE_CALENDAR_URL = "https://www.googleapis.com/calendar/v3/calendars";
    const BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY = "holiday@group.v.calendar.google.com";
    const API_KEY = process.env.REACT_APP_GOOGLE_MAP_AUTOCOMPLETE;
    const CALENDAR_REGION = "en.usa";
    const currentYear = new Date().getFullYear();
    const timeMin = new Date(`${currentYear}-01-01`).toISOString();
    const timeMax = new Date(`${currentYear}-12-31`).toISOString();

    const getMarketListData = async () => {
        const data = await CLM.getAllMarkets(pageNumber);
        setMarketList([...marketList, ...data.marketList]);
        setPageNumber(data.pagination.currentPage + 1);
        setTotalPage(data.pagination.totalPages);
    };

    const loadMoremarkets = (event) => {
        if (event.target.scrollTop + 200 >= event.target.scrollHeight) {
            if (totalPage >= pageNumber) {
                getMarketListData();
            }
        }
    };

    const getBrandListData = async () => {
        const data = await CLM.getAllBrands(pageNumber);
        setBrandList([...brandList, ...data.brandList]);
        setPageNumber(data.pagination.currentPage + 1);
        setTotalPage(data.pagination.totalPages);
    };

    const getHolidayList = () => {
        fetch(
            `${BASE_CALENDAR_URL}/${CALENDAR_REGION}%23${BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY}/events?key=${API_KEY}&timeMin=${timeMin}&timeMax=${timeMax}`
        )
            .then((response) => response.json())
            .then((response) => {
                const formattedResponse = response.items
                    .map(({summary, start, end}) => ({summary, start, end}))
                    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

                setHolidays(formattedResponse);
            });
    };

    const getStandardHours = (arr1, arr2) => {
        const finalArr = arr2.map((obj) => {
            const indexx = arr1.findIndex((obj2) => obj2.day === obj.day);
            if (indexx === -1) {
                return {
                    isChecked: false,
                    day: obj.day,
                    startTime: "",
                    endTime: ""
                };
            }
            return {
                isChecked: true,
                day: arr1[indexx].day,
                startTime: arr1[indexx].start_time ? arr1[indexx].start_time : "",
                endTime: arr1[indexx].end_time ? arr1[indexx].end_time : ""
            };
        });

        return finalArr;
    };

    const getHoldidayClosuers = (arr1, arr2) => {
        const finalArr = arr2.map((obj) => {
            const indexx = arr1.findIndex((obj2) => getformateDate(obj2.start_date) === getformateDate(obj.start.date));

            if (indexx === -1) {
                return {
                    isChecked: false,
                    ...obj
                };
            }
            return {
                isChecked: true,
                summary: obj?.summary,
                start: {date: arr1[indexx].start_date},
                end: {date: arr1[indexx].end_date}
            };
        });

        return finalArr;
    };

    const segregateTemporaryandHolidayClosures = (closures) => {
        const temporaryClosures = [];
        const holidayClosures = [];
        let isTempCloActive = false;

        closures.forEach((obj) => {
            if (obj.type === "Fixed") {
                holidayClosures.push(obj);
            } else {
                if (obj.is_active) {
                    isTempCloActive = true;
                }
                temporaryClosures.push({
                    from: obj.start_date,
                    startingAt: obj.start_date,
                    through: obj.end_date,
                    endingAt: obj.end_date
                });
            }
        });

        return {
            temporaryClosures,
            holidayClosures,
            isTempCloActive
        };
    };

    const handleServiceAndOrderType = (data, type) => {
        const arr = [];
        data.forEach((el) => {
            if (type === "service") {
                arr.push(el.service);
            } else if (el?.order_type === "Delivery") {
                arr.push(el.order_type);
            } else {
                arr.push(el.order_type);
            }
        });

        return arr;
    };

    const fetchExistingLocationData = async (id) => {
        const data = await CLM.getLocationDataByID(id);
        const KL = data.kitchenLocationList;
        setLocationName(KL.name);
        const standardHoursSortedData = getStandardHours(KL.standardHours, formSchema.hoursOfOperation.standardHours);
        const {temporaryClosures, holidayClosures, isTempCloActive} = segregateTemporaryandHolidayClosures(KL.closureHours);

        const createFormDataSchema = {
            locationDetails: {
                Name: KL.name || "",
                Address: KL.address,
                Phone: formatFetchedPhoneNumber(KL.phone) || "",
                Services: handleServiceAndOrderType(KL.kitchenLocationService, "service"),
                "Order type": handleServiceAndOrderType(KL.kitchenLocationOrderType, "orderType"),
                Market: KL.market_id || "",
                "Location type": KL.location_type === "External" ? "Partner" : KL.location_type,
                "Revenue share %": KL.revenue_share ? parseFloat(KL.revenue_share).toFixed(1) :"" ,
                "Buffer time": `${KL.setup_time} min` || ""
            },
            setTime: KL.setup_time || 15,
            brandsmarket: KL.brandKitchenLocation?.length ? KL.brandKitchenLocation?.map((el) => el.brand_id) : [],
            hoursOfOperation: {
                standardHours: standardHoursSortedData,
                holidayClosures: getHoldidayClosuers(holidayClosures, holidays).filter((el) => el.isChecked)
            },
            temporaryClosures: {
                isActive: isTempCloActive,
                cloures: temporaryClosures?.length ? temporaryClosures : []
            },
            leadTime: {
                knowAboutOrders: KL.lead_cut_off_day || "",
                cuttOffTime: KL.lead_cut_off_time || null
            },
            delivery: KL.deliveryFee?.length
                ? KL.deliveryFee?.map((el) => ({deliveryFee: el?.delivery_fee, minimumOrder: el?.order_minimum, radius: el?.radius, ...el}))
                : [],
            fees: {
                salesTaxRate: checkforPerfectNumber(KL.sales_tax_rate || ""),
                serviceFee: checkforPerfectNumber(KL.service_fee || ""),
                minimumServiceFee: FormatCurrency(KL.minimum_service_fee || "")
            }
        };

        setInitialValue(createFormDataSchema);
    };

    React.useEffect(() => {
        getBrandListData();
        getMarketListData();
        getHolidayList();
    }, []);

    React.useEffect(() => {
        setInitialValue({
            ...initialVlaues,
            hoursOfOperation: {
                ...initialVlaues.hoursOfOperation,
                holidayClosures: [...holidays.map((obj) => ({isChecked: false, ...obj}))]
            }
        });
        if (id && holidays.length && brandList.length && marketList.length) {
            fetchExistingLocationData(id);
            setIsEdit(true);
            setReadyOnly(type === "locationDetail");
        }
    }, [holidays, brandList, marketList]);

    return {
        initialVlaues,
        marketList,
        brandList,
        loadMoremarkets,
        isEdit,
        id,
        loading,
        locationName,
        readOnly,
        setReadyOnly
    };
};

export default useLocationDetailController;
