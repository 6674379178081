import {Grid} from "@mui/material";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import useTempDrawer from "hooks/useTempDrawer";
import React, {useState, useEffect} from "react";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CreateBrand from "./CreateBrand/CreateBrand";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {useDispatch, useSelector} from "store";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import Filters from "commonComponent/Filters";
import {generateUrl} from "utils/helperFunctions/helpers";
import FilterChips from "commonComponent/FilterChipsComponent";
import {useNavigate} from "react-router-dom";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import Filter from "commonComponent/FilterAccoridan";
import moment from "moment";
import {setPageNumber, setRowsPerPage, setOrder, setOrderBy} from "store/slices/paginationSlice";

const Brands = (props) => {
    const {search, showFilter, handleHeaderActions, setSelectedCheckbox, updateListCount, selectedCheckbox, filterChips} = props;
    const {toggleDrawer} = useTempDrawer();
    const [open, setOpen] = React.useState(false);
    const [brandListing, setBrandListing] = React.useState([]);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.order || "asc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "brand_name");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    // const [searchedValue, setSearchedValue] = useState({});
    const [cuisines, setCuisines] = useState([]);
    const dispatch = useDispatch();
    const navigation = useNavigate();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const handleCreatebrand = (btntype) => (e) => {
        switch (btntype) {
            case "Create brand": {
                setOpenFilter(false);
                toggleDrawer("right", "addNewBrand")(e);
                break;
            }
            case "brandFilter": {
                setOpenFilter((prev) => !prev);
                // toggleDrawer("right", true)(e);
                break;
            }
            default:
                break;
        }
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handleStatus = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.BRAND.STATUS(values.brand_id));
        let isActive = false;
        if (type === "Deactivate") {
            isActive = false;
        } else {
            isActive = true;
        }
        const payload = {
            status: isActive
        };

        const response = await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );

            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                setOpenFilter(false);
                navigation(`/brand-detail/${row.brand_id}?brandName=${row.brand_name}&isEdit=true`);
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            case "Deactivate": {
                handleStatus(row, type);
                break;
            }
            case "Activate": {
                handleStatus(row, type);
                break;
            }
            default:
                break;
        }
    };

    const getBrandList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.BRAND.GET);
        const params = {
            page: currentPage,
            pageSize: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };

        if (currentOrderBy) {
            params.sortBy = currentOrderBy;
            params.orderBy = currentOrder;
        }
        // while searching we dont need page number
        if (search === "") {
            params.page = currentPage;
        }

        if (search !== "") {
            params.brand_name = search;
            setBrandListing([]);
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);

        if (response.success) {
            setBrandListing(response.data.brandList);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.BRAND.DELETE(selectedRow.brand_id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Brand Deleted Successfully",
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getBrandList();
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        dispatch(setOrder(val1));
        dispatch(setOrderBy(val2));
    };

    useEffect(() => {
        getBrandList(Object.keys(filterChips)?.length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, filterChips]);

    const getCuisine = async (page = 1, pageSize = 1000) => {
        let tempData = [];
        const instance = NetworkManager(API.BRAND.GET);
        const params = {
            page: page,
            pageSize: pageSize
        };
        if (currentOrderBy) {
            params.sortBy = "cusine";
            params.orderBy = "asc";
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);

        if (response.success) {
            const cuisineData = response.data.brandList.map((el) => ({id: `${el.cusine}`, label: el.cusine}));

            tempData = [...new Set(cuisineData.map(JSON.stringify))].map(JSON.parse);
        }
        return setCuisines(tempData);
    };
    useEffect(() => {
        getCuisine();
    }, []);

    return (
        <>
            <Grid container>
                {/* {openFilter ? (
                    <Filters
                        filterState={filterState}
                        filterChips={filterChips}
                        setFilterChips={setFilterChips}
                        handleApplyFilter={handleApplyFilter}
                    />
                ) : (
                    <CreateBrand handleIsEdited={handleIsEdited} selectedRow={selectedRow} handleSelectedRow={handleSelectedRow} />
                )} */}
                {!openFilter && (
                    <CreateBrand handleIsEdited={handleIsEdited} selectedRow={selectedRow} handleSelectedRow={handleSelectedRow} />
                )}
                <Grid item xs={12}>
                    {/* <TableTopFilterWrapper
                        lessmarginOnTop
                        pageheader={<PageHeader title="Brands" />}
                        btnTitle={WithPermission({module: "Brands", permission: "create_permission"}) && "Create brand"}
                        handleClick={handleCreatebrand}
                        handleSearch={handleSearch}
                        filterType="brandFilter"
                    > */}
                    {/* {openFilter && (
                            <Filter
                                filterState={filterState}
                                setFilterState={setFilterChips}
                                filterChips={filterChips}
                                showFilter={openFilter}
                                // setSearchedValue={setSearchedValue}
                            />
                        )}
                        <FilterChips filterChips={filterChips} setFilterChips={setFilterChips} filterState={filterState} /> */}
                    <CommonTable
                        loading={loading}
                        data={brandListing}
                        currentTab="brands"
                        header={[
                            {key: "brand_name", label: "Brand"},
                            {key: "cusine", label: "Cuisine"},
                            {key: "owner", label: "Owner"},
                            {key: "menulist", label: "Menus"},
                            {key: "brandServices.service_name", label: "Services"},
                            {key: "createdAt", label: "Created"},
                            {key: "is_active", label: "Status"},

                            WithPermission({module: "Brands", permission: ["update_permission", "delete_permission"]}) && {
                                key: "action",
                                label: "Action"
                            }
                        ].filter((el) => el)}
                        actions={[
                            WithPermission({module: "Brands", permission: "update_permission"}) && "Edit",
                            WithPermission({module: "Brands", permission: "delete_permission"}) && "Delete"
                        ]}
                        handleActionBtn={handleActionBtn}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage - 1}
                        sortingLabel={["Brand", "Cuisine", "Owner", "Status", "Created"]}
                    />
                    {/* </TableTopFilterWrapper> */}
                </Grid>
            </Grid>

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this brand?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
        </>
    );
};

export default Brands;
