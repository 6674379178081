import {
    Dialog,
    DialogActions,
    Grid,
    FormControlLabel,
    Checkbox,
    DialogContent,
    Stack,
    DialogContentText,
    DialogTitle,
    Slide,
    Radio,
    RadioGroup,
    Button,
    InputAdornment,
    Typography
} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import Cancel from "../../assets/images/icons/cancel.svg";
import React from "react";
import { Box } from "@mui/system";
import styles from "./Driver.module.scss";

import { Form, Formik, ErrorMessage } from "formik";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import { HouseAccountValidation } from "utils/validations/validations";
import { enforceFormat, FormatCurrency } from "utils/helperFunctions/helpers";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import { dispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import { NetworkManager, API } from "network/core";
import CustomNewDateTimePicker from "commonComponent/newDateAndTimePicker/CustomNewDateTimePicker";
import { getformateDateUS} from "utils/app-dates/dates";


const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);




const defaultInitialState = {
    // item Details
    amount: "",
    date: "",
};


const HouseAccountPayment = (props) => {
    const {
        title = 'How much is received?',
        open,
        handleClose,
        orderId,
        hideBackdrop = false,
        buttonTitle = "Delete",
        noOnClose = false,
        handleHouseAccountPayment,
        remainingAmount = 0
    } = props;
    // const [selected, setSelected] = React.useState("customer_order");

    const [transactionData, setTransactionData] = React.useState([])
    const [initialValues, setInitialValues] = React.useState(defaultInitialState);

    const handlePayment = async (values, setSubmitting) => {
        setSubmitting(true);

        const instance = NetworkManager(API.ORDER.HOUSEACCOUNTPAYMENT(orderId));

        const payload = {
            "amount": Number(values.amount),
            "receivedDate": values.date,
        }


        const response = await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    transition: "Fade",
                    close: true
                })
            );
            handleHouseAccountPayment(response)
            handleClose()

        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }

        setSubmitting(false);

    };

    const getTransactionHistory = async () => {

        const instance = NetworkManager(API.ORDER.HOUSEACCOUNTPAYMENTLIST(orderId));
        const response = await instance.request();

        if (response.success) {
            const data = response.data.orderPayments

            const transaction = data.map((row) => {
                return {
                    TransactionData: row?.createdAt,
                    ReceivedData: row?.received_date,
                    Amount: row?.amount

                }
            })
            setTransactionData(transaction)
        }
    };

    React.useEffect(() => {
        if (orderId) {
            getTransactionHistory()

            setInitialValues({
                ...defaultInitialState,
                remainingAmount: Number(remainingAmount)
            })
        }

    }, [orderId])


    return (
        <Dialog
            open={open}
            {...(!noOnClose && { onClose: handleClose })}
            // TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            hideBackdrop={hideBackdrop}
            PaperProps={{ sx: { boxShadow: "0px 0px 8px rgba(0,0,0,0.2)", padding: "33px 23px 0px 23px", width: "421px", position: "relative" } }}
        >
            <Box sx={{ position: "absolute", right: "16px", top: "27px", cursor: "pointer" }} onClick={handleClose}>
                <img src={Cancel} alt="plus-icon" width="12px" height="12px" />
            </Box>
            <DialogTitle
                id="alert-dialog-title"
                className={styles.title1}
            >
                {title}

            </DialogTitle>

            <DialogContent sx={{ marginTop: "-15px", padding: "5px 5px" }}>
                <Typography variant="specify" >Please specify amount received.</Typography>
                <Stack sx={{ marginTop: "20px" }}>
                    <Grid item xs={12}>
                        {Number(remainingAmount) > 0 ?
                            <Formik
                                enableReinitialize
                                initialValues={initialValues}
                                validationSchema={HouseAccountValidation}
                                onSubmit={(val, { setSubmitting }) => handlePayment(val, setSubmitting)}
                            >
                                {(formik) => (
                                    <Form>

                                        <Grid container spacing={3} item xs={12} >

                                            <Grid item xs={12}>
                                                <CustomTextField
                                                    fullWidth
                                                    label="Amount"
                                                    onKeyDown={enforceFormat}
                                                    {...formik.getFieldProps("amount")}
                                                    inputProps={{
                                                        maxLength: 12
                                                    }}

                                                    sx={{
                                                        "&": {
                                                            "& .MuiOutlinedInput-root": {

                                                                "& .MuiOutlinedInput-input": {
                                                                    paddingLeft: '4px !important'
                                                                }
                                                            },
                                                        }
                                                    }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start" sx={{ color: "#212121" }}>
                                                                $
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                <ErrorMessage name="amount" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <CustomNewDateTimePicker
                                                    // differentAnchor={anchorEl}
                                                    dateTitle="Receiving date"
                                                    timeTitle="Receiving time"
                                                    // disablePast

                                                    clearButton
                                                    handleClear={() => {
                                                        formik.setFieldValue(`date`, null);
                                                    }}
                                                    // setDifferentAnchor={setAnchorEl}
                                                    value={formik.values.date || null}

                                                    label="Date received"
                                                    {...formik.getFieldProps("date")}
                                                    onChange={(val) => {
                                                        formik.setFieldValue(`date`, val);
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="date"
                                                    render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    {!!transactionData.length && <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={4}>
                                                                <Typography variant="checkBoxSelected" fontSize="14px">
                                                                    Transaction date
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} textAlign='center' >
                                                                <Typography variant="checkBoxSelected" fontSize="14px">
                                                                    Received
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} textAlign='right'>
                                                                <Typography variant="checkBoxSelected" fontSize="14px">
                                                                    Amount
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>}
                                                    {!!transactionData.length &&
                                                        transactionData.map((obj, index) => (
                                                            <Grid item xs={12} key={index}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={4}>
                                                                        <Typography variant="checkBoxUnSelected" fontSize="14px">
                                                                            {obj?.TransactionData ? getformateDateUS(obj.TransactionData, 'dd/mm/yy') : '-'}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4} textAlign='center'>
                                                                        <Typography variant="checkBoxUnSelected" textAlign='left' fontSize="14px">
                                                                            {obj?.ReceivedData ? getformateDateUS(obj.ReceivedData, 'dd/mm/yy') : '-'}
                                                                        </Typography>
                                                                    </Grid>  
                                                                    <Grid item xs={4} textAlign='right'>
                                                                        <Typography variant="checkBoxUnSelected" textAlign='left' fontSize="14px">
                                                                            {obj?.Amount
                                                                                ? FormatCurrency(obj.Amount)
                                                                                : "-"}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sx={{ mt: 4, pb: 2, pt: 1, ml: '-2px', width: "100%", position: "sticky", bottom: 0, bgcolor: "#fff", zIndex: 1 }}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Grid container pb='10px'>
                                                        <Grid item xs={6}>
                                                            <Typography variant="checkBoxSelected" fontWeight={700} fontSize="15px">
                                                                Remaining balance
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6} textAlign='right'>
                                                            <Typography variant="checkBoxUnSelected" fontSize="14px">
                                                                {FormatCurrency(remainingAmount)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <ButtonGrouping
                                                sx={{ justifyContent: 'space-between' }}
                                                btnprops={[
                                                    {
                                                        btnTitle: "Paid in full",
                                                        variant: "outlined",
                                                        onClick: () => {
                                                            formik.setFieldValue(`amount`, remainingAmount);
                                                            // formik.setFieldTouched('date',true)
                                                            // formik.setFieldTouched('amount',true)
                                                            setTimeout(() => formik.handleSubmit(), 300)

                                                        },
                                                        sx: (t) => ({
                                                            color: "#00AB3A",
                                                            border: "1.5px solid #00AB3A",
                                                            height: "35px",
                                                            width: "178px",
                                                            fontSize: "0.875rem",
                                                            fontWeight: 500,

                                                        })
                                                    },
                                                    {
                                                        btnTitle: "Submit",
                                                        variant: "contained",
                                                        onClick: formik.handleSubmit,
                                                        sx: (t) => ({
                                                            sx: (t) => ({ color: t.palette.background.paper }),
                                                            border: "1.5px solid #00AB3A",
                                                            height: "35px",
                                                            width: "178px",
                                                            fontSize: "0.875rem",
                                                            fontWeight: 500,

                                                        })
                                                    }
                                                ]}
                                            />
                                        </Grid>

                                    </Form>
                                )}
                            </Formik>
                            : (
                                <Grid container spacing={3} item xs={12} pb={2} >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={4}>
                                                        <Typography variant="checkBoxSelected" fontSize="14px">
                                                            Transaction date
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4} textAlign='center' >
                                                        <Typography variant="checkBoxSelected" fontSize="14px">
                                                            Received
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4} textAlign='right'>
                                                        <Typography variant="checkBoxSelected" fontSize="14px">
                                                            Amount
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {!!transactionData.length &&
                                                transactionData.map((obj, index) => (
                                                    <Grid item xs={12} key={index}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={4}>
                                                                <Typography variant="checkBoxUnSelected" fontSize="14px">
                                                                    {obj?.TransactionData ? getformateDateUS(obj.TransactionData, 'dd/mm/yy') : '-'}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} textAlign='center'>
                                                                <Typography variant="checkBoxUnSelected" textAlign='left' fontSize="14px">
                                                                    {obj?.ReceivedData ? getformateDateUS(obj.ReceivedData, 'dd/mm/yy') : '-'}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} textAlign='right'>
                                                                <Typography variant="checkBoxUnSelected" textAlign='left' fontSize="14px">
                                                                    {obj?.Amount
                                                                        ? FormatCurrency(obj.Amount)
                                                                        : "-"}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                        </Grid>
                                    </Grid>

                                </Grid>
                            )}
                    </Grid>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default HouseAccountPayment;
