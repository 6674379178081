import {Grid} from "@mui/material";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import React, {useState, useEffect} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import ItemDetailsTab from "./tabs/itemDetailsTab";
import SettingsTab from "./tabs/settingsTab";
import CustomizedTabs from "commonComponent/NewTab";
import ModifiersTab from "./tabs/modifiersTab";
import NewItemDetail from "commonComponent/ItemComponent/ItemDetail/NewItemDetail";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import WithPermission from "commonComponent/RBAC/permissionHoc";

const ItemDetails = () => {
    const [value, setValue] = React.useState("itemDetails");
    const navigate = useNavigate();
    const param = useParams();
    const {menuId,type, id} = param;
    const [searchParams] = useSearchParams();
    const isEditable = searchParams.get("edit");
    const menuName = searchParams.get("menuName");
    const itemName = searchParams.get("itemName");
    const modifierGrpId = searchParams.get("id");
    const [isEdit, setIsEdit] = useState(isEditable);
    const [title, setTitle] = useState(itemName);
    const [breadCrumb, setBreadcrumb] = useState("View item");
    const tab = searchParams.get("tab");
    const showButton = ["itemName"];
    const itemUpdatePermission = WithPermission({module: "Items", permission: "update_permission"});
    
    
    useEffect(() => {
        setIsEdit(isEditable !== "null" && isEditable);
        navigate({
            pathname: window.location.pathname,
            search: menuName
                ? `?tab=${"itemDetails"}&edit=${isEditable}&menuName=${menuName}`
                : `?tab=${"itemDetails"}&edit=${isEditable}&itemName=${itemName}`
        });
    }, [isEditable]);

    const breadScrumData = [
        {label: type !== "masterList" ? "Menus" : "Menu items", href: type !== "masterList" ? "/menus?tab=menu" : "/menus?tab=menuItems"},
        // {
        //     label: type !== "masterList" ? "View menu items" : " ",
        //     href: type !== "masterList" ? `/menus/menuDetail/${type}/?tab=menuItems&menuName=${itemName}` : "/menus?tab=menuItems"
        // }
        // {
        //     label: breadCrumb,
        //     isActive: !modifierGrpId || !(isEditable === true || isEditable === "true"),
        //     ...(modifierGrpId && {
        //         href: `/items/${type}/${id}?tab=modifiers&edit=null&itemName=${itemName}`
        //     }),
        // }
    ];
    if(type !== "masterList") {
        
        breadScrumData.push({label: "View menu items", href: `/menus/menuDetail/${type}/?tab=menuItems&menuName=${menuName}` })
    }
    if (isEditable === true || isEditable === "true") {
        breadScrumData.push({label: "View item", href: `/items/${type}/${id}?tab=itemDetails&edit=null&itemName=${itemName}&menuName=${menuName}`});
    } else {
        breadScrumData.push({label: "View item", isActive: true});
    }

    if (isEditable === true || isEditable === "true") {
        breadScrumData.push({label: "Edit menu item", isActive: true});
    }

    if (modifierGrpId) {
        breadScrumData.push({label: "Modifier group detail", isActive: true});
    }

    const handleHeaderActions = (btntype) => (e) => {
        switch (btntype) {
            case "Edit item": {
                setIsEdit(true);
                navigate({
                    pathname: window.location.pathname,
                    search: `?tab=${"itemDetails"}&edit=true&itemName=${itemName}&menuName=${menuName}`
                });
                break;
            }
            default: {
                break;
            }
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TableTopFilterWrapper
                        lessmarginOnTop
                        pageheader={<PageHeader title={title} breadsCrumData={breadScrumData} />}
                        {...((isEditable !== "true" || isEditable !== true) && {onlyBtn: showButton.includes(tab)})}
                        {...(tab === "itemDetails" &&
                            (isEditable === true || isEditable === "true") && {
                                onlyHeading: true
                            })}
                        btnTitle={itemUpdatePermission ? "Edit item" : null}
                        handleClick={handleHeaderActions}
                        onlyBtn
                    >
                        <NewItemDetail isEditable={isEdit} setTitle={setTitle} />
                    </TableTopFilterWrapper>
                </Grid>
                {/* <Grid item xs={12}> */}

                {/* <CustomizedTabs tabsInfo={dummyData} value={value} handleChange={handleChange} /> */}
                {/* </Grid> */}
            </Grid>
        </>
    );
};

export default ItemDetails;
