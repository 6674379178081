import {Dialog} from "@mui/material";
import React from "react";
import Slide from "@mui/material/Slide";
import useToggleDilog from "hooks/useToggleDilog";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CommonDilogBox = (props) => {
    const {openState, children} = props;

    const {open, toggleDilog} = useToggleDilog();

    return (
        <>
            <Dialog sx={{zIndex:1250}}  fullScreen  open={open === openState} onClose={toggleDilog(false)} TransitionComponent={Transition}>
                {children}
            </Dialog>
        </>
    );
};

export default CommonDilogBox;
