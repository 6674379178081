import React from "react";
import CreatePromotionForm from "./CreatePromotionForm";

const Createpromotion = (props) => {
    const {handleIsEdited, selectedRow, handleSelectedRow} = props;

    return (
        <>
            <CreatePromotionForm handleIsEdited={handleIsEdited} selectedRow={selectedRow} handleSelectedRow={handleSelectedRow} />
        </>
    );
};

export default Createpromotion;
