import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: ""
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const preview = createSlice({
    name: "preview",
    initialState,
    reducers: {
        storeDataString(state, action) {
            state.data = action.payload;
        }
    }
});

export default preview.reducer;

export const {storeDataString} = preview.actions;
