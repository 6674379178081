import { Grid, Box, Typography, Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import AnimateButton from "ui-component/extended/AnimateButton";
import { API, NetworkManager } from "network/core";
import { getHHMMWithTimeString, getformateDate, getFormattedTimeinAMPM } from "utils/app-dates/dates";

import { FormatCurrency } from "utils/helperFunctions/helpers";
import { dispatch } from "store";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { openSnackbar } from "store/slices/snackbar";
import Loader from "ui-component/Loader";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const UnassignDeliveriedList = ({ driversData, delivery, handleIsUpdated }) => {
    const [selectedValue, setSelectedValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [secondaryDriverList, setSeondaryDriverList] = useState(null);
    const [selectedSecondaryDriver, setSelectedSecondaryDriver] = useState([]);
    const [showSecondary, setShowSecondary] = useState(false)


    useEffect(() => {
        if (selectedValue === "" && driversData?.length > 0) {
            setSeondaryDriverList(driversData?.map((el) => ({
                label: `${el?.name}`,
                id: el?.id
            })))
        }
        else if (driversData) {
            const tempDriverArr = driversData.filter((item) => item.id !== selectedValue)
            if (tempDriverArr?.length) {

                setSeondaryDriverList(tempDriverArr?.map((el) => ({
                    label: `${el?.name}`,
                    id: el?.id
                })))
            }
            else {
                setSeondaryDriverList([])
            }
            // if secondary driver list selected and primary driver updated
            // then remove the secondary driver if its selected at primary

            const tempArr = [...selectedSecondaryDriver]
            setSelectedSecondaryDriver([...tempArr.filter((item) => item !== selectedValue)])

        }


    }, [selectedValue, driversData])


    const handleAssignDriver = async () => {
        setLoading(true);
        const instance = NetworkManager(API.DRIVER.ASSIGN);
        const payload = {
            orderId: delivery?.order_id,
            carrierId: `${selectedValue}`
        };
        if (selectedValue) {
            const res = await instance.request(payload);
            if (res.success) {
                handleIsUpdated();
                setSelectedValue("");
                dispatch(
                    openSnackbar({
                        open: true,
                        message: res.message,
                        variant: "alert",
                        alert: {
                            color: "success"
                        },
                        close: false
                    })
                );
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: res.message,
                        variant: "alert",
                        alert: {
                            severity: "error",
                            color: "error"
                        },
                        close: false
                    })
                );
            }
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Please select a driver",
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }

        setLoading(false);
    };

    const handleAssignSecondaryDriver = async () => {
        if (selectedSecondaryDriver?.length) {
            const instance = NetworkManager(API.DRIVER.SECONDARYASSIGN);
            const response = await instance.request({
                orderId: delivery.order_id,
                carrierIds: selectedSecondaryDriver.map(item => `${item}`)
            });
        }
    };

    return (
        <Box
            sx={{
                minWidth: { xs: "100% !important" },
                maxWidth: { xs: "100% !important" },
                border: "1px solid #E0E2E6",
                borderRadius: "4px",
                background: "#fff",
                padding: "26px 30px",
                marginTop: "14px",
                "@media (min-width: 1536px)": {
                    minWidth: "778px !important",
                    maxWidth: "778px !important"
                }
            }}
        >
            {loading && <Loader />}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Grid item xs={10}>
                    <Box>
                        <Typography sx={{ fontSize: "1.18rem", fontWeight: 600, color: "#020A11" }}>
                            Order
                            <Link style={{ textDecoration: "none" }} to={`/orders/${delivery.order_id}`}>
                                {" "}
                                <span style={{ color: "#00ab3a" }}>#{delivery.orderNumber}</span>
                            </Link>
                            &nbsp;&nbsp; <span style={{ color: "#26272A", opacity: 0.3032 }}> | </span> &nbsp;&nbsp;
                            <span>
                                {delivery.name}{" "}

                            </span>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} sx={{ textAlign: "right" }}>
                    <Box>
                        <Typography sx={{ fontSize: "1.063rem", fontWeight: 600 }}>
                            {/* {getHHMMWithTimeString(delivery.time).toLowerCase().split(" ").join("")} */}
                            {getFormattedTimeinAMPM(delivery.date).toLowerCase().split(" ").join("")}
                        </Typography>
                    </Box>
                </Grid>
            </Box>
            <Grid container sx={{ marginTop: "18px" }}>
                <Grid item xs={7.5} sx={{ borderRight: "1px solid rgba(38, 39, 42, 0.32)" }}>
                    <span style={{ fontSize: "0.875rem", fontWeight: 500, opacity: 0.4501 }}>{delivery.address}</span>
                    <Typography sx={{ fontSize: "0.938rem", fontWeight: 600, color: "#000", marginTop: "4px" }}>{delivery.customerName}</Typography>
                    <Typography sx={{ fontSize: "0.938rem", fontWeight: 400, marginTop: "4px" }}>{delivery.customerAddress}</Typography>
                    <Typography sx={{ fontSize: "0.75rem", fontWeight: 600, marginTop: "15px" }} />
                    <Typography sx={{ fontSize: "0.938rem", fontWeight: 400, marginTop: "4px" }}>{FormatCurrency(delivery.total)}</Typography>
                </Grid>
                <Grid item xs={4.5} sx={{ paddingLeft: "24px" }}>
                    {WithPermission({ module: "Orders", permission: ["update_permission"] }) && (
                        <Box>
                            <CustomSelect
                                label="Select driver"
                                labelProps={{ sx: { top: selectedValue === "" ? "-6px" : "0px", "&.Mui-focused": { top: "0px" } } }}
                                menuItems={driversData?.map((el) => ({
                                    label: `${el?.name}`,
                                    id: el?.id
                                }))}
                                noNone
                                onChange={(e) => {
                                    const id = e.target.value;
                                    setSelectedValue(id);
                                }}
                                height="40px"
                                sx={{
                                    maxWidth: "256px",
                                    height: "40px",
                                    fontSize: "0.938rem",
                                    fontWeight: 400,
                                    color: "#25272B",
                                    "& .MuiSelect-select": {
                                        padding: "9px 14px !important"
                                    }
                                }}
                                value={selectedValue}
                            />
                            {showSecondary ? <Stack
                                mb={1}
                                mt={1}

                            ><CustomSelectWithCheckbox

                                    selectAll
                                    height="40px"
                                    sx={{
                                        maxWidth: "256px",
                                        height: "40px",
                                        fontSize: "0.938rem",
                                        fontWeight: 400,
                                        color: "#25272B",
                                        "& .MuiSelect-select": {
                                            padding: "9px 14px !important"
                                        }
                                    }}
                                    label="Add more driver(s)"
                                    menuItems={secondaryDriverList}
                                    values={selectedSecondaryDriver}
                                    labelProps={{ sx: { top: selectedSecondaryDriver.length ? '0px' : "-6px", "&.Mui-focused": { top: "0px" } } }}
                                    onChange={(val) => {
                                        setSelectedSecondaryDriver(val)

                                    }}
                                /></Stack> :
                                <Stack
                                    mb={1}
                                    mt={1}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}
                                >
                                    <Stack>
                                        <AddCircleIcon
                                            onClick={() =>
                                                setShowSecondary(pre => !pre)
                                            }
                                            sx={{
                                                fontSize: "1.125rem",
                                                color: "#00ab3a",
                                                cursor: "pointer"
                                            }}
                                        />
                                    </Stack>
                                    <Stack ml={0.5} >
                                        <Typography

                                            sx={{ color: "#00ab3a", cursor: "pointer", fontWeight: 600 }}
                                            onClick={() =>
                                                setShowSecondary(pre => !pre)
                                            }
                                        >
                                            Add more driver(s)
                                        </Typography>
                                    </Stack>
                                </Stack>}
                            <Box >
                                <AnimateButton>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        size="large"
                                        sx={{
                                            fontSize: "0.875rem",
                                            fontStyle: "normal",
                                            fontWeight: 500,
                                            bgcolor: "#00ab3a",
                                            height:'35px',

                                            minWidth: "170px",
                                            color: "#FFF",
                                            ":hover": {
                                                bgcolor: "#00ab3a"
                                            }
                                        }}
                                        onClick={() => {
                                            handleAssignDriver()
                                            handleAssignSecondaryDriver()
                                        }}
                                    >
                                        Assign driver
                                    </Button>
                                </AnimateButton>
                            </Box>

                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

function UnAssignedDeliveries({ formik, driversData, isUpdated, handleIsUpdated }) {
    const [page, setPage] = useState(0);
    const [unassigenedDeliveryData, setUnassignedDeliveryData] = useState([]);
    const [expanded, setExpanded] = React.useState("panel1");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const navigation = useNavigate();

    const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
        border: `1px solid #fff`,

        "&:not(:last-child)": {
            borderBottom: 0
        },
        "&:before": {
            display: "none"
        }
    }));

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary expandIcon={<KeyboardArrowDownIcon sx={{ fontSize: "2rem", color: "#000000" }} />} {...props} />
    ))(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
        flexDirection: "row-reverse",
        minHeight: "5px",
        height: "2rem",
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(180deg)"
        },
        "& .MuiAccordionSummary-content": {
            marginLeft: theme.spacing(1.0)
        }
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(1.5),
        marginTop: "-0.6rem",
        marginLeft: "34px"
    }));

    const { values } = formik;
    const market = values.market;
    const filterDate = values.date;

    const getUnassignedDeliveries = async () => {
        const instance = NetworkManager(API.DELIVERIES.UNASSIGNDRIVERLIST);
        const params = {
            market_id: market,
            date: getformateDate(filterDate, "yyyy-mm-dd")
        };
        const res = await instance.request({}, params);

        if (res.success) {
            const fetchedData = res.data;

            const formatFetchedData = fetchedData.map((el) => ({
                order_id: el.order_id,
                orderNumber: el.order_number,
                name: el.brand?.brand_name,
                address: el?.kitchen_location?.address,
                date: el?.delivery_date,
                time: el?.delivery_time,
                customerName: el?.customer?.company?.company_name || el?.customer?.full_name,
                customerAddress: el.delivery_address,
                total: el?.total_price
            }));
            setUnassignedDeliveryData(formatFetchedData);
        }
    };

    useEffect(() => {
        if (market && filterDate) {
            getUnassignedDeliveries();
        }
    }, [market, filterDate, isUpdated]);

    return (
        <>
            <Box>
                <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        sx={{
                            backgroundColor: "#fff",
                            width: "209px",
                            "&": {
                                ".MuiAccordionSummary-content": {
                                    alignItems: "center",
                                    marginLeft: 0
                                }
                            }
                        }}
                    >
                        <Typography color="#000A12" sx={{ fontSize: "1.25rem", marginTop: "12.6px", fontWeight: 700, marginBottom: "14px" }}>
                            Unassigned ({unassigenedDeliveryData.length}){" "}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div
                            style={{
                                marginLeft: "-25px",

                                minWidth: { xs: "100% !important", lg: "70%" },
                                maxWidth: { xs: "100% !important", lg: "70%" },
                                "@media (min-width: 1536px)": {
                                    minWidth: "777px !important",
                                    maxWidth: "790px !important"
                                }
                            }}
                            className="custom-scrollbar"
                        >
                            {unassigenedDeliveryData.map((delivery, index) => (
                                <Grid item xs={12} key={index}>
                                    <UnassignDeliveriedList
                                        handleIsUpdated={handleIsUpdated}
                                        driversData={driversData}
                                        delivery={delivery}
                                    />
                                </Grid>
                            ))}
                            {unassigenedDeliveryData?.length === 0 && (
                                <Box p={1}>
                                    <Typography sx={{ fontSize: "0.75rem", fontWeight: 600, color: "#000" }}>No data available</Typography>
                                </Box>
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
}

export default UnAssignedDeliveries;
