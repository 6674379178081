import React, {useState} from "react";
import Tooltip from "commonComponent/Tooltip";
import Typography from "@mui/material/Typography";
import {useStyles} from "./AppTableStyles";

function getUniqueCategories(data) {
    const uniqueCategoriesSet = new Set();
    data.forEach((item) => {
        item.menuCategory?.filter((menu)=>menu.menu_id).forEach((menu) => {
            uniqueCategoriesSet.add(menu.category_name);
        });
    });

    // Convert Set to Array if needed
    const uniqueCategoriesArray = Array.from(uniqueCategoriesSet);

    return uniqueCategoriesArray;
}

const CategoryDisplay = ({menuData}) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const menuCategoryItems = getUniqueCategories(menuData.menucategoryitem);
    const styles = useStyles();

    const count = menuCategoryItems.length;

    // Function to extract category names from nested structure
    const extractCategoryNames = (menuCategory) => {
        return menuCategory.menuCategory?.filter((menu)=>menu.menu_id).map((menu) => menu.category_name);
    };

    // Display the first 2 category names for each menu category
    const initialCategoryNames = menuCategoryItems.slice(0, 2).join(", ");

    // Display "View more" button and tooltip
    const tooltipContent = menuCategoryItems.slice(2).join(", ");

    return (
        <Typography variant="body3" sx={styles?.categoryDisplay?.mainTypo}>
            <div>
                {initialCategoryNames}

                {tooltipContent && (
                    <Tooltip
                        title={
                            <Typography variant="body3" sx={styles?.categoryDisplay?.mainTypo}>
                                {tooltipContent}
                            </Typography>
                        }
                        arrow
                        followCursor
                        open={isTooltipOpen}
                        onOpen={() => setIsTooltipOpen(true)}
                        onClose={() => setIsTooltipOpen(false)}
                    >
                        <small style={styles.categoryDisplay.small}>&nbsp;+{count - 2} more</small>
                    </Tooltip>
                )}
            </div>
        </Typography>
    );
};

export default CategoryDisplay;
