import * as React from "react";
import Box from "@mui/material/Box";

import FormControl from "@mui/material/FormControl";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function CustomSelect(props) {
    const {label, menuItems = [], onClick, onBlur = () => {}, ...restprops} = props;
    return (
        <Box sx={{minWidth: 350}}>
            <FormControl fullWidth onClick={onClick} onBlur={onBlur}>
                {/* <Select
                    {...restprops}
                    IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={label}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {menuItems.map((obj, id) => (
                        <MenuItem key={id} value={obj.id}>
                            {obj.label}
                        </MenuItem>
                    ))}
                </Select> */}
                <Autocomplete
                    {...restprops}
                    id="free-solo-dialog-demo"
                    freeSolo
                    onBlur={onBlur}
                    options={menuItems}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label={label} />}
                />
            </FormControl>
        </Box>
    );
}
