import { Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, Typography } from "@mui/material";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import { ErrorMessage } from "formik";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { enforceFormat, enforceFormatFor1Digits, formatToPhone } from "utils/helperFunctions/helpers";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Checked from "assets/images/icons/lightCheckedBox.svg";
import Unchecked from "assets/images/icons/unchecked.svg";

const LocationDetails = (props) => {
    const { formik, controller, marketName, selectedAddress } = props;
    const { getFieldProps, values, setFieldValue } = formik;
    const [pincode, setPincode] = useState(selectedAddress?.address_details?.pincode ? selectedAddress?.address_details?.pincode : "");
    const [city, setCity] = useState(selectedAddress?.address_details?.city ? selectedAddress?.address_details?.city : "");
    const [state, setState] = useState(selectedAddress?.address_details?.state ? selectedAddress?.address_details?.state : "");
    const [street, setStreet] = useState(
        selectedAddress?.address_details?.street_line ? selectedAddress?.address_details?.street_line : ""
    );
    const [country, setCountry] = useState(selectedAddress?.address_details?.country ? selectedAddress?.address_details?.country : "");

    const { locationDetails } = values;
    const { readOnly } = controller;

    const handleCheckox = (type, index) => {
        switch (type) {
            case "services":
                return index === 0 ? locationDetails.services[0] === "Catering" : locationDetails.services[1] === "Home meals";

            case "orderType":
                return index === 0 ? locationDetails.orderType[0] === "Delivery" : locationDetails.orderType[1] === "Pickup";

            default:
                return false;
        }
    };

    const parseAddressComponents = async (addressComponents) => {
        if (addressComponents) {
            let newPincode = "";
            let newCity = "";
            let newState = "";
            let newStreetLine = "";
            let newCountry="";
            for (let i = 0; i < addressComponents.length; i+=1) {
                const component = addressComponents[i];
                if (component.types.includes("postal_code")) {
                    
                    newPincode = component.long_name;
                } else if (component.types.includes("locality") ) {
                    
                    newCity = component.short_name;
                }
                else if (component.types.includes("administrative_area_level_2")) {
                    
                    newState += component.short_name + " ";// eslint-disable-line prefer-template
                }
                 else if (component.types.includes("administrative_area_level_1")) {
                    
                    newState += component.short_name;
                }
                else if (component.types.includes("premise")) {
                    
                    newStreetLine += component.short_name +", ";// eslint-disable-line prefer-template
                }
                else if (component.types.includes("subpremise")) {
                    
                    newStreetLine += component.short_name +", ";// eslint-disable-line prefer-template
                } else if (component.types.includes("route")) {
                    
                    newStreetLine += component.short_name;
                } else if (component.types.includes("street_number")) {
                    
                    newStreetLine += component.short_name + " ";// eslint-disable-line prefer-template
                } else if (component.types.includes("point_of_interest")) {
                    
                    newStreetLine += component.short_name + ", ";// eslint-disable-line prefer-template
                }
                else if(component.types.includes("country")){
                    newCountry = component.short_name;
                }
            }
            setPincode(newPincode);
            setCity(newCity);
            setState(newState);
            setStreet(newStreetLine);
            setCountry(newCountry)
            return {
                street_line: newStreetLine,
                city: newCity,
                state: newState,
                pincode: newPincode,
                country:newCountry
            }
        }
        return {
            street_line: "",
            city: '',
            state: '',
            pincode: '',
            country:""
        }
    };

    const handleSelect = async (value) => {
        const result = await geocodeByAddress(value);
        const addressDetails = await parseAddressComponents(result[0]?.address_components);
        const ll = await getLatLng(result[0]);

        setTimeout(() => {
            const addressWithoutCountry = result[0].formatted_address.replace(/,\sUSA$/, '');
            formik.setFieldValue(`locationDetails.address`, addressWithoutCountry);
            formik.setFieldValue(`locationDetails.latitude`, ll.lat);
            formik.setFieldValue(`locationDetails.longitude`, ll.lng);
            formik.setFieldValue(`locationDetails.address_details`, addressDetails)
        }, 100)


    };

    const onError = (status, clearSuggestions) => {
        clearSuggestions();
    };

    return (
        <>
            <Grid container rowSpacing={3}>
                <Grid item xs={12} container>
                    <Grid item lg={3.35} xs={12}>
                        <CustomTextField
                            disabled={readOnly}
                            fullWidth
                            placeholder="Location"
                            label="Location"
                            inputProps={{ maxLength: 50 }}
                            {...getFieldProps("locationDetails.name")}
                        />
                        <ErrorMessage name="locationDetails.name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={4}>
                    <Grid item lg={6} xs={12} container spacing={3}>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item lg={7} sm={6} xs={12}>
                                {window.google && (
                                    <PlacesAutocomplete
                                        value={formik.values.locationDetails.address}
                                        onChange={formik.handleChange(`locationDetails.address`)}
                                        searchOptions={{
                                            componentRestrictions: { country: "us" }
                                        }}
                                        onSelect={handleSelect}
                                        onError={onError}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div style={{ position: "relative" }}>
                                                <CustomTextField
                                                    fullWidth
                                                    label="Address"
                                                    value={formik.values.locationDetails.address}
                                                    {...getInputProps({
                                                        placeholder: "Address",
                                                        className: "location-search-input",
                                                        disabled: readOnly
                                                    })}
                                                    autoComplete='followmore'
                                                                id='falcon'
                                                                name={`random-name-${Math.random()}`}
                                                />
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        zIndex: 1000,
                                                        width: "100%",
                                                        boxShadow: "0px 0px 5px rgba(0,0,0,0.5)"
                                                    }}
                                                >
                                                    {loading && (
                                                        <div style={{ padding: "6px 12px", backgroundColor: "#ffffff" }}>Loading...</div>
                                                    )}
                                                    {suggestions.map((suggestion, index) => {
                                                        const style = suggestion.active
                                                            ? {
                                                                cursor: "pointer",
                                                                color: "#191919",
                                                                fontSize: "16px",
                                                                padding: "6px 16px",
                                                                backgroundColor: "#F5F7FB"
                                                            }
                                                            : {
                                                                backgroundColor: "#ffffff",
                                                                color: "#191919",
                                                                fontSize: "16px",
                                                                padding: "6px 16px",
                                                                cursor: "pointer"
                                                            };
                                                        return (
                                                            <div
                                                                key={index}
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    style
                                                                })}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                )}
                                <ErrorMessage name="locationDetails.address" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                            <Grid item lg={5} sm={6} xs={12}>
                                <CustomTextField
                                    disabled={readOnly}
                                    fullWidth
                                    placeholder="Phone"
                                    label="Phone"
                                    onKeyDown={enforceFormat}
                                    onKeyUp={formatToPhone}
                                    inputProps={{ maxLength: 14 }}
                                    // {...(formik.values.locationDetails.phone && {
                                    //     InputProps: {
                                    //         startAdornment: <InputAdornment position="start">+1</InputAdornment>
                                    //     }
                                    // })}
                                    {...getFieldProps("locationDetails.phone")}
                                />
                                <ErrorMessage name="locationDetails.phone" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container spacing={2}>
                            <Grid item lg={3} md={4} xs={12}>
                                <CustomSelect
                                    disabled={marketName || readOnly}
                                    label="Market"
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#212121",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                        }
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            onScroll: controller.loadMoremarkets,
                                            sx: {
                                                maxHeight: 200
                                            }
                                        }
                                    }}
                                    menuItems={controller.marketList.map((obj) => ({ label: obj.market_name, id: obj.market_id }))}
                                    {...getFieldProps("locationDetails.market")}
                                />
                                <ErrorMessage name="locationDetails.market" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                            <Grid item lg={4} md={4} xs={12}>
                                <CustomSelect
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: "#212121"
                                        }
                                    }}
                                    disabled={readOnly}
                                    label="Location type"
                                    menuItems={[
                                        { label: "Internal", id: "Internal" },
                                        { label: "Partner", id: "External" }
                                    ]}
                                    {...getFieldProps("locationDetails.locationType")}
                                />
                                <ErrorMessage
                                    name="locationDetails.locationType"
                                    render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                />
                            </Grid>
                            <Grid item lg={5} md={4} xs={12}>
                                <CustomTextField
                                    disabled={readOnly}
                                    fullWidth
                                    placeholder="%"
                                    label="Revenue share"
                                    inputProps={{ maxLength: 5 }}
                                    onKeyDown={enforceFormat}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start" sx={{ color: "#212121" }}>
                                                %
                                            </InputAdornment>
                                        )
                                    }}
                                    {...getFieldProps("locationDetails.revenueShare")}
                                    // onChange={(e)=>{
                                    //     formik.setFieldValue
                                    // }}
                                    onChange={(e) => {
                                        if (enforceFormatFor1Digits(e)) {
                                            const val = formik.values.locationDetails;
                                            val.revenueShare = e.target.value;
                                            formik.setFieldValue("locationDetails", val);
                                        }
                                    }}
                                />
                                <ErrorMessage
                                    name="locationDetails.revenueShare"
                                    render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} xs={12} container>
                        <Grid item lg={3} md={5} xs={6}>
                            <Typography variant="checkBoxSelected" fontSize="12px">
                                Services
                            </Typography>
                            <FormGroup>
                                <FormControlLabel
                                    sx={{ height: "28px" }}
                                    disabled={readOnly}
                                    required
                                    checked={handleCheckox("services", 0)}
                                    control={
                                        <Checkbox
                                            disableRipple
                                            icon={<img src={Unchecked} style={{ width: "18px", height: "18px" }} alt="checked" />}
                                            checkedIcon={<img src={Checked} alt="checkbox" style={{ width: "18px", height: "18px" }} />}
                                        />
                                    }
                                    label={
                                        <Typography variant={handleCheckox("services", 0) ? "checkBoxSelected" : "checkBoxUnSelected"}>
                                            Catering
                                        </Typography>
                                    }
                                    onChange={(e) => setFieldValue("locationDetails.services[0]", e.target.checked ? "Catering" : null)}
                                />
                                <FormControlLabel
                                    sx={{ height: "28px", minWidth: '125px' }}
                                    disabled={readOnly}
                                    checked={handleCheckox("services", 1)}
                                    control={
                                        <Checkbox
                                            disableRipple
                                            icon={<img src={Unchecked} style={{ width: "18px", height: "18px" }} alt="checked" />}
                                            checkedIcon={<img src={Checked} alt="checkbox" style={{ width: "18px", height: "18px" }} />}
                                        />
                                    }
                                    label={
                                        <Typography variant={handleCheckox("services", 1) ? "checkBoxSelected" : "checkBoxUnSelected"}>
                                            Home meals
                                        </Typography>
                                    }
                                    onChange={(e) => setFieldValue("locationDetails.services[1]", e.target.checked ? "Home meals" : null)}
                                />
                            </FormGroup>
                            <ErrorMessage name="locationDetails.services" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>
                        <Grid item lg={3} md={5} xs={6}>
                            <Typography variant="checkBoxSelected" fontSize="12px">
                                Order type
                            </Typography>
                            <FormGroup>
                                <FormControlLabel
                                    sx={{ height: "28px" }}
                                    disabled={readOnly}
                                    required
                                    checked={handleCheckox("orderType", 0)}
                                    control={
                                        <Checkbox
                                            disableRipple
                                            icon={<img src={Unchecked} style={{ width: "18px", height: "18px" }} alt="checked" />}
                                            checkedIcon={<img src={Checked} alt="checkbox" style={{ width: "18px", height: "18px" }} />}
                                        />
                                    }
                                    label={
                                        <Typography variant={handleCheckox("orderType", 0) ? "checkBoxSelected" : "checkBoxUnSelected"}>
                                            Delivery
                                        </Typography>
                                    }
                                    onChange={(e) => setFieldValue("locationDetails.orderType[0]", e.target.checked ? "Delivery" : null)}
                                />
                                <FormControlLabel
                                    sx={{ height: "28px" }}
                                    disabled={readOnly}
                                    checked={handleCheckox("orderType", 1)}
                                    control={
                                        <Checkbox
                                            disableRipple
                                            icon={<img src={Unchecked} style={{ width: "18px", height: "18px" }} alt="checked" />}
                                            checkedIcon={<img src={Checked} alt="checkbox" style={{ width: "18px", height: "18px" }} />}
                                        />
                                    }
                                    label={
                                        <Typography variant={handleCheckox("orderType", 1) ? "checkBoxSelected" : "checkBoxUnSelected"}>
                                            Pickup
                                        </Typography>
                                    }
                                    onChange={(e) => setFieldValue("locationDetails.orderType[1]", e.target.checked ? "Pickup" : null)}
                                />
                            </FormGroup>
                            <ErrorMessage name="locationDetails.orderType" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

LocationDetails.propTypes = {
    formik: PropTypes.object
};

export default LocationDetails;
