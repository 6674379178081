const LAYOUT_CONST = {
    VERTICAL_LAYOUT: "vertical",
    HORIZONTAL_LAYOUT: "horizontal",
    DEFAULT_DRAWER: "default",
    MINI_DRAWER: "mini-drawer"
};

export const NAVIGATION_CONST = [
    {
        route: "/",
        title: ""
    },
    {
        route: "/markets",
        title: "Markets"
    },
    {
        route: "/locations",
        title: "Locations"
    },
    {
        route: "/marketLocations/:id",
        title: "Markets"
    },
    {
        route: "/locationDetail/:id",
        title: "Locations"
    },
    {
        route: "/reports/orderlist",
        title: "Reports"
    },
    {
        route: "/locations/:type/:id?",
        title: "Locations"
    },
    {
        route: "/customers",
        title: "Customers"
    },
    {
        route: "/customers/:type/:customersid?",
        title: "Customers"
    },
    {
        route: "/customer/detail",
        title: "Customers"
    },
    {
        route: "/promotions",
        title: "Promotions"
    },
    {
        route: "/brands",
        title: "Brands"
    },
    {
        route: "/menus",
        title: "Menu"
    },
    {
        route: "/menus/:type/:menuid?",
        title: "Menu"
    },
    {
        route: "/items",
        title: "Items"
    },
    {
        route: "/items/:type/:id?",
        title: "Items"
    },
    {
        route: "/menus-list",
        title: "Menu"
    },
    {
        route: "/modifiers-list",
        title: "Modifiers"
    },
    {
        route: "/brand-detail/:id?",
        title: "Brand"
    },
    {
        route: "/reports",
        title: "Reports"
    },
    {
        route: "/delivery",
        title: "Deliveries"
    },

    {
        route: "/delivery?tab=drivers",
        title: "Drivers"
    },
    {
        route: "/menus?tab=templates",
        title: "Templates"
    },
    {
        route: "/menus?tab=categories",
        title: "Categories"
    },

    {
        route: "/reviews",
        title: "Reviews"
    },
    {
        route: "/orders",
        title: "Orders"
    },
    {
        route: "/orders/:id",
        title: "Orders"
    },
    {
        route: "/settings",
        title: "Account"
    },

    {
        route: "/setting/role?",
        title: "Roles"
    },
    {
        route: "/setting/user?",
        title: "Users"
    },
    {
        route: "/quote/detail",
        title: "Orders"
    },
    {
        route: "/print",
        title: "Orders"
    },
    {
        route: "/services",
        title: "Services"
    }
];

export default LAYOUT_CONST;
