import PropTypes from "prop-types";

// material-ui
import {useTheme} from "@mui/material/styles";
import {Avatar, Box, useMediaQuery} from "@mui/material";

// project imports
import LAYOUT_CONST from "constant";
import useConfig from "hooks/useConfig";
import LogoSection from "../LogoSection";
import SearchSection from "./SearchSection";
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";
import NotificationSection from "./NotificationSection";
import {useSelector} from "store";

// assets
import {IconMenu2} from "@tabler/icons";
import Hamburger from "assets/images/icons/hamburger.svg";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({handleDrawerToggle}) => {
    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
    const {layout} = useConfig();
    const {drawerOpen} = useSelector((state) => state.menu);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: drawerOpen ? 198 : "auto",
                    display: "flex",
                    bgcolor: "transparent",
                    alignItems: "center",
                    [theme.breakpoints.down("md")]: {
                        width: "auto"
                    }
                }}
            >
                <Box
                    component="span"
                    sx={{
                        display: {xs: "none", md: drawerOpen ? "block" : "none"},
                        padding: "8px",
                        paddingLeft: "0px",
                        transition: "all .2s ease-in-out",
                        flexGrow: 1,
                        zIndex: 12300
                    }}
                >
                    <LogoSection />
                </Box>

                {layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: "hidden",
                            transition: "all .2s ease-in-out",
                            background: theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === "dark" ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            "&:hover": {
                                // background: theme.palette.mode === "dark" ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            },
                            borderRadius: "4px !important"
                        }}
                        onClick={handleDrawerToggle}
                        color="inherit"
                    >
                        <img src={Hamburger}  alt="hamburger" stroke={1.5} size="20px" />
                    </Avatar>
                ) : null}
            </Box>

            {/* header search */}
            {/* <SearchSection />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} /> */}

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            {/* <ProfileSection /> */}

            {/* mobile header */}
            {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box> */}
        </>
    );
};

Header.propTypes = {
    handleDrawerToggle: PropTypes.func
};

export default Header;
