import { Box, Grid, Stack, Typography } from "@mui/material";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSwitch from "commonComponent/Form/Fields/CustomSwitch";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import DropDown from "commonComponent/Form/Fields/DropDown";
import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import useProfileController from "./profileForm.controller";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import { useNavigate } from "react-router-dom";
import ImageCropper from "commonComponent/ImageCropper/imageCropper";
import { brandDetailProfileTab } from "utils/validations/validations";
import Loader from "ui-component/Loader";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";

const ProfileForm = (props) => {
    const { isEdit } = props;
    const PC = useProfileController(props);

    const navigation = useNavigate();

    const handleCancel = (formik) => {
        formik.resetForm();
        navigation("/menus?tab=brand");
    };

    return (
        <>
            {PC.loading && <Loader />}
            <Formik
                enableReinitialize
                initialValues={PC.initialValues}
                validationSchema={brandDetailProfileTab}
                onSubmit={(val, { setSubmitting }) => {
                    PC.handleSubmitProfileData(val, setSubmitting);
                }}
            >
                {(formik) => (
                    <Form>
                        {/* {!isEdit && (
                            <Grid container sx={{mb: 1}}>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <CustomSwitch
                                            checked={isEdit}
                                            onChange={(e) => {
                                                setIsEdit((pre) => !pre);
                                            }}
                                        />
                                        <Typography>Enable Editing</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        )} */}

                        <Grid container spacing={2} mb={2} flexWrap="wrap-reverse">
                            <Grid item md={6} sm={12}>
                                <Grid container spacing={2} mt={0.6}>
                                    <Grid item xs={12} container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <CustomTextField
                                                disabled={!isEdit}
                                                fullWidth
                                                placeholder="Brand"
                                                label="Brand"
                                                inputProps={{ maxLength: 250 }}
                                                {...formik.getFieldProps("brandName")}
                                            />
                                            <ErrorMessage name="brandName" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <CustomTextField
                                                disabled={!isEdit}
                                                fullWidth
                                                placeholder="Abbreviation"
                                                label="Abbreviation"
                                                inputProps={{ maxLength: 50 }}
                                                {...formik.getFieldProps("abbreviation")}
                                            />
                                            <ErrorMessage name="abbreviation" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <CustomTextField
                                                disabled={!isEdit}
                                                fullWidth
                                                placeholder="Owner"
                                                label="Owner"
                                                inputProps={{ maxLength: 50 }}
                                                {...formik.getFieldProps("owner")}
                                            />
                                            {/* <CustomSelect
                                                disabled={!isEdit}
                                                label="Owner"
                                                menuItems={[{id: "Bitetime", label: "Bitetime"}]}
                                                {...formik.getFieldProps("owner")}
                                            /> */}
                                            <ErrorMessage name="owner" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <CustomTextField
                                                disabled={!isEdit}
                                                fullWidth
                                                placeholder="Cuisine"
                                                label="Cuisine"
                                                inputProps={{ maxLength: 250 }}
                                                {...formik.getFieldProps("cuisine")}
                                            />
                                            <ErrorMessage name="cuisine" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container spacing={2}>
                                        <Grid item xs={12}>
                                            <CustomSelectWithCheckbox
                                                disabled={!isEdit}
                                                label="Menus"
                                                selectAll
                                                menuItems={PC.menus}
                                                values={formik.values.menus}
                                                onChange={(val) => formik.setFieldValue("menus", [...val])}
                                            />
                                            <ErrorMessage name="menus" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>



                                        <Grid item xs={12}>
                                            <CustomSelectWithCheckbox
                                                disabled={!isEdit}
                                                label="Services"
                                                selectAll
                                                menuItems={[
                                                    { label: "Catering", id: "CATERING" },
                                                    { label: "Home meals", id: "HOME_MEALS" }
                                                ]}
                                                values={formik.values.brandServices}
                                                onChange={(val) => {
                                                    formik.setFieldValue("brandServices", [...val]);
                                                }}
                                            />
                                            <ErrorMessage
                                                name="brandServices"
                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <CustomSelectWithCheckbox
                                                disabled={!isEdit}
                                                label="Locations"
                                                selectAll
                                                menuItems={PC.locations.map((el) => ({ label: el.name, id: el.kitchen_location_id }))}
                                                values={formik.values.locations}
                                                onChange={(val) => formik.setFieldValue("locations", [...val])}
                                            />
                                            <ErrorMessage name="locations" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomSelect
                                                disabled={!isEdit || !formik.values?.locations?.length}
                                                label="Default location"
                                                menuItems={PC.locations
                                                    .filter((el) => formik.values?.locations?.includes(el.kitchen_location_id))
                                                    .map((el) => ({ label: el.name, id: el.kitchen_location_id }))}
                                                {...formik.getFieldProps("defaultLocation")}
                                            />
                                            <ErrorMessage
                                                name="defaultLocation"
                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                            />
                                        </Grid>
                                        {(formik.values.photos?.length || isEdit) && (
                                            <Grid item xs={12} mb={5}>
                                                <Box
                                                    sx={{
                                                        borderRadius: "4px",
                                                        border: "1px solid #bdbdbd",
                                                        p: 2,
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <ImageCropper
                                                        title="Upload brand logo"

                                                        image={formik.values.photos}
                                                        isEdit={isEdit}
                                                        minSize={1000}
                                                        aspectWidth={15}
                                                        aspectHeight={5}
                                                        multiPhoto={false}
                                                        handleChange={(list) => {
                                                            formik.setFieldValue(`photos`, list);
                                                        }}
                                                        handleError={(msg = "Minimum 1KB file size is required") => {
                                                            formik.setTouched({ photos: true });
                                                            setTimeout(() => formik.setErrors({ photos: msg }), 100);
                                                        }}
                                                    />
                                                </Box>
                                                <ErrorMessage name="photos" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6} sm={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box ml={1} />
                                            
                                        <Stack direction="row" spacing={1} mt={1} alignItems="center">
                                            <Typography
                                                fontSize="0.875rem"
                                                fontWeight={700}
                                                sx={{ color: formik.values.isActive ? "#A1A3A8" : "#F0475C" }}
                                            >
                                                Inactive
                                            </Typography>
                                            <CustomSwitch
                                                disabled={!isEdit}
                                                checked={formik.values.isActive}
                                                onChange={(e) => {
                                                    formik.setFieldValue("isActive", e.target.checked);
                                                }}
                                            />
                                            <Typography
                                                fontSize="0.875rem"
                                                fontWeight={700}
                                                sx={{ color: !formik.values.isActive ? "#A1A3A8" : "#00ab3a" }}
                                            >
                                                Active
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {isEdit && (
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ p: 3, pt: 1, width: "100%", position: "fixed", bottom: 0, right: 0, bgcolor: "#fff" }}
                                >
                                    <ButtonGrouping
                                        btnprops={[
                                            {
                                                btnTitle: "Cancel",
                                                sx: (t) => ({ color: t.palette.error.dark }),
                                                onClick: () => handleCancel(formik)
                                            },
                                            {
                                                btnTitle: "Save",
                                                variant: "contained",
                                                disabled: formik.isSubmitting,
                                                sx: (t) => ({ color: t.palette.background.paper }),
                                                onClick: formik.handleSubmit
                                            }
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ProfileForm;
