import {useTheme} from "@mui/system";

export const useStyles = (open) => {
    const theme = useTheme();
    const borderColor = open ? theme.palette.primary.main : theme.palette.other.fieldBorder;
    const borderWidth = open ? 2 : 1;
    return {
        paperProps: {
            "& .MuiPickersDay-root": {
                "& .Mui-selected": {
                    color: "white"
                },
                "& .Mui-selected:focus": {
                    backgroundColor: `${theme.palette.primary.main} !important`,
                    background: `${theme.palette.primary.main} !important`
                }
            },
            "& .MuiPickersDay-root:focus": {
                backgroundColor: `${theme.palette.primary.main}`,
                background: `${theme.palette.primary.main}`
            },
            "& .Mui-selected:focus": {
                backgroundColor: `${theme.palette.primary.main} !important`,
                background: `${theme.palette.primary.main} !important`
            },
            "& .MuiPickersDay-today": {
                "& .Mui-selected": {
                    color: "white"
                },
                "& .Mui-selected:focus": {
                    backgroundColor: `${theme.palette.primary.main} !important`,
                    background: `${theme.palette.primary.main} !important`
                }
            },
            "& .MuiPickersDay-root:not": {
                "& .Mui-selected": {
                    border: `1px solid ${theme.palette.grey?.[500]}`,
                    color: "white"
                }
            }
        },
        datePicker: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            border: `${borderWidth}px solid`,
            borderColor: borderColor,
            height: "42px",
            paddingLeft: "12px",
            borderRadius: "4px",
            cursor: "pointer"
        },
        textDropDown: {
            display: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative"
            },
            endAdornment: {
                borderLeft: `1px solid ${theme.palette.other.fieldBorder}`,
                height: "42px",
                width: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 1
            },
            menuItem: {pl: 1.4, height: "30px", borderRadius: "4px"}
        },
        datePickerText: {
            color: theme.palette.grey[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "14px",
            fontWeight: 400
        }
    };
};
