import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {ErrorMessage, Form, Formik} from "formik";
import React from "react";
import useAddRolesController from "./addRoles.controller";
import {rolesConfig} from "./roles.config";

const Heading = ({title}) => (
    <Typography variant="h3" color="#020A11" fontSize="1.125rem" fontWeight={600} mb={4} mt={4}>
        {title}
    </Typography>
);

const TableHeading = ({title}) => (
    <Typography sx={{textTransform: "capitalize",mt:1}} variant="h5" color="#020A11" fontSize="0.812rem" fontWeight={400}>
        {title}
    </Typography>
);

const ModuleHeading = ({title}) => (
    <Typography sx={{textTransform: "capitalize"}} variant="h5" color="#020A11" fontSize="0.937rem" fontWeight={600}>
        {title}
    </Typography>
);

const CustomButton = ({text, ...props}) => (
    <Box width={rolesConfig.buttonBoxWidth}>
        <Button fullWidth size="large" {...props}>
            {text}
        </Button>
    </Box>
);

const AddRolesComponent = (props) => {
    const rc = useAddRolesController(props);
    return (
        <>
            <Formik enableReinitialize initialValues={rc.initialState} validationSchema={rc.validation} onSubmit={rc.handleSave}>
                {(formik) => (
                    <Form>
                        <Grid container>
                            {/* ----- Basic Details ----- */}
                            <Grid item xs={12}>
                                <Heading title="Role details" />
                                <Stack spacing={3}>
                                    <Box width={rolesConfig.textInputWidth}>
                                        <TextField
                                            fullWidth
                                            id="role"
                                            name="role"
                                            label="Role name"
                                            variant="outlined"
                                            {...formik.getFieldProps("role")}
                                            // helperText={formik.touched.role && formik.errors.role}
                                        />
                                        <ErrorMessage name="role" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Box>
                                    <Box width={rolesConfig.textFieldBoxwidth}>
                                        <TextField
                                            fullWidth
                                            id="description"
                                            name="description"
                                            label="Description"
                                            variant="outlined"
                                            minRows={2}
                                            multiline
                                            {...formik.getFieldProps("description")}
                                        />
                                        <ErrorMessage name="description" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Box>
                                </Stack>
                            </Grid>

                            {/* ----- Permissions ----- */}
                            <Grid item xs={9}>
                                <Heading title="Permissions" />
                                {/* <Stack>
                                    <FormControlLabel
                                        label="Select all"
                                        onChange={() => rc.handleSelectAll(formik)}
                                        control={<Checkbox checked={formik?.values?.isSelectedAll} />}
                                    />
                                </Stack> */}
                                <TableContainer sx={{marginTop: "5px", marginLeft: "-7px"}}>
                                    <Table>
                                        {formik.values.modules?.map((ele, i) => (
                                            <React.Fragment key={ele}>
                                                {i === 0 && (
                                                    <>
                                                        <TableHead>
                                                            <TableRow sx={{borderBottom: "none", color: "red"}}>
                                                                <TableCell sx={{borderBottom: "none"}}>
                                                                    <ModuleHeading title="Module" />
                                                                </TableCell>
                                                                {Object.keys(formik.values.permission[ele]).map((k) => (
                                                                    <TableCell key={k} sx={{borderBottom: "none"}}>
                                                                        <ModuleHeading title={rc.tableheading[k]} />
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{borderBottom: "none", padding: "5px 8px !important"}} />

                                                            {/* {Object.keys(formik.values.permission[ele]).map((k) => (
                                                                <TableCell key={k} sx={{borderBottom: "none"}}>
                                                                    <Stack>
                                                                        <FormControlLabel
                                                                            label="Select all"
                                                                            onChange={() => rc.handleSelectAll(formik)}
                                                                            control={<Checkbox checked={formik?.values?.isSelectedAll} />}
                                                                        />
                                                                    </Stack>
                                                                </TableCell>
                                                            ))} */}
                                                            {/* For View */}
                                                            <TableCell sx={{borderBottom: "none"}}>
                                                                <Stack>
                                                                    <FormControlLabel
                                                                        label="Select all"
                                                                        onChange={() => rc.handleIsRead(formik)}
                                                                        control={<Checkbox checked={formik?.values?.isRead} />}
                                                                    />
                                                                </Stack>
                                                            </TableCell>
                                                            {/* For Add */}
                                                            <TableCell sx={{borderBottom: "none"}}>
                                                                <Stack>
                                                                    <FormControlLabel
                                                                        label="Select all"
                                                                        onChange={() => rc.handleIsCreate(formik)}
                                                                        control={<Checkbox checked={formik?.values?.isCreate} />}
                                                                    />
                                                                </Stack>
                                                            </TableCell>
                                                            {/* For Edit */}
                                                            <TableCell sx={{borderBottom: "none"}}>
                                                                <Stack>
                                                                    <FormControlLabel
                                                                        label="Select all"
                                                                        onChange={() => rc.handleIsEdit(formik)}
                                                                        control={<Checkbox checked={formik?.values?.isEdit} />}
                                                                    />
                                                                </Stack>
                                                            </TableCell>
                                                            {/* For Delete */}
                                                            <TableCell sx={{borderBottom: "none"}}>
                                                                <Stack>
                                                                    <FormControlLabel
                                                                        label="Select all"
                                                                        onChange={() => rc.handleIsUpdate(formik)}
                                                                        control={<Checkbox checked={formik?.values?.isUpdate} />}
                                                                    />
                                                                </Stack>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                )}

                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell sx={{borderBottom: "none", padding: "5px 8px !important"}}>
                                                            <TableHeading  title={ele} />
                                                        </TableCell>
                                                        {Object.keys(formik.values.permission[ele]).map((k) => (
                                                            <TableCell
                                                                key={k}
                                                                align="left"
                                                                sx={{borderBottom: "none", padding: "5px 8px !important"}}
                                                            >
                                                                <FormControlLabel
                                                                    onChange={() => rc.handleCheck(formik, ele, k)}
                                                                    control={<Checkbox sx={{marginLeft:'-5px'}} checked={formik.values.permission[ele][k]} />}
                                                                />
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableBody>
                                            </React.Fragment>
                                        ))}
                                    </Table>
                                </TableContainer>
                            </Grid>

                            {/* ----- Buttons ----- */}

                            <Grid item xs={12} sx={{mt: 4, mb: 3.5}}>
                                {(WithPermission({module: "Roles", permission: ["update_permission"]}) ||
                                    WithPermission({module: "Roles", permission: ["create_permission"]})) && (
                                    <Grid container spacing={2}>
                                        <Grid item sx={{ml: "auto"}}>
                                            <CustomButton
                                                disabled={formik.values.checkAtleastOneViewSelected === props?.modules?.length}
                                                text="Save"
                                                variant="contained"
                                                type="submit"
                                                sx={{background: "#00ab3a", color: "#FFFFFF"}}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddRolesComponent;
