import {useState} from "react";
import {useCustomerModel} from "./customers.model";
import {sortFunction} from "utils/helperFunctions/helpers";

export const useCustomerController = () => {
    const [customerList, setCustomerList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [customerEmail, setCustomerEmail] = useState([]);
    const model = useCustomerModel();

    const getCustomerListing = async (searchedValue) => {
        const tempData = [];
        if (searchedValue === "") {
            setCustomerList([]);
        } else {
            const query = [`?full_name=${searchedValue}`];
            const response = await model.getCustomerListing(query);
            if (response?.success) {
                response?.data?.customers.forEach((el) => {
                    tempData.push({id: el?.user_id, label: `${el?.full_name} ${el?.last_name}`});
                });
            }
            setCustomerList(sortFunction(tempData));
        }
    };
    const getCustomerEmail = async (searchedValue) => {
        const tempData = [];
        const query = [`?email=${searchedValue}`];
        if (!searchedValue) {
            setCustomerEmail([]);
        } else {
            if (searchedValue !== "") {
                const response = await model.getCustomerListing(query);
                if (response?.success) {
                    response?.data?.customers.forEach((el) => {
                        tempData.push({id: el?.user_id, label: el?.email});
                    });
                }
                setCustomerEmail(tempData);
            }
            if (searchedValue === "") {
                setCustomerEmail([]);
            }
        }
    };
    const getCompanyListing = async (searchedValue) => {
        if (searchedValue === "") {
            setCompanyList([]);
        }
        if (searchedValue) {
            const tempData = [];

            const response = await model.getCompanyListing(searchedValue);
            if (response?.success) {
                response?.data?.companyList.forEach((el) => {
                    tempData.push({id: el?.company_id, label: el?.company_name});
                });
            }
            setCompanyList(tempData);
        } else {
            setCompanyList([]);
        }
    };
    // const tabFilterState = (val) => {
    //     console.log(val,656565)
    //     if (val === "customer") {
    //         return
    //     } else {
    //         return [
    //             {
    //                 key: "company_name",
    //                 label: "Company name",
    //                 type: "select",
    //                 getData: companyList
    //             },
    //         ];
    //     }
    // };
    const filterState = [
        {
            key: "customer_name",
            label: "Customer name",
            type: "select",
            getData: customerList,
            freeSolo: true
        },
        {
            key: "company_name",
            label: "Company name",
            type: "select",
            freeSolo: true,
            getData: companyList
        },
        {
            key: "ltv",
            label: "LTV",
            type: "numberfield",
            filterType: "Equals",
            filterKey: "ltvFilter",
            getData: [
                {label: "Equals", value: "equal", id: "equal"},
                {label: "Greater than", value: "greater than", id: "_greater_than"},
                {label: "Less than", value: "less than", id: "_less_than"},
                {label: "Not equal", value: "not equal", id: "_not_equal"}
            ]
        },
        {
            key: "aov",
            label: "AOV",
            type: "numberfield",
            filterType: "Equals",
            filterKey: "aovFilter",
            getData: [
                {label: "Equals", value: "equal", id: "equal"},
                {label: "Greater than", value: "greater than", id: "_greater_than"},
                {label: "Less than", value: "less than", id: "_less_than"},
                {label: "Not equal", value: "not equal", id: "_not_equal"}
            ]
        },
        {
            key: "order_count",
            label: "Order count",
            type: "numberfield",
            filterType: "Equals",
            filterKey: "orderCountFilter",
            getData: [
                {label: "Equals", value: "equal", id: "equal"},
                {label: "Greater than", value: "greater than", id: "_greater_than"},
                {label: "Less than", value: "less than", id: "_less_than"},
                {label: "Not equal", value: "not equal", id: "_not_equal"}
            ]
        },
        {
            key: "email",
            label: "Email",
            type: "select",
            getData: customerEmail,
            freeSolo: true
        },
        // {
        //     key: "address",
        //     label: "Address",
        //     type: "textfield"
        // },
        {
            type: "rangePicker",
            key1: "start_date",
            key2: "end_date",
            startLabel: "Created date",
            endLabel: "Created to"
        },
        // // {
        //     key: "start_date",
        //     label: "Created from",
        //     type: "startDate"
        // },
        // {
        //     key: "end_date",
        //     label: "Created to",
        //     type: "startDate",
        //     minDate:"start_date"
        // },
        {
            type: "dateRange",
            key1: "last_order_start_date",
            key2: "last_order_end_date",
            startLabel: "Last order date",
            endLabel: "Last order to"
        }
    ];
    return {
        customerList,
        getCustomerListing,
        filterState,
        getCompanyListing,
        getCustomerEmail,
        setCustomerEmail
    };
};
