import {FormControl, Grid, IconButton, InputAdornment, InputLabel, Menu, OutlinedInput, Stack, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {LocalizationProvider, StaticDatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import React, {useEffect, useState} from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {formatDate} from "commonComponent/Apptable/dateUtils";
import {getFormattedTimeinAMPM} from "utils/app-dates/dates";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {openSnackbar} from "store/slices/snackbar";
import {useDispatch} from "react-redux";

const CustomNewDateTimePicker = (props) => {
    const {
        timepicker,
        datepicker,
        label,
        clearButton = false,
        handleClear = () => {},
        dateTitle = "Event date",
        timeTitle = "Event time",
        value,
        onChange,
        disabled,
        minValue = "",
        day = null,
        placeholder,
        differentAnchor = false,
        disablePast = false,
        maxDate = "",
        setDifferentAnchor = () => {},
        sx = {},
        deliveryDateRef,
        createdAt = ""
    } = props;
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState({
        hour: "",
        minutes: "",
        meridian: ""
    });

    const [errorMessage, setErrorMessage] = useState(""); // To manage error messages
    const dispatch = useDispatch();

    const [min, setMin] = useState(false);
    const [val, setVal] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(differentAnchor || null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (minValue) {
            const timeMeridian = getFormattedTimeinAMPM(minValue).split(" ");
            const time = timeMeridian[0].split(":");

            const hour = time[0] < 10 ? `0${time[0]}` : time[0];
            const minutes =
                parseInt(time[1].slice(0, -2), 10) < 10 ? `0${parseInt(time[1].slice(0, -2), 10)}` : parseInt(time[1].slice(0, -2), 10);
            const meridian = time[1].slice(-2).toLowerCase();

            setMin({
                hour,
                minutes,
                meridian
            });
        }
    }, [minValue]);

    useEffect(() => {
        if (value) {
            const timeMeridian = getFormattedTimeinAMPM(value).split(" ");
            const time = timeMeridian[0].split(":");

            const hour = value ? (time[0] < 10 ? `0${time[0]}` : time[0]) : "";
            const minutes = value ? time[1]?.substring(0, 2) : "";
            const meridian = value ? (time[1]?.substring(2, 4) === "pm" ? "pm" : "am") : "";

            const date = value ? new Date(value)?.toDateString() : null;

            setSelectedDate(value);

            setSelectedTime({
                hour,
                minutes,
                meridian
            });

            if (timepicker) {
                setVal(getFormattedTimeinAMPM(value));
            }
            if (datepicker) {
                setVal(formatDate(date));
            }
            if (!timepicker && !datepicker) {
                const timee = `${hour}:${minutes}${meridian}`;
                if (date) {
                    setVal(`${formatDate(date)} ${timee}`);
                } else {
                    setVal(null);
                }
            }
        }
    }, [value]);

    const handleClick = (event) => {
        setAnchorEl(event.target.parentElement.offsetParent);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setDifferentAnchor(null);
    };

    const updateClearance = () => {
        setSelectedTime({
            hour: "",
            minutes: "",
            meridian: ""
        });
        setVal(null);
        setSelectedDate(null);
        handleClear();
        handleClose();
    };

    const handleSave = () => {
        const {hour, minutes, meridian} = selectedTime;
        const date = selectedDate ? new Date(selectedDate)?.toDateString() : new Date()?.toDateString();
        const time = `${hour}:${minutes}:00 ${meridian}`;
        const finalDateTime = new Date(`${date} ${time}`);

        if (maxDate && new Date(finalDateTime) > new Date(maxDate)) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Selected time exceeds the maximum allowed date.",
                    variant: "alert",
                    alert: {severity: "error", color: "error"},
                    close: false
                })
            );
            return;
        }

        const currentTime = new Date();
        if (maxDate && new Date(finalDateTime) < currentTime) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Selected time cannot be earlier than the current time.",
                    variant: "alert",
                    alert: {severity: "error", color: "error"},
                    close: false
                })
            );
            return;
        }

        if (!timepicker && !datepicker && selectedDate && hour && minutes && meridian) {
            const finalDateTime = new Date(`${date} ${time}`);
            if (!timepicker && !datepicker) {
                onChange(finalDateTime);
            }
            handleClose();
        }

        if (timepicker && hour && minutes && meridian) {
            let finalDateTime;
            if (day) {
                finalDateTime = new Date(`${new Date(day)?.toDateString()} ${time}`);
            } else {
                finalDateTime = new Date(`${new Date()?.toDateString()} ${time}`);
            }

            if (new Date(minValue) > new Date(finalDateTime)) {
                return;
            }

            onChange(finalDateTime);

            if (deliveryDateRef?.current !== undefined) {
                deliveryDateRef.current = finalDateTime;
            }

            handleClose();
        }

        if (datepicker && selectedDate) {
            onChange(new Date(date));
            handleClose();
        }
    };

    const generateHoursOrMinute = (startFrom = 1, upto = 12) => {
        const UPTOLOOP = upto === 12 ? upto : upto - 5;
        const incrementBy = upto === 12 ? 1 : 5;
        const arr = [];

        for (let i = startFrom; i <= UPTOLOOP; i += incrementBy) {
            const data = i < 10 ? `0${i}` : `${i}`;
            arr.push({label: upto === 12 ? i : data, id: data});
        }

        return arr;
    };
    const checkForMin = (upto) => {
        const {meridian} = selectedTime;
        const isMinValueDate = minValue instanceof Date;
        const isSelectedDateMatch = selectedDate
            ? new Date(selectedDate).toDateString() === (isMinValueDate ? minValue.toDateString() : null)
            : null;

        if (!timepicker && min && minValue !== undefined && meridian === min?.meridian && isSelectedDateMatch) {
            if (upto === 12) return generateHoursOrMinute(Number(min.hour), upto);
            if (upto === 60) return generateHoursOrMinute(Number(min.minutes), upto);
        }
        if (timepicker && min && meridian === min.meridian) {
            if (upto === 12) return generateHoursOrMinute(Number(min.hour), upto);
            if (upto === 60) return generateHoursOrMinute(Number(min.minutes), upto);
        }
        if (upto === 12) return generateHoursOrMinute(1, upto);
        return generateHoursOrMinute(0, upto);
    };

    const customSelectStyle = {
        "&": {
            ".MuiOutlinedInput-input": {
                fontWeight: 600,
                fontSize: "16px",
                minWidth: "40px"
            }
        }
    };

    const handleMouseDownPassword = (event) => {
        // event.preventDefault();
        console.log("");
    };

    return (
        <div>
            {!differentAnchor && (
                <FormControl
                    fullWidth
                    disabled={disabled}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="outlined"
                    onClick={disabled ? null : handleClick}
                >
                    {label && <InputLabel htmlFor="outlined-adornment-picker">{label}</InputLabel>}
                    <OutlinedInput
                        placeholder={placeholder}
                        id="outlined-adornment-picker"
                        value={val || ""}
                        sx={sx}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    disabled={disabled}
                                    aria-label="handleClick"
                                    onClick={handleClick}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {timepicker ? <AccessTimeIcon /> : <CalendarTodayIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label={label}
                    />
                </FormControl>
            )}

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
            >
                <Box px={3} py={1} sx={{overflow: "auto"}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {!timepicker && (
                            <Box>
                                {!datepicker && (
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h4" fontWeight={700}>
                                            {dateTitle}
                                        </Typography>
                                        {/* <Typography variant="h4" fontWeight={600}>
                                            Time zone:{" "}
                                            {new Date(val).toLocaleString("us", {timeZoneName: "short"}).split(" ").slice(3).join(" ")}
                                        </Typography> */}
                                    </Stack>
                                )}
                                <div className="custom-date-picker">
                                    <StaticDatePicker
                                        views={["day"]}
                                        displayStaticWrapperAs="desktop"
                                        PaperProps={{
                                            sx: {
                                                "& .MuiPickersDay-root": {
                                                    "& .Mui-selected": {
                                                        color: "white"
                                                    }
                                                }
                                            }
                                        }}
                                        disablePast={disablePast}
                                        {...(minValue && {minDate: minValue})}
                                        renderInput={(props) => <CustomTextField {...props} />}
                                        value={selectedDate}
                                        onChange={(date) => setSelectedDate(date)}
                                        {...(maxDate && {maxDate: new Date(maxDate)})}
                                    />
                                </div>
                            </Box>
                        )}
                        {!datepicker && (
                            <Box>
                                {!timepicker && (
                                    <Typography variant="h4" fontWeight={700} sx={{mb: 1}}>
                                        {timeTitle}
                                    </Typography>
                                )}
                                <Grid container spacing={2} alignItems="center" sx={{mt: 1}}>
                                    <Grid item xs>
                                        <CustomSelect
                                            noNone
                                            sx={customSelectStyle}
                                            numberValidation
                                            label="Hour"
                                            menuItems={checkForMin(12)}
                                            value={selectedTime.hour || ""}
                                            onChange={(e) => setSelectedTime((pre) => ({...pre, hour: e.target.value}))}
                                        />
                                    </Grid>
                                    <Grid item xs={0.5}>
                                        <Typography variant="h3" fontWeight={600}>
                                            :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <CustomSelect
                                            noNone
                                            sx={customSelectStyle}
                                            numberValidation
                                            label="Mins"
                                            menuItems={checkForMin(60)}
                                            value={selectedTime.minutes}
                                            onChange={(e) => setSelectedTime((pre) => ({...pre, minutes: e.target.value}))}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <CustomSelect
                                            noNone
                                            sx={{...customSelectStyle}}
                                            label="am/pm"
                                            menuItems={[
                                                {id: "am", label: "am"},
                                                {id: "pm", label: "pm"}
                                            ]}
                                            value={selectedTime.meridian} // This binds the current value to the select component
                                            onChange={(e) => setSelectedTime((pre) => ({...pre, meridian: e.target.value}))} // This updates the state correctly
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                        <Box pt={2}>
                            {clearButton ? (
                                <ButtonGrouping
                                    btnprops={[
                                        {
                                            btnTitle: "Cancel",
                                            sx: (t) => ({color: t.palette.error.dark}),
                                            onClick: () => handleClose()
                                        },
                                        clearButton && {
                                            btnTitle: "Clear",
                                            sx: (t) => ({
                                                bgcolor: t.palette.background.paper,
                                                color: "#F0475C",
                                                border: "1px solid #F91239"
                                            }),
                                            onClick: () => updateClearance()
                                        },
                                        {
                                            btnTitle: "Save",
                                            variant: "contained",
                                            sx: (t) => ({color: t.palette.background.paper}),
                                            onClick: handleSave
                                        }
                                    ]}
                                />
                            ) : (
                                <ButtonGrouping
                                    btnprops={[
                                        {
                                            btnTitle: "Cancel",
                                            sx: (t) => ({color: t.palette.error.dark}),
                                            onClick: () => handleClose()
                                        },

                                        {
                                            btnTitle: "Save",
                                            variant: "contained",
                                            sx: (t) => ({color: t.palette.background.paper}),
                                            onClick: handleSave
                                        }
                                    ]}
                                />
                            )}
                        </Box>
                    </LocalizationProvider>
                </Box>
            </Menu>
        </div>
    );
};

export default CustomNewDateTimePicker;
