import React from "react";
import {Grid} from "@mui/material";
import PropTypes from "prop-types";
import SelectType from "./SelectType";
import {motion} from "framer-motion";

const Filter = (props) => {
    const {filterState, isChild = false, setFilterState, filterChips, setSearchedValue, filterValue, showFilter, isNest = false} = props;
   
    return (
        <motion.div
            initial={{height: 0, opacity: 0}} // Initial styles
            animate={{height: showFilter ? "auto" : 0, opacity: showFilter ? 1 : 0, pointerEvents: showFilter ? "auto" : "none"}} 
            transition={{duration: 0.5}} 
        >
            <Grid container spacing={1 / 2} pb={1}>
                {filterState.map((state) => {
                    return (
                        <Grid item key={state?.key} xs={4} md={12 / 5} lg={12 / 7}>
                            <SelectType
                                item={state}
                                setFilterState={setFilterState}
                                filterChips={filterChips}
                                setSearchedValue={setSearchedValue}
                                value={filterValue}
                                isChild={isChild}
                                isNest={isNest}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </motion.div>
    );
};

export default Filter;

Filter.propTypes = {
    filterState: PropTypes.object,
    setFilterState: PropTypes.func,
    filterChips: PropTypes.object,
    setSearchedValue: PropTypes.func,
    filterValue: PropTypes.string,
    showFilter: PropTypes.bool
};
