import {useTheme} from "@emotion/react";

export const useStyles = () => {
    const theme=useTheme()
    return {
        paperprops: {
            boxShadow: "0px 0px 8px rgba(0,0,0,0.2)",
            width: "421px",
            position: "relative",
            padding: "33px 23px"
        },
        crossIcon: {
            position: "absolute",
            right: "16px",
            top: "16px",
            cursor: "pointer"
        },
        dialogContent: {
            padding: "0px"
        },
        dialogStack: {
            marginTop: "25px",
            marginBottom: "8px"
        },
        selectMenuPaperProps: {
            maxHeight: 200
        },
        unAssignButton: {
            fontSize: "0.937rem",
            fontWeight: 600,
            color: theme.palette.primary.main
        },
        updateButton: {
            fontSize: "0.813rem",
            fontStyle: "normal",
            fontWeight: 600,
            bgcolor: theme.palette.primary.main,
            height: "35px",
            color: theme.palette.primary.contrastText,
            mt: 0.5,
            ":hover": {
                bgcolor: theme.palette.primary.main
            }
        }
    };
};
