import React, { useEffect, useState } from "react";

const defaultValues = {
    itemDetail: [],
    headerData: {
        template: {
            name: "",  templateId: null
        },
        service: "Catering",
        forHowMany: "",
    },
    selectedBrand: "",
    brandName: "",
    selectedMenu: '',
    categories: {},
    items: [],
    specialInstruction: "",
    kitchenNote: "",
    deliveryInstruction: "",
    itemModifier: {},
    clickOnSaveDraft: false,
    isSaved: false,
};

const useCreateOrderController = () => {
    const [initialValues, setInitialVlaues] = useState(defaultValues);

    useEffect(() => {
        setInitialVlaues(defaultValues);
    }, []);

    const handleInitialValues = (values) => {
        setInitialVlaues(values);
    }

    return { initialValues, handleInitialValues, defaultValues };
};

export default useCreateOrderController;
