import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import React from "react";
import AnimateButton from "ui-component/extended/AnimateButton";
import {Box} from "@mui/system";
import Cancel from "../../assets/images/icons/cancel.svg";
import warning from "../../assets/images/group.svg";
import styles from "./Driver.module.scss";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const DeleteModal = (props) => {
    const {
        title,
        description,
        open,
        handleClose,
        handleSubmit,
        hideBackdrop = false,
        buttonTitle = "Delete",
        noOnClose = false,
        redButtonText,
        handleDraftClose
    } = props;
    return (
        <Dialog
            open={open}
            {...(!noOnClose && {onClose: handleClose})}
            // TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            hideBackdrop={hideBackdrop}
            PaperProps={{sx: {boxShadow: "0px 0px 8px rgba(0,0,0,0.2)", width: "421px", minHeight: "220px"}}}
        >
            <Box sx={{position: "absolute", right: "16px", top: "8px", cursor: "pointer"}} onClick={handleClose}>
                <img src={Cancel} alt="plus-icon" width="12px" height="12px" />
            </Box>
            <DialogTitle id="alert-dialog-title" variant="h3" className={styles.headingDivision1}>
                <img src={warning} alt="!" />
            </DialogTitle>
            <DialogTitle id="alert-dialog-title" variant="h3" className={styles.heading22}>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" variant="body2" className={styles.heading33}>
                    {buttonTitle === "Accept" ? "" : description}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={styles.heading4}>
                <ButtonGrouping
                    btnprops={[
                        {
                            btnTitle: redButtonText || "Cancel",
                            onClick: handleDraftClose || handleClose,
                            sx: (t) => ({
                                bgcolor: t.palette.background.paper,
                                color: "#F0475C",
                                minWidth: 153,
                                border: "1px solid #F91239"
                            })
                        },
                        {
                            btnTitle: buttonTitle,
                            variant: "contained",
                            sx: (t) => ({
                                bgcolor: "#00ab3a",
                                color: t.palette.background.paper,
                                minWidth: 153,
                                ":hover": {
                                    bgcolor: "#00ab3a"
                                }
                            }),
                            onClick: handleSubmit
                        }
                    ]}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DeleteModal;
