import {Grid} from "@mui/material";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import React from "react";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import CommonTable from "commonComponent/TableSection/CommonTable";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";

import useLocationController from "./locations.controller";
import Filters from "commonComponent/Filters";
import FilterChips from "commonComponent/FilterChipsComponent";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import Filter from "commonComponent/FilterAccoridan";

const Locations = () => {
    const [searchParam] = useSearchParams();
    const marketName = searchParam.get("marketName");
    const LC = useLocationController();
    console.log(LC, "tata");
    const filterChips = LC.type === "marketLocations" ? LC.childfilterChips : LC.filterChips;
    const openFilter = LC.type === "marketLocations" ? LC.childopenFilter : LC.openFilter;
    const rowsPerPage = LC.type === "marketLocations" ? LC.childrowsPerPage : LC.rowsPerPage;
    const currentPage = LC.type === "marketLocations" ? LC.childcurrentPage : LC.currentPage;

    return (
        <>
            <Grid container>
                {/* <Filters
                    filterState={LC.filterState}
                    filterChips={LC.filterChips}
                    setFilterChips={LC.setFilterChips}
                    handleApplyFilter={LC.handleApplyFilter}
                /> */}

                <Grid item xs={12}>
                    <TableTopFilterWrapper
                        lessmarginOnTop
                        pageheader={
                            <PageHeader
                                title={marketName || "Locations"}
                                {...(LC.id && {
                                    breadsCrumData: [
                                        {label: "Markets", href: "/markets"},
                                        {label: "Locations", isActive: true}
                                    ]
                                })}
                            />
                        }
                        btnTitle={WithPermission({module: "Locations", permission: "create_permission"}) && "Create location"}
                        handleClick={LC.handleCreateLocation}
                        handleSearch={LC.handleSearch}
                        filterType="locationFilter"
                    >
                        {openFilter && (
                            <Filter
                                filterState={LC.filterState}
                                filterChips={filterChips}
                                setSearchedValue={LC.setSearchedValue}
                                showFilter={openFilter}
                                isChild={LC.type === "marketLocations"}
                            />
                        )}
                        <FilterChips filterChips={filterChips} filterState={LC.filterState} isChild={LC.type === "marketLocations"} />
                        <CommonTable
                            {...(LC.id && {
                                id: `?marketId=${LC.id}&marketName=${marketName}`
                            })}
                            loading={LC.loading}
                            data={LC.locationListData}
                            header={[
                                {key: "name", label: "Location"},
                                {key: "market", label: "Market"},
                                {key: "state", label: "State"},
                                {key: "address", label: "Address"},
                                {key: "revenue_share", label: "Revenue share"},
                                {key: "createdAt", label: "Created"},
                                {key: "is_active", label: "Status"},
                                {key: "action", label: "Action"}
                            ]}
                            actions={[
                                WithPermission({module: "Locations", permission: "update_permission"}) && "Edit",
                                WithPermission({module: "Locations", permission: "delete_permission"}) && "Delete"
                            ]}
                            handleActionBtn={LC.handleActionBtn}
                            onPageChange={LC.handlePageChange}
                            onRowsPerPageChange={LC.handleRowsChange}
                            handleSort={LC.handleSort}
                            totalItems={LC.totalItems}
                            rowsPerPage={rowsPerPage}
                            currentPage={currentPage - 1}
                            sortingLabel={["Location", "Market", "State", "city", "Status", "Created", "Revenue share"]}
                        />
                    </TableTopFilterWrapper>
                </Grid>
            </Grid>

            <DeleteModal
                open={LC.open}
                title="Are you sure you want to delete this location?"
                description="This will permanently remove all data associated with it."
                handleClose={LC.handleDeleteModal}
                handleSubmit={LC.handleDelete}
            />
        </>
    );
};

export default Locations;
