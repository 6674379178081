import React, {useEffect, useState, useRef} from "react";
import {Box, Grid, Typography, InputAdornment, Stack, Checkbox, IconButton, FormControl, FormControlLabel, FormGroup} from "@mui/material";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {enforceFormat, formatToPhone} from "utils/helperFunctions/helpers";
import {ErrorMessage, Form, Formik, FieldArray} from "formik";
import * as yup from "yup";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import PlusIcon from "assets/images/icons/plus.png";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import useTempDrawer from "hooks/useTempDrawer";
import {useNavigate, useSearchParams, useParams, Link} from "react-router-dom";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "react-redux";
import {v4 as uuidv4} from "uuid";
import Loader from "ui-component/Loader";
import UserView from "./UserView/UserView";
import Unchecked from "assets/images/icons/unchecked.svg";
import Checked from "assets/images/icons/lightCheckedBox.svg";
import CustomSwitch from "commonComponent/Form/Fields/CustomSwitch";

const formSchema = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    role: "",
    userType: "",
    market: "",
    location: "",
    isActive: false
};

function User(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState(formSchema);
    const [loading, setLoading] = useState(false);
    const [customerData, setCustomerData] = useState([]);
    const [urlSearchParams] = useSearchParams();
    const prm = useParams();
    const profileId = prm.type;
    const [addressList, setAddresList] = useState([]);
    const [isListUpdated, setIsAddedListUpdated] = useState(false);
    const [cardList, setCardList] = useState([]);
    const mrkName = urlSearchParams.get("customerName");
    const editMode = urlSearchParams.get("edit");
    const [selectedAddress, setSelectedAddress] = useState("");
    const [buttonType, setbuttonType] = useState("");
    const [isAddressUpdate, setIsAddressUpdate] = useState(false);
    const [marketList, setmarketList] = useState([]);
    const [locationList, setLocationList] = React.useState([]);

    const getAllMarkets = async (pageNumber = 1) => {
        const params = `?page=${pageNumber}&pageSize=5000&sortBy=createdAt&orderBy=desc&is_active=true`;
        const instance = NetworkManager(API.MARKET.GET);
        const response = await instance.request({}, [params]);

        if (response.success) {
            const data = response.data;
            const idAndLabel = data.marketList.map((el) => ({id: el.market_id, label: el.market_name}));
            setmarketList([...idAndLabel]);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const getLocation = async (id) => {
        if (!id) {
            return;
        }
        const instance = NetworkManager(API.LOCATION.GETBRANDWISELOCATION);
        const params = {
            brand_id: id
        };

        const response = await instance.request({}, params);
        if (response.success) {
            const states = response.data?.[0]?.kitchenlocationdetails?.map((state) => ({
                label: state.kitchen_name,
                value: state.kitchen_location_id,
                id: state.kitchen_location_id
            }));
            setLocationList(states);
        }
    };
    useEffect(() => {
        getAllMarkets();
        getLocation();
    }, []);

    const handleNewCustomer = async (val, setSubmitting) => {
        setSubmitting(true);
        // const fullName = `${val.first_name} ${val.last_name}`;

        const payload = {
            email: val.email,
            full_name: val.first_name,
            last_name: val.last_name,
            phone: val.phone
        };
    };

    const labelList = [
        {label: "Orders", value: "orders"},
        {label: "Order pricing and totals", value: "order_pricing"},
        {label: "Customers", value: "customers"},
        {label: "Markets", value: "markets"},
        {label: "Brands", value: "brands"},
        {label: "Delivery", value: "delivery"},
        {label: "Reports", value: "reports"},
        {label: "Settings", value: "settings"}
        // Add more label objects as needed
    ];
    const handleCreateCustomer = async (values) => {};
    return (
        <>
            {loading && <Loader />}
            {editMode ? (
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={yup.object({
                        first_name: yup.string().required("First name is required").nullable().trim(),
                        last_name: yup.string().required("Last name is required").nullable().trim(),
                        email: yup.string().required("Email is required").email("Enter valid email").nullable().trim(),
                        phone: yup.string().required("Last name is required").nullable().trim(),
                        role: yup.string().required("Role is required").nullable().trim(),
                        userType: yup.string().required("User type is required").nullable().trim(),
                        market: yup.string().required("Market is required").nullable().trim(),
                        location: yup.string().required("Location is required").nullable().trim(),
                        isActive: yup.boolean().nullable()
                    })}
                    onSubmit={(values, actions) => handleCreateCustomer(values, actions)}
                >
                    {(formik) => (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems="center">
                                    <Typography sx={{fontSize: "14px", fontWeight: 700, color: "#A1A3A8"}}>Inactive</Typography>
                                    <CustomSwitch
                                        checked={formik.values.isActive}
                                        onChange={(e) => {
                                            formik.setFieldValue("isActive", e.target.checked);
                                        }}
                                    />
                                    <Typography sx={{fontSize: "14px", fontWeight: 700, color: "#00ab3a"}}>Active</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                                <CustomTextField
                                    fullWidth
                                    placeholder="First name"
                                    label="First name"
                                    inputProps={{maxLength: 50}}
                                    {...formik.getFieldProps("first_name")}
                                />
                                <ErrorMessage name="first_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                                <CustomTextField
                                    fullWidth
                                    placeholder="Last name"
                                    label="Last name"
                                    inputProps={{maxLength: 50}}
                                    {...formik.getFieldProps("last_name")}
                                />
                                <ErrorMessage name="last_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                            <Grid item xs={12} sx={{paddingTop: "0px !important"}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4} xl={3.5} mt={2}>
                                        <CustomTextField
                                            fullWidth
                                            placeholder="Email address"
                                            label="Email address"
                                            inputProps={{maxLength: 50}}
                                            {...formik.getFieldProps("email")}
                                        />
                                        <ErrorMessage name="email" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} xl={3.5} mt={2}>
                                        <CustomTextField
                                            fullWidth
                                            label="Phone number"
                                            onKeyDown={enforceFormat}
                                            onKeyUp={formatToPhone}
                                            inputProps={{maxLength: 14}}
                                            // {...(formik.values.phone && {
                                            //     InputProps: {
                                            //         startAdornment: <InputAdornment position="start">+1</InputAdornment>
                                            //     }
                                            // })}
                                            {...formik.getFieldProps("phone")}
                                        />
                                        <ErrorMessage name="phone" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{paddingTop: "0px !important"}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4} xl={3.5} mt={2}>
                                        <CustomSelect
                                            label="Role"
                                            fullWidth
                                            menuItems={[
                                                {label: "Admin", value: "ADMIN", id: "DISCOUNT"},
                                                {label: "Subadmin", value: "SUBADMIN", id: "SUBADMIN"},
                                                {label: "User", value: "USER", id: "USER"}
                                            ]}
                                            {...formik.getFieldProps("role")}
                                            onChange={(e) => {
                                                const id = e.target.value;
                                                formik.setFieldValue("role", id);
                                            }}
                                        />
                                        <ErrorMessage name="role" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4} xl={3.5} mt={2}>
                                        <CustomSelect
                                            label="User type"
                                            fullWidth
                                            menuItems={[
                                                {label: "Ceo", value: "CEO", id: "CEO"},
                                                {label: "Manager", value: "MANAGER", id: "MANAGER"}
                                            ]}
                                            {...formik.getFieldProps("userType")}
                                            onChange={(e) => {
                                                const id = e.target.value;
                                                formik.setFieldValue("userType", id);
                                            }}
                                        />
                                        <ErrorMessage name="userType" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} mt={3}>
                                <Typography sx={{fontSize: "1.125rem", fontWeight: 600}}>Access</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{paddingTop: "0px !important"}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4} xl={3.5} mt={2}>
                                        <CustomSelect
                                            label="Market"
                                            menuItems={marketList}
                                            values={formik.values.market}
                                            onChange={(e) => formik.setFieldValue("market", e.target.value)}
                                        />
                                        <ErrorMessage name="market" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} xl={3.5} mt={2}>
                                        <CustomSelect
                                            label="Location"
                                            menuItems={locationList}
                                            values={formik.values.location}
                                            onChange={(e) => formik.setFieldValue("location", e.target.value)}
                                        />
                                        <ErrorMessage name="location" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} mt={3}>
                                <Typography sx={{fontSize: "1.125rem", fontWeight: 600}}>Permissions</Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                                <FieldArray
                                    name="label"
                                    render={(arrayHelper) => (
                                        <FormControl>
                                            <FormGroup>
                                                {labelList.map((obj, index) => (
                                                    <Stack key={index}>
                                                        <FormControlLabel
                                                            // sx={{height: 30}}
                                                            sx={{marginBottom: "8px"}}
                                                            checked={formik.values.label}
                                                            key={index}
                                                            control={
                                                                <Checkbox
                                                                    disableRipple
                                                                    icon={
                                                                        <img
                                                                            src={Unchecked}
                                                                            style={{width: "18px", height: "18px"}}
                                                                            alt="checked"
                                                                        />
                                                                    }
                                                                    checkedIcon={
                                                                        <img
                                                                            src={Checked}
                                                                            alt="checkbox"
                                                                            style={{width: "18px", height: "18px"}}
                                                                        />
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                <Typography
                                                                    sx={{fontSize: "15px", fontWeight: 600, marginLeft: "8px"}}
                                                                    variant="checkBoxUnSelected"
                                                                >
                                                                    {obj.label}
                                                                </Typography>
                                                            }
                                                            onChange={(e) => {
                                                                if (formik.values.label.length && formik.values.label.includes(obj.value)) {
                                                                    const valIndex = formik.values.label.findIndex(
                                                                        (el) => el === obj.value
                                                                    );
                                                                    arrayHelper.remove(valIndex);
                                                                } else {
                                                                    arrayHelper.push(obj.value);
                                                                }
                                                            }}
                                                        />
                                                    </Stack>
                                                ))}
                                            </FormGroup>
                                            <ErrorMessage name="label" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                                <ButtonGrouping
                                    btnprops={[
                                        {
                                            btnTitle: "Cancel",
                                            sx: (t) => ({color: t.palette.error.dark, width: "92px"}),
                                            onClick: (e) => {
                                                formik.resetForm();
                                                navigate("/settings?tab=user");
                                            }
                                        },
                                        {
                                            btnTitle: "Save",
                                            variant: "contained",
                                            sx: (t) => ({color: t.palette.background.paper, width: "92px"}),
                                            onClick: (e) => {
                                                formik.handleSubmit();
                                            }
                                        }
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Formik>
            ) : (
                <UserView />
            )}
        </>
    );
}

export default User;
