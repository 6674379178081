import { API, NetworkManager } from "network/core";
import { useEffect, useState } from "react";

const useItemDetailController = (props) => {
    const { formik, catId } = props;

    const [category, setCategory] = useState([]);
    const [allMenus, setAllMenus] = useState([]);

    const DietaryCheckboxes = [
        { label: "Vegetarian", value: "VEGETARIAN" },
        { label: "Vegan", value: "VEGAN" },
        { label: "Gluten-free", value: "GLUTEN-FREE" },
        { label: "Dairy-free", value: "DAIRY-FREE" },
        { label: "Spicy", value: "SPICY" }
    ];

    function createSetWithOccurrences(arr) {
        const occurrenceMap = new Map();

        arr.forEach((item) => {
            const id = item.id;
            if (occurrenceMap.has(id)) {
                occurrenceMap.set(id, occurrenceMap.get(id) + 1);
            } else {
                occurrenceMap.set(id, 1);
            }
        });

        let obj = {};
        [...occurrenceMap].forEach(([id, occ]) => {
            obj = { ...obj, [id]: occ };
        });
        return obj;
    }

    const getCategory = async (menusIdArr = []) => {
        if (menusIdArr.length > 0) {
            const instance = NetworkManager(API.CATEGORY.MENUWISECATEGORY);
            const response = await instance.request({}, [`?sortBy=createdAt&orderBy=asc&menu_id=${menusIdArr.map((id) => id)}`]);
            if (response.success) {
                let menuCatmappingOBJ = {};

                const catArr = [];
                response.data.menuList?.forEach((mCat) => {
                    menuCatmappingOBJ = { ...menuCatmappingOBJ, [mCat?.menu_id]: mCat?.menuCategory?.map((el) => el?.category_id) };

                    const arr = mCat?.menuCategory?.map((obj) => ({
                        label: obj?.category?.category_name,
                        value: obj?.category_id,
                        id: obj?.category_id
                    }));
                    catArr.push(...arr);
                });

                formik.setFieldValue("menuCategoryOccurance", createSetWithOccurrences(catArr));
                formik.setFieldValue("menuCaterogyMapping", menuCatmappingOBJ);

                const resArr = [];
                // catArr.forEach((item) => {
                //     const i = resArr.findIndex((x) => x.id === item.id);
                //     if (i <= -1) {
                //         resArr.push({...item});
                //     }
                // });
                const categoryOccurance = createSetWithOccurrences(catArr)
                catArr.forEach((item) => {
                    if (categoryOccurance[item?.id]>=menusIdArr.length) {
                        const i = resArr.findIndex((x) => x.id === item.id);
                        if (i <= -1) {
                            resArr.push({ ...item });
                        }
                    }
                });

                setCategory(resArr);
            }
        } else {
            setCategory([]);
        }
    };

    useEffect(() => {
        if (!catId) {
            getCategory(formik.values.menus);
        }
    }, [formik.values.menus]);

    const getAllMenus = async () => {
        const instance = NetworkManager(API.MENU.GETALLMENUS);
        const response = await instance.request({}, [`?page=1&pageSize=5000&sortBy=createdAt&orderBy=asc`]);
        if (response.success) {
            const states = response.data.menuList?.map((state) => ({
                label: state.menu_name,
                value: state.menu_id,
                id: state.menu_id
            }));
            setAllMenus(states);
        }
    };

    useEffect(() => {
        getAllMenus();
    }, []);

    return {
        allMenus,
        category,
        DietaryCheckboxes
    };
};

export default useItemDetailController;
