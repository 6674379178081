import React, { useState, useEffect } from "react";
import { dispatch } from "store/index";
import { useSelector } from "react-redux";
import Tooltip from "commonComponent/Tooltip";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getOrderItemStyle, Reorder } from "commonComponent/Dragger/utils";
import { IconGripVertical } from "@tabler/icons";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    TextField,
    OutlinedInput,
    LinearProgress
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import { FieldArray, Form, ErrorMessage } from "formik";
import { API, NetworkManager } from "network/core";
import { enforceFormat, FormatCurrency, enforceFormatForNumber, commaFormattedNumber,generateUrl } from "utils/helperFunctions/helpers";
import styles from "./mainSection.module.scss";
import { styled } from "@mui/material/styles";
import TickIcon from "assets/images/icons/tick2.svg";
import RedIcon from "assets/images/icons/redTick.svg";
import YellowIcon from "assets/images/icons/yellowTick.svg";
import DeleteIcon from "assets/images/icons/delIcon.svg";
import Checked from "assets/images/icons/lightCheckedBox.svg";
import Unchecked from "assets/images/icons/unchecked.svg";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TableWarePopup from "commonComponent/DilogBox/Tableware";
import { ellipsizeText } from "commonComponent/Apptable/textUtils";
import CustomMenuItem from "commonComponent/DilogBox/CustomeMenuItem";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    borderBottom: `1px dashed #E0E2E6`,
    "&:not(:last-child)": {
        borderBottom: 0
    },
    "&:before": {
        display: "none"
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<KeyboardArrowDownIcon sx={{ fontSize: "1.3rem", color: "#000000" }} />} {...props} />
))(({ theme }) => ({
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(180deg)"
    },
    "& .MuiButtonBase-root": {
        padding: "0px !important"
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1.0)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1.5),
    marginTop: "-5px",
    marginLeft: "34px"
}));


const dietaryMap = {
    VEGAN: "VG",
    VEGETARIAN: "V",
    "GLUTEN-FREE": "GF",
    "DAIRY-FREE": "DF",
    SPICY: "S"
};

const dietarySequence=['VEGETARIAN','VEGAN',"GLUTEN-FREE","DAIRY-FREE",'SPICY']

const sortAndMapDietary = (arr1) => {
    // Create a copy of arr1 to avoid modifying the original array
    const sortedArr = [...arr1];
  
    // Sort the array based on the index in dietarySequence
    sortedArr.sort((a, b) => dietarySequence.indexOf(a.modifier_dietary) - dietarySequence.indexOf(b.modifier_dietary));
  
    // Map each value using dietaryMap
    const mappedArr = sortedArr.map(obj => dietaryMap[obj.modifier_dietary]);
  
    // Return the resulting array as in the original format
    return mappedArr;
  };


const initialSchema = {
    itemDetail: []
};

const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 18,
    height: 18,
    boxShadow:
        theme.palette.mode === "dark"
            ? "0 0 0 1px rgb(16 22 26 / 40%)"
            : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#fff",
    // backgroundImage:
    //     theme.palette.mode === "dark"
    //         ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
    //         : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
        outline: "2px auto #673AB7",
        outlineOffset: 2
    },
    "input:hover ~ &": {
        backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5"
    },
    "input:disabled ~ &": {
        boxShadow: "none",
        background: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)"
    }
}));

const BpCheckedIcon = ({ checked }) => (
    <svg width="18px" height="18px" viewBox="0 0 24 24" fontSize="36px">
        <circle cx="50%" cy="50%" r="11px" stroke={`${checked ? "#00ab3a" : "#A0A7B9"}`} fill="none" />
        {checked && <circle cx="50%" cy="50%" r="6px" stroke="#00ab3a" fill="#00ab3a" />}
    </svg>
);

const FormField = ({ values, formik, insert, remove, push, orderItems, handleReset, setWaitForItem, waitForItem }) => {
    const [expanded, setExpanded] = useState([]);
    const [itemInfo, setItemInfo] = useState([]);
    const [schema, setSchema] = useState([]);
    const [loading, setLoading] = useState(false);
    const { itemDetail } = values;
    const state = useSelector((state) => state.order);
    const [discountFlag, setDiscountFlag] = useState(false);
    const [openCustomMenu, setOpenCustomMenu] = useState(false);


    const handleCustomMenu = () => {
        setOpenCustomMenu(pre => !pre)
    }

    const getItemData = async (state) => {
        setLoading(true);
        setWaitForItem(true);
        const params={role:"Customer"}
        const instance = NetworkManager(API.ITEM.GET);
        const url = generateUrl(params);
        const response = await instance.request({}, [state.selectedItem,url]);
        if (response.success) {
            const data = response.data.item;
            data.key = `${new Date().getTime()}${Math.random()}${data.item_id}`
            setItemInfo([...itemInfo, data]);
            // clear the redux state
            dispatch({
                type: "UpdateItem",
                payload: {
                    id: ""
                }
            });
            const selectedMenuId = formik.values.selectedMenu;
            const menuCategory = data.menuCategoryItem?.filter((row) => row.menuCategory.menu_id === selectedMenuId);
            const modifierData = {};
            data.itemModifierGroup
                ?.sort((a, b) => a.series_no - b.series_no)
                ?.forEach((item) => {
                    modifierData[item.modifierGroup.modifier_group_id] = {
                        is_required: item.modifierGroup.is_required,
                        maximum_selections: item.modifierGroup.maximum_selections,
                        minimum_selections: item.modifierGroup.minimum_selections,
                        name: item.modifierGroup?.display_name,
                        series_no: item.series_no,
                        allowQuantityModification: item.modifierGroup.allow_quantity_modification
                    };
                });

            push({
                checkboxModifier: [],
                radioModifier: [],
                modifierData: modifierData,
                price: parseFloat(data?.price).toFixed(2),
                quantity: Number(data?.minimum_order_quantity ?? 1),
                packaging: [],
                itemId: data.item_id,
                minimum_order_quantity:Number(data.minimum_order_quantity),
                specialInstruction: "",
                disableFlag: false,
                menuCategoryId: menuCategory?.[0]?.menu_category_id ?? "",
                item_type: data?.item_type,
                servingSize:data?.serving_size
            });
        }
        setLoading(false);
        setWaitForItem(false);
    };

    const handleCustomMenuData = (response) => {
        const data = response.data.item;
        data.key = `${new Date().getTime()}${Math.random()}${data.item_id}`;
        setItemInfo([...itemInfo, data]);
        // clear the redux state
        dispatch({
            type: "UpdateItem",
            payload: {
                id: ""
            }
        });
        const modifierData = {};
        push({
            checkboxModifier: [],
            radioModifier: [],
            modifierData: modifierData,
            price: parseFloat(data?.price).toFixed(2),
            quantity: formik.values.headerData.forHowMany !=="" ? formik.values.headerData.forHowMany :1,
            packaging: [],
            itemId: data.item_id,
            minimum_order_quantity: 0,
            specialInstruction: "",
            disableFlag: false,
            item_type: data.item_type
        });
        setWaitForItem(false);
    }

    
    useEffect(() => {
        setItemInfo([]);
    }, [formik.values.selectedBrand, formik.values.selectedMenu]);

    useEffect(() => {
        if (state?.selectedItem) {
            // let flag = false;
            // itemInfo.forEach((row) => {
            //     if (row.item_id === state?.selectedItem) {
            //         flag = true;
            //     }
            // });
            // if (flag === false) {
                
            // }
            getItemData(state);
        }
    }, [state]);

    const getItemDataForPreFill = (id) =>
        new Promise((resolve, reject) => {
            const params={role:"Customer"}
            const url = generateUrl(params);
            const instance = NetworkManager(API.ITEM.GET);
            resolve(instance.request({}, [id,url]));
        });

    const promiseFunction = (orderItems) => {
        const promiseArray = [];
        setLoading(true);
        
        orderItems.forEach((row) => {
            promiseArray.push(getItemDataForPreFill(row));
        });
        Promise.all(promiseArray).then((arr) => {
            const result = [];
            const modifier = {};
            arr.forEach((response,ind) => {
                if (response.success) {
                    const data = response.data.item;
                    data.key = `${new Date().getTime()}${ind}${Math.random()}${data.item_id}`
                    result.push(data);

                    const modifierData = {};
                    data.itemModifierGroup.forEach((item) => {
                        modifierData[item.modifierGroup.modifier_group_id] = {
                            is_required: item.modifierGroup.is_required,
                            maximum_selections: item.modifierGroup.maximum_selections,
                            minimum_selections: item.modifierGroup.minimum_selections,
                            name: item.modifierGroup?.display_name,
                            series_no: item.series_no,
                            allowQuantityModification: item.modifierGroup.allow_quantity_modification
                        };
                    });
                    modifier[data.item_id] = modifierData;
                }
            });
            const newItemDetail = formik.values.itemDetail;
            newItemDetail.forEach((row, index) => {
                if (row.itemId in modifier) {
                    formik.values.itemDetail[index].modifierData = modifier[row.itemId];
                }
            });
           
            setItemInfo(result);
        });
        setLoading(false);
    };

    useEffect(() => {
        if (orderItems) {
            promiseFunction(orderItems);
        }
    }, [orderItems]);

    const handleRemove = (index) => {
        const temp = JSON.parse(JSON.stringify(itemInfo));
        temp.splice(index, 1);
        setItemInfo(temp);
        remove(index);
    };

    const handleChecked = (itemIndex, id) => {
        let flag = false;
        itemDetail[itemIndex]?.checkboxModifier?.forEach((modifier) => {
            if (modifier.modifierId === id) {
                flag = true;
            }
        });
        return flag;
    };
    const handleCheckedForRadio = (itemIndex, id, id2) => {
        let flag = false;
        itemDetail[itemIndex]?.radioModifier?.forEach((obj) => {
            obj = JSON.parse(obj);
            const modifierId = obj?.modifierId;
            const modifierGroupId = obj?.modifierGroupId;
            if (modifierGroupId === id && modifierId === id2) {
                flag = true;
            }
        });

        return flag;
    };

    const getModifierIndex = (itemIndex, id) => {
        let presentIndex = -1;
        itemDetail[itemIndex]?.checkboxModifier?.forEach((modifier, index) => {
            if (modifier.modifierId === id) {
                presentIndex = index;
            }
        });
        return presentIndex;
    };

    const getModifierIndexForRadio = (itemIndex, id) => {
        let presentIndex = -1;
        itemDetail[itemIndex]?.radioModifier?.forEach((modifier, index) => {
            const obj = JSON.parse(modifier);
            if (obj.modifierId === id) {
                presentIndex = index;
            }
        });
        return presentIndex;
    };

    const handleItemTotalPrice = (itemIndex) => {
        let totalPrice = parseFloat(itemDetail[itemIndex]?.price);
        itemDetail[itemIndex]?.checkboxModifier.forEach((row) => {
            if (!row.allowQuantityModification) {
                totalPrice += parseFloat(row.price);
            }
        });

        itemDetail[itemIndex]?.radioModifier.forEach((row) => {
            const obj = JSON.parse(row);
            if (!obj.allowQuantityModification) {
                totalPrice += parseFloat(obj.price);
            }
        });

        return totalPrice;
    };

    const handleChange = (panel) => (e, isExpanded) => {
        if (e.target.id === "id1" || e.target.id === "id2") {
            const temp = JSON.parse(JSON.stringify(expanded));
            if (temp.includes(panel)) {
                const index = temp.indexOf(panel);
                temp.splice(index, 1);
            } else {
                temp.push(panel);
            }

            setExpanded(temp);
        }
    };

    const handleQuantityCheck = (guestCount, categoryId, itemQuantity,servingSize=0) => {
        const category = formik.values.categories[categoryId];
        servingSize=Number(servingSize ||0)

        const greenTick = () => (
            <Tooltip placement="top" title="Enough for your number of guests">
                <img src={TickIcon} className={styles.tick} alt="tick icon" />
            </Tooltip>
        );

        const yellowTick = () => (
            <Tooltip placement="top" title="Too much for your number of guests. You may want to reduce.">
                <img src={YellowIcon} className={styles.tick} alt="tick icon" />
            </Tooltip>
        );

        const redTick = () => (
            <Tooltip placement="top" title="Not enough for your number of guests. We suggest you add more.">
                <img src={RedIcon} className={styles.tick} alt="tick icon" />
            </Tooltip>
        );

        const checkGraterOrLess = () => {
            if (guestCount < (parseInt(itemQuantity, 10)*servingSize)) {
                return greenTick();
            } else {
                return redTick();
            }
        };

        switch (category) {
            case "Sides": {
                return guestCount === (parseInt(itemQuantity, 10)*servingSize) ? greenTick() : checkGraterOrLess();
            }
            case "Desserts": {
                return guestCount === (parseInt(itemQuantity, 10)*servingSize) ? greenTick() : checkGraterOrLess();
            }
            case "Extras": {
                return guestCount === (parseInt(itemQuantity, 10)*servingSize) ? greenTick() : checkGraterOrLess();
            }
            case "Drinks": {
                return guestCount === (parseInt(itemQuantity, 10)*servingSize) ? greenTick() : checkGraterOrLess();
            }
            default: {
                const categories = formik.values.categories;
                let totalItems = 0;

                itemDetail?.forEach((row1) => {
                    if (categories && row1?.menuCategoryId in categories && row1.item_type === 1) {
                        if (
                            categories[row1.menuCategoryId] !== "Sides" &&
                            categories[row1.menuCategoryId] !== "Desserts" &&
                            categories[row1.menuCategoryId] !== "Extras" &&
                            categories[row1.menuCategoryId] !== "Drinks"
                        ) {
                            if (row1.quantity !== "") {
                                totalItems += (parseInt(row1.quantity, 10)*row1?.servingSize);
                            }
                        }
                    }
                });
                // if(guestCount )
                

                const num = parseInt(totalItems, 10);

                const lowerBound = guestCount * 1.0; // 100% of guestCount
                const upperBound = guestCount * 1.16; // 115% of guestCount
                // Check if totalItems is between lowerBound and upperBound

                if (num >= lowerBound && num <= upperBound) {
                    return greenTick();
                } else if (num < guestCount) {
                    // eslint-disable-line
                    return redTick();
                }
                return greenTick();
            }
        }
    };

    const onDragEnd = (formik) => (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        if (result.type === "QUESTIONS") {
            const quest = Reorder(JSON.parse(JSON.stringify(formik.values.itemDetail)), result.source.index, result.destination.index);

            formik.setFieldValue(`itemDetail`, quest);
            const quest2 = Reorder(itemInfo, result.source.index, result.destination.index);
            setItemInfo(quest2);
        } else {
            const answers = Reorder(
                formik.values.itemDetail[parseInt(result.type, 10)].answers,
                result.source.index,
                result.destination.index
            );

            const quest1 = JSON.parse(JSON.stringify(formik.values.itemDetail));

            quest1[result.type].answers = answers;

            formik.setFieldValue(`itemDetail`, quest1);
        }
    };


    return itemInfo.length > 0 ? (
        <Box>
            <Box className={styles.linearProgress}>
                {loading && <LinearProgress />}
            </Box> 
            <Grid container spacing={0.5} className={styles.mainSection}>
                <Grid item xs={12}>
                    <Grid container spacing={0.5}>
                        <Grid item xs={8.35}>
                            <Typography className={styles.mx1}>Items</Typography>
                        </Grid>
                        <Grid item xs={3.65} sx={{ display: "flex", flexDirection: "row" }}>
                            <Grid container>
                                <Grid item xs={4} sx={{ textAlign: "left" }}>
                                    <Typography className={styles.mx1}>Price</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography className={styles.mx1} sx={{ maxWidth: "100px" }}>
                                        Quantity
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sx={{ textAlign: "left" }}>
                                    {" "}
                                    <Typography className={styles.mx1}>Total</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <DragDropContext onDragEnd={onDragEnd(formik)}>
                    <Droppable droppableId="droppable" type="QUESTIONS">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} style={{ background: "#ffff", width: "100%" }}>
                                {itemInfo.length &&
                                    itemInfo?.map((row1, itemIndex) => {
                                        const totalPriceForCurrentIndex = handleItemTotalPrice(itemIndex);
                                    
                                        const guestCount = parseInt(formik.values.headerData.forHowMany, 10) || 0;
                                        const category = formik.values.categories[itemDetail[itemIndex]?.menuCategoryId];

                                        return (
                                            <Draggable key={row1.key} draggableId={row1.key} index={itemIndex}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        style={getOrderItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                    >
                                                        <Grid item xs={12}>
                                                            <Accordion
                                                                expanded={expanded.includes(`panel${itemIndex}`)}
                                                                onChange={handleChange(`panel${itemIndex}`)}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "centner"
                                                                    }}
                                                                >
                                                                    <span {...provided.dragHandleProps}>
                                                                        <IconGripVertical icon="grip-vertical" style={{ color: "#E0E2E7" }} />
                                                                    </span>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon id="id2" />}
                                                                        aria-controls="panel1bh-content"
                                                                        id="panel1bh-header"
                                                                        sx={{
                                                                            "&.Mui-focusVisible": {
                                                                                backgroundColor: "transparent"
                                                                            },
                                                                            "&.Mui-focused": {
                                                                                backgroundColor: "inherit"
                                                                            },
                                                                            width: "100%",
                                                                            color: "#212121",
                                                                            padding: 0,
                                                                            flexDirection: "row-reverse",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            height:'48px'
                                                                        }}
                                                                    >
                                                                        <Grid container spacing={0.5}>
                                                                            <Grid item xs={8} md={8} lg={8.2}>
                                                                                <Typography
                                                                                    id="id1"
                                                                                    sx={{
                                                                                        width: "97%",
                                                                                        flexShrink: 0,
                                                                                        fontSize: "1.25rem",
                                                                                        fontWeight: 700,
                                                                                        marginTop: "9px"
                                                                                    }}
                                                                                >
                                                                                    {ellipsizeText(row1?.item_name,70)}
                                                                                </Typography>
                                                                                <ErrorMessage
                                                                                    name={`itemDetail[${itemIndex}].packaging`}
                                                                                    render={(msg) => (
                                                                                        <CustomErrorMessage errorMessage={msg} />
                                                                                    )}
                                                                                />
                                                                                {/* <ErrorMessage name={`itemModifier[${row1.item_id}]`} render={(msg) => <CustomErrorMessage errorMessage={msg} />} /> */}
                                                                                {formik.touched?.itemModifier &&
                                                                                    formik.errors?.itemModifier?.[itemIndex] && (
                                                                                        <CustomErrorMessage
                                                                                            errorMessage={
                                                                                                formik.errors?.itemModifier?.[itemIndex]
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                            </Grid>

                                                                            <Grid
                                                                                item
                                                                                xs={4}
                                                                                lg={3.8}
                                                                                md={4}
                                                                                sx={{ display: "flex", flexDirection: "row" }}
                                                                            >
                                                                                <Grid container className={styles.cxFlex}>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={4}
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between"
                                                                                        }}
                                                                                    >
                                                                                        <Box>
                                                                                            <Typography className={styles.cx1}>
                                                                                                {FormatCurrency(totalPriceForCurrentIndex)}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                        <Box>
                                                                                            {(guestCount !== 0 && itemDetail[itemIndex]?.item_type===1)  && handleQuantityCheck(
                                                                                                    guestCount,
                                                                                                    itemDetail[itemIndex]?.menuCategoryId,
                                                                                                    parseInt(
                                                                                                        itemDetail[itemIndex]?.quantity,
                                                                                                        10
                                                                                                    ),
                                                                                                    row1?.serving_size
                                                                                                )}
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <Typography
                                                                                            className={`${styles.cx1} ${styles.cxFlex}`}
                                                                                            sx={{ maxWidth: "72px" }}
                                                                                        >
                                                                                            <TextField
                                                                                                variant="outlined"
                                                                                                size="small"
                                                                                                value={itemDetail[itemIndex]?.quantity}
                                                                                                type="num"
                                                                                                min={1}
                                                                                                onKeyDown={enforceFormatForNumber}
                                                                                                defaultValue={1}
                                                                                                
                                                                                                onChange={(e) => {
                                                                                                    if (e.target.value.length === 5)
                                                                                                        return false;
                                                                                                    else {
                                                                                                        formik.setFieldValue(
                                                                                                            `itemDetail[${itemIndex}].quantity`,
                                                                                                            e.target.value
                                                                                                        );
                                                                                                        formik.setTouched({
                                                                                                            "itemDetail.quantity": true
                                                                                                        });
                                                                                                    }
                                                                                                    return true;
                                                                                                }}
                                                                                            />
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={3} lg={3} sx={{ textAlign: "left" }}>
                                                                                        {" "}
                                                                                        <Typography className={styles.cx1}>
                                                                                            {FormatCurrency(
                                                                                                totalPriceForCurrentIndex *
                                                                                                itemDetail[itemIndex]?.quantity
                                                                                            )}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={1}
                                                                                        lg={1}
                                                                                        className={styles.cxFlex}
                                                                                        sx={{ textAlign: "right" }}
                                                                                    >
                                                                                        {" "}
                                                                                        <Typography
                                                                                            className={styles.cx1}
                                                                                            sx={{ cursor: "pointer" }}
                                                                                            onClick={() => handleRemove(itemIndex)}
                                                                                        >
                                                                                            <img src={DeleteIcon} alt="delete icon" />
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </AccordionSummary>
                                                                </Box>
                                                                <AccordionDetails sx={{ marginLeft: "42px !important" }}>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        container
                                                                        spacing={0.5}
                                                                        justifyContent="flex-start"
                                                                        sx={{ flexDirection: "column" ,mt:'-12px' }}
                                                                    >
                                                                        <Grid item container xs={12}>
                                                                            {row1.itemModifierGroup?.length
                                                                                ? row1.itemModifierGroup?.filter((o => o?.modifierGroup?.modifierGroupModifier?.length))?.map((row2, index2) => (
                                                                                    <Grid
                                                                                        item
                                                                                        xs={12}
                                                                                        mb={2}
                                                                                        sx={{ display: "flex", flexDirection: "column" }}
                                                                                    >
                                                                                        <Box>
                                                                                            <Typography className={styles.mx2}>
                                                                                                {row2?.modifierGroup?.display_name ||
                                                                                                    row2?.modifierGroup
                                                                                                        ?.modifier_group_name}


                                                                                            </Typography>
                                                                                            <Typography className={styles.mx222}>

                                                                                                {row2.modifierGroup
                                                                                                    ?.modifier_group_description &&
                                                                                                    `${row2.modifierGroup?.modifier_group_description}`}

                                                                                            </Typography>

                                                                                        </Box>
                                                                                        <Grid container pl={0.3}>
                                                                                            {row2?.modifierGroup?.is_allow_multiple ? (
                                                                                                <>
                                                                                                    <Grid item xs={12}>
                                                                                                        <FormControl
                                                                                                            component="fieldset"
                                                                                                            variant="standard"
                                                                                                            fullWidth
                                                                                                        >
                                                                                                            <FormGroup>
                                                                                                                {row2?.modifierGroup
                                                                                                                    ?.modifierGroupModifier
                                                                                                                    ?.length
                                                                                                                    ? row2?.modifierGroup?.modifierGroupModifier
                                                                                                                        ?.sort(
                                                                                                                            (a, b) =>
                                                                                                                                a
                                                                                                                                    ?.modifier
                                                                                                                                    ?.series_no -
                                                                                                                                b
                                                                                                                                    ?.modifier
                                                                                                                                    ?.series_no
                                                                                                                        )
                                                                                                                        ?.map(
                                                                                                                            (
                                                                                                                                obbj,
                                                                                                                                i
                                                                                                                            ) => (
                                                                                                                                <Box
                                                                                                                                    sx={{
                                                                                                                                        display:
                                                                                                                                            "flex",
                                                                                                                                        flexDirection:
                                                                                                                                            "row",
                                                                                                                                        width: "100%"
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <Grid
                                                                                                                                        item
                                                                                                                                        xs={
                                                                                                                                            8.3
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        <Box
                                                                                                                                            sx={{
                                                                                                                                                display:
                                                                                                                                                    "flex",
                                                                                                                                                maxWidth: 400,
                                                                                                                                                minWidth: 400,
                                                                                                                                                justifyContent:
                                                                                                                                                    "space-between",
                                                                                                                                                alignItems:
                                                                                                                                                    "center",
                                                                                                                                                paddingRight: 3
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <FormControlLabel
                                                                                                                                                key={
                                                                                                                                                    i
                                                                                                                                                }
                                                                                                                                                control={
                                                                                                                                                    <Checkbox
                                                                                                                                                        icon={
                                                                                                                                                            <img
                                                                                                                                                                src={
                                                                                                                                                                    Unchecked
                                                                                                                                                                }
                                                                                                                                                                style={{
                                                                                                                                                                    width: "18px",
                                                                                                                                                                    height: "18px"
                                                                                                                                                                }}
                                                                                                                                                                alt="checked"
                                                                                                                                                            />
                                                                                                                                                        }
                                                                                                                                                        checkedIcon={
                                                                                                                                                            <img
                                                                                                                                                                src={
                                                                                                                                                                    Checked
                                                                                                                                                                }
                                                                                                                                                                alt="checkbox"
                                                                                                                                                                style={{
                                                                                                                                                                    width: "18px",
                                                                                                                                                                    height: "18px"
                                                                                                                                                                }}
                                                                                                                                                            />
                                                                                                                                                        }
                                                                                                                                                        checked={handleChecked(
                                                                                                                                                            itemIndex,
                                                                                                                                                            obbj.modifier_id
                                                                                                                                                        )}
                                                                                                                                                        onChange={(
                                                                                                                                                            e
                                                                                                                                                        ) => {
                                                                                                                                                            if (
                                                                                                                                                                e
                                                                                                                                                                    .target
                                                                                                                                                                    .checked
                                                                                                                                                            ) {
                                                                                                                                                                const tempValue =
                                                                                                                                                                    [
                                                                                                                                                                        ...itemDetail[
                                                                                                                                                                            itemIndex
                                                                                                                                                                        ]
                                                                                                                                                                            ?.checkboxModifier
                                                                                                                                                                    ];
                                                                                                                                                                tempValue.push(
                                                                                                                                                                    {
                                                                                                                                                                        modifierGroupId:
                                                                                                                                                                            obbj.modifier_group_id,
                                                                                                                                                                        modifierId:
                                                                                                                                                                            obbj.modifier_id,
                                                                                                                                                                        itemId: row2.item_id,
                                                                                                                                                                        quantity:
                                                                                                                                                                            itemDetail[
                                                                                                                                                                                itemIndex
                                                                                                                                                                            ]
                                                                                                                                                                                ?.quantity
                                                                                                                                                                                ? itemDetail[
                                                                                                                                                                                    itemIndex
                                                                                                                                                                                ]
                                                                                                                                                                                    ?.quantity
                                                                                                                                                                                : 1,
                                                                                                                                                                        price: obbj
                                                                                                                                                                            ?.modifier
                                                                                                                                                                            ?.price,
                                                                                                                                                                        modifierGroupName:
                                                                                                                                                                            row2.modifier_group_name,
                                                                                                                                                                        series_no:
                                                                                                                                                                            row2?.series_no,
                                                                                                                                                                        allowQuantityModification:
                                                                                                                                                                            row2
                                                                                                                                                                                ?.modifierGroup
                                                                                                                                                                                ?.allow_quantity_modification
                                                                                                                                                                    }
                                                                                                                                                                );
                                                                                                                                                                formik.setFieldValue(
                                                                                                                                                                    `itemDetail[${itemIndex}].checkboxModifier`,
                                                                                                                                                                    tempValue
                                                                                                                                                                );
                                                                                                                                                            } else {
                                                                                                                                                                let tempValue =
                                                                                                                                                                    [
                                                                                                                                                                        ...itemDetail[
                                                                                                                                                                            itemIndex
                                                                                                                                                                        ]
                                                                                                                                                                            ?.checkboxModifier
                                                                                                                                                                    ];
                                                                                                                                                                tempValue =
                                                                                                                                                                    tempValue.filter(
                                                                                                                                                                        (
                                                                                                                                                                            modifier
                                                                                                                                                                        ) =>
                                                                                                                                                                            modifier.modifierId !==
                                                                                                                                                                            obbj.modifier_id
                                                                                                                                                                    );
                                                                                                                                                                formik.setFieldValue(
                                                                                                                                                                    `itemDetail[${itemIndex}].checkboxModifier`,
                                                                                                                                                                    tempValue
                                                                                                                                                                );
                                                                                                                                                            }
                                                                                                                                                            formik.setTouched(
                                                                                                                                                                {
                                                                                                                                                                    "itemDetail.checkboxModifier": true
                                                                                                                                                                }
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                    />
                                                                                                                                                }
                                                                                                                                                label={
                                                                                                                                                    <Typography
                                                                                                                                                        className={
                                                                                                                                                            handleChecked(
                                                                                                                                                                itemIndex,
                                                                                                                                                                obbj.modifier_id
                                                                                                                                                            )
                                                                                                                                                                ? styles.mx6Selected
                                                                                                                                                                : styles.mx6
                                                                                                                                                        }
                                                                                                                                                    >
                                                                                                                                                        {
                                                                                                                                                            obbj
                                                                                                                                                                ?.modifier
                                                                                                                                                                ?.modifier_name
                                                                                                                                                        }{" "}
                                                                                                                                                        {obbj
                                                                                                                                                            ?.modifier
                                                                                                                                                            ?.price !==
                                                                                                                                                            "0.00"
                                                                                                                                                            ? `(+${FormatCurrency(
                                                                                                                                                                obbj
                                                                                                                                                                    ?.modifier
                                                                                                                                                                    ?.price
                                                                                                                                                            )})`
                                                                                                                                                            : ""}
                                                                                                                                                    </Typography>
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                            <Box
                                                                                                                                                className={
                                                                                                                                                    styles.mx4
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                {sortAndMapDietary(obbj?.modifier?.ModifierDietary)?.map(
                                                                                                                                                    (
                                                                                                                                                        diet,
                                                                                                                                                        ind
                                                                                                                                                    ) => (
                                                                                                                                                        <Typography
                                                                                                                                                            className={
                                                                                                                                                                styles.mx3
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            {" "}
                                                                                                                                                            {
                                                                                                                                                                diet
                                                                                                                                                            }
                                                                                                                                                        </Typography>
                                                                                                                                                    )
                                                                                                                                                )}
                                                                                                                                                
                                                                                                                                            </Box>
                                                                                                                                        </Box>
                                                                                                                                    </Grid>
                                                                                                                                    <Grid
                                                                                                                                        item
                                                                                                                                        xs={
                                                                                                                                            3.7
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        {row2
                                                                                                                                            ?.modifierGroup
                                                                                                                                            ?.allow_quantity_modification
                                                                                                                                            ? handleChecked(
                                                                                                                                                itemIndex,
                                                                                                                                                obbj.modifier_id
                                                                                                                                            ) && (
                                                                                                                                                <Grid
                                                                                                                                                    container
                                                                                                                                                    className={
                                                                                                                                                        styles.cxFlex
                                                                                                                                                    }
                                                                                                                                                >
                                                                                                                                                    <Grid
                                                                                                                                                        item
                                                                                                                                                        xs={
                                                                                                                                                            4
                                                                                                                                                        }
                                                                                                                                                        sx={{
                                                                                                                                                            textAlign:
                                                                                                                                                                "left"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <Typography
                                                                                                                                                            className={
                                                                                                                                                                styles.cx1
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            {obbj
                                                                                                                                                                ?.modifier
                                                                                                                                                                ?.price !==
                                                                                                                                                                "0.00"
                                                                                                                                                                ? `${FormatCurrency(
                                                                                                                                                                    obbj
                                                                                                                                                                        ?.modifier
                                                                                                                                                                        ?.price
                                                                                                                                                                )}`
                                                                                                                                                                : ""}
                                                                                                                                                        </Typography>
                                                                                                                                                    </Grid>
                                                                                                                                                    <Grid
                                                                                                                                                        item
                                                                                                                                                        xs={
                                                                                                                                                            4
                                                                                                                                                        }
                                                                                                                                                    >
                                                                                                                                                        <Box
                                                                                                                                                            sx={{
                                                                                                                                                                display:
                                                                                                                                                                    "flex",
                                                                                                                                                                flexDirection:
                                                                                                                                                                    "column",
                                                                                                                                                                mb: 0.4
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <OutlinedInput
                                                                                                                                                                id="outlined-adornment-weight"
                                                                                                                                                                aria-describedby="outlined-weight-helper-text"
                                                                                                                                                                key={
                                                                                                                                                                    obbj.modifier_id
                                                                                                                                                                }
                                                                                                                                                                inputProps={{
                                                                                                                                                                    "aria-label":
                                                                                                                                                                        "weight"
                                                                                                                                                                }}
                                                                                                                                                                value={
                                                                                                                                                                    itemDetail[
                                                                                                                                                                        itemIndex
                                                                                                                                                                    ]
                                                                                                                                                                        ?.checkboxModifier[
                                                                                                                                                                        getModifierIndex(
                                                                                                                                                                            itemIndex,
                                                                                                                                                                            obbj.modifier_id
                                                                                                                                                                        )
                                                                                                                                                                    ]
                                                                                                                                                                        .quantity
                                                                                                                                                                }
                                                                                                                                                                onKeyDown={
                                                                                                                                                                    enforceFormatForNumber
                                                                                                                                                                }
                                                                                                                                                                onChange={(
                                                                                                                                                                    e
                                                                                                                                                                ) => {
                                                                                                                                                                    if (
                                                                                                                                                                        e
                                                                                                                                                                            .target
                                                                                                                                                                            .value
                                                                                                                                                                            .length ===
                                                                                                                                                                        5
                                                                                                                                                                    )
                                                                                                                                                                        return false;

                                                                                                                                                                    if (
                                                                                                                                                                        e
                                                                                                                                                                            .target
                                                                                                                                                                            .value >
                                                                                                                                                                        0
                                                                                                                                                                    ) {
                                                                                                                                                                        const presentIndex =
                                                                                                                                                                            getModifierIndex(
                                                                                                                                                                                itemIndex,
                                                                                                                                                                                obbj.modifier_id
                                                                                                                                                                            );
                                                                                                                                                                        const tempValue =
                                                                                                                                                                            [
                                                                                                                                                                                ...itemDetail[
                                                                                                                                                                                    itemIndex
                                                                                                                                                                                ]
                                                                                                                                                                                    ?.checkboxModifier
                                                                                                                                                                            ];
                                                                                                                                                                        tempValue[
                                                                                                                                                                            presentIndex
                                                                                                                                                                        ].quantity =
                                                                                                                                                                            e.target.value;

                                                                                                                                                                        formik.setFieldValue(
                                                                                                                                                                            `itemDetail[${itemIndex}].checkboxModifier`,
                                                                                                                                                                            tempValue
                                                                                                                                                                        );
                                                                                                                                                                    } else {
                                                                                                                                                                        const presentIndex =
                                                                                                                                                                            getModifierIndex(
                                                                                                                                                                                itemIndex,
                                                                                                                                                                                obbj.modifier_id
                                                                                                                                                                            );
                                                                                                                                                                        const tempValue =
                                                                                                                                                                            [
                                                                                                                                                                                ...itemDetail[
                                                                                                                                                                                    itemIndex
                                                                                                                                                                                ]
                                                                                                                                                                                    ?.checkboxModifier
                                                                                                                                                                            ];
                                                                                                                                                                        tempValue[
                                                                                                                                                                            presentIndex
                                                                                                                                                                        ].quantity =
                                                                                                                                                                            "";

                                                                                                                                                                        formik.setFieldValue(
                                                                                                                                                                            `itemDetail[${itemIndex}].checkboxModifier`,
                                                                                                                                                                            tempValue
                                                                                                                                                                        );
                                                                                                                                                                    }
                                                                                                                                                                    formik.setTouched(
                                                                                                                                                                        {
                                                                                                                                                                            "itemDetail.checkboxModifier": true
                                                                                                                                                                        }
                                                                                                                                                                    );
                                                                                                                                                                    return true;
                                                                                                                                                                }}
                                                                                                                                                                sx={{
                                                                                                                                                                    "&": {
                                                                                                                                                                        height: "24px !important",
                                                                                                                                                                        maxWidth:
                                                                                                                                                                            "72px",
                                                                                                                                                                        ".MuiOutlinedInput-input":
                                                                                                                                                                        {
                                                                                                                                                                            padding:
                                                                                                                                                                                "1px 14px"
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }}
                                                                                                                                                            />
                                                                                                                                                        </Box>
                                                                                                                                                    </Grid>
                                                                                                                                                    <Grid
                                                                                                                                                        item
                                                                                                                                                        xs={
                                                                                                                                                            3
                                                                                                                                                        }
                                                                                                                                                        sx={{
                                                                                                                                                            textAlign:
                                                                                                                                                                "left"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        {" "}
                                                                                                                                                        <Typography
                                                                                                                                                            className={
                                                                                                                                                                styles.cx1
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            {FormatCurrency(
                                                                                                                                                                obbj
                                                                                                                                                                    ?.modifier
                                                                                                                                                                    ?.price *
                                                                                                                                                                itemDetail[
                                                                                                                                                                    itemIndex
                                                                                                                                                                ]
                                                                                                                                                                    ?.checkboxModifier[
                                                                                                                                                                    getModifierIndex(
                                                                                                                                                                        itemIndex,
                                                                                                                                                                        obbj.modifier_id
                                                                                                                                                                    )
                                                                                                                                                                ]
                                                                                                                                                                    .quantity
                                                                                                                                                            )}
                                                                                                                                                        </Typography>
                                                                                                                                                    </Grid>
                                                                                                                                                    <Grid
                                                                                                                                                        item
                                                                                                                                                        xs={
                                                                                                                                                            1
                                                                                                                                                        }
                                                                                                                                                        className={
                                                                                                                                                            styles.cxFlex
                                                                                                                                                        }
                                                                                                                                                        sx={{
                                                                                                                                                            textAlign:
                                                                                                                                                                "right"
                                                                                                                                                        }}
                                                                                                                                                    />
                                                                                                                                                </Grid>
                                                                                                                                            )
                                                                                                                                            : null}
                                                                                                                                    </Grid>
                                                                                                                                </Box>
                                                                                                                            )
                                                                                                                        )
                                                                                                                    : null}
                                                                                                            </FormGroup>
                                                                                                        </FormControl>
                                                                                                    </Grid>
                                                                                                </>
                                                                                            ) : (
                                                                                                <Grid item xs={12}>
                                                                                                    <FormControl fullWidth>
                                                                                                        <RadioGroup
                                                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                                                            defaultValue="female"
                                                                                                            name="radio-buttons-group"
                                                                                                            value={itemDetail[
                                                                                                                itemIndex
                                                                                                            ]?.radioModifier?.filter(
                                                                                                                (obj) =>
                                                                                                                    JSON.parse(obj)
                                                                                                                        .modifierGroupId ===
                                                                                                                    row2.modifier_group_id
                                                                                                            )}
                                                                                                            onChange={(e) => {
                                                                                                                
                                                                                                                // current radio button modifier group id
                                                                                                                const currentModifierGroupId =
                                                                                                                    JSON.parse(
                                                                                                                        e.target.value
                                                                                                                    ).modifierGroupId;

                                                                                                                const alreadyPresent = [
                                                                                                                    ...itemDetail[
                                                                                                                        itemIndex
                                                                                                                    ]?.radioModifier
                                                                                                                ];
                                                                                                                let alreadyPresentModifier =
                                                                                                                    { price: "0.00" };
                                                                                                                const tempValue = [];

                                                                                                                alreadyPresent.forEach(
                                                                                                                    (modifier) => {
                                                                                                                        const obj =
                                                                                                                            JSON.parse(
                                                                                                                                modifier
                                                                                                                            );
                                                                                                                        if (
                                                                                                                            obj.modifierGroupId ===
                                                                                                                            currentModifierGroupId
                                                                                                                        ) {
                                                                                                                            alreadyPresentModifier =
                                                                                                                                obj;
                                                                                                                        } else {
                                                                                                                            tempValue.push(
                                                                                                                                modifier
                                                                                                                            );
                                                                                                                        }
                                                                                                                    }
                                                                                                                );
                                                                                                                const newObj = JSON.parse(
                                                                                                                    e.target.value
                                                                                                                );
                                                                                                                // default quantity should be equal to number of guests selected
                                                                                                                if (
                                                                                                                    itemDetail[itemIndex]
                                                                                                                        ?.quantity !== ""
                                                                                                                ) {
                                                                                                                    newObj.quantity =
                                                                                                                        itemDetail[
                                                                                                                            itemIndex
                                                                                                                        ]?.quantity;
                                                                                                                } else {
                                                                                                                    newObj.quantity = 1;
                                                                                                                }

                               

                                                                                                                tempValue.push(
                                                                                                                    JSON.stringify(newObj)
                                                                                                                );
                                                                                                                formik.setFieldValue(
                                                                                                                    `itemDetail[${itemIndex}].radioModifier`,
                                                                                                                    tempValue
                                                                                                                );
                                                                                                                formik.setTouched({
                                                                                                                    "itemDetail.radioModifier": true
                                                                                                                });
                                                                                                            }}
                                                                                                        >
                                                                                                            {row2?.modifierGroup
                                                                                                                ?.modifierGroupModifier
                                                                                                                ?.length &&
                                                                                                                row2?.modifierGroup?.modifierGroupModifier
                                                                                                                    ?.sort(
                                                                                                                        (a, b) =>
                                                                                                                            a?.modifier
                                                                                                                                ?.series_no -
                                                                                                                            b?.modifier
                                                                                                                                ?.series_no
                                                                                                                    )
                                                                                                                    ?.map((obbj, i) => (
                                                                                                                        <Box
                                                                                                                            className={
                                                                                                                                styles.mx81
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <Grid
                                                                                                                                item
                                                                                                                                xs={8.3}
                                                                                                                                sx={{height:'26px !important'}}
                                                                                                                            >
                                                                                                                                <FormControlLabel
                                                                                                                                    key={
                                                                                                                                        i
                                                                                                                                    }
                                                                                                                                    sx={{
                                                                                                                                        "& .MuiRadio-root":
                                                                                                                                        {
                                                                                                                                            padding:
                                                                                                                                                "3px 9px"
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                    value={JSON.stringify(
                                                                                                                                        {
                                                                                                                                            modifierGroupId:
                                                                                                                                                row2.modifier_group_id,
                                                                                                                                            modifierId:
                                                                                                                                                obbj.modifier_id,
                                                                                                                                            itemId: row2.item_id,
                                                                                                                                            quantity:
                                                                                                                                                JSON.parse(
                                                                                                                                                    itemDetail[
                                                                                                                                                        itemIndex
                                                                                                                                                    ]?.radioModifier?.filter(
                                                                                                                                                        (
                                                                                                                                                            obj
                                                                                                                                                        ) =>
                                                                                                                                                            JSON.parse(
                                                                                                                                                                obj
                                                                                                                                                            )
                                                                                                                                                                .modifierGroupId ===
                                                                                                                                                            row2.modifier_group_id
                                                                                                                                                    )?.[0] ??
                                                                                                                                                    0
                                                                                                                                                )
                                                                                                                                                    ?.quantity ??
                                                                                                                                                1,
                                                                                                                                            price: obbj
                                                                                                                                                ?.modifier
                                                                                                                                                ?.price,
                                                                                                                                            series_no:
                                                                                                                                                row2?.series_no,
                                                                                                                                            modifierGroupName:
                                                                                                                                                row2
                                                                                                                                                    ?.modifierGroup
                                                                                                                                                    ?.modifier_group_name,
                                                                                                                                            allowQuantityModification:
                                                                                                                                                row2
                                                                                                                                                    ?.modifierGroup
                                                                                                                                                    ?.allow_quantity_modification
                                                                                                                                        }
                                                                                                                                    )}
                                                                                                                                    className={
                                                                                                                                        styles.mx8
                                                                                                                                    }
                                                                                                                                    control={
                                                                                                                                        <Radio
                                                                                                                                        checkedIcon={
                                                                                                                                            <BpCheckedIcon
                                                                                                                                                checked
                                                                                                                                            />
                                                                                                                                        }
                                                                                                                                        icon={
                                                                                                                                            <BpCheckedIcon />
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                    }
                                                                                                                                    label={
                                                                                                                                        <Typography
                                                                                                                                            className={
                                                                                                                                                handleCheckedForRadio(
                                                                                                                                                    itemIndex,
                                                                                                                                                    row2.modifier_group_id,
                                                                                                                                                    obbj.modifier_id
                                                                                                                                                )
                                                                                                                                                    ? styles.mx9Selected
                                                                                                                                                    : styles.mx9
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            {
                                                                                                                                                obbj
                                                                                                                                                    ?.modifier
                                                                                                                                                    ?.modifier_name
                                                                                                                                            }{" "}
                                                                                                                                            {obbj
                                                                                                                                                ?.modifier
                                                                                                                                                ?.price !==
                                                                                                                                                "0.00"
                                                                                                                                                ? `(+${FormatCurrency(
                                                                                                                                                    obbj
                                                                                                                                                        ?.modifier
                                                                                                                                                        ?.price
                                                                                                                                                )})`
                                                                                                                                                : ""}
                                                                                                                                            <Box
                                                                                                                                                className={
                                                                                                                                                    styles.mx7
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                 {sortAndMapDietary(obbj?.modifier?.ModifierDietary)?.map(
                                                                                                                                                    (
                                                                                                                                                        diet,
                                                                                                                                                        ind
                                                                                                                                                    ) => (
                                                                                                                                                        <Typography
                                                                                                                                                            className={
                                                                                                                                                                styles.mx3
                                                                                                                                                            }
                                                                                                                                                        >
                                                                                                                                                            {" "}
                                                                                                                                                            {
                                                                                                                                                                diet
                                                                                                                                                            }
                                                                                                                                                        </Typography>
                                                                                                                                                    )
                                                                                                                                                )}
                                                                                                                                            </Box>
                                                                                                                                        </Typography>
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            </Grid>
                                                                                                                            <Grid
                                                                                                                                item
                                                                                                                                xs={3.7}
                                                                                                                            >
                                                                                                                                {row2
                                                                                                                                    ?.modifierGroup
                                                                                                                                    ?.allow_quantity_modification
                                                                                                                                    ? getModifierIndexForRadio(
                                                                                                                                        itemIndex,
                                                                                                                                        obbj.modifier_id
                                                                                                                                    ) !==
                                                                                                                                    -1 && (
                                                                                                                                        <Grid
                                                                                                                                            container
                                                                                                                                            className={
                                                                                                                                                styles.cxFlex
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            <Grid
                                                                                                                                                item
                                                                                                                                                xs={
                                                                                                                                                    4
                                                                                                                                                }
                                                                                                                                                sx={{
                                                                                                                                                    textAlign:
                                                                                                                                                        "left"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <Typography
                                                                                                                                                    className={
                                                                                                                                                        styles.cx1
                                                                                                                                                    }
                                                                                                                                                >
                                                                                                                                                    {obbj
                                                                                                                                                        ?.modifier
                                                                                                                                                        ?.price !==
                                                                                                                                                        "0.00"
                                                                                                                                                        ? `${FormatCurrency(
                                                                                                                                                            obbj
                                                                                                                                                                ?.modifier
                                                                                                                                                                ?.price
                                                                                                                                                        )}`
                                                                                                                                                        : ""}
                                                                                                                                                </Typography>
                                                                                                                                            </Grid>
                                                                                                                                            <Grid
                                                                                                                                                item
                                                                                                                                                xs={
                                                                                                                                                    4
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                <Box
                                                                                                                                                    sx={{
                                                                                                                                                        display:
                                                                                                                                                            "flex",
                                                                                                                                                        flexDirection:
                                                                                                                                                            "column",
                                                                                                                                                        mb: 0.4
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <OutlinedInput
                                                                                                                                                        id="outlined-adornment-weight"
                                                                                                                                                        aria-describedby="outlined-weight-helper-text"
                                                                                                                                                        key={
                                                                                                                                                            obbj.modifier_id
                                                                                                                                                        }
                                                                                                                                                        inputProps={{
                                                                                                                                                            "aria-label":
                                                                                                                                                                "weight"
                                                                                                                                                        }}
                                                                                                                                                        value={
                                                                                                                                                            JSON.parse(
                                                                                                                                                                itemDetail[
                                                                                                                                                                    itemIndex
                                                                                                                                                                ]
                                                                                                                                                                    ?.radioModifier[
                                                                                                                                                                getModifierIndexForRadio(
                                                                                                                                                                    itemIndex,
                                                                                                                                                                    obbj.modifier_id
                                                                                                                                                                )
                                                                                                                                                                ]
                                                                                                                                                            )
                                                                                                                                                                .quantity
                                                                                                                                                        }
                                                                                                                                                        onKeyDown={
                                                                                                                                                            enforceFormatForNumber
                                                                                                                                                        }
                                                                                                                                                        onChange={(
                                                                                                                                                            e
                                                                                                                                                        ) => {
                                                                                                                                                            if (
                                                                                                                                                                e
                                                                                                                                                                    .target
                                                                                                                                                                    .value
                                                                                                                                                                    .length ===
                                                                                                                                                                4
                                                                                                                                                            )
                                                                                                                                                                return false;
                                                                                                                                                            if (
                                                                                                                                                                e
                                                                                                                                                                    .target
                                                                                                                                                                    .value >
                                                                                                                                                                0
                                                                                                                                                            ) {
                                                                                                                                                                const presentIndex =
                                                                                                                                                                    getModifierIndexForRadio(
                                                                                                                                                                        itemIndex,
                                                                                                                                                                        obbj.modifier_id
                                                                                                                                                                    );
                                                                                                                                                                const tempValue =
                                                                                                                                                                    [
                                                                                                                                                                        ...itemDetail[
                                                                                                                                                                            itemIndex
                                                                                                                                                                        ]
                                                                                                                                                                            ?.radioModifier
                                                                                                                                                                    ];
                                                                                                                                                                const obj =
                                                                                                                                                                    JSON.parse(
                                                                                                                                                                        tempValue[
                                                                                                                                                                        presentIndex
                                                                                                                                                                        ]
                                                                                                                                                                    );
                                                                                                                                                                obj.quantity =
                                                                                                                                                                    e.target.value;
                                                                                                                                                                tempValue[
                                                                                                                                                                    presentIndex
                                                                                                                                                                ] =
                                                                                                                                                                    JSON.stringify(
                                                                                                                                                                        obj
                                                                                                                                                                    );
                                                                                                                                                                formik.setFieldValue(
                                                                                                                                                                    `itemDetail[${itemIndex}].radioModifier`,
                                                                                                                                                                    tempValue
                                                                                                                                                                );
                                                                                                                                                            } else {
                                                                                                                                                                const presentIndex =
                                                                                                                                                                    getModifierIndexForRadio(
                                                                                                                                                                        itemIndex,
                                                                                                                                                                        obbj.modifier_id
                                                                                                                                                                    );
                                                                                                                                                                const tempValue =
                                                                                                                                                                    [
                                                                                                                                                                        ...itemDetail[
                                                                                                                                                                            itemIndex
                                                                                                                                                                        ]
                                                                                                                                                                            ?.radioModifier
                                                                                                                                                                    ];

                                                                                                                                                                const obj =
                                                                                                                                                                    JSON.parse(
                                                                                                                                                                        tempValue[
                                                                                                                                                                        presentIndex
                                                                                                                                                                        ]
                                                                                                                                                                    );
                                                                                                                                                                obj.quantity =
                                                                                                                                                                    "";
                                                                                                                                                                tempValue[
                                                                                                                                                                    presentIndex
                                                                                                                                                                ] =
                                                                                                                                                                    JSON.stringify(
                                                                                                                                                                        obj
                                                                                                                                                                    );

                                                                                                                                                                formik.setFieldValue(
                                                                                                                                                                    `itemDetail[${itemIndex}].radioModifier`,
                                                                                                                                                                    tempValue
                                                                                                                                                                );
                                                                                                                                                            }
                                                                                                                                                            formik.setTouched(
                                                                                                                                                                {
                                                                                                                                                                    "itemDetail.radioModifier": true
                                                                                                                                                                }
                                                                                                                                                            );
                                                                                                                                                            return true;
                                                                                                                                                        }}
                                                                                                                                                        sx={{
                                                                                                                                                            "&": {
                                                                                                                                                                height: "24px !important",
                                                                                                                                                                maxWidth:
                                                                                                                                                                    "72px",
                                                                                                                                                                ".MuiOutlinedInput-input":
                                                                                                                                                                {
                                                                                                                                                                    padding:
                                                                                                                                                                        "1px 14px"
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }}
                                                                                                                                                    />
                                                                                                                                                </Box>
                                                                                                                                            </Grid>
                                                                                                                                            <Grid
                                                                                                                                                item
                                                                                                                                                xs={
                                                                                                                                                    3
                                                                                                                                                }
                                                                                                                                                sx={{
                                                                                                                                                    textAlign:
                                                                                                                                                        "left"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                {" "}
                                                                                                                                                <Typography
                                                                                                                                                    className={
                                                                                                                                                        styles.cx1
                                                                                                                                                    }
                                                                                                                                                >
                                                                                                                                                    {FormatCurrency(
                                                                                                                                                        obbj
                                                                                                                                                            ?.modifier
                                                                                                                                                            ?.price *
                                                                                                                                                        JSON.parse(
                                                                                                                                                            itemDetail[
                                                                                                                                                                itemIndex
                                                                                                                                                            ]
                                                                                                                                                                ?.radioModifier[
                                                                                                                                                            getModifierIndexForRadio(
                                                                                                                                                                itemIndex,
                                                                                                                                                                obbj.modifier_id
                                                                                                                                                            )
                                                                                                                                                            ]
                                                                                                                                                        )
                                                                                                                                                            .quantity
                                                                                                                                                    )}
                                                                                                                                                </Typography>
                                                                                                                                            </Grid>
                                                                                                                                            <Grid
                                                                                                                                                item
                                                                                                                                                xs={
                                                                                                                                                    1
                                                                                                                                                }
                                                                                                                                                className={
                                                                                                                                                    styles.cxFlex
                                                                                                                                                }
                                                                                                                                                sx={{
                                                                                                                                                    textAlign:
                                                                                                                                                        "right"
                                                                                                                                                }}
                                                                                                                                            />
                                                                                                                                        </Grid>
                                                                                                                                    )
                                                                                                                                    : null}
                                                                                                                            </Grid>
                                                                                                                        </Box>
                                                                                                                    ))}
                                                                                                        </RadioGroup>
                                                                                                    </FormControl>
                                                                                                </Grid>
                                                                                            )}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                ))
                                                                                : null}
                                                                            <Grid item xs={8.5} mt={0.4}>
                                                                                <Stack
                                                                                    mb={1}
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        flexDirection: "row",
                                                                                        alignItems: "center"
                                                                                    }}
                                                                                >
                                                                                    <Stack>
                                                                                        {itemDetail[itemIndex]?.disableFlag ? (
                                                                                            <RemoveCircleIcon
                                                                                                onClick={() =>
                                                                                                    formik.setFieldValue(
                                                                                                        `itemDetail[${itemIndex}].disableFlag`,
                                                                                                        false
                                                                                                    )
                                                                                                }
                                                                                                sx={{
                                                                                                    fontSize: "1.125rem",
                                                                                                    color: "#00ab3a",
                                                                                                    cursor: "pointer"
                                                                                                }}
                                                                                            />
                                                                                        ) : (
                                                                                            <AddCircleIcon
                                                                                                onClick={() =>
                                                                                                    formik.setFieldValue(
                                                                                                        `itemDetail[${itemIndex}].disableFlag`,
                                                                                                        true
                                                                                                    )
                                                                                                }
                                                                                                sx={{
                                                                                                    fontSize: "1.125rem",
                                                                                                    color: "#00ab3a",
                                                                                                    cursor: "pointer"
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Stack>
                                                                                    <Stack ml={0.5}>
                                                                                        <Typography
                                                                                            className={styles.discount}
                                                                                            sx={{ color: "#00ab3a" }}
                                                                                        >
                                                                                            Special instructions
                                                                                        </Typography>
                                                                                    </Stack>
                                                                                </Stack>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={4.5}
                                                                                sx={{
                                                                                    minHeight: itemDetail[itemIndex]?.disableFlag
                                                                                        ? "115px"
                                                                                        : "0px",
                                                                                    height: itemDetail[itemIndex]?.disableFlag
                                                                                        ? 115
                                                                                        : "0px",
                                                                                    pt: itemDetail[itemIndex]?.disableFlag ? "10px" : "0px",

                                                                                    transition: "0.3s ease-out",
                                                                                    overflow: "hidden",
                                                                                    display: "flex",
                                                                                    flexDirection: "column"
                                                                                }}
                                                                            >
                                                                                <FormControl>
                                                                                    <CustomTextField
                                                                                        multiline
                                                                                        minRows={2}
                                                                                        value={itemDetail[itemIndex]?.specialInstruction}
                                                                                        sx={{
                                                                                            "&": {
                                                                                                height: "fit-content !important",
                                                                                                backgroundColor: "#A0A7B9",
                                                                                                "& .MuiOutlinedInput-root": {
                                                                                                    "& .MuiOutlinedInput-input": {
                                                                                                        height: `52px !important`,
                                                                                                        overflow:'scroll !important',
                                                                                                        overflowY: 'scroll'
                                                                                                    }
                                                                                                }

                                                                                            }

                                                                                        }}
                                                                                        fullWidth
                                                                                        label="Special instructions"
                                                                                        // {...formik.getFieldProps("specialInstruction")}
                                                                                        onChange={(e) => {
                                                                                            formik.setFieldValue(
                                                                                                `itemDetail[${itemIndex}].specialInstruction`,
                                                                                                e.target.value
                                                                                            );
                                                                                            formik.setTouched({
                                                                                                "itemDetail.specialInstruction": true
                                                                                            });
                                                                                        }}
                                                                                        inputProps={{ maxLength: 250 }}
                                                                                    />
                                                                                </FormControl>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <Grid item xs={12} mb={3}>
                    <Stack
                        mb={1}
                        mt={1}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                        }}
                    >
                        <Stack>
                            <AddCircleIcon
                                onClick={() =>
                                    handleCustomMenu()
                                }
                                sx={{
                                    fontSize: "1.125rem",
                                    color: "#00ab3a",
                                    cursor: "pointer"
                                }}
                            />
                        </Stack>
                        <Stack ml={0.5}>
                            <Typography
                                className={styles.discount}
                                sx={{ color: "#00ab3a", cursor: "pointer" }}
                                onClick={() =>
                                    handleCustomMenu()
                                }
                            >
                                Add custom menu item
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
                <CustomMenuItem
                    open={openCustomMenu}
                    buttonTitle="Submit"
                    title="Add custom menu item"

                    handleClose={handleCustomMenu}
                   
                    handleCustomMenuData={handleCustomMenuData}
                    noOnClose
                />
                
            </Grid>
        </Box>
    ) : (
        <Box>
            <Grid container spacing={0.5} className={styles.mainSection}>
                <Grid item xs={12}>
                    <Grid container spacing={0.5}>
                        <Grid item xs={6}>
                            <Typography className={styles.noItems}>No items added.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

const MainSection = (props) => {
    const { formik, orderItems, handleReset, setWaitForItem, waitForItem } = props;
    const { values } = formik;

    return (
        <>
            <Form>
                <FieldArray name="itemDetail">
                    {({ insert, remove, push }) => (
                        <FormField
                            setWaitForItem={setWaitForItem}
                            waitForItem={waitForItem}
                            handleReset={handleReset}
                            values={values}
                            orderItems={orderItems}
                            formik={formik}
                            insert={insert}
                            remove={remove}
                            push={push}
                        />
                    )}
                </FieldArray>
            </Form>
        </>
    );
};

export default MainSection;
