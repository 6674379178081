import React from "react";
import { Grid, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { ErrorMessage, FieldArray } from "formik";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomNewDateTimePicker from "commonComponent/newDateAndTimePicker/CustomNewDateTimePicker";
import Checked from "assets/images/icons/lightCheckedBox.svg";
import Unchecked from "assets/images/icons/unchecked.svg";

const StandardHours = (props) => {
    const { formik, readOnly } = props;
    const { setFieldValue, values } = formik;
    const { hoursOfOperation } = values;
    const { standardHours } = hoursOfOperation;

    const handleCheckBox = (obj, index) => (e) => {
        if (e.target.checked) {
            setFieldValue(`hoursOfOperation.standardHours[${index}]`, { ...obj, isChecked: true });
        } else {
            setFieldValue(`hoursOfOperation.standardHours[${index}]`, { ...obj, isChecked: false });
            setFieldValue(`hoursOfOperation.standardHours[${index}].endTime`, "");
            setFieldValue(`hoursOfOperation.standardHours[${index}].startTime`, "");
        }
    };

    return (
        <>
            <Grid item xs={12} container spacing={2}>
                <FieldArray
                    name="delivery"
                    render={() => (
                        <>
                            {standardHours.length &&
                                standardHours.map((obj, index) => (
                                    
                                    <Grid key={index+obj.startTime+obj.endTime} container item xs={12} spacing={2} alignItems="center">
                                        <Grid item xs={4}>
                                            <FormControlLabel
                                                disabled={readOnly}
                                                checked={obj.isChecked}
                                                {...(formik.errors?.hoursOfOperation?.standardHours?.[index]?.startTime && {
                                                    sx: {
                                                        mb: 1.5
                                                    }
                                                })}
                                                control={
                                                    <Checkbox
                                                        disableRipple
                                                        icon={<img src={Unchecked} style={{ width: "18px", height: "18px" }} alt="checked" />}
                                                        checkedIcon={
                                                            <img src={Checked} alt="checkbox" style={{ width: "18px", height: "18px" }} />
                                                        }
                                                    />
                                                }
                                                label={
                                                    <Typography variant={obj.isChecked ? "checkBoxSelected" : "checkBoxUnSelected"}>
                                                        {obj.day}
                                                    </Typography>
                                                }
                                                onChange={handleCheckBox(obj, index)}
                                            />
                                        </Grid>
                                        <Grid item xs={4} >
                                            <CustomNewDateTimePicker
                                                disabled={readOnly || !obj.isChecked}
                                                timepicker
                                                sx={{
                                                    "& .MuiOutlinedInput-input": {
                                                        minWidth: '50px',
                                                        paddingRight: '0px'
                                                    },
                                                    "&.MuiIconButton-root":{
                                                        marginRight:'4px'
                                                    }
                                                }}
                                                placeholder="hh:mm"
                                                value={obj.startTime || null}
                                                onChange={(val) => {
                                                    // if (new Date(val) > new Date(obj.endTime)) {
                                                    //     setFieldValue(`hoursOfOperation.standardHours[${index}].endTime`, "");
                                                    // }
                                                    setFieldValue(`hoursOfOperation.standardHours[${index}].startTime`, val);
                                                }}
                                            />
                                            <ErrorMessage
                                                name={`hoursOfOperation.standardHours[${index}].startTime`}
                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                            />
                                        </Grid>
                                        <Grid item xs={4} >
                                            <CustomNewDateTimePicker
                                                disabled={readOnly || !obj.isChecked}
                                               
                                                timepicker
                                                sx={{
                                                    "& .MuiOutlinedInput-input": {
                                                        minWidth: '50px',
                                                        paddingRight: '0px'
                                                    },
                                                    "&.MuiIconButton-root":{
                                                        marginRight:'4px'
                                                    }
                                                }}
                                                placeholder="hh:mm"
                                                value={obj.endTime || null}
                                                onChange={(val) => setFieldValue(`hoursOfOperation.standardHours[${index}].endTime`, val)}
                                            />

                                            <ErrorMessage
                                                name={`hoursOfOperation.standardHours[${index}].endTime`}
                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                        </>
                    )}
                />
            </Grid>
        </>
    );
};

export default StandardHours;
