// To handle long texts in table.
const ellipsizeText = (text, outputLength = 30) => {
    if (text !== undefined && text?.length > outputLength) {
        return `${text.substring(0, outputLength)}...`;
    }
   
    return text===undefined ? "" :text===null?"":text;
};

const ellipsizeTextForDownload = (text, outputLength = 30) => {
    if (text !== undefined && text?.length > outputLength) {
        return `${text.substring(0, outputLength)} ...`;
    }
    return ' ';
};

// To capitalize first letter all over the panel

const capitalizeFirstLetter = (string) => {
    if (string) {
        const newStr = string.replace(/_/g, ' ');
        return newStr.charAt(0).toUpperCase() + newStr.slice(1).toLowerCase();
    }
    return null
};

export { ellipsizeText, capitalizeFirstLetter,ellipsizeTextForDownload };
