import {
    Dialog,
    DialogActions,
    Grid,
    FormControlLabel,
    Checkbox,
    DialogContent,
    Stack,
    DialogContentText,
    DialogTitle,
    Slide,
    Radio,
    RadioGroup,
    Button,
    Typography
} from "@mui/material";
import Rating from "@mui/material/Rating";

import Cancel from "../../assets/images/icons/cancel.svg";
import React from "react";
import {Box} from "@mui/system";
import AnimateButton from "ui-component/extended/AnimateButton";
import styles from "./Driver.module.scss";
import RadioIcon from "assets/images/icons/Bg-Radio.svg";
import CheckedIcon from "assets/images/icons/checkedIconRadio.svg";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ReviewModal = (props) => {
    const {
        title,
        rating,
        description,
        open,
        handleClose,
        author,
        handleSubmit,
        hideBackdrop = true,
        buttonTitle = "Delete",
        noOnClose = false
    } = props;
    const [selected, setSelected] = React.useState("customer_order");
    return (
        <Dialog
            open={open}
           onClose= {handleClose}
            // TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            hideBackdrop={hideBackdrop}
            PaperProps={{sx: {boxShadow: "0px 0px 8px rgba(0,0,0,0.2)", padding: "33px 23px", width: "421px", position: "relative"}}}
        >
            <Box sx={{position: "absolute", right: "16px", top: "16px", cursor: "pointer"}} onClick={handleClose}>
                <img src={Cancel} alt="plus-icon" width="12px" height="12px" />
            </Box>
            <DialogTitle
                id="alert-dialog-title"
                className={styles.title}
                sx={{letterSpacing: "0.001px", marginTop: "-15px", padding: "0px 5px 15px 5px"}}
            >
                {title}
            </DialogTitle>
            <DialogContent sx={{marginTop: "-15px", padding: "5px 5px 5px 2px"}}>
                <DialogContentText
                    id="alert-dialog-description"
                    style={{marginTop: "4px", marginBottom: "4px"}}
                    className={styles.titleDivision}
                >
                    <Rating sx={{color: "#00ab3a", fontSize: "1.1962rem"}} value={rating} readOnly />
                </DialogContentText>
                <DialogContentText
                    id="alert-dialog-description"
                    style={{marginLeft: "3px", marginBottom: "7px"}}
                    className={styles.titleDivision}
                >
                    {`${description?.charAt(0).toUpperCase() + description?.slice(1)}`}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description" style={{marginLeft: "3px"}} className={styles.authorStyle}>
                    {`${author?.charAt(0).toUpperCase() + author?.slice(1)}`}
                </DialogContentText>
            </DialogContent>
            <Box sx={{display: "flex", justifyContent: "center", mt: 1.2}}>
                <AnimateButton>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{
                            width: "190px",
                            height: "35px",
                            fontSize: "0.875rem",
                            fontStyle: "normal",
                            fontWeight: 500,
                            marginTop: "10px",
                            bgcolor: "#00ab3a",

                            color: "#FFF",
                            ":hover": {
                                bgcolor: "#00ab3a"
                            }
                        }}
                        onClick={() => handleSubmit(selected)}
                    >
                        {buttonTitle}
                    </Button>
                </AnimateButton>
            </Box>
        </Dialog>
    );
};

export default ReviewModal;
