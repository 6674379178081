import { Dialog } from "@mui/material";
import React from "react";
import Slide from "@mui/material/Slide";
import useOrderDialog from "hooks/useOrderDialog";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CommonDilogBox = (props) => {
    const { openState, children} = props;
    const { open, toggleOrderdetailDialog } = useOrderDialog();

    return (
        <>
            <Dialog fullWidth
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            
                sx={{
                    zIndex: 1550,
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "784px",  // Set your width here
                            padding:'0px',
                        },
                    },
                }} open={open === openState} onClose={toggleOrderdetailDialog(false)} TransitionComponent={Transition}>
                {children}
            </Dialog>
        </>
    );
};

export default CommonDilogBox;
