// List all endpoints here
import {OFFLINE} from "network/offline";
import {HTTP_METHODS, APIRouter, APIWithOfflineRouter, APICustomRouter} from "../core/httpHelper";

// ******************
// Endpoint class takes 3 params in constructor ==> "endpoint", "http-method", "API-version"
// By default, version is set to v1
// ******************
export const API = {
    AUTH: {
        // if you want to return offline json if api fails
        LOGIN: new APIWithOfflineRouter("auth/authenticate", HTTP_METHODS.POST, OFFLINE.LOGIN),
        LOGIN_GOOGLE: new APIWithOfflineRouter("/user/google-login/", HTTP_METHODS.POST, OFFLINE.LOGINGOOGLE),
        LOGIN_FACEBOOK: new APIWithOfflineRouter("/user/facebook-login/", HTTP_METHODS.POST, OFFLINE.LOGINFACEBOOK),
        SIGNUP: new APIWithOfflineRouter("/user/signup/", HTTP_METHODS.POST, OFFLINE.SIGNUP),
        FORGOTPASSWORD: new APIWithOfflineRouter("auth/password/forgot", HTTP_METHODS.POST, OFFLINE.FORGOTPASSWORD),
        RESETPASSWORD: new APIWithOfflineRouter("auth/password/reset", HTTP_METHODS.PUT, OFFLINE.RESETPASSWORD),
        VERIFYOTP: new APIWithOfflineRouter("/auth/login", HTTP_METHODS.POST, OFFLINE.LOGIN),
        REFRESH_TOKEN: new APIRouter("auth/authenticate/refresh", HTTP_METHODS.POST)
    },
    USER: {
        PROFILE: new APIWithOfflineRouter("/user/profile/", HTTP_METHODS.GET, OFFLINE.PROFILE),
        LOGOUT: new APIWithOfflineRouter("auth/authenticate", HTTP_METHODS.DEL, OFFLINE.LOGOUT),
        STATE: new APIWithOfflineRouter("states", HTTP_METHODS.GET, OFFLINE.LOGOUT),
        CITY: (id) => new APIWithOfflineRouter(id ? `cities${id}` : "cities", HTTP_METHODS.GET, OFFLINE.LOGOUT)
    },
    MEDIA: {
        // if you want to upload a file with or without data
        UPLOAD: new APIRouter("/user/media/", HTTP_METHODS.POST)
    },
    THIRD_PARTY: {
        // If the base url is different from default
        CHECK: new APICustomRouter("https://example.com", "/test", HTTP_METHODS.GET)
    },
    LISTING: {
        LIST: new APIWithOfflineRouter("/user/dataListing", HTTP_METHODS.GET, OFFLINE.DATA),
        USER: new APIWithOfflineRouter("/user/userListing", HTTP_METHODS.GET, OFFLINE.USER)
    },
    SETTING: {
        STRIPE: new APIWithOfflineRouter("/user/dataListing", HTTP_METHODS.GET, OFFLINE.DATA)
    },
    MARKET: {
        ADD: new APIWithOfflineRouter("markets", HTTP_METHODS.POST, OFFLINE.DATA),
        GET: new APIWithOfflineRouter("markets", HTTP_METHODS.GET, OFFLINE.DATA),
        LIST: new APIWithOfflineRouter("markets/list", HTTP_METHODS.GET, OFFLINE.DATA),
        UPDATE: (id) => new APIWithOfflineRouter(`markets/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DELETE: (id) => new APIWithOfflineRouter(`markets/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA)
    },
    BRAND: {
        ADD: new APIWithOfflineRouter("brand", HTTP_METHODS.POST, OFFLINE.DATA),

        GET: new APIWithOfflineRouter("brand", HTTP_METHODS.GET, OFFLINE.DATA),
        GETBRANDWITHADDRESSFILTER: new APIWithOfflineRouter("brand/kitchenLocation/list", HTTP_METHODS.POST, OFFLINE.DATA),
        GETBYID: (id) => new APIWithOfflineRouter(`brand/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        UPDATE: (id) => new APIWithOfflineRouter(`brand/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DELETE: (id) => new APIWithOfflineRouter(`brand/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA),
        STATUS: (id) => new APIWithOfflineRouter(`brand/status/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA)
    },
    SERVICE: {
        GET: new APIWithOfflineRouter("cateringService", HTTP_METHODS.GET, OFFLINE.DATA),
        UPSERT: new APIWithOfflineRouter("cateringService", HTTP_METHODS.POST, OFFLINE.DATA)
    },
    CATEGORY: {
        MENUWISECATEGORY: new APIWithOfflineRouter("menu/category", HTTP_METHODS.GET, OFFLINE.DATA),
        ADD: new APIWithOfflineRouter("category", HTTP_METHODS.POST, OFFLINE.DATA),
        GET: new APIWithOfflineRouter("category", HTTP_METHODS.GET, OFFLINE.DATA),
        UPDATE: (id) => new APIWithOfflineRouter(`category/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DELETE: (id) => new APIWithOfflineRouter(`category/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA),
        DUPLICATE: (id) => new APIWithOfflineRouter(`category/${id}`, HTTP_METHODS.POST, OFFLINE.DATA)
    },
    LOCATION: {
        ADD: new APIWithOfflineRouter("kitchenLocation", HTTP_METHODS.POST, OFFLINE.DATA),
        GET: new APIWithOfflineRouter("kitchenLocation", HTTP_METHODS.GET, OFFLINE.DATA),
        GETBYID: (id) => new APIWithOfflineRouter(`kitchenLocation/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        UPDATE: (id) => new APIWithOfflineRouter(`kitchenLocation/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        STATUS: (id) => new APIWithOfflineRouter(`kitchenLocation/status/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DELETE: (id) => new APIWithOfflineRouter(`kitchenLocation/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA),
        GETBRANDWISELOCATION: new APIWithOfflineRouter(`brand/kitchenLocation/list`, HTTP_METHODS.GET, OFFLINE.DATA)
    },
    ITEM: {
        ADD: new APIWithOfflineRouter("item", HTTP_METHODS.POST, OFFLINE.DATA),
        GET: new APIWithOfflineRouter("item", HTTP_METHODS.GET, OFFLINE.DATA),
        GETMASTERLIST: new APIWithOfflineRouter("item/list/menuItems", HTTP_METHODS.GET, OFFLINE.DATA),
        GETUNASSIGNED: new APIWithOfflineRouter(`item/unassigned/list`, HTTP_METHODS.GET, OFFLINE.DATA),
        GETUNASSIGNEDUPDATED: (menuId, id) =>
            new APIWithOfflineRouter(`item/unassigned/menu/${menuId}/category/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        GETWITHACTIVEMODIFIER: new APIWithOfflineRouter("item?role=Customer", HTTP_METHODS.GET, OFFLINE.DATA),
        UPDATE: (id) => new APIWithOfflineRouter(`item/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DELETE: (id) => new APIWithOfflineRouter(`item/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA),
        DUPLICATE: (id) => new APIWithOfflineRouter(`item/duplicate/${id}`, HTTP_METHODS.POST, OFFLINE.DATA),
        BULKDELETE: new APIWithOfflineRouter("item/bulk/delete", HTTP_METHODS.POST, OFFLINE.DATA)
    },
    MENU: {
        ADD: new APIWithOfflineRouter("menu", HTTP_METHODS.POST, OFFLINE.DATA),
        GET: new APIWithOfflineRouter("menu", HTTP_METHODS.GET, OFFLINE.DATA),
        GETUNASSIGNED: new APIWithOfflineRouter("menu/unassigned", HTTP_METHODS.GET, OFFLINE.DATA),
        GETBYID: (id) => new APIWithOfflineRouter(`menu/menuDetails/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        GETMENUITEM: (id) => new APIWithOfflineRouter(`item/menu/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        GETMENUITEMMODIFIERS: (id) => new APIWithOfflineRouter(`item/modifierGroup/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        UPDATE: (id) => new APIWithOfflineRouter(`menu/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DELETE: (id) => new APIWithOfflineRouter(`menu/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA),
        STATUS: (id) => new APIWithOfflineRouter(`menu/status/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DUPLICATE: (id) => new APIWithOfflineRouter(`menu/duplicate/${id}`, HTTP_METHODS.POST, OFFLINE.DATA),
        GETALLMENUS: new APIWithOfflineRouter("menu/master", HTTP_METHODS.GET, OFFLINE.DATA),
        GETCATEGORYBYID: (id) => new APIWithOfflineRouter(`menu/detail/category/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        GETBRANDBYID: (id) => new APIWithOfflineRouter(`menu/detail/brand/${id}`, HTTP_METHODS.GET, OFFLINE.DATA)
    },
    IMAGE: {
        ADD: new APIWithOfflineRouter("upload/image", HTTP_METHODS.POST, OFFLINE.DATA),
        GET: new APIWithOfflineRouter("upload/image", HTTP_METHODS.GET, OFFLINE.DATA),
        DELETE: new APIWithOfflineRouter(`upload/image`, HTTP_METHODS.DEL, OFFLINE.DATA)
    },
    LABEL: {
        ADD: new APIWithOfflineRouter("item/label", HTTP_METHODS.POST, OFFLINE.DATA),
        GET: new APIWithOfflineRouter("item/label/list", HTTP_METHODS.GET, OFFLINE.DATA)
    },
    MODIFIERGROUP: {
        ADD: new APIWithOfflineRouter("modifierGroup", HTTP_METHODS.POST, OFFLINE.DATA),
        GET: new APIWithOfflineRouter("modifierGroup", HTTP_METHODS.GET, OFFLINE.DATA),
        GETBYID: (id) => new APIWithOfflineRouter(`modifierGroup/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        GETMODIFIERDATABYID: new APIWithOfflineRouter(`modifier/modifierGroup`, HTTP_METHODS.GET, OFFLINE.DATA),
        UPDATE: (id) => new APIWithOfflineRouter(`modifierGroup/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DELETE: (id) => new APIWithOfflineRouter(`modifierGroup/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA),
        STATUS: (id) => new APIWithOfflineRouter(`modifierGroup/status/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        GETBYITEMID: (id) => new APIWithOfflineRouter(`item/itemModifierGroup/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        SOFTDELETE: (id) => new APIWithOfflineRouter(`modifierGroup/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA),
        ADDMODIFIERTOITEM: (id) => new APIWithOfflineRouter(`item/itemModifierGroup/${id}`, HTTP_METHODS.POST, OFFLINE.DATA),
        ADDMODIFIERTOGROUP: new APIWithOfflineRouter(`modifierGroup/modifier`, HTTP_METHODS.POST, OFFLINE.DATA)
    },
    MODIFIER: {
        ADD: new APIWithOfflineRouter("modifier", HTTP_METHODS.POST, OFFLINE.DATA),
        GET: new APIWithOfflineRouter("modifier", HTTP_METHODS.GET, OFFLINE.DATA),
        DUPLICATE: (id) => new APIWithOfflineRouter(`modifier/${id}`, HTTP_METHODS.POST, OFFLINE.DATA),
        UPDATE: (id) => new APIWithOfflineRouter(`modifier/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DELETE: (id) => new APIWithOfflineRouter(`modifier/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA),
        DELETEMODIFIERWITHGROUPID: (id) => new APIWithOfflineRouter(`modifier/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA),
        SEARCHMODIFIER: new APIWithOfflineRouter("modifier", HTTP_METHODS.GET, OFFLINE.DATA)
    },
    PROMOTIONS: {
        ADD: new APIWithOfflineRouter("discount/", HTTP_METHODS.POST, OFFLINE.DATA),
        GET: new APIWithOfflineRouter("discount", HTTP_METHODS.GET, OFFLINE.DATA),
        POST: (id) => new APIWithOfflineRouter(`discount/validate?user_id=${id}`, HTTP_METHODS.POST, OFFLINE.DATA),
        GETBYID: (id) => new APIWithOfflineRouter(`discount/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        UPDATE: (id) => new APIWithOfflineRouter(`discount/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DELETE: (id) => new APIWithOfflineRouter(`discount/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA),
        BULKDELETE: new APIWithOfflineRouter(`discount/bulk/delete/`, HTTP_METHODS.DEL, OFFLINE.DATA),
        STATUS: (id) => new APIWithOfflineRouter(`discount/status/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DUPLICATE: (id) => new APIWithOfflineRouter(`discount/duplicate/${id}`, HTTP_METHODS.POST, OFFLINE.DATA)
    },
    ORDER: {
        ADD: new APIWithOfflineRouter("order/create/", HTTP_METHODS.POST, OFFLINE.DATA),
        GET: new APIWithOfflineRouter("order/list", HTTP_METHODS.GET, OFFLINE.DATA),
        COUNT: new APIWithOfflineRouter("order/count", HTTP_METHODS.GET, OFFLINE.DATA),
        GETBYID: (id) => new APIWithOfflineRouter(`order/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        UPDATE: new APIWithOfflineRouter(`order/update/`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DELETE: (id) => new APIWithOfflineRouter(`order/delete/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA),
        STATUS: (id, status, url) =>
            new APIWithOfflineRouter(`order/update/status?orderId=${id}&status=${status}&url=${url}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DUPLICATE: (id) => new APIWithOfflineRouter(`order/duplicate/${id}`, HTTP_METHODS.POST, OFFLINE.DATA),
        QUOTE: new APIWithOfflineRouter("order/quote/email", HTTP_METHODS.POST, OFFLINE.DATA),
        MULTIPLEQUOTE: new APIWithOfflineRouter("order/multiple/quote/email", HTTP_METHODS.POST, OFFLINE.DATA),
        QUOTEDETAIL: (id) => new APIWithOfflineRouter(`order/detail/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        BULKDELETE: new APIWithOfflineRouter("order/bulk/delete", HTTP_METHODS.POST, OFFLINE.DATA),
        ORDERSTATE: new APIWithOfflineRouter(`order/update/status`, HTTP_METHODS.PUT, OFFLINE.DATA),
        PLACEORDER: new APIWithOfflineRouter("order/save/card", HTTP_METHODS.POST, OFFLINE.DATA),
        REFUND: new APIWithOfflineRouter("payment/refund", HTTP_METHODS.POST, OFFLINE.DATA),
        EXTRAPAYMENT: new APIWithOfflineRouter("payment/admin/initiate/topup", HTTP_METHODS.POST, OFFLINE.DATA),
        DRIVE: new APIWithOfflineRouter("order/time/duration", HTTP_METHODS.GET, OFFLINE.DATA),
        PREFERREDPRICE: (id) => new APIWithOfflineRouter(`customer/preferredPricingCustomer/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        ORDERNOTES: new APIWithOfflineRouter("order/orderNote/create", HTTP_METHODS.POST, OFFLINE.DATA),
        ORDERHISTORY: (id) => new APIWithOfflineRouter(`order/history/note/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        UPDATECANCELFEE: new APIWithOfflineRouter("payment/charge/fee", HTTP_METHODS.POST, OFFLINE.DATA),
        MARKINGPAID: new APIWithOfflineRouter(`order/paid/status`, HTTP_METHODS.PUT, OFFLINE.DATA),
        CUTOFFVERIFY: new APIWithOfflineRouter("order/check/admin/brand", HTTP_METHODS.POST, OFFLINE.DATA),
        PREVIEW: new APIWithOfflineRouter("order/preview/email", HTTP_METHODS.POST, OFFLINE.DATA),
        GETBYCUSTOMERID: (id) => new APIWithOfflineRouter(`order/list/customer/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        GETTEMPLATELIST: new APIWithOfflineRouter(`orderTemplate/list/orders`, HTTP_METHODS.GET, OFFLINE.DATA),
        TEMPLATEDETAILBYID: (id) => new APIWithOfflineRouter(`OrderTemplate/detail/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        ORDERSCHEDULE: (id) => new APIWithOfflineRouter(`order/schedule/calendarEvent/${id}`, HTTP_METHODS.POST, OFFLINE.DATA),
        CUSTOMITEMLIST: new APIWithOfflineRouter("item/customItems/list", HTTP_METHODS.GET, OFFLINE.DATA),
        HOUSEACCOUNTPAYMENT: (orderId) =>
            new APIWithOfflineRouter(`order/houseAccount/payment/${orderId}`, HTTP_METHODS.POST, OFFLINE.DATA),
        HOUSEACCOUNTPAYMENTLIST: (orderId) =>
            new APIWithOfflineRouter(`order/houseAccount/paymentDetails/${orderId}`, HTTP_METHODS.GET, OFFLINE.DATA),
        CUTOFFTIME: new APIWithOfflineRouter(`order/cutoff-time/`, HTTP_METHODS.GET, OFFLINE.DATA),
        MULTIPLECUTOFFTIME: new APIWithOfflineRouter(`order/mulitiple/cutoff-time`, HTTP_METHODS.GET, OFFLINE.DATA),
        EDITEXPIRATIONTIME: (orderId) => new APIWithOfflineRouter(`order/expiry-time/order/${orderId}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        SENDRECEIPT: (orderId) => new APIWithOfflineRouter(`order/send/receipt/${orderId}`, HTTP_METHODS.GET, OFFLINE.DATA),
        ORDERBYCARD: new APIWithOfflineRouter(`order/change/card`, HTTP_METHODS.PUT, OFFLINE.DATA)
    },
    TEMPLATE: {
        ADD: new APIWithOfflineRouter("orderTemplate/newOrderTemplate", HTTP_METHODS.POST, OFFLINE.DATA),
        DUPLICATE: (id) => new APIWithOfflineRouter(`orderTemplate/duplicate/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        GET: new APIWithOfflineRouter("orderTemplate/list", HTTP_METHODS.GET, OFFLINE.DATA),
        QUOTEDETAIL: (id) => new APIWithOfflineRouter(`orderTemplate/detail/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        BULKDELETE: new APIWithOfflineRouter("orderTemplate/bulk/delete", HTTP_METHODS.DEL, OFFLINE.DATA),
        UPDATE: (id) => new APIWithOfflineRouter(`orderTemplate/update/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA)
    },

    CUSTOMER: {
        REVIEWUPDATE: (id) => new APIWithOfflineRouter(`review/${id}/publish`, HTTP_METHODS.PUT, OFFLINE.DATA),
        GETREVIEWDATA: (id) => new APIWithOfflineRouter(`review/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        REVIEWUNPUBLISH: (id) => new APIWithOfflineRouter(`review/${id}/unpublish`, HTTP_METHODS.PUT, OFFLINE.DATA),
        REVIEW: new APIWithOfflineRouter("review", HTTP_METHODS.GET, OFFLINE.DATA),
        GET: new APIWithOfflineRouter("customer/auth/list", HTTP_METHODS.GET, OFFLINE.DATA),
        ADD: new APIWithOfflineRouter("customer/create", HTTP_METHODS.POST, OFFLINE.DATA),
        BULKDELETE: new APIWithOfflineRouter("customer/bulk/delete", HTTP_METHODS.POST, OFFLINE.DATA),
        GETCUSTOMERDATA: (id) => new APIWithOfflineRouter(`customer/profile/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        UPDATEPROFILE: (id) => new APIWithOfflineRouter(`customer/profile/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        GETNOTES: (id) => new APIWithOfflineRouter(`customer/customerNote/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        DELETENOTES: (id) => new APIWithOfflineRouter(`customer/customerNote/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA),
        CREATENOTES: (id) => new APIWithOfflineRouter(`customer/customerNote/${id}`, HTTP_METHODS.POST, OFFLINE.DATA),
        UPDATENOTES: (id) => new APIWithOfflineRouter(`customer/customerNote/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        GETPREFERREDPRICING: (id) => new APIWithOfflineRouter(`customer/preferredPricingCustomer/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        UPDATEPREFERREDPRICING: (id) =>
            new APIWithOfflineRouter(`customer/updatePreferredPricingCustomer/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        CREATEPREFERREDPRICING: (id) =>
            new APIWithOfflineRouter(`customer/updatePreferredPricingCustomer/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        GETADDRESSLIST: (id) => new APIWithOfflineRouter(`customer/address/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        EDITADDRESS: (id) => new APIWithOfflineRouter(`customer/address/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        GETCARDLIST: (id) => new APIWithOfflineRouter(`customer/card/admin/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        UPLOADFILE: new APIWithOfflineRouter("upload/admin/file", HTTP_METHODS.POST, OFFLINE.DATA),
        DELETEFILE: new APIWithOfflineRouter("upload/admin/file", HTTP_METHODS.DEL, OFFLINE.DATA),
        NOTESBULKDELETE: new APIWithOfflineRouter("customer/customerNote/bulk/delete", HTTP_METHODS.POST, OFFLINE.DATA),
        CUSTOMERORDERLIST: (id) => new APIWithOfflineRouter(`order/customer/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        ORDERDELETELIST: (id) => new APIWithOfflineRouter(`order/customer/delete/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        RESENDINVITE: (id) => new APIWithOfflineRouter(`customer/resendInvite/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA)
    },
    COMPANY: {
        GET: new APIWithOfflineRouter("company", HTTP_METHODS.GET, OFFLINE.DATA),
        ADD: new APIWithOfflineRouter("company", HTTP_METHODS.POST, OFFLINE.DATA)
    },
    ADDRESS: {
        CREATE: new APIWithOfflineRouter("customer/address", HTTP_METHODS.POST, OFFLINE.DATA),
        DELETE: (id) => new APIWithOfflineRouter(`customer/address/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA)
    },
    CARD: {
        ADD: new APIWithOfflineRouter("customer/card/admin", HTTP_METHODS.POST, OFFLINE.DATA),
        LISTING: (id) => new APIWithOfflineRouter(`customer/card/admin/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),

        DELETE: (customerId, id) =>
            new APIWithOfflineRouter(`customer/card/admin/customerCard/${customerId}/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA),
        HOUSEACCOUNT: (id) => new APIWithOfflineRouter(`customer/admin/houseAccount/${id}`, HTTP_METHODS.POST, OFFLINE.DATA),
        HOUSEACCOUNTUPDATE: (id) => new APIWithOfflineRouter(`customer/admin/houseAccount/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        HOUSEACCOUNTDATA: (id) => new APIWithOfflineRouter(`customer/admin/houseAccount/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        HOUSEACCOUNTLISTING: new APIWithOfflineRouter("customer/admin/customerHouseAccountLists", HTTP_METHODS.GET, OFFLINE.DATA),
        HOUSEACCOUNTACTIVE:new APIWithOfflineRouter(`customer/card/houseaccount/activate`, HTTP_METHODS.PUT, OFFLINE.DATA),
        HOUSEACCOUNTDEACTIVE:new APIWithOfflineRouter(`customer/card/houseaccount/deactivate`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DELETEHOUSEACCOUNT:new APIWithOfflineRouter(`customer/card/houseaccount/delete`, HTTP_METHODS.DEL, OFFLINE.DATA),
        BULKDELETE: new APIWithOfflineRouter("customer/admin/deleteCustomerHouseAccount", HTTP_METHODS.PUT, OFFLINE.DATA)
    },
    DRIVER: {
        LISTING: new APIWithOfflineRouter("driver", HTTP_METHODS.GET, OFFLINE.DATA),
        SECONDARYLISTING: (id) => new APIWithOfflineRouter(`driver/secondaryDriver/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        CREATE: new APIWithOfflineRouter("driver", HTTP_METHODS.POST, OFFLINE.DATA),
        UPDATE: new APIWithOfflineRouter("driver", HTTP_METHODS.PUT, OFFLINE.DATA),
        ASSIGN: new APIWithOfflineRouter("driver/assign", HTTP_METHODS.POST, OFFLINE.DATA),
        SECONDARYASSIGN: new APIWithOfflineRouter("driver/assign/secondaryDriver", HTTP_METHODS.POST, OFFLINE.DATA),
        DELETE: (id) => new APIWithOfflineRouter(`driver/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA)
    },
    ROLES: {
        CREATE: new APIWithOfflineRouter("role", HTTP_METHODS.POST, OFFLINE.DATA),
        LISTING: new APIWithOfflineRouter("role/list", HTTP_METHODS.GET, OFFLINE.DATA),
        DELETE: (id) => new APIWithOfflineRouter(`role/${id}`, HTTP_METHODS.DEL, OFFLINE.DATA),
        STATUS: (id) => new APIWithOfflineRouter(`role/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        UPDATE: (id) => new APIWithOfflineRouter(`role/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DETAIL: (id) => new APIWithOfflineRouter(`role/detail/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        MODULES: new APIWithOfflineRouter("role/module/list", HTTP_METHODS.GET, OFFLINE.DATA)
    },
    REPORTS: {
        ORDERLISTING: new APIWithOfflineRouter("report/orders", HTTP_METHODS.GET, OFFLINE.DATA),
        SALESLISTING: new APIWithOfflineRouter("report/sales", HTTP_METHODS.GET, OFFLINE.DATA)
    },
    DELIVERIES: {
        AVAILABLEDRIVERLIST: new APIWithOfflineRouter("driver/assigned/list", HTTP_METHODS.GET, OFFLINE.DATA),
        UNAVAILABLEDRIVERLIST: new APIWithOfflineRouter("driver/unassigned/list", HTTP_METHODS.GET, OFFLINE.DATA),
        UNASSIGNDRIVERLIST: new APIWithOfflineRouter("order/unassigned/list", HTTP_METHODS.GET, OFFLINE.DATA),
        ASSIGNDRIVERLIST: new APIWithOfflineRouter("order/assigned/list", HTTP_METHODS.GET, OFFLINE.DATA),
        COMPLETEDORDERLIST: new APIWithOfflineRouter("order/completed/list", HTTP_METHODS.GET, OFFLINE.DATA),
        DELIVERIESLIST: new APIWithOfflineRouter("order/delivery/list", HTTP_METHODS.POST, OFFLINE.DATA),
        DEASSIGN: (id) => new APIWithOfflineRouter(`driver/unAssign/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        CHANGEDRIVER: new APIWithOfflineRouter("driver/change", HTTP_METHODS.POST, OFFLINE.DATA)
    },
    ACCOUNT: {
        RESETPASS: new APIWithOfflineRouter("auth/password/update", HTTP_METHODS.PUT, OFFLINE.DATA),
        GETACCOUNT: new APIWithOfflineRouter("auth/admin/details", HTTP_METHODS.GET, OFFLINE.PROFILE),
        UPDATEACCOUNT: new APIWithOfflineRouter("auth/admin/update", HTTP_METHODS.PUT, OFFLINE.PROFILE)
    },

    SUBADMIN: {
        LISTING: new APIWithOfflineRouter("auth/subAdmin/list", HTTP_METHODS.GET, OFFLINE.DATA),
        CREATE: new APIWithOfflineRouter("auth/subAdmin/register", HTTP_METHODS.POST, OFFLINE.DATA),
        UPDATE: new APIWithOfflineRouter(`auth/subAdmin/status`, HTTP_METHODS.PUT, OFFLINE.DATA),
        PUT: (id) => new APIWithOfflineRouter(`auth/subAdmin/update/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DETAIL: (id) => new APIWithOfflineRouter(`auth/subAdmin/detail/${id}`, HTTP_METHODS.GET, OFFLINE.DATA),
        REINVITE: (id) => new APIWithOfflineRouter(`auth/resend/invite/${id}`, HTTP_METHODS.PUT, OFFLINE.DATA),
        DELETE: (id) => new APIWithOfflineRouter(`auth/subAdmin/delete/${id}`, HTTP_METHODS.POST, OFFLINE.DATA),
        BULKDELETE: new APIWithOfflineRouter(`auth/subAdmin/bulk/delete`, HTTP_METHODS.DEL, OFFLINE.DATA),
        ASSIGNMANAGER: new APIWithOfflineRouter(`order/assign-account-manager`, HTTP_METHODS.PUT, OFFLINE.DATA)
    }
};
