import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {FormControlLabel, IconButton, InputAdornment, ListSubheader, Typography, Button, Box} from "@mui/material";
import CustomTextField from "./CustomTextField";
import Unchecked from "assets/images/icons/unchecked.svg";
import Checked from "assets/images/icons/lightCheckedBox.svg";

export default function CustomSelectWithCheckbox(props) {
    const {
        label,
        menuItems = [],
        values = [],
        onChange,
        disabled = false,
        selectAll = false,
        hasSearch = false,
        sx = null,
        labelProps = null,
        height = null,
        fromRefund = false
    } = props;
    console.log("menuItems : ", menuItems);
    const [searchablemenuItems, setSearchableMenuItem] = React.useState([]);
    const [search, setSearch] = React.useState("");
    const [itemList, setItemList] = React.useState([]);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSave = () => {
        handleClose(); // Close the popover
    };

    const handleSearch = (e) => {
        e.preventDefault();
        if (e.target.value) {
            const SearchedItems = itemList.filter((el) => el.label.toLowerCase().includes(e.target.value.toLowerCase()));
            setSearchableMenuItem(SearchedItems || []);
        } else {
            setSearchableMenuItem(itemList);
        }
        setSearch(e.target.value);
    };

    React.useEffect(() => {
        setItemList([...menuItems?.sort((a, b) => a?.label?.localeCompare(b?.label))]);

        setSearchableMenuItem([...menuItems?.sort((a, b) => a?.label?.localeCompare(b?.label))]);
    }, [menuItems]);

    const isChecked = (obj) => {
        return values?.some((el) => el === (fromRefund ? obj.orderItemId : obj.id));
    };

    const handleChange = (event) => {
        const {
            target: {value}
        } = event;

        onChange(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            onChange(searchablemenuItems.map((el) => (fromRefund ? el.orderItemId : el.id)));
        } else {
            onChange([]);
        }
    };

    return (
        <div>
            <FormControl fullWidth>
                <InputLabel {...labelProps} id="demo-multiple-checkbox-label">
                    {label}
                </InputLabel>
                <Select
                    disabled={disabled}
                    {...(height && {height: height})}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={values}
                    onChange={handleChange}
                    input={<OutlinedInput label={label} />}
                    renderValue={(id) =>
                        itemList
                            .filter((el) => id.includes(fromRefund ? el.orderItemId : el.id))
                            .map((obj) => obj?.label)
                            .join(", ")
                    }
                    sx={{
                        "& .MuiOutlinedInput-input": {
                            color: "#212121",
                            fontSize: "14px",
                            fontWeight: 400
                        },

                        ...(sx && {...sx})
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                maxHeight: "60vh"
                            }
                        },
                        autoFocus: false
                    }}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
                >
                    {hasSearch && (
                        <ListSubheader sx={{pt: 1, pb: 1}}>
                            <CustomTextField
                                placeholder="Search"
                                autoFocus
                                fullWidth
                                value={search}
                                onChange={handleSearch}
                                {...(search?.length && {
                                    InputProps: {
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    onClick={(e) => {
                                                        setSearch("");
                                                        setSearchableMenuItem([...itemList]);
                                                    }}
                                                >
                                                    <Typography>X</Typography>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }
                                })}
                                onKeyDown={(e) => {
                                    if (e.key !== "Escape") {
                                        // Prevents autoselecting item while typing (default Select behaviour)
                                        e.stopPropagation();
                                    }
                                }}
                            />
                        </ListSubheader>
                    )}
                    {searchablemenuItems.length > 0 && selectAll && (
                        <MenuItem sx={{pl: 1.4, height: "30px"}}>
                            <FormControlLabel
                                checked={searchablemenuItems.length > 0 && values.length === searchablemenuItems.length}
                                control={
                                    <Checkbox
                                        disableRipple
                                        onChange={handleSelectAll}
                                        icon={<img src={Unchecked} style={{width: "18px", height: "18px"}} alt="checked" />}
                                        checkedIcon={<img src={Checked} alt="checkbox" width="18px" height="18px" />}
                                    />
                                }
                                label={<ListItemText primary="Select all" />}
                            />
                        </MenuItem>
                    )}
                    {searchablemenuItems.map((obj, i) => (
                        <MenuItem
                            key={i}
                            value={fromRefund ? obj.orderItemId : obj.id}
                            sx={{p: 0, maxWidth: "450px !important", alignItems: "start !important", marginTop: "3px"}}
                        >
                            <Checkbox
                                checked={isChecked(obj)}
                                disableRipple
                                icon={<img src={Unchecked} style={{width: "18px", height: "18px"}} alt="checked" />}
                                checkedIcon={<img src={Checked} alt="checkbox" style={{width: "18px", height: "18px"}} />}
                            />
                            <ListItemText primary={obj.label} sx={{wordBreak: "break-word", whiteSpace: "break-spaces"}} />
                        </MenuItem>
                    ))}
                    {searchablemenuItems.length === 0 && (
                        <MenuItem value="" sx={{p: 0, textAlign: "center"}}>
                            <ListItemText primary="No data available" />
                        </MenuItem>
                    )}
                    <Box sx={{pl: 1, pr: 1}}>
                        <Button fullWidth sx={{height: "35px"}} variant="contained" color="primary" onClick={onSave}>
                            Save
                        </Button>
                    </Box>
                </Select>
            </FormControl>
        </div>
    );
}
