import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
// material-ui
import { useTheme, styled } from "@mui/material/styles";
import { Avatar, Box, Card, Grid, IconButton, InputAdornment, OutlinedInput, Popper } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "ui-component/extended/Transitions";

// assets
import { IconSearch, IconX } from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";
import { useLocation } from "react-router-dom";
import _debounce from 'lodash/debounce';

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
    zIndex: 1100,
    width: "99%",
    top: "-55px !important",
    padding: "0 12px",
    [theme.breakpoints.down("sm")]: {
        padding: "0 10px"
    }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    width: 279,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: theme.palette.other.slateWhite,
    '& input:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 100px #f0f0f0 inset',
        height:'2px'
      },
    borderRadius: "23px",
    height: "37px",
    overflow: "none",
    "& input": {
        background: "transparent !important",
        paddingLeft: "4px !important",

    },
    "& input::placeholder": {
        color: theme.palette.other.placeholder,
        fontWeight: 400
    },
    "& fieldset": {
        border: "none"
    },
    [theme.breakpoints.down("lg")]: {
        width: 250
    },
    [theme.breakpoints.down("md")]: {
        width: "100%",
        marginLeft: 4,
        // background: theme.palette.mode === "dark" ? theme.palette.dark[800] : "#fff"
    }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.secondary.light,
    color: theme.palette.mode === "dark" ? theme.palette.secondary.main : theme.palette.secondary.dark,
    "&:hover": {
        background: theme.palette.mode === "dark" ? theme.palette.secondary.main : theme.palette.secondary.dark,
        color: theme.palette.secondary.light
    }
}));

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, handleChange, popupState }) => {
    const theme = useTheme();

    return (
        <OutlineInputStyle
            id="input-search-header"

            value={value}
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search"
            startAdornment={
                <InputAdornment position="start">
                    <IconSearch stroke={1.5} size="16px" color={theme.palette.secondary.dark} />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                    <Box sx={{ ml: 2 }} onClick={() => handleChange("")}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                background: theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.orange.light,
                                color: theme.palette.orange.dark,
                                "&:hover": {
                                    background: theme.palette.orange.dark,
                                    color: theme.palette.orange.light
                                }
                            }}
                            {...bindToggle(popupState)}
                        >
                            <IconX stroke={1.5} size="20px" />
                        </Avatar>
                    </Box>
                </InputAdornment>
            }
            aria-describedby="search-helper-text"
            inputProps={{ "aria-label": "weight" }}
        />
    );
};

MobileSearch.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    popupState: PopupState
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = (props) => {
    const theme = useTheme();
    const [value, setValue] = useState("");

    const { handleUpdate, backgroundColor = "#f0f0f0", placeholder = "Search", border = 'none', color = null } = props;
    const location = useLocation();
    const debounceFn = useCallback(_debounce(handleUpdate, 1000), []);

    useEffect(() => {
        setValue("");
    }, [location]);

    // useEffect(() => {
    //     if (handleUpdate) {
    //         handleUpdate(value);
    //     }
    // }, [value]);

    const handleChange = (value) => {
        setValue(value)
        debounceFn(value);
    }

    return (
        <>
            {/* Mobile View */}
            {/* <Box sx={{ display: { xs: "none", md: "none" } }}>
                <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                        <>
                            <Box sx={{ ml: 2 }}>
                                <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                                    <IconSearch stroke={1.5} size="19px" />
                                </HeaderAvatarStyle>
                            </Box>
                            <PopperStyle {...bindPopper(popupState)} transition>
                                {({ TransitionProps }) => (
                                    
                                        <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: "center left" }}>
                                            <Card
                                                sx={{
                                                    background: theme.palette.mode === "dark" ? theme.palette.dark[900] : "#fff",
                                                    [theme.breakpoints.down("sm")]: {
                                                        border: 0,
                                                        boxShadow: "none"
                                                    }
                                                }}
                                            >
                                                <Box sx={{ p: 2 }}>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs>
                                                            <MobileSearch value={value} setValue={setValue} handleChange={handleChange} popupState={popupState} />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        </Transitions>
                                    
                                )}
                            </PopperStyle>
                        </>
                    )}
                </PopupState>
            </Box> */}
            <Box sx={{ display: { xs: "block", md: "block" } }}>
                <OutlineInputStyle
                    id="input-search-header"
                    value={value}
                    style={{ border: border }}
                    onChange={(e) => handleChange(e.target.value)}
                    placeholder={placeholder}
                    autoComplete='off'
                    sx={{
                        "& input::placeholder": {
                            opacity: color ? 1 : 0.7
                        },
                        backgroundColor:{backgroundColor},
                        maxWidth:'250px'
                       
                    }}
                    startAdornment={
                        <InputAdornment position="start">
                            <IconSearch stroke={1.5} size="16px" color={theme.palette.secondary.dark} />
                        </InputAdornment>
                    }
                    {...(value && {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton sx={{ p: 0 }} onClick={() => handleChange("")}>
                                    <ClearIcon fontSize="small" color={theme.palette.secondary.dark} />
                                </IconButton>
                            </InputAdornment>
                        )
                    })}
                    aria-describedby="search-helper-text"
                    inputProps={{ "aria-label": "weight" }}
                />
            </Box>
        </>
    );
};

export default SearchSection;
