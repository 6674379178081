import * as Yup from "yup";
import {validationContant} from "./validationConstants";
import {twoDigitAfterDecimal} from "utils/helperFunctions/helpers";

const addLocationValidation = Yup.object().shape({
    setTime: Yup.string()
        .required("*Required field")
        // .test("test1", "Minimum 15 min is required", (item) => {
        //     commenting this as asked on ticket 837 @mehran-nickelfox
        //     if (Number(item) < 15) {
        //         return false;
        //     }
        //     return true;
        // })
        .test("test2", "Upto 30 min is allowed", (item) => {
            if (Number(item) > 30) {
                return false;
            }
            return true;
        }),
    locationDetails: Yup.object({
        name: Yup.string().required("*Required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
        address: Yup.string().required("*Required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
        phone: Yup.string().required("*Required field"),
        market: Yup.string().required("*Required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow").nullable(),
        locationType: Yup.string().required("*Required field"),
        revenueShare: Yup.number().required("*Required field").lessThan(100.000000001, `Amount must be less than 100%`),
        services: Yup.array().min(1, "At least one option is required").required(),
        orderType: Yup.array().min(1, "At least one option is required").required()
    }),
    brandsmarket: Yup.array().nullable(),
    hoursOfOperation: Yup.object({
        standardHours: Yup.array()
            .of(
                Yup.object().shape({
                    isChecked: Yup.boolean(),
                    day: Yup.string().nullable(),
                    startTime: Yup.string().when("isChecked", {
                        is: (isChecked) => isChecked === true,
                        then: Yup.string().required("*Required field")
                    }),
                    endTime: Yup.string().when("isChecked", {
                        is: (isChecked) => isChecked === true,
                        then: Yup.string().required("*Required field")
                    })
                })
            )
            .test("atleaseOne", "At least one standard hour is required", (item) => {
                if (item?.some((el) => el?.isChecked)) {
                    return true;
                }
                return false;
            }),
        holidayClosures: Yup.array().nullable()
    }),
    temporaryClosures: Yup.object({
        isActive: Yup.boolean().nullable(),
        cloures: Yup.array().when("isActive", {
            is: (isActive) => isActive === true,
            then: Yup.array().of(
                Yup.object().shape({
                    from: Yup.string().nullable(),
                    startingAt: Yup.string().nullable(),
                    through: Yup.string().nullable(),
                    endingAt: Yup.string().nullable()
                })
            )
        })
    }),
    leadTime: Yup.object({
        knowAboutOrders: Yup.string().required("*Required field"),
        cuttOffTime: Yup.string().required("*Required field").nullable()
    }),
    delivery: Yup.array().of(
        Yup.object().shape({
            // deliveryFee: Yup.string().required("*Required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
            minimumOrder: Yup.string().required("*Required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
            radius: Yup.string().required("*Required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow")
        })
    ),
    fees: Yup.object({
        salesTaxRate: Yup.number().required("*Required field").lessThan(100.000000001, `Amount must be less than 100%`),
        serviceFee: Yup.number().required("*Required field").lessThan(100.000000001, `Amount must be less than 100%`),
        minimumServiceFee: Yup.number().required("*Required field").max(validationContant.maxCharLimit, "Only up to $250 allowed")
    })
});

const CustomMenuItemValidation = Yup.object().shape({
    name: Yup.string().required("*Item name is required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
    sku: Yup.string().required("*sku is required field"),
    price: Yup.string().required("*Price is required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
    description: Yup.string().max(validationContant.maxCharLimit, "Only up to 250 characters allow")
});

const HouseAccountValidation = Yup.object().shape({
    date: Yup.date().required("*Required").nullable(),
    amount: Yup.string()
        .required("*Required")
        .test("test", `Amount should be less than remaining amount.`, (item, obj) => {
            const parent = obj?.parent;
            const remainingAmount = parent?.remainingAmount;

            if (remainingAmount < Number(item)) {
                return false;
            }
            return true;
        })
});

const NewItemFlowValidation = Yup.object().shape({
    is_active: Yup.boolean().nullable(),

    name: Yup.string().required("*Item name is required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
    menus: Yup.array().when("item_type", {
        is: (val) => val === "1",
        then: Yup.array().min(1, "At least one option is required").required()
    }),
    category_id: Yup.array().when("item_type", {
        is: (val) => val === "1",
        then: Yup.array()
            .min(1, "At least one option is required")
            .required()
            .when("fromAddNewMenu", {
                is: (val) => !val,
                then: Yup.array().test("test", "Category is not available for selected combination of menus", (item, obj) => {
                    const parent = obj?.parent;
                    const menuCategoryOccurance = parent?.menuCategoryOccurance;
                    const catId = item?.[0];
                    const slectedMenuLength = parent?.menus?.length;
                    if (Number(menuCategoryOccurance?.[catId]) === Number(slectedMenuLength)) {
                        return true;
                    }
                    return false;
                })
            })
    }),
    sku: Yup.string().required("*sku is required field"),
    price: Yup.string().required("*Price is required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
    item_type: Yup.string().required("*Item type is required field"),
    description: Yup.string().max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
    label: Yup.string(),// Labels are not required Ticket 836 @mehran-nickelfox
    customName: Yup.string()
        .max(validationContant.maxCharLimit, "Only up to 250 characters allow")
        .when("label", {
            is: (val) => val === "CUSTOM",
            then: Yup.string().required("*Label name is required field")
        }),
    minimumOrderQuantity: Yup.string()
        .when("item_type", {
            is: (val) => val === "1",
            then: Yup.string()
                .required("*Minimum order qty is required field")
                .max(validationContant.digitLimit, `Only up to ${validationContant.digitLimit} digits allow`)
                .nullable()
        })
        .nullable(),
    servingSize: Yup.string()
        .when("item_type", {
            is: (val) => val === "1",
            then: Yup.string()
                .required("*Serving size is required field")
                .max(validationContant.digitLimit, `Only up to ${validationContant.digitLimit} digits allow`)
                .nullable()
        })
        .nullable(),
    // portionSize: Yup.string()
    //     .required("*Portion Size is required field")
    //     .max(validationContant.digitLimit, `Only up to ${validationContant.digitLimit} digits allow`)
    //     .nullable(),
    // unit: Yup.string()
    //     .required("*Unit is required field")
    //     .max(validationContant.maxCharLimit, "Only up to 250 characters allow")
    //     .nullable(),
    temperatureServed: Yup.array()
        .when("item_type", {
            is: (val) => val === "1",
            then: Yup.array().min(1, "At least one option is required").required().nullable()
        })
        .nullable(),
    // packaging: Yup.array().min(1, "At least one option is required").required().nullable(),
    // individual: Yup.string()
    //     .max(validationContant.digitLimit, `Only up to ${validationContant.digitLimit} digits allow`)
    //     .when("packaging", {
    //         is: (val) => val.includes("individual"),
    //         then: Yup.string().required("*Individual is required field").nullable()
    //     })
    //     .nullable(),
    kitchenNote: Yup.string().max(validationContant.maxCharLimit, "Only up to 250 characters allow").nullable(),
    customerNote: Yup.string().max(validationContant.maxCharLimit, "Only up to 250 characters allow").nullable()
});

const NewAddModifierFlowValidation = Yup.object().shape({
    modifierGroupName: Yup.string().required("Modifier group name required").nullable().trim(),
    displayName: Yup.string().required("Category is  required").nullable().trim()
});

const ItemDetailTabValidation = Yup.object().shape({
    is_active: Yup.boolean().nullable(),
    name: Yup.string().required("*Item name is required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
    category_id: Yup.string().required("*Category is required field"),
    price: Yup.string().required("*Price is required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
    description: Yup.string().max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
    label: Yup.string().required(),
    customName: Yup.string()
        .max(validationContant.maxCharLimit, "Only up to 250 characters allow")
        .when("label", {
            is: (val) => val === "CUSTOM",
            then: Yup.string().required("*Label name is required field")
        })
});

const ItemDetailSettingTabValidation = Yup.object().shape({
    minimumOrderQuantity: Yup.string()
        .required("*Minimum order qty is required field")
        .max(validationContant.maxCharLimit, "Only up to 250 characters allow")
        .nullable(),
    servingSize: Yup.string()
        .required("*Serving size is required field")
        .max(validationContant.maxCharLimit, "Only up to 250 characters allow")
        .nullable(),
    portionSize: Yup.string()
        .required("*Portion size is required field")
        .max(validationContant.maxCharLimit, "Only up to 250 characters allow")
        .nullable(),
    unit: Yup.string()
        .required("*Unit is required field")
        .max(validationContant.maxCharLimit, "Only up to 250 characters allow")
        .nullable(),
    temperatureServed: Yup.array().min(1, "At least one option is required").required().nullable(),
    packaging: Yup.array().min(1, "At least one option is required").required().nullable(),
    individual: Yup.string()
        .max(validationContant.maxCharLimit, "Only up to 250 characters allow")
        .when("packaging", {
            is: (val) => val.includes("individual"),
            then: Yup.string().required("*Individual is required field")
        })
        .nullable(),
    kitchenNote: Yup.string().max(validationContant.maxCharLimit, "Only up to 250 characters allow").nullable(),
    customerNote: Yup.string().max(validationContant.maxCharLimit, "Only up to 250 characters allow").nullable()
});

const brandDetailProfileTab = Yup.object().shape({
    isActive: Yup.boolean().nullable(),
    brandName: Yup.string().required("*Brand is required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
    abbreviation: Yup.string()
        .required("*Abbreviation is required field")
        .max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
    owner: Yup.string().required("*Owner is required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
    brandServices: Yup.array().min(1, "Please choose services to continue.").required("Please choose services to continue."),
    cuisine: Yup.string().required("*Cuisine is required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
    // menus: Yup.array().min(1, "At least one option is required").required("*Menus is required field").nullable(),
    locations: Yup.array().min(1, "At least one option is required").required("*Locations is required field").nullable(),
    defaultLocation: Yup.string().required("*Default location is required field"),
    photos: Yup.array().nullable()
});

const CreateModifierGroupValidation = Yup.object().shape({
    name: Yup.string().required("Name is required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow")
});

const CreateModifierValidation = Yup.object().shape({
    name: Yup.string().required("Name is required field").max(validationContant.maxCharLimit, "Only up to 250 characters allow")
});

const CreateOrderQuoteValidaion = Yup.object().shape({
    paymentMethod: Yup.string().required("Required field"),
    creditCard: Yup.string().when("paymentMethod", {
        is: (val) => val === "Credit Card",
        then: Yup.string().required("Credit card is required")
    }),
    deposit: Yup.string().when("depositEnable", {
        is: (val) => val,
        then: Yup.string()
            .required("Discount is required")
            .matches(/^\d+(\.\d{1,2})?$/, "Discount  must be a number")
            .max(100, "Discount percentage must be less than or equal to 100"),
        otherwise: Yup.string().nullable().trim()
    })
    // houseAccount:Yup.string().when("paymentMethod", {
    //     is: (val) => val === "House account",
    //     then: Yup.string().required("House account is required")
    // })
});

const CreatePromotionValidation = Yup.object().shape({
    discount_code: Yup.string()
        .required("Discount code is required field")
        .max(validationContant.maxCharLimit, "Only up to 250 characters allow")
        .nullable(),
    is_unlimited: Yup.boolean().nullable(),
    number_available: Yup.number()
        .when("is_unlimited", {
            is: (val) => !val,
            then: Yup.number().min(1, "Minimum 1 is required").required("Number available is required field")
        })
        // .max(validationContant.maxCharLimit, "Only up to 250 characters allow")
        .nullable(),
    minimum_order_value: Yup.string()
        .max(validationContant.maxCharLimit, "Only up to 250 characters allow")
        .test("is-decimal", "Two digits decimal", twoDigitAfterDecimal)
        .nullable(),
    // start_date: Yup.date().min(new Date(new Date().setHours(0, 0, 0, 0)), 'Start date cannot be in the past').required("Start date is required field").nullable(),

    start_date: Yup.date().required("Start date is required field").nullable(),
    end_date: Yup.string().nullable(),
    noendDate: Yup.boolean().nullable(),
    discount_type: Yup.string().required("Discount type  is required field").nullable(),
    // amount: Yup.string()
    //     .required("Amount is required field")
    //     .max(validationContant.maxCharLimit, "Only up to 250 characters allow")
    //     .nullable(),

    amount: Yup.string().when("discount_type", {
        is: "PERCENTAGE",
        then: Yup.string()
            .required("Percentage is required field")
            .matches(/^100(\.0{1,2})?$|^\d{0,2}(\.\d{1,2})?$/, "Invalid percentage")
            .max(100, "Percentage must be less than or equal to 100"),
        otherwise: Yup.string()
            .required("Amount is required field")
            .max(validationContant.maxCharLimit, "Only up to 250 characters allow")
            .nullable()
            .trim()
    }),
    services: Yup.array().min(1, "At least one option is required").required("Service is a required field").nullable(),
    market_id: Yup.array().min(1, "At least one option is required").required("Market is a required field").nullable(),
    brand_id: Yup.array().min(1, "At least one option is required").required("Brand is a required field").nullable(),
    company: Yup.string().nullable(),
    description: Yup.string().nullable()
});

const RefundValidtaion = Yup.object().shape({
    type: Yup.string().required("Required field"),
    method: Yup.string().required("Required field"),
    reason: Yup.string().required("Required field"),
    partialAmountType: Yup.string().when("type", {
        is: (val) => val === "Partial",
        then: Yup.string().required("Required field")
    }),
    partialAmount: Yup.string()
        .test("test", `Amount should be less than remaining amount.`, (item, obj) => {
            const parent = obj?.parent;
            const partialAmountType = parent?.partialAmountType;
            const remainingAmount = parent?.remainingAmount;

            if (partialAmountType === "percent") {
                const calcualtePercentage = +parseFloat((parseFloat(remainingAmount) * parseFloat(item)) / 100).toFixed(2);
                if (remainingAmount < calcualtePercentage) {
                    return false;
                }
            } else if (remainingAmount < item) {
                return false;
            }

            return true;
        })
        .when("type", {
            is: (val) => val === "Partial",
            then: Yup.string().required("Required field")
        })
});

const CancellationValidation = Yup.object().shape({
    cancellationFee: Yup.string().required("Required field").nullable(),
    reason: Yup.string().nullable()
});

export {
    addLocationValidation,
    ItemDetailTabValidation,
    ItemDetailSettingTabValidation,
    brandDetailProfileTab,
    CreateModifierGroupValidation,
    CreateModifierValidation,
    CreatePromotionValidation,
    NewItemFlowValidation,
    NewAddModifierFlowValidation,
    CreateOrderQuoteValidaion,
    RefundValidtaion,
    CancellationValidation,
    CustomMenuItemValidation,
    HouseAccountValidation
};
