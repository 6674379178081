import {Grid} from "@mui/material";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import useTempDrawer from "hooks/useTempDrawer";
import React, {useState, useEffect, useLayoutEffect} from "react";

import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import CommonTable from "commonComponent/TableSection/CommonTable";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import {useDispatch, useSelector} from "store";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import Filters from "commonComponent/Filters";
import {generateUrl, sortFunction} from "utils/helperFunctions/helpers";
import FilterChips from "commonComponent/FilterChipsComponent";
import ReviewModal from "commonComponent/DilogBox/ReviewModal";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import Filter from "commonComponent/FilterAccoridan";
import ReviewPublish from "commonComponent/DilogBox/reviewPublish";
import {setPageNumber, setRowsPerPage, setShowFilter, setOrder, setOrderBy} from "store/slices/paginationSlice";

const Reviews = () => {
    const {toggleDrawer} = useTempDrawer();

    const [reviewtListData, setReviewtListData] = useState([]);
    const [open, setOpen] = useState(false);
    const [reviewOptionModal, setReviewOptionModal] = useState(false);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.order || "desc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "createdAt");
    const [search, setSearch] = useState("");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const filterChips = useSelector((state) => state?.pagination?.filterChips);
    const openFilter = useSelector((state) => state?.pagination?.showFilter);

    const [marketList, setMarketList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const dispatch = useDispatch();
    const [selectedMenus, setSelectedMenus] = useState([]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [menuList, setMenuList] = React.useState([]);

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };
    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handleCreatemarket = (btntype) => (e) => {
        switch (btntype) {
            case "Create market": {
                dispatch(setShowFilter(false));
                toggleDrawer("right", "createMarket")(e);
                break;
            }
            case "marketFilter": {
                dispatch(setShowFilter(!openFilter))
                // setOpenFilter((prev) => !prev);
                // toggleDrawer("right", "marketFilter")(e);
                break;
            }
            default:
                break;
        }
    };

    const getMenuList = async () => {
        const instance = NetworkManager(API.MENU.GET);
        const params = {
            page: 1,
            pageSize: 5000,
            sortBy: "menu_name",
            orderBy: "asc"
        };
        const url = generateUrl(params);
        const response = await instance.request({}, [url]);

        if (response.success) {
            const tempList = [];
            response.data.menuList
                ?.filter((a) => a.menu.is_active === "true")
                .forEach((row) => {
                    const tempObj = {};
                    tempObj.brand_id = row.brand_id;
                    tempObj.brandName = row.brand.brand_name;
                    tempObj.createdAt = row.createdAt;
                    tempObj.menu_name = row.menu.menu_name;
                    tempObj.menu_id = row.menu_id;
                    tempObj.is_active = row.menu.is_active;
                    tempObj.abbreviation = row.brand.abbreviation;
                    tempObj.brandServices = row.brand.brandServices;

                    tempObj.label = row.menu.menu_name;
                    tempObj.value = row.menu_id;
                    tempObj.id = row.menu_id;
                    tempList.push(tempObj);
                });
            setMenuList(tempList);
        }
    };

    const [isReviewPopup, setIsReviewPopup] = useState(false);

    const handleStatus = async (row, type) => {
        let instance;
        // if selectedRow is not null have to add PUT api else ADD api
        if (type === "Unpublish") {
            instance = NetworkManager(API.CUSTOMER.REVIEWUNPUBLISH(row.order_review_id));
        } else instance = NetworkManager(API.CUSTOMER.REVIEWUPDATE(row.order_review_id));
        let payload;
        if (type === "Unpublish") {
            payload = {
                is_visible_home_page: false,
                is_visible_menu_page: false,
                is_visible_review_page: false,
                menus: []
            };
        } else {
            payload = {
                is_visible_home_page: selectedCheckboxes?.includes("Home page"),
                is_visible_menu_page: selectedCheckboxes?.includes("Menu page"),
                is_visible_review_page: selectedCheckboxes?.includes("Rate and review page"),
                menus: selectedCheckboxes?.includes("Menu page") ? [...selectedMenus] : []
            };
        }

        // if (type === "Publish" && !isReviewPopup) {

        //     return;
        // }

        const response = await instance.request(payload);

        if (response.success) {
            setSelectedCheckboxes([]);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleIsEdited();
        } else if (response.error) {
            setSelectedCheckboxes([]);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        const keysObject = {
            "Menu page": row?.is_visible_menu_page,
            "Home page": row?.is_visible_home_page,
            "Rate and review page": row?.is_visible_review_page
        };
        console.log(row, "12345");
        const visiblePages = Object.keys(keysObject).filter((key) => keysObject[key]);

        switch (type) {
            case "Publish": {
                setIsReviewPopup(true);
                setSelectedCheckboxes([...visiblePages]);
                setSelectedMenus([...row?.menu.map((menu) => menu?.menu_id)]);
                break;
            }
            case "Unpublish": {
                handleStatus(row, type);
                break;
            }
            default:
                break;
        }
    };

    const getReviewList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.CUSTOMER.REVIEW);
        const params = {
            page: currentPage,
            pageSize: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };
        if (currentOrderBy) {
            params.sortBy = currentOrderBy;
            params.orderBy = currentOrder;
        }
        // while searching we dont need page number
        if (search === "") {
            params.page = currentPage;
        }

        if (search !== "") {
            params.search = search;
            setReviewtListData([]);
        }

        const url = generateUrl(params);
        const response = await instance.request({}, [url]);

        if (response.success) {
            const tempData = [];
            response.data.review.forEach((element) => {
                const obj = element;
                obj.OrderID = element?.order_details?.order_number;
                obj.Customer = element?.customer?.full_name ?? `${element?.first_name}` + ` ${element?.last_name}`;
                obj.Market = element.order_details?.kitchen_location?.market?.market_name;
                obj.Service = element.order_details?.service_type;
                obj.Brand = element.order_details?.brand?.brand_name;
                obj.Rating = element?.rating;
                obj.Review = element?.review;

                obj.driverProfessionalism = element?.driver_review;
                obj.images = element?.images;
                obj.source = element?.review_type === "GOOGLE" ? "Google" : "Bitetime";
                obj.is_sharable = element?.is_sharable;
                obj.publishedTo = `${element?.is_visible_menu_page ? "Menu page" : ""}${
                    (element?.is_visible_menu_page && element?.is_visible_home_page) ||
                    (element?.is_visible_menu_page && element?.is_visible_review_page)
                        ? ", "
                        : ""
                }${element?.is_visible_home_page ? "Home page" : ""}${
                    element?.is_visible_home_page && element?.is_visible_review_page ? ", " : ""
                }${element?.is_visible_review_page ? "Review page" : ""}`;
                obj.status = element?.status;
                obj.is_sharable = element?.review_type === "GOOGLE" ? "N/A" : element?.is_sharable === true ? "Yes" : "No";

                tempData.push(obj);
            });

            setReviewtListData(tempData);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleSearch = (val) => {
        setSearch(val);
        dispatch(setPageNumber(1));
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
        // setCurrentPage(number + 1);
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        // setRowsPerPage(number);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        dispatch(setOrder(val1));
        const brandVal = val2 === "Brand" ? "brand_name" : val2.toLowerCase();
        const marketVal = val2 === "Market" ? "market_name" : brandVal;
        const oredrVal = val2 === "review_ID" ? "order_number" : marketVal;
        const customerVal = val2 === "customers" ? "customer_name" : oredrVal;
        const serviceVal = val2 === "Service" ? "service_type" : customerVal;
        const reviewsVal = val2 === "reviews" ? "review" : serviceVal;
        const value2 = val2 === "createdAt" ? val2 : reviewsVal;
        dispatch(setOrderBy(value2));
    };

    useEffect(() => {
        getMenuList();
    }, []);

    useEffect(() => {
        getReviewList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, filterChips]);

    const getMarketList = async (page = 1, pageSize = 1000) => {
        const tempData = [];
        const instance = NetworkManager(API.MARKET.GET);
        const params = {
            page: page,
            pageSize: pageSize
        };
        if (currentOrderBy) {
            params.sortBy = "createdAt";
            params.orderBy = "desc";
        }

        const response = await instance.request({}, params);

        if (response.success) {
            response.data.marketList.forEach((el) => {
                tempData.push({id: el.market_name, label: el.market_name});
            });
        }
        setMarketList(sortFunction(tempData));
    };

    const getBrandList = async (page = 1, pageSize = 1000) => {
        const tempData = [];
        const instance = NetworkManager(API.BRAND.GET);
        const params = {
            page: page,
            pageSize: pageSize
        };
        if (currentOrderBy) {
            params.sortBy = "createdAt";
            params.orderBy = "desc";
        }

        const response = await instance.request({}, params);

        if (response.success) {
            response.data.brandList.forEach((el) => {
                tempData.push({id: el.brand_id, label: el.brand_name});
            });
        }
        setBrandList(sortFunction(tempData));
    };

    const getState = async () => {
        let tempData = [];
        const instance = NetworkManager(API.USER.STATE);
        const response = await instance.request();
        if (response.success) {
            const states = response.data.stateList.map((state) => ({
                label: state.name,
                value: state.name,
                id: state.state_id
            }));
            tempData = states;
        }
        return tempData;
    };
    useLayoutEffect(() => {
        getBrandList();
        getMarketList();
    }, []);
    const filterState = [
        {
            key: "brand_id",
            label: "Brand",
            type: "multiselect",
            getData: brandList
        },
        // {
        //     key: "market_name",
        //     label: "Market name",
        //     type: "multiselect",
        //     getData: marketList
        // },

        {
            key: "status",
            label: "Status",
            type: "multiselect",
            getData: () => [
                {label: "New", id: "NEW"},
                {label: "Unpublished", id: "UNPUBLISHED"},
                {label: "Published", id: "PUBLISHED"}
            ]
        },
        {
            key: "rating",
            label: "Rating",
            type: "multiselect",
            getData: () => [
                {label: "1", id: "1"},
                {label: "2", id: "2"},
                {label: "3", id: "3"},
                {label: "4", id: "4"},
                {label: "5", id: "5"}
            ]
        }
    ];

    // const handleApplyFilter = (Morefilters) => {
    //     setFilterChips(Morefilters);
    //     setCurrentPage(1);
    // };

    return (
        <Grid container>
            {/* <Filters
                sideBarStateName="marketFilter"
                filterState={filterState}
                filterChips={filterChips}
                setFilterChips={setFilterChips}
                handleApplyFilter={handleApplyFilter}
            /> */}

            {isReviewPopup && (
                <ReviewPublish
                    menuList={menuList}
                    selectedMenus={selectedMenus}
                    setSelectedMenus={setSelectedMenus}
                    handleClose={() => setIsReviewPopup(false)}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    updatePopup={(selectedCheckBox) => {
                        setIsReviewPopup(false);
                        handleStatus(selectedRow, "Publish");
                    }}
                    setUpdatePopup={(selectedCheckBox) => {
                        handleStatus(selectedRow, "Publish");
                    }}
                    onSave={(selArr = []) => {
                        handleActionBtn("publish", selectedRow, () => {});
                    }}
                />
            )}

            <Grid item xs={12}>
                <TableTopFilterWrapper
                    lessmarginOnTop
                    pageheader={<PageHeader title="Reviews" />}
                    handleClick={handleCreatemarket}
                    handleSearch={handleSearch}
                    filterType="marketFilter"
                >
                    {openFilter && (
                        <Filter
                            filterState={filterState}
                            // setFilterState={setFilterChips}
                            filterChips={filterChips}
                            showFilter={openFilter}
                            // setSearchedValue={setSearchedValue}
                        />
                    )}
                    <FilterChips
                        filterChips={filterChips}
                        // setFilterChips={setFilterChips}
                        filterState={filterState}
                    />
                    <CommonTable
                        loading={loading}
                        data={reviewtListData}
                        header={[
                            // {key: "source", label: "Source"},
                            {key: "review_ID", label: "Order #"},
                            {key: "customers", label: "Customer"},
                            {key: "Market", label: "Market"},
                            // {key: "Service", label: "Service"},
                            {key: "Brand", label: "Brand"},
                            {key: "Rating", label: "Rating"},
                            {key: "reviews", label: "Review"},
                            {key: "driverProfessionalism", label: "Driver professionalism"},
                            {key: "images", label: "Images"},
                            {key: "is_sharable", label: "Share"},
                            {key: "createdAt", label: "Created"},
                            {key: "publishedTo", label: "Published to"},
                            {key: "status", label: "Status"},

                            WithPermission({module: "Reviews", permission: ["update_permission"]}) && {
                                key: "action",
                                label: "Action"
                            }
                        ].filter((el) => el)}
                        handleActionBtn={handleActionBtn}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage - 1}
                        actions={["Publish", "Unpublish"]}
                        sortingLabel={["Status", "Rating", "Review", "Created", "Customer", "Service", "Order #", "Market", "Brand"]}
                    />
                </TableTopFilterWrapper>
            </Grid>
        </Grid>
    );
};

export default Reviews;
