import * as React from "react";
import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const MaterialUISwitch = styled(Switch)(({theme}) => ({
    // width: 62,
    // height: 34,
    // padding: 7,
    "& .MuiSwitch-switchBase": {
        color: theme.palette.error.dark,
        "&.Mui-disabled": {
            opacity: 1,
            color: "#eef2f6"
        },
        "&.Mui-disabled+.MuiSwitch-track": {
            opacity: 1
        },
        "&.Mui-checked": {
            color: theme.palette.primary.main,
            transform: "translateX(22px)",
            // "& .MuiSwitch-thumb:before": {
            //     // backgroundColor: "red"
            // },
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#e4e4e4 !important"
            }
        }
    },
    // "& .MuiSwitch-thumb": {
    //     backgroundColor: theme.palette.mode === "dark" ? "#003892" : "red",
    //     width: 32,
    //     height: 32,
    //     "&:before": {
    //         content: "''",
    //         position: "absolute",
    //         width: "100%",
    //         height: "100%",
    //         left: 0,
    //         top: 0
    //     }
    // },
    "& .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#e4e4e4 !important",
        borderRadius: 20 / 2
    }
}));

const CustomSwitch = (props) => <MaterialUISwitch {...props} />;

export default CustomSwitch;
