import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import styles from "./createOrder.module.scss";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CustomizedAccordions from "./accordian";

import {
    FormatCurrency,
} from "utils/helperFunctions/helpers";
import StorageManager from "storage/StorageManager";
import useTempDrawer from "hooks/useTempDrawer";
import useToggleDilog from "hooks/useToggleDilog";
import { store, dispatch } from "store/index";
import OrderUpdationModal from "commonComponent/DilogBox/OrderPriceUpdationModal";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import expandIcon from "assets/images/icons/expand.svg";

import collapseIcon from "assets/images/icons/collapse.svg";


const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0
    },
    "&:before": {
        display: "none"
    }
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)"
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)"
}));

function CreateOrderFooter({
    orderId,
    formik,
    navigate,
    initialValues,
    handleReset,
    setOpenDialog,
    setOpenPaymentDialog,
    setOpenRefundDialog,
    setRefundAmount,
    setExtraAmount,
    Reorder,
    setFooterHeight,
    handleUpdateOrder
}) {
    const { toggleDrawer, state } = useTempDrawer();
    const { toggleDilog } = useToggleDilog();

    const [loading, setLoading] = useState(false);

    const [openCancelModal, setCancelModal] = useState(false);

    const [expanded, setExpanded] = React.useState("panel1");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        setFooterHeight(newExpanded ? panel : false);
    };

    let orderPriceForAllItem = 0;
    const serviceTaxTotal = 0;
    const discountedPrice = 0;
    const salesTaxTotalPrice = 0;
    let overAllPrice = 0;
    const preferredPriceTotal = 0;

    const handleItemTotalPrice = () => {
        const { itemDetail } = formik.values;
        let totalPrice = 0;

        itemDetail?.forEach((obj, itemIndex) => {
            let tempPrice = 0;
            tempPrice += parseFloat(itemDetail[itemIndex]?.price);
            tempPrice *= parseFloat(itemDetail[itemIndex]?.quantity !== "" ? itemDetail[itemIndex]?.quantity : 1);
            itemDetail[itemIndex]?.checkboxModifier?.forEach((row) => {
                if (row.allowQuantityModification) {
                    // for custom modifier (means for those we can modify quantity)
                    tempPrice += parseFloat(row?.quantity !== "" ? row?.quantity : 1) * parseFloat(row.price);
                } else {
                    // for modifier that doest not have modifiable quantity
                    // for those we have to take item quantity * modifier price
                    tempPrice +=
                        parseFloat(itemDetail[itemIndex]?.quantity !== "" ? itemDetail[itemIndex]?.quantity : 1) * parseFloat(row.price);
                }
            });
            itemDetail[itemIndex]?.radioModifier?.forEach((row) => {
                const obj = JSON.parse(row);
                if (obj.allowQuantityModification) {
                    tempPrice += parseFloat(obj?.quantity !== "" ? obj?.quantity : 1) * parseFloat(obj.price);
                } else {
                    tempPrice +=
                        parseFloat(itemDetail[itemIndex].quantity !== "" ? itemDetail[itemIndex].quantity : 1) * parseFloat(obj.price);
                }
            });

            totalPrice += tempPrice;
            orderPriceForAllItem = totalPrice;
        });
        StorageManager.put("orderItemPrice", totalPrice);
        return totalPrice;
    };






    const calculateOverallPrice = () => {
        let finalPrice = parseFloat(orderPriceForAllItem) + parseFloat(serviceTaxTotal) + parseFloat(salesTaxTotalPrice);
        if (preferredPriceTotal < finalPrice) {
            finalPrice -= Number(preferredPriceTotal);
        }
        overAllPrice = finalPrice;
        StorageManager.put("orderTotal", parseFloat(finalPrice).toFixed(2));
        return parseFloat(finalPrice).toFixed(2);
    };




    const handlePaymentText = () => {
        let finalWord = "Create template";

        if (formik.values.headerData.template.templateId) {
            finalWord = "Update template"
        }
        return finalWord;
    };



    const handleOrderUpdationModal = () => {
        setOpenPaymentDialog(true);
        setCancelModal(false);
        formik.handleSubmit();
    };

    const handleClose = () => {
        setCancelModal(false);
    };

    const handleImageClick = (event) => {
        // Prevent the accordion from expanding/collapsing when the image is clicked
        event.stopPropagation();
    };

    const handleTouch = (formik) => {
        const allFields = Object.keys(formik.values);
        const touchedFields = {};

        allFields.forEach((field) => {
            touchedFields[field] = true;
        });
        formik.setTouched(touchedFields);
        formik.setFieldTouched("headerData.template.name", true)
        formik.setFieldTouched("headerData.forHowMany", true)
    }

    return (
        <>

            <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} sx={{ border: "none" }}>
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    sx={{
                        background: "transparent",
                        padding: "0px",
                        marginLeft: "0px",
                        minHeight: "1px !important",
                        position: "relative",
                        cursor: "auto !important",
                        "& .MuiAccordionSummary-content": {
                            margin: "0px",
                            position: "relative"
                        }
                    }}
                >
                    <span style={{ position: "absolute", top: "-8px", left: "35px", cursor: "pointer" }}>
                        <img src={expanded ? expandIcon : collapseIcon} alt="expand" />
                    </span>
                    <Box
                        sx={{ borderTop: "1px solid #E0E2E6", width: "100%", padding: "0px", paddingBottom: "2rem" }}
                        onClick={handleImageClick}
                    >
                        <Box sx={{ marginTop: "-10px !important", marginLeft: "37px" }}>{/* abc */}</Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ marginTop: "-2.5rem", borderTop: "none" }}>
                    <div className="footerScale">
                        <Grid container className={styles.orderFooterMain}>
                            <Grid item xs={5.8} lg={8}>
                                <Box>
                                    <CustomizedAccordions formik={formik} />
                                </Box>
                            </Grid>
                            <Grid item xs={0.2}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={6} lg={3.8} mt={1}>
                                <Box>
                                    <Grid container>
                                        <Grid item xs={9}>
                                            <Typography className={styles.summary} fontWeight={400}>
                                                Order items
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} className={styles.priceData}>
                                            <Typography className={styles.summary} fontWeight={400}>
                                                {FormatCurrency(parseFloat(handleItemTotalPrice()))}
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                    <Grid container mt={0.3} sx={{ borderTop: "2px solid #CCCFD6", paddingTop: "5px" }}>
                                        <Grid item xs={9}>
                                            <Typography className={styles.totalHeader} fontWeight={400}>
                                                Total
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} className={styles.priceData}>
                                            <Typography className={styles.totalHeader} fontWeight={400}>
                                                {FormatCurrency(calculateOverallPrice())}
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                </Box>
                                <Grid container mt={2.5}>
                                    <Grid item xs={2}>
                                        {/* <Button
                                            className={styles.cancelBtn}
                                            onClick={() => {
                                                toggleDilog(false)();
                                                formik.resetForm({ values: initialValues });
                                                handleReset();

                                                dispatch({
                                                    type: "RemoveItem"
                                                });
                                            }}
                                        >
                                            Cancel
                                        </Button> */}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={10}
                                        sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", textAlign: "right" }}
                                    >
                                         <Button
                                            className={styles.cancelBtn}
                                            onClick={() => {
                                                toggleDilog(false)();
                                                formik.resetForm({ values: initialValues });
                                                handleReset();

                                                dispatch({
                                                    type: "RemoveItem"
                                                });
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        {(formik.values.orderStatus === "Draft" ||
                                            formik.values.orderStatus === "Quote Sent" ||
                                            formik.values.orderStatus === "") && (
                                                <Button
                                                    // disabled={calculateOverallPrice() === parseFloat(formik.values.paid_already).toFixed(2)}
                                                    variant="outlined"
                                                    className={styles.quoteBtn}
                                                    onClick={() => {
                                                        // handleTouch(formik)
                                                        // formik.handleSubmit();
                                                        formik.setFieldTouched('headerData.customer.userId', true)
                                                        const { userId } = formik.values.headerData.customer
                                                        if (userId !== "" && userId !== null) {
                                                            handleUpdateOrder(formik.values)
                                                        }
                                                    }}
                                                >
                                                    Save draft
                                                </Button>
                                            )}
                                        <Button
                                            variant="contained"
                                            // disabled={calculateOverallPrice() === parseFloat(formik.values.paid_already).toFixed(2)}
                                            className={styles.paymentBtn}
                                            onClick={(e) => {

                                                formik.handleSubmit();
                                                handleTouch(formik)
                                                // formik.setTouched({ itemModifier: true });
                                            }}
                                        >
                                            {handlePaymentText()}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <OrderUpdationModal
                            open={openCancelModal}
                            buttonTitle="Confirm"
                            title={`Updated order total: ${FormatCurrency(calculateOverallPrice())}`}
                            description="Would you like to charge a cancelation fee?"
                            handleClose={handleClose}
                            handleSubmit={handleOrderUpdationModal}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
            {!expanded && (
                <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", paddingRight: "0.9rem", paddingBottom: "1rem" }}>
                    <Box sx={{ width: "75%" }}>{/* {} */}</Box>
                    <Box sx={{ width: "34%" }}>
                        <Grid container>
                            <Grid item xs={9}>
                                <Typography className={styles.totalHeader} fontWeight={400}>
                                    Total
                                </Typography>
                            </Grid>
                            <Grid item xs={3} className={styles.priceData}>
                                <Typography className={styles.totalHeader} fontWeight={400}>
                                    {FormatCurrency(calculateOverallPrice())}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container mt={2.5}>
                            <Grid item xs={1} sx={{ paddingLeft: "0.3rem" }}>
                                {/* <Button
                                    className={styles.cancelBtn}
                                    onClick={() => {
                                        toggleDilog(false)();
                                        formik.resetForm({ values: initialValues });
                                        handleReset();

                                        dispatch({
                                            type: "RemoveItem"
                                        });
                                    }}
                                >
                                    Cancel
                                </Button> */}
                            </Grid>
                            <Grid item xs={11} sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", textAlign: "right" }}>
                                <Button
                                    className={styles.cancelBtn}
                                    onClick={() => {
                                        toggleDilog(false)();
                                        formik.resetForm({ values: initialValues });
                                        handleReset();

                                        dispatch({
                                            type: "RemoveItem"
                                        });
                                    }}
                                >
                                    Cancel
                                </Button>
                                {(formik.values.orderStatus === "Draft" ||
                                    formik.values.orderStatus === "Quote Sent" ||
                                    formik.values.orderStatus === "") && (
                                        <Button
                                            // disabled={calculateOverallPrice() === parseFloat(formik.values.paid_already).toFixed(2)}
                                            variant="outlined"
                                            sx={{ padding: "5px 12px" }}
                                            className={styles.quoteBtnOuter}
                                            onClick={() => {
                                                formik.setFieldTouched('headerData.customer.userId', true)
                                                const { userId } = formik.values.headerData.customer
                                                if (userId !== "" && userId !== null) {
                                                    handleUpdateOrder(formik.values)
                                                }
                                            }}
                                        >
                                            Save draft
                                        </Button>
                                    )}
                                <Button
                                    variant="contained"
                                    className={styles.paymentBtnOuter}
                                    sx={{ padding: "5px 15px" }}
                                    onClick={(e) => {

                                        handleTouch(formik)
                                        formik.handleSubmit();
                                        // formik.setTouched({ itemModifier: true });
                                    }}
                                >
                                    {handlePaymentText()}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            )}
        </>
    );
}

export default CreateOrderFooter;
