import {Link as RouterLink} from "react-router-dom";

// material-ui
import {Link} from "@mui/material";
// import Logo from 'ui-component/Logo';
import logoImg from 'assets/images/BitetimeLogoNew.svg';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link href="/orders?tab=scheduled" >
        {/* <Logo /> */}
        <img
            decoding="async"
            width="320"
            height="60"
            src={logoImg}
            className="attachment-medium size-medium wp-image-15"
            alt="Bitetime"
            loading="lazy"
            srcSet={logoImg}
            style={{width: "139px", height: "25px"}}
           
        />
    </Link>
);

export default LogoSection;
