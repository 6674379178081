import React, { useState, useEffect } from 'react';

const PreviewThumbnail = ({ file }) => {
  const [previewUrl, setPreviewUrl] = useState(null);
  const [fileType, setFileType] = useState(null);

  useEffect(() => {
    const fetchPreview = async () => {
      try {
        const blob = file instanceof Blob ? file : await fetch(file).then((res) => res.blob());

        const reader = new FileReader();
        reader.readAsDataURL(blob);

        reader.onload = () => {
          setPreviewUrl(reader.result);
        };
      } catch (error) {
        console.error('Error fetching or reading the file:', error);
      }
    };

    fetchPreview();
  }, [file]);

  useEffect(() => {
    const fileTypeFromUrl = file?.split('.').pop();
    setFileType(fileTypeFromUrl);
  }, [file]);

  return (
    <div>
      {fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'gif' ? (
        <img src={previewUrl} alt="preview" style={{ width: '300px' }} />
      ) : fileType === 'pdf' ? (
        <iframe
          src={`https://docs.google.com/gview?url=${file}&embedded=true`}
          style={{ width: '300px', height: '300px' }}
          frameBorder="0"
          title={file}
        />
      ) : fileType === 'doc' || fileType === 'docx' ? (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${file}`}
          style={{ width: '300px', height: '300px' }}
          frameBorder="0"
          title={file}
        />
      ) : (
        <p>Unsupported file type</p>
      )}
    </div>
  );
};

export default PreviewThumbnail;