import {Box, Grid} from "@mui/material";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {NetworkManager, API} from "network/core";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {useNavigate, useSearchParams} from "react-router-dom";
import FilterChips from "commonComponent/FilterChipsComponent";
import {csvBuilder, generateUrl} from "utils/helperFunctions/helpers";
import Filters from "commonComponent/Filters";
import NewAddItem from "commonComponent/ItemComponent/Additem/NewAddItem";
import useAuth from "hooks/useAuth";
import PrintOrderModal from "commonComponent/DilogBox/PrintOrderModal";
import SendQuote from "../../CreateOrder/createOrderFooter/SendQuote/SendQuote";
import useTempDrawer from "hooks/useTempDrawer";
import AddNewNotes from "views/pages/privatePages/customers/CreateCustomer/AddNewNoteForm";
import OrderNotes from "../../Notes/OrderNotes";
import {setPageNumber, setRowsPerPage, setOrder, setOrderBy} from "store/slices/paginationSlice";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import PDFComponent from "../../OrderQuoteDetail/OrderView/pdfComponent";

const DraftTab = (props) => {
    const {
        search,
        showFilter,
        handleEditOrder,
        openEditDialog,
        setSelectedCheckbox,
        updateListCount,
        selectedCheckbox,
        filterChips,
        setSearchedValue,
        showMultipleQuote = false,
        setShowMultipleQuote
    } = props;
    const [searchParams] = useSearchParams();
    const OrderId = searchParams.get("orderId");
    const {orderUpdate} = useAuth();
    const {toggleDrawer, state} = useTempDrawer();

    const [open, setOpen] = React.useState(false);
    const [unplacedOrderListing, setUnplacedOrderListing] = React.useState([]);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.order || "desc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "created_at");
    const [priceParam, setPriceParam] = useState("customer_order");
    const [showHeading, setShowHeading] = useState(false);
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    // const [filterChips, setFilterChips] = useState([]);
    const [printOptionModal, setPrintOptionModal] = useState(false);
    const [openCancelModal, setCancelModal] = useState(false);
    const [openQuoteModal, setOpenQuoteModal] = useState(false);
    const [openUnassign, setOpenUnassign] = useState(false);
    const [itemDetailSorted, setItemDetailSorted] = useState(null);
    const [subTotal, setSubTotal] = useState("");
    const [secondaryDriver, setSecondaryDriver] = useState(null);
    const [quoteListData, setQuoteListData] = useState(null);
    const [imageUrl, setImageUrl] = useState("");

    const [printSelect, setPrintSelect] = useState("");

    const navigation = useNavigate();

    const dispatch = useDispatch();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    useEffect(() => {
        setSelectedCheckbox([]);
    }, []);

    const handleCancelModal = () => {
        setCancelModal((pre) => !pre);
    };
    React.useEffect(() => {
        if (OrderId) {
            // if user comes from request edit email template
            openEditDialog(OrderId);
        }
    }, [OrderId]);

    const getUnplacedOrderList = async (moreFilters = false) => {
        if (state.right === "SendQuote") {
            return;
        }
        setLoading(true);
        const instance = NetworkManager(API.ORDER.GET);
        const params = {
            offset: currentPage,
            status: "Draft",
            limit: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };
        if (moreFilters && moreFilters.guest_count) {
            if (moreFilters.guestFilter === "equal" || moreFilters.guestFilter === undefined) {
                params.guest_count = moreFilters.guest_count;
            } else {
                delete params.guest_count;
                params[`guest_count${moreFilters.guestFilter}`] = moreFilters.guest_count;
            }
            delete params.guestFilter;
        }
        if (moreFilters && moreFilters.total_amount) {
            if (moreFilters.amountFilter === "equal" || moreFilters.amountFilter === undefined) {
                params.total_amount = moreFilters.total_amount;
            } else {
                delete params.total_amount;
                params[`total_amount${moreFilters.amountFilter}`] = moreFilters.total_amount;
            }
            delete params.amountFilter;
        }
        if (moreFilters?.charged_by) {
            params.charged_by = moreFilters?.charged_by.join(",");
        }

        if (currentOrderBy) {
            params.sort_by = currentOrderBy;
            params.sort = currentOrder;
        }

        if (search === "") {
            params.offset = currentPage;
        }

        if (search !== "") {
            params.search = search;
            setUnplacedOrderListing([]);
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);
        if (response.success) {
            setUnplacedOrderListing(response.data.orderList);
            setTotalItems(response.data.pagination.totalItems);

            setSelectedRow(null);
        }
        setLoading(false);
        setSearchedValue({});
    };

    const handleCancelOrder = async () => {
        const instance = NetworkManager(API.ORDER.STATUS(selectedRow.order_id, "Cancelled"));
        const payload = {
            link: `${process.env.REACT_APP_CUSTOMER_URL}account?tab=orders&orderId=${selectedRow.order_id}`
        };

        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleCancelModal();
        getUnplacedOrderList();
    };

    const getDriverListSecondary = async (orderId) => {
        const instance = NetworkManager(API.DRIVER.SECONDARYLISTING(orderId));
        const response = await instance.request();

        if (response.success) {
            const driverList = response.data?.driver;
            if (driverList?.length) {
                setSecondaryDriver(driverList);
            }
        }
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
        getDriverListSecondary(row?.order_id);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
        toggleDrawer("right", false)();
    };

    const handleUnassignDriverModal = (data) => {
        setSelectedRow(data);
        setOpenUnassign((pre) => !pre);
    };

    const metersToMiles = (meters) => {
        const metersInOneMile = 1609.34;
        const miles = meters / metersInOneMile;
        return miles.toFixed(1);
    };

    const handleSalesTaxString = () => {
        return selectedRow.kitchen_location?.sales_tax_rate ? `${selectedRow?.kitchen_location?.sales_tax_rate}%` : "0%";
    };

    const handleAdvanceString = () => {
        return selectedRow?.advance_deposit_percent ? `${selectedRow?.advance_deposit_percent}%` : "0%";
    };

    const getColorOfStatus = (status) => {
        switch (status) {
            case "Draft":
                return "#A952FF";
            case "Quote Sent":
                return "#24C4FB";
            case "QUOTE_EXPIRED":
                return "#757575";

            case "Cancelled":
                return "#EA3744";
            case "Needs driver":
                return "#FF226B";
            case "Needs Driver":
                return "#FF226B";
            case "Accepted":
                return "#00ab3a";
            case "New":
                return "#ED9E05";
            case "Active":
                return "#00ab3a";
            case "Driver assigned":
                return "#FF872A";
            case "Driver Assigned":
                return "#FF872A";
            case "ON_THE_WAY":
                return "#00ab3a";
            case "QUOTE_REJECTED":
                return "#EA3744";
            default:
                return "#000000";
        }
    };
    const getBackgroundOfStatus = (status) => {
        switch (status) {
            case "Draft":
                return "#FAF2FF";
            case "Quote Sent":
                return "#E8FAFF";
            case "Cancelled":
                return "#FFF2F2";
            case "Needs driver":
                return "#FFF2F2";
            case "Needs Driver":
                return "#FFF2F2";
            case "Accepted":
                return "#E4FFE2";
            case "New":
                return "#FFF8E5";
            case "Active":
                return "#F0FFF9";
            case "Driver assigned":
                return "#FFEFE4";
            case "Driver Assigned":
                return "#FFEFE4";
            case "ON_THE_WAY":
                return "#E4FFE2";
            case "PUBLISHED":
                return "#E4FFE2";
            case "UNPUBLISHED":
                return "#F2F2F2";
            case "NEW":
                return "#FFF8E5";
            case "QUOTE_EXPIRED":
                return "#F2F2F2";
            case "QUOTE_REJECTED":
                return "#FFF2F2";
            default:
                return "#F2F2F2";
        }
    };

    const dietaryMap = {
        VEGETARIAN: "V",
        VEGAN: "VG",
        "GLUTEN-FREE": "GF",
        "DAIRY-FREE": "DF",
        SPICY: "S"
    };

    const dietarySequence = ["VEGETARIAN", "VEGAN", "GLUTEN-FREE", "DAIRY-FREE", "SPICY"];

    const sortAndMapDietary = (arr1) => {
        // Create a copy of arr1 to avoid modifying the original array
        const sortedArr = [...arr1];

        // Sort the array based on the index in dietarySequence
        sortedArr.sort((a, b) => dietarySequence.indexOf(a.dietary) - dietarySequence.indexOf(b.dietary));

        // Map each value using dietaryMap
        const mappedArr = sortedArr.map((obj) => dietaryMap[obj.dietary]);

        // Return the resulting array as in the original format
        return mappedArr;
    };
    const showDietaryForItem = (orderItem) => {
        const temp = sortAndMapDietary(orderItem.item?.itemDietary);
        return temp.map((item) => {
            return (
                <Box
                    sx={{
                        width: "19px",
                        height: "19px",
                        border: "1px solid #000",
                        borderRadius: "50%",
                        fontSize: "7.5px",
                        fontWeight: 700,
                        lineHeight: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    {item}
                </Box>
            );
        });
    };

    const sortAndMapModifierDietary = (arr1) => {
        // Create a copy of arr1 to avoid modifying the original array
        const sortedArr = [...arr1];

        // Sort the array based on the index in dietarySequence
        sortedArr.sort((a, b) => dietarySequence.indexOf(a.modifier_dietary) - dietarySequence.indexOf(b.modifier_dietary));

        // Map each value using dietaryMap
        const mappedArr = sortedArr.map((obj) => dietaryMap[obj.modifier_dietary]);

        // Return the resulting array as in the original format
        return mappedArr;
    };

    const showDietaryForModifier = (orderItem) => {
        const temp = sortAndMapModifierDietary(orderItem?.modifierDietary);
        return temp.map((item) => {
            return (
                <Box
                    sx={{
                        width: "19px",
                        height: "19px",
                        border: "1px solid #000",
                        borderRadius: "50%",
                        fontSize: "7.5px",
                        fontWeight: 700,
                        lineHeight: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    {item}
                </Box>
            );
        });
    };

    const calculateTotalItemPrice = (orderItem) => {
        let totalPrice = parseFloat(orderItem?.price);

        orderItem?.orderItemsModifiers.forEach((row) => {
            totalPrice += row.total_price;
        });
        totalPrice += parseFloat(orderItem?.packaging_price);

        return parseFloat(totalPrice).toFixed(2);
    };

    const handlePrintModal = () => {
        setPrintOptionModal((pre) => !pre);
    };

    const elementsToExclude = document.querySelectorAll(".order_status");

    const fetchImageAsDataURL = (url) => {
        return fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }
                return response.blob();
            })
            .then((blob) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            })
            .catch((error) => {
                console.error(`Failed to fetch image ${url}:`, error);
                throw error;
            });
    };

    const replaceImageSources = (element) => {
        const images = element.querySelectorAll("img");
        const promises = [];

        images.forEach((img) => {
            const url = img.src;
            const promise = fetchImageAsDataURL(url)
                .then((dataUrl) => {
                    img.src = dataUrl;
                })
                .catch((error) => {
                    console.error(`Error fetching image ${url}`, error);
                });
            promises.push(promise);
        });

        return Promise.all(promises);
    };
    const downloadPdfDocument = async () => {
        elementsToExclude.forEach((element) => {
            element.style.display = "none";
        });

        const input = document.getElementById("content");
        const footerBox = document.getElementById("footer");
        const img = document.getElementById("img");
        footerBox.style.paddingBottom = "3mm";
        if (img) {
            img.src = imageUrl;
            img.style.height = "auto";
            img.style.width = "126px";
            img.style.maxHeight = "54px";
        }

        const pdfWidth = 210;
        const pdfHeight = 297;

        const margin = {
            top: 2,
            right: 10,
            bottom: 0,
            left: 10
        };

        const contentWidth = pdfWidth - margin.left - margin.right;

        const pdf = new JsPDF({
            orientation: "portrait",
            unit: "mm",
            format: [pdfWidth, pdfHeight]
        });

        const addFooterToPage1 = (footerCanvas) => {
            const footerMargin = 0;
            let currentY = pdfHeight - margin.bottom;

            if (footerCanvas) {
                const footerImgData = footerCanvas.toDataURL("image/png");
                const footerImgWidth = footerCanvas.width;
                const footerImgHeight = footerCanvas.height;
                const footerRatio = contentWidth / footerImgWidth;
                const scaledFooterHeight = footerImgHeight * footerRatio;

                currentY -= scaledFooterHeight;
                pdf.addImage(footerImgData, "PNG", margin.left, currentY, contentWidth, scaledFooterHeight);
                currentY -= footerMargin;
            }
        };
        const jj = await html2canvas(footerBox, {
            useCORS: true,
            allowTaint: true,
            scale: 2
        });

        const opt = {
            callback: function (pdf) {
                const totalPages = pdf.internal.pages.length - 1;
                for (let i = 1; i <= totalPages; i += 1) {
                    pdf.setPage(i);
                    addFooterToPage1(jj);
                }
                pdf.save(`${quoteListData?.order?.brand?.brand_name}_${quoteListData?.order?.order_number}.pdf`);
            },
            margin: [5, 0, 15, 15],
            autoPaging: "text",
            html2canvas: {
                allowTaint: true,
                dpi: 300,
                letterRendering: true,
                logging: false,
                scale: 0.2
            }
        };
        setPrintSelect("");

        pdf.html(input, opt);
    };

    const handlePrintDownload = (price) => {
        downloadPdfDocument();
        setPrintOptionModal(false);
        // setPrintOptionModal((pre) => {
        //     if (pre) {
        //         setPrintSelect("");
        //     }
        //     return !pre;
        // });
    };

    const getQuoteDetailList = async (id) => {
        setLoading(true);
        const instance = NetworkManager(API.ORDER.QUOTEDETAIL(id));
        const response = await instance.request();
        if (response.success) {
            setQuoteListData(response.data);

            const sortedItem = [];
            response.data.category.forEach((row) => {
                row.orderItems.forEach((col) => {
                    sortedItem.push(col);
                });
            });
            sortedItem.sort((a, b) => a.item_ordering - b.item_ordering);
            const total = sortedItem.map((item) => parseFloat(calculateTotalItemPrice(item)));
            const sum = total.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setSubTotal(sum.toFixed(2));
            setItemDetailSorted(sortedItem);
        }
        setLoading(false);
    };

    const [driverListing, setDriverListing] = React.useState([]);
    const getDriverList = async () => {
        const instance = NetworkManager(API.DRIVER.LISTING);

        const response = await instance.request();

        if (response.success) {
            const driverList = response.data?.driver;
            // Modified array of objects with new property names
            const modifiedArray = driverList
                ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                ?.map((obj) => ({
                    phone: obj.phoneNumber,
                    name: obj.name,
                    ...obj
                }));
            setDriverListing(modifiedArray);
        }
    };

    useEffect(() => {
        getDriverList();
        if (selectedRow?.order_id) {
            getQuoteDetailList(selectedRow?.order_id);
            getDriverListSecondary(OrderId);
        }
    }, [selectedRow?.order_id]);

    const url = quoteListData?.order?.brand?.brand_logo;

    useEffect(() => {
        const fetchImage = async () => {
            if (url) {
                try {
                    const baseUrl = process.env.REACT_APP_BASE_URL;
                    const response = await fetch(`${baseUrl}api/v1/brand/image/get?url=${encodeURIComponent(url)}`);

                    console.log(response);
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    const blob = await response.blob();
                    setImageUrl(URL.createObjectURL(blob));
                } catch (error) {
                    console.error("Failed to fetch image:", error);
                }
            }
        };

        fetchImage();
    }, [url]);

    const print = (price) => {
        setPrintSelect("");
        handlePrintModal();
        window.open(`/print?orderId=${selectedRow.order_id}&price=${price}`, "_blank", "noreferrer");

        // window.print();
    };
    const columnData = [
        {key: "order_number", label: "Order #"},
        {key: "created_at", label: "Created"},
        {key: "delivery_date", label: "Delivery"},
        {key: "customer", label: "Customer"},
        {key: "delivery_address", label: "Address"},
        {key: "service_type", label: "Service"},
        {key: "kitchen_location.market.market_name", label: "Market"},
        {key: "brand.brand_name", label: "Brand"},
        {key: "total_price", label: "Total"},
        {key: "order.status", label: "Status"}
    ];

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                setOpenQuoteModal(false);
                handleEditOrder(row.order_id);
                break;
            }
            case "Download": {
                // csvBuilder(row, columnData, setSelectedCheckbox);
                setPrintOptionModal(true);
                setPriceParam("customer_order");
                setPrintSelect("download");
                break;
            }
            case "Delete draft": {
                handleDeleteModal();
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            case "Cancel order": {
                handleCancelModal();
                break;
            }
            case "Re-send quote": {
                setOpenQuoteModal(true);
                toggleDrawer("right", "SendQuote")(e);
                break;
            }
            case "Print": {
                handlePrintModal(row);
                break;
            }
            case "Add note": {
                toggleDrawer("right", "addNewNotes")(e);
                break;
            }
            default:
                break;
        }
    };

    useEffect(() => {
        if (showMultipleQuote) {
            setSelectedRow(JSON.parse(selectedCheckbox[0]));
            setOpenQuoteModal(true);
            toggleDrawer("right", "SendQuote")();
        }
    }, [showMultipleQuote]);

    const handleCloseMultipleQuote = (done = false) => {
        setSelectedRow([]);
        setSelectedCheckbox([]);
        setOpenQuoteModal(false);
        toggleDrawer("right", false)();
        setShowMultipleQuote(false);
        if (done === "success") {
            getUnplacedOrderList();
        }
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.ORDER.DELETE(selectedRow.order_id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getUnplacedOrderList();
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(number + 1));
    };

    const handleSort = (val1, val2) => {
        if (val2 === "payment_by") {
            dispatch(setOrder(val1));
            dispatch(setOrderBy("charged_by"));
        } else if (val2 === "kitchen_location.market.market_name") {
            const splitText = val2.split(".");
            dispatch(setOrder(val1));
            dispatch(setOrderBy(splitText[2]));
        } else if (val2 === "order.status" || val2 === "brand.brand_name") {
            const splitText = val2.split(".");
            dispatch(setOrder(val1));
            dispatch(setOrderBy(splitText[1]));
        } else if (val2 === "customer") {
            const filterText = `${val2}_name`;
            dispatch(setOrder(val1));
            dispatch(setOrderBy(filterText));
        } else {
            dispatch(setOrder(val1));
            dispatch(setOrderBy(val2));
        }
    };
    useEffect(() => {
        if (search) {
            dispatch(setPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        getUnplacedOrderList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, filterChips, orderUpdate, updateListCount, state]);

    const handleAddItemToCateogoryList = (item) => {
        // getUnplacedOrderList();
    };

    const getMarketList = async (page = 1, pageSize = 1000) => {
        const tempData = [];
        const instance = NetworkManager(API.MARKET.GET);
        const params = {
            page: page,
            pageSize: pageSize
        };
        if (currentOrder) {
            params.sortBy = "createdAt";
            params.orderBy = "desc";
        }

        const response = await instance.request({}, params);

        if (response.success) {
            response.data.marketList.forEach((el) => {
                tempData.push({id: el.market_id, label: el.market_name});
            });
        }
        return tempData;
    };

    const getBrandList = async (page = 1, pageSize = 1000) => {
        const tempData = [];
        const instance = NetworkManager(API.BRAND.GET);
        const params = {
            page: page,
            pageSize: pageSize
        };
        if (currentOrderBy) {
            params.sortBy = "createdAt";
            params.orderBy = "desc";
        }

        const response = await instance.request({}, params);

        if (response.success) {
            response.data.brandList.forEach((el) => {
                tempData.push({id: el.brand_id, label: el.brand_name});
            });
        }
        return tempData;
    };

    const filterState = [
        {
            key: "search",
            label: "Order id",
            type: "textfield"
        },
        {
            key: "start_date",
            label: "Created from",
            type: "startDate"
        },
        {
            key: "end_date",
            label: "Created to",
            type: "endDate"
        },
        {
            key: "delivery_start_date",
            label: "Delivery from",
            type: "startDate"
        },
        {
            key: "delivery_end_date",
            label: "Delivery to",
            type: "endDate"
        },
        {
            key: "customer_name",
            label: "Customer",
            type: "textfield"
        },
        {
            key: "delivery_address",
            label: "Address",
            type: "textfield"
        },
        {
            key: "source",
            label: "Source",
            type: "multiselect",
            getData: () => [
                {id: "ADMIN", label: "Direct order"},
                {id: "Customer", label: "Marketplace"},
                {id: "EZcater", label: "EZcater"},
                {id: "EZorder", label: "EZorder"}
            ]
        },
        {
            key: "service_type",
            label: "Service",
            type: "multiselect",
            getData: () => [
                {id: "CATERING", label: "Catering"},
                {id: "HOME_MEALS", label: "Home meals"}
            ]
        },
        {
            key: "market_id",
            label: "Market",
            type: "multiselect",
            getData: getMarketList
        },
        {
            key: "brand_id",
            label: "Brand",
            type: "multiselect",
            getData: getBrandList
        },
        {
            key: "guest_count",
            label: "Guests",
            type: "numberfield",
            filterType: "Equals",
            filterKey: "guestFilter"
        },
        {
            key: "total_amount",
            label: "Total",
            type: "numberfieldWithDecimal",
            filterType: "Equals",
            filterKey: "amountFilter"
        },
        {
            key: "status",
            label: "Status",
            type: "select",
            getData: () => [
                {id: "Quote Sent", label: "Quote sent"},
                {id: "QUOTE_REJECTED", label: "Quote rejected"},
                {id: "Draft", label: "Draft"}
            ]
        }
    ];

    const handleApplyFilter = (Morefilters) => {
        dispatch(setPageNumber(1));
        // setFilterChips(Morefilters);
    };

    return (
        <>
            {showFilter ? (
                <Filters
                    filterState={filterState}
                    filterChips={filterChips}
                    // setFilterChips={setFilterChips}
                    handleApplyFilter={handleApplyFilter}
                    sideBarStateName="orderFilter"
                />
            ) : null}

            <Grid container>
                <Grid item xs={12}>
                    {/* <FilterChips
                        filterChips={filterChips}
                        //  setFilterChips={setFilterChips}
                    /> */}
                    <CommonTable
                        withCheckbox
                        loading={loading}
                        data={unplacedOrderListing}
                        checkbox
                        header={[
                            {key: "order_number", label: "Order #"},
                            {key: "created_at", label: "Created"},
                            {key: "delivery_date", label: "Delivery"},
                            {key: "customer", label: "Customer"},
                            {key: "delivery_address", label: "Address"},
                            {key: "service_type", label: "Service"},
                            {key: "kitchen_location.market.market_name", label: "Market"},
                            {key: "brand.brand_name", label: "Brand"},
                            {key: "total_price", label: "Total"},
                            {key: "order.status", label: "Status"},
                            {key: "action", label: "Action"}
                        ]}
                        height="75vh"
                        actions={[]}
                        handleActionBtn={handleActionBtn}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage - 1}
                        sortingLabel={[
                            "Order #",
                            "Created",
                            "Delivery",
                            "Total",
                            "Brand",
                            "Customer",
                            "Status",
                            "Service",
                            "Payment",
                            "Market"
                        ]}
                        setSelectedCheckbox={setSelectedCheckbox}
                        selectedCheckbox={selectedCheckbox}
                    />
                </Grid>
            </Grid>
            {state.right === "addNewNotes" && (
                <OrderNotes
                    userID={selectedRow?.customer?.user_id}
                    selectedData={selectedRow}
                    handleIsEdited={handleIsEdited}
                    handleSelectedRow={handleSelectedRow}
                    setShowFilter={() => {}}
                    // setSelectedRow={setSelectedRow}
                />
            )}
            <PrintOrderModal
                open={printOptionModal}
                buttonTitle={printSelect === "download" ? "Download order" : "Print order"}
                title={printSelect === "download" ? "Download order" : "Print order"}
                description={
                    printSelect === "download"
                        ? "Select the type of order you would like to download"
                        : "Select the type of order you would like to print"
                }
                handleClose={() => {
                    setPrintOptionModal(false);
                    setPrintSelect("");
                }}
                handleSubmit={(price) => {
                    if (printSelect === "download") {
                        handlePrintDownload(price);
                    } else {
                        print(price);
                    }
                }}
                noOnClose
                selected={priceParam}
                onSelect={(price) => {
                    setPriceParam(price);
                    setShowHeading(price === "price");
                }}
            />

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this order?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
            <DeleteModal
                open={openCancelModal}
                buttonTitle="Confirm"
                title="Are you sure you want to cancel this order?"
                description="This will permanently cancel the order."
                handleClose={handleCancelModal}
                handleSubmit={handleCancelOrder}
            />

            {selectedRow && openQuoteModal && (
                <SendQuote
                    orderId={{order_id: selectedRow?.order_id, order_number: selectedRow?.order_number}}
                    buttonTitle={showMultipleQuote ? "Send quotes" : "Re-send quote"}
                    customerId={selectedRow?.customer?.email}
                    userId={selectedRow?.customer?.user_id}
                    brandName={selectedRow?.brand?.brand_name}
                    brandId={selectedRow?.brand?.brand_id}
                    showMultipleQuote={showMultipleQuote}
                    selectedCheckbox={selectedCheckbox}
                    setSelectedCheckbox={setSelectedCheckbox}
                    handleCloseMultipleQuote={handleCloseMultipleQuote}
                />
            )}

            {selectedRow && printSelect === "download" && (
                <PDFComponent
                    quoteListData={quoteListData}
                    PriceParam={priceParam}
                    getColorOfStatus={getColorOfStatus}
                    showDietaryForItem={showDietaryForItem}
                    // handleDriverModal={handleDriverModal}
                    handleUnassignDriverModal={handleUnassignDriverModal}
                    itemDetailSorted={itemDetailSorted}
                    metersToMiles={metersToMiles}
                    showDietaryForModifier={showDietaryForModifier}
                    calculateTotalItemPrice={calculateTotalItemPrice}
                    handleAdvanceString={handleAdvanceString}
                    handleSalesTaxString={handleSalesTaxString}
                    subTotal={subTotal}
                    showHeading={showHeading}
                    secondaryDriver={secondaryDriver}
                    getBackgroundOfStatus={getBackgroundOfStatus}
                    printSelect={printSelect}
                    driverListing={driverListing}
                    imageUrl={imageUrl}
                    zIndex={-11}
                />
            )}
        </>
    );
};

export default DraftTab;
