import React, {useEffect, useState} from "react";
import PopUp from "commonComponent/Popup/Popup";

function TableWarePopup({updatePopup, setUpdatePopup, onSave, plates, cutlery, utensile}) {
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [initialCheckboxes, setInitialCheckboxes] = useState([]);
    const checkboxLabels = ["Plates", "Cutlery with napkins", "Serving utensils"];

    const handleUpdatePopup = () => {
        setUpdatePopup(!updatePopup);
        setSelectedCheckboxes(initialCheckboxes); // Revert changes on popup close
    };

    const handleUpdateTableWare = async () => {
        const sortedCheckboxes = checkboxLabels.filter((label) => selectedCheckboxes.includes(label));
        onSave(sortedCheckboxes);
        setInitialCheckboxes(sortedCheckboxes); // Save the updated state
        setUpdatePopup(false); // Close the popup
    };

    const handleCheckboxChange = (checkboxId) => {
        const updatedCheckboxes = selectedCheckboxes.includes(checkboxId)
            ? selectedCheckboxes.filter((id) => id !== checkboxId)
            : [...selectedCheckboxes, checkboxId];

        setSelectedCheckboxes(updatedCheckboxes);
    };

    useEffect(() => {
        const arr = [];

        if (plates) {
            arr.push("Plates");
        }
        if (cutlery) {
            arr.push("Cutlery with napkins");
        }
        if (utensile) {
            arr.push("Serving utensils");
        }
        setSelectedCheckboxes(arr);
        setInitialCheckboxes(arr); // Initialize the initial state
    }, [plates, cutlery, utensile]);

    return (
        <div>
            <PopUp
                open={updatePopup}
                title="Tableware"
                description="Please tell us what you need"
                handleClose={handleUpdatePopup}
                handleSubmit={handleUpdateTableWare}
                handleCheckboxChange={handleCheckboxChange}
                selectedCheckboxes={selectedCheckboxes}
                checkboxLabels={checkboxLabels}
            />
        </div>
    );
}

export default TableWarePopup;
