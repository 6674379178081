import * as React from "react";
import Popper from "@mui/material/Popper";
import { IconButton, ClickAwayListener, Grow, Paper, MenuList, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styles from "./appTable.module.scss";
import { useStyles } from "./AppTableStyles";

export default function ActionButton({ ActionArray, handleActionBtn, row, ind }) {
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const style = useStyles();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };

    const handleClose = (event) => {
        if (anchorEl.current && anchorEl.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    };

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? "spring-popper" : undefined;

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '100%'
        }}>
            <IconButton aria-describedby={id} type="button" onClick={handleClick} sx={style.actionButton.iconButton} color="#9B9EA6">
                <MoreVertIcon
                    sx={(t) => ({
                        color: t.palette.other.actionBtn
                    })}
                />
            </IconButton>
            <Popper elevation={0} transition id={id} open={open} anchorEl={anchorEl} sx={style.actionButton.popover}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? style.actionButton.grow.top : style.actionButton.grow.bottom
                        }}
                    >
                        <Paper
                            elevation={0}
                            // style={{

                            // }}
                            sx={style.actionButton.paper}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {ActionArray?.map(
                                        (item, index) =>
                                            item && (
                                                <MenuItem
                                                    key={index}
                                                    onClick={(e) => {
                                                        handleClose()
                                                        handleActionBtn(item, row, handleClose, ind)(e)
                                                    }}
                                                    className={styles.menuItem}
                                                >
                                                    {item}
                                                </MenuItem>
                                            )
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}
