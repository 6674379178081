import React from "react";

const FilterContext = React.createContext();

const FilterContextProvider = ({children}) => {
    const [openFilter, setOpenFilter] = React.useState(false);

    const handleFilter=(bool=null)=>{
        if(bool !==null){
        setOpenFilter(bool)
        }
        else{
            setOpenFilter((prev) => !prev);
        }

    }

    
    return (
        <>
            <FilterContext.Provider value={{openFilter,handleFilter}}>{children}</FilterContext.Provider>
        </>
    );
};

export {FilterContextProvider, FilterContext};
