import moment from "moment";
import dayjs from 'dayjs';

const formatDate = (string) => {
    if (string) {
        return moment(string).format("M/DD/YYYY");
    }
    return null;
};

const formatDateDayjs = (string) => {
    if (string) {
        return string;
    }
    return null;
};

export {formatDate,formatDateDayjs};
