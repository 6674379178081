// third-party
import {combineReducers} from "redux";

// project imports
import snackbarReducer from "./slices/snackbar";
import menuReducer from "./slices/menu";
import accountReducer from "./accountReducer";
import orderReducer from "./orderReducer";
import previewReducer from "./slices/preview";
import headerDataReducer from "./slices/ordername";
import paginationReducer from "./slices/paginationSlice";
import cardDataReducer from "./slices/card";
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    account: accountReducer,
    order: orderReducer,
    preview: previewReducer,
    headerdata: headerDataReducer,
    pagination: paginationReducer,
    cardData: cardDataReducer
});

export default reducer;
