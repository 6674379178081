import {Grid} from "@mui/material";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {NetworkManager, API} from "network/core";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {generateUrl} from "utils/helperFunctions/helpers";
import useTempDrawer from "hooks/useTempDrawer";
import AddNewUser from "../../AddNewUser/AddNewUser";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {setPageNumber, setRowsPerPage, setOrder, setOrderBy} from "store/slices/paginationSlice";

// import CreateCustomerForm from "../../CreateCustomer/CreateCustomerForm";

const Users = (props) => {
    const {toggleDrawer, state} = useTempDrawer();
    const {search, setSelectedCheckbox, updateListCount, selectedCheckbox, filterChips} = props;
    const [title, setTitle] = useState("Create Customer");

    const [open, setOpen] = React.useState(false);
    const [userListing, setUserListing] = React.useState([]);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.order || "asc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "first_name");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };
    const getUserList = async (moreFilters = false) => {
        setLoading(true);

        const instance = NetworkManager(API.SUBADMIN.LISTING);
        const params = {
            offset: currentPage,
            limit: rowsPerPage,
            // page: currentPage,
            // pageSize: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };
        if (currentOrderBy) {
            params.sortBy = currentOrderBy;
            params.orderBy = currentOrder;
        }

        if (search === "") {
            params.page = currentPage;
        }
        if (search !== "") {
            params.search = search.trim();
            setUserListing([]);
        }
        if (moreFilters.email) {
            params.email = encodeURIComponent(moreFilters.email);
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);

        if (response.success) {
            setUserListing(response.data?.subAdmins);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
        setSelectedRow(null);
    };

    const handleStatus = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.SUBADMIN.UPDATE);
        const payload = {
            user_id: values.user_id,
            status: type === "Deactivate" ? "DEACTIVATE" : "ACTIVATE"
        };
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleResendInvite = async (values) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.SUBADMIN.REINVITE(values.user_id));

        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                toggleDrawer("right", "addNewUser")(e);
                break;
            }
            case "Deactivate": {
                handleStatus(row, type);
                break;
            }
            case "Activate": {
                handleStatus(row, type);
                break;
            }
            case "Re-send invite": {
                handleResendInvite(row, type);
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            default:
                break;
        }
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.SUBADMIN.DELETE(selectedRow.user_id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getUserList();
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
        // setCurrentPage(number + 1);
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        // setRowsPerPage(number);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };
    const handleSort = (val1, val2) => {
        if (val2 === "user.status") {
            const splitText = val2.split(".");
            dispatch(setOrder(val1));
            dispatch(setOrderBy(splitText[1]));
        } else {
            dispatch(setOrder(val1));
            dispatch(setOrderBy(val2));
        }
    };

    useEffect(() => {
        if (search) {
            dispatch(setPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        getUserList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, filterChips, updateListCount]);

    // const filterState = [
    //     // {
    //     //     key: "search",
    //     //     label: "Username",
    //     //     type: "textfield"
    //     // },
    //     // {
    //     //     key: "email",
    //     //     label: "Email",
    //     //     type: "emailfield"
    //     // },
    //     {
    //         key: "status",
    //         label: "Status",
    //         type: "select",
    //         getData: () => [
    //             {id: "ACTIVATE", label: "Active"},
    //             {id: "DEACTIVATE", label: "Inactive"},
    //             {id: "INVITED", label: "Invited"}
    //         ]
    //     },
    //     {
    //         key: "roleIds",
    //         label: "Role",
    //         type: "select",
    //         getData: roles
    //     }
    // ];
    return (
        <>
            {/* {showFilter ? (
                <Filters
                    filterState={filterState}
                    filterChips={filterChips}
                    setFilterChips={setFilterChips}
                    handleApplyFilter={handleApplyFilter}
                />
            ) : null} */}
            {state.right === "addNewUser" && (
                <AddNewUser
                    sideBarStateName="addNewUser"
                    selectedRow={selectedRow}
                    setTitle={setTitle}
                    conditionalTitle={title}
                    handleIsEdited={handleIsEdited}
                />
            )}

            <Grid container>
                <Grid item xs={12}>
                    <CommonTable
                        withCheckbox
                        loading={loading}
                        data={userListing}
                        currentTab="settings"
                        notCaptilize
                        header={[
                            {key: "first_name", label: "User"},
                            {key: "email", label: "Email"},
                            {key: "phone", label: "Phone"},
                            {key: "role", label: "Role"},
                            {key: "createdAt", label: "Created"},
                            {key: "designation", label: "Designation"},
                            {key: "linkedin_link", label: "Linkedin link"},
                            {key: "calendar_link", label: "Calendar link"},

                            {key: "user.status", label: "Status"},
                            // {key: "last_order", label: "Last order"},
                            // {key: "isActivate", label: "Status"},

                            {key: "action", label: "Action"}
                        ]}
                        actions={[
                            WithPermission({module: "Users", permission: ["update_permission"]}) && "Edit",
                            WithPermission({module: "Users", permission: ["delete_permission"]}) && "Delete",
                            "Activate"
                        ]}
                        handleActionBtn={handleActionBtn}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        setSelectedCheckbox={setSelectedCheckbox}
                        selectedCheckbox={selectedCheckbox}
                        currentPage={currentPage - 1}
                        sortingLabel={["User", "Created", "Status", "Role"]}
                    />
                </Grid>
            </Grid>

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this user?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
        </>
    );
};

export default Users;
