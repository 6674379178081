import {Box, Stack, Grid} from "@mui/material";

import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import CustomeDatePicker from "commonComponent/Form/Fields/CustomeDatePicker";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import useTempDrawer from "hooks/useTempDrawer";
import React, {useEffect, useState} from "react";
import {getformateDate} from "utils/app-dates/dates";
import PerfectScrollbar from "react-perfect-scrollbar";
import {enforceFormat} from "utils/helperFunctions/helpers";

const SelectFieldType = ({obj, state, setState}) => {
    const {type} = obj;
    const [multiSelectData, setMultiSelectData] = useState([]);
    const [selectData, setSelectData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await obj?.getData();
            switch (type) {
                case "multiselect":
                    setMultiSelectData(data);
                    break;
                case "select":
                    setSelectData(data);
                    break;
                default:
                    break;
            }
        };
        if (type === "multiselect" || type === "select") {
            fetchData();
        }
    }, []);

    switch (type) {
        case "textfield": {
            return (
                <CustomTextField
                    fullWidth
                    label={obj?.label}
                    value={state?.[obj?.key] || ""}
                    onChange={(e) => {
                        setState((pre) => ({...pre, [obj?.key]: e.target.value}));
                    }}
                />
            );
        }
        case "emailfield": {
            return (
                <CustomTextField
                    fullWidth
                    label={obj?.label}
                    type="email"
                    value={state?.[obj?.key] || ""}
                    onChange={(e) => {
                        setState((pre) => ({...pre, [obj?.key]: e.target.value}));
                    }}
                />
            );
        }
        case "nameTextfield": {
            return (
                <CustomTextField
                    fullWidth
                    label={obj?.label}
                    onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                    }}
                    value={state?.[obj?.key] || ""}
                    onChange={(e) => {
                        setState((pre) => ({...pre, [obj?.key]: e.target.value}));
                    }}
                />
            );
        }
        case "select": {
            return (
                <>
                    <CustomSelect
                        label={obj?.label}
                        menuItems={selectData}
                        value={state?.[obj?.key] || []}
                        onChange={(e) => {
                            setState((pre) => ({...pre, [obj?.key]: e.target.value}));
                        }}
                    />
                </>
            );
        }
        case "multiselect": {
            return (
                <>
                    <CustomSelectWithCheckbox
                        selectAll
                        label={obj?.label}
                        menuItems={multiSelectData}
                        values={state?.[obj?.key] || []}
                        onChange={(val) => setState((pre) => ({...pre, [obj?.key]: val}))}
                    />
                </>
            );
        }
        case "createdAt": {
            return (
                <>
                    <CustomeDatePicker
                        label={obj?.label}
                        views={["day"]}
                        valueBasic={state?.[obj?.key] || null}
                        setValueBasic={(val) => setState((pre) => ({...pre, [obj?.key]: getformateDate(val), isCreatedAt: true}))}
                    />
                </>
            );
        }
        case "startDate": {
            return (
                <>
                    <CustomeDatePicker
                        label={obj?.label}
                        valueBasic={state?.[obj?.key] || null}
                        setValueBasic={(val) => setState((pre) => ({...pre, [obj?.key]: getformateDate(val), isCreatedAt: false}))}
                    />
                </>
            );
        }
        case "endDate": {
            return (
                <>
                    <CustomeDatePicker
                        {...(state?.start_date && {
                            minDate: new Date(state?.start_date)
                        })}
                        label={obj?.label}
                        valueBasic={state?.[obj?.key] || null}
                        setValueBasic={(val) => setState((pre) => ({...pre, [obj?.key]: getformateDate(val), isCreatedAt: false}))}
                    />
                </>
            );
        }
        case "numberfield": {
            return (
                <>
                    <Box sx={{display: "flex", gap: "1rem"}}>
                        <CustomTextField
                            fullWidth
                            label={obj?.label}
                            value={state?.[obj?.key] || ""}
                            inputProps={{
                                maxLength: 50,
                                onInput: (e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                                }
                            }}
                            onChange={(e) => {
                                setState((pre) => ({...pre, [obj?.key]: e.target.value}));
                            }}
                        />
                        <CustomSelect
                            sx={{minWidth: 115}}
                            // label={obj?.filterType}
                            menuItems={[
                                {label: "Equals", value: "equal", id: "equal"},
                                {label: "Greater than", value: "greater than", id: "_greater_than"},
                                {label: "Less than", value: "less than", id: "_less_than"},
                                {label: "Not equal", value: "not equal", id: "_not_equal"}
                            ]}
                            value={state?.[obj?.filterKey]}
                            onChange={(e) => {
                                setState((pre) => ({...pre, [obj?.filterKey]: e.target.value}));
                            }}
                        />
                    </Box>
                </>
            );
        }
        case "numberfieldWithDecimal": {
            return (
                <>
                    <Box sx={{display: "flex", gap: "1rem"}}>
                        <CustomTextField
                            fullWidth
                            label={obj?.label}
                            value={state?.[obj?.key] || ""}
                            onKeyDown={enforceFormat}
                            inputProps={{
                                maxLength: 50
                                // onInput: (e) => {
                                //     e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                                // }
                            }}
                            onChange={(e) => {
                                setState((pre) => ({...pre, [obj?.key]: e.target.value}));
                            }}
                        />
                        <CustomSelect
                            sx={{minWidth: 115}}
                            // label={obj?.filterType}
                            menuItems={[
                                {label: "Equals", value: "equal", id: "equal"},
                                {label: "Greater than", value: "greater than", id: "_greater_than"},
                                {label: "Less than", value: "less than", id: "_less_than"},
                                {label: "Not equal", value: "not equal", id: "_not_equal"}
                            ]}
                            value={state?.[obj?.filterKey]}
                            onChange={(e) => {
                                setState((pre) => ({...pre, [obj?.filterKey]: e.target.value}));
                            }}
                        />
                    </Box>
                </>
            );
        }
        default: {
            return (
                <CustomTextField
                    fullWidth
                    label={obj?.label}
                    value={state?.[obj?.key]}
                    onChange={(e) => {
                        setState((pre) => ({...pre, [obj?.key]: e.target.value}));
                    }}
                />
            );
        }
    }
};

const Filters = (props) => {
    const {handleApplyFilter, sideBarStateName = false, filterChips = {}, setFilterChips = () => {}, filterState = []} = props;
    const {toggleDrawer} = useTempDrawer({});
    const [state, setState] = useState(filterChips);

    useEffect(() => {
        setState(filterChips);
    }, [filterChips]);

    return (
        <TemporaryDrawer useForFilter sideBarStateName={sideBarStateName}>
            <Stack sx={{height: "100%"}} justifyContent="space-between">
                <PerfectScrollbar>
                    <Stack spacing={2}>
                        <PageHeader title="Filters" fontSize="1.625rem" showOnlyTitle />
                        {filterState?.length &&
                            filterState?.map((obj, index) => (
                                <Box key={index}>
                                    <SelectFieldType obj={obj} state={state} setState={setState} />
                                </Box>
                            ))}
                    </Stack>
                </PerfectScrollbar>
                <Grid item justifyContent="center" alignItems="center" pt={1}>
                    <ButtonGrouping
                        btnprops={[
                            {
                                btnTitle: Object.keys(state).length ? "Clear all" : "Cancel",
                                sx: (t) => ({color: t.palette.error.dark}),
                                onClick: (e) => {
                                    if (Object.keys(state).length) {
                                        setFilterChips({});
                                        return;
                                    }
                                    toggleDrawer("right", false)(e);
                                }
                            },
                            {
                                btnTitle: "Apply",
                                variant: "contained",
                                sx: (t) => ({color: t.palette.background.paper}),
                                onClick: (e) => {
                                    handleApplyFilter(state);
                                    toggleDrawer("right", false)(e);
                                }
                            }
                        ]}
                    />
                </Grid>
            </Stack>
        </TemporaryDrawer>
    );
};

export default Filters;
