import * as React from "react";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, {TooltipProps, tooltipClasses} from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

const DarkTooltip = styled(({className, ...props}) => <Tooltip {...props} classes={{popper: className}} />)(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#26272A",
        borderRadius: "4px",
        boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.09)",
        color: "#FFF",
        fontSize: "12px",
        fontWeight: 400,
        padding: 8,
        width: 350,
        fontFamily: "Inter"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#FFF",
        "&::before": {
            backgroundColor: "#26272A",
            boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.09)"
        }
    }
}));

export default function CustomizedTooltips({children, placement, title, ...rest}) {
    return (
        <DarkTooltip {...rest} title={title} arrow placement={placement}>
            {children}
        </DarkTooltip>
    );
}
