import {NetworkManager, API} from "network/core";

export const usePromotionModel = () => {
    const fetchMarketList = async (params) => {
        const instance = NetworkManager(API.MARKET.GET);
        const response = await instance.request({}, params);
        return response;
    };
    const fetchBrandList = async (params) => {
        const instance = NetworkManager(API.BRAND.GET);
        const response = await instance.request({}, params);
        return response;
    };
    return {
        fetchMarketList,
        fetchBrandList
    };
};
