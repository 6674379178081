import React, {useLayoutEffect} from "react";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import {Grid} from "@mui/material";
import {useUpdateBrandController} from "./brandDetail.controller";
import CustomizedTabs from "commonComponent/NewTab";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import {useSearchParams} from "react-router-dom";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {API, NetworkManager} from "network/core";
import Filter from "commonComponent/FilterAccoridan";
import FilterChips from "commonComponent/FilterChipsComponent";
import {sortFunction} from "utils/helperFunctions/helpers";

const BrandDetail = () => {
    const {
        handleChange,
        dummyData,
        value,
        id,
        btnlabel,
        handleHeaderActions,
        handleSearch,
        headerName,
        breadScrum,
        filterChips,
        // setFilterChips,
        showFilter,
        brandList,
        setBrandList,
        brand
    } = useUpdateBrandController();
    const [searchParams] = useSearchParams();
    const editable = searchParams.get("isEdit");
    const showSearchandFilter = ["profile", "menu"];
    const showButton = ["profile"];
    const tab = searchParams.get("tab");

    console.log("Brand Detail");
    const breadcrumbs = [
        {label: "Brands", href: "/menus?tab=brand"}
        // {label: breadScrum, isActive: true}
    ];

    if (editable === "true" && tab === "profile") {
        breadcrumbs.push({label: "View brands", href: `/brand-detail/${id}?tab=profile&brandName=${headerName}`});
    } else {
        breadcrumbs.push({label: breadScrum, isActive: true});
    }

    if (editable === "true" && tab === "profile") {
        // Add "Edit Brand" breadcrumb when editable is true
        breadcrumbs.push({label: "Edit brand", isActive: true});
    }
    const filterState = [
        {
            key: "menu_name",
            label: "Menu",
            type: "textfield"
        },
        {
            key: "brand_id",
            label: "Brand",
            type: "multiselect",
            getData: brandList
        },
        {
            key: "service_name",
            label: "Service",
            type: "multiselect",
            getData: () => [
                {id: "CATERING", label: "Catering"},
                {id: "HOME_MEALS", label: "Home meals"}
            ]
        },
        {
            key: "start_date",
            label: "Created",
            type: "createdAt"
        },
        {
            key: "is_active",
            label: "Status",
            type: "select",
            getData: () => [
                {id: "active", label: "Active"},
                {id: "inactive", label: "Inactive"}
            ]
        }
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <TableTopFilterWrapper
                    onlyHeading={!showSearchandFilter.includes(tab)}
                    lessmarginOnTop
                    pageheader={<PageHeader title={headerName || "Brand"} breadsCrumData={breadcrumbs} />}
                    {...(editable !== "true" && {onlyBtn: showButton.includes(tab)})}
                    {...(tab === "profile" &&
                        editable === "true" && {
                            onlyHeading: true
                        })}
                    onlyBtn={value === "profile"}
                    btnTitle={WithPermission({module: "Brands", permission: "update_permission"}) && btnlabel}
                    handleClick={handleHeaderActions}
                    handleSearch={handleSearch}
                    filterType="none"
                >
                    <Filter
                        filterState={filterState}
                        filterChips={filterChips}
                        // setFilterState={setFilterChips}
                        showFilter={showFilter}
                        isNest={brand === "brand-detail"}
                    />
                    <FilterChips
                        filterChips={filterChips}
                        isNest={brand === "brand-detail"}

                        // setFilterChips={setFilterChips}
                    />
                    <CustomizedTabs tabsInfo={dummyData} value={value} handleChange={handleChange} />
                </TableTopFilterWrapper>
            </Grid>
        </Grid>
    );
};

export default BrandDetail;
