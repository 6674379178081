import React, {useLayoutEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "store";
import {openSnackbar} from "store/slices/snackbar";
import {API, NetworkManager} from "network/core";
import useTempDrawer from "hooks/useTempDrawer";
import {generateUrl, sortFunction} from "utils/helperFunctions/helpers";
import {
    setChildPageNumber,
    setChildRowsPerPage,
    setChildShowFilter,
    setPageNumber,
    setRowsPerPage,
    setShowFilter,
    setChildOrder,
    setChildOrderBy
} from "store/slices/paginationSlice";

const useLocationController = () => {
    const [searchParam] = useSearchParams();
    const marketName = searchParam.get("marketName");
    const prm = useParams();
    const {id} = prm;
    const navaigation = useNavigate();
    const {toggleDrawer} = useTempDrawer();
    const {pathname} = useLocation();
    const type = pathname.split("/")[1];
    console.log(type);
    // const [openFilter, setOpenFilter] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [locationListData, setLocationListData] = React.useState([]);

    const [search, setSearch] = useState("");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const filterChips = useSelector((state) => state?.pagination?.filterChips);
    const openFilter = useSelector((state) => state?.pagination?.showFilter);
    const childcurrentPage = useSelector((state) => state?.pagination?.childCurrentPage);
    const childrowsPerPage = useSelector((state) => state?.pagination?.childRowsPerPage);
    const childfilterChips = useSelector((state) => state?.pagination?.childFilterChips);
    const childopenFilter = useSelector((state) => state?.pagination?.childShowFilter);
    const currentOrder = useSelector((state) => state?.pagination?.childOrder || "asc");
    const currentOrderBy = useSelector((state) => state?.pagination?.childOrderBy || "name");

    const [state, setState] = useState([]);
    const [market, setMarket] = useState([]);

    const dispatch = useDispatch();

    const data = [
        {label: "Market", href: "/markets"},
        {label: "Location", href: "/locations"}
    ];

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const handleCreateLocation = (btntype) => (e) => {
        switch (btntype) {
            case "Create location": {
                navaigation(id ? `/locations/addLocation?marketId=${id}&marketName=${marketName}` : `/locations/addLocation`);
                break;
            }
            case "locationFilter": {
                // toggleDrawer("right", true)(e);
                if (type === "marketLocations") {
                    dispatch(setChildShowFilter(!childopenFilter));
                } else {
                    dispatch(setShowFilter(!openFilter));
                }
                break;
            }
            default:
                break;
        }
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handleStatus = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api

        const instance = NetworkManager(API.LOCATION.STATUS(values.kitchen_location_id));

        let isActive = false;
        if (type === "Deactivate") {
            isActive = false;
        } else {
            isActive = true;
        }
        const payload = {status: isActive};

        const response = await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );

            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleActionBtn = (type, rowData, handleClose) => (e) => {
        handleClose();
        switch (type) {
            case "Edit": {
                navaigation(`/locations/editLocation/${rowData.kitchen_location_id}`);
                break;
            }
            case "Delete": {
                handleDeleteModal();
                setSelectedRow(rowData);
                break;
            }
            case "Deactivate": {
                handleStatus(rowData, type);
                break;
            }
            case "Activate": {
                handleStatus(rowData, type);
                break;
            }
            default:
                break;
        }
    };

    const getLocationListData = async (moreFilters = false) => {
        setLoading(true);
        const params = {
            page: type === "marketLocations" ? childcurrentPage : currentPage,
            pageSize: type === "marketLocations" ? childrowsPerPage : rowsPerPage,
            ...(moreFilters && {...moreFilters}),
            ...(id && id !== "addLocation" && {market: id})
        };
        if (currentOrderBy) {
            params.sortBy = currentOrderBy;
            params.orderBy = currentOrder;
        }
        // while searching we dont need page number
        if (search === "") {
            params.page = currentPage;
        }

        if (search !== "") {
            params.location_name = search;
            setLocationListData([]);
        }

        const url = generateUrl(params);

        const instance = NetworkManager(API.LOCATION.GET);
        const response = await instance.request({}, [url]);

        if (response.success) {
            setLocationListData(response.data.kitchenLocationList);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setLoading(false);
    };

    React.useEffect(() => {
        getLocationListData(
            Object.keys(type === "marketLocations" ? childfilterChips : filterChips).length
                ? type === "marketLocations"
                    ? childfilterChips
                    : filterChips
                : ""
        );
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, id, filterChips, childfilterChips, childcurrentPage, childrowsPerPage]);

    const handleDelete = async () => {
        const instance = NetworkManager(API.LOCATION.DELETE(selectedRow.kitchen_location_id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Location Deleted Successfully",
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getLocationListData();
    };

    const handleSearch = (val) => {
        setSearch(val);
        if (type === "marketLocations") {
            dispatch(setChildPageNumber(1));
        } else {
            // setRowsPerPage(number);
            dispatch(setPageNumber(1));
        }
    };

    const handlePageChange = (e, number) => {
        if (type === "marketLocations") {
            dispatch(setChildPageNumber(number + 1));
        } else {
            // setRowsPerPage(number);
            dispatch(setPageNumber(number + 1));
        }
        // setCurrentPage(number + 1);
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        if (type === "marketLocations") {
            dispatch(setChildRowsPerPage(number));
        } else {
            // setRowsPerPage(number);
            dispatch(setRowsPerPage(number));
        }
        dispatch(setPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        dispatch(setChildOrder(val1));
        if (val2 === "state") {
            dispatch(setChildOrderBy("stateName"));
        } else if (val2 === "market") {
            dispatch(setChildOrderBy("market_name"));
        } else {
            dispatch(setChildOrderBy(val2));
        }
    };

    const getMarketList = async (page = 1, pageSize = 1000) => {
        const tempData = [];
        const instance = NetworkManager(API.MARKET.GET);
        const params = {
            page: page,
            pageSize: pageSize
        };
        if (currentOrderBy) {
            params.sortBy = "createdAt";
            params.orderBy = "desc";
        }

        const response = await instance.request({}, params);

        if (response.success) {
            response.data.marketList.forEach((el) => {
                tempData.push({id: el.market_id, label: el.market_name});
            });
        }
        setMarket(sortFunction(tempData));
    };

    const getState = async () => {
        let tempData = [];
        const instance = NetworkManager(API.USER.STATE);
        const response = await instance.request();
        if (response.success) {
            const states = response.data.stateList
                .sort((a, b) => {
                    const labelA = a.name.toLowerCase(); // Ensure case-insensitive sorting
                    const labelB = b.name.toLowerCase();
                    return labelA.localeCompare(labelB);
                })
                .map((state) => ({
                    label: state.name,
                    value: state.name,
                    id: state.state_id
                }));
            tempData = states;
        }
        setState(tempData);
    };
    useLayoutEffect(() => {
        getState();
        getMarketList();
    }, []);
    // const getCity = async () => {
    //     let tempData = [];
    //     const instance = NetworkManager(API.USER.CITY());
    //     const response = await instance.request();
    //     if (response.success) {
    //         const cities = response.data.cityList.map((city) => ({
    //             label: city.name,
    //             value: city.name,
    //             id: city.city_id
    //         }));
    //         tempData = cities;
    //     }
    //     return tempData;
    // };

    const filterState = [
        // {
        //     key: "location_name",
        //     label: "Location name",
        //     type: "textfield"
        // },
        // {
        //     key: "city",
        //     label: "City",
        //     type: "select",
        //     getData: getCity
        // },
        {
            key: "state",
            label: "State",
            type: "multiselect",
            getData: state
        },
        {
            key: "is_active",
            label: "Status",
            type: "select",
            getData: () => [
                {id: "active", label: "Active"},
                {id: "inactive", label: "Inactive"}
            ]
        }
    ];

    if (!id) {
        filterState.splice(1, 0, {
            key: "market",
            label: "Market",
            type: "multiselect",
            getData: market
        });
    }
    const handleApplyFilter = (Morefilters) => {
        // if (Morefilters?.market_ids) {
        //     Morefilters.market_ids = Morefilters.market_ids.join(",")
        // }
        // setFilterChips(Morefilters);
        dispatch(setPageNumber(1));
    };

    return {
        id,
        data,
        locationListData,
        open,
        handleCreateLocation,
        handleActionBtn,
        handleDeleteModal,
        selectedRow,
        totalItems,
        handleSearch,
        handlePageChange,
        handleRowsChange,
        handleSort,
        handleIsEdited,
        handleDelete,
        isEdited,
        rowsPerPage,
        currentPage,
        loading,
        filterState,
        handleApplyFilter,
        filterChips,
        // setFilterChips,
        openFilter,
        childcurrentPage,
        childrowsPerPage,
        childfilterChips,
        childopenFilter,
        type
    };
};

export default useLocationController;
