import React from "react";
import {Stack} from "@mui/material";
import Address from "./sections/Address";
import DateTime from "./sections/DateTime";
import {GuestCount} from "./sections/GuestCount";
import Customer from "./sections/Customer";
import Service from "./sections/Service";
import OrderType from "./sections/OrderType";
import Kitchen from "./sections/Kitchen";

const DelivarySection = (props) => {
    const {formik, orderId, customerName, customerId, editScreen, preferrefPriceRef, kitchenIdRef, deliveryDateRef} = props;
    const {headerData, selectedBrand} = formik.values;

    return (
        <Stack direction="row" className="stack-order" sx={{flexWrap: "wrap"}}>
            <Customer
                preferrefPriceRef={preferrefPriceRef}
                Parentformik={formik}
                customerName={customerName}
                customerId={customerId}
                editScreen={editScreen}
            />
            <Service Parentformik={formik} />
            <OrderType Parentformik={formik} />
            {headerData?.customer?.userId ? <Address Parentformik={formik} orderId={orderId} /> : null}
            <DateTime Parentformik={formik} deliveryDateRef={deliveryDateRef} />
            <GuestCount Parentformik={formik} />
            {headerData?.deliveryTo !== "" ? <Kitchen kitchenIdRef={kitchenIdRef} Parentformik={formik} orderId={orderId} /> : null}
        </Stack>
    );
};

export default DelivarySection;
