import {Grid, Divider} from "@mui/material";
import React, {useState, useEffect} from "react";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import AddRolesComponent from "commonComponent/Roles/AddRoles";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";

import {useNavigate, useSearchParams} from "react-router-dom";

const RoleUpdate = () => {
    const navigate = useNavigate();

    const [urlSearchParams] = useSearchParams();
    const id = urlSearchParams.get("roleID");

    const [positions, setPositions] = useState({});
    const [modules, setModules] = useState([]);
    const [roleData, setRoleData] = useState({});
    const dispatch = useDispatch();

    const keyarr = ["read_permission", "create_permission", "update_permission", "delete_permission"];
    const crudStatus = (add, read, edit, del) => ({
        [keyarr[0]]: read,
        [keyarr[1]]: add,
        [keyarr[2]]: edit,
        [keyarr[3]]: del
    });

    const handleRoleDetail = (value, mod) => {
        const createPermission = (modules) => {
            const permission = {};
            modules.forEach((element) => {
                permission[element] = crudStatus(false, false, false, false);
            });

            return permission;
        };
        const generatePermission = () => {
            const tempObj = {};
            value?.role_modules?.map((el) => {
                tempObj[el?.module?.module_name] = {
                    read_permission: el?.read_permission,

                    create_permission: el?.create_permission,
                    update_permission: el?.update_permission,
                    delete_permission: el?.delete_permission
                };
                return null;
            });
            return {...createPermission(mod), ...tempObj};
        };

        const obj = {
            role: value.role_name,
            description: value.description,
            modules: mod,
            checkAtleastOneViewSelected: -1,
            permission: generatePermission()
        };
        setRoleData(obj);
    };

    const handleDetail = async (mod) => {
        const instance = NetworkManager(API.ROLES.DETAIL(id));
        const response = await instance.request();
        if (response.success) {
            handleRoleDetail(response?.data?.rows[0], mod);
        }
    };

    const handleAddRole = async (value) => {
        const payload = {
            role_name: value.role,
            description: value.description,
            is_active: true,
            permissions: value.modules
                ?.map((el) => ({
                    ...value.permission[el],
                    module_id: positions[el]
                }))
                .filter((el) => el?.read_permission)
        };
        const instance = id ? NetworkManager(API.ROLES.UPDATE(id)) : NetworkManager(API.ROLES.CREATE);
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        navigate(-1);
    };
    function generatePositionObj(arr = []) {
        const positionsObj = {};
        arr.map((el) => {
            positionsObj[el?.module_name] = el?.module_id;
            return null;
        });
        return positionsObj;
    }
    const getModuleList = async () => {
        const instance = NetworkManager(API.ROLES.MODULES);

        const response = await instance.request();
        if (response.success) {
            setPositions(generatePositionObj(response.data));
            setModules(response.data.map((item) => item?.module_name));
            if (id) {
                handleDetail(response.data.map((item) => item?.module_name));
            }
        }
    };

    useEffect(() => {
        getModuleList();
    }, []);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <PageHeader
                        title="Roles"
                        breadsCrumData={[
                            {label: "Settings", href: "/settings?tab=roles"},
                            {label: "Roles", isActive: true}
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AddRolesComponent setDefaultData={roleData} modules={modules} handleSave={handleAddRole} groupedPermission />
                </Grid>
            </Grid>
        </>
    );
};

export default RoleUpdate;
