import { Box, Grid } from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import { ErrorMessage, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as yup from "yup";

const defaultInitialValues = {
    name: "",
    templateId: null,

};

const CustomerForm = (props) => {
    const { handleClose, children, Parentformik, customerName, customerId } = props;
    const [initialValues, setInitialValues] = useState(defaultInitialValues)

    const handleSubmit = (values) => {
        Parentformik.setFieldValue('headerData.template', values)
        handleClose()
    }


    useEffect(() => {
        if (Parentformik?.values?.headerData?.template) {
            const { template } = Parentformik?.values?.headerData
            setInitialValues({
                name: template.name,
                templateId: template?.templateId
            });
        }
    }, [Parentformik])


    const stopPropagationForTab = (event) => {
        if (event.key === "Tab") {
            event.stopPropagation();
        }
    };

    return (
        <>
            <Box p={2} sx={{ maxWidth: 400 }}
            >
                <Formik enableReinitialize initialValues={initialValues}
                    validationSchema={yup.object({
                        // name: yup.string().required("Template name is required").nullable(),

                    })}
                    onSubmit={(val, { setSubmitting }) => handleSubmit(val)}>
                    {(formik) => (

                        <>
                            <Grid container rowSpacing={2}  >
                                <Grid item xs={12}>
                                    {/* <CustomTextField  fullWidth label="Customer name" {...formik.getFieldProps("customerName")} /> */}
                                    <CustomTextField
                                        fullWidth
                                        label="Template name"
                                        sx={{ minWidth: '350px' }}
                                        id="template_id"
                                        onKeyDown={stopPropagationForTab}
                                        inputProps={{maxLength: 250}}
                                        value={formik.values.name}
                                        {...formik.getFieldProps("name")}
                                        onChange={(e) => {
                                            formik.setFieldValue("name", e.target.value);
                                            const temp = {
                                                name: e.target.value,
                                                templateId: formik.values.templateId,

                                            };
                                            Parentformik.setFieldValue('headerData.template', temp)
                                        }}
                                    />
                                    <ErrorMessage name="name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                </Grid>
                            </Grid>
                            {/* 
                            <Box mt={1}>
                                <ButtonGrouping
                                    btnprops={[
                                        {
                                            btnTitle: "Cancel",
                                            sx: (t) => ({ color: "#f0475c" }),
                                            onClick: handleClose
                                        },
                                        {
                                            btnTitle: formik.isSubmitting ? "Sending" : "Save",
                                            disabled: formik.isSubmitting,
                                            variant: "contained",
                                            onClick: () => {
                                                formik.handleSubmit();

                                                setTimeout(() => {
                                                    formik.resetForm();
                                                }, 25000);
                                            },
                                            sx: (t) => ({ color: t.palette.background.paper })
                                        }
                                    ]}
                                />
                            </Box> */}
                        </>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default CustomerForm;
