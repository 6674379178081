import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// action - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { useUserSession } from "hooks/userSession";
import { NetworkManager, API } from "network/core";
import { openSnackbar } from 'store/slices/snackbar';
import {store,dispatch} from 'store/index'
import { useSelector } from 'react-redux';
import StorageManager from 'storage/StorageManager';


// const
const initialState = {
    isLoggedIn: false,
    isInitialized: true,
    user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext();

export const FirebaseProvider = ({ children }) => {
    // const [state, dispatch] = useReducer(accountReducer,initialState);
    // let state = store.getState()
    let state = useSelector((state) => state.account);
    const userSession = useUserSession()


    useEffect(
        () => {
            if (userSession.isValidSession()) {
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: {
                            id: '1234',
                            email: '2344',
                            name: 'John Doe'
                        }
                    }
                });
                
            } else {
                dispatch({
                    type: LOGOUT
                });
            }
            state =store.getState()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    // useEffect(()=>{
    //     state = store.getState()

    // },[store])

    function getCookie(name){
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts?.length === 2) return parts.pop().split(';').shift();
        return null
    }

    const logout = async () => {
        const instance = NetworkManager(API.USER.LOGOUT)
        const deviceToken = getCookie('Device-Token')
        const response = await instance.request({}, {"deviceToken":encodeURIComponent(deviceToken)})
        if (response.success) {
            userSession.deleteSession()
            StorageManager.clearStore()
            dispatch({
                type: LOGOUT
            });
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'You have successfully logged out of your account.',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        }
    }

    const login = async () => {

        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user: {
                    id: '1234',
                    email: '2344',
                    name: 'John Doe'
                }
            }
        });
        dispatch(
            openSnackbar({
                open: true,
                message: 'Welcome! You have successfully signed into your account.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
    }

    const resetPassword = async (payload) => {
        // await firebase.auth().sendPasswordResetEmail(email); 
        const instance = NetworkManager(API.AUTH.RESETPASSWORD)
        const response = await instance.request(payload)
        return response
    };

    const forgotPassword = async (email) => {
        // await firebase.auth().sendPasswordResetEmail(email); 
        const instance = NetworkManager(API.AUTH.FORGOTPASSWORD)
        const response = await instance.request({email:email})
        return response
        // if (response.success) {
        //     userSession.deleteSession()
        //     dispatch({
        //         type: LOGOUT
        //     });
    };

    const updateProfile = () => { };
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                dispatch,
                login,
                logout,
                resetPassword,
                forgotPassword,
                updateProfile
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
