import {useState} from "react";
import {usePromotionModel} from "./promotion.model";
import {sortFunction} from "utils/helperFunctions/helpers";

export const usePromotionController = () => {
    const [marketList, setMarketList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const model = usePromotionModel();
    const staticParams = {
        page: 1,
        pageSize: 1000,
        sortBy: "createdAt",
        orderBy: "desc"
    };
    const getMarketList = async () => {
        const response = await model.fetchMarketList(staticParams);
        const tempData = [];
        if (response?.success) {
            response?.data?.marketList.forEach((el) => {
                tempData.push({id: el?.market_id, label: el?.market_name});
            });
        }
        setMarketList(sortFunction(tempData));
    };
    const getBrandList = async () => {
        const response = await model.fetchBrandList(staticParams);
        const tempData = [];
        if (response?.success) {
            response?.data?.brandList.forEach((el) => {
                tempData.push({id: el?.brand_id, label: el?.brand_name});
            });
        }
        setBrandList(sortFunction(tempData));
    };
    const filterState = [
        // {
        //     key: "discount_code",
        //     label: "Promotion",
        //     type: "textfield"
        // },
        {
            key: "service_name",
            label: "Service",
            type: "multiselect",
            getData: () => [
                {id: "CATERING", label: "Catering"},
                {id: "HOME_MEALS", label: "Home meals"}
            ]
        },
        {
            key: "market_ids",
            label: "Market",
            type: "multiselect",
            getData: marketList
        },
        {
            key: "brand_ids",
            label: "Brand",
            type: "multiselect",
            getData: brandList
        },
        {
            key: "description",
            label: "Description",
            type: "textfield"
        },
        {
            type:'dateRange',
            key1:'start_date',
            key2:'end_date',
            startLabel:"Start date",
            endLabel:"End date"
            
        },
        {
            key: "is_active",
            label: "Status",
            type: "select",
            getData: () => [
                {id: "active", label: "Active"},
                {id: "inactive", label: "Inactive"}
            ]
        }
    ];
    return {
        getMarketList,
        getBrandList,
        filterState
    };
};
