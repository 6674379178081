import * as React from "react";
import {styled} from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {width} from "@mui/system";

const AntTabs = styled(Tabs)(({theme}) => ({
    marginLeft: "-1.5rem",
    width: "105%",
    "& .MuiTabs-flexContainer": {
        marginLeft: "1.5rem"
    },
    "& .MuiTabs-indicator": {
        height: "4px",
        backgroundColor: theme.palette.primary.main
    }
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({theme}) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
        minWidth: 0
    },
    fontWeight: 400,
    fontSize: "0.875rem",
    padding: "12px 10px",
    marginRight: theme.spacing(1),
    letterSpacing: "-0.7px",
    color: "#000000",

    "&:hover": {
        color: "theme.palette.primary.main",
        opacity: 1
    },
    "&.Mui-selected": {
        color: "#000000",
        fontWeight: 700,
        fontSize: "0.875rem"
    },
    "&.Mui-focusVisible": {
        backgroundColor: "#d1eaff"
    }
}));

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{padding: "8px 0px 16px 0px"}}>{children}</Box>}
        </div>
    );
}

export default function CustomizedTabs({tabsInfo, value, handleChange,tabShow=true}) {
    // console.log("tabsInfo : ",tabsInfo)
    return (
        <Box sx={{width: "100%"}}>
            <Box sx={{bgcolor: "#fff"}}>
                <AntTabs
                    variant="scrollable"
                    sx={{minHeight: "25px",height:"30px"}}
                    scrollButtons={false}
                    value={value}
                    onChange={handleChange}
                    aria-label="ant example"
                >
                    {tabsInfo.map(
                        (obj, index) => obj && <AntTab sx={{minHeight: "25px",height:"30px"}} key={index} label={obj.label} value={obj.value} />
                    )}
                </AntTabs>

                {tabShow && tabsInfo.map(
                    (obj, index) =>
                        obj && (
                            <TabPanel key={index} value={value} index={obj.value}>
                                {obj?.comp && obj.comp()}
                            </TabPanel>
                        )
                )}
            </Box>
        </Box>
    );
}
