import React from "react";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import _debounce from "lodash/debounce";
import PropTypes from "prop-types";
import CustomSearchableSelect from "commonComponent/Form/Fields/CustomAutoComplete";
import {getformateDate, getDayjsFormat} from "utils/app-dates/dates";
import FilterDatePicker from "commonComponent/Form/Fields/FilterDatePicker";
import CustomTextDropDown from "commonComponent/Form/Fields/CustomTextDropDown";
import DateRange2 from "commonComponent/DateRange2";
import {DateRange} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {
    setChildFilterChips,
    setChildPageNumber,
    setFilterChips,
    setNestFilterChips,
    setNestPageNumber,
    setPageNumber
} from "store/slices/paginationSlice";

const SelectType = (props) => {
    const {item, setFilterState, filterChips, setSearchedValue, value, isChild = false, isNest = false} = props;
    const dispatch = useDispatch();
    const handleMultiSelect = (e, menuItems) => {
        let alreadyPresent = false;
        const mappedValues = e.map((item) => item?.id);
        let mappedSet;
        if (e[0]?.id === "selectAll") {
            const menuItemIdMap = menuItems.map((item) => item?.id);
            if (filterChips[item?.key]) {
                const concatArray = menuItemIdMap.concat(filterChips[item?.key]);
                mappedSet = new Set(concatArray);
            } else {
                mappedSet = new Set(menuItemIdMap);
            }
        } else if (filterChips[item?.key]) {
            const presentArray = filterChips[item?.key];
            const concatArray = mappedValues.concat(presentArray);
            const newMapSet = new Set(mappedValues);
            const idSet = new Set(concatArray);
            presentArray.forEach((item) => {
                if (newMapSet.has(item)) {
                    alreadyPresent = true;
                }
            });
            mappedSet = idSet;
        } else {
            mappedSet = new Set(mappedValues);
        }
        if (!alreadyPresent) {
            // const prev=
            const prev = {...filterChips, [item?.key]: Array.from(mappedSet)};
            if (isNest) {
                dispatch(setNestPageNumber(1));
                dispatch(setNestFilterChips(prev));
            } else if (isChild) {
                dispatch(setChildPageNumber(1));
                dispatch(setChildFilterChips(prev));
            } else {
                dispatch(setPageNumber(1));
                dispatch(setFilterChips(prev));
            }
        }
    };
    const handleMultipleSelect = (e, menuItems) => {
        if (item?.type === "multiselect") {
            handleMultiSelect(e, menuItems);
        } else {
            const prev = {
                ...filterChips,
                [item?.key]: item?.key === "customer_name" || item?.key === "company_name" || item?.key === "email" ? e?.label : e?.id
            };
            if (isNest) {
                dispatch(setNestPageNumber(1));
                dispatch(setNestFilterChips(prev));
            } else if (isChild) {
                dispatch(setChildPageNumber(1));
                dispatch(setChildFilterChips(prev));
            } else {
                dispatch(setPageNumber(1));
                dispatch(setFilterChips(prev));
            }
        }
    };
    if (item?.type === "dateRange" || item?.type === "rangePicker") {
        return (
            <DateRange2
                startDatePlaceholderText={item?.startLabel}
                endDatePlaceholderText={item?.endLabel}
                value={[filterChips?.[item?.key1], filterChips?.[item?.key2]]}
                onChange={(values) => {
                    const prev = {
                        ...filterChips,
                        [item?.key1]: getDayjsFormat(values[0]),
                        [item?.key2]: getDayjsFormat(values[1])
                    };
                    if (isNest) {
                        dispatch(setNestPageNumber(1));
                        dispatch(setNestFilterChips(prev));
                    } else if (isChild) {
                        dispatch(setChildPageNumber(1));
                        dispatch(setChildFilterChips(prev));
                    } else {
                        dispatch(setPageNumber(1));
                        dispatch(setFilterChips(prev));
                    }
                    setFilterState((pre) => ({...pre, [item?.key1]: getDayjsFormat(values[0]), [item?.key2]: getDayjsFormat(values[1])}));
                }}
            />
        );
    } else if (item?.type === "createdAt" || item?.type === "startDate") {
        return (
            <FilterDatePicker
                label={item?.label}
                minDate={filterChips?.[item?.minDate] || null}
                valueBasic={[filterChips?.[item?.key1], filterChips?.[item?.key2]]}
                setValueBasic={(val) => {
                    const prev = {
                        ...filterChips,
                        [item?.key]: getDayjsFormat(val),
                        isCreatedAt: item?.type === "createdAt"
                    };
                    if (isNest) {
                        dispatch(setNestPageNumber(1));
                        dispatch(setNestFilterChips(prev));
                    } else if (isChild) {
                        dispatch(setChildPageNumber(1));
                        dispatch(setChildFilterChips(prev));
                    } else {
                        dispatch(setPageNumber(1));
                        dispatch(setFilterChips(prev));
                    }
                }}
            />
        );
    } else if (item?.type === "multiselect" || item?.type === "select") {
        const menuItems = typeof item?.getData === "function" ? item?.getData() : item?.getData;
        return (
            <CustomSearchableSelect
                multiple={item?.type === "multiselect"}
                menuItems={menuItems?.length === 0 ? [] : menuItems}
                height={item?.type === "multiselect" ? "14px" : "15px"}
                label={item?.label}
                value={value}
                selectedValues={filterChips?.[item?.key]}
                mustAddCssClass={item?.type === "multiselect" ? "searchable-multiselect" : "searchable-select"}
                handleChange={(e) => {
                    handleMultipleSelect(e, menuItems);
                }}
                handleInputChange={(e) => {
                    if (
                        item?.label === "Customer" ||
                        item?.label === "Customer name" ||
                        item?.label === "Company name" ||
                        item?.label === "Email" ||
                        item?.label === "Account manager"
                    ) {
                        let key;
                        switch (item?.label) {
                            case "Customer name":
                                key = "customerName";
                                break;
                            case "Company name":
                                key = "companyName";
                                break;
                            case "Email":
                                key = "email";
                                break;
                            case "Account manager":
                                key = "search";
                                break;
                            default:
                                key = "customerName";
                        }
                        setSearchedValue((pre) => ({...pre, [key]: e}));
                    }
                }}
                freeSolo={item?.freeSolo}
            />
        );
    } else if (item?.type === "numberfield" || item?.type === "numberfieldWithDecimal") {
        return (
            <CustomTextDropDown
                handleChange={(keyValue, filterValue) => {
                    if (keyValue === "") {
                        const filterCopy = {...filterChips};
                        delete filterCopy[item?.key];
                        delete filterCopy[item?.filterKey];
                        if (isNest) {
                            dispatch(setNestPageNumber(1));
                            dispatch(setNestFilterChips(filterCopy));
                        } else if (isChild) {
                            dispatch(setChildPageNumber(1));
                            dispatch(setChildFilterChips(filterCopy));
                        } else {
                            dispatch(setPageNumber(1));
                            dispatch(setFilterChips(filterCopy));
                        }
                    } else {
                        const prev = {
                            ...filterChips,
                            [item?.key]: keyValue
                        };
                        if (isNest) {
                            dispatch(setNestPageNumber(1));
                            dispatch(setNestFilterChips(prev));
                        } else if (isChild) {
                            dispatch(setChildPageNumber(1));
                            dispatch(setChildFilterChips(prev));
                        } else {
                            dispatch(setPageNumber(1));
                            dispatch(setFilterChips(prev));
                        }
                        if (filterValue) {
                            const newPrev = {
                                ...filterChips,
                                [item?.filterKey]: filterValue
                            };
                            if (isNest) {
                                dispatch(setNestPageNumber(1));
                                dispatch(setNestFilterChips(newPrev));
                            } else if (isChild) {
                                dispatch(setChildPageNumber(1));
                                dispatch(setChildFilterChips(newPrev));
                            } else {
                                dispatch(setPageNumber(1));
                                dispatch(setFilterChips(newPrev));
                            }
                        }
                    }
                }}
                key={item?.filterKey}
                label={item?.label}
                ids={item?.filterKey}
                menuItems={item?.getData}
            />
        );
    } else {
        switch (item.type) {
            case "emailfield": {
                return (
                    <CustomTextField
                        fullWidth
                        label={item?.label}
                        type="email"
                        height="32px"
                        onChange={_debounce((e) => {
                            const prev = {
                                ...filterChips,
                                [item?.key]: e.target.value
                            };
                            if (e.target.value?.length === 0) {
                                delete prev[item?.key];
                            }
                            if (isNest) {
                                dispatch(setNestPageNumber(1));
                                dispatch(setNestFilterChips(prev));
                            } else if (isChild) {
                                dispatch(setChildPageNumber(1));
                                dispatch(setChildFilterChips(prev));
                            } else {
                                dispatch(setPageNumber(1));
                                dispatch(setFilterChips(prev));
                            }
                        }, 800)}
                    />
                );
            }
            case "nameTextfield": {
                return (
                    <CustomTextField
                        fullWidth
                        label={item?.label}
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                        }}
                        height="32px"
                        onChange={_debounce((e) => {
                            const prev = {
                                ...filterChips,
                                [item?.key]: e.target.value
                            };
                            if (e.target.value?.length === 0) {
                                delete prev[item?.key];
                            }
                            if (isNest) {
                                dispatch(setNestPageNumber(1));
                                dispatch(setNestFilterChips(prev));
                            } else if (isChild) {
                                dispatch(setChildPageNumber(1));
                                dispatch(setChildFilterChips(prev));
                            } else {
                                dispatch(setPageNumber(1));
                                dispatch(setFilterChips(prev));
                            }
                        }, 800)}
                    />
                );
            }
            case "endDate": {
                return (
                    <FilterDatePicker
                        {...(filterChips?.start_date && {
                            minDate: getDayjsFormat(filterChips?.start_date)
                        })}
                        {...(item?.minDate && {minDate: getDayjsFormat(filterChips?.[item?.minDate])})}
                        label={item?.label}
                        valueBasic={filterChips?.[item?.key] || null}
                        setValueBasic={(val) => {
                            const prev = {
                                ...filterChips,
                                [item?.key]: getDayjsFormat(val),
                                isCreatedAt: false
                            };
                            if (isNest) {
                                dispatch(setNestPageNumber(1));
                                dispatch(setNestFilterChips(prev));
                            } else if (isChild) {
                                dispatch(setChildPageNumber(1));
                                dispatch(setChildFilterChips(prev));
                            } else {
                                dispatch(setPageNumber(1));
                                dispatch(setFilterChips(prev));
                            }
                        }}
                    />
                );
            }
            default: {
                return (
                    <CustomTextField
                        fullWidth
                        placeholder={item?.label}
                        height="32px"
                        onChange={_debounce((e) => {
                            const prev = {
                                ...filterChips,
                                [item?.key]: e.target.value
                            };

                            if (e.target.value?.length === 0) {
                                delete prev[item?.key];
                            }
                            console.log(item?.key, 310);
                            if (isNest) {
                                dispatch(setNestPageNumber(1));
                                dispatch(setNestFilterChips(prev));
                            } else if (isChild) {
                                dispatch(setChildPageNumber(1));
                                dispatch(setChildFilterChips(prev));
                            } else {
                                dispatch(setPageNumber(1));
                                dispatch(setFilterChips(prev));
                            }
                        }, 800)}
                    />
                );
            }
        }
    }
};

export default SelectType;
SelectType.propTypes = {
    item: PropTypes.object,
    setFilterState: PropTypes.func,
    filterChips: PropTypes.object,
    setSearchedValue: PropTypes.func
};
