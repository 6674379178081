import {Grid, IconButton, Typography} from "@mui/material";
import React from "react";

const CommonHeader = (props) => {
    const {title, btnText, onClick = () => {}} = props;
    return (
        <>
            <Grid item xs={12} container justifyContent="space-between" alignItems="center" sx={{mb: "0.687rem"}}>
                <Grid item>
                    <Typography variant="cardTitle" fontSize="1.25em">
                        {title}
                    </Typography>
                </Grid>
                {btnText && (
                    <Grid item alignSelf="flex-start">
                        <IconButton onClick={onClick}>
                            <Typography variant="h5" sx={(t) => ({color: t.palette.primary.main})}>
                                {btnText}
                            </Typography>
                        </IconButton>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default CommonHeader;
