import {Grid} from "@mui/material";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {NetworkManager, API} from "network/core";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {useNavigate, useSearchParams, useParams} from "react-router-dom";
import FilterChips from "commonComponent/FilterChipsComponent";
import {generateUrl} from "utils/helperFunctions/helpers";
import useAuth from "hooks/useAuth";
import {customers} from "../../../Tabs/data";
import useTempDrawer from "hooks/useTempDrawer";
import AddNewNotes from "../../../CreateCustomer/AddNewNoteForm";
import Filters from "commonComponent/Filters";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {setChildPageNumber, setChildRowsPerPage} from "store/slices/paginationSlice";

const Notes = (props) => {
    // console.log("props : ", props);
    const {toggleDrawer} = useTempDrawer();
    const {
        search,
        showFilter,
        handleEditOrder,
        setSelectedCheckbox,
        setShowFilter = () => {},
        updateListCount,
        selectedCheckbox,
        filterChips,
        setFilterValue,
        setSearchedValue
    } = props;
    const [searchParams] = useSearchParams();
    const OrderId = searchParams.get("orderId");
    const {orderUpdate} = useAuth();

    const [open, setOpen] = React.useState(false);
    const [notes, setNotes] = React.useState([]);
    // const [customerData, setCustomerData] = useState(customers);
    const currentPage = useSelector((state) => state?.pagination?.childCurrentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.childRowsPerPage);

    const [orderBy, setOrderBy] = useState("createdAt");
    const [order, setOrder] = useState("desc");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const formSchema = {
        note: ""
    };

    const [initialValues, setInitialValues] = useState(formSchema);

    const navigation = useNavigate();
    const dispatch = useDispatch();
    // const [title, setTitle] = useState("Create note");

    const prm = useParams();
    const NotesId = prm?.type;

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };
    React.useEffect(() => {
        if (OrderId) {
            handleEditOrder(OrderId);
        }
    }, [OrderId]);

    let apiEndpoint;

    const isEditing = selectedRow?.customer_note_id;

    const handleDuplicate = async (values) => {
        const generateCopyName = (name) => {
            const copyPattern = /copy(\d+)$/i;
            const match = name.match(copyPattern);

            if (match) {
                // If there's already a copy suffix, increment the number
                const copyNumber = parseInt(match[1], 10) + 1;
                return name.replace(copyPattern, `Copy${copyNumber}`);
            } else {
                // If there's no copy suffix, append "copy1"
                return `${name} Copy1`;
            }
        };

        const duplicatedNote = generateCopyName(values);

        const payload = {
            note: duplicatedNote
        };

        apiEndpoint = API.CUSTOMER.CREATENOTES(NotesId);

        const instance = NetworkManager(apiEndpoint);
        const res = await instance.request(payload);
        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
            handleIsEdited();
            setSelectedRow(null);
            handleSelectedRow(null);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
        setInitialValues(formSchema);
        setSelectedRow(null);
        handleSelectedRow(null);
    };

    const getNotesList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.CUSTOMER.GETNOTES(`${NotesId}`));
        const params = {
            page: currentPage,
            pageSize: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };
        if (orderBy) {
            params.sortBy = orderBy;
            params.orderBy = order;
        }

        if (search === "") {
            params.page = currentPage;
        }
        if (search !== "") {
            params.search = search;
            setNotes([]);
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);

        if (response.success) {
            setNotes(response?.data?.note);
            setTotalItems(response?.data?.pagination?.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleStatus = async (values, type) => {};

    const handleActionBtn = (type, row, handleClose) => (e) => {
        // console.log("ROEW : ", row);
        setShowFilter(false);
        handleSelectedRow(row);
        handleClose();

        switch (type) {
            case "Edit": {
                toggleDrawer("right", "addNewNotes")(e);
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            case "Duplicate": {
                handleDuplicate(row.note);

                console.log("Duplicate Inside");
                // setNotes((prevNotes) => [...prevNotes, newRow]); // Assuming setNotes updates the state
                break;
            }
            default:
                handleStatus(row, type);
                break;
        }
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.CUSTOMER.NOTESBULKDELETE);
        const response = await instance.request({customerNoteIds: selectedRow.customer_note_id});
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getNotesList();
    };

    const handlePageChange = (e, number) => {
        dispatch(setChildPageNumber(number + 1));
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        dispatch(setChildRowsPerPage(number));
        dispatch(setChildPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        setOrder(val1);
        setOrderBy(val2);
    };

    useEffect(() => {
        if (search) {
            dispatch(setChildPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        getNotesList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, order, orderBy, isEdited, filterChips, orderUpdate, updateListCount]);

    return (
        <>
            {/* {console.log("Notes")} */}
            <AddNewNotes
                userID={NotesId}
                selectedData={selectedRow}
                handleIsEdited={handleIsEdited}
                handleSelectedRow={handleSelectedRow}
                setShowFilter={setShowFilter}
            />

            <Grid container>
                <Grid item xs={12}>
                    {/* <FilterChips filterChips={filterChips} setFilterChips={setFilterChips} /> */}
                    <CommonTable
                        withCheckbox
                        loading={loading}
                        data={notes}
                        checkbox
                        header={[
                            {key: "note", label: "Note"},
                            {key: "creator.first_name", label: "Created by"},
                            {key: "createdAt", label: "Created"},
                            {key: "action", label: "Action"}
                        ]}
                        // actions={["Edit", "Delete"]}
                        actions={[
                            WithPermission({module: "Customers", permission: "update_permission"}) && "Edit",
                            WithPermission({module: "Customers", permission: "create_permission"}) && "Duplicate",
                            (WithPermission({module: "Promotions", permission: "delete_permission"}) ||
                                WithPermission({module: "Customers", permission: "delete_permission"})) &&
                                "Delete"
                        ]}
                        handleActionBtn={handleActionBtn}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        sortingLabel={["Created"]}
                        currentPage={currentPage - 1}
                        setSelectedCheckbox={setSelectedCheckbox}
                        selectedCheckbox={selectedCheckbox}
                    />
                </Grid>
            </Grid>

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this note?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
        </>
    );
};

export default Notes;
