import React from "react";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import {Box, Grid, ImageList, ImageListItem, Typography} from "@mui/material";
import Loader from "ui-component/Loader";
import {MainHeading, SubContainer} from "../../../locations/createLocations/CreateLocationForm";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import {useNavigate, useParams} from "react-router-dom";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "store";
import {formatDate} from "commonComponent/Apptable/dateUtils";
import { ellipsizeText } from "commonComponent/Apptable/textUtils";
import {convertDaysToHHMM, getFormattedTimeinAMPM, getHHMMTime, getformateDate,} from "utils/app-dates/dates";

const dietaryMap = {
    VEGETARIAN: "V ",
    VEGAN: "VG ",
    "GLUTEN-FREE": "GF ",
    "DAIRY-FREE": "DF ",
    SPICY: "S "
};

const dietarySequence=['VEGETARIAN','VEGAN',"GLUTEN-FREE","DAIRY-FREE",'SPICY']

const sortAndMapDietary = (arr1) => {
    // Create a copy of arr1 to avoid modifying the original array
    const sortedArr = [...arr1];
  
    // Sort the array based on the index in dietarySequence
    sortedArr.sort((a, b) => dietarySequence.indexOf(a.dietary) - dietarySequence.indexOf(b.dietary));
  
    // Map each value using dietaryMap
    const mappedArr = sortedArr.map(obj => dietaryMap[obj.dietary]);
  
    // Return the resulting array as in the original format
    return mappedArr;
  };

  const sortDaysOfWeek = (daysArray) => {
    // Define the order of weekdays
    const weekdaysOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  
    // Custom sorting function based on weekdays order
    const customSort = (a, b) => {
      return weekdaysOrder.indexOf(a) - weekdaysOrder.indexOf(b);
    };
  
    // Sort the array using the custom sorting function
    const sortedDays = daysArray.sort(customSort);
  
    return sortedDays;
  };




const MenuViewPage = () => {
    const [menuData, setMenudata] = React.useState(null);
    const [loading, setLoading] = React.useState(null);
    const navigation = useNavigate();
    const prm = useParams();
    const {id, menuid} = prm;

    const TableCellStyle = () => ({
        p: 0,
        pl: 1,
        pb: 2,
        border: "none",
        verticalAlign: "Top",
        color: "#25272B",
        fontWeight: 500,
        fontSize: "14px"
    });

    const getMenuDataById = async (id) => {
        setLoading(true);
        const instance = NetworkManager(API.MENU.GETBYID(id));
        const response = await instance.request();

        const newSchema = {
            selectedMenu: "",
            menuName: "",
            cuisine: "",
            category: [],
            scheduleName: "",
            image: [],
            locations: [],
            frequency: ["Daily"],
            startDate: "",
            endDate: "",
            noEndDate: false,
            repeatEvery: 1,
            recurring: "Day",
            recurringValues: [],
            ends: "never",
            end_value_on: null,
            end_value_after: "",
            menuDetails: {},
            schedule: {}
        };

        if (response.success) {
            const data = response.data;
            newSchema.menuDetails.menuName = data.menuList.menu_name;
            newSchema.menuDetails.cuisine = data.menuList.cusine;
            newSchema.menuDetails.image = data.menuList.image_url;
            if (data.menuList.cut_off_hour) {
                newSchema.menuDetails.cut_off_hour = data.menuList.cut_off_hour;
            }
            // newSchema.schedule["Schedule name"] = data.menuList?.schedule_name ?? "";
            // newSchema.schedule.Frequency = data.menuList?.frequency ?? "";
            newSchema.noEndDate = data.menuList.no_end_date;
            if (data.menuList?.kitchen_location_name?.length > 0) {
                newSchema.schedule.Locations = data.menuList.kitchen_location_name;
            }

            if (data.menuList.start_on_date) {
                newSchema.schedule["Start date"] = {date: data.menuList.start_on_date, isDate: true};
            }
            if (data.menuList.end_on_date) {
                newSchema.schedule["End date"] = {date: data.menuList.end_on_date, isDate: true};
            }
            // if (data.menuList.repeat_every) {
            //     newSchema.schedule['Repeat every'] = data.menuList.repeat_every;
            // }

            // if (data.menuList.repeat_duration !== null) {
            //     newSchema.schedule.Recurring = data.menuList.repeat_duration;
            // }
            // if (data.menuList.cut_off_hour) {
            //     newSchema.schedule["Cut off time"] = data.menuList.cut_off_hour;
            // }
            if (data.menuList?.menuScheduleRepeat) {
                newSchema.schedule["Available on"] =
                    data.menuList.menuScheduleRepeat.length > 0 ? data.menuList.menuScheduleRepeat.map((row) => row.day) : "";
            }
            // if (data.menuList.schedule_end_on === "Date") {
            //     newSchema.ends = "on";
            //     newSchema.schedule["Ends on"] = {date: data.menuList.end_on_date, isDate: true};
            // }
            else if (data.menuList.schedule_end_on === "Occurance") {
                newSchema.ends = "after";
                newSchema.schedule.Occurance = data.menuList.end_on_occurance;
            } else {
                newSchema.ends = "never";
            }

            // get menu categories
            const instance1 = NetworkManager(API.MENU.GETCATEGORYBYID(id));
            const response1 = await instance1.request();
            if (response1.success) {
                const menuCategory = response1.data?.menuList;
                if (menuCategory?.length > 0) {
                    const tempObj = [];
                    menuCategory.sort((a, b) => a.order_by - b.order_by);
                    menuCategory.forEach((row) => {
                        const obj = {};
                        obj.label = row.category.category_name;
                        obj.value = row.category.category_name;
                        obj.order_by = row.order_by;
                        obj.description = row.category.description;
                        obj.id = row.category_id;
                        obj.item = [];

                        row.menuCategoryItem.sort((a, b) => a.order_by - b.order_by);

                        row.menuCategoryItem
                            .filter((row) => row.item)
                            .forEach((col) => {
                                const tempItem = {};
                                tempItem.item_id = col.item_id;
                                tempItem.order_by = col.order_by;
                                tempItem.price = col.item.price;
                                tempItem.item_name = col.item.item_name;
                                tempItem.description = col.item.description;
                                tempItem.item_dietary = col.item.itemDietary;
                                obj.item.push(tempItem);
                            });
                        tempObj.push(obj);
                    });
                    newSchema.category = tempObj;
                }
                setMenudata(newSchema);
            }
        } else {
            // dispatch(
            //     openSnackbar({
            //         open: true,
            //         message: response.message,
            //         variant: "alert",
            //         alert: {
            //             color: "error"
            //         },
            //         transition: "Fade",
            //         close: true
            //     })
            // );
        }
        setLoading(false);
    };

    React.useEffect(() => {
        if (menuid) {
            getMenuDataById(menuid);
        }
    }, []);

    const mapNamingForID = (type, val) => {
        switch (type) {
            // case "Market": {
            //     const market = LDC.marketList.filter((el) => el.market_id === val);
            //     return market[0]?.market_name;
            // }
            // case "brands": {
            //     const brands = LDC.brandList.filter((el) => el.brand_id === val);
            //     return brands[0]?.brand_name;
            // }
            default: {
                return val;
            }
        }
    };

    return (
        <>
            {/* {loading && <Loader />} */}

            <Grid container>
                {menuData && (
                    <Grid item xs={12}>
                        <Box sx={{mb: 5}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid item lg={10} md={10} xs={12}>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={(t) => ({p: 2, border: "1px solid #dbdbdb", borderRadius: 1, height: "100%"})}
                                        >
                                            <Grid item container spacing={2} justifyContent="space-between" xs={12}>
                                                <Grid item xs={12} flexDirection="column">
                                                    <Grid item xs={11}>
                                                        <Typography sx={{fontSize: "1.125rem", fontWeight: 600}} color="#020A11">
                                                            Menu details
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={11} sx={{marginTop: "16px"}}>
                                                        <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                            Menu
                                                        </Typography>
                                                        <Typography variant="subtitle2" sx={{wordBreak:'break-all'}} fontSize="16px" fontWeight={400} color="#212121">
                                                            {menuData.menuDetails.menuName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={11} sx={{marginTop: "10px"}}>
                                                        <Typography variant="subtitle2" sx={{wordBreak:'break-all'}} fontSize="10px" color="#9e9e9e">
                                                            Cuisine
                                                        </Typography>
                                                        <Typography variant="subtitle2" fontSize="14px" fontWeight={400} color="#212121">
                                                            {ellipsizeText(menuData.menuDetails.cuisine,50)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={11} sx={{marginTop: "10px"}}>
                                                        <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                            Cut-off time
                                                        </Typography>
                                                        <Typography variant="subtitle2" fontSize="14px" fontWeight={400} color="#212121">
                                                            {menuData.menuDetails.cut_off_hour}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                {menuData?.menuDetails?.image && (
                                                    <Box sx={{width: 384, height: 166, marginLeft: "16px"}}>
                                                        {menuData?.menuDetails?.image?.map((item) => (
                                                            <img
                                                                src={`${item}?w=384&h=166&fit=crop&auto=format`}
                                                                srcSet={`${item}?w=384&h=166&fit=crop&auto=format&dpr=2 2x`}
                                                                alt="menuThumbnail"
                                                                loading="lazy"
                                                                style={{
                                                                    display: "block",
                                                                    maxWidth: "384px",
                                                                    maxHeight: "166px",
                                                                    width: "384px",
                                                                    height: "auto",
                                                                    objectFit: "cover"
                                                                }}
                                                            />
                                                        ))}
                                                    </Box>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item lg={10} md={10} xs={12}>
                                        <SubContainer>
                                            {!!menuData.category.length &&
                                                menuData.category.map((obj, index) => (
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography sx={{fontSize: "1.125rem", fontWeight: 600}} color="#020A11">
                                                                {obj.label}
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize="14px"
                                                                fontWeight={400}
                                                                fontStyle="italic"
                                                                sx={{wordBreak:'break-all'}}
                                                                color="#212121"
                                                            >
                                                                {obj.description}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            key={index}
                                                            sx={{paddingTop: "18px !important", paddingBottom: "32px !important"}}
                                                        >
                                                            <Grid container spacing={1}>
                                                                {obj.item.length > 0 &&
                                                                    obj.item.map((col, ind) => (
                                                                        <>
                                                                            <Grid key={ind + col.item_name} item xs={11}>
                                                                                <Typography
                                                                                    variant="checkBoxUnSelected"
                                                                                    fontSize="15px"
                                                                                    fontWeight={500}
                                                                                    color="#26272A"
                                                                                >
                                                                                    {col.item_name} &nbsp;&nbsp;{" "}
                                                                                    {sortAndMapDietary(col.item_dietary)?.map(
                                                                                        (item) => item
                                                                                    )}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={1} pr={0.7} sx={{textAlign: "right"}}>
                                                                                <Typography
                                                                                    variant="checkBoxUnSelected"
                                                                                    fontSize="15px"
                                                                                    fontWeight={500}
                                                                                    color="#25272B"
                                                                                >
                                                                                    ${parseFloat(col.price).toFixed(2)}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </>
                                                                    ))}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                        </SubContainer>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item lg={10} md={10} xs={12}>
                                        <SubContainer title="Schedule" fontWeight={600}>
                                            <Grid container spacing={2} mt={0.3}>
                                                {!!Object.entries(menuData.schedule).length &&
                                                    Object.entries(menuData.schedule).map((val, i) => (
                                                        <Grid key={i} item xs={3}>
                                                            <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                                {val[0]}
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize="14px"
                                                                fontWeight={500}
                                                                color="#212121"
                                                            >
                                                                {Array.isArray(val[1])
                                                                    ? sortDaysOfWeek(val[1])?.map((el, indx) => (
                                                                          <span key={indx}>{`${indx !== 0 ? ", " : ""}${el}`}</span>
                                                                      ))
                                                                    : val[1]?.isDate
                                                                    ? formatDate(val[1]?.date)
                                                                    : mapNamingForID(val[0], val[1])}
                                                            </Typography>
                                                        </Grid>
                                                    ))}
                                            </Grid>{" "}
                                        </SubContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default MenuViewPage;
