import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    cartOrderName: ""
};

const headerData = createSlice({
    name: "headerData",
    initialState,
    reducers: {
        setCartOrderName: (state, action) => {
            state.cartOrderName = action.payload;
            // console.log("SSSS : ", state, action);
        }
    }
});

export default headerData.reducer;

export const {setCartOrderName} = headerData.actions;
