import React from "react";

const DrawerContextModifier = React.createContext();

const DrawerContextModifierProvider = ({children}) => {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setState({...state, [anchor]: open});
    };
    return (
        <>
            <DrawerContextModifier.Provider value={{state, toggleDrawer}}>{children}</DrawerContextModifier.Provider>
        </>
    );
};

export {DrawerContextModifierProvider, DrawerContextModifier};
