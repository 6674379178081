import React from "react";
import { Grid, Typography, FormControlLabel, Checkbox, Box } from "@mui/material";
import { FieldArray } from "formik";
import { getformateDate, getformateDateUS } from "utils/app-dates/dates";
import Checked from 'assets/images/icons/lightCheckedBox.svg';
import Unchecked from 'assets/images/icons/unchecked.svg';

const HolidayClosures = (props) => {
    const { formik, readOnly } = props;
    const { values, setFieldValue } = formik;
    const { hoursOfOperation } = values;
    const { holidayClosures } = hoursOfOperation;

    return (
        <Grid item xs={12} container >
            <FieldArray
                name="delivery"
                render={() => (
                    <Box sx={{ pl:0.4,mt:1.5, height: "33.39em", overflowY: "auto", width: "100%" }}>
                        {!!holidayClosures.length &&
                            holidayClosures.map((obj, index) => (
                                <Grid key={index} item xs={12} spacing={2}>
                                    <FormControlLabel
                                        disabled={readOnly}
                                        checked={obj.isChecked}
                                        sx={{
                                            mb: 1.8
                                        }}
                                        control={<Checkbox disableRipple
                                            icon={<img src={Unchecked} style={{ width: '18px', height: '18px' }} alt="checked" />}
                                            checkedIcon={
                                                <img
                                                    src={Checked}
                                                    alt="checkbox"
                                                    style={{ width: '18px', height: '18px' }}
                                                />
                                            } />}
                                        label={
                                            <Typography variant={obj.isChecked ? "checkBoxSelected" : "checkBoxUnSelected"}>
                                                {`${obj?.summary} ${getformateDateUS(obj?.start?.date)}`}
                                            </Typography>
                                        }
                                        onChange={(e) => {
                                            setFieldValue(`hoursOfOperation.holidayClosures[${index}]`, {
                                                ...obj,
                                                isChecked: e.target.checked
                                            });
                                        }}
                                    />
                                </Grid>
                            ))}
                    </Box>
                )}
            />
        </Grid>
    );
};

export default HolidayClosures;
