import React from "react";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import Loader from "ui-component/Loader";
import {MainHeading, SubContainer} from "../createLocations/CreateLocationForm";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import useLocationDetailController from "./locationDetail.controller";
import {useNavigate, useSearchParams} from "react-router-dom";
import {convertDaysToHHMM, getFormattedTimeinAMPM, getHHMMTime, getformateDate, getformateDateUS} from "utils/app-dates/dates";
import {FormatCurrency} from "utils/helperFunctions/helpers";
import WithPermission from "commonComponent/RBAC/permissionHoc";

const LocationDetailPage = () => {
    const [searchParam] = useSearchParams();
    const mrkId = searchParam.get("marketId");
    const mrkName = searchParam.get("marketName");
    const LDC = useLocationDetailController();
    const navigation = useNavigate();
    const {initialVlaues} = LDC;

    const mappingLeadTime = {
        CurrentDay: "Current day",
        TheDayBefore: "One day before",
        TwoDaysBefore: "Two days before",
        ThreeDaysBefore: "Three days before",
        FourDaysBefore: "Four days before"
    };

    const data = [];

    if (mrkId) {
        data.push({label: "Market", href: "/markets"});
    }
    data.push({label: "Locations", href: mrkId && mrkName ? `/marketLocations/${mrkId}?marketName=${mrkName}` : "/locations"});
    data.push({
        label: "View location",
        isActive: true
    });

    const mapNamingForID = (type, val) => {
        switch (type) {
            case "Market": {
                const market = LDC.marketList.filter((el) => el.market_id === val);
                return market[0]?.market_name;
            }
            case "brands": {
                const brands = LDC.brandList.filter((el) => el.brand_id === val);
                return brands[0]?.brand_name;
            }
            default: {
                return val;
            }
        }
    };

    const TableCellStyle = () => ({
        p: 0,
        pl: 1,
        pb: 2,
        border: "none",
        verticalAlign: "Top",
        color: "#25272B",
        fontWeight: 500,
        fontSize: "14px"
    });

    const timeClosed = (from, startingAt, through, endingAt) => {
        if (from && startingAt && through && endingAt) {
            const date1 = getformateDate(from, "mm/dd/yyyy");
            const time1 = getHHMMTime(startingAt);
            const date2 = getformateDate(through, "mm/dd/yyyy");
            const time2 = getHHMMTime(endingAt);

            const date001 = new Date(`${date1} ${time1}`);
            const date002 = new Date(`${date2} ${time2}`);

            const daysDiff = Math.abs((date001 - date002) / (1000 * 60 * 60 * 24));
            const result = convertDaysToHHMM(daysDiff);
            return result;
        }
        return "No data";
    };

    return (
        <>
            {LDC.loading ? (
                <Loader />
            ) : (
                <Grid container>
                    <Grid item xs={12}>
                        <TableTopFilterWrapper
                            onlyBtn
                            noAlignItem
                            lessmarginOnTop
                            pageheader={<PageHeader title={LDC.isEdit ? LDC.locationName : "Create Location"} breadsCrumData={data} />}
                            btnTitle={WithPermission({module: "Locations", permission: "update_permission"}) && "Edit location"}
                            handleClick={(val) => (e) => {
                                if (val === "Edit location")
                                    navigation(
                                        mrkId
                                            ? `/locations/editLocation/${LDC.id}?viewpage=true&marketId=${mrkId}&marketName=${mrkName}`
                                            : `/locations/editLocation/${LDC.id}?viewpage=true`
                                    );
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{mb: 5}}>
                            <Grid container spacing={2.2}>
                                <Grid item xs={12}>
                                    <Grid item lg={10} md={10} xs={12}>
                                        <SubContainer title="Location details">
                                            <Grid container spacing={2}>
                                                {!!Object.entries(initialVlaues.locationDetails).length &&
                                                    Object.entries(initialVlaues.locationDetails).map((val, i) => (
                                                        <Grid key={i} item sx={{width: "230px"}}>
                                                            <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                                {val[0]}
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize="14px"
                                                                fontWeight={500}
                                                                color="#212121"
                                                            >
                                                                {Array.isArray(val[1])
                                                                    ? val[1].map((el, indx) => (
                                                                          <span key={indx}>{`${indx !== 0 ? ", " : ""}${el}`}</span>
                                                                      ))
                                                                    : mapNamingForID(val[0], val[1])}
                                                            </Typography>
                                                        </Grid>
                                                    ))}
                                            </Grid>
                                        </SubContainer>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item lg={10} md={10} xs={12}>
                                        <SubContainer title="Brands">
                                            {initialVlaues.brandsmarket?.length ? (
                                                <>
                                                    <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                        Brands
                                                    </Typography>
                                                    <Typography variant="subtitle2" fontSize="14px" fontWeight={500} color="#212121">
                                                        {initialVlaues.brandsmarket.map((el, indx) => (
                                                            <span key={indx}>{`${indx !== 0 ? ", " : ""}${mapNamingForID(
                                                                "brands",
                                                                el
                                                            )}`}</span>
                                                        ))}
                                                    </Typography>
                                                </>
                                            ) : (
                                                <Typography variant="subtitle2" fontSize="14px" color="#364152">
                                                    No brands available
                                                </Typography>
                                            )}
                                        </SubContainer>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <MainHeading title="Hours of operation" />
                                    <Grid item lg={10} md={10} xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={6} xs={12}>
                                                <SubContainer title="Standard hours">
                                                    <Grid container spacing={2}>
                                                        {!!initialVlaues.hoursOfOperation?.standardHours.length &&
                                                            initialVlaues.hoursOfOperation?.standardHours.map((obj, index) => (
                                                                <Grid item xs={12} key={index}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={4}>
                                                                            <Typography variant="checkBoxSelected" fontSize="14px">
                                                                                {obj.day}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <Typography variant="checkBoxUnSelected" fontSize="14px">
                                                                                {obj.startTime
                                                                                    ? getFormattedTimeinAMPM(obj.startTime)
                                                                                    : "Closed"}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <Typography variant="checkBoxUnSelected" fontSize="14px">
                                                                                {obj.endTime
                                                                                    ? getFormattedTimeinAMPM(obj.endTime)
                                                                                    : "Closed"}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                    </Grid>
                                                </SubContainer>
                                            </Grid>
                                            <Grid item lg={6} xs={12}>
                                                <SubContainer
                                                    {...(!initialVlaues.hoursOfOperation?.holidayClosures.length && {childFlex: true})}
                                                    title="Holiday closures"
                                                >
                                                    <Grid container spacing={2}>
                                                        {initialVlaues.hoursOfOperation?.holidayClosures.length ? (
                                                            initialVlaues.hoursOfOperation?.holidayClosures
                                                                .slice()
                                                                .sort((a, b) => new Date(a.start.date) - new Date(b.start.date))
                                                                .map((obj, index) => {
                                                                    return (
                                                                        <Grid key={index} item xs={12}>
                                                                            <Typography variant="checkBoxUnSelected">
                                                                                {`${obj?.summary} ${getformateDateUS(obj?.start?.date)}`}
                                                                            </Typography>
                                                                        </Grid>
                                                                    );
                                                                })
                                                        ) : (
                                                            <Grid container item xs={12} justifyContent="center">
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    textAlign="center"
                                                                    fontSize="14px"
                                                                    color="#364152"
                                                                >
                                                                    No holiday closures
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </SubContainer>
                                            </Grid>
                                            <Grid item lg={6} xs={12}>
                                                <SubContainer title="Temporary closures">
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            {[
                                                                                {key: "from", label: "From"},
                                                                                {key: "through", label: "Through"},
                                                                                {key: "timeClosed", label: "Time closed"}
                                                                            ].map((obj, i) => (
                                                                                <TableCell
                                                                                    key={obj.key}
                                                                                    align="left"
                                                                                    sx={{p: 0, pl: i === 0 ? 0 : 1, border: "none"}}
                                                                                >
                                                                                    <Typography
                                                                                        variant="subtitle2"
                                                                                        fontSize="12px"
                                                                                        color="#9e9e9e"
                                                                                    >
                                                                                        {obj.label}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            ))}
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {initialVlaues.temporaryClosures.cloures?.length ? (
                                                                            initialVlaues.temporaryClosures.cloures.map((obj, index) => (
                                                                                <TableRow key={index}>
                                                                                    <TableCell
                                                                                        variant="subtitle2"
                                                                                        sx={() => ({
                                                                                            ...TableCellStyle(),
                                                                                            pl: 0
                                                                                        })}
                                                                                    >
                                                                                        {getformateDateUS(obj.from)}
                                                                                        <br />
                                                                                        <span style={{fontWeight: 500}}>
                                                                                            {getFormattedTimeinAMPM(obj.startingAt)}
                                                                                        </span>
                                                                                    </TableCell>

                                                                                    <TableCell
                                                                                        variant="subtitle2"
                                                                                        sx={() => ({
                                                                                            ...TableCellStyle()
                                                                                        })}
                                                                                    >
                                                                                        {getformateDateUS(obj.through)}
                                                                                        <br />
                                                                                        <span style={{fontWeight: 500}}>
                                                                                            {getFormattedTimeinAMPM(obj.endingAt)}
                                                                                        </span>
                                                                                    </TableCell>

                                                                                    <TableCell
                                                                                        variant="subtitle2"
                                                                                        sx={() => ({
                                                                                            ...TableCellStyle(),
                                                                                            fontWeight: 500
                                                                                        })}
                                                                                    >
                                                                                        {timeClosed(
                                                                                            obj.from,
                                                                                            obj.startingAt,
                                                                                            obj.through,
                                                                                            obj.endingAt
                                                                                        )}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))
                                                                        ) : (
                                                                            <TableRow>
                                                                                <TableCell
                                                                                    variant="subtitle2"
                                                                                    sx={() => ({
                                                                                        border: "none",
                                                                                        textAlign: "center",
                                                                                        paddingTop: 5
                                                                                    })}
                                                                                    colSpan={5}
                                                                                >
                                                                                    No temporary closures
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Grid>
                                                </SubContainer>
                                            </Grid>
                                            <Grid item lg={6} xs={12}>
                                                <SubContainer title="Lead time">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Box sx={{mb: 1}}>
                                                                <Typography variant="subtitle2" fontSize="12px" color="#9e9e9e">
                                                                    How far in advance do you need to know about orders?
                                                                </Typography>
                                                            </Box>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize="14px"
                                                                fontWeight={500}
                                                                color="#212121"
                                                            >
                                                                {initialVlaues.leadTime.knowAboutOrders &&
                                                                    mappingLeadTime[initialVlaues.leadTime.knowAboutOrders]}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Box sx={{mb: 1}}>
                                                                <Typography variant="subtitle2" fontSize="12px" color="#9e9e9e">
                                                                    Cut-off time
                                                                </Typography>
                                                            </Box>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize="14px"
                                                                fontWeight={500}
                                                                style={{textTransform: "lowercase"}}
                                                                color="#212121"
                                                            >
                                                                {getFormattedTimeinAMPM(initialVlaues.leadTime.cuttOffTime)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </SubContainer>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <MainHeading title="Fees" />

                                    <Grid item lg={10} md={10} xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={6} xs={12}>
                                                <SubContainer title="Delivery fees">
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            {[
                                                                                // {key: "deliveryFee", label: "Delivery fee"},
                                                                                {key: "minimumOrder", label: "Order minimum"},
                                                                                {key: "radius", label: "Delivery distance"}
                                                                            ].map((obj, i) => (
                                                                                <TableCell
                                                                                    fontSize="12px"
                                                                                    key={obj.key}
                                                                                    align="left"
                                                                                    sx={{p: 0, pl: i === 0 ? 0 : 1, pb: 1, border: "none"}}
                                                                                >
                                                                                    <Typography
                                                                                        variant="subtitle2"
                                                                                        fontSize="12px"
                                                                                        color="#9e9e9e"
                                                                                    >
                                                                                        {obj.label}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            ))}
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {!!initialVlaues.delivery?.length &&
                                                                            initialVlaues.delivery
                                                                                ?.sort((a, b) => a.radius - b.radius)
                                                                                ?.map((obj, index) => (
                                                                                    <TableRow key={index}>
                                                                                        {/* <TableCell
                                                                                        variant="subtitle2"
                                                                                        sx={() => ({
                                                                                            ...TableCellStyle(),
                                                                                            pl: 0
                                                                                        })}
                                                                                    >
                                                                                        {`$${obj?.deliveryFee}`}
                                                                                    </TableCell> */}
                                                                                        <TableCell
                                                                                            variant="subtitle2"
                                                                                            sx={() => ({
                                                                                                ...TableCellStyle(),
                                                                                                pl: 0
                                                                                            })}
                                                                                        >
                                                                                            {`${FormatCurrency(obj?.minimumOrder)}`}
                                                                                        </TableCell>
                                                                                        <TableCell
                                                                                            variant="subtitle2"
                                                                                            sx={() => ({
                                                                                                ...TableCellStyle(),
                                                                                                fontWeight: 500
                                                                                            })}
                                                                                        >
                                                                                            {`${obj?.radius} miles from location`}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Grid>
                                                </SubContainer>
                                            </Grid>
                                            <Grid item lg={6} xs={12}>
                                                <SubContainer title="Other rates and fees">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={4}>
                                                            <Typography variant="subtitle2" fontSize="12px" color="#9e9e9e">
                                                                Sales tax rate %
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize="14px"
                                                                fontWeight={500}
                                                                color="#212121"
                                                            >
                                                                {initialVlaues.fees.salesTaxRate}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="subtitle2" fontSize="12px" color="#9e9e9e">
                                                                Service fee %
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize="14px"
                                                                fontWeight={500}
                                                                color="#212121"
                                                            >
                                                                {initialVlaues.fees.serviceFee}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="subtitle2" fontSize="12px" color="#9e9e9e">
                                                                Minimum service fee
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize="14px"
                                                                fontWeight={500}
                                                                color="#212121"
                                                            >
                                                                {initialVlaues.fees.minimumServiceFee}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </SubContainer>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default LocationDetailPage;
