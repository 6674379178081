import React from "react";

const OrderBoxContext = React.createContext();

const OrderBoxContextProvider = ({ children }) => {
    const [open, setOpen] = React.useState(false);
    const [pastOrderId, setPastId] = React.useState(null)
    const [orderIngFromPast, setOrderingFromPast] = React.useState(null)
    const [touchFormikFields, setTouchFormikFields] = React.useState(false)

    const toggleOrderdetailDialog = (open) => (event) => {
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setOpen(open);
    };

    const handlePastOrder = (id,type='pastOrder') => {
        if (id) {
            setPastId(id)
            setOrderingFromPast(type)
        }
        else {
            setPastId(null)
            setOrderingFromPast(null)
        }

    }

    return (
        <>
            <OrderBoxContext.Provider value={{ open, toggleOrderdetailDialog, handlePastOrder, pastOrderId, orderIngFromPast, touchFormikFields, setTouchFormikFields }}>{children}</OrderBoxContext.Provider>
        </>
    );
};

export { OrderBoxContextProvider, OrderBoxContext };
