import { Grid, InputAdornment } from "@mui/material";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import { ErrorMessage } from "formik";
import React from "react";
import { enforceFormat,onlyNumbersWithOutDecimal  } from "utils/helperFunctions/helpers";

const Fees = (props) => {
    const { formik, readOnly } = props;
    const { getFieldProps } = formik;
    return (
        <>
            <Grid container spacing={1}>
                <Grid item  sm={3.85} xs={12} md={3.25}   lg={2.7} xl={2.22} >
                    <CustomTextField
                        disabled={readOnly}
                        fullWidth
                        sx={{minWidth:'155px'}}
                        placeholder="%"
                        label="Sales tax rate"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start" sx={{ color: "#212121" }}>
                                    %
                                </InputAdornment>
                            )
                        }}
                        inputProps={{ maxLength: 5 }}
                        onKeyDown={enforceFormat}
                        {...getFieldProps("fees.salesTaxRate")}
                    />
                    <ErrorMessage name="fees.salesTaxRate" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                </Grid>
                <Grid item lg={2.7} md={3.25} sm={3.85} xs={12} xl={2.22}>
                    <CustomTextField
                        fullWidth
                        sx={{minWidth:'155px'}}
                        placeholder="%"
                        label="Service fee"
                        inputProps={{ maxLength: 5 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start" sx={{ color: "#212121" }}>
                                    %
                                </InputAdornment>
                            )
                        }}
                        onKeyDown={onlyNumbersWithOutDecimal}
                        {...getFieldProps("fees.serviceFee")}
                    />
                    <ErrorMessage name="fees.serviceFee" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                </Grid>
                <Grid item lg={2.7} md={3.25} sm={3.85} xs={12} xl={2.22}>
                    <CustomTextField
                        fullWidth
                        sx={{minWidth:'155px'}}
                        placeholder=""
                        label="Minimum service fee"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ color: "#212121" }}>
                                    $
                                </InputAdornment>
                            )
                        }}
                        onKeyDown={enforceFormat}
                        {...getFieldProps("fees.minimumServiceFee")}
                    />
                    <ErrorMessage name="fees.minimumServiceFee" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                </Grid>
            </Grid>
        </>
    );
};

export default Fees;
