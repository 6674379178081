import {Stack, Box, Typography} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import {ErrorMessage, Form, Formik} from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import React, {useState, useEffect} from "react";
import * as yup from "yup";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import PlusIcon from "assets/images/icons/plus.png";
import {generateUrl} from "utils/helperFunctions/helpers";

const formSchema = {
    category: ""
};

// const categoryListNew=[
//     {label:"Home made",value:"Home made",id:"1"},
//     {label:"Dessert",value:"Dessert",id:"2"},
//     {label:"Meal",value:"Meal",id:"3"}
// ]

const AddCategoryForm = (props) => {
    const {toggleDrawer} = useTempDrawer();
    const [initialValues, setInitialValues] = React.useState(formSchema);
    const [categoryList, setCategoryList] = useState([]);
    const dispatch = useDispatch();
    const {handleIsEdited, selectedRow, handleSelectedRow, handleChange, updateCategory} = props;

    const getCategory = async () => {
        const instance = NetworkManager(API.CATEGORY.GET);
        const params = {
            sortBy: "category_name",
            orderBy: "asc",
            type : "item"
        };
        const url = generateUrl(params);
        const response = await instance.request({}, [url]);
        if (response.success) {
            const states = response.data?.categoryList?.rows?.map((state) => ({
                label: state.category_name,
                value: state.category_name,
                id: state.category_id,
                description: state.description
            }));
            setCategoryList(states);
        }
    };

    useEffect(() => {
        getCategory();
    }, []);

    useEffect(() => {
        if (selectedRow) {
            setInitialValues({
                category: selectedRow.category
            });
        } else {
            setInitialValues({
                category: ""
            });
        }
    }, [selectedRow]);

    const handleAddCategory = async (values) => {
        let selectedCategory = {};
        selectedCategory = categoryList.filter((item) => item.id === values.category)[0];
        selectedCategory.item = [];
             const resData = {
                label: selectedCategory?.value,
                value: selectedCategory?.value,
                id: selectedCategory?.id,
                description: selectedCategory?.description,
                item: []
            };
            updateCategory(resData);

        // const instance = NetworkManager(API.CATEGORY.DUPLICATE(selectedCategory?.id));

        // const response = await instance.request();

        // if (response.success) {
        //     const DC = response.data?.name;
        //     const resData = {
        //         label: DC?.category_name,
        //         value: DC?.category_name,
        //         id: DC?.category_id,
        //         description: DC?.description,
        //         item: []
        //     };
        //     updateCategory(resData);
        // } else {
        //     dispatch(
        //         openSnackbar({
        //             open: true,
        //             message: response.message,
        //             variant: "alert",
        //             alert: {
        //                 severity: "error",
        //                 color: "error"
        //             },
        //             close: false
        //         })
        //     );
        // }

      
    };

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={yup.object({
                    category: yup.string().required("Please enter category")
                })}
                onSubmit={(val) => handleAddCategory(val)}
            >
                {(formik) => (
                    <Form>
                        <TemporaryDrawer sideBarStateName="AddNewCategory" handleSelectedRow={handleSelectedRow} resetForm={formik.resetForm} touched={formik.touched}>
                            <Stack sx={{height: "100%"}} justifyContent="space-between" mt={-2}>
                                <Stack spacing={2}>
                                    <PageHeader fontSize="1.625rem" title={selectedRow ? "Edit category" : "Add category"} showOnlyTitle />
                                    <Stack spacing={3}>
                                        <CustomSelect
                                            label="Category"
                                            menuItems={categoryList}
                                            {...formik.getFieldProps("category")}
                                            // value={formik.category}
                                            // name='category'
                                            onChange={(e) => {
                                                const id = e.target.value;
                                                formik.setFieldValue("category", id);
                                            }}
                                        />
                                        <ErrorMessage name="category" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Stack>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            marginTop: "10px"
                                        }}
                                    >
                                        <img src={PlusIcon} alt="plus-icon" width="15px" height="15px" />
                                        <Typography
                                            onClick={() => handleChange("new")}
                                            sx={{
                                                marginLeft: "5px",
                                                cursor: "pointer",
                                                fontWeight: 500,
                                                
                                                color: (theme) => theme.palette.primary.main
                                            }}
                                        >
                                            Create new category
                                        </Typography>
                                    </Box>
                                </Stack>

                                <ButtonGrouping
                                    btnprops={[
                                        {
                                            btnTitle: "Cancel",
                                            sx: (t) => ({color: t.palette.error.dark}),
                                            onClick: (e) => {
                                                formik.resetForm();
                                                toggleDrawer("right", false)(e);
                                                handleSelectedRow();
                                            }
                                        },
                                        {
                                            btnTitle: "Save",
                                            variant: "contained",
                                            sx: (t) => ({color: t.palette.background.paper}),
                                            onClick: (e) => {
                                                formik.handleSubmit();

                                                formik.validateForm().then((res) => {
                                                    // to close the sidebar if no field has validation error
                                                    if (Object.keys(res).length === 0) {
                                                        toggleDrawer("right", false)(e);
                                                        setTimeout(() => {
                                                            formik.resetForm();
                                                        }, 1500);
                                                    }
                                                });
                                            }
                                        }
                                    ]}
                                />
                            </Stack>
                        </TemporaryDrawer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddCategoryForm;
