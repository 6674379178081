// project imports
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import Markets from "views/pages/privatePages/markets";
import Customers from "views/pages/privatePages/customers";
import Orders from "views/pages/privatePages/orders";
import Brands from "views/pages/privatePages/brands";
import BrandDetail from "views/pages/privatePages/brands/brandDetail";
import MenuList from "views/pages/privatePages/brands/menuList";
import Reports from "views/pages/privatePages/reports";

import Delivery from "views/pages/privatePages/delivery";
import Settings from "views/pages/privatePages/settings";
import Locations from "views/pages/privatePages/locations";
import CreateLocations from "views/pages/privatePages/locations/createLocations/CreateLocations";
import ItemDetails from "views/pages/privatePages/brands/brandDetail/items/itemDetails";
import ModifiersListing from "views/pages/privatePages/brands/ModifiersGroupList";
import ItemsListing from "views/pages/privatePages/brands/ItemList";
import Menus from "views/pages/privatePages/Menus";
import MenuDetail from "views/pages/privatePages/Menus/menuDetailPage";
import LocationDetailPage from "views/pages/privatePages/locations/locationDetail";
import PromotionsListing from "views/pages/privatePages/Promotions";
import Quotes from "views/pages/privatePages/quote";
import CustomerDetail from "views/pages/privatePages/customers/CustomerDetail";
import OrderQuoteDetail from "views/pages/privatePages/orders/OrderQuoteDetail";
import PrintOrder from "views/pages/privatePages/print";
import Reviews from "views/pages/privatePages/Review";
import ProfileView from "views/pages/privatePages/customers/CustomerDetail/Tabs/Profile/ProfileView/ProfileView";
import UserDetail from "views/pages/privatePages/settings/UserDetail";
import RoleUpdate from "views/pages/privatePages/settings/Tabs/Roles/RoleUpdate";
import OrderDetails from "views/pages/privatePages/reports/Detail";
import Services from "views/pages/privatePages/services";

// sample page routing
// const SamplePage = Loadable(lazy(() => import("views/sample-page")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: "/",
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: "/",
            element: <Markets />
        },
        {
            path: "/markets",
            element: <Markets />
        },
        {
            path: "/locations",
            element: <Locations />
        },
        {
            path: "/services",
            element: <Services />
        },
        {
            path: "/marketLocations/:id",
            element: <Locations />
        },
        {
            path: "/reports/orderlist",
            element: <OrderDetails />
        },

        {
            path: "/locationDetail/:id",
            element: <LocationDetailPage />
        },
        {
            path: "/locations/:type/:id?",
            element: <CreateLocations />
        },
        {
            path: "/customers",
            element: <Customers />
        },
        {
            path: "/customers/:type/:customersid?",
            element: <CustomerDetail />
        },
        {
            path: "/customer/detail",
            element: <ProfileView />
        },
        {
            path: "/promotions",
            element: <PromotionsListing />
        },
        {
            path: "/menus",
            element: <Menus />
        },
        {
            path: "/menus/:type/:menuid?",
            element: <MenuDetail />
        },
        {
            path: "/items",
            element: <ItemsListing />
        },
        {
            path: "/items/:type/:id?",
            element: <ItemDetails />
        },
        {
            path: "/menus-list",
            element: <MenuList />
        },
        {
            path: "/modifiers-list",
            element: <ModifiersListing />
        },
        {
            path: "/brand-detail/:id?",
            element: <BrandDetail />
        },
        {
            path: "/reports",
            element: <Reports />
        },
        {
            path: "/delivery",
            element: <Delivery />
        },
        {
            path: "/reviews",
            element: <Reviews />
        },
        {
            path: "/orders",
            element: <Orders />
        },
        {
            path: "/orders/:id",
            element: <OrderQuoteDetail />
        },
        {
            path: "/settings",
            element: <Settings />
        },
        {
            path: "/settings/userdetail/:id",
            element: <UserDetail />
        },
        {
            path: "/setting/role?",
            element: <RoleUpdate />
        },
        {
            path: "/quote/detail",
            element: <Quotes />
        },
        {
            path: "/print",
            element: <PrintOrder />
        }
    ]
};

export default MainRoutes;
