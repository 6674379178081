import {
    Dialog,
    DialogActions,
    Grid,
    FormControlLabel,
    Checkbox,
    DialogContent,
    Stack,
    DialogContentText,
    DialogTitle,
    Slide,
    Radio,
    RadioGroup,
    Button,
    Typography
} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import Cancel from "../../assets/images/icons/cancel.svg";
import React from "react";
import {Box} from "@mui/system";
import AnimateButton from "ui-component/extended/AnimateButton";
import styles from "./Driver.module.scss";
import RadioIcon from "assets/images/icons/Bg-Radio.svg";
import CheckedIcon from "assets/images/icons/checkedIconRadio.svg";
import {styled} from "@mui/material/styles";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const BpCheckedIcon = ({ checked }) => (
    <svg width="18px" height="18px" viewBox="0 0 24 24" fontSize="36px">
        <circle cx="50%" cy="50%" r="11px" stroke={`${checked ? "#00ab3a" : "#A0A7B9"}`} fill="none" />
        {checked && <circle cx="50%" cy="50%" r="6px" stroke="#00ab3a" fill="#00ab3a" />}
    </svg>
);

const PrintOrderModal = (props) => {
    const {
        title,
        description,
        open,
        handleClose,
        handleSubmit,
        hideBackdrop = false,
        buttonTitle = "Delete",
        noOnClose = false,
        onSelect,
        selected
    } = props;
    // const [selected, setSelected] = React.useState("customer_order");
    return (
        <Dialog
            open={open}
            {...(!noOnClose && {onClose: handleClose})}
            // TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            hideBackdrop={hideBackdrop}
            PaperProps={{sx: {boxShadow: "0px 0px 8px rgba(0,0,0,0.2)", padding: "33px 23px", width: "421px", position: "relative"}}}
        >
            <Box sx={{position: "absolute", right: "16px", top: "16px", cursor: "pointer"}} onClick={handleClose}>
                <img src={Cancel} alt="plus-icon" width="12px" height="12px" />
            </Box>
            <DialogTitle
                id="alert-dialog-title"
                className={styles.title}
                sx={{letterSpacing: "0.001px", marginTop: "-15px", padding: "0px 5px 15px 5px"}}
            >
                {title}
            </DialogTitle>
            <DialogContent sx={{marginTop: "-15px", padding: "5px 5px"}}>
                <DialogContentText id="alert-dialog-description" className={styles.titleDivision}>
                    {description}
                </DialogContentText>
                <Stack sx={{marginTop: "15px"}}>
                    <Grid item xs={12}>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="never"
                            name="radio-buttons-group"
                            onChange={(e, value) => {
                                onSelect(value);
                            }}
                        >
                            <FormControlLabel
                                value="customer_order"
                                checked={selected === "customer_order"}
                                control={<Radio disableRipple checkedIcon={<BpCheckedIcon checked/>} icon={<BpCheckedIcon />} />}
                                label={
                                    <Typography variant={selected === "customer_order" ? "checkBoxSelected" : "checkBoxUnSelected"}>
                                        Customer order
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                sx={{marginBottom: "5px"}}
                                checked={selected === "price"}
                                value="price"
                                control={<Radio disableRipple checkedIcon={<BpCheckedIcon checked/>} icon={<BpCheckedIcon />} />}
                                label={
                                    <Typography variant={selected === "price" ? "checkBoxSelected" : "checkBoxUnSelected"}>
                                        Kitchen order (without prices)
                                    </Typography>
                                }
                            />
                        </RadioGroup>
                    </Grid>
                </Stack>
            </DialogContent>
            <AnimateButton>
                <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    sx={{
                        fontSize: "0.813rem",
                        fontStyle: "normal",
                        fontWeight: 600,
                        marginTop: "10px",
                        bgcolor: "#00ab3a",
                        height: "35px",
                        color: "#FFF",
                        ":hover": {
                            bgcolor: "#00ab3a"
                        }
                    }}
                    onClick={() => handleSubmit(selected)}
                >
                    {buttonTitle}
                </Button>
            </AnimateButton>
        </Dialog>
    );
};

export default PrintOrderModal;
