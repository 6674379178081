import {Grid, Box} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import {ErrorMessage, Form, Formik} from "formik";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import * as yup from "yup";
import AvailableDrivers from "./AvailableDrivers/AvailableDrivers";
import UnAssignedDeliveries from "./UnAssignedDeliveries/UnAssignedDeliveries";
import AssignedDeliveries from "./AssignedDeliveries/AssignedDeliveries";
import CompletedDeliveries from "./CompletedDeliveries/CompletedDeliveries";
import {API, NetworkManager} from "network/core";
import {dispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import Calendar from "./Calendar/Calendar";
import Loader from "ui-component/Loader";
import {useNavigate, useSearchParams} from "react-router-dom";

const formSchema = {
    market: "",
    date: ""
};

function Deliveries() {
    const [initialValues, setInitialValues] = useState(formSchema);
    const [marketList, setMarketList] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [selectedMarket, setSelectedmarket] = useState();
    const [driversData, setDriversData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [urlSearchParams] = useSearchParams();
    const marketId = urlSearchParams.get("marketId");

    const handleIsUpdated = () => setIsUpdated(!isUpdated);

    const getAllMarkets = async (pageNumber = 1) => {
        const params = `?page=${pageNumber}&pageSize=5000&sortBy=createdAt&orderBy=desc&is_active=true`;
        const instance = NetworkManager(API.MARKET.GET);
        const response = await instance.request({}, [params]);

        if (response.success) {
            const data = response.data.marketList;
            setMarketList(data);
            if (marketId) {
                setSelectedmarket(marketId);
                setInitialValues({market: marketId, date: new Date()});
                return;
            }
            setSelectedmarket(data[0].market_id);
            setInitialValues({market: data[0].market_id, date: new Date()});
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const getDriverList = async () => {
        setLoading(true);
        const instance = NetworkManager(API.DRIVER.LISTING);
        const params = {
            market_id: selectedMarket
        };
        const res = await instance.request({}, params);

        if (res.success) {
            const fetchedData = res.data?.driver;
            const mapData = fetchedData?.sort((a, b) => a?.name?.localeCompare(b?.name))?.map((el) => ({
                ...el,
                name: `${el?.name}`,
                deliveries: el?.orders?.map((el) => ({time: el?.delivery_time, address: el?.delivery_address}))
            }));
            setDriversData(mapData);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (selectedMarket) {
            getDriverList();
        }
    }, [selectedMarket]);

    useEffect(() => {
        getAllMarkets();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={yup.object({
                    market: yup.string().required("Market is required").nullable().trim()
                })}
            >
                {(formik) => (
                    <Grid
                        container
                        sx={{
                            maxWidth: {xs: "100%"},
                            marginTop: "23px",
                            "@media (min-width: 1536px)": {
                                minWidth: "1170px !important",
                                maxWidth: "1170px !important"
                            }
                        }}
                    >
                        <Grid item xs={12}>
                            <Box sx={{display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: 2}}>
                                <Box>
                                    <Calendar setDate={(val) => formik.setFieldValue("date", val)} />
                                </Box>
                                <Box>
                                    <CustomSelect
                                        label="Market"
                                        menuItems={marketList?.map((el) => ({
                                            label: el?.market_name,
                                            value: el?.market_id,
                                            id: el?.market_id
                                        }))}
                                        value={formik.values.market}
                                        onChange={(e) => {
                                            const id = e.target.value;
                                            formik.setFieldValue("market", id);
                                            setSelectedmarket(id);
                                            navigate({
                                                pathname: window.location.pathname,
                                                search: `?tab=deliveries&marketId=${id}`
                                            });
                                        }}
                                        sx={{width: "151px", height: "52px"}}
                                    />
                                    <ErrorMessage name="market" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container sx={{display: "flex", gap: 2}}>
                                <AvailableDrivers isUpdated={isUpdated} formik={formik} marketList={marketList} />
                                <Grid
                                    item
                                    sx={{
                                        marginTop: "23px",
                                        minWidth: {xs: "100% !important", lg: "60% !important"},
                                        maxWidth: {xs: "100% !important", lg: "60% !important"},
                                        "@media (min-width: 1536px)": {
                                            minWidth: "777px !important",
                                            maxWidth: "790px !important"
                                        }
                                    }}
                                >
                                    <UnAssignedDeliveries
                                        formik={formik}
                                        driversData={driversData}
                                        isUpdated={isUpdated}
                                        handleIsUpdated={handleIsUpdated}
                                        marketId={selectedMarket}
                                    />
                                    <AssignedDeliveries
                                        formik={formik}
                                        driversData={driversData}
                                        isUpdated={isUpdated}
                                        handleIsUpdated={handleIsUpdated}
                                        marketId={selectedMarket}
                                    />
                                    <CompletedDeliveries formik={formik} isUpdated={isUpdated} handleIsUpdated={handleIsUpdated} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </>
    );
}

export default Deliveries;
