import {Grid} from "@mui/material";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import React, {useState, useEffect} from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {useDispatch, useSelector} from "store";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import Filters from "commonComponent/Filters";
import {generateUrl} from "utils/helperFunctions/helpers";
import useTempDrawer from "hooks/useTempDrawer";
import FilterChips from "commonComponent/FilterChipsComponent";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {setNestPageNumber, setNestRowsPerPage, setOrder, setOrderBy} from "store/slices/paginationSlice";

const MenuListing = (props) => {
    const {search, filterChips, brand, isChild} = props;
    const param = useParams();
    const location = useLocation();
    console.log(location, "NESTisRendered");
    const {id} = param;
    console.log(isChild);
    const [open, setOpen] = React.useState(false);
    const [brandListing, setBrandListing] = React.useState([]);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    const currentPage = useSelector((state) => state.pagination.nestCurrentPage);
    const rowsPerPage = useSelector((state) => state.pagination.nestRowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.order || "asc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "menu_name");
    // const [search, setSearch] = useState("");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {toggleDrawer} = useTempDrawer();
    const [searchParams] = useSearchParams();
    const brandName = searchParams.get("brandName");
    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const handleCreatemenu = (btntype) => (e) => {
        switch (btntype) {
            case "Create menu": {
                if (id) {
                    navigate(`/menus/menuDetail/?tab=menu&brandId=${id}&edit=yes`);
                } else {
                    navigate(`/menus/menuDetail/?tab=menu&add=yes`);
                }
                break;
            }
            case "menuFilter": {
                toggleDrawer("right", true)(e);
                break;
            }
            default:
                if (id) navigate(`/menus/menuDetail/?tab=menu&brandId=${id}`);

                break;
        }
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handleStatus = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.MENU.STATUS(values.menu_id));

        let isActive = false;
        if (type === "Deactivate") {
            isActive = "false";
        } else {
            isActive = "true";
        }
        const payload = {
            status: isActive
        };

        const response = await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );

            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleDuplicate = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.MENU.DUPLICATE(values.menu_id));
        const response = await instance.request();

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );

            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                if (id) {
                    navigate(
                        `/menus/menuDetail/${row.menu_id}/?tab=menu&edit=yes&menuName=${row?.menu_name}&brandId=${id}&brandName=${brandName}`
                    );
                    return;
                }
                navigate(`/menus/menuDetail/${row.menu_id}/?tab=menu&add=yes&menuName=${row?.menu_name}`);
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            case "Deactivate": {
                handleStatus(row, type);
                break;
            }
            case "Duplicate": {
                handleDuplicate(row, type);
                break;
            }
            case "Activate": {
                handleStatus(row, type);
                break;
            }
            default:
                break;
        }
    };

    const getMenuList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.MENU.GET);
        const params = {
            page: currentPage,
            pageSize: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };
        if (currentOrderBy) {
            params.sortBy = currentOrderBy;
            params.orderBy = currentOrder;
        }
        // while searching we dont need page number
        if (search === "") {
            params.page = currentPage;
        }

        if (search !== "") {
            params.menu_name = search;
            params.page = 1;
            setBrandListing([]);
        }
        if (id) {
            params.brand_id = id;
        }
        const url = generateUrl(params);
        const response = await instance.request({}, [url]);

        if (response.success) {
            const tempList = [];
            response.data.menuList.forEach((row) => {
                const tempObj = {};
                tempObj.brand_id = row.brand_id;
                tempObj.brandName = row.brand.brand_name;
                tempObj.createdAt = row.createdAt;
                tempObj.menu_name = row.menu.menu_name;
                tempObj.menu_id = row.menu_id;
                tempObj.is_active = row.menu.is_active === "true" || false;
                tempObj.abbreviation = row.brand.abbreviation;
                tempObj.brandServices = row.brand.brandServices;
                tempList.push(tempObj);
            });

            setBrandListing(tempList);
            setTotalItems(response.data.pagination.totalItems);
            // setCurrentPage(response.data.pagination.currentPage-1)

            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.MENU.DELETE(selectedRow.menu_id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getMenuList();
    };

    // const handleSearch = (val) => {
    //     setSearch(val);
    //     setCurrentPage(1);
    // };

    const handlePageChange = (e, number) => {
        // setCurrentPage(number + 1);
        dispatch(setNestPageNumber(number + 1));
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        // setRowsPerPage(number);
        dispatch(setNestRowsPerPage(number));
        dispatch(setNestPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        dispatch(setOrder(val1));
        dispatch(setOrderBy(val2));
    };

    useEffect(() => {
        if (search) {
            dispatch(setNestPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        getMenuList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, filterChips]);

    // const handleApplyFilter = (Morefilters) => {
    //     // setFilterChips(Morefilters);
    //     setCurrentPage(1);
    // };
    return (
        <>
            {/* <Filters
                filterState={filterState}
                filterChips={filterChips}
                setFilterChips={setFilterChips}
                handleApplyFilter={handleApplyFilter}
            /> */}
            <Grid container>
                <Grid item xs={12}>
                    {/* <TableTopFilterWrapper
                        filterType="menuFilter"
                        lessmarginOnTop
                        pageheader={id ? <PageHeader title=" " /> : <PageHeader title="Menus" />}
                        btnTitle="Create menu"
                        handleClick={handleCreatemenu}
                        handleSearch={handleSearch}
                    > */}
                    {/* <FilterChips filterChips={filterChips} setFilterChips={setFilterChips} /> */}
                    <CommonTable
                        path={`brandId=${id}&brandName=${brandName}`}
                        loading={loading}
                        data={brandListing}
                        header={[
                            {key: "menu_name", label: "Menu"},
                            {key: "brandName", label: "Brand"},
                            {key: "brandServices.service_name", label: "Service"},
                            {key: "createdAt", label: "Created"},
                            {key: "is_active", label: "Status"},
                            WithPermission({
                                module: "Menu",
                                permission: ["delete_permission", "create_permission", "update_permission"]
                            }) && {
                                key: "action",
                                label: "Action"
                            }
                        ].filter((el) => el)}
                        actions={[
                            WithPermission({module: "Menu", permission: "update_permission"}) && "Edit",
                            WithPermission({module: "Menu", permission: "create_permission"}) && "Duplicate",
                            WithPermission({module: "Menu", permission: "delete_permission"}) && "Delete"
                        ]}
                        handleActionBtn={handleActionBtn}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage - 1}
                        sortingLabel={["Menu", "brand", "Created", "Status"]}
                    />
                    {/* </TableTopFilterWrapper> */}
                </Grid>
            </Grid>
            <DeleteModal
                open={open}
                title="Are you sure you want to delete this menu?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
        </>
    );
};

export default MenuListing;
