import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import html2canvas from "html2canvas";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import JsPDF from "jspdf";
import { NetworkManager, API } from "network/core";
import { useLocation, useSearchParams } from "react-router-dom";
import Loader from "ui-component/Loader";
import { FormatCurrency, checkforPerfectNumber, FormatPhoneNumber, commaFormattedNumber } from "utils/helperFunctions/helpers";
import { ellipsizeText } from "commonComponent/Apptable/textUtils";
import { Document, Page, pdfjs } from "react-pdf";
import { useStyles } from "../orders/OrderQuoteDetail/OrderView/styles";
import Tooltip from "commonComponent/Tooltip";
import logo from "assets/images/logo.svg";
import PDFComponent from "../orders/OrderQuoteDetail/OrderView/pdfComponent";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PrintOrder() {
    const [quoteListData, setQuoteListData] = useState(null);
    const [bannerClr, setBannerClr] = useState("#5ADA92");
    const [bannerBackgroundClr, setBannerBackgroundClr] = useState("rgba(237, 255, 245, 0.5)");
    const [loading, setLoading] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParams] = useSearchParams();
    const [itemDetailSorted, setItemDetailSorted] = useState(null);
    const location = useLocation();
    const OrderId = searchParams.get("orderId");
    const PriceParam = searchParams.get("price");
    const DownloadTrue = searchParams.get("download");
    const [subTotal, setSubTotal] = useState("");

    const [error, setError] = useState(null);
    const onError = (err) => {
        setError(err);
    };
    const styles = useStyles();

    const getColorOfStatus = (status) => {
        switch (status) {
            case "Draft":
                return "#9F33F9";
            case "Complete":
                return "rgb(20, 201, 234)";
            case "Quote Sent":
                return "#24C4FB";
            case "QUOTE_REJECTED":
                return "#E10000";
            case "Cancelled":
                return "rgb(38, 39, 42)";
            case "Rejected":
                return "rgb(38, 39, 42)";
            case "Needs Driver":
                return "rgb(20, 201, 234)";
            case "Accepted":
                return "rgb(20, 201, 234)";
            case "New":
                return "rgb(20, 201, 234)";
            case "Active":
                return "rgb(20, 201, 234)";
            case "Driver Assigned":
                return "rgb(20, 201, 234)";
            case "ON_THE_WAY":
                return "rgb(2, 180, 108);";
            default:
                return "#26272A";
        }
    };

    const metersToMiles = (meters) => {
        const metersInOneMile = 1609.34;
        const miles = meters / metersInOneMile;
        return miles.toFixed(1);
    };

    useEffect(() => {
        if (quoteListData) {
            if (quoteListData?.order?.status === "Cancelled") {
                setBannerBackgroundClr("#F8EEEE");
            } else if (quoteListData?.order?.status === "Draft") {
                setBannerBackgroundClr("#F7EEFF");
            } else if (quoteListData?.order?.status === "Rejected") {
                setBannerBackgroundClr("#E3E3E3");
            }
        }
    }, [quoteListData]);


    const printDocument = async (orderNum) => {
        const input = document.getElementById("divToPrint");
        const bg = document.getElementsByClassName("bgColor")

        // const footer = document.getElementsByClassName("footer")
        // footer.style.position = "fixed"
        // footer.style.bottom = 0
        // footer.style.width = "100%"
        // footer.style.textAlign = "center"
        // footer.style.left = 0
        // footer.style.right = 0



        // html2canvas(document.body).then( (canvas)=> {
        //     const imgData = canvas.toDataURL("image/jpeg", 1.0);
        //     const pdf = new JsPDF("p", "mm", [400, 480]);
        //     pdf.addImage(imgData, "JPEG", 0, 0, 400, 480);
        //     pdf.save("screen-3.pdf");
        // });
        window.print();

        html2canvas(input, { scale: 4, quality: 4 }).then((canvas) => {
            setLoading(true);
            const imgData = canvas.toDataURL("image/jpeg");
            const pdf = new JsPDF("p", "mm", "a4", true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const totalPages = pdf.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i + 1) {
                pdf.setPage(i);
                pdf.setFontSize(5);
                pdf.setTextColor(0);
                pdf.text(pdf.internal.pageSize.getWidth() - 5, pdf.internal.pageSize.getHeight() - 0.1, "YOUR_TEXT");
                pdf.addImage(logo, 'JPEG', pdf.internal.pageSize.getWidth() - 1.1, pdf.internal.pageSize.getHeight() - 0.25, 1, 0.2);
            }
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const imgX = (pdfWidth - imgWidth * ratio) / 2;
            const imgY = 30;
            pdf.addImage(imgData, "JPEG", imgX, imgY, imgWidth * ratio, imgHeight * ratio);

            pdf.save(`order_${orderNum}.pdf`);
            setLoading(false);
            // window.close();
        });
    };

    // window.onbeforeprint = function() {
    //     let pageNum = 0;
    //     const pages = document.getElementsByClassName('pageNumber');
    //     for (let i = 0; i < pages.length; i+1) {
    //       pages[i].textContent = pageNum;
    //       pageNum += 1;
    //     }
    //   };

    const calculateTotalItemPrice = (orderItem) => {
        let totalPrice = parseFloat(orderItem?.price);

        orderItem?.orderItemsModifiers.forEach((row) => {
            totalPrice += row.total_price;
        });
        totalPrice += parseFloat(orderItem.packaging_price);

        return parseFloat(totalPrice).toFixed(2);
    };
    const getQuoteDetailList = async (id) => {
        setLoading(true);
        const instance = NetworkManager(API.ORDER.QUOTEDETAIL(id));
        const response = await instance.request();
        if (response.success) {
            setQuoteListData(response.data);
            const sortedItem = [];
            response.data.category.forEach((row) => {
                row.orderItems.forEach((col) => {
                    sortedItem.push(col);
                });
            });
            sortedItem.sort((a, b) => a.item_ordering - b.item_ordering);
            const subTotal = sortedItem.map((item) => parseFloat(calculateTotalItemPrice(item)));
            const sum = subTotal.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setSubTotal(sum.toFixed(2));
            setItemDetailSorted(sortedItem);
            // printDocument(response.data?.order?.order_number)
            setTimeout(() => {
                printDocument(response.data?.order?.order_number);
            }, 1000);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        if (OrderId) {
            getQuoteDetailList(OrderId);
        }
    }, [OrderId]);

    const handleServiceString = () => {
        let str = "";
        if (
            Math.round(Number(quoteListData?.order?.kitchen_location?.minimum_service_fee)) !==
            Math.round(Number(quoteListData?.order?.service_fee_value))
        ) {
            if (quoteListData?.order?.service_fee_unit === "percent") {
                str = `${parseFloat(quoteListData?.order?.service_fee_unit_value)}%`;
            } else if (quoteListData?.order?.service_fee_unit === "usd") {
                str = `$ ${parseFloat(quoteListData?.order?.service_fee_unit_value)}`;
            }
        }

        return str;
    };

    const handleAdvanceString = () => {
        return quoteListData?.order?.advance_deposit_percent ? `${quoteListData?.order?.advance_deposit_percent}%` : "0%";
    };

    const handleSalesTaxString = () => `${checkforPerfectNumber(quoteListData?.order?.kitchen_location?.sales_tax_rate || " ")}%`;

    const dietaryMap = {
        VEGETARIAN: "V ",
        VEGAN: "VG ",
        "GLUTEN-FREE": "GF ",
        "DAIRY-FREE": "DF ",
        SPICY: "S "
    };

    const dietarySequence = ["VEGETARIAN", "VEGAN", "GLUTEN-FREE", "DAIRY-FREE", "SPICY"];

    const sortAndMapDietary = (arr1) => {
        // Create a copy of arr1 to avoid modifying the original array
        const sortedArr = [...arr1];

        // Sort the array based on the index in dietarySequence
        sortedArr.sort((a, b) => dietarySequence.indexOf(a.dietary) - dietarySequence.indexOf(b.dietary));

        // Map each value using dietaryMap
        const mappedArr = sortedArr.map((obj) => dietaryMap[obj.dietary]);

        // Return the resulting array as in the original format
        return mappedArr;
    };

    const sortAndMapModifierDietary = (arr1) => {
        // Create a copy of arr1 to avoid modifying the original array
        const sortedArr = [...arr1];

        // Sort the array based on the index in dietarySequence
        sortedArr.sort((a, b) => dietarySequence.indexOf(a.modifier_dietary) - dietarySequence.indexOf(b.modifier_dietary));

        // Map each value using dietaryMap
        const mappedArr = sortedArr.map((obj) => dietaryMap[obj.modifier_dietary]);

        // Return the resulting array as in the original format
        return mappedArr;
    };
    function formatDateToAMPM(timestamp) {
        const date = new Date(timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "pm" : "am";

        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedHours}:${formattedMinutes}${ampm}`;
    }
    const showDietaryForItem = (orderItem) => {
        let dietary = "";
        if (orderItem.item?.itemDietary?.length > 0) {
            const temp = sortAndMapDietary(orderItem.item?.itemDietary);

            dietary = `(${temp.join(", ")})`;
        }
        return dietary;
    };
    const showDietaryForModifier = (orderItem) => {
        let dietary = "";
        if (orderItem?.modifierDietary?.length > 0) {
            const temp = sortAndMapModifierDietary(orderItem?.modifierDietary);

            dietary = `(${temp.join(", ")})`;
        }
        return dietary;
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handlePreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const handleNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };
    const pdfUrl = "your-pdf-document.pdf";

    const handlePrintPDF = () => {
        document.title = `order_${quoteListData?.order?.order_number}`;
        window.print();
    };

    return (
        <Grid id="PDFId" container mb={8} mt={2} pr={2} sx={{ display: "grid", placeItems: "center" }} className="quote">
            {loading && <Loader />}
            {quoteListData ? (
                <>
                    {/* <Grid container className="testDiv">
                        <Grid item xs={12} sx={{textAlign: "right", display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                            <Button
                                startIcon={<LocalPrintshopOutlinedIcon sx={{width: "16px", height: "16px", color: "#000000"}} />}
                                onClick={() => handlePrintPDF()}
                                sx={{fontSize: "1rem"}}
                            >
                              Print order
                            </Button>
                        </Grid>
                    </Grid> */}
                    <PDFComponent
                        quoteListData={quoteListData}
                        PriceParam={PriceParam}
                        getColorOfStatus={getColorOfStatus}
                        showDietaryForItem={showDietaryForItem}
                        itemDetailSorted={itemDetailSorted}
                        formatDateToAMPM
                        metersToMiles={metersToMiles}
                        handleAdvanceString={handleAdvanceString}

                        showDietaryForModifier={showDietaryForModifier}
                        calculateTotalItemPrice
                        handleSalesTaxString={handleSalesTaxString}
                        subTotal={subTotal}
                        showAssignDriverButton={false}
                    />

                </>
            ) : null}
        </Grid>
    );
}

export default PrintOrder;
