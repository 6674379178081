import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";

const useAddRolesController = (props) => {
    const [initialState, setIntialState] = useState({
        role: "",
        description: "",
        isSelectedAll: false,
        isRead: false,
        isCreate: false,
        isUpdate: false,
        isEdit: false,
        checkAtleastOneViewSelected: 0
    });

    const navigation = useNavigate();

    const validation = Yup.object().shape({
        role: Yup.string().required("*Role is required").max(100, "Can not add more than 100 character"),
        description: Yup.string().required("*Description is required").max(250, "Can not add more than 250 character")
    });

    const tableheading = {read_permission: "View", create_permission: "Add", update_permission: "Edit", delete_permission: "Delete"};
    // Change in keyarr strings will change the Header of permisssion checkbox
    const keyarr = ["read_permission", "create_permission", "update_permission", "delete_permission"];
    const crudStatus = (add, read, edit, del) => ({
        [keyarr[0]]: read,
        [keyarr[1]]: add,
        [keyarr[2]]: edit,
        [keyarr[3]]: del
    });

    const handleSelectAll = (formik) => {
        if (!formik?.values?.isSelectedAll) {
            props?.modules.forEach((ele) => {
                formik.setFieldValue(`permission[${ele}]`, crudStatus(true, true, true, true));
            });
        } else {
            props?.modules.forEach((ele) => {
                formik.setFieldValue(`permission[${ele}]`, crudStatus(false, false, false, false));
            });
        }

        formik.setFieldValue(`isSelectedAll`, !formik?.values?.isSelectedAll);
    };

    // for view
    const handleIsRead = (formik) => {
        if (!formik?.values?.isRead) {
            props?.modules.forEach((ele) => {
                formik.setFieldValue(
                    `permission[${ele}]`,
                    crudStatus(
                        formik.values.permission[ele][keyarr[1]],
                        true,
                        formik.values.permission[ele][keyarr[2]],
                        formik.values.permission[ele][keyarr[3]]
                    )
                );
            });
        } else {
            props?.modules.forEach((ele) => {
                formik.setFieldValue(`permission[${ele}]`, crudStatus(false, false, false, false));
            });
            formik.setFieldValue(`isCreate`, false);
            formik.setFieldValue(`isEdit`, false);
            formik.setFieldValue(`isUpdate`, false);
        }

        formik.setFieldValue(`isRead`, !formik?.values?.isRead);
    };

    // for add
    const handleIsCreate = (formik) => {
        if (!formik?.values?.isCreate) {
            props?.modules.forEach((ele) => {
                formik.setFieldValue(
                    `permission[${ele}]`,
                    crudStatus(true, true, formik.values.permission[ele][keyarr[2]], formik.values.permission[ele][keyarr[3]])
                );
            });
            formik.setFieldValue(`isRead`, true);
        } else {
            props?.modules.forEach((ele) => {
                formik.setFieldValue(
                    `permission[${ele}]`,
                    crudStatus(
                        false,
                        formik?.values?.isRead,
                        formik.values.permission[ele][keyarr[2]],
                        formik.values.permission[ele][keyarr[3]]
                    )
                );
            });
        }

        formik.setFieldValue(`isCreate`, !formik?.values?.isCreate);
    };
    // for Edit
    const handleIsEdit = (formik) => {
        if (!formik?.values?.isEdit) {
            props?.modules.forEach((ele) => {
                formik.setFieldValue(
                    `permission[${ele}]`,
                    crudStatus(formik.values.permission[ele][keyarr[1]], true, true, formik.values.permission[ele][keyarr[3]])
                );
            });
            formik.setFieldValue(`isRead`, true);
        } else {
            props?.modules.forEach((ele) => {
                formik.setFieldValue(
                    `permission[${ele}]`,
                    crudStatus(
                        formik.values.permission[ele][keyarr[1]],
                        formik?.values?.isRead,
                        false,
                        formik.values.permission[ele][keyarr[3]]
                    )
                );
            });
        }

        formik.setFieldValue(`isEdit`, !formik?.values?.isEdit);
    };
    // for Update
    const handleIsUpdate = (formik) => {
        if (!formik?.values?.isUpdate) {
            props?.modules.forEach((ele) => {
                formik.setFieldValue(
                    `permission[${ele}]`,
                    crudStatus(formik.values.permission[ele][keyarr[1]], true, formik.values.permission[ele][keyarr[2]], true)
                );
            });
            formik.setFieldValue(`isRead`, true);
        } else {
            props?.modules.forEach((ele) => {
                formik.setFieldValue(
                    `permission[${ele}]`,
                    crudStatus(
                        formik.values.permission[ele][keyarr[1]],
                        formik?.values?.isRead,
                        formik.values.permission[ele][keyarr[2]],
                        false
                    )
                );
            });
        }

        formik.setFieldValue(`isUpdate`, !formik?.values?.isUpdate);
    };

    function checkAllCreatePermissions(permissions, permissionName) {
        if (permissions) {
            const modules = Object.keys(permissions);
            for (let i = 0; i < modules.length; i += 1) {
                const module = modules[i];
                if (permissions[module][permissionName] === false) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    const createPermission = (modules, defaultPermission) => {
        const permission = {};
        modules.forEach((element) => {
            permission[element] = crudStatus(false, false, false, false);
        });
        let isRead = false;
        let isCreate = false;
        let isUpdate = false;
        let isEdit = false;
        if (defaultPermission) {
            const checkIsCreate = checkAllCreatePermissions(props?.setDefaultData?.permission, keyarr[1]);
            const checkIsRead = checkAllCreatePermissions(props?.setDefaultData?.permission, keyarr[0]);
            const checkIsEdit = checkAllCreatePermissions(props?.setDefaultData?.permission, keyarr[2]);
            const checkIsDelete = checkAllCreatePermissions(props?.setDefaultData?.permission, keyarr[3]);
            isRead = checkIsRead;
            isCreate = checkIsCreate;
            isUpdate = checkIsDelete;
            isEdit = checkIsEdit;
        }
        setIntialState({
            role: "",
            description: "",
            modules: props.modules,
            isSelectedAll: false,
            isRead: isRead,
            isCreate: isCreate,
            isUpdate: isUpdate,
            isEdit: isEdit,
            checkAtleastOneViewSelected: 0,
            permission
        });
    };

    const handleCheck = (formik, ele, key) => {
        if (key !== keyarr[0]) {
            formik.setFieldValue(`permission[${ele}][${keyarr[0]}]`, true);
        }
        if (key === keyarr[0]) {
            let count = formik.values?.checkAtleastOneViewSelected;
            if (formik.values.permission[ele][key]) {
                count += 1;
            } else {
                count -= 1;
            }
            formik.setFieldValue(`checkAtleastOneViewSelected`, count);
        }
        if (key === keyarr[0] && formik.values.permission[ele][key] && props.groupedPermission) {
            formik.setFieldValue(`permission[${ele}]`, crudStatus(false, false, false, false));
        }

        formik.setFieldValue(`permission[${ele}][${key}]`, !formik.values.permission[ele][key]);
    };

    const handleSave = (val) => {
        props.handleSave(val);
    };

    const handleClose = () => {
        navigation(-1);
    };

    useEffect(() => {
        createPermission(props.modules, props?.setDefaultData);
    }, [props.modules, props?.setDefaultData]);

    useEffect(() => {
        if (props?.setDefaultData) {
            // const check =
            //     Object?.keys(props?.setDefaultData?.permission).map((el) =>
            //         Object.values(props?.setDefaultData?.permission?.[el]).some((element) => element === false)
            //     ) || [];

            setIntialState((pre) => ({
                ...pre,
                ...props.setDefaultData
            }));
        }
    }, [props.setDefaultData]);

    return {
        initialState,
        handleCheck,
        handleClose,
        handleSave,
        validation,
        tableheading,
        handleSelectAll,
        handleIsRead,
        handleIsCreate,
        handleIsEdit,
        handleIsUpdate
    };
};

export default useAddRolesController;
