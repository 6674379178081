import {Grid, Box, Typography, useMediaQuery} from "@mui/material";
import React from "react";
import {getFields} from "../getFields";
import ActionButton from "../ActionButton";
import {useTheme} from "@emotion/react";

const ApptableCards = (props) => {
    const {
        headers,
        data,
        ActionArray,
        handleActionBtn,
        id,
        searchParam,
        path,
        sortingLabel = [],
        loading = false,
        withCheckbox = false,
        notCaptilize,
        handleHeaderActions,
        handleReviewModal,
        reviewStatus,
        reportStatus,
        statusChange,
        customerStatus,
        orderStatus,
        userStatus
        // isMobile
    } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery("(max-width:480px)");
    const headerMap = new Map();
    headers.forEach((header) => {
        if (!headerMap.has(header?.key)) {
            headerMap.set(header?.key, header?.label);
        }
    });
    return (
        <Box>
            {data.map((row, itx) => {
                return (
                    <Grid
                        container
                        key={itx}
                        sx={{border: "1px solid #f0f0f0", backgroundColor: "#f7f7f7"}}
                        mb="1rem"
                        p="1rem"
                        borderRadius="4px"
                    >
                        {Array.from(headerMap.keys()).map((key, i) => {
                            const finalCheck = headerMap.has("is_active") ? "is_active" : false;
                            const statusCheck3 = headerMap.has("status") ? "status" : finalCheck;
                            const stausCheck2 = headerMap.has("isActivate") ? "isActivate" : statusCheck3;
                            const statusKey = headerMap.has("order.status") ? "order.status" : stausCheck2;
                            const actionButtonRow =
                                i === 0 ? (
                                    <Grid item xs={12} display="flex" justifyContent="space-between">
                                        <Grid item xs={4} display="flex">
                                            <Grid item xs={6}>
                                                {key !== "action" && key !== statusKey && (
                                                    <Typography
                                                        sx={{color: "#8F9198", paddingRight: "10px", fontSize: isMobile ? "10px" : "12px"}}
                                                    >
                                                        {headerMap.get(key)}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={6}>
                                                {key !== "action" &&
                                                    key !== statusKey &&
                                                    getFields(
                                                        key,
                                                        row,
                                                        id,
                                                        path,
                                                        searchParam,
                                                        handleReviewModal,
                                                        handleHeaderActions,
                                                        isMobile
                                                    )}
                                            </Grid>
                                        </Grid>
                                        <Box sx={{display: "flex"}}>
                                            {i === 0 && statusKey && (
                                                <Typography sx={{fontWeight: 400}}>
                                                    {" "}
                                                    {getFields(
                                                        statusKey,
                                                        row,
                                                        id,
                                                        path,
                                                        searchParam,
                                                        handleReviewModal,
                                                        handleHeaderActions,
                                                        isMobile
                                                    )}
                                                </Typography>
                                            )}
                                            {i === 0 && (
                                                <ActionButton
                                                    row={row}
                                                    ActionArray={
                                                        row?.order_number
                                                            ? orderStatus(row?.status?.trim(), row)
                                                            : row?.sub_role_detail
                                                            ? userStatus(row?.status?.trim())
                                                            : row.is_active === true || row.is_active === false
                                                            ? [...ActionArray, statusChange(row)]
                                                            : row?.customer_review_id
                                                            ? reviewStatus(row.status)
                                                            : row?.reportUniqueID
                                                            ? reportStatus(row.status)
                                                            : row?.customerUniqueID
                                                            ? customerStatus(row.isActivate)
                                                            : [...ActionArray]
                                                    }
                                                    handleActionBtn={handleActionBtn}
                                                />
                                            )}
                                        </Box>
                                    </Grid>
                                ) : (
                                    <Grid item xs={6} display="flex" py="0.2rem">
                                        <Grid item xs={4}>
                                            {key !== "action" && key !== statusKey && (
                                                <Typography
                                                    sx={{color: "#8F9198", paddingRight: "10px", fontSize: isMobile ? "10px" : "12px"}}
                                                >
                                                    {headerMap.get(key)}
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {key !== "action" &&
                                                key !== statusKey &&
                                                getFields(
                                                    key,
                                                    row,
                                                    id,
                                                    path,
                                                    searchParam,
                                                    handleReviewModal,
                                                    handleHeaderActions,
                                                    isMobile
                                                )}
                                        </Grid>
                                    </Grid>
                                );
                            const notButtonCard =
                                i === 0 ? (
                                    <Grid item xs={12} display="flex" justifyContent="space-between">
                                        <Grid item xs={4} display="flex">
                                            <Grid item xs={6}>
                                                {key !== "action" && key !== statusKey && (
                                                    <Typography
                                                        sx={{color: "#8F9198", paddingRight: "10px", fontSize: isMobile ? "10px" : "12px"}}
                                                    >
                                                        {headerMap.get(key)}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={6}>
                                                {key !== "action" &&
                                                    key !== statusKey &&
                                                    getFields(key, row, id, path, searchParam, handleReviewModal, handleHeaderActions)}
                                            </Grid>
                                        </Grid>
                                        <Box>
                                            {i === 0 && headerMap.has(statusKey) && (
                                                <Typography sx={{fontWeight: 400}}>
                                                    {" "}
                                                    {getFields(
                                                        statusKey,
                                                        row,
                                                        id,
                                                        path,
                                                        searchParam,
                                                        handleReviewModal,
                                                        handleHeaderActions,
                                                        isMobile
                                                    )}
                                                </Typography>
                                            )}
                                            {i === 0 && (
                                                <ActionButton
                                                    row={row}
                                                    ActionArray={
                                                        row?.order_number
                                                            ? orderStatus(row?.status?.trim(), row)
                                                            : row?.sub_role_detail
                                                            ? userStatus(row?.status?.trim())
                                                            : row.is_active === true || row.is_active === false
                                                            ? [...ActionArray, statusChange(row)]
                                                            : row?.customer_review_id
                                                            ? reviewStatus(row.status)
                                                            : row?.reportUniqueID
                                                            ? reportStatus(row.status)
                                                            : row?.customerUniqueID
                                                            ? customerStatus(row.isActivate)
                                                            : [...ActionArray]
                                                    }
                                                    handleActionBtn={handleActionBtn}
                                                />
                                            )}
                                        </Box>
                                    </Grid>
                                ) : (
                                    <Grid item xs={6} display="flex" py="0.2rem">
                                        <Grid item xs={4}>
                                            {key !== "action" && key !== statusKey && (
                                                <Typography
                                                    sx={{color: "#8F9198", paddingRight: "10px", fontSize: isMobile ? "10px" : "12px"}}
                                                >
                                                    {headerMap.get(key)}
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {key !== "action" &&
                                                key !== statusKey &&
                                                getFields(key, row, id, path, searchParam, handleReviewModal, handleHeaderActions)}
                                        </Grid>
                                    </Grid>
                                );
                            return row[key]?.type !== "button" && typeof row[key] !== "object" ? actionButtonRow : notButtonCard;
                        })}
                    </Grid>
                );
            })}
        </Box>
    );
};

export default ApptableCards;
