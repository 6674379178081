import React, {useState, useEffect} from "react";
import {Grid, Typography, Box, Stack, Divider} from "@mui/material";
import Button from "@mui/material/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Paid from "assets/PAID.svg";
import {NetworkManager, API} from "network/core";
import {useLocation, useSearchParams} from "react-router-dom";
import Loader from "ui-component/Loader";
import {FormatCurrency, checkforPerfectNumber, FormatPhoneNumber, commaFormattedNumber} from "utils/helperFunctions/helpers";
import {useStyles} from "../../../OrderQuoteDetail/OrderView/styles";
import Cancel from "assets/images/icons/cancel.svg";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";
import {truncate} from "lodash";
import logo from "assets/images/logo.svg";

function PrintOrder({orderDetailId, handleClose, handleOrderClick}) {
    const [quoteListData, setQuoteListData] = useState(null);
    const [bannerClr, setBannerClr] = useState("#5ADA92");
    const [bannerBackgroundClr, setBannerBackgroundClr] = useState("rgba(237, 255, 245, 0.5)");
    const [loading, setLoading] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParams] = useSearchParams();
    const [itemDetailSorted, setItemDetailSorted] = useState(null);
    const location = useLocation();
    const OrderId = orderDetailId;
    const PriceParam = searchParams.get("price");
    const DownloadTrue = searchParams.get("download");
    const [subTotal, setSubTotal] = useState("");

    const [error, setError] = useState(null);
    const onError = (err) => {
        setError(err);
    };
    const styles = useStyles();

    const getColorOfStatus = (status) => {
        switch (status) {
            case "Draft":
                return "#9F33F9";
            case "Complete":
                return "rgb(20, 201, 234)";
            case "Quote Sent":
                return "#24C4FB";
            case "QUOTE_REJECTED":
                return "#E10000";
            case "Cancelled":
                return "rgb(38, 39, 42)";
            case "Rejected":
                return "rgb(38, 39, 42)";
            case "Needs Driver":
                return "rgb(20, 201, 234)";
            case "Accepted":
                return "rgb(20, 201, 234)";
            case "New":
                return "rgb(20, 201, 234)";
            case "Active":
                return "rgb(20, 201, 234)";
            case "Driver Assigned":
                return "rgb(20, 201, 234)";
            case "ON_THE_WAY":
                return "rgb(2, 180, 108);";
            default:
                return "#26272A";
        }
    };

    const metersToMiles = (meters) => {
        const metersInOneMile = 1609.34;
        const miles = meters / metersInOneMile;
        return miles.toFixed(1);
    };

    useEffect(() => {
        if (quoteListData) {
            if (quoteListData?.order?.status === "Cancelled") {
                setBannerBackgroundClr("#F8EEEE");
            } else if (quoteListData?.order?.status === "Draft") {
                setBannerBackgroundClr("#F7EEFF");
            } else if (quoteListData?.order?.status === "Rejected") {
                setBannerBackgroundClr("#E3E3E3");
            }
        }
    }, [quoteListData]);

    const calculateTotalItemPrice = (orderItem) => {
        let totalPrice = parseFloat(orderItem?.item?.price);
        orderItem?.orderTemplateItemsModifiers?.forEach((row) => {
            totalPrice += Number(row?.modifier?.price);
        });

        return parseFloat(totalPrice).toFixed(2);
    };

    const calculateSubtotal = () => {
        let final = 0;

        itemDetailSorted.forEach((orderItem) => {
            let totalPrice = 0;
            totalPrice += parseFloat(orderItem?.item?.price);
            orderItem?.orderTemplateItemsModifiers?.forEach((row) => {
                totalPrice += Number(row?.modifier?.price);
            });
            totalPrice *= Number(orderItem?.quantity);
            final += totalPrice;
        });

        return parseFloat(final).toFixed(2);
    };
    const getQuoteDetailList = async (id) => {
        setLoading(true);
        const instance = NetworkManager(API.ORDER.TEMPLATEDETAILBYID(id));
        let response = await instance.request();
        response = {
            code: response.code,
            data: {
                order: response.data.orderTemplateDetails,
                category: response.data.orderTemplateCategory.map((item) => {
                    return {
                        category: item.category,
                        orderItems: item.orderTemplateItems,
                        order_category_id: item.order_template_category_id
                    };
                })
            },
            message: response.message,
            success: response.success,
            error: response.error
        };
        if (response.success) {
            setQuoteListData(response.data);
            const sortedItem = [];
            response.data.category.forEach((row) => {
                row.orderItems.forEach((col) => {
                    sortedItem.push(col);
                });
            });
            sortedItem.sort((a, b) => a.item_ordering - b.item_ordering);

            setItemDetailSorted(sortedItem);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        if (OrderId) {
            getQuoteDetailList(OrderId);
        }
    }, [OrderId]);

    const handleSalesTaxString = () => `${checkforPerfectNumber(quoteListData?.order?.kitchen_location?.sales_tax_rate || " ")}%`;

    const dietaryMap = {
        VEGETARIAN: "V",
        VEGAN: "VG",
        "GLUTEN-FREE": "GF",
        "DAIRY-FREE": "DF",
        SPICY: "S"
    };

    const dietarySequence = ["VEGETARIAN", "VEGAN", "GLUTEN-FREE", "DAIRY-FREE", "SPICY"];

    const sortAndMapDietary = (arr1) => {
        const sortedArr = [...arr1];
        sortedArr.sort((a, b) => dietarySequence.indexOf(a.dietary) - dietarySequence.indexOf(b.dietary));
        const mappedArr = sortedArr.map((obj) => dietaryMap[obj.dietary]);
        return mappedArr;
    };

    const sortAndMapModifierDietary = (arr1) => {
        const sortedArr = [...arr1];
        sortedArr.sort((a, b) => dietarySequence.indexOf(a.modifier_dietary) - dietarySequence.indexOf(b.modifier_dietary));
        const mappedArr = sortedArr.map((obj) => dietaryMap[obj.modifier_dietary]);
        return mappedArr;
    };
    function formatDateToAMPM(timestamp) {
        const date = new Date(timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "pm" : "am";

        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedHours}:${formattedMinutes}${ampm}`;
    }
    const showDietaryForItem = (orderItem) => {
        let dietary = "";
        if (orderItem.item?.itemDietary?.length > 0) {
            const temp = sortAndMapDietary(orderItem.item?.itemDietary);
            const originalString = temp.join(", ");
            dietary = `(${originalString})`;
        }
        return dietary;
    };
    const showDietaryForModifier = (orderItem) => {
        let dietary = "";
        if (orderItem?.modifierDietary?.length > 0) {
            const temp = sortAndMapModifierDietary(orderItem?.modifierDietary);
            const originalString = temp.join(", ");
            dietary = `(${originalString})`;
        }
        return dietary;
    };

    const isPartialAmountPaid = () => {
        if (quoteListData?.order?.remaining_amount_to_be_paid === 0) {
            return false;
        } else if (Number(quoteListData?.order?.remaining_amount_to_be_paid) < Number(quoteListData?.order?.total_price)) {
            return true;
        }
        return false;
    };

    const partialAmountPaid = () => {
        if (quoteListData?.order?.remaining_amount_for_refund) {
            return FormatCurrency(Number(quoteListData?.order?.advance_deposit_amount + quoteListData?.order?.remaining_amount_for_refund));
        } else return FormatCurrency(Number(quoteListData?.order?.advance_deposit_amount));
        // return FormatCurrency(Number(quoteListData?.order?.total_price) - Number(quoteListData?.order?.remaining_amount_to_be_paid));
    };
    return (
        <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center"}} className="quote">
            <Box sx={{position: "absolute", right: "16px", top: "16px", cursor: "pointer", zIndex: "1999"}} onClick={handleClose}>
                <img src={Cancel} alt="plus-icon" width="12px" height="12px" />
            </Box>
            {loading && <Loader />}
            {quoteListData ? (
                <Box
                    id="divToPrint"
                    sx={{
                        border: "1px solid #f0f0f0",
                        padding: "16px",
                        borderRadius: "4px",
                        width: "816px",
                        minWidth: "777px",
                        maxWidth: "777px"
                    }}
                >
                    <Grid item xs={12}>
                        <Typography variant="subDetail1" fontSize="0.813em" color="#020A11" fontWeight={500}>
                            <Stack
                                sx={[
                                    styles?.titleContainer,
                                    {
                                        overflow: PriceParam === "price" ? "auto" : "hidden"
                                    }
                                ]}
                            >
                                <Grid container xs={6} gap="10px" display="flex" flexDirection="column">
                                    <Grid item xs={6} sx={{textAlign: "left", marginTop: "16px"}}>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                color: "#00AB3A",
                                                fontSize: "0.8125rem",
                                                width: "168px",
                                                height: "36px",
                                                fontWeight: 600,
                                                border: "1px solid #00AB3A"
                                            }}
                                            onClick={() => {
                                                handleOrderClick(quoteListData?.order?.order_template_id);
                                            }}
                                        >
                                            Use template
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} px={0}>
                                        <Typography variant="subDetail1" fontSize="1.42rem" color="#000" fontWeight={600}>
                                            {ellipsizeText(quoteListData?.order?.order_template_name)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid sx={{mb: "6px", display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                                    <Box sx={{width: "126px", height: "54px", position: "relative", right: 14, top: 10}}>
                                        {quoteListData?.order?.brand?.brand_logo ? (
                                            <Box>
                                                <img
                                                    src={quoteListData?.order?.brand?.brand_logo}
                                                    alt={quoteListData?.order?.brand?.brand_name}
                                                    style={styles?.image}
                                                />
                                            </Box>
                                        ) : null}
                                        <Box>
                                            <Typography sx={styles?.title}>
                                                {quoteListData?.order?.brand?.brand_logo ? "" : quoteListData?.order?.brand?.brand_name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{minHeight: "max-content", minWidth: "max-content", mt: "12px"}}>
                                        <Typography fontSize="1.42rem" color="#000" fontWeight={600}>
                                            Bitetime catering template
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Stack>
                        </Typography>
                    </Grid>

                    <Grid container py="12px">
                        <Grid item xs={12} sx={{display: "flex", flexDirection: "column", justifyContent: "center", maxHeight: "210px"}}>
                            <Grid
                                container
                                pb="12px"
                                xs={12}
                                sx={{display: "flex", flexDirection: "row", justifyContent: "center", ml: "3px"}}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    p={0}
                                    sx={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        flexWrap: "wrap"
                                    }}
                                >
                                    <Box sx={{display: "flex", alignItems: "center"}}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: 700,
                                                lineHeight: "14px",
                                                color: "rgba(27, 28, 28, 1)",
                                                minWidth: "77px"
                                            }}
                                        >
                                            BRAND
                                        </Typography>
                                        <Typography sx={{fontSize: "15px", fontWeight: 400, lineHeight: "20px", color: "rgba(0, 0, 0, 1)"}}>
                                            {quoteListData?.order?.brand ? quoteListData?.order?.brand?.brand_name : ""}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sx={{display: "flex"}}>
                                    <Box sx={{display: "flex"}}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: 700,
                                                color: "rgba(27, 28, 28, 1)",
                                                lineHeight: "14px",
                                                minWidth: "77px",
                                                mt: "4px"
                                            }}
                                        >
                                            MENU
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "15px",
                                                fontWeight: 400,
                                                lineHeight: "20px",
                                                color: "rgba(0, 0, 0, 1)",
                                                mt: "2px"
                                            }}
                                        >
                                            {quoteListData?.order?.menu ? `${quoteListData?.order?.menu?.menu_name}` : ""}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    p={0}
                                    sx={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        flexWrap: "wrap"
                                    }}
                                >
                                    <Box sx={{display: "flex", alignItems: "center"}}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: 700,
                                                lineHeight: "14px",
                                                color: "rgba(27, 28, 28, 1)",
                                                minWidth: "77px"
                                            }}
                                        >
                                            GUESTS
                                        </Typography>
                                        <Typography sx={{fontSize: "15px", fontWeight: 400, lineHeight: "20px", color: "rgba(0, 0, 0, 1)"}}>
                                            {new Intl.NumberFormat().format(quoteListData?.order?.no_of_guest)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid
                            container
                            sx={{
                                backgroundColor: "rgba(243, 243, 243, 1)",
                                height: "30.4px",
                                border: "1px solid #f0f0f0",
                                borderRadius: "2.9px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "8.7px 11.6px ",
                                width: "972px"
                            }}
                        >
                            <Grid item xs={1}>
                                <Typography sx={{fontSize: "12px", fontWeight: 700, lineHeight: "14px", color: "rgba(167, 167, 167, 1)"}}>
                                    {" "}
                                    Quantity
                                </Typography>
                            </Grid>
                            <Grid item xs={PriceParam === "price" ? 11 : 7}>
                                <Typography sx={{fontSize: "12px", fontWeight: 700, lineHeight: "14px", color: "rgba(167, 167, 167, 1)"}}>
                                    Items
                                </Typography>
                            </Grid>

                            {PriceParam !== "price" && (
                                <Grid item sx={{display: "flex", justifyContent: "flex-end", textAlign: "end"}} xs={2}>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: 700,
                                            lineHeight: "14px",
                                            color: "rgba(167, 167, 167, 1)"
                                        }}
                                    >
                                        {" "}
                                        Price
                                    </Typography>
                                </Grid>
                            )}

                            {PriceParam !== "price" && (
                                <Grid item sx={{display: "flex", justifyContent: "flex-end", textAlign: "end"}} xs={2}>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: 700,
                                            lineHeight: "14px",
                                            color: "rgba(167, 167, 167, 1)"
                                        }}
                                    >
                                        {" "}
                                        Total
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        {itemDetailSorted?.map((orderItem, index) => (
                            <Grid
                                key={`${orderItem?.item_id},${index}`}
                                container
                                sx={{padding: "8px 12px 8px 0", borderBottom: "1px solid rgba(230, 230, 230, 1)", mt: "6px"}}
                                display="flex"
                                alignItems="center"
                            >
                                {console.log("Order item: ", orderItem)}{" "}
                                <Grid item xs={1} textAlign="center" height="100%">
                                    <Typography
                                        variant="h5"
                                        sx={{fontSize: "14px", fontWeight: 400, lineHeight: "17.5px", color: "rgba(0, 0, 0, 1)"}}
                                    >
                                        {commaFormattedNumber(orderItem?.quantity)}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={PriceParam === "price" ? 5 : 7}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{wordBreak: "break-word"}}
                                    paddingLeft={1.5}
                                >
                                    <Grid item xs={11} display="flex" alignItems="center" height="100%">
                                        <Typography sx={{fontSize: "14px", fontWeight: 600, lineHeight: "17.5px", color: "#000"}}>
                                            {ellipsizeText(orderItem?.item?.item_name)}
                                        </Typography>
                                    </Grid>

                                    {orderItem?.orderItemsModifiers ||
                                        (orderItem?.orderTemplateItemsModifiers && (
                                            <Grid item xs={11}>
                                                <ul style={{padding: "0px 0px 0px 20px", margin: 0}}>
                                                    {orderItem?.orderItemsModifiers ||
                                                        orderItem?.orderTemplateItemsModifiers
                                                            ?.sort((a, b) => {
                                                                if (a.item_modifier_group_series_no !== b.item_modifier_group_series_no) {
                                                                    return (
                                                                        a.item_modifier_group_series_no - b.item_modifier_group_series_no
                                                                    );
                                                                } else {
                                                                    return a.series_no - b.series_no;
                                                                }
                                                            })
                                                            ?.map((item) => {
                                                                return (
                                                                    <li
                                                                        key={`${item?.item_id},${index}`}
                                                                        style={{paddingBottom: "4px", color: "#000"}}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                lineHeight: "18.62px",
                                                                                color: "rgba(0, 0, 0, 1)"
                                                                            }}
                                                                        >
                                                                            {item.modifier_group?.display_name}:&nbsp;
                                                                            {item?.quantity > 0
                                                                                ? `${commaFormattedNumber(item.quantity)} x `
                                                                                : ""}{" "}
                                                                            {item.modifier?.modifier_name} {showDietaryForModifier(item)}{" "}
                                                                        </Typography>
                                                                    </li>
                                                                );
                                                            })}
                                                </ul>
                                            </Grid>
                                        ))}
                                    {orderItem?.item?.customer_note && (
                                        <Grid sx={{display: "flex"}}>
                                            <Typography
                                                sx={{
                                                    color: "rgba(0, 0, 0, 1)",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "18.62px"
                                                }}
                                            >
                                                Includes:&nbsp;
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "rgba(0, 0, 0, 1)",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "18.62px",

                                                    maxWidth: "300px"
                                                }}
                                            >
                                                {truncate(orderItem?.item?.customer_note, {length: 45})}
                                            </Typography>
                                        </Grid>
                                    )}
                                    {orderItem?.special_instruction && (
                                        <Grid item xs={7} paddingTop="8px">
                                            {orderItem?.item?.special_instruction && (
                                                <Box sx={{display: "-webkit-box", flexDirection: "row"}}>
                                                    <Typography sx={{fontSize: "14px", fontWeight: 400, lineHeight: "18.62px"}}>
                                                        Includes:&nbsp;
                                                    </Typography>
                                                    <Typography sx={{fontSize: "14px", fontWeight: 400, lineHeight: "18.62px"}}>
                                                        &nbsp;{orderItem?.item?.special_instruction}
                                                    </Typography>
                                                </Box>
                                            )}
                                            {orderItem?.special_instruction && (
                                                <Box sx={{display: "-webkit-box", flexDirection: "row", marginBottom: "2px"}}>
                                                    <Typography
                                                        sx={{
                                                            color: "rgba(0, 0, 0, 1)",
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            lineHeight: "18.62px"
                                                        }}
                                                    >
                                                        Special instructions:&nbsp;{" "}
                                                        {orderItem?.special_instruction.length > 0 ? orderItem?.special_instruction : ""}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Grid>
                                    )}
                                </Grid>
                                {PriceParam !== "price" && (
                                    <Grid
                                        item
                                        xs={2}
                                        className={styles.titleDivision}
                                        justifyContent="flex-end"
                                        display="flex"
                                        height="100%"
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                lineHeight: "17.5px",
                                                color: "rgba(0, 0, 0, 1)"
                                            }}
                                        >
                                            {FormatCurrency(orderItem?.item?.price)}
                                        </Typography>
                                    </Grid>
                                )}{" "}
                                <Grid item xs={2} className={styles.titleDivision} justifyContent="flex-end" display="flex" height="100%">
                                    <Typography sx={{fontSize: "14px", fontWeight: 700, lineHeight: "17.5px", color: "rgba(0, 0, 0, 1)"}}>
                                        {FormatCurrency(orderItem?.quantity * orderItem?.item?.price)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                        {quoteListData?.order?.tableware_cutlery === "true" || quoteListData?.order?.tableware_plates === true ? (
                            <Grid container padding="8px 12px">
                                <Grid item xs={12} display="flex" justifyContent="space-between">
                                    {" "}
                                    <Typography
                                        variant="h5"
                                        sx={{fontSize: "14px", fontWeight: 600, lineHeight: "17.5px", color: "rgba(0, 0, 0, 1)"}}
                                        paddingLeft="75px"
                                    >
                                        Tableware
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        sx={{fontSize: "14px", fontWeight: 600, lineHeight: "17.5px", color: "rgba(0, 0, 0, 1)"}}
                                    >
                                        INCLUDED
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} paddingTop="6px">
                                    <Typography
                                        variant="h5"
                                        sx={{fontSize: "14px", fontWeight: 400, lineHeight: "18.48px", color: "rgba(0, 0, 0, 1)"}}
                                        paddingLeft="75px"
                                    >
                                        Includes:&nbsp;
                                        {quoteListData?.order?.tableware_plates && "Plates, "}
                                        {!quoteListData?.order?.serving_utensils && "and "}
                                        {quoteListData?.order?.tableware_cutlery &&
                                        quoteListData?.order?.tableware_plates &&
                                        quoteListData?.order?.serving_utensils
                                            ? "cutlery with napkins, "
                                            : quoteListData?.order?.tableware_cutlery && quoteListData?.order?.tableware_plates
                                            ? "cutlery with napkins. "
                                            : "Cutlery with napkins,"}
                                        {(quoteListData?.order?.tableware_plates || quoteListData?.order?.tableware_cutlery) &&
                                            quoteListData?.order?.serving_utensils &&
                                            "and serving utensils"}
                                        {!quoteListData?.order?.tableware_plates &&
                                            !quoteListData?.order?.tableware_cutlery &&
                                            quoteListData?.order?.serving_utensils &&
                                            "Serving utensils"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            ""
                        )}
                        {(quoteListData?.order?.tableware_cutlery === "true" || quoteListData?.order?.tableware_plates === true) && (
                            <hr style={{width: "905px", border: "0.6px solid rgba(230, 230, 230, 1)"}} />
                        )}
                    </Grid>

                    <Grid
                        container
                        padding="8px 0px 6.5px 0px"
                        borderBottom={PriceParam !== "price" ? 0 : 0}
                        sx={{display: "flex", flexDirection: "column"}}
                    >
                        {PriceParam !== "price" && (
                            <Grid container>
                                <Grid item xs={12} sm={6} className={styles.titleDivision}>
                                    <Grid container sx={{display: "flex", flexDirection: "column"}}>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                overflow: "hidden",
                                                display: "flex",
                                                alignItems: "flex-start",
                                                flexDirection: "column",
                                                gap: "3px",
                                                mt: "4px"
                                            }}
                                        >
                                            <Typography
                                                sx={{fontSize: "12px", fontWeight: 700, lineHeight: "14px", color: "#1B1C1C"}}
                                                paddingLeft={0}
                                            >
                                                SPECIAL INSTRUCTIONS
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: "15px",
                                                    fontWeight: 400,
                                                    lineHeight: "20px",
                                                    color: "#000",
                                                    maxWidth: "fit-content"
                                                }}
                                            >
                                                {quoteListData?.order?.special_instructions?.length > 1
                                                    ? quoteListData?.order?.special_instructions
                                                    : ""}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container sx={{padding: "2px 0px"}}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        overflow: "hidden",
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                        flexDirection: "column",
                                                        gap: "3px",
                                                        mt: "2px"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: 700,
                                                            lineHeight: "14px",
                                                            color: "#1B1C1C"
                                                        }}
                                                    >
                                                        KITCHEN NOTE
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "15px",
                                                            fontWeight: 400,
                                                            lineHeight: "20px",
                                                            color: "#000",
                                                            maxWidth: "fit-content"
                                                        }}
                                                    >
                                                        {quoteListData?.order?.kitchen_note}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{textAlign: "right", display: "flex", flexDirection: "column"}}>
                                    <Grid container spacing={4} display="flex" justifyContent="flex-end">
                                        <Grid item xs={6} spacing={2}>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                    lineHeight: "20.44px",
                                                    color: "rgba(0, 0, 0, 1)"
                                                }}
                                            >
                                                Order items
                                            </Typography>
                                            {Number(quoteListData?.order?.service_fee_value) > 0 && (
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "20.44px",
                                                        color: "rgba(0, 0, 0, 1)",
                                                        minWidth: "max-content",
                                                        ml: "-70px"
                                                    }}
                                                    style={{paddingBottom: "1.902px"}}
                                                >
                                                    Service fee{" "}
                                                    {quoteListData?.order?.apply_minimum_service_fee
                                                        ? `(Minimum)`
                                                        : quoteListData?.order?.service_fee_unit === "usd"
                                                        ? `($${quoteListData?.order?.service_fee_unit_value} with gratuity included)`
                                                        : `(${quoteListData?.order?.service_fee_unit_value}% with gratuity included)`}
                                                </Typography>
                                            )}{" "}
                                            {Number(quoteListData?.order?.preferred_price) > 0 && (
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "20.44px",
                                                        color: "rgba(0, 0, 0, 1)"
                                                    }}
                                                    style={{paddingBottom: "1.902px"}}
                                                >
                                                    Preferred pricing{" "}
                                                    {quoteListData?.order?.preferred_price_unit_type !== "DISCOUNT" &&
                                                    quoteListData?.order?.preferred_price_unit_type !== "" &&
                                                    quoteListData?.order?.preferred_price_unit_value
                                                        ? `${Math.floor(Number(quoteListData?.order?.preferred_price_unit_value))}%`
                                                        : ""}
                                                </Typography>
                                            )}
                                            {Number(quoteListData?.order?.price_type_value) > 0 &&
                                                quoteListData?.order?.price_type !== "Discount" && (
                                                    <Typography
                                                        variant="h5"
                                                        sx={[styles?.pricing, styles?.color2, styles?.paddingBott]}
                                                        style={{paddingBottom: "1.902px"}}
                                                    >
                                                        {quoteListData?.order?.price_type && quoteListData?.order?.price_type}{" "}
                                                        {quoteListData?.order?.price_type_unit === "percent" &&
                                                            quoteListData?.order?.discount_or_fee_unit_value}
                                                        {quoteListData?.order?.price_type_unit === "percent" && "%"}{" "}
                                                        {quoteListData?.order?.discount_or_fee_description
                                                            ? `(${ellipsizeText(quoteListData?.order?.discount_or_fee_description, 25)})`
                                                            : null}
                                                    </Typography>
                                                )}
                                            {quoteListData?.order?.discount_or_fee_unit_value > 0 && (
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "20.44px",
                                                        color: "rgba(0, 0, 0, 1)"
                                                    }}
                                                >
                                                    Discount amount&nbsp;
                                                    {quoteListData?.order?.discount_or_fee_unit_value
                                                        ? `${quoteListData?.order?.discount_or_fee_unit_value}${
                                                              quoteListData?.order?.price_type_unit === "percent" && "%"
                                                          }`
                                                        : null}
                                                    {quoteListData?.order?.discount_or_fee_description !== "" &&
                                                        quoteListData?.order?.discount_or_fee_description !== null &&
                                                        `(${ellipsizeText(quoteListData?.order?.discount_or_fee_description, 25)})`}
                                                </Typography>
                                            )}
                                            {/* <Typography
                                                variant="h5"
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "20.44px",
                                                    color: "rgba(0, 0, 0, 1)"
                                                }}
                                                style={{paddingBottom: "1.902px"}}
                                            >
                                                Sales tax {handleSalesTaxString()}
                                            </Typography> */}
                                            {quoteListData?.order?.advance_deposit_percent &&
                                                quoteListData?.order?.advance_deposit_amount > 0 &&
                                                (quoteListData?.order?.status === "Cancelled" ||
                                                    quoteListData?.order?.status === "Rejected") && (
                                                    <Typography
                                                        variant="h5"
                                                        color="rgba(0, 0, 0, 1)"
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            lineHeight: "20.44px",
                                                            color: "rgba(0, 0, 0, 1)"
                                                        }}
                                                    >
                                                        Advance payment
                                                    </Typography>
                                                )}
                                            <Typography
                                                variant="h5"
                                                color={quoteListData?.order?.status === "Cancelled" ? "#E10000" : "#00ab3a"}
                                                sx={{fontSize: "14px", fontWeight: 700, lineHeight: "20.44px"}}
                                            >
                                                Total
                                            </Typography>
                                            <>
                                                {" "}
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "20.44px",
                                                        color: "rgba(0, 0, 0, 1)"
                                                    }}
                                                >
                                                    Per person
                                                </Typography>
                                            </>
                                            {(quoteListData?.order?.status === "Cancelled" ||
                                                quoteListData?.order?.status === "Rejected") &&
                                                quoteListData?.order?.charge_fee_amount !== null && (
                                                    <Typography
                                                        color="#E10000"
                                                        sx={{fontSize: "14px", fontWeight: 700, lineHeight: "20.44px"}}
                                                    >
                                                        Cancelation fee
                                                    </Typography>
                                                )}{" "}
                                            {(quoteListData?.order?.is_full_refunded || quoteListData?.order?.is_partial_refunded) && (
                                                <Typography
                                                    variant="h5"
                                                    color="rgba(25, 185, 61, 1)"
                                                    sx={{fontSize: "14px", fontWeight: 700, lineHeight: "20.44px"}}
                                                >
                                                    Refund amount
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={2.8} pr="12px" display="flex" flexDirection="column" alignItems="flex-end">
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                    lineHeight: "20.44px",
                                                    color: "rgba(0, 0, 0, 1)"
                                                }}
                                            >
                                                {/* {FormatCurrency(Number(subTotal).toFixed(2))} */}${calculateSubtotal()}
                                            </Typography>
                                            {Number(quoteListData?.order?.service_fee_value) > 0 && (
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "20.44px",
                                                        color: "rgba(0, 0, 0, 1)"
                                                    }}
                                                >
                                                    {FormatCurrency(Number(quoteListData?.order?.service_fee_value).toFixed(2))}
                                                </Typography>
                                            )}
                                            {Number(quoteListData?.order?.preferred_price) > 0 && (
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "20.44px",
                                                        color: "rgba(0, 0, 0, 1)"
                                                    }}
                                                >
                                                    {Number(quoteListData?.order?.preferred_price) > 0 && "-"}
                                                    {FormatCurrency(quoteListData?.order?.preferred_price)}
                                                </Typography>
                                            )}

                                            {Number(quoteListData?.order?.price_type_value) > 0 &&
                                                quoteListData?.order?.price_type !== "Discount" && (
                                                    <Typography
                                                        variant="h5"
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            lineHeight: "20.44px",
                                                            color: "rgba(0, 0, 0, 1)"
                                                        }}
                                                    >
                                                        {quoteListData?.order?.price_type === "Discount" &&
                                                            Number(quoteListData?.order?.price_type_value) > 0 &&
                                                            "-"}{" "}
                                                        {FormatCurrency(Number(quoteListData?.order?.price_type_value))}
                                                    </Typography>
                                                )}
                                            {quoteListData?.order?.discount_or_fee_unit_value > 0 && (
                                                <Typography variant="h5" sx={{fontSize: "14px", fontWeight: 400, lineHeight: "20.44px"}}>
                                                    -{FormatCurrency(quoteListData?.order?.price_type_value)}
                                                </Typography>
                                            )}
                                            {/* <Typography
                                                variant="h5"
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "20.44px",
                                                    color: "rgba(0, 0, 0, 1)",
                                                    mt: "1px"
                                                }}
                                            >
                                                {FormatCurrency(
                                                    quoteListData?.order?.tax_exempt ? "0.00" : quoteListData?.order?.sales_tax
                                                )}
                                            </Typography> */}
                                            {quoteListData?.order?.advance_deposit_percent &&
                                                quoteListData?.order?.advance_deposit_amount > 0 &&
                                                (quoteListData?.order?.status === "Cancelled" ||
                                                    quoteListData?.order?.status === "Rejected") && (
                                                    <Typography
                                                        variant="h5"
                                                        color="#00ab3a"
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            lineHeight: "20.44px",
                                                            color: "rgba(0, 0, 0, 1)"
                                                        }}
                                                    >
                                                        {FormatCurrency(quoteListData?.order?.advance_deposit_amount)}
                                                    </Typography>
                                                )}
                                            <Typography
                                                variant="h5"
                                                color={quoteListData?.order?.status === "Cancelled" ? "#E10000" : "#00ab3a"}
                                                sx={{
                                                    textDecoration:
                                                        quoteListData?.order?.status === "Cancelled" ||
                                                        quoteListData?.order?.status === "Rejected"
                                                            ? "line-through"
                                                            : "none",
                                                    ...styles?.pricing,
                                                    fontWeight: 700,
                                                    fontSize: "14px",
                                                    lineHeight: "20.44px"
                                                }}
                                            >
                                                {FormatCurrency(quoteListData?.order?.total_price)}
                                            </Typography>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "20.44px",
                                                    color: "rgba(0, 0, 0, 1)"
                                                }}
                                            >
                                                {quoteListData?.order?.no_of_guest > 0
                                                    ? FormatCurrency(
                                                          parseFloat(quoteListData?.order?.total_price) /
                                                              parseFloat(quoteListData?.order?.no_of_guest)
                                                      )
                                                    : FormatCurrency(0)}
                                            </Typography>

                                            {(quoteListData?.order?.status === "Cancelled" ||
                                                quoteListData?.order?.status === "Rejected") &&
                                                quoteListData?.order?.charge_fee_amount !== null && (
                                                    <Typography
                                                        sx={{fontSize: "14px", fontWeight: 700, lineHeight: "20.44px"}}
                                                        color="#E10000"
                                                    >
                                                        {FormatCurrency(parseFloat(quoteListData?.order?.charge_fee_amount))}
                                                    </Typography>
                                                )}
                                            {(quoteListData?.order?.is_full_refunded || quoteListData?.order?.is_partial_refunded) && (
                                                <Typography
                                                    variant="h5"
                                                    sx={{fontSize: "14px", fontWeight: 700, lineHeight: "20.44px", marginTop: "1px"}}
                                                    color="rgba(25, 185, 61, 1)"
                                                >
                                                    {FormatCurrency(
                                                        quoteListData?.order?.amount_charge -
                                                            quoteListData?.order?.remaining_amount_for_refund
                                                    )}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                    {isPartialAmountPaid() &&
                                        quoteListData?.order?.status !== "Cancelled" &&
                                        quoteListData?.order?.status !== "Rejected" && (
                                            <Grid container display="flex" justifyContent="flex-end" pb="8px">
                                                <Grid item xs={5} sx={[styles?.backPrice, {minWidth: "100px", padding: "4px 8px 4px 0px"}]}>
                                                    {isPartialAmountPaid() && (
                                                        <>
                                                            <Typography
                                                                variant="h5"
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "20.44px",
                                                                    color: "rgba(0, 0, 0, 1)"
                                                                }}
                                                            >
                                                                Advance payment
                                                            </Typography>

                                                            <Typography
                                                                variant="h5"
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "20.44px",
                                                                    color: "rgba(0, 0, 0, 1)"
                                                                }}
                                                            >
                                                                Remaining due
                                                            </Typography>
                                                        </>
                                                    )}
                                                </Grid>
                                                <Grid item xs={2.8} sx={[styles?.backPrice, {padding: "4px 12px 4px 32px"}]}>
                                                    {isPartialAmountPaid() && (
                                                        <>
                                                            <Typography variant="h5" sx={{paddingBottom: "1.902px", ...styles?.detail}}>
                                                                {partialAmountPaid()}
                                                            </Typography>
                                                            <Typography variant="h5" sx={{paddingBottom: "1.902px", ...styles?.detail}}>
                                                                {FormatCurrency(quoteListData?.order?.remaining_amount_to_be_paid)}
                                                            </Typography>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        )}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mt: "10px"}}>
                        <img src={logo} alt="logo" height="19px" width="106px" />
                        <Typography sx={{fontSize: "14px", fontWeight: 400, lineHeight: "21px", color: "rgba(0, 10, 18, 1)", pr: "12px"}}>
                            For help with your order, contact us at 1.844.986.2483 or hello@bitetime.com
                        </Typography>
                    </Box>
                </Box>
            ) : null}
        </Grid>
    );
}

export default PrintOrder;
