import {API, NetworkManager} from "network/core";
import {useNavigate} from "react-router-dom";
import {dispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";

const useCreateLocationModal = (props) => {
    const {setLoading} = props;

    const navigation = useNavigate();

    const getAllMarkets = async (pageNumber = 1) => {
        let data;
        const params = `?page=${pageNumber}&pageSize=5000&sortBy=market_name&orderBy=asc&is_active=true`;
        const instance = NetworkManager(API.MARKET.GET);
        const response = await instance.request({}, [params]);

        if (response.success) {
            data = response.data;
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        return data;
    };

    const getAllBrands = async (pageNumber = 1) => {
        let data;
        const params = `?page=${pageNumber}&pageSize=5000&sortBy=brand_name&orderBy=asc&is_active=true`;
        const instance = NetworkManager(API.BRAND.GET);
        const response = await instance.request({}, [params]);

        if (response.success) {
            data = response.data;
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        return data;
    };

    const getLocationDataByID = async (id) => {
        let data;
        setLoading(true);
        const instance = NetworkManager(API.LOCATION.GETBYID(id));
        const params = "?page=1&pageSize=10&sortBy=name&orderBy=ASC";
        const response = await instance.request({}, [params]);

        if (response.success) {
            data = response.data;
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    transition: "Fade",
                    close: true
                })
            );
        }
        setLoading(false);
        return data;
    };

    const handleFormSubmission = async (payload, setSubmitting) => {
        setLoading(true);
        const instance = NetworkManager(API.LOCATION.ADD);
        const response = await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    transition: "Fade",
                    close: true
                })
            );
             navigation("/locations");
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    transition: "Fade",
                    close: true
                })
            );
        }
         setSubmitting(false);
        setLoading(false);
    };

    const handleEditFormSubmission = async (id, payload, setSubmitting) => {
        setLoading(true);
        const instance = NetworkManager(API.LOCATION.UPDATE(id));
        const response = await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    transition: "Fade",
                    close: true
                })
            );
            navigation("/locations");
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    transition: "Fade",
                    close: true
                })
            );
        }
        setSubmitting(false);
        setLoading(false);
    };

    return {
        getAllMarkets,
        getAllBrands,
        handleFormSubmission,
        handleEditFormSubmission,
        getLocationDataByID
    };
};

export default useCreateLocationModal;
