import {Grid, Typography} from "@mui/material";
import React, {useState, useEffect} from "react";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import CustomizedTabs from "commonComponent/NewTab";
import useCustomerDetailController from "./customerDetail.controlller";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import {CsvBuilder} from "filefy";
import Fields from "commonComponent/Apptable/getFieldsForDownload";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import FilterChips from "commonComponent/FilterChipsComponent";
import Filter from "commonComponent/FilterAccoridan";

const CustomerDetail = () => {
    const CC = useCustomerDetailController();
    const prm = useParams();

    const dispatch = useDispatch();
    const customerId = prm?.type;
    const [searchParams] = useSearchParams();

    const location = useLocation();
    const tabname = searchParams.get("tab");
    const edit = searchParams.get("edit");
    const customerName = searchParams.get("customerName");
    const showSearchandFilter = ["orders", "notes", "profile", "preferred_pricing", "houseAccount"];
    const showButton = ["profile", "preferred_pricing", "houseAccount"];
    const breadScrumsData = [{label: "Customers", href: "/customers?tab=customer"}];
    const labelData = [
        {
            name: "View profile",
            editName: "Edit profile",
            url: "profile"
        },
        {
            name: "View preferred pricing",
            editName: "Edit preferred pricing",
            url: "preferred_pricing"
        },
        {
            name: "Orders list",
            url: "orders"
            // editName: "Orders"
        },
        {
            name: "Notes list",
            url: "notes"
            // editName: "Notes"
        },
        {
            name: "View house account",
            url: "houseAccount",
            editName: "Edit house account"
        }
    ];
    const currentLabel = labelData.find((item) => item.url === tabname);
    if (edit !== null && currentLabel) {
        if (currentLabel.name === "Orders list" || currentLabel.name === "Notes list") {
            // breadScrumsData = [];
        } else {
            breadScrumsData.push({
                label: currentLabel.name,
                href: `/customers/${customerId}/?tab=${currentLabel.url}&customerName=${customerName}`
            });
        }
    }

    if (tabname) {
        if (currentLabel.name === "Orders list" || currentLabel.name === "Notes list") {
            breadScrumsData.push({label: currentLabel.name, isActive: true});
        } else {
            breadScrumsData.push({label: edit ? currentLabel.editName : currentLabel.name, isActive: true});
        }
    }

    useEffect(() => {
        CC.getMarketList();
        CC.getBrandList();
    }, []);

    const handleDeleteBulk = async () => {
        const instance = NetworkManager(API.ORDER.BULKDELETE);
        const payload = CC.selectedCheckbox.map((row) => {
            const list = JSON.parse(row);
            return list.order_id;
        });

        const response = await instance.request({orderIds: payload.join(",")});
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            CC.setSelectedCheckbox([]);
            CC.setUpdateListCount((pre) => pre + 1);
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        CC.setSelectedCheckbox([]);
    };

    const handleNotesDeleteBulk = async () => {
        const instance = NetworkManager(API.CUSTOMER.NOTESBULKDELETE);
        const payload = CC.selectedCheckbox.map((row) => {
            const list = JSON.parse(row);
            return list.customer_note_id;
        });

        const response = await instance.request({customerNoteIds: payload.join(",")});
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            CC.setSelectedCheckbox([]);
            CC.setUpdateListCount((pre) => pre + 1);
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        CC.setSelectedCheckbox([]);
    };

    const columnData = [
        {key: "order_number", label: "Order #"},
        {key: "created_at", label: "Created"},
        {key: "delivery_date", label: "Delivery date"},
        {key: "customer", label: "Customer"},
        {key: "delivery_address", label: "Address"},
        {key: "service_type", label: "Service"},
        {key: "kitchen_location.market.market_name", label: "Market"},
        {key: "brand.brand_name", label: "Brand"},
        {key: "total_price", label: "Total"},
        {key: "order.status", label: "Status"}
    ];

    const columnDataNotes = [
        {key: "note", label: "Note"},
        {key: "creator.first_name", label: "Created by"},
        {key: "createdAt", label: "Created"},
        {key: "action", label: "Action"}
    ];

    const csvBuilder = () => {
        const currentColumnData = tabname === "notes" ? columnDataNotes : columnData;

        new CsvBuilder("order_list.csv")
            .setColumns(currentColumnData.map((col) => col.label))
            .addRows(
                CC.selectedCheckbox.map((row) =>
                    currentColumnData.map((col) => {
                        const arr = JSON.parse(row);
                        return Fields(col.key, arr);
                    })
                )
            )
            .exportFile();
        CC.setSelectedCheckbox([]);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TableTopFilterWrapper
                        onlyHeading={!showSearchandFilter.includes(tabname)}
                        {...(edit !== "true" && {onlyBtn: showButton.includes(tabname)})}
                        {...(currentLabel?.url === "preferred_pricing" &&
                            edit === "true" && {
                                onlyHeading: true
                            })}
                        {...(currentLabel?.url === "profile" &&
                            edit === "true" && {
                                onlyHeading: true
                            })}
                        {...(currentLabel?.url === "houseAccount" &&
                            edit === "true" && {
                                onlyHeading: true
                            })}
                        lessmarginOnTop
                        pageheader={
                            <PageHeader
                                title={CC.headerName}
                                {...(CC.value && {
                                    breadsCrumData: breadScrumsData
                                })}
                            />
                        }
                        btnTitle={CC.label}
                        handleClick={CC.handleCreateLocation}
                        handleSearch={CC.handleSearch}
                        filterType="orderFilter"
                        orderFilter="orderFilter"
                        selectedCheckbox={CC.selectedCheckbox}
                        handleDeleteBulk={
                            tabname === "notes" && WithPermission({module: "Customers", permission: "delete_permission"})
                                ? handleNotesDeleteBulk
                                : WithPermission({module: "Orders", permission: "delete_permission"})
                                ? handleDeleteBulk
                                : null
                        }
                        csvBuilder={csvBuilder}
                    >
                        <Filter
                            filterState={CC.getFilterState(CC.value)}
                            // setFilterState={CC.setFilterChips}
                            filterChips={CC.filterChips}
                            showFilter={CC.showFilter}
                            isChild
                            // setSearchedValue={setSearchedValue}
                        />

                        <FilterChips
                            filterChips={CC.filterChips}
                            // setFilterChips={CC.setFilterChips}
                            filterState={CC.getFilterState(CC.value)}
                            isChild
                        />

                        <CustomizedTabs tabsInfo={CC.dummyData} value={CC.value} handleChange={CC.handleChange} />
                    </TableTopFilterWrapper>
                </Grid>
            </Grid>
        </>
    );
};

export default CustomerDetail;
