import React, { useState, useEffect, useMemo } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { getItemStyle, getAnswerListStyle } from "./utils";
import { IconGripVertical } from "@tabler/icons";
import { Box, Grid, Typography } from "@mui/material";

import { Form, Formik } from "formik";
import * as yup from "yup";
import ActionButton from "commonComponent/Apptable/ActionButton";
import PlusIcon from "assets/images/icons/plus.png";
import SearchableDropdown from "commonComponent/Form/Fields/SearchableDropdown";
import { NetworkManager, API } from "network/core";
import { ellipsizeText } from "commonComponent/Apptable/textUtils";
import { useParams } from "react-router-dom";

const schema = {
    item: [],
    description: ""
};

const dietaryMap = {
    VEGETARIAN: "V ",
    VEGAN: "VG ",
    "GLUTEN-FREE": "GF ",
    "DAIRY-FREE": "DF ",
    SPICY: "S "
};

const dietarySequence = ["VEGETARIAN", "VEGAN", "GLUTEN-FREE", "DAIRY-FREE", "SPICY"];

const sortAndMapDietary = (arr1) => {
    // Create a copy of arr1 to avoid modifying the original array
    const sortedArr = [...arr1];

    // Sort the array based on the index in dietarySequence
    sortedArr.sort((a, b) => dietarySequence.indexOf(a.dietary) - dietarySequence.indexOf(b.dietary));

    // Map each value using dietaryMap
    const mappedArr = sortedArr.map((obj) => dietaryMap[obj.dietary]);

    // Return the resulting array as in the original format
    return mappedArr;
};

const Answers = (props) => {
    const {
        question,
        questionNum,
        isEdit,
        handleCreateitem,
        handleCategoryItemDelete,
        handleCategoryItemDuplication,
        existingItemList,
        getItemById,
        getItemListUnassigned,
        categorlyItemlist,
        setCategoryItemList
    } = props;
    const [row, setRow] = useState(null);
    const [itemValue, setItemValue] = useState(null);
    const [initialValues, setInitialValues] = useState(schema);
    const params = useParams();
    const menuId = params?.menuid;
    useEffect(() => {
        const temp = JSON.parse(JSON.stringify(initialValues));
        temp.description = question.description;
        setInitialValues(temp);
    }, []);

    const itemDuplication = async (row, questionNum, ind) => {
        console.log("ADD EXITING");
        const instance = NetworkManager(API.ITEM.DUPLICATE(row.item_id));
        const response = await instance.request();
        if (response.success) {
            const tempRow = JSON.parse(JSON.stringify(row));
            tempRow.item_id = response?.data?.item?.item_id;
            tempRow.item_name = response?.data?.item?.item_name;
            handleCategoryItemDuplication(tempRow, questionNum, ind);
        }
    };

    const handleActionBtn = (type, row, handleClose, ind) => (e) => {
        handleClose();
        switch (type) {
            case "Edit": {
                handleCreateitem("Edit item", questionNum, question.id, ind, row)(e);

                break;
            }
            case "Remove": {
                handleCategoryItemDelete(questionNum, ind);
                break;
            }
            case "Duplicate": {
                itemDuplication(row, questionNum, ind);

                break;
            }
            case "Activate": {
                break;
            }
            default:
                break;
        }
    };

    const DragItem = ({ question, questionNum, isEdit, handleActionBtn }) => {

        return useMemo(() => {
            return (
                <>
                    {question.item.map((answer, index) => (
                        <Draggable
                            key={`${questionNum}${index}${answer.price}${answer.item_name}`}
                            draggableId={`${questionNum}${index}`}
                            index={index}
                        >
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                >
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                        <Box
                                            sx={{
                                                fontSize: "15px",
                                                fontWeight: 500,
                                                display: "flex",
                                                flexDirection: "row-reverse",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            {answer.item_name} &nbsp;&nbsp;{" "}
                                            {sortAndMapDietary(answer.item_dietary)?.map((item) => item)}
                                            <span {...provided.dragHandleProps}>
                                                <IconGripVertical
                                                    icon="grip-vertical"
                                                    itemDietary
                                                    style={{ float: "left", color: "#E0E2E7", marginRight: "15px" }}
                                                />
                                            </span>
                                        </Box>
                                        <Box sx={{ display: "flex", fontSize: "15px", fontWeight: 500, alignItems: "center" }}>
                                            ${parseFloat(answer.price).toFixed(2)}
                                            {isEdit && (
                                                <ActionButton
                                                    row={answer}
                                                    ind={index}
                                                    ActionArray={["Edit", "Duplicate", "Remove"]}
                                                    handleActionBtn={handleActionBtn}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </div>
                            )}
                        </Draggable>
                    ))}
                </>
            )

        }, [question, questionNum, isEdit, handleActionBtn])
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={yup.object({
                item: yup.array().min(1, "Atleast 1 item should be added").required("Atleast 1 item should be added"),
                description: yup.string().required("Description is required")
            })}
            onSubmit={(val) => {
                console.log("");
            }}
        >
            {(formik) => (
                <Form>
                    <Droppable droppableId={`droppable${question.id}`} type={`${questionNum}`}>
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} style={getAnswerListStyle(snapshot.isDraggingOver)}>
                                <Grid item xs={12}>
                                    <Box sx={{ marginBottom: "20px" }}>
                                        <Typography sx={{ fontSize: 16, marginLeft: "5px", color: (theme) => theme.palette.secondary.main }}>
                                            {ellipsizeText(formik.values.description)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                {/* {question.item.map((answer, index) => (
                                    <Draggable
                                        key={`${questionNum}${index}${answer.price}${answer.item_name}`}
                                        draggableId={`${questionNum}${index}`}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                <Box sx={{ display: "flex", justifyContent: "space-between" }} border="1px solid green">
                                                    <Box
                                                        sx={{
                                                            fontSize: "15px",
                                                            fontWeight: 500,
                                                            display: "flex",
                                                            flexDirection: "row-reverse",
                                                            justifyContent: "center",
                                                            alignItems: "center"
                                                        }}
                                                    >
                                                        {answer.item_name} &nbsp;&nbsp;{" "}
                                                        {sortAndMapDietary(answer.item_dietary)?.map((item) => item)}
                                                        <span {...provided.dragHandleProps}>
                                                            <IconGripVertical
                                                                icon="grip-vertical"
                                                                itemDietary
                                                                style={{ float: "left", color: "#E0E2E7", marginRight: "15px" }}
                                                            />
                                                        </span>
                                                    </Box>
                                                    <Box sx={{ display: "flex", fontSize: "15px", fontWeight: 500, alignItems: "center" }}>
                                                        ${parseFloat(answer.price).toFixed(2)}
                                                        {isEdit && (
                                                            <ActionButton
                                                                row={answer}
                                                                ind={index}
                                                                ActionArray={["Edit", "Duplicate", "Remove"]}
                                                                handleActionBtn={handleActionBtn}
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>
                                            </div>
                                        )}
                                    </Draggable>
                                ))} */}
                                <DragItem questionNum={questionNum} question={question} isEdit={isEdit} handleActionBtn={handleActionBtn} />
                                {provided.placeholder}
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginTop: "10px"
                                    }}
                                >
                                    <SearchableDropdown
                                        disabled={!isEdit}
                                        value={itemValue}
                                        label="Add existing item"
                                        menuItems={categorlyItemlist}
                                        sx={{ fontSize: "0.70em" }}
                                        onBlur={() => setCategoryItemList([])}
                                        onChange={(e, value) => {
                                            setItemValue(value);
                                            getItemById(value.id, questionNum, question.id);
                                            setCategoryItemList([])
                                        }}
                                        onClick={() => getItemListUnassigned(menuId, question?.id)}
                                    />
                                    <Box
                                        sx={{
                                            minWidth: 100,
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            cursor: isEdit ? "pointer" : "normal"
                                        }}
                                        onClick={(e) => {
                                            if (isEdit) {
                                                handleCreateitem("Create item", questionNum, question.id)(e);
                                            }
                                        }}
                                    >
                                        <img src={PlusIcon} alt="plus-icon" width="15px" height="15px" />
                                        <Typography
                                            sx={{
                                                fontSize: "0.875rem",
                                                marginLeft: "5px",
                                                fontWeight: 500,
                                                color: (theme) => theme.palette.primary.main
                                            }}
                                        >
                                            Add item
                                        </Typography>
                                    </Box>
                                </Box>
                            </div>
                        )}
                    </Droppable>
                </Form>
            )}
        </Formik>
    );
};

export default Answers;
