import { Dates } from "utils/app-dates/dates"

export const CookieKeys = {
    Auth: `${process.env.REACT_APP_APP_ENV}:Auth-Token`,
    API_TOKEN: `${process.env.REACT_APP_APP_ENV}:api-key`,
    REFRESH_TOKEN: `${process.env.REACT_APP_APP_ENV}:Refresh-Token`,
    DEVICE_TOKEN: 'Device-Token'
}

export const CookieOptions = {
    expires: Dates().addInCurrent(7, "days")._d,
    // expires: Dates().addInCurrent(7, "days"),
    sameSite: "strict",
    path: "/",
    ...(window !== undefined && {
        secure: window.location.protocol === "https:"
    })
}
