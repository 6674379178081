import {Grid, Typography} from "@mui/material";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";
import BreadsCrums from "commonComponent/BreadScrums/BreadsCrums";
import SearchSection from "commonComponent/SearchSection";
import React, {useEffect} from "react";

const PageHeader = (props) => {
    const {title, showOnlyTitle = false, breadsCrumData, fontSize} = props;
     
    return (
        <>
            <Grid container>
                {!showOnlyTitle && breadsCrumData && (
                    <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <BreadsCrums data={breadsCrumData} />
                        </Grid>
                    </Grid>
                )}
                {title !=='null' ? (
                    <Grid item xs={12} sx={{my: 1}}>
                        <Typography
                            fontSize="1.875rem"
                            lineHeight="1.63rem"
                            sx={{wordBreak: "break-all", color: "#000000", fontWeight: 700, ...(fontSize && {fontSize: fontSize})}}
                        >
                            {title}
                        </Typography>
                    </Grid>
                ):null}
            </Grid>
        </>
    );
};

export default PageHeader;
