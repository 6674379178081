import {Button, Dialog, DialogActions, Box, DialogContent, DialogContentText, DialogTitle, Slide, Typography, Grid} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import React, {useEffect, useState} from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Checked from "assets/images/icons/lightCheckedBox.svg";
import Unchecked from "assets/images/icons/unchecked.svg";
import Cancel from "assets/images/icons/cancel.svg";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import {ErrorMessage} from "formik";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const PopUp = (props) => {
    const {
        title,
        description,
        open,
        handleClose,
        handleSubmit,
        hideBackdrop = false,
        noOnClose = false,
        selectedCheckboxes,
        handleCheckboxChange,
        checkboxLabels,
        reviewPopup,
        menuList,
        selectedMenus,
        setSelectedMenus
    } = props;

    console.log(selectedCheckboxes?.includes("Menu page"), "++++++++");

    // useEffect(() => {
    //     return () => {
    //         setSelectedMenus([]);
    //     };
    // }, []);

    return (
        <Dialog
            open={open}
            {...(!noOnClose && {onClose: handleClose})}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            hideBackdrop={hideBackdrop}
            PaperProps={{
                sx: {
                    boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.09)",
                    borderRadius: "4px",
                    padding: "35px 67px",
                    border: "1px solid #F0F0F0"
                }
            }}
        >
            <Box sx={{position: "absolute", right: "16px", top: "16px", cursor: "pointer"}} onClick={handleClose}>
                <img src={Cancel} alt="plus-icon" width="12px" height="12px" />
            </Box>
            <DialogTitle
                id="alert-dialog-title"
                sx={{marginTop: "-30px", letterSpacing: "0.001px", fontSize: "1.25rem", fontWeight: 700, textAlign: "center"}}
            >
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    sx={{marginTop: "-5px", fontSize: "0.938rem", color: "#000A12", fontWeight: 400, textAlign: "center"}}
                >
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogContent sx={{paddingTop: "0px"}}>
                <FormGroup>
                    {checkboxLabels.map((label) => {
                        return (
                            <Grid container alignItems="center">
                                <FormControlLabel
                                    key={label}
                                    control={
                                        <Checkbox
                                            icon={<img src={Unchecked} style={{width: "18px", height: "18px"}} alt="unchecked" />}
                                            checkedIcon={<img src={Checked} alt="checked" style={{width: "18px", height: "18px"}} />}
                                            sx={{padding: "4px"}}
                                            checked={selectedCheckboxes.includes(label)}
                                            onChange={() => handleCheckboxChange(label)}
                                        />
                                    }
                                />
                                <Typography
                                    onClick={() => handleCheckboxChange(label)}
                                    sx={{
                                        marginLeft: "-10px",
                                        fontSize: "0.938rem",
                                        display: "inline",
                                        cursor: "pointer",
                                        fontWeight: selectedCheckboxes.includes(label) ? 600 : 400
                                    }}
                                >
                                    {label}
                                </Typography>
                            </Grid>
                        );
                    })}
                    {reviewPopup && (
                        <Box container sx={{margin: "1rem -7px", width: "300px"}}>
                            <CustomSelectWithCheckbox
                                selectAll
                                label="Menu"
                                values={selectedMenus}
                                menuItems={menuList}
                                onChange={(val) => {
                                    console.log([...val]);
                                    setSelectedMenus([...val]);
                                }}
                            />
                            {selectedCheckboxes?.includes("Menu page") && !selectedMenus.length && (
                                <CustomErrorMessage errorMessage="Menu is required" />
                            )}
                        </Box>
                    )}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={selectedCheckboxes?.includes("Menu page") ? !selectedMenus.length : false}
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                        color: "#fff",
                        height: "35px",
                        width: "153px",
                        fontSize: "0.875rem",
                        fontWeight: 500
                    }}
                >
                    Update
                </Button>
                <Button
                    variant="outlined"
                    onClick={handleClose}
                    sx={{
                        color: "#F91239",
                        border: "1px solid #F91239",
                        height: "35px",
                        width: "153px",
                        fontSize: "0.875rem",
                        fontWeight: 500,
                        ":hover": {
                            border: "1px solid #F91239"
                        }
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PopUp;
