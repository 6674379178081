import {Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import useNewItemDetailController from "./newItemDetail.controller";
import ItemDetailsSection from "./itemDetails/Itemdetails";
import ModifierGroupSection from "./modifierGroup/ModifierGroup";
import SettingSection from "./settingsection/SettingSection";
import {Grid, Stack, Typography} from "@mui/material";
import CustomSwitch from "commonComponent/Form/Fields/CustomSwitch";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import PhotoSection from "./PhotoSection/PhotoSection";
import useTempDrawer from "hooks/useTempDrawer";
import {NewItemFlowValidation} from "utils/validations/validations";
import Loader from "ui-component/Loader";
import {useNavigate, useSearchParams} from "react-router-dom";
import ItemViewPage from "./ItemViewPage/ItemViewPage";

const NewItemDetail = (props) => {
    const {isEditable, inSideBar, menuid = false, catId = false, handleItemDrawerClose = () => {}, selectedRow = false} = props;

    const navigation = useNavigate();
    const [searchparam] = useSearchParams();
    const menuName = searchparam.get("menuName");
    console.log(navigation);
    const {toggleDrawer} = useTempDrawer();

    const NIDC = useNewItemDetailController(props);
    const {id, type, loading, initialValues, labelList, handleAddItem} = NIDC;
    console.log("Item detail page optionaxasl");

    return (
        <>
            {isEditable === true || isEditable === "true" ? (
                <div>
                    {" "}
                    {loading ? (
                        <Loader />
                    ) : (
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={NewItemFlowValidation}
                            onSubmit={(val, {setSubmitting}) => handleAddItem(val, setSubmitting)}
                        >
                            {(formik) => (
                                <Form>
                                    {console.log(formik, "FORMIK")}
                                    <Grid container spacing={3} item xs={12} {...(!inSideBar && {lg: 6, md: 8, sm: 10})}>
                                        <Grid item xs={12}>
                                            <ItemDetailsSection
                                                isEdit={isEditable}
                                                formik={formik}
                                                labelList={labelList}
                                                menuid={menuid}
                                                catId={catId}
                                                inSideBar={inSideBar}
                                            />
                                        </Grid>
                                        {formik.values.item_type === 1 && (
                                            <>
                                                <Grid item xs={12} mt={2}>
                                                    <PhotoSection isEdit={isEditable} formik={formik} />
                                                </Grid>
                                                <Grid item xs={12} mt={3}>
                                                    <ModifierGroupSection isEdit={isEditable} formik={formik} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SettingSection isEdit={isEditable} formik={formik} />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            mt: 2,
                                            pb: 2,
                                            pt: 1,
                                            ml: "-2px",
                                            width: "100%",
                                            position: "sticky",
                                            bottom: 0,
                                            bgcolor: "#fff",
                                            zIndex: 1
                                        }}
                                    >
                                        <ButtonGrouping
                                            btnprops={[
                                                {
                                                    btnTitle: "Cancel",
                                                    sx: (t) => ({color: t.palette.error.dark}),
                                                    onClick: (e) => {
                                                        if (catId) {
                                                            handleItemDrawerClose();
                                                        }
                                                        if (inSideBar) {
                                                            toggleDrawer("right", false)(e);
                                                        } else {
                                                            // navigation(-2)
                                                            navigation(
                                                                menuName !== "null"
                                                                    ? `/menus/menuDetail/${type}/?tab=menuItems&menuName=${menuName}`
                                                                    : `/menus?tab=menuItems`
                                                            );
                                                        }
                                                    }
                                                },
                                                {
                                                    btnTitle: "Save",
                                                    variant: "contained",
                                                    disabled: formik.isSubmitting,
                                                    sx: (t) => ({color: t.palette.background.paper}),
                                                    onClick: formik.handleSubmit
                                                }
                                            ]}
                                        />
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            ) : (
                <ItemViewPage id={id} selectedRow={selectedRow} />
            )}
        </>
    );
};

export default NewItemDetail;
