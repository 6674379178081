import { Grid, Box, Typography, Button } from "@mui/material";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import DriverModal from "commonComponent/DilogBox/DriverModal";
import { API, NetworkManager } from "network/core";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { dispatch } from "store";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { openSnackbar } from "store/slices/snackbar";
import Loader from "ui-component/Loader";
import { getHHMMWithTimeString, getformateDate, getFormattedTimeinAMPM } from "utils/app-dates/dates";
import { FormatCurrency } from "utils/helperFunctions/helpers";
import { styled } from "@mui/material/styles";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import WithPermission from "commonComponent/RBAC/permissionHoc";

function AssignedDeliveries({ formik, driversData, isUpdated, handleIsUpdated, marketId }) {
    const [openDriverModal, setOpenDriverModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [assigenedDeliveryData, setAssignedDeliveryData] = useState([]);
    const [selectedRow, setSelectedRow] = useState("");
    const [selectedDriver, setSelectedDriver] = useState("");
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = React.useState("panel1");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
        border: `1px solid #fff`,

        "&:not(:last-child)": {
            borderBottom: 0
        },
        "&:before": {
            display: "none"
        }
    }));

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary expandIcon={<KeyboardArrowDownIcon sx={{ fontSize: "2rem", color: "#000000" }} />} {...props} />
    ))(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
        flexDirection: "row-reverse",
        minHeight: "5px",
        height: "2rem",
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(180deg)"
        },
        "& .MuiAccordionSummary-content": {
            marginLeft: theme.spacing(1.0)
        }
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(1.5),
        marginTop: "-0.6rem",
        marginLeft: "34px"
    }));

    const { values } = formik;
    const market = values.market;
    const filterDate = values.date;

    const handleChangeDriverModal = (rowData) => {
        setSelectedRow(rowData);
        setSelectedDriver(rowData?.driver_id);
        setOpenDriverModal((pre) => !pre);
    };
    const handleUnassignDriverModal = (rowData) => {
        setSelectedRow(rowData);
        setOpen((pre) => !pre);
    };

    const getAssignedDeliveries = async () => {
        const instance = NetworkManager(API.DELIVERIES.ASSIGNDRIVERLIST);
        const params = {
            market_id: market,
            date: getformateDate(filterDate, "yyyy-mm-dd")
        };
        const res = await instance.request({}, params);

        if (res.success) {
            const fetchedData = res.data;

            const formatFetchedData = fetchedData.map((el) => ({
                order_id: el?.order_id,
                orderNumber: el?.order_number,
                driver_id: el?.driver?.id,
                carrier_id:el?.carrier_id,
                name: el.brand?.brand_name,
                address: el?.kitchen_location?.address,
                date: el?.delivery_date,
                time: el?.delivery_time,
                customerName: el?.customer?.company?.company_name || el?.customer?.full_name,
                customerAddress: el.delivery_address,
                total: el?.total_price,
                driver: {
                    name: `${el?.driver?.name}`,
                    phone: el?.driver?.phoneNumber
                },
                secondaryDriver: el?.secondaryDriver
            }));
            setAssignedDeliveryData(formatFetchedData);
        }
    };

    const hanldeUnassignDriver = async () => {
        setLoading(true);

        const instance = NetworkManager(API.DELIVERIES.DEASSIGN(selectedRow?.order_id));

        const res = await instance.request();
        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleUnassignDriverModal();
            handleIsUpdated();
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setLoading(false);
    };

    const handleChangeDriver = async (selectedDriver) => {
        setLoading(true);
        const instance = NetworkManager(API.DELIVERIES.CHANGEDRIVER);
        const payload = {
            orderId: selectedRow?.order_id,
            carrierId: `${selectedDriver}`
        };
        const res = await instance.request(payload);

        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleChangeDriverModal();
            setSelectedDriver("");
            handleIsUpdated();
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },

                    close: false
                })
            );
            setSelectedDriver("");
        }
        setLoading(false);
    };

    useEffect(() => {
        if (market && filterDate) {
            getAssignedDeliveries();
        }
    }, [market, filterDate, isUpdated]);

    return (
        <>
            {loading && <Loader />}
            <Box sx={{ marginTop: "32px" }}>
                <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        sx={{
                            backgroundColor: "#fff",
                            width: "209px",
                            "&": {
                                ".MuiAccordionSummary-content": {
                                    alignItems: "center",
                                    marginLeft: 0
                                }
                            }
                        }}
                    >
                        <Typography color="#000A12" sx={{ fontSize: "1.25rem", fontWeight: 700, marginTop: "12.6px", marginBottom: "14px" }}>
                            Assigned ({assigenedDeliveryData.length})
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <div
                            style={{
                                marginLeft: "-25px",
                                minWidth: { xs: "100% !important", lg: "70%" },
                                maxWidth: { xs: "100% !important", lg: "70%" },
                                "@media (min-width: 1536px)": {
                                    minWidth: "777px !important",
                                    maxWidth: "790px !important"
                                }
                            }}
                            className="custom-scrollbar"
                        >
                            {assigenedDeliveryData.map((delivery, index) => (
                                <Grid key={index} container>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            minWidth: { xs: "100% !important" },
                                            maxWidth: { xs: "100% !important" },
                                            border: "1px solid #E0E2E6",
                                            borderRadius: "4px",
                                            background: "#fff",
                                            padding: "26px 30px",
                                            marginTop: "14px",
                                            "@media (min-width: 1536px)": {
                                                minWidth: "778px !important",
                                                maxWidth: "778px !important"
                                            }
                                        }}
                                    >
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Box>
                                                <Typography sx={{ fontSize: "1.18rem", fontWeight: 600, color: "#020A11" }}>
                                                    Order{" "}
                                                    <Link style={{ textDecoration: "none" }} to={`/orders/${delivery.order_id}`}>
                                                        {" "}
                                                        <span style={{ color: "#00ab3a" }}>#{delivery.orderNumber}</span>
                                                    </Link>
                                                    &nbsp;&nbsp; <span style={{ color: "#26272A", opacity: 0.3032 }}> | </span> &nbsp;&nbsp;
                                                    <span>
                                                        {delivery.name}{" "}
                                                        
                                                    </span>
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography sx={{ fontSize: "1.063rem", fontWeight: 600 }}>
                                                    {/* {getHHMMWithTimeString(delivery.time).toLowerCase().split(" ").join("")} */}

                                                    {getFormattedTimeinAMPM(delivery.date).toLowerCase().split(" ").join("")}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Grid container sx={{ marginTop: "18px" }}>
                                            <Grid item xs={7.5} sx={{ borderRight: "1px solid rgba(38, 39, 42, 0.32)" }}>
                                            <span style={{ fontSize: "0.875rem", fontWeight: 500, opacity: 0.4501 }}>
                                                            {delivery.address}
                                                        </span>
                                                <Typography sx={{ fontSize: "0.938rem", fontWeight: 600,color: "#000", marginTop: "4px"   }}>
                                                    {delivery.customerName}
                                                </Typography>
                                                <Typography sx={{ fontSize: "0.938rem", fontWeight: 400, marginTop: "4px" }}>
                                                    {delivery.customerAddress}
                                                </Typography>
                                                <Typography sx={{ fontSize: "0.75rem", fontWeight: 600, marginTop: "15px" }} />
                                                    
                                               
                                                <Typography sx={{ fontSize: "0.938rem", fontWeight: 400, marginTop: "4px" }}>
                                                    {FormatCurrency(delivery.total)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4.5} sx={{ paddingLeft: "24px" }}>
                                                <Box>
                                                    <Typography sx={{ fontSize: "0.75rem", fontWeight: 600 }}>Driver</Typography>
                                                    <Typography sx={{ fontSize: "1.125rem", fontWeight: 700, marginTop: "8px" }}>
                                                        {delivery.driver.name}
                                                    </Typography>
                                                    <Typography sx={{ fontSize: "1rem", fontWeight: 500, marginTop: "6px" }}>
                                                        {delivery.driver.phone}
                                                    </Typography>
                                                    {delivery?.secondaryDriver?.length ? <Typography sx={{ fontSize: "0.75rem", fontWeight: 600 }}> Secondary driver</Typography>:null}

                                                    {delivery?.secondaryDriver?.map((row) => {
                                                        return <>

                                                            <Typography sx={{ fontSize: "0.938rem", fontWeight: 400}}>
                                                                {row?.name}&nbsp; {row?.phoneNumber}
                                                            </Typography>

                                                        </>
                                                    })}

                                                    < Box sx={{ marginTop: "13px" }}>
                                                        {WithPermission({ module: "Orders", permission: ["update_permission"] }) && (
                                                            <>
                                                                <Typography sx={{ fontSize: "0.938rem", fontWeight: 600, color: "#00ab3a" }}>
                                                                    <Button
                                                                        variant="text"
                                                                        sx={{ fontSize: "0.937rem", fontWeight: 600, color: "#00ab3a" }}
                                                                        onClick={() => handleUnassignDriverModal(delivery)}
                                                                    >
                                                                        Unassign
                                                                    </Button>
                                                                    &nbsp;
                                                                    <span style={{ color: "#26272A", opacity: 0.3032 }}> | </span>
                                                                    &nbsp;
                                                                    <Button
                                                                        variant="text"
                                                                        sx={{ fontSize: "0.937rem", fontWeight: 600, color: "#00ab3a" }}
                                                                        onClick={() => handleChangeDriverModal(delivery)}
                                                                    >
                                                                        Change
                                                                    </Button>
                                                                </Typography>{" "}
                                                            </>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                            {assigenedDeliveryData?.length === 0 && (
                                <Box p={1}>
                                    <Typography sx={{ fontSize: "0.75rem", fontWeight: 600, color: "#000" }}>No data available</Typography>
                                </Box>
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
              {openDriverModal &&  <DriverModal
                    driversData={driversData}
                    selectedDriver={selectedDriver}
                    setSelectedDriver={setSelectedDriver}
                    orderId={selectedRow?.order_id}
                    prefillDriverId={selectedRow?.carrier_id}
                    open={openDriverModal}
                    marketId={marketId}
                    buttonTitle="Assign driver"
                    title="Assign a driver"
                    description={`Select a driver to assign to order #${selectedRow?.orderNumber}`}
                    handleClose={handleChangeDriverModal}
                    handleSubmit={(selectedDriver) => handleChangeDriver(selectedDriver)}
                />}
                <DeleteModal
                    buttonTitle="Unassign"
                    open={open}
                    title="Are you sure you want to unassign driver?"
                    handleClose={handleUnassignDriverModal}
                    handleSubmit={hanldeUnassignDriver}
                />
            </Box>
        </>
    );
}

export default AssignedDeliveries;
