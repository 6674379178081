// import React from 'react';
// import { styled, useTheme } from '@mui/material/styles';
// import DateRangePicker from '@wojtekmaj/react-daterange-picker';
// import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
// import 'react-calendar/dist/Calendar.css';
// import { Popover, Typography } from '@mui/material';

import React, { useState } from 'react';
import CalendarTodayIcon from "@mui/icons-material/DateRange";
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


const StyledDateRangePicker = styled(Calendar)(({ theme }) => ({


    '& .react-daterange-picker__wrapper': {
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        border: "none"
    },
    '& .react-calendar__month-view__days__day--weekend': {
        color: theme.palette.text.primary,
    },
    '& .react-calendar__tile': {
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        backgroundColor: '#ffff'
    },

    '& .react-calendar__tile--hover': {
        backgroundColor: theme.palette.action.selected,
    },
    '& .react-calendar__tile--now': {
        backgroundColor: '#ffff',
    },
    '& .react-calendar__tile--now:hover': {
        backgroundColor: '#ffff',
    },

    '& .react-calendar__tile--active:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#ffff !important',
    },
    '& .react-calendar__tile--active': {
        backgroundColor: theme.palette.primary.main,
        color: '#ffff !important',
    },

    '& .react-daterange-picker__inputGroup': {
        padding: '9.5px 14px', // Set padding to input group

    },
    '& .react-daterange-picker__calendar': {
        borderRadius: '4px', // Set border radius for the calendar
    },

    '& .react-daterange-picker__clear-button': {
        display: 'none', // Hide the cross icon button
    },
    '& .react-daterange-picker__calendar-button': {
        color: 'rgba(0, 0, 0, 0.54)', // Set calendar icon color
        opacity: 1
    },
    '& .react-daterange-picker__calendar-button:hover': {
        color: 'rgba(0, 0, 0, 0.54)', // Set calendar icon color
        opacity: 1
    },
    '& .react-daterange-picker__button:hover': {
        color: 'rgba(0, 0, 0, 0.54)', // Set calendar icon color
        opacity: 1
    },
    '& .react-daterange-picker__button:enabled:hover': {
        stroke: 'rgba(0, 0, 0, 0.54)',
    },
    '& .react-daterange-picker__button__icon': {
        stroke: 'rgba(0, 0, 0, 0.54)',
    },
    '& .react-daterange-picker__button:enabled:focus ': {
        stroke: 'rgba(0, 0, 0, 0.54)',
    },
    '& .react-calendar': {
        borderWidth: "thin",
        backgroundColor: "#ffffff",
        color: "#364152",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
        transformOrigin: "top center",
        outline: 0,
    },
    // '& .react-calendar__tile--hover': {
    //     background: theme.palette.action.selected,
    // },
    // '& .react-calendar__tile--now': {
    //     backgroundColor: '#ffff',
    // },
    // '& .react-calendar__tile--now:hover': {
    //     background: theme.palette.action.selected,
    // },

    // '& .react-calendar__tile--active:hover': {
    //     background: '#00ab3a !important',
    //     color: '#ffff !important',
    // },
    // '& .react-calendar__tile--active': {
    //     background: '#00ab3a !important',
    //     color: '#ffff !important',
    // },

    '& .react-calendar__tile--hasActive': {
        background: '#00ab3a !important',
        color: '#ffff !important',
    },

    '& .react-calendar__tile--active:enabled:hover': {
        background: '#00ab3a !important',

    },
    '& .react-calendar__tile--active:enabled:focus': {
        background: '#00ab3a !important',

    },

}));



// color: rgba(0, 0, 0, 0.54)

const StyledInputWrapper = styled('div')(({ theme }) => ({
    padding: '8px 11px',
    borderRadius: '4px',
    border: `1px solid #d9d9d9`,
    display: 'inline-flex',
    alignItems: 'center',
    width:'100%',
    // Style the placeholder of the input element
    '& input':{
        fontSize:'14px',
        color: "#212121",

    },
    '& input::placeholder': {
        opacity: 0.5,

    },

}));

const CustomDateRangePicker = ({
    value,
    onChange,
    minDate = null,
    maxDate = null,
    handleDatesChange,
    handleFocusChange,
    minimumNights = 0,
    startDatePlaceholderText = 'Created from',
    endDatePlaceholderText = 'Created to',
    ...props
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleCalendarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleDateChange = (date) => {
        onChange(date);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <StyledInputWrapper>
                <input
                    type="text"
                    placeholder={startDatePlaceholderText}
                    // value={value[0] ? value[0] : ''}
                    readOnly
                    style={{ border: 'none',width:'100%', marginRight: '8px' }}
                />
                <CalendarTodayIcon onClick={handleCalendarClick} />
            </StyledInputWrapper>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <StyledDateRangePicker
                    onChange={handleDateChange}
                    selectRange
                    value={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    {...props}
                />
            </Popover>
        </div>
    );
};


export default CustomDateRangePicker;
