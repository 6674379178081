import { DrawerContextModifier } from 'contexts/DrawerContextModifier';
import { useContext } from 'react';

// ==============================|| TEMP DRAWER HOOKS ||============================== //

const useTempDrawer = () => {
    const context = useContext(DrawerContextModifier);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};

export default useTempDrawer;
