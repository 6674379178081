import {Box, Menu, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddAddressForm from "./addAddressForm";
import SymbolButton from "commonComponent/Buttons/SymbolButton";
import {ErrorMessage} from "formik";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";

const Address = (props) => {
    const {Parentformik, orderId, kitchenIdRef} = props;

    const {headerData, kitchenList, selectedKitchen, kitchenLocationId} = Parentformik.values;
    // console.log("Parentformik.values :", Parentformik.values);
    kitchenIdRef.current = Parentformik.values?.kitchenLocationId;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openCancelModal, setCancelModal] = useState(false);
    const [dummyLocation, setDummyLocation] = useState(null);
    const [openForm, setOpenForm] = useState(kitchenList?.length === 0);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        if (openForm) {
            setOpenForm(false);
        }
        setAnchorEl(null);
    };

    const handleCancelModal = () => {
        setCancelModal((pre) => !pre);
    };

    const handleAddressSelect = (obj) => {
        Parentformik.setFieldValue("kitchenLocationId", obj?.kitchen_location_id ?? "");
        Parentformik.setFieldValue("selectedKitchen", obj?.kitchen_name ?? "");
        Parentformik.setFieldValue("kitchenLatitude", obj?.kitchen_latitude ?? "");
        Parentformik.setFieldValue("kitchenLongitude", obj?.kitchen_longitude ?? "");
        Parentformik.setFieldValue("marketIDForDiscount", obj?.market?.market_id ?? "");
        Parentformik.setFieldValue("marketNameForDiscount", obj?.market?.market_name ?? "");
        Parentformik.setFieldValue("kitchenMinimumServiceFee", obj?.kitchen_minimum_service_fee ?? 0);
        Parentformik.setFieldValue("addressChanged", true);
        Parentformik.setFieldValue("kitchen_location_name",  obj?.kitchen_name );


        kitchenIdRef.current = obj?.kitchen_location_id;

        const serviceFee = {unitType: "percent", value: JSON.stringify(obj?.kitchen_service_fee ?? 0)};
        const salesTax = {value: JSON.stringify(obj?.kitchen_sales_tax_rate ?? 0)};
        const setuptime = obj?.kitchen_setup_time ?? 0;

        Parentformik.setFieldValue("serviceFees", serviceFee);
        Parentformik.setFieldValue("salesTax", salesTax);
        Parentformik.setFieldValue("setup_time", setuptime.toString());
        setCancelModal(false);
        handleClose();
    };

    const handleCancelOrder = async () => {
        handleAddressSelect(dummyLocation);
    };

    return (
        <>
            <Stack
                nowrap
                sx={{minWidth: 0, cursor: "pointer", flexShrink: 20}}
                spacing={0.5}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                className="muiStack_start"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <Typography variant="OrderSectionHeading" sx={{whiteSpace: "nowrap"}}>
                    From kitchen
                </Typography>

                <Stack direction="row" spacing={1} mt="0px !important" alignItems="center">
                    <Typography noWrap variant="OrderSectionHeading" fontWeight={400}>
                        {selectedKitchen ? ellipsizeText(selectedKitchen, 25) : "Add kitchen"}
                    </Typography>
                    <KeyboardArrowDownIcon fontSize="small" />
                </Stack>
                <ErrorMessage name="kitchenLocationId" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
            </Stack>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
            >
                <Box sx={{padding: "2px 15px"}} pb={0} pt={kitchenList?.length > 0 ? 2 : 0}>
                    <Stack spacing={1}>
                        {kitchenList?.length > 0 &&
                            kitchenList?.map((obj, i) => (
                                <Stack
                                    spacing={0.5}
                                    sx={{cursor: "pointer"}}
                                    key={i}
                                    onClick={() => {
                                        if (Parentformik.values.itemDetail.length === 0) {
                                            handleAddressSelect(obj);
                                        } else if (Parentformik.values.itemDetail.length > 0) {
                                            setDummyLocation(obj);
                                            setCancelModal(true);
                                        }
                                    }}
                                >
                                    <Typography varient="headingOne">{obj.delivery_address}</Typography>
                                    <Typography varient="headingOne" fontWeight={400}>
                                        {obj.kitchen_name}
                                    </Typography>
                                </Stack>
                            ))}
                    </Stack>
                </Box>

                {openCancelModal && (
                    <DeleteModal
                        open={openCancelModal}
                        buttonTitle="Confirm"
                        title="Are you sure you want to change location?"
                        description="This may clear your order data."
                        handleClose={handleCancelModal}
                        handleSubmit={handleCancelOrder}
                    />
                )}
            </Menu>
        </>
    );
};

export default Address;
