import React, {useEffect, useState} from "react";
import {Stack, Typography, useMediaQuery, useTheme, Box, Grid} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {ErrorMessage, Formik, Form} from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import {useSelector, useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";

const formSchema = {
    note: ""
};

const OrderNotes = ({userID, selectedData, handleIsEdited, setTitle, handleSelectedRow, setSelectedRow, setShowFilter}) => {
    const dispatch = useDispatch();
    
    const [initialValues, setInitialValues] = useState(formSchema);
    const {toggleDrawer} = useTempDrawer();

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up("sm"));

    useEffect(() => {
        if (selectedData) {
            setInitialValues({
                note: selectedData?.note
            });
        } else {
            setInitialValues(formSchema);
        }
    }, [selectedData]);

    const isEditing = selectedData?.customer_note_id;

    const handleCreateOrUpdateNotes = async (values) => {
        const payload = {
            orderId: selectedData.order_id,
            note: values.note
        };

        const instance = NetworkManager(API.ORDER.ORDERNOTES);
        const res = await instance.request(payload);
        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
            handleIsEdited();
            setSelectedRow(null);
            handleSelectedRow(null);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
        setInitialValues(formSchema);
        setTitle(isEditing ? "Edit note" : "Create note");
        selectedData(null);
        setSelectedRow(null);
        handleSelectedRow(null);
    };
    return (
        <>
            <Formik enableReinitialize initialValues={initialValues} onSubmit={(val) => handleCreateOrUpdateNotes(val)}>
                {(formik) => (
                    <Form>
                        <TemporaryDrawer sideDrawerState="addNewNotes" handleSelectedRow={handleSelectedRow} resetForm={formik.resetForm}>
                            <Stack sx={{height: "100%"}} direction="column" justifyContent="space-between" padding={1}>
                                <Box>
                                    <Box sx={{marginTop: "-8px"}}>
                                        <Typography fontSize="1.625rem" sx={{fontWeight: 700}}>
                                            {isEditing ? "Edit note" : "Create note"}
                                        </Typography>
                                    </Box>

                                    <Grid container sx={{marginTop: "20px"}}>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                fullWidth
                                                multiline
                                                inputProps={{
                                                    maxLength: 500
                                                }}
                                                minRows={11.7}
                                                sx={{"&": {height: "235px !important"}}}
                                                label="Add a note"
                                                {...formik.getFieldProps("note")}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    sx={{
                                        position: "fixed",
                                        bottom: 40,
                                        height: "52px",
                                        width: !matchUpMd ? "290px" : "420px"
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box
                                                sx={{
                                                    position: "sticky",
                                                    bottom: 0,
                                                    mt: 2,
                                                    p: 2,
                                                    bgcolor: "#fff",
                                                    zIndex: 1
                                                }}
                                            >
                                                <ButtonGrouping
                                                    btnprops={[
                                                        {
                                                            btnTitle: "Cancel",
                                                            sx: (t) => ({color: t.palette.error.dark}),
                                                            onClick: () => {
                                                                formik.resetForm();
                                                                handleSelectedRow(null);
                                                                toggleDrawer("right", false)();
                                                            }
                                                        },
                                                        {
                                                            btnTitle: "Save",
                                                            variant: "contained",
                                                            disabled: formik.isSubmitting,
                                                            onClick: (e) => {
                                                                formik.handleSubmit();
                                                                formik.validateForm().then((res) => {
                                                                    if (Object.keys(res).length === 0) {
                                                                        toggleDrawer("right", false)(e);
                                                                        setTimeout(() => {
                                                                            formik.resetForm();
                                                                        }, 1500);
                                                                    }
                                                                });
                                                            },
                                                            sx: (t) => ({
                                                                color: t.palette.background.paper
                                                            })
                                                        }
                                                    ]}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>
                        </TemporaryDrawer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default OrderNotes;
