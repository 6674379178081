import {Box, Button, Grid, Typography} from "@mui/material";
import {Stack} from "@mui/system";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
// import MobileCloseBtn from "components/Buttons/MobileCloseBtn";
// import NewCustomDrawer from "components/CommonComponent/SideDrawer/NewCustomeDrawer";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {resetPasswordValidation} from "constants/validationConstant";
import {ErrorMessage, Formik} from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import {API} from "network/core";
import networkManager from "network/core/networkManager";
import React, {useState} from "react";
import {dispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import Loader from "ui-component/Loader";

const ResetPasswordForm = () => {
    const {toggleDrawer} = useTempDrawer();
    const [loading, setLoading] = useState(false);
    const [submitEvent, setSubmitEvent] = useState(null);
    const [initialState] = useState({
        password: "",
        newPassword: "",
        confirmPassword: ""
    });

    const handleResetPassword = async (val, setSubmitting) => {
        setSubmitting(true);
        setLoading(true);
        const payload = {
            old_password: val.password,
            new_password: val.newPassword
        };
       
        const instance = networkManager(API.ACCOUNT.RESETPASS);
        const res = await instance.request(payload);
        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
            toggleDrawer("right", false)();

        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
        setSubmitting(false);
        setLoading(false);
    };
    return (
        <>
            {loading && <Loader />}
            <Formik
                enableReinitialize
                initialValues={initialState}
                validationSchema={resetPasswordValidation}
                onSubmit={(val, {setSubmitting} ) => handleResetPassword(val, setSubmitting)}
            >
                {(formik) => (
                    <TemporaryDrawer drawerName="resetPasswordDrawer" zIndex={1350}>
                        <Box sx={{height: "100%", display: "flex", flexDirection: "column", marginTop: "-0.5rem"}}>
                            <Box sx={{pb: 10, flex: 1, overflowY: "scroll"}}>
                                <Box>
                                    <Grid container>
                                        <Grid item xs={12} sx={{mb: 1, position: "relative"}}>
                                            <Box
                                                sx={{
                                                    display: {xs: "block", sm: "none"},
                                                    position: "absolute",
                                                    right: 10,
                                                    top: 11.15,
                                                    zIndex: 100
                                                }}
                                            >
                                                {/* <MobileCloseBtn
                                                    onClick={(e) => {
                                                        formik.resetForm();
                                                        toggleNewDrawer("resetPasswordDrawer", "right", false)(e);
                                                    }}
                                                /> */}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography fontSize="1.625rem" sx={{fontWeight: 700}}>
                                                Reset password
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Grid container rowSpacing={2.5} mt={1}>
                                    <Grid item xs={12}>
                                        <CustomTextField
                                            label="Current password"
                                            fullWidth
                                            inputProps={{maxLength: 250}}
                                            {...formik.getFieldProps("password")}
                                        />
                                        <ErrorMessage name="password" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomTextField
                                            label="New password"
                                            fullWidth
                                            inputProps={{maxLength: 250}}
                                            {...formik.getFieldProps("newPassword")}
                                        />
                                        <ErrorMessage name="newPassword" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomTextField
                                            label="Confirm new password"
                                            fullWidth
                                            inputProps={{maxLength: 250}}
                                            {...formik.getFieldProps("confirmPassword")}
                                        />
                                        <ErrorMessage name="confirmPassword" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontSize="0.68rem" sx={{fontWeight: 400}} fontStyle="italic">
                                            Password must be at least 7 characters long including one number.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{p: 2}}>
                                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                    <Button
                                        size="large"
                                        sx={{
                                            height: "44px",
                                            minWidth: "92px",
                                            color: "#F0475C"
                                        }}
                                        disabled={formik.isSubmitting}
                                        onClick={(e) => {
                                            formik.resetForm();
                                            toggleDrawer("right", false)(e);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        sx={{
                                            height: "44px",
                                            minWidth: "92px",
                                            color: "#FFF"
                                        }}
                                        disabled={formik.isSubmitting}
                                        onClick={(e) => {
                                            setSubmitEvent(e);
                                            formik.handleSubmit();
                                            formik.validateForm().then((res) => {
                                                // to close the sidebar if no field has validation error
                                                if (Object.keys(res).length === 0) {
                                                    toggleDrawer("right", false)(e);
                                                    setTimeout(() => {
                                                        formik.resetForm();
                                                    }, 1500);
                                                }
                                            });
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Stack>
                            </Box>
                        </Box>
                    </TemporaryDrawer>
                )}
            </Formik>
        </>
    );
};

export default ResetPasswordForm;
