import {Grid} from "@mui/material";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {ErrorMessage} from "formik";
import React, {useState, useEffect} from "react";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import {generateUrl} from "utils/helperFunctions/helpers";
import {NetworkManager, API} from "network/core";

const ModifierGroupDetail = (props) => {
    const {formik} = props;
    const [categoryList, setCategoryList] = useState([]);
    const getCategory = async () => {
        const instance = NetworkManager(API.CATEGORY.GET);
        const params = {
            sortBy: "category_name",
            orderBy: "asc",
            type : "modifier"
        };
        const url = generateUrl(params);
        const response = await instance.request({}, [url]);
        if (response.success) {
            const states = response.data?.categoryList?.rows?.map((state) => ({
                label: state.category_name,
                value: state.category_name,
                id: state.category_id,
                description: state.description
            }));
            setCategoryList(states);
        }
    };
    useEffect(() => {
        getCategory();
    }, []);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CustomTextField
                        // disabled={!isEdit}
                        fullWidth
                        placeholder="Modifier group name (internal)"
                        label="Modifier group name (internal)"
                        inputProps={{maxLength: 250}}
                        {...formik.getFieldProps("modifierGroupName")}
                    />
                    <ErrorMessage name="modifierGroupName" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                </Grid>

                <Grid item xs={12}>
                    <CustomSelect
                        label="Category"
                        menuItems={categoryList}
                        {...formik.getFieldProps("displayName")}
                        inputProps={{maxLength: 250}}
                        value={formik.values.categoryId}
                        // name='category'
                        onChange={(e) => {
                            const value = e.target.value;
                            const label= categoryList.filter((obj) => obj?.id === value)?.[0].label;
                            formik.setFieldValue("displayName", label);
                            formik.setFieldValue("categoryId", value);
                        }}
                    />
                    <ErrorMessage name="displayName" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextField
                        // disabled={!isEdit}
                        fullWidth
                        placeholder="Description (optional)"
                        label="Description (optional)"
                        inputProps={{maxLength: 250}}
                        {...formik.getFieldProps("modifierDescription")}
                    />
                    {/* <ErrorMessage name="modifierDescription" render={(msg) => <CustomErrorMessage errorMessage={msg} />} /> */}
                </Grid>
            </Grid>
        </>
    );
};

export default ModifierGroupDetail;
