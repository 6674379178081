import React from "react";
import {ErrorMessage, FieldArray} from "formik";
import {Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, Radio, Stack, Typography} from "@mui/material";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import {enforceFormat} from "utils/helperFunctions/helpers";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSwitch from "commonComponent/Form/Fields/CustomSwitch";
import useItemDetailController from "./itemDetailTab.controller";
import Unchecked from "assets/images/icons/unchecked.svg";
import Checked from "assets/images/icons/lightCheckedBox.svg";
import CustomRadio from "commonComponent/Radio";
import {BpCheckedIcon} from "commonComponent/DilogBox/PrintOrderModal";

const ItemDetailsSection = (props) => {
    const {formik, labelList, menuid, catId, isEdit = true, inSideBar} = props;
    const {getFieldProps} = formik;
    const IDC = useItemDetailController(props);

    return (
        <>
            <Grid container>
                <Grid item container spacing={4} xs={12}>
                    {/* <Grid item xs={12}>
                        <Box ml={1}>
                            <Typography variant="body4" fontSize="12px">
                                Status
                            </Typography>
                        </Box>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <CustomSwitch
                                // disabled={!isEdit}
                                checked={formik.values.is_active}
                                onChange={(e) => {
                                    formik.setFieldValue("is_active", e.target.checked);
                                }}
                            />
                            <Typography fontWeight={formik.values.is_active ? 600 : 400}>Active</Typography>
                        </Stack>
                    </Grid> */}

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CustomTextField
                                    disabled={!isEdit}
                                    fullWidth
                                    label="Item name"
                                    inputProps={{maxLength: 250}}
                                    {...getFieldProps("name")}
                                />
                                <ErrorMessage name="name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <CustomSelect
                                    noNone
                                    label="Item type"
                                    disabled={!isEdit}
                                    fullWidth
                                    menuItems={[
                                        {label: "Custom", value: "CUSTOM", id: 0},
                                        {label: "Regular", value: "REGULAR", id: 1}
                                    ]}
                                    {...formik.getFieldProps("item_type")}
                                    onChange={(e) => {
                                        const id = e.target.value;
                                        formik.setFieldValue("item_type", id);
                                    }}
                                />
                                <ErrorMessage name="item_type" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>

                            {!menuid && formik.values.item_type === 1 && (
                                <Grid item sm={inSideBar ? 12 : 6} xs={12}>
                                    <CustomSelectWithCheckbox
                                        disabled={!isEdit || menuid}
                                        selectAll
                                        label="Menu"
                                        menuItems={IDC.allMenus}
                                        values={formik.values.menus}
                                        onChange={(val) => {
                                            formik.setFieldValue("menus", val);
                                        }}
                                    />
                                    <ErrorMessage name="menus" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                </Grid>
                            )}
                            {!catId && formik.values.item_type === 1 && (
                                <Grid item sm={inSideBar ? 12 : 6} xs={12}>
                                    <CustomSelect
                                        disabled={!isEdit || catId}
                                        label="Category"
                                        fullWidth
                                        menuItems={IDC.category}
                                        value={formik.values.category_id?.[0]}
                                        onChange={(e) => {
                                            const val = e.target.value;
                                            formik.setFieldValue("category_id", [val]);
                                        }}
                                    />
                                    {/* <CustomSelectWithCheckbox
                                        disabled={!isEdit || catId}
                                        label="Category"
                                        menuItems={IDC.category}
                                        values={formik.values.category_id}
                                        onChange={(val) => {
                                            formik.setFieldValue("category_id", val);
                                        }}
                                    /> */}
                                    <ErrorMessage name="category_id" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                </Grid>
                            )}

                            <Grid item sm={6} xs={12}>
                                <CustomTextField
                                    disabled={!isEdit}
                                    fullWidth
                                    label="Price"
                                    onKeyDown={(e) => {
                                        // Allow backspace, tab, delete, arrows, and decimal points
                                        if (
                                            !(
                                                e.key === "Backspace" ||
                                                e.key === "Tab" ||
                                                e.key === "Delete" ||
                                                e.key === "ArrowLeft" ||
                                                e.key === "ArrowRight" ||
                                                e.key === "." ||
                                                /^[0-9]$/.test(e.key)
                                            )
                                        ) {
                                            e.preventDefault();
                                        }
                                    }}
                                    {...getFieldProps("price")}
                                    inputProps={{
                                        maxLength: 7,
                                        inputMode: "decimal",
                                        pattern: "[0-9]*[.,]?[0-9]{0,2}" // Allow numbers with an optional decimal point and up to 2 decimal places
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" sx={{color: "#212121"}}>
                                                $
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                <ErrorMessage name="price" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <CustomTextField
                                    disabled={!isEdit}
                                    fullWidth
                                    label="SKU"
                                    inputProps={{maxLength: 250}}
                                    {...getFieldProps("sku")}
                                />
                                <ErrorMessage name="sku" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextField
                                    disabled={!isEdit}
                                    multiline
                                    minRows={2.5}
                                    sx={{"&": {height: "fit-content !important"}}}
                                    fullWidth
                                    label="Description (250 characters max)"
                                    inputProps={{maxLength: 250}}
                                    {...getFieldProps("description")}
                                />
                                <ErrorMessage name="description" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                        </Grid>
                    </Grid>

                    {formik.values.item_type === 1 && (
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    <FieldArray
                                        name="dietary"
                                        render={(arrayHelper) => (
                                            <FormControl>
                                                <Typography variant="checkBoxSelected" fontSize="14px" sx={{mb: 2}}>
                                                    Dietary
                                                </Typography>

                                                <FormGroup>
                                                    {IDC.DietaryCheckboxes.map((obj, index) => (
                                                        <Stack key={index} direction="row">
                                                            <FormControlLabel
                                                                sx={{height: 30}}
                                                                disabled={!isEdit}
                                                                checked={formik.values.dietary.includes(obj.value)}
                                                                key={index}
                                                                control={
                                                                    <Checkbox
                                                                        disableRipple
                                                                        icon={
                                                                            <img
                                                                                src={Unchecked}
                                                                                style={{width: "18px", height: "18px"}}
                                                                                alt="checked"
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <img
                                                                                src={Checked}
                                                                                alt="checkbox"
                                                                                style={{width: "18px", height: "18px"}}
                                                                            />
                                                                        }
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography
                                                                        fontSize="15px"
                                                                        variant={
                                                                            formik.values.dietary.length &&
                                                                            formik.values.dietary.includes(obj.value)
                                                                                ? "checkBoxSelected"
                                                                                : "checkBoxUnSelected"
                                                                        }
                                                                    >
                                                                        {obj.label}
                                                                    </Typography>
                                                                }
                                                                onChange={(e) => {
                                                                    if (
                                                                        formik.values.dietary.length &&
                                                                        formik.values.dietary.includes(obj.value)
                                                                    ) {
                                                                        const valIndex = formik.values.dietary.findIndex(
                                                                            (el) => el === obj.value
                                                                        );
                                                                        arrayHelper.remove(valIndex);
                                                                    } else {
                                                                        arrayHelper.push(obj.value);
                                                                    }
                                                                }}
                                                            />
                                                        </Stack>
                                                    ))}
                                                </FormGroup>

                                                <ErrorMessage name="dietary" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <FieldArray
                                        name="label"
                                        render={(arrayHelper) => (
                                            <FormControl>
                                                <Typography variant="checkBoxSelected" fontSize="14px" sx={{mb: 2}}>
                                                    Label (appears on menu item thumbnail)
                                                </Typography>

                                                <FormGroup sx={{mb: 2}}>
                                                    {labelList.map((obj, index) => (
                                                        <Stack key={index}>
                                                            <FormControlLabel
                                                                sx={{height: 30}}
                                                                disabled={!isEdit}
                                                                key={index}
                                                                control={
                                                                    <Checkbox
                                                                        checked={formik.values.label.includes(obj.value)}
                                                                        disableRipple
                                                                        icon={
                                                                            <img
                                                                                src={Unchecked}
                                                                                style={{width: "18px", height: "18px"}}
                                                                                alt="checked"
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <img
                                                                                src={Checked}
                                                                                alt="checkbox"
                                                                                style={{width: "18px", height: "18px"}}
                                                                            />
                                                                        }
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography
                                                                        fontSize="15px"
                                                                        sx={{textTransform: "capitalize"}}
                                                                        variant={
                                                                            formik.values.label.length &&
                                                                            formik.values.label.includes(obj.value)
                                                                                ? "checkBoxSelected"
                                                                                : "checkBoxUnSelected"
                                                                        }
                                                                    >
                                                                        {obj.label}
                                                                    </Typography>
                                                                }
                                                                onChange={(e) => {
                                                                    if (formik.values.label.includes(obj.value)) {
                                                                        formik.setFieldValue("label", "");
                                                                    } else {
                                                                        formik.setFieldValue("label", obj.value);
                                                                    }

                                                                    // if (
                                                                    //     formik.values.label.length &&
                                                                    //     formik.values.label.includes(obj.value)
                                                                    // ) {
                                                                    //     const valIndex = formik.values.label.findIndex(
                                                                    //         (el) => el === obj.value
                                                                    //     );
                                                                    //     arrayHelper.remove(valIndex);
                                                                    // } else {
                                                                    //     arrayHelper.push(obj.value);
                                                                    // }
                                                                }}
                                                            />
                                                            {obj.label === "Custom" && formik.values.label.includes("CUSTOM") && (
                                                                <Stack sx={{mt: 1}}>
                                                                    <CustomTextField
                                                                        label="Label name"
                                                                        disabled={!formik.values.label.includes(obj.value)}
                                                                        fullWidth
                                                                        {...getFieldProps("customName")}
                                                                    />

                                                                    <ErrorMessage
                                                                        name="customName"
                                                                        render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                    />
                                                                </Stack>
                                                            )}
                                                        </Stack>
                                                    ))}
                                                </FormGroup>
                                                <ErrorMessage name="label" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default ItemDetailsSection;
