import {Box} from "@mui/system";
import React, {useEffect, useState} from "react";
import LeftArrow from "../../../../../../../assets/images/icons/arrow-left.svg";
import RightArrow from "../../../../../../../assets/images/icons/arrow-right.svg";
import {Button, Typography} from "@mui/material";

function Calendar({setDate}) {
    function formatDate(date) {
        const options = {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric"
        };
        return date.toLocaleDateString("en-US", options);
    }

    const [currentDate, setCurrentDate] = useState(new Date());

    const formattedDate = formatDate(currentDate);
    const isToday = formattedDate === formatDate(new Date());

    const handleNextDate = () => {
        const nextDate = new Date(currentDate);
        nextDate.setDate(currentDate.getDate() + 1);
        setCurrentDate(nextDate);
    };

    const handlePrevDate = () => {
        if (!isToday) {
            const prevDate = new Date(currentDate);
            prevDate.setDate(currentDate.getDate() - 1);
            setCurrentDate(prevDate);
        }
    };

    useEffect(() => {
        setDate(currentDate);
    }, [currentDate]);

    return (
        <Box
            sx={{
                height: "52px",
                width: "344px",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "4px",
                padding: "16px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                background: "#FAFAFA"
            }}
        >
            <Button
                sx={{position: "absolute", left: "0"}}
                disableRipple
                disableTouchRipple
                disableFocusRipple
                onClick={handlePrevDate}
                disabled={isToday}
            >
                <img src={LeftArrow} alt="left arrow" />
            </Button>
            <Typography sx={{fontSize: "0.875rem", fontWeight: 500, color: "#212121"}}>{formattedDate}</Typography>
            <Button sx={{position: "absolute", right: "0"}} disableRipple disableTouchRipple disableFocusRipple onClick={handleNextDate}>
                <img src={RightArrow} alt="right arrow" />
            </Button>
        </Box>
    );
}

export default Calendar;
