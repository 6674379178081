import React, {useEffect, useState} from "react";
import {getformateDateUS, getFormattedTimeinAMPM} from "utils/app-dates/dates";
import {generateUrl, FormatCurrency} from "utils/helperFunctions/helpers";
import {Grid, Typography, Box, Button} from "@mui/material";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";
import Slider, {ResponsiveObject, Settings} from "react-slick";
import AnimateButton from "ui-component/extended/AnimateButton";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import useTempDrawer from "hooks/useTempDrawer";
import RefundForm from "../../../CreateOrder/createOrderFooter/Refund";
import DriverModal from "commonComponent/DilogBox/DriverModal";
import {Link} from "react-router-dom";
import WithPermission from "commonComponent/RBAC/permissionHoc";

const settings = {
    autoplay: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 2500,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
const sliderItemsCountFix = (settings, itemsCount) => {
    if (!settings.responsive) return settings;
    const responsiveArray = settings.responsive.map((responsiveItem) => {
        const responsive = responsiveItem.settings !== "unslick" && responsiveItem.settings;
        if (!responsive) return;
        const slidesToShow = responsive.slidesToShow || 1;
        const isItemsMoreThanSlidesToShow = itemsCount > slidesToShow;

        // eslint-disable-next-line consistent-return
        return isItemsMoreThanSlidesToShow ? responsiveItem : {...responsiveItem, settings: "unslick"};
    });

    settings.slidesToShow = itemsCount < 3 ? 1 : itemsCount;

    return {...settings, responsive: responsiveArray};
};

function StatusCards({setLoading, generateUrl, handleSelectedRow, getScheduleOrderList, filterChips, setScheduleOrder}) {
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRow1, setSelectedRow1] = useState(null);
    const [openDriverModal, setOpenDriverModal] = useState(false);
    const [driverListing, setDriverListing] = React.useState([]);
    const {toggleDrawer, state} = useTempDrawer();
    const [scheduleOrderListing, setScheduleOrderListing] = React.useState([]);
    const dispatch = useDispatch();

    const getStatusScheduleOrderList = async () => {
        setLoading(true);
        const instance = NetworkManager(API.ORDER.GET);
        const params = {
            offset: 0,
            status: "New,Accepted,Needs Driver,Active,Driver Assigned,Delivered",
            limit: 5000,
            sort: "desc",
            sort_by: "delivery_date"
        };
        const url = generateUrl(params);

        const response = await instance.request({}, [url]);
        if (response.success) {
            setScheduleOrderListing(response.data.orderList);
            setScheduleOrder(response.data.orderList);
        }
        setLoading(false);
    };

    const handleDriverModal = () => {
        setOpenDriverModal((pre) => !pre);
    };

    const getDriverList = async () => {
        const instance = NetworkManager(API.DRIVER.LISTING);

        const response = await instance.request();

        if (response.success) {
            const driverList = response.data?.driver;
            // Modified array of objects with new property names
            const modifiedArray = driverList
                ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                ?.map((obj) => ({
                    phone: obj.phoneNumber,
                    name: obj.name,
                    ...obj
                }));
            setDriverListing(modifiedArray);
        }
    };

    const handleAssignDriver = async (values) => {
        const instance = NetworkManager(API.DRIVER.ASSIGN);

        const response = await instance.request({
            orderId: selectedRow1?.order_id,
            carrierId: `${values}`
        });

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            getScheduleOrderList(Object.keys(filterChips).length ? filterChips : "");
            getStatusScheduleOrderList();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setOpenDriverModal((pre) => !pre);
    };

    const handleStatus = async (row, status) => {
        const instance = NetworkManager(API.ORDER.STATUS(row.order_id, status));
        const payload = {
            link: `${process.env.REACT_APP_CUSTOMER_URL}account?tab=orders&orderId=${row.order_id}`
        };

        const response = await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            getScheduleOrderList(Object.keys(filterChips).length ? filterChips : "");
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }

        getStatusScheduleOrderList();
    };

    function formatDateToAMPM(timestamp) {
        const date = new Date(timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "pm" : "am";

        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedHours}:${formattedMinutes}${ampm}`;
    }

    useEffect(() => {
        getDriverList();
        getStatusScheduleOrderList();
    }, []);

    const addressFormat = (address) => {
        const add = address.split(",");
        const str = `${add[add.length - 2]}, ${add[add.length - 1]}`;
        return str;
    };

    return (
        <>
            <div style={{width: "6000px", overflowX: "scroll"}} className="statusScroll">
                {" "}
                {scheduleOrderListing.length > 0 && (
                    // <Slider {...sliderItemsCountFix(settings, scheduleOrderListing.length)} style={{maxWidth: 1080}}>
                    <Box sx={{display: "flex", pb: 1}}>
                        {/* NE */}
                        {scheduleOrderListing
                            .filter(
                                (item) =>
                                    (item.status === "Active" && item?.re_confirm === 1) ||
                                    (item.status === "Driver assigned" && item?.re_confirm === 1) ||
                                    item.status === "New" ||
                                    item.status === "Needs driver"
                            )

                            .map((item) => (
                                <Grid
                                    key={`item.order_id ${Math.random()}`}
                                    sx={{
                                        minWidth: "245px !important",
                                        maxWidth: "245px !important",
                                        mr: 2,
                                        position: "relative",
                                        minHeight: "226px !important"
                                    }}
                                    item
                                    xs={12}
                                    sm={5.8}
                                    md={4}
                                    lg={3}
                                    border="1px solid #E0E2E6"
                                    borderRadius="4px"
                                    p={2}
                                >
                                    {/* {console.log(item, "263263")} */}
                                    <Typography
                                        component={Link}
                                        to={`/orders/${item.order_id}`}
                                        sx={{
                                            fontSize: "0.93rem",
                                            fontWeight: 600,
                                            textDecoration: "none"
                                        }}
                                        color={(t) => t.palette.primary.main}
                                    >
                                        {item.order_number} &nbsp;{" "}
                                        <span style={{color: "#26272A", opacity: "0.5029", fontWeight: 300}}> | </span> &nbsp;
                                        <Typography
                                            sx={{
                                                display: "inline",
                                                fontSize: "0.75rem",
                                                fontWeight: 600,
                                                color: (t) => t.palette.secondary.main,
                                                letterSpacing: "-0.7px !important"
                                            }}
                                        >
                                            {item?.brand?.brand_name ?? ""}
                                        </Typography>
                                    </Typography>
                                    <Typography variant="h4" mt={0.8} fontWeight={600} lineHeight="24px">
                                        {item?.delivery_date
                                            ? `${new Date(item?.delivery_date).toLocaleDateString("en-us", {
                                                  month: "short",
                                                  weekday: "short",
                                                  day: "numeric"
                                              })}, ${formatDateToAMPM(item?.delivery_date)}`
                                            : null}
                                    </Typography>
                                    <Typography sx={{fontSize: "0.75rem", fontWeight: 600}} mt={0.8}>
                                        {item?.customer?.company?.company_name ?? item?.customer?.full_name}
                                    </Typography>
                                    {item?.address_details && Object.keys(item?.address_details)?.length ? (
                                        <Typography sx={{fontSize: "0.75rem", fontWeight: 400, lineHeight: "0.8rem"}}>
                                            {item?.address_details.street_line ?? ""}
                                            <Typography sx={{fontSize: "0.75rem", fontWeight: 400, lineHeight: "0.8rem"}}>
                                                {`${item?.address_details?.city !== "" ? `${item?.address_details?.city}, ` : ""}${
                                                    item?.address_details?.state !== "" ? `${item?.address_details?.state} ` : ""
                                                }${item?.address_details?.pincode}`}
                                            </Typography>
                                        </Typography>
                                    ) : (
                                        <Typography sx={{fontSize: "0.75rem", fontWeight: 400, lineHeight: "0.8rem"}}>
                                            {item.delivery_address ? item.delivery_address.split(",")[0] : ""}
                                            <Typography sx={{fontSize: "0.75rem", fontWeight: 400, lineHeight: "0.8rem"}}>
                                                {/* {item.delivery_address && item.delivery_address.split(",")[1]} */}
                                                {item.delivery_address && addressFormat(item.delivery_address)}
                                            </Typography>
                                        </Typography>
                                    )}
                                    <Typography
                                        variant="h4"
                                        fontWeight={600}
                                        sx={{position: "absolute", bottom: "68px", left: "1rem", lineHeight: "22px"}}
                                    >
                                        {FormatCurrency(item?.total_price)}
                                    </Typography>
                                    {item.status === "New"
                                        ? WithPermission({module: "Orders", permission: ["update_permission"]}) && (
                                              <>
                                                  {" "}
                                                  <Box
                                                      mt={2}
                                                      sx={{display: "flex", gap: "6px", width: "87%", position: "absolute", bottom: "16px"}}
                                                  >
                                                      <Box sx={{width: "50%"}}>
                                                          <Button
                                                              // fullWidth
                                                              size="large"
                                                              variant="contained"
                                                              color="primary"
                                                              sx={{
                                                                  color: "white",
                                                                  fontWeight: 500,
                                                                  fontSize: "0.875rem",
                                                                  height: "35px",
                                                                  width: "100%"
                                                              }}
                                                              onClick={() => {
                                                                  handleStatus(item, "Accepted");
                                                              }}
                                                          >
                                                              Accept
                                                          </Button>
                                                      </Box>
                                                      <Box sx={{width: "50%"}}>
                                                          <Button
                                                              // fullWidth
                                                              size="large"
                                                              variant="outlined"
                                                              color="error"
                                                              sx={{
                                                                  color: "#FF1C43",
                                                                  fontWeight: 500,
                                                                  height: "35px",
                                                                  width: "100%",
                                                                  fontSize: "0.875rem"
                                                              }}
                                                              onClick={(e) => {
                                                                  setSelectedRow(item);
                                                                  //   toggleDrawer("right", "refund")(e);
                                                                  handleStatus(item, "Rejected");
                                                              }}
                                                          >
                                                              Reject
                                                          </Button>
                                                      </Box>
                                                  </Box>
                                              </>
                                          )
                                        : item.status === "Needs driver"
                                        ? WithPermission({module: "Orders", permission: ["update_permission"]}) && (
                                              <Box mt={2} sx={{position: "absolute", bottom: "16px", width: "87%"}}>
                                                  <AnimateButton>
                                                      <Button
                                                          fullWidth
                                                          size="large"
                                                          variant="contained"
                                                          color="primary"
                                                          sx={{color: "white", fontWeight: 600, fontSize: "0.813rem", height: "35px"}}
                                                          onClick={(e) => {
                                                              setSelectedRow1(item);
                                                              handleDriverModal(item);
                                                          }}
                                                      >
                                                          {item.status === "Needs driver" && "Assign driver"}
                                                      </Button>
                                                  </AnimateButton>
                                              </Box>
                                          )
                                        : WithPermission({module: "Orders", permission: ["update_permission"]}) && (
                                              <Box mt={2} sx={{position: "absolute", bottom: "16px", width: "87%"}}>
                                                  <AnimateButton>
                                                      <Button
                                                          fullWidth
                                                          size="large"
                                                          variant="contained"
                                                          color="primary"
                                                          sx={{color: "white", fontWeight: 600, fontSize: "0.813rem", height: "35px"}}
                                                          onClick={() => {
                                                              if (item.status === "Active") {
                                                                  setSelectedRow1(item);
                                                                  handleDriverModal(item);
                                                              } else if (item.status === "Driver assigned") {
                                                                  handleStatus(item, "Needs Driver");
                                                              }
                                                          }}
                                                      >
                                                          {(item.status === "Active" || item.status === "Driver assigned") && "Confirm"}
                                                      </Button>
                                                  </AnimateButton>
                                              </Box>
                                          )}
                                </Grid>
                            ))}

                        {/* </Slider> */}
                    </Box>
                )}
            </div>
            {state.right === "refund" && (
                <RefundForm
                    handleSelectedRow={handleSelectedRow}
                    customerId={selectedRow?.customer?.user_id}
                    orderId={selectedRow?.order_id}
                    orderNumber={selectedRow?.order_number}
                    refundMethod={selectedRow?.charged_by}
                    orderData={selectedRow}
                />
            )}
            {openDriverModal && (
                <DriverModal
                    open={openDriverModal}
                    buttonTitle="Assign driver"
                    title="Assign a driver"
                    orderId={selectedRow1?.order_id}
                    marketId={
                        selectedRow1?.kitchen_location?.market?.market_id.length > 0
                            ? selectedRow1?.kitchen_location?.market?.market_id
                            : []
                    }
                    driversData={driverListing}
                    description={`Select a driver to assign to order #${selectedRow1?.order_number}`}
                    handleClose={handleDriverModal}
                    handleSubmit={(values) => handleAssignDriver(values)}
                />
            )}
        </>
    );
}

export default StatusCards;
