import {Grid} from "@mui/material";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {NetworkManager, API} from "network/core";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {useNavigate, useSearchParams} from "react-router-dom";
import FilterChips from "commonComponent/FilterChipsComponent";
import {generateUrl} from "utils/helperFunctions/helpers";
import Filters from "commonComponent/Filters";
import useTempDrawer from "hooks/useTempDrawer";
import CreateCustomerForm from "../../CreateCustomer/CreateCustomerForm";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {setPageNumber, setRowsPerPage, setOrder, setOrderBy} from "store/slices/paginationSlice";

const HouseAccount = (props) => {
    const {toggleDrawer} = useTempDrawer();
    const {search, showFilter, setSelectedCheckbox, updateListCount, selectedCheckbox, filterChips} = props;
    const [title, setTitle] = useState("Create Customer");

    const [open, setOpen] = React.useState(false);
    const [customerListing, setCustomerListing] = React.useState([]);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.order || "asc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "full_name_HouseAccount");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    // const [filterChips, setFilterChips] = useState({});
    const [urlSearchParams] = useSearchParams();

    const navigation = useNavigate();
    const tab = urlSearchParams.get("tab");
    const modifierId = urlSearchParams.get("id");

    const dispatch = useDispatch();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };
    const getHouseAccountList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.CARD.HOUSEACCOUNTLISTING);
        const params = {
            offset: currentPage,
            limit: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };

        if (currentOrderBy) {
            params.sortBy = currentOrderBy;
            params.orderBy = currentOrder;
        }

        if (search === "") {
            params.page = currentPage;
        }
        if (search !== "") {
            params.search = search;
            setCustomerListing([]);
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);
        setLoading(false);
        if (response.success) {
            setCustomerListing(response.data?.houseAccount);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handleStatus = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.DRIVER.UPDATE(`${values.driver_id}`));
        let isActive = false;
        if (type === "Deactivate") {
            isActive = false;
        } else {
            isActive = true;
        }
        const payload = {
            is_active: isActive
        };
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                navigation(`/customers/${row.user_id}/?tab=houseAccount&customerName=${row?.full_name}&edit=true`);

                break;
            }

            case "Delete": {
                handleDeleteModal();
                break;
            }
            default:
                break;
        }
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.CARD.BULKDELETE);
        const payload = {
            customerCardIds: `${selectedRow?.customerCard[0].customer_card_id}`
        };
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getHouseAccountList();
        setSelectedCheckbox([]);
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
        // setCurrentPage(number + 1);
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        // setRowsPerPage(number);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };
    const handleSort = (val1, val2) => {
        dispatch(setOrder(val1));
        dispatch(setOrderBy(val2));
    };

    useEffect(() => {
        if (search) {
            dispatch(setPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        getHouseAccountList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, filterChips, updateListCount]);

    const filterState = [
        {
            key: "company_name",
            label: "Company",
            type: "textfield"
        }
    ];

    const handleApplyFilter = (Morefilters) => {
        dispatch(setPageNumber(1));
        // setFilterChips(Morefilters);
    };
    return (
        <>
            {showFilter ? (
                <Filters
                    filterState={filterState}
                    filterChips={filterChips}
                    // setFilterChips={setFilterChips}
                    handleApplyFilter={handleApplyFilter}
                />
            ) : (
                <CreateCustomerForm
                    selectedData={selectedRow}
                    setTitle={setTitle}
                    conditionalTitle={title}
                    handleIsEdited={handleIsEdited}
                />
            )}

            <Grid container>
                <Grid item xs={12}>
                    {/* <FilterChips
                        filterChips={filterChips}
                        // setFilterChips={setFilterChips}
                    /> */}
                    <CommonTable
                        withCheckbox
                        loading={loading}
                        data={customerListing}
                        header={[
                            {key: "full_name_HouseAccount", label: "Customer"},
                            {key: "company.company_name", label: "Company"},
                            {key: "card.orders", label: "Orders"},
                            {key: "card.balance", label: "Balance"},
                            {key: "card.createdAt", label: "Created"},
                            {key: "action", label: "Action"}
                        ]}
                        actions={[
                            WithPermission({module: "Customers", permission: "update_permission"}) && "Edit",
                            WithPermission({module: "Customers", permission: "delete_permission"}) && "Delete"
                        ]}
                        handleActionBtn={handleActionBtn}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        setSelectedCheckbox={setSelectedCheckbox}
                        selectedCheckbox={selectedCheckbox}
                        currentPage={currentPage - 1}
                        sortingLabel={["Customer"]}
                        height="75vh"
                    />
                </Grid>
            </Grid>

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this House account?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
        </>
    );
};

export default HouseAccount;
