import {Box, Grid, InputAdornment} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {ErrorMessage, Formik} from "formik";
import React, {useState} from "react";
import {enforceFormat, formatToPhone} from "utils/helperFunctions/helpers";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import * as yup from "yup";
import {API, NetworkManager} from "network/core";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";

const defaultInitialValues = {
    address: "",
    company: "",
    event_name: "",
    delivery_contact_name: "",
    phone: "",
    longitude: null,
    latitude: null,
    delivery_instructions: ""
};

const AddNewCustomerForm = (props) => {
    // console.log("propsprops : ", props);
    const {handleClose, Parentformik, orderId} = props;
    const {headerData} = Parentformik.values;
    const [openCancelModal, setCancelModal] = useState(false);
    const [dummyLocation, setDummyLocation] = useState(null);
    const [pincode, setPincode] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [street, setStreet] = useState("");
    const [country, setCountry] = useState("");

    const onError = (status, clearSuggestions) => {
        clearSuggestions();
    };

    const handleSubmitAddress = async (val, setSubmitting) => {
        setSubmitting(true);
        const addresses = [
            {
                address_type: "DELIVERY",
                delivery_address: val.address,
                location_name: val.company,
                apartment_name: val?.apartment_name,
                contact_name: val.delivery_contact_name,
                contact: val.phone,
                latitude: val.latitude,
                longitude: val.longitude,
                instruction: val?.delivery_instructions,
                address_details: {
                    street_line: street,
                    city: city,
                    state: state,
                    pincode: pincode,
                    country: country
                }
            }
        ];

        const payload = {
            customer_id: headerData?.customer?.userId,
            addressDetails: addresses
        };
        const instance = NetworkManager(API.ADDRESS.CREATE);

        const response = await instance.request(payload);
        // console.log("responseresponse : ", response);
        if (response.success) {
            const data = response.data.address?.[0];

            const add = [
                {
                    posted: true,
                    address_id: data?.address_id,
                    address_type: data?.address_type,
                    delivery_address: data?.delivery_address,
                    location_name: data?.location_name,
                    apartment_name: data?.apartment_name,
                    contact_name: data?.contact_name,
                    contact: data?.contact,
                    longitude: data?.longitude,
                    latitude: data?.latitude,
                    address_details: {
                        street_line: street,
                        city: city,
                        state: state,
                        pincode: pincode,
                        country: country
                    }
                }
            ];

            Parentformik.setFieldValue("headerData.deliveryTo", add[0]?.delivery_address);
            Parentformik.setFieldValue("headerData.longitude", add[0]?.longitude);
            Parentformik.setFieldValue("headerData.latitude", add[0]?.latitude);
            Parentformik.setFieldValue("headerData.address_details", add[0]?.address_details);
            Parentformik.setTouched({"headerData.deliveryTo": true});
            Parentformik.setFieldValue("headerData.address_id", add[0].address_id);
            Parentformik.setFieldValue("name", data?.contact_name);
            Parentformik.setFieldValue("phone", data?.contact);
            Parentformik.setFieldValue("addressChanged", true);
            Parentformik.setFieldValue("company", data?.location_name);
            Parentformik.setFieldValue("apartment", data?.event_name);
            Parentformik.setFieldValue("deliveryInstruction", data?.instruction);
            Parentformik.setFieldValue("apartment",data?.apartment_name)

            const addList = Parentformik.values.headerData.customer.customerAddresses;
            addList.push({
                delivery_address: add[0]?.delivery_address,
                location_name:  data?.location_name,
                address_type: "DELIVERY",
                longitude: add[0]?.longitude,
                latitude: add[0]?.latitude,
                address_details: add[0]?.address_details,
                address_id: add[0].address_id,
                contact_name:data?.contact_name,
                contact:data?.contact,
                apartment_name:data?.apartment_name,      
                instruction:data?. instruction, 

            });
            Parentformik.setFieldValue("headerData.customer.customerAddresses", addList);
        }
        handleClose();
        setSubmitting(false);
    };

    // console.log("headerData : ", headerData);
    const stopPropagationForTab = (event) => {
        if (event.key === "Tab") {
            event.stopPropagation();
        }
    };

    const handleCancelModal = () => {
        setCancelModal((pre) => !pre);
    };

    const getBrandList = async (values, formik) => {
        const instance = NetworkManager(API.BRAND.GETBRANDWITHADDRESSFILTER);
        const params = {
            orderBy: "ASC"
        };
        if (values.address !== "") {
            params.search = values.address.split(",").slice(0, -1).join(",") || values.address;
            params.latitude = values.latitude;
            params.longitude = values.longitude;
        }
        if (Parentformik.values.headerData.when !== "") {
            params.deliveryDate = Parentformik.values.headerData.when;
        }
        const response = await instance.request(params);
        if (response.success) {
            if (Parentformik.values.selectedBrand !== "") {
                const selectedBrandPresentInList = response.data?.filter((row) => row.brand_id === Parentformik.values.selectedBrand);
                if (selectedBrandPresentInList.length === 0) {
                    setCancelModal(true);
                } else {
                    formik.handleSubmit();
                    setTimeout(() => {
                        formik.resetForm();
                    }, 2500);
                    setCancelModal(false);
                }
            }
        }
    };

    const parseAddressComponents = async (addressComponents) => {
        if (addressComponents) {
            let newPincode = "";
            let newCity = "";
            let newState = "";
            let newStreetLine = "";
            let newCountry = "";
            for (let i = 0; i < addressComponents.length; i += 1) {
                const component = addressComponents[i];
                if (component.types.includes("postal_code")) {
                    newPincode = component.long_name;
                } else if (component.types.includes("locality")) {
                    newCity = component.short_name;
                } else if (component.types.includes("administrative_area_level_2")) {
                    newState += component.short_name + " "; // eslint-disable-line prefer-template
                } else if (component.types.includes("administrative_area_level_1")) {
                    newState += component.short_name;
                } else if (component.types.includes("premise")) {
                    newStreetLine += component.short_name + ", "; // eslint-disable-line prefer-template
                } else if (component.types.includes("subpremise")) {
                    newStreetLine += component.short_name + ", "; // eslint-disable-line prefer-template
                } else if (component.types.includes("route")) {
                    newStreetLine += component.short_name;
                } else if (component.types.includes("street_number")) {
                    newStreetLine += component.short_name + " "; // eslint-disable-line prefer-template
                } else if (component.types.includes("point_of_interest")) {
                    newStreetLine += component.short_name + ", "; // eslint-disable-line prefer-template
                } else if (component.types.includes("country")) {
                    newCountry = component.short_name;
                }
            }
            setPincode(newPincode);
            setCity(newCity);
            setState(newState);
            setStreet(newStreetLine);
            setCountry(newCountry);
        }
    };

    return (
        <Box p={2} sx={{maxWidth: 400}}>
            <Formik
                initialValues={defaultInitialValues}
                validationSchema={yup.object({
                    address: yup.string().required("Delivery address is required").nullable().trim(),
                    latitude: yup.string().required("Latitude is required").nullable().trim(),
                    longitude: yup.string().required("Longitude is required").nullable().trim(),
                    delivery_contact_name: yup.string().required("Contact name is required"),
                    phone: yup.string().required("Contact phone is required").length(14, "Contact phone must contain 10 digits"),
                    company: yup.string().required("Company or location name is required"),
                    apartment: yup.string(),
                    delivery_instructions: yup.string()
                })}
                onSubmit={(val, {setSubmitting}) => handleSubmitAddress(val, setSubmitting)}
            >
                {(formik) => (
                    <form autoComplete="off">
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <CustomTextField
                                    fullWidth
                                    onKeyDown={stopPropagationForTab}
                                    label="Company or Location name"
                                    {...formik.getFieldProps("company")}
                                />
                                <ErrorMessage name="company" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>{" "}
                            <Grid item xs={12}>
                                {window.google && (
                                    <PlacesAutocomplete
                                        value={formik.values.address}
                                        {...formik.getFieldProps("address")}
                                        onChange={formik.handleChange(`address`)}
                                        autoFocus={false}
                                        autoComplete={false}
                                        searchOptions={{
                                            componentRestrictions: {country: "us"}
                                        }}
                                        onSelect={(value) => {
                                            const setletlong = async () => {
                                                const result = await geocodeByAddress(value);
                                                await parseAddressComponents(result[0]?.address_components);
                                                const ll = await getLatLng(result[0]);

                                                const addressWithoutCountry = result[0].formatted_address.replace(/,\sUSA$/, "");
                                                formik.setFieldValue(`address`, addressWithoutCountry);
                                                formik.setFieldValue(`latitude`, ll.lat);
                                                formik.setFieldValue(`longitude`, ll.lng);
                                            };
                                            setletlong();
                                        }}
                                        onError={onError}
                                    >
                                        {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                                            <div style={{position: "relative"}}>
                                                <CustomTextField
                                                    fullWidth
                                                    id="falcon"
                                                    label="Delivery address"
                                                    value={formik.values.address}
                                                    {...getInputProps({
                                                        placeholder: "",
                                                        className: "location-search-input",
                                                        onKeyDown: stopPropagationForTab
                                                    })}
                                                    autoComplete="followmore"
                                                    name={`random-name-${Math.random()}`}
                                                />
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        zIndex: 1000,
                                                        width: "100%",
                                                        boxShadow: "0px 0px 5px rgba(0,0,0,0.5)"
                                                    }}
                                                >
                                                    {loading && (
                                                        <div style={{padding: "6px 12px", backgroundColor: "#ffffff"}}>Loading...</div>
                                                    )}
                                                    {suggestions.map((suggestion, index) => {
                                                        const style = suggestion.active
                                                            ? {
                                                                  cursor: "pointer",
                                                                  color: "#191919",
                                                                  fontSize: "16px",
                                                                  padding: "6px 16px",
                                                                  backgroundColor: "#F5F7FB"
                                                              }
                                                            : {
                                                                  backgroundColor: "#ffffff",
                                                                  color: "#191919",
                                                                  fontSize: "16px",
                                                                  padding: "6px 16px",
                                                                  cursor: "pointer"
                                                              };
                                                        return (
                                                            <div
                                                                key={index}
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    style
                                                                })}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                )}

                                <ErrorMessage name="address" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextField
                                    fullWidth
                                    onKeyDown={stopPropagationForTab}
                                    label="Apartment name (optional)"
                                    {...formik.getFieldProps("apartment_name")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextField
                                    fullWidth
                                    onKeyDown={stopPropagationForTab}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                                    }}
                                    inputProps={{maxLength: 50}}
                                    label="Contact name"
                                    {...formik.getFieldProps("delivery_contact_name")}
                                />
                                <ErrorMessage name="delivery_contact_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextField
                                    fullWidth
                                    label="Contact phone"
                                    onKeyDown={enforceFormat}
                                    onKeyUp={formatToPhone}
                                    inputProps={{maxLength: 14}}
                                    // {...(formik.values.phone && {
                                    //     InputProps: {
                                    //         startAdornment: <InputAdornment position="start">+1</InputAdornment>
                                    //     }
                                    // })}
                                    {...formik.getFieldProps("phone")}
                                />
                                <ErrorMessage name="phone" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextField
                                    fullWidth
                                    multiline
                                    minRows={2.5}
                                    label="Delivery instructions (optional)"
                                    inputProps={{maxLength: 250}}
                                    // {...(formik.values.phone && {
                                    //     InputProps: {
                                    //         startAdornment: <InputAdornment position="start">+1</InputAdornment>
                                    //     }
                                    // })}
                                    {...formik.getFieldProps("delivery_instructions")}
                                />
                            </Grid>
                        </Grid>

                        <Box mt={1}>
                            <ButtonGrouping
                                btnprops={[
                                    {
                                        btnTitle: "Cancel",
                                        sx: (t) => ({color: "#000"}),
                                        onClick: handleClose
                                    },
                                    {
                                        btnTitle: formik.isSubmitting ? "Sending" : "Save",
                                        disabled: !formik?.isValid || formik.isSubmitting,
                                        variant: "contained",
                                        onClick: () => {
                                            if (Parentformik.values.itemDetail.length === 0) {
                                                formik.handleSubmit();

                                                setTimeout(() => {
                                                    formik.resetForm();
                                                }, 2500);
                                            } else if (Parentformik.values.itemDetail.length > 0) {
                                                getBrandList(formik.values, formik);
                                            }
                                        },
                                        sx: (t) => ({color: t.palette.background.paper})
                                    }
                                ]}
                            />
                        </Box>
                        <DeleteModal
                            open={openCancelModal}
                            buttonTitle="Confirm"
                            title="Are you sure you want to change address?"
                            description="Selected brand is not deliverable at the selected delivery address."
                            handleClose={handleCancelModal}
                            handleSubmit={() => {
                                formik.handleSubmit();
                                setTimeout(() => {
                                    formik.resetForm();
                                }, 2500);
                                handleCancelModal();
                            }}
                        />
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default AddNewCustomerForm;
