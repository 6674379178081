import * as React from "react";
import {styled} from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {Typography} from "@mui/material";

const BpIcon = styled("span")(({theme}) => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === "dark"
            ? "0 0 0 1px rgb(16 22 26 / 40%)"
            : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    // backgroundImage:
    //     theme.palette.mode === "dark"
    //         ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
    //         : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
        outline: "2px auto #673AB7",
        outlineOffset: 2
    },
    "input:hover ~ &": {
        backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5"
    },
    "input:disabled ~ &": {
        boxShadow: "none",
        background: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)"
    }
}));

const BpCheckedIcon = styled(BpIcon)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.palette.mode === "dark" ? "0 0 0 1px rgb(2, 180, 108 / 40%)" : "inset 0 0 0 1px #00ab3a, inset 0 -1px 0 #00ab3a",
    "&:before": {
        display: "block",
        width: 16,
        height: 16,
        content: '""'
    },
    "input:hover ~ &": {
        backgroundColor: theme.palette.primary.main
    }
}));

// Inspired by blueprintjs
export function BpRadio(props) {
    return <Radio disableRipple color="default" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} {...props} />;
}

export default function CustomRadioButton(props) {
    const {options = [], value, onChange} = props;
    return (
        <FormControl>
            <RadioGroup aria-labelledby="demo-customized-radios" name="customized-radios" value={value} onChange={onChange}>
                {options.map((obj, index) => (
                    <FormControlLabel
                        sx={{height: "26px"}}
                        key={index}
                        value={obj?.value}
                        control={<BpRadio />}
                        label={
                            <Typography variant={obj?.value === value ? "checkBoxSelected" : "checkBoxUnSelected"}>{obj?.label}</Typography>
                        }
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}
