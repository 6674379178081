import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import StorageManager from "storage/StorageManager";

const useRoleBaseAccessController = (props) => {
    const {role = "ADMIN", modules = []} = props;
    console.log("modules", modules);
    const [permission, setPermission] = useState({});
    const navigation = useNavigate();

    // Change in keyarr strings will change the Header of permisssion checkbox
    const keyarr = ["read_permission", "create_permission", "update_permission", "delete_permission"];
    const crudStatus = (create, read, update, del) => ({
        [keyarr[0]]: read,
        [keyarr[1]]: create,
        [keyarr[2]]: update,
        [keyarr[3]]: del
    });

    const createPermission = (modules) => {
        console.log(modules,212121);
        const permission = {};
        modules.forEach((element) => {
            permission[element?.title] = crudStatus(true, true, true, true);
        });
        setPermission({
            role: "ADMIN",
            description: "This ia Admin",
            permission
        });
    };

    const getPagesWithPermission = (permission) => {
        const menuPath = modules.find(
            (obj) => permission && Object.keys(permission).indexOf(obj.title) !== -1 && permission[obj.title]?.read_permission
        );
        return menuPath?.route;
    };

    useEffect(() => {
        // navigation(getPagesWithPermission(permission?.permission), {replace: true});
    }, [permission]);

    useEffect(() => {
        const parsedData = StorageManager.get("module") ? JSON.parse(StorageManager.get("module")) : {};
        console.log(parsedData,"hEHEH")
        if (role === "ADMIN") {
            createPermission(modules);
        } else {
            const generatePermission = (value) => {
                const tempObj = {};
                value?.role_modules?.map((el) => {
                    tempObj[el?.module?.module_name] = {
                        read_permission: el?.permissionObj?.read_permission || false,

                        create_permission: el?.permissionObj?.create_permission || false,
                        update_permission: el?.permissionObj?.update_permission || false,
                        delete_permission: el.permissionObj?.delete_permission || false
                    };
                    return null;
                });
                return {...tempObj};
            };
            const grabRole = {
                role: parsedData?.role_name,
                description: parsedData?.description,

                permission: generatePermission(parsedData)
            };
            setPermission(grabRole);
        }
    }, [role]);

    return {
        permission,
        setPermission
    };
};

export default useRoleBaseAccessController;
