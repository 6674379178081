import CommonDilogBox from "commonComponent/CommonDilogBox/CommonDilogBox";
import React, { useEffect, useState } from "react";
import SideSection from "./sideSection/SideSection";
import HeaderSection from "./headerSection/HeaderSection";
import { Form, Formik } from "formik";
import { Box } from "@mui/material";

import CreateOrderFooter from "./createOrderFooter";

import MainSection from "./mainSection";
import useCreateOrderController from "./createOrder.controller";
import { API, NetworkManager } from "network/core";
import useToggleDilog from "hooks/useToggleDilog";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import * as Yup from "yup";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import useTempDrawer from "hooks/useTempDrawer";
import { convertToLocal, convertToUTC } from "utils/app-dates/dates";

const drawerWidth = 280;

const CreateOrder = () => {
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
    const [openRefundDialog, setOpenRefundDialog] = useState(false);
    const [refundAmount, setRefundAmount] = useState(0);

    const [extraAmount, setExtraAmount] = useState(0);
    const [remainingAmountToPaid, setRemainingAmountToPaid] = useState(0);
    const [orderSaved, setOrderSaved] = useState(false);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [orderId, setOrderId] = React.useState(null);
    const { toggleDilog, open } = useToggleDilog();
    const [flag, setFlag] = React.useState(true);
    const [orderDetail, setOrderDetail] = React.useState(null);
    const [orderedBrandId, setOrderedBrandId] = React.useState("");
    const [orderedMenuId, setOrderedMenuId] = React.useState("");
    const [orderItems, setOrderitems] = React.useState(null);
    const [closeModal, setCloseModal] = React.useState(false);
    const [waitForItem, setWaitForItem] = useState(false);
    const [footerHeight, setFooterHeight] = useState("panel1");

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const OrderId = searchParams.get("templateId");
    const Reorder = searchParams.get("reOrder");
    const CustomerId = searchParams.get("customerId");
    const CustomerName = searchParams.get("customerName");
    const Tab = searchParams.get("tab");
    const { toggleDrawer, state } = useTempDrawer();

    const handleReset = () => {
        setMobileOpen(false);
        setOrderId(null);
        setFlag(true);
        setOrderDetail(null);
        setOrderedBrandId("");
        setOrderedMenuId("");
        setOrderitems(null);
        dispatch({
            type: "ORDER"
        });
        navigate(`/menus?tab=${Tab}`);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const COC = useCreateOrderController();

    const sleep = () => new Promise((resolve) => setTimeout(resolve, 4000));

    const handleQuoteView = (e) => {
        toggleDrawer("right", "SendQuote")(e);
    };
    const handlePaymentView = (e) => {
        toggleDrawer("right", "Payment")(e);
    };

    const PackageDefault = { INDIVIDUAL: "Individual", TRAY: "Tray" };

    const filterEmptyAndNull = (obj) => {
        const result = {};

        Object.entries(obj).forEach(([key, value]) => {
            // Omit empty strings and null values
            if (value !== '' && value !== null) {
                result[key] = value;
            }
        });

        return result;
    };


    let orderPriceForAllItem = 0;
    const serviceTaxTotal = 0;
    const discountedPrice = 0;
    const salesTaxTotalPrice = 0;
    let overAllPrice = 0;
    const preferredPriceTotal = 0;




 const handleItemTotalPrice = (values) => {
        const { itemDetail } = values;
        let totalPrice = 0;

        itemDetail?.forEach((obj, itemIndex) => {
            let tempPrice = 0;
            tempPrice += parseFloat(itemDetail[itemIndex]?.price);
            tempPrice *= parseFloat(itemDetail[itemIndex]?.quantity !== "" ? itemDetail[itemIndex]?.quantity : 1);
            itemDetail[itemIndex]?.checkboxModifier?.forEach((row) => {
                if (row.allowQuantityModification) {
                    // for custom modifier (means for those we can modify quantity)
                    tempPrice += parseFloat(row?.quantity !== "" ? row?.quantity : 1) * parseFloat(row.price);
                } else {
                    // for modifier that doest not have modifiable quantity
                    // for those we have to take item quantity * modifier price
                    tempPrice +=
                        parseFloat(itemDetail[itemIndex]?.quantity !== "" ? itemDetail[itemIndex]?.quantity : 1) * parseFloat(row.price);
                }
            });
            itemDetail[itemIndex]?.radioModifier?.forEach((row) => {
                const obj = JSON.parse(row);
                if (obj.allowQuantityModification) {
                    tempPrice += parseFloat(obj?.quantity !== "" ? obj?.quantity : 1) * parseFloat(obj.price);
                } else {
                    tempPrice +=
                        parseFloat(itemDetail[itemIndex].quantity !== "" ? itemDetail[itemIndex].quantity : 1) * parseFloat(obj.price);
                }
            });

            totalPrice += tempPrice;
            orderPriceForAllItem = totalPrice;
        });
       
        return totalPrice;
    };






    const calculateOverallPrice = () => {
        let finalPrice = parseFloat(orderPriceForAllItem) + parseFloat(serviceTaxTotal) + parseFloat(salesTaxTotalPrice);
        if (preferredPriceTotal < finalPrice) {
            finalPrice -= Number(preferredPriceTotal);
        }
        overAllPrice = finalPrice;
    
        return parseFloat(finalPrice).toFixed(2);
    };


    const handleUpdateOrder = async (values, reOrderid = null, formik = null) => {

        let totalPrice=handleItemTotalPrice(values)
        totalPrice=calculateOverallPrice()

        const payload = {
            "orderTemplateName":values.headerData.template.name,
            serviceType: values.headerData.service,
            noOfGuests: values.headerData.forHowMany,
            brandId: values.selectedBrand,
            menuId: values.selectedMenu,
            totalPrice:totalPrice
            // kitchenLocationId: values.kitchenLocationId,
            
        };

        payload.specialInstruction = values.specialInstruction;
        payload.deliveryInstruction = values.deliveryInstruction;
  

        payload.kitchenNote = values.kitchenNote;

        if (values.itemDetail.length > 0) {
            const items = [];

            values.itemDetail.forEach((row, index) => {
                const modifier = [];
                const tempItem = {
                    itemId: row.itemId,
                    quantity: row.quantity,
                    item_type:row.item_type,
                    item_ordering: index
                };
               
                if(row.item_type ===1){
                     // only for regular items
                    tempItem.menuCategoryId=row.menuCategoryId
                }
                if (row.specialInstruction !== "") {
                    tempItem.specialInstruction = row.specialInstruction;
                }
                if (row.packaging.length > 0) {
                    const tempCol = JSON.parse(row.packaging[0]);
                    tempItem.packagingType = PackageDefault[tempCol.name];
                }
                
                if (row.checkboxModifier.length > 0) {
                    row.checkboxModifier.forEach((col) => {
                        const obj = {
                            modifierGroupId: col.modifierGroupId,
                            modifierId: col.modifierId,
                            itemId: col.itemId,
                            quantity: col.quantity,
                            itemQuantity: row.quantity,
                            series_no: col.series_no,
                        };
                        if (!col?.allowQuantityModification) {
                            obj.quantity = row.quantity;
                        }
                        modifier.push(obj);
                    });
                }
                if (row.radioModifier.length > 0) {
                    row.radioModifier.forEach((col) => {
                        const col1 = JSON.parse(col);
                        const obj = {
                            modifierGroupId: col1.modifierGroupId,
                            modifierId: col1.modifierId,
                            itemId: col1.itemId,
                            quantity: col1.quantity,
                            itemQuantity: row.quantity,
                            series_no: col1.series_no,
                        };
                        if (!col?.allowQuantityModification) {
                            obj.quantity = row.quantity;
                        }
                        modifier.push(obj);
                    });
                    // modifier.push(row.radioModifier)
                    
                }
                if(row.item_type ===1){
                    // if not custom menu item
                   tempItem.modifiers = modifier
                }
                items.push(tempItem);
            });

            payload.items = items;
            // payload.modifiers = modifier;
        }

        const instance = NetworkManager(!values.headerData.template.templateId ? API.TEMPLATE.ADD:API.TEMPLATE.UPDATE(values.headerData.template.templateId));


        const response = await instance.request(filterEmptyAndNull(payload));

        if (response.success) {
           
            setOrderSaved(true);
            if (formik) {
                formik.setTouched({});
            }
            setCloseModal(true);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const getOrderDetailById = async (id) => {
        const instance = NetworkManager(API.TEMPLATE.QUOTEDETAIL(id));
        const response = await instance.request();
        if (response.success) {
            setOrderDetail(response.data);

            const schema = {
                itemDetail: [],
                headerData: {
                    template: {
                        name: "",  templateId: null
                    },
                    service: "Catering",
                    forHowMany: "",
                },
                selectedBrand: "",
                brandName: "",
                selectedMenu: '',
                categories: {},
                items: [],
                specialInstruction: "",
                kitchenNote: "",
                deliveryInstruction: "",
                itemModifier: {},
                clickOnSaveDraft: false,
                isSaved: false,
            };

            const { 
                orderTemplateCategory
                , orderTemplateDetails
            } = response.data;
            
            setOrderedMenuId(orderTemplateDetails?.menu?.menu_id ?? "");
            setOrderedBrandId(orderTemplateDetails?.brand?.brand_id ?? "");
            schema.selectedBrand = orderTemplateDetails?.brand?.brand_id ?? "";
            schema.brandName = orderTemplateDetails?.brand?.brand_name;
            schema.selectedMenu = orderTemplateDetails?.menu?.menu_id ?? "";
            schema.brandName = orderTemplateDetails?.brand?.brand_name ?? ""


            schema.specialInstruction = orderTemplateDetails?.special_instructions ?? "";
            schema.kitchenNote = orderTemplateDetails?.kitchen_note ?? "";
            schema.deliveryInstruction = orderTemplateDetails?.delivery_instructions ?? "";
           
            schema.headerData.forHowMany = orderTemplateDetails?.no_of_guest ?? "";
            schema.headerData.service = orderTemplateDetails?.service_type ?? "Catering";
          
            schema.headerData.template.name = orderTemplateDetails?.order_template_name  ?? ""  ;
            schema.headerData.template.templateId = orderTemplateDetails?.order_template_id ?? null;
        
            const tempItemList = [];
            const itemDetailList = [];
            orderTemplateCategory.forEach((row) => {
                row.orderTemplateItems.forEach((col) => {
                    const tempCheckboxList = [];
                    const tempRadioList = [];

                    col.orderTemplateItemsModifiers.forEach((modifier) => {
                        if (modifier.modifier_group.is_allow_multiple) {
                            tempCheckboxList.push({
                                modifierGroupId: modifier.modifier_group.modifier_group_id,
                                modifierId: modifier.modifier.modifier_id,
                                itemId: col.item?.item_id,
                                quantity: modifier.quantity,
                                series_no: modifier.series_no,
                                price: parseFloat(modifier?.modifier?.price).toFixed(2),
                                modifierGroupName: modifier.modifier_group.modifier_group_name,
                                allowQuantityModification: modifier.modifier_group.allow_quantity_modification
                            });
                        } else {
                            tempRadioList.push(
                                JSON.stringify({
                                    modifierGroupId: modifier.modifier_group.modifier_group_id,
                                    modifierId: modifier.modifier.modifier_id,
                                    itemId: col.item?.item_id,
                                    quantity: modifier.quantity,
                                    price: parseFloat(modifier?.modifier?.price).toFixed(2),
                                    series_no: modifier.series_no,
                                    modifierGroupName: modifier.modifier_group.modifier_group_name,
                                    allowQuantityModification: modifier.modifier_group.allow_quantity_modification

                                })
                            );
                        }
                    });

                    itemDetailList.push({
                        checkboxModifier: tempCheckboxList,
                        radioModifier: tempRadioList,
                        price: col.item.price,
                        quantity: col.quantity,
                        specialInstruction: col.special_instruction,
                        packaging: [],
                        itemId: col.item.item_id,
                        minimum_order_quantity: Number(col?.item?.minimum_order_quantity),
                        itemOrdering: col.item_ordering,
                        servingSize:Number(col.item.serving_size),
                        menuCategoryId: col.menu_category_id,
                        item_type: col?.item?.item_type
                    });
                });
            });
            itemDetailList.sort((a, b) => a.itemOrdering - b.itemOrdering);
            itemDetailList.forEach((row1) => {
                tempItemList.push(row1.itemId);
            });
           

            schema.itemDetail = itemDetailList;
            COC.handleInitialValues(schema);
            setTimeout(() => {
                setOrderitems(tempItemList);
            }, 100);
            
        }
    };

    React.useEffect(() => {
        if (open) {
            setFlag(false);
            if (OrderId) {
                getOrderDetailById(OrderId);
            }
        }
    }, [open, OrderId]);

    const { initialValues } = COC;

    const handleClose = () => {
        toggleDilog(false)();
    };

    function numberToWords(number) {
        const units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
        const teens = ['', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
        const tens = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

        function convertToWordsLessThanThousand(num) {
            if (num === 0) {
                return '';
            } else if (num < 10) {
                return units[num];
            } else if (num < 20) {
                return teens[num - 10];
            } else {
                const digit = num % 10;
                const tenDigit = Math.floor(num / 10);
                return tens[tenDigit] + (digit !== 0 ? ' ' + units[digit] : ''); // eslint-disable-line prefer-template
            }
        }

        function convertToWords(num) {
            if (num === 0) {
                return 'zero';
            }

            const billion = Math.floor(num / 1000000000);
            const million = Math.floor((num % 1000000000) / 1000000);
            const thousand = Math.floor((num % 1000000) / 1000);
            const remainder = num % 1000;

            let result = '';

            if (billion > 0) {
                result += convertToWordsLessThanThousand(billion) + ' billion '; // eslint-disable-line prefer-template
            }

            if (million > 0) {
                result += convertToWordsLessThanThousand(million) + ' million '; // eslint-disable-line prefer-template
            }

            if (thousand > 0) {
                result += convertToWordsLessThanThousand(thousand) + ' thousand '; // eslint-disable-line prefer-template
            }

            if (remainder > 0) {
                result += convertToWordsLessThanThousand(remainder);
            }

            return result.trim();
        }

        return convertToWords(number);
    }

    const dynamicValidation = (values) => {
        const error = {
            itemModifier: []
        };

        values?.itemDetail?.forEach((row, index) => {
            const obbj = {};
            error.itemModifier.push(null)
            // if (Number(row?.quantity) < Number(row?.minimum_order_quantity)) {

            //     error.itemModifier[row.itemId] = `Minimum ${numberToWords(JSON.stringify(row.minimum_order_quantity)?.split(".")?.[0])} quantity required`;
            // }
            if (row?.checkboxModifier?.length) {
                row?.checkboxModifier?.forEach((col) => {
                    if (col?.modifierGroupId in obbj) {
                        obbj[col.modifierGroupId] += 1;
                    } else {
                        obbj[col?.modifierGroupId] = 1;
                    }
                    if (col.allowQuantityModification) {
                        if (col?.quantity < row?.quantity / 2) {
                            if (col?.modifier_group_name?.toLowerCase() === "drinks") {
                                error.itemModifier[index] = `For drinks modifier minimum quantity should be 1`;
                            } else {
                                error.itemModifier[index] = `For each modifier quantity should be more than half of quantity of item`;
                            }
                        }
                    }
                });
            }
            if (row?.radioModifier?.length) {
                row?.radioModifier?.forEach((col) => {
                    const col1 = JSON.parse(col);
                    if (col1?.modifierGroupId in obbj) {
                        obbj[col1?.modifierGroupId] += 1;
                    } else {
                        obbj[col1?.modifierGroupId] = 1;
                    }
                    if (col1.allowQuantityModification) {
                        if (col1?.quantity < row?.quantity / 2) {
                            error.itemModifier[index] = `For each modifier quantity should be more than half of quantity of item`;
                        }
                    }
                });
            }

            if (row.modifierData) {
                Object.keys(row?.modifierData).forEach((item) => {
                    if (row?.modifierData[item]?.is_required) {
                        if (!(item in obbj)) {
                            // es-lint disable line
                            error.itemModifier[index] = `Modifier ${row?.modifierData[item]?.name?.toLowerCase()} is required`;
                        }
                    }
                    if (
                        row?.modifierData[item]?.minimum_selections &&
                        Number(row.modifierData[item].minimum_selections) > Number(obbj[item] || 0)
                    ) {
                        error.itemModifier[
                            index
                        ] = ` Minimim ${numberToWords(row?.modifierData[item]?.minimum_selections)} of ${row?.modifierData[item]?.name.toLowerCase()} is required`;
                    }
                    if (row?.modifierData[item]?.maximum_selections && Number(row.modifierData[item].maximum_selections) > 0) {
                        if (Number(row.modifierData[item].maximum_selections) < Number(obbj[item] || 0)) {

                            error.itemModifier[
                                index
                            ] = ` Maximum ${numberToWords(row?.modifierData[item]?.maximum_selections)} of ${row?.modifierData[item]?.name.toLowerCase()} is allowed`;
                        }
                    }
                });
            }
        });

        const errorLength = error.itemModifier.filter((a)=>a!==null)
        if(errorLength.length ===0){
            return {}
        }
        return error;
    };

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validate={dynamicValidation}
                validationSchema={Yup.object().shape({
                    itemDetail: Yup.array()
                        // .of(
                        //     Yup.object().shape({
                        //         packaging: Yup.array().min(1, "Packaging type is required")
                        //     })
                        // )
                        .min(1, "At least one item is required")
                        .required()
                        .nullable(),
                    headerData: Yup.object({
                        template: Yup.object()
                            .shape({
                                name: Yup.string().required("*Required field").nullable()
                            })
                            .nullable(),
                         service: Yup.string().required("*Required field").nullable(),
                         forHowMany: Yup.string().required("*Required field").nullable()
                    }),
                    selectedMenu: Yup.string().required("*Required field").nullable(),
                    selectedBrand: Yup.string().required("*Required field").nullable(),
                })}
                onSubmit={(val, formikHelpers) => {
                    formikHelpers.setTouched({});
                    handleUpdateOrder(val);
                }}
            >
                {(formik) => (
                    <Form>
                        <CommonDilogBox openState="CreateTemplate">
                            {open && (
                                <Box sx={{ display: "flex" }}>
                                    <SideSection
                                        formik={formik}
                                        initialValues={COC.defaultValues}
                                        drawerWidth={drawerWidth}
                                        mobileOpen={mobileOpen}
                                        handleDrawerToggle={handleDrawerToggle}
                                        orderId={orderId}
                                        ordereredId={orderId?.order_id}
                                        editScreen={OrderId}
                                        navigate={navigate}
                                        orderedMenuId={orderedMenuId}
                                        orderedBrandId={orderedBrandId}
                                        setOrderedMenuId={setOrderedMenuId}
                                        handleReset={handleReset}
                                        Tab={Tab}
                                        orderSaved={orderSaved}
                                        closeModal={closeModal}
                                        setCloseModal={setCloseModal}
                                        setWaitForItem={setWaitForItem}
                                        waitForItem={waitForItem}
                                    />
                                    <Box
                                        component="main"
                                        sx={{
                                            display: "flex",
                                            flexGrow: 1,
                                            p: 0,
                                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                                            position: "relative"
                                        }}
                                    >
                                        <HeaderSection
                                            orderId={orderId}
                                            formik={formik}
                                            drawerWidth={drawerWidth}
                                            customerName={CustomerName}
                                            customerId={CustomerId}
                                            editScreen={OrderId}
                                            handleDrawerToggle={handleDrawerToggle}
                                        />
                                        <Box
                                            sx={{
                                                marginTop: "60px",
                                                pl: "1.275rem",
                                                pt: "1.25rem",
                                                pr: "1.25rem",
                                                height: `calc(100vh - ${footerHeight === "panel1" ? '205px' : '205px'})`,
                                                overflow: "scroll",
                                                marginBottom: 2,
                                                width: "100%"
                                            }}
                                        >
                                            <MainSection
                                                orderId={orderId}
                                                formik={formik}
                                                orderItems={orderItems}
                                                setWaitForItem={setWaitForItem}
                                            />
                                        </Box>
                                        <Box sx={{ position: "fixed", bottom: 10, width:'calc(100% - 290px)', background: "#ffff", zIndex: 1 }}>
                                            <CreateOrderFooter
                                                navigate={navigate}
                                                formik={formik}
                                                handleUpdateOrder={(values) => {
                                                    handleUpdateOrder(values, null, formik)
                                                }}
                                                orderId={orderId}
                                                initialValues={COC.defaultValues}
                                                handleReset={handleReset}
                                                setOpenDialog={setOpenDialog}
                                                setOpenPaymentDialog={setOpenPaymentDialog}
                                                setOpenRefundDialog={setOpenRefundDialog}
                                                setRefundAmount={setRefundAmount}
                                                setExtraAmount={setExtraAmount}
                                                handlePaymentView={handlePaymentView}
                                                Reorder={Reorder}
                                                setFooterHeight={setFooterHeight}
                                            />

                                            
                                          
                                          
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </CommonDilogBox>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CreateOrder;
