import {Stack, Typography, Box, Grid} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {enforceFormat, formatToPhone, generateUrl, getManagers} from "utils/helperFunctions/helpers";
import {ErrorMessage, Form, Formik} from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import React, {useState, useEffect, useLayoutEffect} from "react";
import * as yup from "yup";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {API, NetworkManager} from "network/core";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import Loader from "ui-component/Loader";

const formSchema = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company: "",
    companyId: "",
    accountManager: ""
};

const CreateCustomerForm = (props) => {
    const {toggleDrawer} = useTempDrawer();
    const [initialValues, setInitialValues] = useState(formSchema);
    const {sideBarStateName = false} = props;
    const {handleClose, Parentformik, handleIsEdited} = props;
    const [existingCompany, setExistingCompany] = useState([]);
    const [companySearch, setCompanySearch] = useState("");
    const [managers, setManagers] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const getExisitingCompany = async () => {
        const instance = NetworkManager(API.COMPANY.GET);
        const response = await instance.request({}, [`?company_name=${companySearch}`]);
        if (response.success) {
            const data = response.data.companyList;
            setExistingCompany(data);
        }
    };

    const createCompany = async (name) =>
        new Promise((resolve) => {
            const instance = NetworkManager(API.COMPANY.ADD);
            instance.request({company_name: name}).then((response) => {
                if (response.success) {
                    resolve(response.data?.company?.company_id);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: response.message,
                            variant: "alert",
                            alert: {
                                color: "success"
                            },
                            close: true
                        })
                    );
                } else {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: response.message,
                            variant: "alert",
                            alert: {
                                color: "error"
                            },
                            close: true
                        })
                    );
                }
            });
        });

    useEffect(() => {
        if (companySearch) {
            getExisitingCompany();
        } else {
            setExistingCompany([]);
        }
    }, [companySearch]);
    useLayoutEffect(() => {
        getManagers(setManagers, setLoading);
    }, []);

    const addNewCustomer = async (payload, setSubmitting) => {
        const instance = NetworkManager(API.CUSTOMER.ADD);

        const response = await instance.request(payload);
        if (response.success) {
            handleIsEdited();
            const {customer} = response?.data;
            const data = {
                customerName: customer?.full_name,
                name: customer?.full_name,
                email: customer?.email,
                phone: customer?.phone,
                company: customer?.company,
                customerAddresses: [],
                userId: customer?.user_id
            };
            Parentformik.setFieldValue("headerData.customer", data);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
        handleClose();

        setSubmitting(false);
    };

    const handleNewCustomer = async (val, setSubmitting) => {
        setSubmitting(true);
        // const fullName = `${val.first_name} ${val.last_name}`;
        const payload = {
            email: val.email,
            full_name: val.first_name,
            last_name: val.last_name,
            phone: val.phone
        };
        if (val?.accountManager) {
            payload.account_manager_id = val?.accountManager;
        }
        if (val.company !== "") {
            if (val.companyId !== "") {
                payload.company_id = val.companyId;
                addNewCustomer(payload, setSubmitting);
            } else {
                createCompany(val.company).then((resp) => {
                    payload.company_id = resp;
                    addNewCustomer(payload, setSubmitting);
                });
            }
        } else {
            addNewCustomer(payload, setSubmitting);
        }
    };
    const stopPropagationForTab = (event) => {
        if (event.key === "Tab") {
            event.stopPropagation();
        }
    };
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={yup.object({
                first_name: yup.string().required("First name is required").nullable().trim(),
                last_name: yup.string().required("Last name is required").nullable().trim(),
                email: yup.string().required("Email is required").email("Enter valid email").nullable().trim(),
                phone: yup.string().nullable().trim(),
                company: yup.string().nullable().trim()
            })}
            onSubmit={(val, {setSubmitting}) => handleNewCustomer(val, setSubmitting)}
        >
            {(formik) => (
                <Form>
                    {loading && <Loader />}
                    <TemporaryDrawer sideBarStateName={sideBarStateName} resetForm={formik.resetForm} touched={formik.touched}>
                        <Stack sx={{height: "100%"}} justifyContent="space-between">
                            <Stack spacing={4}>
                                <Typography sx={{fontSize: "1.625rem", fontWeight: 700, color: "#000"}}>Create customer</Typography>
                                <Stack spacing={3} sx={{marginTop: "28px !important"}}>
                                    <CustomTextField
                                        placeholder="First name"
                                        label="First name"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                                        }}
                                        inputProps={{maxLength: 50}}
                                        {...formik.getFieldProps("first_name")}
                                    />
                                    <ErrorMessage name="first_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    <CustomTextField
                                        placeholder="Last name"
                                        label="Last name"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                                        }}
                                        inputProps={{maxLength: 50}}
                                        {...formik.getFieldProps("last_name")}
                                    />
                                    <ErrorMessage name="last_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    <CustomTextField
                                        placeholder="Email address"
                                        label="Email address"
                                        inputProps={{maxLength: 50}}
                                        {...formik.getFieldProps("email")}
                                    />
                                    <ErrorMessage name="email" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    <CustomTextField
                                        fullWidth
                                        label="Phone number (optional)"
                                        onKeyDown={enforceFormat}
                                        onKeyUp={formatToPhone}
                                        inputProps={{maxLength: 14}}
                                        // {...(formik.values.phone && {
                                        //     InputProps: {
                                        //         startAdornment: <InputAdornment position="start">+1</InputAdornment>
                                        //     }
                                        // })}
                                        {...formik.getFieldProps("phone")}
                                    />
                                    <ErrorMessage name="phone" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    <Grid item xs={12}>
                                        {/* <CustomTextField fullWidth  onKeyDown={stopPropagationForTab} label="Company (optional)" {...formik.getFieldProps("company")} /> */}

                                        <CustomTextField
                                            {...props}
                                            fullWidth
                                            name="company"
                                            label="Company (optional)"
                                            placeholder=""
                                            onKeyDown={stopPropagationForTab}
                                            value={formik.values.company}
                                            onChange={(e) => {
                                                setCompanySearch(e.target.value);
                                                formik.setFieldValue("company", e.target.value);
                                            }}
                                        />
                                        {!!existingCompany?.length && (
                                            <Box
                                                sx={{
                                                    p: 1,
                                                    boxShadow: "0px 0px 3px rgba(0,0,0,0.3)",
                                                    borderRadius: 0.5,
                                                    position: "absolute",
                                                    width: "100%",
                                                    bgcolor: "#fff",
                                                    maxHeight: "200px",
                                                    overflow: "auto",
                                                    zIndex: 1380
                                                }}
                                            >
                                                {existingCompany?.map((obj, i) => (
                                                    <Box
                                                        key={i}
                                                        sx={{p: 0.2, py: 0.2, cursor: "pointer", "&:hover": {bgcolor: "#Fafafa"}}}
                                                        onClick={() => {
                                                            formik.setFieldValue("company", obj.company_name);
                                                            formik.setFieldValue("companyId", obj.company_id);
                                                            setExistingCompany([]);
                                                        }}
                                                    >
                                                        <Typography variant="body3" fontSize="0.875rem" fontWeight={500}>
                                                            {obj?.company_name}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomSelect
                                            label="Account manager"
                                            menuItems={managers}
                                            {...formik.getFieldProps("accountManager")}
                                        />
                                    </Grid>
                                </Stack>
                            </Stack>

                            <ButtonGrouping
                                btnprops={[
                                    {
                                        btnTitle: "Cancel",
                                        sx: (t) => ({color: t.palette.error.dark, width: "92px"}),
                                        onClick: (e) => {
                                            formik.resetForm();
                                            toggleDrawer("right", false)(e);
                                        }
                                    },
                                    {
                                        btnTitle: "Save",
                                        variant: "contained",
                                        sx: (t) => ({color: t.palette.background.paper, width: "92px"}),
                                        onClick: (e) => {
                                            formik.handleSubmit();

                                            formik.validateForm().then((res) => {
                                                if (Object.keys(res).length === 0) {
                                                    toggleDrawer("right", false)(e);
                                                    setTimeout(() => {
                                                        formik.resetForm();
                                                    }, 1500);
                                                }
                                            });
                                        }
                                    }
                                ]}
                            />
                        </Stack>
                    </TemporaryDrawer>
                </Form>
            )}
        </Formik>
    );
};

export default CreateCustomerForm;
