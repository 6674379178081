const useSettingSectionController = () => {
    const RadioButtons = [
        {lable: "Hot", value: "HOT"},
        {lable: "Warm", value: "WARM"},
        {lable: "Cold", value: "COLD"}
    ];
    const checkBoxs = [
        {lable: "Tray", value: "tray"},
        {lable: "Individual", value: "individual"}
    ];

    return {
        RadioButtons,
        checkBoxs
    };
};

export default useSettingSectionController;
