import React from "react";
import {DatePicker, DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import CustomTextField from "./CustomTextField";

const CustomeDatePicker = (props) => {
    const {valueBasic, setValueBasic, ...restProps} = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}> 
            <div className="custom-date-picker">
                <DesktopDatePicker
                    {...restProps}
                    format="M/dd/yyyy"
                    views={["day"]}
                    
                    
                    displayStaticWrapperAs="desktop"
                    PaperProps={{
                        sx: {
                            "& .MuiPickersDay-root": {
                                "& .Mui-selected": {
                                    color: 'white'
                                },
                                "& .Mui-selected:focus": {
                                    backgroundColor: "#00ab3a !important",
                                    background: "#00ab3a !important"
                                },
                            },"& .MuiPickersDay-root:focus": {
                                
                                    backgroundColor: "#00ab3a",
                                    background: "#00ab3a"
                               
                            },
                            "& .Mui-selected:focus": {
                                backgroundColor: "#00ab3a !important",
                                background: "#00ab3a !important"
                            },
                            "& .MuiPickersDay-today":{
                                "& .Mui-selected": {
                                    color: 'white'
                                },
                                "& .Mui-selected:focus": {
                                    backgroundColor: "#00ab3a !important",
                                    background: "#00ab3a !important"
                                },
                            }
                            ,
                            "& .MuiPickersDay-root:not":
                            {
                                "& .Mui-selected": {
                                    border: "1px solid #697586",
                                    color: 'white'
                                } 
                            }
 
                        }
                    }}
                    renderInput={(props) => <CustomTextField fullWidth placeholder="mm/dd/yyyy" sx={{minWidth: "7em"}} {...props} />}
                    value={valueBasic}
                    onChange={(newValue) => {
                        setValueBasic(newValue);
                    }}
                />
            </div>
        </LocalizationProvider>
    );
};

export default CustomeDatePicker;
