import {Box} from "@mui/system";
import React, {useState} from "react";
import CustomTextField from "./CustomTextField";
import {IconButton, ListItemText, MenuItem, Popover} from "@mui/material";
import {useStyles} from "./useStyles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import _debounce from "lodash/debounce";
import {enforceFormat, onlyNumbersWithOutDecimal} from "utils/helperFunctions/helpers";

const CustomTextDropDown = (props) => {
    const {menuItems, ids, handleChange, label,key} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [inputValue, setInputValue] = useState("");
    const [width, setWidth] = useState("");
    const styles = useStyles();
    const handleClick = (e) => {
        const parent = document.getElementById(ids);
        setWidth(`${parent.offsetWidth}px`);
        setAnchorEl(parent);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const fireHandleChange = _debounce((val) => handleChange(val), 1500);
    const handleInputChange = (e) => {
        const value = e?.target?.value;
        fireHandleChange(value);
        // _debounce(() => handleChange(inputValue), 1000);
        setInputValue(value);
    };
    const handleSelect = (val) => {
        handleChange(inputValue, val);
        setInputValue("");
        handleClose();
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const labelChecck = label === "Total" || label === "LTV" || label === "AOV";
    return (
        <Box
            sx={[styles.textDropDown.display]}
            // className="text-select"
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
            }}
            id={ids}
        >
            <Popover
                id={id}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                anchorEl={anchorEl}
                PaperProps={{
                    sx: {
                        width: width,
                        boxShadow: "0px 0px 8px rgba(0,0,0,0.2) !important",
                        paddingX: "0.5rem",
                        paddingY: "0.1rem"
                    }
                }}
                slotProps={{
                    paper: {
                        sx: {boxShadow: "0px 0px 8px rgba(0,0,0,0.2) !important"}
                    }
                }}
            >
                {menuItems.map((item) => (
                    <MenuItem
                        sx={[styles.textDropDown.menuItem]}
                        // sx={[style]}
                        key={item?.label}
                        value={item?.value}
                        onClick={() => handleSelect(item?.id)}
                    >
                        <ListItemText primary={item?.label} defaultValue={item?.value} />
                    </MenuItem>
                ))}
            </Popover>
            <CustomTextField
                height="32px"
                value={inputValue}
                placeholder={label}
                
                onKeyDown={labelChecck ? enforceFormat : onlyNumbersWithOutDecimal}
                type="number"
                sx={{width: "100%"}}
                onWheel={(e) => e.target.blur()}
                InputProps={{
                    endAdornment: (
                        <Box sx={[styles.textDropDown.endAdornment]}>
                            <IconButton onClick={handleClick} sx={[styles.textDropDown.display]} disabled={!inputValue}>
                                <KeyboardArrowDownIcon />
                            </IconButton>
                        </Box>
                    )
                }}
                onChange={handleInputChange}
            />
        </Box>
    );
};

export default CustomTextDropDown;
