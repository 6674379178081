import React from "react";
import {useDropDownController} from "./dropdown.controller";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Unchecked from "assets/images/icons/unchecked.svg";
import Checked from "assets/images/icons/lightCheckedBox.svg";

const DropDown = (props) => {
    const {selectedItem, MenuPaperProps, FormControlProps, optionsBoxProps, placeHolder, helperText, disabled} = props;
    const ddc = useDropDownController(props);
    return (
        <>
            <FormControl {...FormControlProps}>
                <Stack
                    id="basic-button"
                    direction="row"
                    alignItems="center"
                    onClick={!disabled && ddc.handleClick}
                    sx={{
                        padding: "10.5px",
                        border: (t) => `1px solid ${ddc.open ? t.palette.primary.main : t.palette.other.fieldBorder}`,
                        borderRadius: 1,
                        color: "#212121"
                    }}
                >
                    <Box sx={{width: "calc(100% - 25px)"}}>
                        {selectedItem?.length > 0 ? (
                            <Typography noWrap sx={(t) => disabled && {color: t.palette.other.fieldBorder}}>
                                {ddc.renderValue(selectedItem)}
                            </Typography>
                        ) : (
                            <Typography
                                fontSize={14}
                                sx={{color: (t) => `${disabled ? t.palette.other.fieldBorder : t.palette.text.darkgray}`}}
                                noWrap
                            >
                                {placeHolder}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{pl: 0.5, display: "flex", alignItems: "center"}}>
                        {ddc.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon sx={{marginTop: "2px"}} />}
                    </Box>
                </Stack>

                <Menu
                    id="basic-menu"
                    anchorEl={ddc.anchorEl}
                    open={ddc.open}
                    onClose={ddc.handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button"
                    }}
                    PaperProps={{
                        ...MenuPaperProps
                    }}
                >
                    {props?.hasSearchBar && (
                        <Box sx={{p: 1}}>
                            <TextField
                                fullWidth
                                id="searchBox"
                                placeholder="Search"
                                type="search"
                                value={ddc.defaultSearch}
                                onChange={ddc.handleDefaultSearch}
                            />
                        </Box>
                    )}

                    <Box {...optionsBoxProps}>
                        {ddc.defaultOptions.map((obj, i) => (
                            <MenuItem key={i} value={obj?.value} onClick={() => ddc.defaultHandleChange(obj)}>
                                {props?.multiSelect && props?.withCheckBox ? (
                                    <>
                                        <Checkbox
                                            disableRipple
                                            icon={<img src={Unchecked} style={{width: "18px", height: "18px"}} alt="checked" />}
                                            checkedIcon={<img src={Checked} alt="checkbox" style={{width: "18px", height: "18px"}} />}
                                            checked={selectedItem?.indexOf(obj?.value) > -1}
                                        />
                                        <ListItemText primary={obj?.label} />
                                    </>
                                ) : (
                                    obj?.label
                                )}
                            </MenuItem>
                        ))}
                        {ddc.defaultOptions?.length === 0 && <Typography textAlign="center">No option available</Typography>}
                    </Box>

                    {props?.clearBtn && (
                        <Stack alignItems="flex-end">
                            <Button onClick={ddc.handleClearSelectedItem}>Clear</Button>
                        </Stack>
                    )}
                </Menu>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </>
    );
};

export default DropDown;
