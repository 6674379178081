export const settings = [
    {
        userName: "Jason Gries",
        email: "jason@bitetime.com",
        phone: "123-456-7890",
        userType: "Superadmin",
        role: "CEO",
        status: "Active",
        order_id: "d979d9e0-3c92-415c-9563-a44ada60a567"
    },
    {
        userName: "Jason Gries",
        email: "jason@bitetime.com",
        phone: "123-456-7890",
        userType: "Superadmin",
        role: "CEO",
        status: "Active",
        order_id: "d979d9e0-3c92-415c-9563-a44ada60a555"
    },
    {
        userName: "Jason Gries",
        email: "jason@bitetime.com",
        phone: "123-456-7890",
        userType: "Superadmin",
        role: "CEO",
        status: "Active",
        order_id: "30398069-0f20-4cb3-ab53-50fb8c7e4359"
    },
    {
        userName: "Jason Gries",
        email: "jason@bitetime.com",
        phone: "123-456-7890",
        userType: "Superadmin",
        role: "CEO",
        status: "Active",
        order_id: "1da6a00a-b944-4702-9879-afe9603bc989"
    }
];
