import React from "react";

const DilogBoxContext = React.createContext();

const DilogBoxContextProvider = ({children}) => {
    const [open, setOpen] = React.useState(false);

    const toggleDilog = (open) => (event) => {
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setOpen(open);
    };

    return (
        <>
            <DilogBoxContext.Provider value={{open, toggleDilog}}>{children}</DilogBoxContext.Provider>
        </>
    );
};

export {DilogBoxContextProvider, DilogBoxContext};
