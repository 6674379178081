import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useTempDrawer from "hooks/useTempDrawer";
import useToggleDilog from "hooks/useToggleDilog";
import User from "./Tabs/User/User";

const useUserDetailController = () => {
    const navigate = useNavigate();
    const {toggleDilog} = useToggleDilog();
    const prm = useParams();
    const customerid = prm?.type;
    const [label, setLebel] = useState("");
    const [heading, setHeading] = useState("");
    const [search, setSearch] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const [breadCrumb, setBreadcrumb] = useState("Menu detail");
    const [editMenu, setEditMenu] = useState(false);
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [updateListCount, setUpdateListCount] = useState(1);
    const {toggleDrawer} = useTempDrawer();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const tab = urlSearchParams.get("tab");
    const editCustomerorNot = urlSearchParams.get("edit");
    const headerName = urlSearchParams.get("userName");
    const showHouseAccount = urlSearchParams.get("show");
    const [value, setValue] = React.useState("profile");

    const handleButtonLabelandFilter = (type) => {
        switch (type) {
            case "user":
                setLebel("Edit user");
                setHeading("User");
                break;
            case "account":
                setLebel("Edit account");
                setHeading("Account");
                break;
            default:
                setLebel("");
                setHeading("Setting");
                break;
        }
    };

    useEffect(() => {
        handleButtonLabelandFilter(tab);
    }, [window.location.search]);

    useEffect(() => {
        setValue(tab);
    }, [tab]);

    useEffect(() => {
        setValue(tab);
        let search = headerName ? `?tab=${tab}&userName=${headerName}` : `?tab=${tab}`;
        if (editCustomerorNot) {
            search = headerName ? `?tab=${tab}&userName=${headerName}&edit=${editCustomerorNot}` : `?tab=${tab}&edit=false`;
        }
        navigate({
            pathname: window.location.pathname,
            search: search
        });
        if (customerid === undefined) {
            setBreadcrumb("Create Profile");
        }
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSelectedCheckbox([]);
        if (editCustomerorNot) {
            navigate({
                pathname: window.location.pathname,
                search: headerName
                    ? `?tab=${newValue}&userName=${headerName}&edit=${editCustomerorNot}`
                    : `?tab=${newValue}&edit=${editCustomerorNot}`
            });
            return;
        }
        navigate({
            pathname: window.location.pathname,
            search: headerName ? `?tab=${newValue}&userName=${headerName}` : `?tab=${newValue}`
        });
    };

    const handleSearch = (val) => {
        setSearch(val);
    };

    const handleCreateLocation = (btntype) => (e) => {
        switch (btntype) {
            // case "Create note": {
            //     setShowFilter(false);
            //     toggleDrawer("right", "addNewNotes")(e);
            //     break;
            // }
            case "Edit user": {
                setShowFilter(false);
                navigate({
                    pathname: window.location.pathname,
                    search: `?tab=user&userName=${headerName}&edit=true`
                });
                break;
            }
            case "Edit account": {
                setShowFilter(false);
                navigate({
                    pathname: window.location.pathname,
                    search: `?tab=account&userName=${headerName}&edit=true`
                });
                break;
            }
            case "none": {
                setShowFilter(true);
                toggleDrawer("right", true)(e);
                break;
            }
            default: {
                break;
                // setEditMenu(true)
            }
        }
    };

    const dummyData = [
        {
            value: "user",
            label: "User",
            comp: () => <User />
        },
    ];
    return {
        dummyData,
        value,
        editCustomerorNot,
        handleChange,
        breadCrumb,
        customerid,
        label,
        heading,
        handleCreateLocation,
        handleSearch,
        search,
        headerName,
        setSelectedCheckbox,
        selectedCheckbox,
        updateListCount,
        setUpdateListCount
    };
};

export default useUserDetailController;
