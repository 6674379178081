import React, {useState, useEffect} from "react";
import {Grid, Typography, Box, Stack, Divider, col} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styles from "../../../quote/quotes.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import Paid from "assets/PAID.svg";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {FormatCurrency, checkforPerfectNumber, FormatPhoneNumber, commaFormattedNumber} from "utils/helperFunctions/helpers";
import Button from "@mui/material/Button";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import Tooltip from "commonComponent/Tooltip";
import {NetworkManager, API} from "network/core";
import {useLocation, useSearchParams, useNavigate} from "react-router-dom";
import {getFormattedTimeinAMPM} from "utils/app-dates/dates";
import Loader from "ui-component/Loader";
import DriverModal from "commonComponent/DilogBox/DriverModal";
import PrintOrderModal from "commonComponent/DilogBox/PrintOrderModal";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import useTempDrawer from "hooks/useTempDrawer";
import useToggleDilog from "hooks/useToggleDilog";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {Document, Page, pdfjs} from "react-pdf";
import {width} from "@mui/system";
import {useStyles} from "./styles";

import PageHeader from "commonComponent/Pageheader/PageHeader";
import PDFComponent from "./pdfComponent";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Printer from "../../../../../../assets/images/icons/printer.svg";
import Edit from "../../../../../../assets/images/icons/edit.svg";

import logo from "assets/images/logo.svg";
import {set} from "lodash";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function OrderView(props) {
    const navigate = useNavigate();
    const {toggleDrawer} = useTempDrawer();
    const {toggleDilog} = useToggleDilog();
    const [open, setOpen] = useState(false);
    const [printOptionModal, setPrintOptionModal] = useState(false);
    const dispatch = useDispatch();
    const [openUnassign, setOpenUnassign] = useState(false);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [bannerClr, setBannerClr] = useState("#5ADA92");
    const [bannerBackgroundClr, setBannerBackgroundClr] = useState("rgba(237, 255, 245, 0.5)");
    const [driverFlag, setDriverFlag] = useState(false);
    const [selectedRow, setSelectedRow] = useState("");
    const [secondaryDriver, setSecondaryDriver] = useState(null);
    const [driverListing, setDriverListing] = React.useState([]);
    const [quoteListData, setQuoteListData] = useState(null);
    const [itemDetailSorted, setItemDetailSorted] = useState(null);
    const [loading, setLoading] = useState(false);
    const [subTotal, setSubTotal] = useState("");
    const [fetchedOrderId, setFetchedOrderId] = useState("");
    const [searchParams] = useSearchParams();
    const urlParams = window.location.href;
    const location = useLocation();
    const OrderId = urlParams?.substring(urlParams.lastIndexOf("/") + 1);
    const DownloadTrue = searchParams.get("download");
    const Tab = searchParams.get("tab");
    const isEditable = searchParams.get("isEdit");
    const [priceParam, setPriceParam] = useState("customer_order");
    const [printSelect, setPrintSelect] = useState("");
    const [showHeading, setShowHeading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const url = quoteListData?.order?.brand?.brand_logo;

    useEffect(() => {
        const fetchImage = async () => {
            if (url) {
                try {
                    const baseUrl = process.env.REACT_APP_BASE_URL;
                    const response = await fetch(`${baseUrl}api/v1/brand/image/get?url=${encodeURIComponent(url)}`);

                    console.log(response);
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    const blob = await response.blob();
                    console.log("hi", URL.createObjectURL(blob));
                    setImageUrl(URL.createObjectURL(blob));
                } catch (error) {
                    console.error("Failed to fetch image:", error);
                }
            }
        };

        fetchImage();
    }, [url]);

    const styles = useStyles();
    const handlePrintModal = () => {
        setPrintOptionModal((pre) => {
            if (pre) {
                setPrintSelect("");
            }
            return !pre;
        });
        setPriceParam("customer_order");
    };
    const handleDriverModal = () => {
        setOpen((pre) => !pre);
    };

    const metersToMiles = (meters) => {
        const metersInOneMile = 1609.34;
        const miles = meters / metersInOneMile;
        return miles.toFixed(1);
    };

    // Select the HTML elements you want to exclude
    const elementsToExclude = document.querySelectorAll(".order_status");

    const fetchImageAsDataURL = (url) => {
        return fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }
                return response.blob();
            })
            .then((blob) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            })
            .catch((error) => {
                console.error(`Failed to fetch image ${url}:`, error);
                throw error;
            });
    };

    const replaceImageSources = (element) => {
        const images = element.querySelectorAll("img");
        const promises = [];

        images.forEach((img) => {
            const url = img.src;
            const promise = fetchImageAsDataURL(url)
                .then((dataUrl) => {
                    img.src = dataUrl;
                })
                .catch((error) => {
                    console.error(`Error fetching image ${url}`, error);
                });
            promises.push(promise);
        });

        return Promise.all(promises);
    };

    const downloadPdfDocument = async () => {
        elementsToExclude.forEach((element) => {
            element.style.display = "none";
        });

        const input = document.getElementById("content");
        console.log("input : ",input)
        const footerBox = document.getElementById("footer");
        const img = document.getElementById("img");
        footerBox.style.paddingBottom = "3mm";
        if (img) {
            img.src = imageUrl;
            img.style.height = "auto";
            img.style.width = "126px";
            img.style.maxHeight = "54px";
        }

        const pdfWidth = 210;
        const pdfHeight = 297;

        const margin = {
            top: 2,
            right: 10,
            bottom: 0,
            left: 10
        };

        const contentWidth = pdfWidth - margin.left - margin.right;

        const pdf = new JsPDF({
            orientation: "portrait",
            unit: "mm",
            format: [pdfWidth, pdfHeight]
        });

        const addFooterToPage1 = (footerCanvas) => {
            const footerMargin = 0;
            let currentY = pdfHeight - margin.bottom;

            if (footerCanvas) {
                const footerImgData = footerCanvas.toDataURL("image/png");
                const footerImgWidth = footerCanvas.width;
                const footerImgHeight = footerCanvas.height;
                const footerRatio = contentWidth / footerImgWidth;
                const scaledFooterHeight = footerImgHeight * footerRatio;

                currentY -= scaledFooterHeight;
                pdf.addImage(footerImgData, "PNG", margin.left, currentY, contentWidth, scaledFooterHeight);
                currentY -= footerMargin;
            }
        };
        const jj = await html2canvas(footerBox, {
            useCORS: true,
            allowTaint: true,
            scale: 2
        });

        const opt = {
            callback: function (pdf) {
                const totalPages = pdf.internal.pages.length - 1;
                for (let i = 1; i <= totalPages; i += 1) {
                    pdf.setPage(i);
                    addFooterToPage1(jj);
                }
                pdf.save(`${quoteListData?.order?.brand?.brand_name}_${quoteListData?.order?.order_number}.pdf`);
            },
            margin: [5, 0, 15, 15],
            autoPaging: "text",
            html2canvas: {
                allowTaint: true,
                dpi: 300,
                letterRendering: true,
                logging: false,
                scale: 0.2
            }
        };

        pdf.html(input, opt);
    };

    const handlePrintDownload = () => {
        downloadPdfDocument();
        setPrintOptionModal((pre) => {
            if (pre) {
                setPrintSelect("");
            }
            return !pre;
        });
    };
    const print = () => {
        setPrintSelect("");
        handlePrintModal();

        window.print();
    };
    const handleAssignDriver = async (values) => {
        const instance =
            quoteListData?.order?.carrier_id !== null ? NetworkManager(API.DELIVERIES.CHANGEDRIVER) : NetworkManager(API.DRIVER.ASSIGN);

        const response = await instance.request({
            orderId: quoteListData?.order?.order_id,
            carrierId: `${values}`
        });

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setDriverFlag(true);
        setOpen((pre) => !pre);
    };
    const handleUnassignDriverModal = (data) => {
        setSelectedRow(data);
        setOpenUnassign((pre) => !pre);
    };

    const getDriverListSecondary = async (orderId) => {
        const instance = NetworkManager(API.DRIVER.SECONDARYLISTING(orderId));
        const response = await instance.request();

        if (response.success) {
            const driverList = response.data?.driver;
            if (driverList?.length) {
                setSecondaryDriver(driverList);
            }
        }
    };

    const hanldeUnassignDriver = async () => {
        setLoading(true);

        const instance = NetworkManager(API.DELIVERIES.DEASSIGN(quoteListData?.order?.order_id));

        const res = await instance.request();
        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            setSecondaryDriver(null);
            getDriverListSecondary(quoteListData?.order?.order_id);
            handleUnassignDriverModal();
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
            handleUnassignDriverModal();
        }

        setSelectedRow("");
        setDriverFlag(true);
        setLoading(false);
    };

    function formatDateToAMPM(timestamp) {
        const date = new Date(timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "pm" : "am";

        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedHours}:${formattedMinutes}${ampm}`;
    }
    const calculateTotalItemPrice = (orderItem) => {
        let totalPrice = parseFloat(orderItem?.price);

        orderItem?.orderItemsModifiers.forEach((row) => {
            totalPrice += row.total_price;
        });
        totalPrice += parseFloat(orderItem?.packaging_price);

        return parseFloat(totalPrice).toFixed(2);
    };
    const getQuoteDetailList = async (id) => {
        setLoading(true);
        const instance = NetworkManager(API.ORDER.QUOTEDETAIL(id));
        const response = await instance.request();
        if (response.success) {
            setQuoteListData(response.data);
            // console.log("response.data11 : ", response.data);
            setFetchedOrderId(response.data.order?.order_id);

            const sortedItem = [];
            response.data.category.forEach((row) => {
                row.orderItems.forEach((col) => {
                    sortedItem.push(col);
                });
            });
            sortedItem.sort((a, b) => a.item_ordering - b.item_ordering);
            const total = sortedItem.map((item) => parseFloat(calculateTotalItemPrice(item)));
            const sum = total.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setSubTotal(sum.toFixed(2));
            setItemDetailSorted(sortedItem);
        }
        setLoading(false);
    };

    const getDriverList = async () => {
        const instance = NetworkManager(API.DRIVER.LISTING);

        const response = await instance.request();

        if (response.success) {
            const driverList = response.data?.driver;
            // Modified array of objects with new property names
            const modifiedArray = driverList
                ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                ?.map((obj) => ({
                    phone: obj.phoneNumber,
                    name: obj.name,
                    ...obj
                }));

            setDriverListing(modifiedArray);
        }
    };

    const OrderUpdate = async (id, orderStatus) => {
        setLoading(true);

        const instance = NetworkManager(API.ORDER.STATUS(fetchedOrderId, orderStatus));
        const payload = {
            link: `${process.env.REACT_APP_CUSTOMER_URL}account?tab=orders&orderId=${fetchedOrderId}`
        };

        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        navigate(-1);
        setLoading(false);
    };

    useEffect(() => {
        getDriverList();
        if (OrderId) {
            getQuoteDetailList(OrderId);
            getDriverListSecondary(OrderId);
        }
        setDriverFlag(false);
    }, [OrderId, driverFlag]);
    const getColorOfStatus = (status) => {
        switch (status) {
            case "Draft":
                return "#A952FF";
            case "Quote Sent":
                return "#24C4FB";
            case "QUOTE_EXPIRED":
                return "#757575";

            case "Cancelled":
                return "#EA3744";
            case "Needs driver":
                return "#FF226B";
            case "Needs Driver":
                return "#FF226B";
            case "Accepted":
                return "#00ab3a";
            case "New":
                return "#ED9E05";
            case "Active":
                return "#00ab3a";
            case "Driver assigned":
                return "#FF872A";
            case "Driver Assigned":
                return "#FF872A";
            case "ON_THE_WAY":
                return "#00ab3a";
            case "QUOTE_REJECTED":
                return "#EA3744";
            default:
                return "#000000";
        }
    };
    const getBackgroundOfStatus = (status) => {
        switch (status) {
            case "Draft":
                return "#FAF2FF";
            case "Quote Sent":
                return "#E8FAFF";
            case "Cancelled":
                return "#FFF2F2";
            case "Needs driver":
                return "#FFF2F2";
            case "Needs Driver":
                return "#FFF2F2";
            case "Accepted":
                return "#E4FFE2";
            case "New":
                return "#FFF8E5";
            case "Active":
                return "#F0FFF9";
            case "Driver assigned":
                return "#FFEFE4";
            case "Driver Assigned":
                return "#FFEFE4";
            case "ON_THE_WAY":
                return "#E4FFE2";
            case "PUBLISHED":
                return "#E4FFE2";
            case "UNPUBLISHED":
                return "#F2F2F2";
            case "NEW":
                return "#FFF8E5";
            case "QUOTE_EXPIRED":
                return "#F2F2F2";
            case "QUOTE_REJECTED":
                return "#FFF2F2";
            default:
                return "#F2F2F2";
        }
    };
    useEffect(() => {
        if (quoteListData) {
            if (quoteListData?.order?.status === "Cancelled") {
                setBannerBackgroundClr("#F8EEEE");
            } else if (quoteListData?.order?.status === "Draft") {
                setBannerBackgroundClr("#F7EEFF");
            } else if (quoteListData?.order?.status === "Rejected") {
                setBannerBackgroundClr("#E3E3E3");
            }
        }
    }, [quoteListData]);

    const handleServiceString = () => {
        let str = "";
        if (
            Math.round(Number(quoteListData?.order?.kitchen_location?.minimum_service_fee)) !==
            Math.round(Number(quoteListData?.order?.service_fee_value))
        ) {
            if (quoteListData?.order?.service_fee_unit === "percent") {
                str = `${parseFloat(quoteListData?.order?.service_fee_unit_value)}%`;
            } else if (quoteListData?.order?.service_fee_unit === "usd") {
                str = `$ ${parseFloat(quoteListData?.order?.service_fee_unit_value)}`;
            }
        }

        return str;
    };

    const handleAdvanceString = () => {
        return quoteListData?.order?.advance_deposit_percent ? `${quoteListData?.order?.advance_deposit_percent}%` : "0%";
    };

    const handleSalesTaxString = () => {
        return quoteListData?.order?.kitchen_location?.sales_tax_rate ? `${quoteListData?.order?.kitchen_location?.sales_tax_rate}%` : "0%";
    };

    const dietaryMap = {
        VEGETARIAN: "V",
        VEGAN: "VG",
        "GLUTEN-FREE": "GF",
        "DAIRY-FREE": "DF",
        SPICY: "S"
    };

    const dietarySequence = ["VEGETARIAN", "VEGAN", "GLUTEN-FREE", "DAIRY-FREE", "SPICY"];

    const sortAndMapDietary = (arr1) => {
        // Create a copy of arr1 to avoid modifying the original array
        const sortedArr = [...arr1];

        // Sort the array based on the index in dietarySequence
        sortedArr.sort((a, b) => dietarySequence.indexOf(a.dietary) - dietarySequence.indexOf(b.dietary));

        // Map each value using dietaryMap
        const mappedArr = sortedArr.map((obj) => dietaryMap[obj.dietary]);

        // Return the resulting array as in the original format
        return mappedArr;
    };

    const sortAndMapModifierDietary = (arr1) => {
        // Create a copy of arr1 to avoid modifying the original array
        const sortedArr = [...arr1];

        // Sort the array based on the index in dietarySequence
        sortedArr.sort((a, b) => dietarySequence.indexOf(a.modifier_dietary) - dietarySequence.indexOf(b.modifier_dietary));

        // Map each value using dietaryMap
        const mappedArr = sortedArr.map((obj) => dietaryMap[obj.modifier_dietary]);

        // Return the resulting array as in the original format
        return mappedArr;
    };

    const showDietaryForItem = (orderItem) => {
        const temp = sortAndMapDietary(orderItem.item?.itemDietary);
        return temp.map((item) => {
            return (
                <Box
                    sx={{
                        width: "19px",
                        height: "19px",
                        border: "1px solid #000",
                        borderRadius: "50%",
                        fontSize: "7.5px",
                        fontWeight: 700,
                        lineHeight: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    {item}
                </Box>
            );
        });
    };
    const showDietaryForModifier = (orderItem) => {
        const temp = sortAndMapModifierDietary(orderItem?.modifierDietary);
        return temp.map((item) => {
            return (
                <Box
                    sx={{
                        width: "19px",
                        height: "19px",
                        border: "1px solid #000",
                        borderRadius: "50%",
                        fontSize: "7.5px",
                        fontWeight: 700,
                        lineHeight: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    {item}
                </Box>
            );
        });
    };

    const handleEditOrder = (orderId) => {
        navigate(`/orders?tab=scheduled&orderId=${orderId}`);
        toggleDilog("CreateOrder")();
        toggleDrawer("right", false)();
    };
    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };

    const handlePreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const handleNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };
    const pdfUrl = "your-pdf-document.pdf";

    const handlePrintPDF = () => {
        setPrintSelect("print");
        handlePrintModal();

        // window.print();
    };

    const getStatusText = (status) => {
        switch (status) {
            case "Cancelled":
                return "Canceled!";
            case "Active":
                return "Accepted!";
            case "Needs Driver":
                return "Assign driver!";
            case "Needs driver ":
                return "Assign driver!";
            case "QUOTE_REJECTED":
                return "Quote rejected!";
            case "QUOTE_EXPIRED":
                return "Quote expired!";
            case "Quote Sent":
                return "Quote sent!";
            case "Driver assigned":
                return "Active!";
            case "Driver Assigned":
                return "Active!";
            case "ON_THE_WAY":
                return "On the way!";
            default:
                return `${quoteListData?.order?.status || ""}!`;
        }
    };
    const getMarkedText = (status) => {
        switch (status) {
            case "Cancelled":
                return "canceled";
            case "Active":
                return "accepted";
            case "Needs Driver":
                return "assign driver";
            case "Needs driver ":
                return "assign driver";
            case "QUOTE_REJECTED":
                return "quote rejected";
            case "Quote Sent":
                return "quote sent";
            case "Driver assigned":
                return "active";
            case "Driver Assigned":
                return "active";
            default:
                return quoteListData?.order?.status.toLowerCase();
        }
    };

    const isCustomer = quoteListData?.order?.customer !== null;

    const {PriceParam} = props;
    return (
        <>
            <Grid container>
                <Grid item xs={12} mb={1} sx={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <Grid container sx={{display: "flex", maxWidth: "905px", justifyContent: "center"}}>
                        <Grid item xs={12} sm={3.5} sx={{display: "flex", alignItems: "center"}}>
                            <Stack
                                direction="row"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                spacing={0.5}
                                // sx={{paddingLeft: "20px"}}
                            >
                                <ArrowBackIosIcon
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                    fontSize="small"
                                    sx={{color: "#000", cursor: "pointer", width: "15px", height: "12px"}}
                                />
                                <Typography
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                    variant="subtitle1"
                                    fontSize="0.807em"
                                    sx={{color: "#000", cursor: "pointer", fontSize: "12px", fontWeight: 500, lineHeight: "14.52px"}}
                                >
                                    Back
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={8.5}
                            sx={{textAlign: "right", display: "flex", flexDirection: "row", justifyContent: "flex-end"}}
                        >
                            {isEditable === "true" &&
                            WithPermission({module: "Orders", permission: "update_permission"}) &&
                            (quoteListData?.order?.status === "Accepted" ||
                                quoteListData?.order?.status === "Active" ||
                                quoteListData?.order?.status === "ON_THE_WAY" ||
                                quoteListData?.order?.status === "Driver Assigned" ||
                                quoteListData?.order?.status === "Driver assigned" ||
                                quoteListData?.order?.status === "Needs Driver") &&
                            new Date(quoteListData?.order?.delivery_date) > new Date() ? (
                                <Button
                                    onClick={() => handleEditOrder(quoteListData?.order?.order_id)}
                                    startIcon={
                                        <img src={Edit} alt="Edit" style={{width: "14.17px", height: "14.17px", color: "#000000"}} />
                                    }
                                    sx={{marginRight: "4px", fontSize: "12px", fontWeight: 500, lineHeight: "15px"}}
                                >
                                    Edit
                                </Button>
                            ) : quoteListData?.order?.status === "Draft" || quoteListData?.order?.status === "Quote Sent" ? (
                                <Button
                                    onClick={() => handleEditOrder(quoteListData?.order?.order_id)}
                                    startIcon={
                                        <img src={Edit} alt="Edit" style={{width: "14.17px", height: "14.17px", color: "#000000"}} />
                                    }
                                    sx={{marginRight: "4px", fontSize: "12px", fontWeight: 500, lineHeight: "15px"}}
                                >
                                    Edit
                                </Button>
                            ) : (
                                ""
                            )}
                            {quoteListData?.order?.customer !== null && (
                                <>
                                    <Button
                                        onClick={() => handlePrintPDF()}
                                        // onClick={() => handlePrintModal()}
                                        startIcon={
                                            <img src={Printer} alt="Print" style={{width: "18px", height: "18px", color: "#000000"}} />
                                        }
                                        sx={{marginRight: "4px", fontSize: "12px", fontWeight: 500, lineHeight: "15px"}}
                                    >
                                        Print
                                    </Button>

                                    <Button
                                        startIcon={<FileDownloadOutlinedIcon sx={{width: "18px", height: "20px", color: "#000000"}} />}
                                        // sx={{marginRight: "10px"}}
                                        // onClick={() => handlePrintPDF()}
                                        onClick={() => handlePrintModal()}
                                        // onClick={downloadPdfDocument}
                                        sx={{paddingRight: 0, fontSize: "12px", fontWeight: 500, lineHeight: "15px"}}
                                    >
                                        Download
                                    </Button>

                                    {(WithPermission({module: "Orders", permission: "update_permission"}) &&
                                        quoteListData?.order?.status === "new") ||
                                        (quoteListData?.order?.status === "New" && (
                                            <ButtonGrouping
                                                btnprops={[
                                                    {
                                                        btnTitle: "Reject",
                                                        sx: () => ({color: "#00ab3a", width: "134px", height: "40px", ml: "4px"}),
                                                        variant: "outlined",
                                                        onClick: () => OrderUpdate(OrderId, "Rejected")
                                                    },

                                                    {
                                                        btnTitle: "Accept",
                                                        variant: "contained",
                                                        onClick: () => OrderUpdate(OrderId, "Accepted"),
                                                        sx: (t) => ({
                                                            color: t.palette.background.paper,
                                                            height: "40px",
                                                            width: "144px",
                                                            fontSize: "1rem"
                                                        })
                                                    }
                                                ]}
                                            />
                                        ))}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                {/* <Grid item xs={12} mt={0.3} sx={{ marginLeft: "-2rem" }}>
                    <Divider sx={{ background: "#E0E2E6", height: "1px", opacity: "0.5", width: "120%" }} />
                </Grid> */}
                {/* changes */}
                {printSelect === "" && isCustomer && (
                    <Grid
                        item
                        xs={12}
                        marginTop={PriceParam === "price" ? 1 : 0}
                        className="order_status"
                        sx={{display: "flex", justifyContent: "center", alignItems: "center"}}
                    >
                        <Stack
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                color:
                                    quoteListData?.order?.status === "Quote Sent" &&
                                    quoteListData?.order?.quote_expiry_time < new Date().toISOString()
                                        ? "#757575"
                                        : getColorOfStatus(quoteListData?.order?.status),
                                backgroundColor:
                                    quoteListData?.order?.status === "Quote Sent" &&
                                    quoteListData?.order?.quote_expiry_time < new Date().toISOString()
                                        ? getBackgroundOfStatus("QUOTE_EXPIRED")
                                        : getBackgroundOfStatus(quoteListData?.order?.status),
                                borderRadius: "4px",
                                paddingX: "8px",
                                width: "905px",
                                height: "28px"
                            }}
                        >
                            <Stack sx={{padding: "4px 0px 4px 0px"}}>
                                {" "}
                                {quoteListData?.order?.status !== "Complete" ? (
                                    <ErrorOutlineIcon color="red" sx={{fontSize: "1.213em"}} ml={1.5} />
                                ) : (
                                    <CheckCircleOutlineIcon color="black" sx={{fontSize: "1.213em"}} ml={1.5} />
                                )}
                            </Stack>
                            <Box sx={{padding: "4px 0px 4px 0px"}}>
                                <Stack ml={0.5} sx={{display: "flex", flexDirection: "row"}}>
                                    {quoteListData?.order?.status === "Quote Sent" &&
                                    quoteListData?.order?.quote_expiry_time < new Date().toISOString() ? (
                                        <Typography fontWeight={600} mr={0.6} fontSize="13px">
                                            {getStatusText("QUOTE_EXPIRED") ?? ""}
                                        </Typography>
                                    ) : (
                                        <Typography fontWeight={600} mr={0.6} fontSize="13px">
                                            {getStatusText(quoteListData?.order?.status) ?? ""}
                                        </Typography>
                                    )}

                                    {/* Check conditions to ensure only one block executes */}

                                    {quoteListData?.order?.status === "Quote Sent" &&
                                    quoteListData?.order?.quote_expiry_time < new Date().toISOString() ? (
                                        // If the quote has expired, show this message
                                        <Typography fontSize="0.812rem">Please contact Bitetime for help with your quote.</Typography>
                                    ) : quoteListData?.order?.status === "ON_THE_WAY" ? (
                                        // If the order is "ON_THE_WAY", show this message
                                        <Typography fontSize="0.812rem">This order is on the way.</Typography>
                                    ) : (
                                        // Otherwise, show the marked status message
                                        <Typography fontSize="0.812rem">
                                            This order has been marked as&nbsp;
                                            {getMarkedText(quoteListData?.order?.status)}.
                                        </Typography>
                                    )}
                                </Stack>
                            </Box>
                        </Stack>
                    </Grid>
                )}
                {/* <Grid item xs={12} mt="30px" sx={{display:'flex',alignItems: 'center',justifyContent: 'center',width:'366px',border:'1px solid cyan'}} >
                    <PageHeader sx={{}} title={`Order #${quoteListData?.order?.order_number ?? ""}`} />
                </Grid> */}
            </Grid>
            <Grid
                id="PDFId"
                container
                //  mb={8} mt={2} pr={2}
                sx={{display: "grid", placeItems: "center"}}
                className="quote"
            >
                {loading && <Loader />}
                {quoteListData ? (
                    <PDFComponent
                        quoteListData={quoteListData}
                        PriceParam={priceParam}
                        getColorOfStatus={getColorOfStatus}
                        showDietaryForItem={showDietaryForItem}
                        handleDriverModal={handleDriverModal}
                        handleUnassignDriverModal={handleUnassignDriverModal}
                        itemDetailSorted={itemDetailSorted}
                        metersToMiles={metersToMiles}
                        showDietaryForModifier={showDietaryForModifier}
                        calculateTotalItemPrice={calculateTotalItemPrice}
                        handleAdvanceString={handleAdvanceString}
                        handleSalesTaxString={handleSalesTaxString}
                        subTotal={subTotal}
                        showHeading={showHeading}
                        secondaryDriver={secondaryDriver}
                        getBackgroundOfStatus={getBackgroundOfStatus}
                        printSelect={printSelect}
                        driverListing={driverListing}
                        imageUrl={imageUrl}
                    />
                ) : null}
            </Grid>
            {/* <Box sx={{display: "none"}}>
<PrintOrder params="price" />
</Box> */}
            {open && (
                <DriverModal
                    open={open}
                    buttonTitle="Assign driver"
                    orderId={quoteListData?.order?.order_id}
                    prefillDriverId={quoteListData?.order?.carrier_id}
                    secondaryDriverPrefill={secondaryDriver ? secondaryDriver?.map((row) => Number(row.id)) : []}
                    title="Assign a driver"
                    description={`Select a driver to assign to order #${quoteListData?.order?.order_number}`}
                    driversData={driverListing}
                    marketId={quoteListData?.order?.kitchen_location?.market?.market_id}
                    handleClose={handleDriverModal}
                    handleSubmit={(values) => handleAssignDriver(values)}
                />
            )}
            <PrintOrderModal
                open={printOptionModal}
                buttonTitle={printSelect ? "Print order" : "Download order"}
                title={printSelect ? "Print order" : "Download order"}
                description={
                    printSelect ? "Select the type of order you would like to print" : "Select the type of order you would like to download"
                }
                handleClose={handlePrintModal}
                handleSubmit={(price) => (printSelect ? print(price) : handlePrintDownload(price))}
                noOnClose
                selected={priceParam}
                onSelect={(price) => {
                    setPriceParam(price);
                    setShowHeading(price === "price");
                }}
            />
            <DeleteModal
                buttonTitle="Unassign"
                open={openUnassign}
                title="Are you sure you want to unassign driver?"
                handleClose={handleUnassignDriverModal}
                handleSubmit={hanldeUnassignDriver}
            />
        </>
    );
}

export default OrderView;
