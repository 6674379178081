import {Grid, Typography, Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import Cards from "../Cards/Cards";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "react-redux";
import {useNavigate, useSearchParams, useParams} from "react-router-dom";
import Loader from "ui-component/Loader";
import { ellipsizeText } from "commonComponent/Apptable/textUtils";
import { FormatCurrency ,convertToInteger} from "utils/helperFunctions/helpers";

function ItemViewPage({selectedRow, id}) {
    const dispatch = useDispatch();
    const [itemData, setItemData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [urlSearchParams] = useSearchParams();
    // const CustomerId = urlSearchParams.get("customerid");
    const mrkName = urlSearchParams.get("customerName");
    const editMode = urlSearchParams.get("edit");
    const prm = useParams();
    const CustomerId = prm?.type;

    const getItemData = async () => {
        setLoading(true);
        const instance = NetworkManager(API.ITEM.GET);
        const response = await instance.request({}, [selectedRow ? selectedRow?.item_id : id]);
        if (response.success) {
            setItemData(response?.data?.item);
            setLoading(false);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    transition: "Fade",
                    close: true
                })
            );
        }
        setLoading(false);
    };

    useEffect(() => {
        getItemData();
    }, [CustomerId]);

    function convertToTitleCase(label) {
        if (typeof label !== "string") {
            return label;
        }

        const words = label.split("_").map((word, index) => {
            if (word.length > 0) {
                if (index === 0) {
                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                } else {
                    return word.charAt(0).toLowerCase() + word.slice(1).toLowerCase();
                }
            }
            return word;
        });

        return words.join("-");
    }
    const itemDetailsData = [
        {label: "Item name", value: itemData?.item_name || "-"},
        {label: "Item type", value: itemData?.item_type === 0 ? "Custom" : "Regular" || "-"},
        {label: "Price", value:  itemData?.price ? FormatCurrency(itemData?.price):"-"},
        {
            label: "Menu",
            value: itemData?.menuCategoryItem?.length===0 ? "-" :itemData?.menuCategoryItem?.map((obj, index) => <div key={index}>{obj?.menuCategory?.menu?.menu_name}</div>) 
        },

        {
            label: "Category",
            value: itemData?.menuCategoryItem?.length === 0 
            ? ["-"] 
            : itemData?.menuCategoryItem?.reduce((acc, cur) => {
                if (cur?.menuCategory?.category?.category_name && !acc.includes(cur.menuCategory.category.category_name)) {
                    acc.push(cur.menuCategory.category.category_name);
                }
                return acc;
            }, [])?.map((obj, index) => <div key={index}>{obj}</div>) ||
                "-"
        },

        {label: "SKU", value: itemData?.sku || "-"}
    ];

    const descriptionData = [{label: "Description", value: itemData?.description || "-"}];
    const dietaryData = [
        {label: "Dietary", value: itemData?.itemDietary?.length===0 ? "-" : itemData?.itemDietary?.map((el, index) => <div key={index}>{el.dietary}</div>) || "-"}
    ];

    const labelData = [
        {
            label: "Label (appears on menu item thumbnail)",
            value: itemData?.itemLabel?.length===0?"-" : itemData?.itemLabel?.map((el) => el.label.label_name) || "-"
        }
    ];

    const settingData = [
        {label: "Minimum order quantity", value: convertToInteger(itemData?.minimum_order_quantity || "-")},
        {
            label: "Serving size",
            value: convertToInteger(itemData?.serving_size || "-")
        }
    ];

    const moreInfoData = [
        {label: "Temperature served", value: itemData?.temperature_served ? [itemData?.temperature_served] : ["HOT"] || "-"},
        {
            label: "Kitchen note (For kitchen staff only)",
            value: itemData?.kitchen_note || "-"
        },
        {
            label: "Customer note (For customers only)",
            value: itemData?.customer_note || "-"
        }
    ];
    // const modifierData = [
    //     {label: "Modifiers groups", value: itemData?.itemModifierGroup?.map((el) => ({
    //         id: el?.modifierGroup?.modifier_group_id,
    //         label: el?.modifierGroup?.modifier_group_name,
    //         ...el?.modifierGroup
    //     })) || "-"},
    // ];

    const ItemThumbnail = itemData?.itemImage?.filter((el) => el?.type === "thumbnail").map((el) => el?.image);
    const ItemPersonalization = itemData?.itemImage?.filter((el) => el?.type === "personalization").map((el) => el?.image);

    return (
        <>
            {loading && <Loader />}
            <Grid container sx={{paddingBottom: "2rem"}}>
                <Grid item xs={12}>
                    <Box sx={{maxWidth: "80%"}}>
                        <Grid
                            container
                            sx={{
                                borderRadius: "4px",
                                border: "1px solid #DBDBDB",
                                padding: "16px"
                            }}
                        >
                            <Grid item xs={12}>
                                <Typography sx={{fontSize: "1.125rem", fontWeight: 500}} color="#020A11">
                                    Item details
                                </Typography>
                                <Grid container mb={2}>
                                    {itemDetailsData.map((item, index) => (
                                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index} mt={1.5}>
                                            <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                                                {item.label}
                                            </Typography>
                                            <Typography sx={{fontSize: "0.875rem", fontWeight: 500, wordBreak: "break-all"}} color="#212121">
                                                {item.value}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Typography sx={{fontSize: "1.125rem", fontWeight: 500}} color="#020A11">
                                    Description
                                </Typography>
                                <Grid container mt={1.5}>
                                    {descriptionData.map((item, index) => (
                                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index}>
                                            <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                                                {item.label}
                                            </Typography>
                                            <Typography
                                                sx={{fontSize: "0.875rem", fontWeight: 500, wordBreak: "break-all"}}
                                                color="#212121"
                                            >
                                                {item.value}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box sx={{display: "flex", gap: "16px", width: "100%", flexWrap: {xs: "wrap", sm: "nowrap"}}} mt={2}>
                            <Cards title="Dietary" data={dietaryData} />
                            <Cards title="Label (appears on menu item thumbnail)" data={labelData} />
                        </Box>
                        <Box sx={{display: "flex", gap: "16px", width: "100%"}} mt={2}>
                            {ItemThumbnail === null ? (
                                ""
                            ) : (
                                <Box
                                    sx={{
                                        borderRadius: "4px",
                                        border: "1px solid #DBDBDB",
                                        padding: "16px",
                                        width: {xs: "100%", md: "50%"}
                                    }}
                                >
                                    <Typography sx={{fontSize: "1.125rem", fontWeight: 500}} color="#020A11">
                                        Menu item thumbnail (plated photo)
                                    </Typography>
                                    <Box sx={{padding: "2rem 0rem", width: "100%", height: "100%"}}>
                                        {ItemThumbnail?.length === 0 || ItemThumbnail === undefined ? (
                                            <Typography>No image available</Typography>
                                        ) : (
                                            <img
                                                style={{width: "100%", height: "100%", objectFit: "contain"}}
                                                src={ItemThumbnail}
                                                alt="logo"
                                            />
                                        )}
                                    </Box>
                                </Box>
                            )}
                            {ItemPersonalization === null ? (
                                ""
                            ) : (
                                <Box
                                    sx={{
                                        borderRadius: "4px",
                                        border: "1px solid #DBDBDB",
                                        padding: "16px",
                                        width: {xs: "100%", md: "50%"}
                                    }}
                                >
                                    <Typography sx={{fontSize: "1.125rem", fontWeight: 500}} color="#020A11">
                                        Item personalization photo
                                    </Typography>
                                    <Box sx={{padding: "2rem 0rem", width: "100%", height: "100%"}}>
                                        {ItemPersonalization?.length === 0 || ItemPersonalization === undefined ? (
                                            <Typography>No image available</Typography>
                                        ) : (
                                            <img
                                                style={{width: "100%", height: "100%", objectFit: "contain"}}
                                                src={ItemPersonalization}
                                                alt="logo"
                                            />
                                        )}
                                    </Box>
                                </Box>
                            )}
                        </Box>

                        <Box sx={{display: "flex", gap: "16px", width: "100%", flexWrap: {xs: "wrap", sm: "nowrap"}}} mt={2}>
                            <Cards title="Settings" data={settingData} />
                            <Cards title="More info" data={moreInfoData} />
                        </Box>
                        <Box sx={{display: "flex", gap: "16px", width: "100%", flexWrap: {xs: "wrap", sm: "nowrap"}}} mt={2}>
                            {/* <Cards title="Modifier groups" data={modifierData} /> */}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default ItemViewPage;
