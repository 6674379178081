import styled from "@emotion/styled";
import {TextField} from "@mui/material";
import React from "react";

const CssTextField = styled(TextField)(({theme, height}) => {
    return {
        "&": {
            "& .MuiOutlinedInput-root": {
                borderRadius: "0px",
                "& .MuiOutlinedInput-input": {
                    height: `${height ?? 52} !important`,
                    padding: height ? "5px 12px" : "15.5px 14px",
                    color: "#212121",
                    fontSize: "14px",
                    fontWeight: 400,
                    width: "100%"
                }
            },
            "& .MuiFormLabel-root ": {
                lineHeight: height ? "0.7em !important" : "1.4375em !important"
            }
        }
    };
});

const CustomTextField = React.forwardRef((props, ref) => (
    <CssTextField
        autoComplete="off" // Disable autocomplete suggestions
        inputProps={{
            autoComplete: "off" 
        }}
        {...props}
        ref={ref}
    />
));
export default CustomTextField;
