import {Box, Checkbox, FormControlLabel, Grid, Stack, Typography} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import {ErrorMessage, Form, Formik} from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import React, {useState, useEffect, useCallback} from "react";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";
import CustomDateTimePicker from "commonComponent/Form/Fields/CustomDateTimePicker";
import CustomeDatePicker from "commonComponent/Form/Fields/CustomeDatePicker";
import {enforceFormat} from "utils/helperFunctions/helpers";
import {CreatePromotionValidation} from "utils/validations/validations";
import Loader from "ui-component/Loader";
import {convertToLocal, convertToUTC} from "utils/app-dates/dates";
import CustomNewDateTimePicker from "commonComponent/newDateAndTimePicker/CustomNewDateTimePicker";
import Checked from "assets/images/icons/lightCheckedBox.svg";
import Unchecked from "assets/images/icons/unchecked.svg";
import _debounce from "lodash/debounce";
import DateTime from "../../orders/CreateOrder/headerSection/DelivarySection/sections/DateTime";
import NewCustomDateTimePicker from "../../orders/CreateOrder/headerSection/DelivarySection/sections/NewDateTimePicker";

const formSchema = {
    discount_code: "",
    number_available: "",
    minimum_order_value: "",
    is_unlimited: false,
    first_time_use:false,
    start_date: "",
    end_date: "",
    noendDate: false,
    discount_type: "",
    amount: "",
    services: [],
    market_id: [],
    brand_id: [],
    company: "",
    description: ""
};

const CreatePromotionForm = (props) => {
    const {handleIsEdited, selectedRow, handleSelectedRow} = props;
    const {toggleDrawer} = useTempDrawer();
    const [initialValues, setInitialValues] = React.useState(formSchema);
    const [marketList, setmarketList] = useState([]);
    const [existingCompany, setExistingCompany] = useState([]);
    const [companySearch, setCompanySearch] = useState("");
    const [brandList, setBrandList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submitEvent, setSubmitEvent] = useState(null);

    const dispatch = useDispatch();
    const getExisitingCompany = useCallback(async () => {
        const instance = NetworkManager(API.COMPANY.GET);
        const response = await instance.request({}, [`?company_name=${companySearch}`]);
        if (response.success) {
            const data = response.data.companyList;
            setExistingCompany(data);
        } else {
            setExistingCompany([]);
        }
    }, [companySearch]);

    const createCompany = async (name) =>
        new Promise((resolve) => {
            const instance = NetworkManager(API.COMPANY.ADD);
            instance.request({company_name: name}).then((response) => {
                if (response.success) {
                    resolve(response.data?.company?.company_id);
                }
            });
        });

    const debouncedHandleUpdateOrder = useCallback(_debounce(getExisitingCompany, 300), [getExisitingCompany]);

    useEffect(() => {
        debouncedHandleUpdateOrder();
    }, [companySearch, debouncedHandleUpdateOrder]);

    const getAllMarkets = async (pageNumber = 1) => {
        const params = `?page=${pageNumber}&pageSize=5000&sortBy=createdAt&orderBy=desc&is_active=true`;
        const instance = NetworkManager(API.MARKET.GET);
        const response = await instance.request({}, [params]);

        if (response.success) {
            const data = response.data;
            const idAndLabel = data.marketList.map((el) => ({id: el.market_id, label: el.market_name}));
            setmarketList([...idAndLabel]);
            // if (selectedRow) {
            //     let brandAvailable = data.marketList.filter((el) => selectedRow.market.includes(el.market_id))

            //     brandAvailable = brandAvailable.map((el) => el.market_id)
            //     if (brandAvailable.length > 0) {
            //         setInitialValues({ ...initialValues, market_id: brandAvailable })

            //     }
            // }
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const getAllBrands = async (pageNumber = 1) => {
        const params = `?page=${pageNumber}&pageSize=5000&sortBy=brand_name&orderBy=asc&is_active=true`;
        const instance = NetworkManager(API.BRAND.GET);
        const response = await instance.request({}, [params]);

        if (response.success) {
            const data = response.data;
            const idAndLabel = data.brandList.map((el) => ({id: el.brand_id, label: el.brand_name}));
            setBrandList([...idAndLabel]);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    useEffect(() => {
        if (selectedRow) {
            let brandAvailable = brandList.filter((el) => selectedRow.brand.includes(el.id));
            brandAvailable = brandAvailable.map((el) => el.id);
            let marketAvailable = marketList.filter((el) => selectedRow?.market.includes(el.id));
            marketAvailable = marketAvailable.map((el) => el.id);

            setInitialValues({
                discount_code: selectedRow.discount_code,
                number_available: selectedRow.number_available === 9999 ? "" : selectedRow.number_available,
                minimum_order_value: selectedRow?.minimum_order_value ?? "",
                is_unlimited: selectedRow.number_available === 9999,
                first_time_use:selectedRow?.first_time_use,
                start_date: convertToLocal(selectedRow.start_date),
                end_date: selectedRow.end_date ? convertToLocal(selectedRow.end_date) : null,
                noendDate: selectedRow.end_date === null,
                discount_type: selectedRow.discount_type,
                amount: selectedRow.amount,
                services: selectedRow.services?.length ? selectedRow.services : "",
                market_id: marketAvailable,
                brand_id: brandAvailable,
                company: selectedRow?.company ?? "",
                companyId: selectedRow?.company_id,
                description: selectedRow?.description ?? ""
            });
        } else {
            setInitialValues({
                discount_code: "",
                number_available: "",
                minimum_order_value: "",
                is_unlimited: false,
                first_time_use:false,
                start_date: "",
                end_date: "",
                noendDate: false,
                discount_type: "",
                amount: "",
                services: [],
                market_id: [],
                brand_id: [],
                company: "",
                description: ""
            });
        }
    }, [selectedRow, brandList, marketList]);

    useEffect(() => {
        getAllMarkets();
        getAllBrands();
    }, []);

    const handleAddPromotion = async (values, setSubmitting, e) => {
        setSubmitting(true);
        setLoading(true);
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(selectedRow ? API.PROMOTIONS.UPDATE(selectedRow.discount_id) : API.PROMOTIONS.ADD);
        const payload = {
            discount_code: values.discount_code.toUpperCase(),
            number_available: values.is_unlimited ? 9999 : values.number_available,
            first_time_use:values.first_time_use,
            start_date: values.start_date ? convertToUTC(values.start_date) : null,
            // ...(!values.noendDate && { end_date: values.end_date ? convertToUTC(values.end_date) : null }),
            discount_type: values.discount_type,
            amount: values.amount,
            no_end_date: values.noendDate,
            services: [...values.services],
            market_id: values.market_id,
            brand_id: values.brand_id,
            description: values.description,
            redemptionCount: values.redemptionCount
        };

        if (values.companyId) {
            payload.company = values.company;
            payload.company_id = values.companyId;
        }

        if (values.minimum_order_value !== "") {
            payload.minimum_order_value = values.minimum_order_value;
        } else {
            payload.minimum_order_value = null;
        }

        if (!values.noendDate) {
            payload.end_date = convertToUTC(values.end_date);
        }

        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            if (e) {
                toggleDrawer("right", false)(e);
            }
            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSubmitting(false);
        setLoading(false);
    };

    const handleTouch = (formik) => {
        const allFields = Object.keys(formik.values);
        const touchedFields = {};

        allFields.forEach((field) => {
            touchedFields[field] = true;
        });

        formik.setTouched(touchedFields);
    };

    return (
        <>
            {loading && <Loader />}
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={CreatePromotionValidation}
                validateOnMount
                onSubmit={(val, {setSubmitting}) => {
                    handleAddPromotion(val, setSubmitting, submitEvent);
                }}
            >
                {(formik) => (
                    <Form>
                        <TemporaryDrawer
                            sideBarStateName="promotionSidebar"
                            handleSelectedRow={handleSelectedRow}
                            resetForm={formik.resetForm}
                            touched={formik.touched}
                        >
                            <Stack sx={{height: "100%"}} justifyContent="space-between">
                                <Stack spacing={2} mt={-2}>
                                    <PageHeader
                                        fontSize="1.625rem"
                                        title={selectedRow ? "Edit  promotions" : "Create promotions"}
                                        showOnlyTitle
                                    />
                                    <Box sx={{mt: 2}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder="Promotion code"
                                                    label="Promotion code"
                                                    inputProps={{maxLength: 50}}
                                                    value={formik.values.discount_code}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("discount_code", e.target.value.toUpperCase());
                                                    }}
                                                    // {...formik.getFieldProps("discount_code")}
                                                />

                                                <ErrorMessage
                                                    name="discount_code"
                                                    render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item sm={6} xs={12}>
                                                        <CustomTextField
                                                            fullWidth
                                                            disabled={formik.values.is_unlimited}
                                                            placeholder="Number available"
                                                            label="Number available"
                                                            inputProps={{maxLength: 5}}
                                                            onKeyDown={enforceFormat}
                                                            {...formik.getFieldProps("number_available")}
                                                        />
                                                        <ErrorMessage
                                                            name="number_available"
                                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={6} xs={12}>
                                                        <CustomTextField
                                                            fullWidth
                                                            placeholder="Min. order value (optional)"
                                                            label="Min. order value (optional)"
                                                            inputProps={{maxLength: 250, min: 1}}
                                                            onKeyDown={enforceFormat}
                                                            {...formik.getFieldProps("minimum_order_value")}
                                                        />

                                                        <ErrorMessage
                                                            name="minimum_order_value"
                                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} ml={0.4}>
                                                        <FormControlLabel
                                                            checked={formik.values.is_unlimited}
                                                            control={
                                                                <Checkbox
                                                                    disableRipple
                                                                    icon={
                                                                        <img
                                                                            src={Unchecked}
                                                                            style={{width: "18px", height: "18px"}}
                                                                            alt="checked"
                                                                        />
                                                                    }
                                                                    checkedIcon={
                                                                        <img
                                                                            src={Checked}
                                                                            alt="checkbox"
                                                                            style={{width: "18px", height: "18px"}}
                                                                        />
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                <Typography
                                                                    variant={
                                                                        formik.values.is_unlimited
                                                                            ? "checkBoxSelected"
                                                                            : "checkBoxUnSelected"
                                                                    }
                                                                >
                                                                    Unlimited
                                                                </Typography>
                                                            }
                                                            onChange={(e) => {
                                                                formik.setFieldValue(`is_unlimited`, e.target.checked);

                                                                formik.setFieldValue("number_available", "");
                                                                setTimeout(() => {
                                                                    formik.setErrors({
                                                                        ...formik.errors,
                                                                        number_available: undefined // Setting to undefined removes the error
                                                                    });
                                                                }, 10);
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} ml={0.4}>
                                                        <FormControlLabel
                                                            checked={formik.values.first_time_use}
                                                            control={
                                                                <Checkbox
                                                                    disableRipple
                                                                    icon={
                                                                        <img
                                                                            src={Unchecked}
                                                                            style={{width: "18px", height: "18px"}}
                                                                            alt="checked"
                                                                        />
                                                                    }
                                                                    checkedIcon={
                                                                        <img
                                                                            src={Checked}
                                                                            alt="checkbox"
                                                                            style={{width: "18px", height: "18px"}}
                                                                        />
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                <Typography
                                                                    variant={
                                                                        formik.values.first_time_use
                                                                            ? "checkBoxSelected"
                                                                            : "checkBoxUnSelected"
                                                                    }
                                                                >
                                                                    First order only
                                                                </Typography>
                                                            }
                                                            onChange={(e) => {
                                                                formik.setFieldValue(`first_time_use`, e.target.checked);

                                                                formik.setFieldValue("number_available", "");
                                                                setTimeout(() => {
                                                                    formik.setErrors({
                                                                        ...formik.errors,
                                                                        number_available: undefined // Setting to undefined removes the error
                                                                    });
                                                                }, 10);
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        {/* <CustomeDatePicker
                                                            label="Start date"
                                                            valueBasic={formik.values.start_date || null}
                                                            setValueBasic={(val) => formik.setFieldValue(`start_date`, val)}

                                                        /> */}
                                                        <NewCustomDateTimePicker
                                                            label="Start date"
                                                            valueBasic={formik.values.start_date || null}
                                                            setValueBasic={(val) => formik.setFieldValue(`start_date`, val)}
                                                        />
                                                        {/* <CustomNewDateTimePicker
                                                            label="Start date"
                                                            value={formik.values.start_date || null}
                                                            minValue={new Date()}
                                                            onChange={(val) => formik.setFieldValue(`start_date`, val)}
                                                        /> */}

                                                        <ErrorMessage
                                                            name="start_date"
                                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                {/* <CustomeDatePicker
                                                                    disabled={formik.values.noendDate}
                                                                    minDate={formik.values.start_date}
                                                                    label="End date"
                                                                    valueBasic={formik.values.end_date || null}
                                                                    setValueBasic={(val) => {
                                                                        if (!formik.values.start_date) {
                                                                            formik.setFieldValue(`start_date`, val);
                                                                        }
                                                                        formik.setFieldValue(`end_date`, val);
                                                                    }}
                                                                /> */}
                                                                <NewCustomDateTimePicker
                                                                    disabled={formik.values.noendDate}
                                                                    minDate={formik.values.start_date}
                                                                    label="End date"
                                                                    valueBasic={formik.values.end_date || null}
                                                                    setValueBasic={(val) => {
                                                                        if (!formik.values.start_date) {
                                                                            formik.setFieldValue(`start_date`, val);
                                                                        }
                                                                        formik.setFieldValue(`end_date`, val);
                                                                    }}
                                                                />
                                                                {/* <CustomNewDateTimePicker
                                                                    disabled={formik.values.noendDate}
                                                                    {...(formik.values.start_date
                                                                        ? {
                                                                            minValue: new Date(formik.values.start_date)
                                                                        }
                                                                        : { minValue: new Date() })}
                                                                    label="End date"
                                                                    value={formik.values.end_date || null}
                                                                    onChange={(val) => {
                                                                        if (!formik.values.start_date) {
                                                                            formik.setFieldValue(`start_date`, val);
                                                                        }
                                                                        formik.setFieldValue(`end_date`, val);
                                                                    }}
                                                                /> */}
                                                                {/* <CustomDateTimePicker
                                                                disabled={formik.values.noendDate}
                                                                label="End date"
                                                                {...(formik.values.start_date && {
                                                                    minDate: new Date(formik.values.start_date)
                                                                })}
                                                                valueBasic={formik.values.end_date || null}
                                                                setValueBasic={(val) => {
                                                                    if (!formik.values.start_date) {
                                                                        formik.setFieldValue(`start_date`, val);
                                                                    }
                                                                    formik.setFieldValue(`end_date`, val);
                                                                }}
                                                            /> */}
                                                                <ErrorMessage
                                                                    name="end_date"
                                                                    render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} mt={2} ml={0.4}>
                                                                <FormControlLabel
                                                                    checked={formik.values.noendDate}
                                                                    control={
                                                                        <Checkbox
                                                                            disableRipple
                                                                            icon={
                                                                                <img
                                                                                    src={Unchecked}
                                                                                    style={{width: "18px", height: "18px"}}
                                                                                    alt="checked"
                                                                                />
                                                                            }
                                                                            checkedIcon={
                                                                                <img
                                                                                    src={Checked}
                                                                                    alt="checkbox"
                                                                                    style={{width: "18px", height: "18px"}}
                                                                                />
                                                                            }
                                                                        />
                                                                    }
                                                                    label={
                                                                        <Typography
                                                                            variant={
                                                                                formik.values.noendDate
                                                                                    ? "checkBoxSelected"
                                                                                    : "checkBoxUnSelected"
                                                                            }
                                                                        >
                                                                            No end date
                                                                        </Typography>
                                                                    }
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(`noendDate`, e.target.checked);
                                                                        if (e.target.checked) {
                                                                            formik.setFieldValue(`end_date`, "");
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item lg={6} md={6} xs={12}>
                                                        <CustomSelect
                                                            fullWidth
                                                            label="Promotion type"
                                                            menuItems={[
                                                                {id: "PERCENTAGE", label: "%"},
                                                                {id: "AMOUNT", label: "$"}
                                                            ]}
                                                            {...formik.getFieldProps("discount_type")}
                                                        />
                                                        <ErrorMessage
                                                            name="discount_type"
                                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={6} md={6} xs={12}>
                                                        <CustomTextField
                                                            fullWidth
                                                            placeholder={
                                                                formik.values.discount_type === "PERCENTAGE" ? "Percentage" : "Amount"
                                                            }
                                                            label={formik.values.discount_type === "PERCENTAGE" ? "Percentage" : "Amount"}
                                                            inputProps={{maxLength: 250, min: 0}}
                                                            onKeyDown={enforceFormat}
                                                            {...formik.getFieldProps("amount")}
                                                        />

                                                        <ErrorMessage
                                                            name="amount"
                                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <CustomSelectWithCheckbox
                                                    label="Service"
                                                    selectAll
                                                    menuItems={[
                                                        {id: "CATERING", label: "Catering"},
                                                        {id: "HOME_MEALS", label: "Home meals"}
                                                    ]}
                                                    values={formik.values.services || []}
                                                    onChange={(val) => formik.setFieldValue("services", val)}
                                                />

                                                <ErrorMessage name="services" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomSelectWithCheckbox
                                                    selectAll
                                                    label="Markets"
                                                    menuItems={marketList}
                                                    values={formik.values.market_id || []}
                                                    onChange={(val) => formik.setFieldValue("market_id", val)}
                                                />
                                                <ErrorMessage
                                                    name="market_id"
                                                    render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomSelectWithCheckbox
                                                    selectAll
                                                    label="Brands"
                                                    menuItems={brandList}
                                                    values={formik.values.brand_id || []}
                                                    onChange={(val) => formik.setFieldValue("brand_id", val)}
                                                />
                                                <ErrorMessage name="brand_id" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                            </Grid>

                                            <Grid item xs={12}>
                                                {/* <CustomTextField
                                                    fullWidth
                                                    placeholder="Company (optional)"
                                                    label="Company (optional)"
                                                    inputProps={{ maxLength: 250 }}
                                                    {...formik.getFieldProps("company")}
                                                /> */}
                                                <CustomTextField
                                                    // {...props}
                                                    fullWidth
                                                    name="company"
                                                    label="Company (optional)"
                                                    placeholder=""
                                                    inputProps={{maxLength: 250}}
                                                    value={formik.values.company}
                                                    {...formik.getFieldProps("company")}
                                                    onChange={(e) => {
                                                        setCompanySearch(e.target.value);
                                                        formik.setFieldValue("company", e.target.value);
                                                        if (e.target.value === "") {
                                                            formik.setFieldValue("companyId", null);
                                                        }
                                                    }}
                                                />
                                                {!!existingCompany?.length && (
                                                    <Box
                                                        sx={{
                                                            p: 1,
                                                            boxShadow: "0px 0px 3px rgba(0,0,0,0.3)",
                                                            borderRadius: 0.5,
                                                            position: "absolute",
                                                            width: "100%",
                                                            bgcolor: "#fff",
                                                            maxHeight: "200px",
                                                            overflow: "auto",
                                                            zIndex: 1380
                                                        }}
                                                    >
                                                        {existingCompany?.map((obj, i) => (
                                                            <Box
                                                                key={i}
                                                                sx={{p: 0.2, py: 0.2, cursor: "pointer", "&:hover": {bgcolor: "#Fafafa"}}}
                                                                onClick={() => {
                                                                    formik.setFieldValue("company", obj.company_name);
                                                                    formik.setFieldValue("companyId", obj.company_id);
                                                                    setExistingCompany([]);
                                                                }}
                                                            >
                                                                <Typography variant="body3" fontSize="0.875rem" fontWeight={500}>
                                                                    {obj?.company_name}
                                                                </Typography>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                )}

                                                <ErrorMessage name="company" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <CustomTextField
                                                    multiline
                                                    minRows={2.5}
                                                    sx={{"&": {height: "fit-content !important"}}}
                                                    fullWidth
                                                    placeholder="Description"
                                                    label="Description"
                                                    {...formik.getFieldProps("description")}
                                                />

                                                <ErrorMessage
                                                    name="description"
                                                    render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Stack>
                                <Box sx={{position: "sticky", bottom: 0, mt: 2, p: 2, bgcolor: "#fff", zIndex: 1}}>
                                    <ButtonGrouping
                                        btnprops={[
                                            {
                                                btnTitle: "Cancel",
                                                sx: (t) => ({color: t.palette.error.dark}),
                                                onClick: (e) => {
                                                    formik.resetForm();
                                                    toggleDrawer("right", false)(e);
                                                    handleSelectedRow();
                                                }
                                            },
                                            {
                                                btnTitle: "Save",
                                                variant: "contained",
                                                disabled: formik.isSubmitting,
                                                sx: (t) => ({color: t.palette.background.paper}),
                                                onClick: (e) => {
                                                    setSubmitEvent(e);
                                                    formik.handleSubmit();

                                                    formik.validateForm().then((res) => {
                                                        // to close the sidebar if no field has validation error
                                                        // if (Object.keys(res).length === 0) {
                                                        //     setTimeout(() => {
                                                        //         formik.resetForm();
                                                        //     }, 1500);
                                                        // }
                                                    });
                                                }
                                            }
                                        ]}
                                    />
                                </Box>
                            </Stack>
                        </TemporaryDrawer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CreatePromotionForm;
