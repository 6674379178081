import {Grid, Typography, Box} from "@mui/material";

function Cards({title, data}) {
    return (
        <Box
            sx={{
                borderRadius: "4px",
                border: "1px solid #DBDBDB",
                padding: "16px",
                width: {xs: "100%", sm: "50%"}
            }}
        >
            <Typography sx={{fontSize: "1.125rem", fontWeight: 500}} color="#020A11">
                {title}
            </Typography>
            {data.map((item, index) => (
                <div key={index} style={{marginTop: "12px"}}>
                    <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                        {item.label}
                    </Typography>
                    <Typography sx={{fontSize: "0.875rem", fontWeight: 500}} color="#212121">
                        {item.value}
                    </Typography>
                </div>
            ))}
        </Box>
    );
}
export default Cards;
