import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    customerPaymentProfileId: ""
};

const cardData = createSlice({
    name: "cardData",
    initialState,
    reducers: {
        setCustomerPaymentProfileId: (state, action) => {
            state.customerPaymentProfileId = action.payload;
        }
    }
});

export default cardData.reducer;

export const {setCustomerPaymentProfileId} = cardData.actions;
