import { Stack } from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
// import CustomDateRangePicker from "commonComponent/Form/Fields/CustomDateRangePicker";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import { ErrorMessage, Form, Formik } from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { NetworkManager, API } from "network/core";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import { values } from "lodash";
// import DateRange from 'commonComponent/Daterange';
import DateRange2 from 'commonComponent/DateRange2';


const formSchema = {
    marketName: "",
    city: "",
    state: "",
    email: ""
};

const CreateMarketForm = (props) => {
    const { toggleDrawer } = useTempDrawer();
    const [initialValues, setInitialValues] = React.useState(formSchema);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [focusedInput, setFocusedInput] = useState(null)
    const [weekEndDate, setWeeEndDate] = useState(null);
    const [weekStartDate, setWeekStartDate] = useState(null);
    const [dateRange, setDateRange] = useState({});
    const dispatch = useDispatch();
    const { handleIsEdited, selectedRow, handleSelectedRow, sideBarStateName = false } = props;

    const getState = async () => {
        const instance = NetworkManager(API.USER.STATE);
        const response = await instance.request();
        if (response.success) {
            const states = response.data.stateList.map((state) => ({
                label: state.name,
                value: state.name,
                id: state.state_id
            }));
            setStateList(states);
        }
    };
    const getCity = async (id) => {
        const instance = NetworkManager(API.USER.CITY(`?stateId=${id}`));
        const response = await instance.request();
        if (response.success) {
            const cities = response.data.cityList.map((city) => ({
                label: city.name,
                value: city.name,
                id: city.city_id
            }));

            setCityList(cities);
        }
    };

    useEffect(() => {
        getState();
    }, []);

    useEffect(() => {
        if (selectedRow) {
            getCity(selectedRow.state_id);
            setInitialValues({
                marketName: selectedRow.market_name,
                state: selectedRow.state_id,
                email: selectedRow.market_user_email
            });
        } else {
            setInitialValues({
                marketName: "",
                city: "",
                state: "",
                email: ""
            });
        }
    }, [selectedRow]);

    const handleAddMarket = async (values) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(selectedRow ? API.MARKET.UPDATE(selectedRow.market_id) : API.MARKET.ADD);
        const payload = {
            marketName: values.marketName,
            stateId: JSON.stringify(values.state),
            marketUserEmail: values.email
        };
        if (values.city !== "") {
            payload.cityId = JSON.stringify(values.city);
        }
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: selectedRow ? "Market updated successfully" : "Market created successfully",
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    }
    const handleDatesChange = (newDates) => {
        
        setStartDate(newDates[0])
        setEndDate(newDates[1])
    }
    

    const handleFocusChange = (focusedInput) => {
        setFocusedInput(focusedInput)
    }

    const handleAccept = (startDate, endDate) => {
        // setIsModalOpen(false);
        setWeeEndDate(endDate);
        setWeekStartDate(startDate);
        setDateRange({
            startDate,
            endDate,
        });
    };

    const handleCancel = () => {
        console.log('cancel')
    }
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={yup.object({
                    marketName: yup.string().required("Market name is required"),
                    // city: yup.string().required("Please choose city to continue."),
                    state: yup.string().required("State is required"),
                    email: yup.string().required("Market email is required").email("Enter valid email").nullable().trim()
                })}
                onSubmit={(val) => handleAddMarket(val)}
            >
                {(formik) => (
                    <Form>
                        <TemporaryDrawer
                            sideBarStateName={sideBarStateName}
                            handleSelectedRow={handleSelectedRow}
                            resetForm={formik.resetForm}
                            touched={formik.touched}
                        >
                            <Stack sx={{ height: "100%" }} justifyContent="space-between" mt={-2}>
                                <Stack spacing={2}>
                                    <PageHeader fontSize="1.625rem" title={selectedRow ? "Edit market" : "Create market"} showOnlyTitle />
                                    <Stack spacing={3}>
                                        <CustomTextField
                                            placeholder="Market"
                                            label="Market"
                                            inputProps={{ maxLength: 50 }}
                                            {...formik.getFieldProps("marketName")}
                                        />
                                        <ErrorMessage name="marketName" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        <CustomSelect
                                            label="State"
                                            menuItems={stateList}
                                            {...formik.getFieldProps("state")}
                                            // value={formik.state}
                                            // name='state'
                                            onChange={(e) => {
                                                const id = e.target.value;
                                                formik.setFieldValue("state", id);
                                                getCity(id);
                                            }}
                                        />
                                        <ErrorMessage name="state" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        <CustomTextField
                                            placeholder="Market email id"
                                            label="Market email id"
                                            inputProps={{ maxLength: 50 }}
                                            {...formik.getFieldProps("email")}
                                        />
                                        <ErrorMessage name="email" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        
                                        {/* <DateRange2
                                        startDate={startDate}
                                        endDate={endDate}
                                        value={[startDate,endDate]}
                                        handleDatesChange={handleDatesChange}
                                    
                                         /> */}
                                        
                                        {/* <CustomSelect
                                            disabled={!formik.values.state}
                                            placeholder="City"
                                            label="City"
                                            menuItems={cityList}
                                            // inputProps={{maxLength: 50}}
                                            {...formik.getFieldProps("city")}
                                        />
                                        <ErrorMessage name="city" render={(msg) => <CustomErrorMessage errorMessage={msg} />} /> */}
                                    </Stack>
                                </Stack>

                                <ButtonGrouping
                                    btnprops={[
                                        {
                                            btnTitle: "Cancel",
                                            sx: (t) => ({ color: t.palette.error.dark }),
                                            onClick: (e) => {
                                                formik.resetForm();
                                                toggleDrawer("right", false)(e);
                                                handleSelectedRow();
                                            }
                                        },
                                        {
                                            btnTitle: "Save",
                                            variant: "contained",
                                            sx: (t) => ({ color: t.palette.background.paper }),
                                            onClick: (e) => {
                                                formik.handleSubmit();

                                                formik.validateForm().then((res) => {
                                                    // to close the sidebar if no field has validation error
                                                    if (Object.keys(res).length === 0) {
                                                        toggleDrawer("right", false)(e);
                                                        setTimeout(() => {
                                                            formik.resetForm();
                                                        }, 1500);
                                                    }
                                                });
                                            }
                                        }
                                    ]}
                                />
                            </Stack>
                        </TemporaryDrawer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CreateMarketForm;
