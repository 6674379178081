import {ellipsizeText} from "./textUtils";

import {getformateDateUS, getFormattedTimeinAMPM} from "utils/app-dates/dates";
import {calculateFinalAmount, FormatCurrency} from "utils/helperFunctions/helpers";

const service = {
    HOME_MEALS: "Home Meals",
    CATERING: "Catering"
};

const getColorOfStatus = (status) => {
    switch (status) {
        case "Draft":
            return "#9F33F9";
        case "Quote sent":
            return "#24C4FB";
        case "QUOTE_REJECTED":
            return "#E10000";
        case "Cancelled":
            return "#E10000";
        case "Rejected":
            return "#757575";
        case "Needs driver":
            return "#FF226B";
        case "Accepted":
            return "#00ab3a";
        case "New":
            return "#F8B900";
        case "Active":
            return "#FF872A";
        case "Driver assigned":
            return "#FF872A";
        case "ON_THE_WAY":
            return "#00ab3a";
        default:
            return "#26272A";
    }
};

const paymentText = (row) => {
    if (row.charged_by === "CREDIT_CARD") {
        return row.is_payment_initiated ? "Paid" : "Unpaid";
    } else if (row.charged_by === "HOUSE_ACCOUNT") {
        if (Number(row?.remaining_amount_to_be_paid).toFixed(2) === Number(row?.total_price).toFixed(2)) {
            return "Unpaid";
        } else if (Number(row?.remaining_amount_to_be_paid) === 0) {
            return "Paid";
        } else if (Number(row?.remaining_amount_to_be_paid).toFixed(2) < Number(row?.total_price).toFixed(2)) {
            return "Partial";
        } else {
            return "Unpaid";
        }
    }
    return "Unpaid";
};

export default function getFieldsForDownload(headerName, row, id = false, path = false, searchParam = false) {
    // SCalculate the remaining amount after refund
    const totalAmount = row?.charge_fee_amount ? row.charge_fee_amount : row?.total_price ? row.total_price : 0;
    const remainingAmount = totalAmount - (row?.refund || 0);

    const newOrderItemPrice = calculateFinalAmount(row?.sales_tax_unit_value, row?.service_fee_unit_value, remainingAmount);

    switch (headerName) {
        case "customer":
            return `${
                row?.customer
                    ? ellipsizeText(
                          `${row?.customer?.full_name ?? ""} ${row?.customer?.last_name !== null ? row?.customer?.last_name : ""}`,
                          20
                      )
                    : "N/A"
            }`;
        case "company_name":
            return `${row?.customer?.company?.company_name}`;

        case "service_type":
            return `${ellipsizeText(row?.service_type ?? "N/A", 30)} ${ellipsizeText(row?.order_type, 30)}`;

        case "kitchen_location.market.market_name":
            return ellipsizeText(row?.kitchen_location?.market?.market_name ?? "N/A", 30);

        case "order_number":
            return `${row[headerName]} `;

        case "source":
            return `${row.order_by === "ADMIN" ? "Direct order" : "Marketplace"}`;

        case "total_price":
            return `$${parseFloat(row[headerName]).toFixed(2)}`;
        case "no_of_guest":
            return `${row.no_of_guest} guests`;

        case "delivery_address":
            return `${row.delivery_address ? ellipsizeText(row.delivery_address.split(",")[0], 30) : "N/A"} ${
                row.delivery_address ? ellipsizeText(row.delivery_address.split(",").splice(1), 30) : ""
            }`;

        case "brand.brand_name":
            return `${ellipsizeText(row?.brand?.brand_name ?? "N/A", 20)} `;
        case "kitchen":
            return `${row?.kitchen_location?.name}`;
        case "payment_by":
            return `${paymentText(row)} `;
        case "payment_method":
            return ` ${row.charged_by === "CREDIT_CARD" ? "Credit card" : "House account"}`;

        case "order.status":
            return ellipsizeText(
                row?.status === "Cancelled"
                    ? "Canceled"
                    : row?.status === "QUOTE_REJECTED"
                    ? "Quote rejected"
                    : row?.status === "Driver assigned"
                    ? "Active"
                    : row?.status === "ON_THE_WAY"
                    ? "On the way"
                    : row?.status ?? "N/A",
                30
            );
        case "delivery_date":
            return `${getformateDateUS(row[headerName])} ${row?.delivery_date === null ? "N/A" : ""} `;

        case "delivery_time":
            return `${getFormattedTimeinAMPM(row.delivery_date)}`;
        case "full_name":
            return `${ellipsizeText(row?.full_name + (row?.last_name ? `${row?.last_name}` : "") || "N/A")}`;
        case "email":
            return `${row[headerName] != null ? `${row[headerName]}` : "N/A"}`;
        case "averageOrderValue":
            return `${row?.averageOrderValue != null ? `$${row.averageOrderValue}` : "N/A"}`;
        case "isActivate":
            return `${row?.isActivate ? "Active" : "Pending"}`;
        case "company.company_name":
            return `${ellipsizeText(row?.company?.company_name ?? "N/A")}`;
        case "customerAddresses.delivery_address":
            return `${row?.customerAddresses?.map((address, index) => (address.delivery_address ? address.delivery_address : "N/A"))}`;
        case "lifeTimeValue":
            return `${row?.lifeTimeValue != null ? `$${row.lifeTimeValue}` : "N/A"}`;

        case "created_at":
            return `${getformateDateUS(row[headerName])} `;
        case "created_time":
            return `${getFormattedTimeinAMPM(row.created_at)}`;
        case "createdAt":
            return `${getformateDateUS(row[headerName])} ${getFormattedTimeinAMPM(row[headerName])}`;
        case "first_name": {
            return `${ellipsizeText(row?.first_name + (row?.last_name ? ` ${row?.last_name}` : "") || "N/A")}`;
        }
        case "role": {
            return `${ellipsizeText(row?.sub_role_detail?.role_name ?? "N/A")}`;
        }
        case "linkedin_link": {
            return `${ellipsizeText(row?.additional_details?.linkedin_link ?? "N/A")}`;
        }
        case "calendar_link": {
            return `${ellipsizeText(row?.additional_details?.calendar_link ?? "N/A")}`;
        }
        case "designation": {
            return `${ellipsizeText(row?.additional_details?.designation ?? "N/A")}`;
        }

        case "user.status":
            return `${
                row?.status === "ACTIVATE"
                    ? "Active"
                    : row?.status === "DEACTIVATE"
                    ? "Inactive"
                    : row?.status === "INVITED"
                    ? "Invited"
                    : "N/A"
            }`;

        case "service": {
            return row?.services?.length ? `[${row.services.map((el, idx) => `${idx > 0 ? " " : ""}${service[el] || ""}`)}]` : "N/A";
        }
        case "marketDetails": {
            return row.marketDetails?.length
                ? `[${row.marketDetails.map((el, idx) => `${idx > 0 ? " " : ""}${el.market_name || "N/A"}`)}]`
                : "N/A";
        }
        case "brandDetails": {
            return row.brandDetails?.length
                ? `[${row.brandDetails.map((el, idx) => `${idx > 0 ? " " : ""}${el.brand_name || "N/A"}`)}]`
                : "N/A";
        }
        case "start_date": {
            return row[headerName] ? getformateDateUS(row[headerName]) : "N/A";
        }
        case "end_date": {
            return row[headerName] ? getformateDateUS(row[headerName]) : "N/A";
        }
        case "end_promodate": {
            return row?.end_date ? (row.no_end_date ? "N/A" : getformateDateUS(row.end_date)) : "N/A";
        }
        case "customer_card_id": {
            return row?.customerCard && row.customerCard.length > 0 ? row.customerCard[0].customer_card_id || "N/A" : "N/A";
        }
        case "card.balance": {
            return row?.customerCard && row.customerCard.length > 0
                ? row.customerCard[0].balance != null
                    ? row.customerCard[0].balance
                    : "N/A"
                : "N/A";
        }
        case "service_fee": {
            return row?.refund
                ? FormatCurrency((row.service_fee_unit_value * newOrderItemPrice) / 100)
                : row?.charge_fee_amount
                ? "$0.00"
                : row?.service_fee_value
                ? FormatCurrency(row.service_fee_value)
                : "$0.00";
        }

        case "sales_tax": {
            return row?.refund
                ? `${FormatCurrency((row?.sales_tax_unit_value * newOrderItemPrice) / 100)}`
                : row?.charge_fee_amount
                ? "$0.00"
                : row?.sales_tax
                ? `${FormatCurrency(row.sales_tax)}`
                : "$0.00";
        }
        case "preferred_pricing": {
            return row?.refund
                ? "$0.00"
                : row?.charge_fee_amount
                ? "$0.00"
                : row?.preferred_price
                ? `${FormatCurrency(row.preferred_price)}`
                : "$0.00";
        }
        case "order_price": {
            return row?.refund
                ? `${FormatCurrency(newOrderItemPrice)}`
                : row?.charge_fee_amount
                ? "$0.00"
                : row?.order_price
                ? `${FormatCurrency(row.order_price - row?.preferred_price - (row?.price_type === "Discount" ? row?.price_type_value : 0))}`
                : "$0.00";
        }
        case "total_price2": {
            return row?.charge_fee_amount
                ? `${FormatCurrency(row?.charge_fee_amount)}`
                : row?.refund
                ? `${FormatCurrency(remainingAmount)}`
                : row?.charge_fee_amount
                ? row.charge_fee_amount
                : row?.total_price
                ? row.total_price
                : "$0.00";
        }
        case "total_price_item": {
            return row?.refund
                ? `${FormatCurrency(newOrderItemPrice)}`
                : row?.charge_fee_amount
                ? "$0.00"
                : row?.order_price
                ? `${FormatCurrency(row.order_price)}`
                : "$0.00";
        }

        case "sales": {
            return row.sales ? `$${row.sales}` : "$0.00";
        }
        case "price_type_value": {
            return row?.charge_fee_amount || row?.refund
                ? "$0.00"
                : row?.price_type === "Discount"
                ? row?.price_type_value
                    ? `$${row?.price_type_value}`
                    : "$0.00"
                : "$0.00";
        }
        case "refund": {
            return row?.refund ? `$${row.refund}` : "$0.00";
        }

        case "charge_fee_amount":
            return row.charge_fee_amount ? Number(row.charge_fee_amount).toFixed(2) : "$0.00";

        case "card.createdAt": {
            return row?.customerCard && row.customerCard.length > 0
                ? row.customerCard[0].createdAt
                    ? getformateDateUS(row.customerCard[0].createdAt)
                    : "N/A"
                : "N/A";
        }
        case "card.orders": {
            return row?.customerCard && row.customerCard.length > 0
                ? row.customerCard[0].orders
                    ? row.customerCard[0].orders
                    : "N/A"
                : "N/A";
        }
        case "is_active":
            return row?.is_active ? "Active" : "Inactive";
        default:
            return row?.[headerName] ?? "N/A";
    }
}

export function getFieldsForDownloadMenuItems(headerName, row, id = false, path = false, searchParam = false) {
    switch (headerName) {
        case "item_name":
            return row[headerName];

        case "menus":
            return row.menucategoryitem?.length > 0
                ? `[${row.menucategoryitem.map((item, index) =>
                      item?.menuCategory?.map((el, i) => `${i > 0 ? " " : ""}${el?.menu_name} `)
                  )}]`
                : "N/A";

        case "services":
            return row.menucategoryitem?.length > 0
                ? `[${row.menucategoryitem.map((item, index) =>
                      item?.menuCategory.length > 0
                          ? item?.menuCategory?.map((ell, iid) =>
                                ell.brandMenu?.map(
                                    (el, indexxx) =>
                                        el?.brand?.brandServices?.length > 0 &&
                                        el?.brand?.brandServices?.map(
                                            (element, idx) =>
                                                `${index > 0 ? ", " : ""}${idx > 0 ? ", " : ""}${service[element?.service_name]}`
                                        )
                                )
                            )
                          : "N/A"
                  )}]`
                : "N/A";

        case "createdAt":
            return getformateDateUS(row[headerName]);

        case "status":
            return row?.is_active ? "Active" : "Inactive";

        default:
            return row?.[headerName] ?? "N/A";
    }
}

export function getFieldsForDownloadTemplate(headerName, row, id = false, path = false, searchParam = false) {
    switch (headerName) {
        case "template_name":
            return row[headerName];

        case "templateBrandName":
            return row?.brand_name ? row?.brand_name : "N/A";
        case "templateMenuName":
            return row?.menu_name ? row?.menu_name : "N/A";

        case "templateService":
            return row?.services ? row?.services : "N/A";
        case "guest":
            return row?.guest ? row?.guest : "N/A";
        case "Created by":
            return row?.createdBy ? row?.createdBy : "N/A";

        case "createdAt":
            return getformateDateUS(row[headerName]);

        case "category_name":
            return ellipsizeText(row.category_name || "N/A");

        case "menu_type":
            // Join array elements into a single string, separated by commas
            return row.menu_type ? row.menu_type.join(", ") : "N/A";

        case "description":
            return ellipsizeText(row.description || "N/A");

        default:
            return row?.[headerName] ?? "N/A";
    }
}
