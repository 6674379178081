import {Typography, LinearProgress} from "@mui/material";
import {useEffect, useRef, useState} from "react";

import Camera from "assets/images/icons/camera-plus.png";

import {IconX} from "@tabler/icons";
import "./cropper.scss";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import CropModal from "./CropModal";

// Styles
const boxStyle = {
    width: "300px",
    height: "300px",
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center"
};
const modalStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

// Container
const Cropper = ({
    image = [],
    minSize = 155000,
    aspectWidth = 4,
    aspectHeight = 3,
    title = "Add a photo (Menu thumbnail)",
    multiPhoto = false,
    handleChange = {},
    handleError = {},
    isEdit
}) => {
    // image src
    const [src, setSrc] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    // preview
    const [preview, setPreview] = useState([]);

    const [uploadedImageList, setUploadedImageList] = useState([]);

    // modal state
    const [modalOpen, setModalOpen] = useState(false);

    // ref to control input element
    const inputRef = useRef(null);

    // handle Click
    const handleInputClick = (e) => {
        e.preventDefault();
        if (isEdit) {
            inputRef.current.click();
        }
    };

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    }

    const handleUploadedImage = (val) => {
        setUploadedImageList([...uploadedImageList, val]);
    };

    const uploadImage = async (image) => {
        setLoading(true);
        const instance = NetworkManager(API.IMAGE.ADD);
        // const file = new File([image], "image", {
        //     lastModified: new Date(),
        // });
        const packet = new FormData();
        packet.append("image", image);

        const response = await instance.request(packet);

        if (response.success) {
            handleUploadedImage(response.data.url);
            let tempPrev = [];
            if (multiPhoto) {
                tempPrev = [...preview];
                tempPrev.push(response.data.url);
            } else {
                tempPrev.push(response.data.url);
            }
            setPreview(tempPrev);
            handleChange(tempPrev);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setLoading(false);
        setModalOpen(false);
    };

    // handle Change
    const handleImgChange = async (e) => {
        const target = e.target;
        const validImage = {"image/png": 1, "image/jpeg": 1, "image/jpg": 1, "image/svg": 1, "image/svg+xml": 1};

        if (target.files && target.files[0]) {
            const minAllowedSize = minSize; // 155kb
            const maxAllowedSize = 2e6; // 2mb
            console.log("target.files[0].size  : ", target.files[0].size);

            if (validImage[target.files[0].type] !== 1) {
                target.value = "";
                handleError("Only jpg/jpeg, png, and svg files are allowed!");
            } 
            // else if (target.files[0].size < minAllowedSize) {
            //     target.value = "";
            //     handleError(`Minimum  151KB file size is required`);
            // }
             else if (target.files[0].size > maxAllowedSize) {
                target.value = "";
                handleError("Maximum 2MB file size is required");
            } else {
                const imageDataUrl = await readFile(e.target.files[0]);
                uploadImage(imageDataUrl);
                setSrc(imageDataUrl);
                setModalOpen(true);
            }
        }
        e.target.value = null;
    };

    const handleDeleteImage = async (ind) => {
        const prev = [...preview];
        prev.splice(ind, 1);
        setPreview(prev);

        // delete from backend
        const instance = NetworkManager(API.IMAGE.DELETE);

        const packet = {
            url: uploadedImageList[ind]
        };
        const response = await instance.request(packet);

        if (response.success) {
            const prev1 = [...uploadedImageList];
            prev1.splice(ind, 1);
            setUploadedImageList(prev1);
            handleChange(prev1);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        }
    };

    useEffect(() => {
        let imageArray;
        if (image) {
            imageArray = Array.isArray(image) ? image : [image];
            setPreview(imageArray);
            setUploadedImageList(imageArray);
        }
    }, [image]);

    return (
        <>
            <main className="container">
                {/* <CropModal
                    loading={loading}
                    imgSrc={src}
                    aspectWidth={aspectWidth}
                    aspectHeight={aspectHeight}
                    open={modalOpen}
                    handleClose={() => {
                        setModalOpen(false);
                    }}
                    changeImage={uploadImage}
                    // aspect={4 / 3}
                    cropShape="rect"
                /> */}
                {/* <CropperModal
                    modalOpen={modalOpen}
                    src={src}
                    setPreview={setPreview}
                    setModalOpen={setModalOpen}
                    multiPhoto={multiPhoto}
                    preview={preview}
                    handleChange={handleChange}
                    handleUploadedImage={handleUploadedImage}
                /> */}
                {Array.isArray(preview) &&
                    preview.length > 0 &&
                    preview.map((prev, index) => (
                        <div className="img-container" key={index}>
                            <img src={prev} alt="" loading="lazy" />
                            {isEdit && (
                                <IconX
                                    className="close"
                                    onClick={() => {
                                        if (isEdit) {
                                            handleDeleteImage(index);
                                        }
                                    }}
                                />
                            )}
                        </div>
                    ))}

                {isEdit && (
                    <a href="/" className="tag" onClick={handleInputClick} style={{opacity: !isEdit ? 0.6 : 1}}>
                        <img src={Camera} className="upload-icon" alt="add-pics" title="add pics" />
                        <Typography
                            sx={{fontSize: 14, fontWeight: 400, marginLeft: "20px", color: (theme) => theme.palette.secondary.main}}
                        >
                            {" "}
                            {title}
                        </Typography>
                    </a>
                )}

                <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg, image/svg, image/svg+xml"
                    disabled={!isEdit}
                    ref={inputRef}
                    onChange={handleImgChange}
                />
                {loading && <LinearProgress />}
            </main>
        </>
    );
};

export default Cropper;
