import React, {useState, useEffect, useContext, useLayoutEffect} from "react";
import {Grid} from "@mui/material";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {NetworkManager, API} from "network/core";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, useNavigate, useSearchParams} from "react-router-dom";
import {generateUrl, getManagers, getSubAdminName} from "utils/helperFunctions/helpers";
import FilterChips from "commonComponent/FilterChipsComponent";
import {FilterContext} from "contexts/FilterContext";
import Filter from "commonComponent/FilterAccoridan";
import useTempDrawer from "hooks/useTempDrawer";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import {setChildPageNumber, setChildRowsPerPage, setChildShowFilter} from "store/slices/paginationSlice";
import ManagerModal from "commonComponent/DilogBox/ManagerModal";
import Fields from "commonComponent/Apptable/getFieldsForDownload";
import {CsvBuilder} from "filefy";
import PrintOrderModal from "commonComponent/DilogBox/PrintOrderModal";
import HouseAccountPayment from "commonComponent/DilogBox/HouseAccountPayment";
import {openSnackbar} from "store/slices/snackbar";
import useToggleDilog from "hooks/useToggleDilog";
import RefundForm from "../../orders/CreateOrder/createOrderFooter/Refund";
import AddNewNotes from "../../customers/CreateCustomer/AddNewNoteForm";

const OrderDetails = (props) => {
    const [searchParam] = useSearchParams();
    const orderName = searchParam.get("PeriodName");
    const {state, toggleDrawer} = useTempDrawer();
    const {setSelectedCheckbox, updateListCount, selectedCheckbox} = props;
    const [open, setOpen] = useState(false);
    const [openCustomMenu, setOpenCustomMenu] = useState(false);
    const [orderListing, setOrderListing] = React.useState([]);
    const {handleFilter} = useContext(FilterContext);
    const [marketList, setMarketList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [searchedValue, setSearchedValue] = useState({});
    const [filterValue, setFilterValue] = useState("");
    const [customers, setCustomers] = useState([]);
    const currentPage = useSelector((state) => state?.pagination?.childCurrentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.childRowsPerPage);
    const [orderBy, setOrderBy] = useState("created_at");
    const [order, setOrder] = useState("desc");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const filterChips = useSelector((state) => state?.pagination?.childFilterChips);
    const showFilter = useSelector((state) => state?.pagination?.childShowFilter);
    const navigation = useNavigate();
    const [search, setSearch] = useState("");
    const [managers, setManagers] = useState([]);
    const [filterManagers, setFilterManagers] = useState([]);
    const [printOptionModal, setPrintOptionModal] = useState(false);
    const [priceParam, setPriceParam] = useState("customer_order");
    const [showHeading, setShowHeading] = useState(false);
    const navigate = useNavigate();
    const {toggleDilog} = useToggleDilog();

    const [refetch, setRefetch] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        getManagers(setManagers, setLoading);
    }, []);
    useLayoutEffect(() => {
        if (searchedValue?.search === "") {
            setManagers([]);
        } else if (searchedValue?.search) {
            getManagers(setFilterManagers, () => {}, searchedValue?.search);
        }
    }, [searchedValue?.search]);
    const getOrdersList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.ORDER.GET);
        const params = {
            offset: currentPage,
            cancellationWithZero: "no",
            limit: rowsPerPage,
            monthYear: orderName,
            ...(moreFilters && {...moreFilters}),
            refund: "yes"
        };
        if (moreFilters && moreFilters.guest_count) {
            if (moreFilters.guestFilter === "equal" || moreFilters.guestFilter === undefined) {
                params.guest_count = moreFilters.guest_count;
            } else {
                delete params.guest_count;
                params[`guest_count${moreFilters.guestFilter}`] = moreFilters.guest_count;
            }
            delete params.guestFilter;
        }
        if (moreFilters && moreFilters.total_amount) {
            if (moreFilters.amountFilter === "equal" || moreFilters.amountFilter === undefined) {
                params.total_amount = moreFilters.total_amount;
            } else {
                delete params.total_amount;
                params[`total_amount${moreFilters.amountFilter}`] = moreFilters.total_amount;
            }
            delete params.amountFilter;
        }
        if (moreFilters?.charged_by) {
            params.charged_by = moreFilters?.charged_by.join(",");
        }

        if (orderBy) {
            params.sort_by = orderBy;
            params.sort = order;
        }

        if (search === "") {
            params.offset = currentPage;
        }
        if (search !== "") {
            params.search = search;
            setOrderListing([]);
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);
        if (response.success) {
            const tempData = [];
            response.data?.orderList.forEach((element) => {
                const obj = element;
                obj.customerUniqueID = "uniqueCustomerElement";
                if (searchParam.get("type") === "sales") {
                    obj.subAdmin = getSubAdminName(element?.accountManager);
                    obj.accountManagerId = element?.accountManager?.user_id;
                }
                tempData.push(obj);
            });
            setOrderListing(tempData);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
            setSearchedValue({});
        }
        setLoading(false);
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const openEditManager = () => {
        setOpen((prev) => !prev);
    };

    const handlePageChange = (e, number) => {
        dispatch(setChildPageNumber(number + 1));
    };
    const handleSearch = (val) => {
        setSearch(val);
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        dispatch(setChildRowsPerPage(number));
        dispatch(setChildPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        setOrder(val1);
        setOrderBy(val2);
    };
    const getMarketList = async (page = 1, pageSize = 1000) => {
        const tempData = [];
        const instance = NetworkManager(API.MARKET.GET);
        const params = {
            page: page,
            pageSize: pageSize
        };
        if (orderBy) {
            params.sortBy = "createdAt";
            params.orderBy = "desc";
        }

        const response = await instance.request({}, params);

        if (response.success) {
            response.data.marketList.forEach((el) => {
                tempData.push({id: el.market_id, label: el.market_name});
            });
        }
        setMarketList(tempData);
    };
    const getCustomerListing = async (searchedValue) => {
        const tempData = [];
        if (!searchedValue) {
            setCustomers([]);
        } else {
            const instance = NetworkManager(API.CUSTOMER.GET);
            const response = await instance.request({}, [`?full_name=${searchedValue}`]);

            if (response?.success) {
                response?.data?.customers.forEach((el) => {
                    tempData.push({id: el?.user_id, label: `${el?.full_name} ${el?.last_name}`});
                });
            }
            setCustomers(tempData);
        }
    };

    useEffect(() => {
        if (searchedValue?.customerName) {
            getCustomerListing(searchedValue?.customerName);
        } else {
            getCustomerListing();
        }
    }, [searchedValue]);

    const getBrandList = async (page = 1, pageSize = 1000) => {
        const tempData = [];
        const instance = NetworkManager(API.BRAND.GET);
        const params = {
            page: page,
            pageSize: pageSize
        };
        if (orderBy) {
            params.sortBy = "createdAt";
            params.orderBy = "desc";
        }

        const response = await instance.request({}, params);

        if (response.success) {
            response.data.brandList.forEach((el) => {
                tempData.push({id: el.brand_id, label: el.brand_name});
            });
        }
        setBrandList(tempData);
    };
    useEffect(() => {
        getMarketList();
        getBrandList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (search) {
            dispatch(setChildPageNumber(1));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        getOrdersList(Object.keys(filterChips).length ? filterChips : "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, currentPage, rowsPerPage, order, orderBy, isEdited, filterChips, updateListCount, refetch]);

    const filterState = [
        {
            type: "dateRange",
            key1: "start_date",
            key2: "end_date",
            startLabel: "Created date",
            endLabel: "Created to"
        },
        {
            type: "dateRange",
            key1: "delivery_start_date",
            key2: "delivery_end_date",
            startLabel: "Delivery date",
            endLabel: "Delivery to"
        },
        {
            key: "customer_name",
            label: "Customer",
            type: "select",
            getData: customers,
            freeSolo: true
        },
        {
            key: "source",
            label: "Source",
            type: "multiselect",
            getData: () => [
                {id: "ADMIN", label: "Direct order"},
                {id: "Customer", label: "Marketplace"},
                {id: "EZcater", label: "EZcater"},
                {id: "EZorder", label: "EZorder"}
            ]
        },
        {
            key: "service_type",
            label: "Service",
            type: "multiselect",
            getData: () => [
                {id: "CATERING", label: "Catering"},
                {id: "HOME_MEALS", label: "Home meals"}
            ]
        },
        {
            key: "charged_by",
            label: "Payment",
            type: "multiselect",
            getData: () => [
                {id: "HOUSE_ACCOUNT", label: "House account"},
                {id: "CREDIT_CARD", label: "Credit card"}
            ]
        },
        {
            key: "market_id",
            label: "Market",
            type: "multiselect",
            getData: marketList
        },
        {
            key: "brand_id",
            label: "Brand",
            type: "multiselect",
            getData: brandList
        },
        {
            key: "guest_count",
            label: "Guests",
            type: "numberfield",
            filterType: "Equals",
            filterKey: "guestFilter",
            getData: [
                {label: "Equals", value: "equal", id: "equal"},
                {label: "Greater than", value: "greater than", id: "_greater_than"},
                {label: "Less than", value: "less than", id: "_less_than"},
                {label: "Not equal", value: "not equal", id: "_not_equal"}
            ]
        },
        {
            key: "total_amount",
            label: "Total",
            type: "numberfieldWithDecimal",
            filterType: "Equals",
            filterKey: "amountFilter",
            getData: [
                {label: "Equals", value: "equal", id: "equal"},
                {label: "Greater than", value: "greater than", id: "_greater_than"},
                {label: "Less than", value: "less than", id: "_less_than"},
                {label: "Not equal", value: "not equal", id: "_not_equal"}
            ]
        }
    ];
    const handleHeaderActions = (btntype) => (e) => {
        switch (btntype) {
            case "none": {
                dispatch(setChildShowFilter(true));
                toggleDrawer("right", true)(e);
                break;
            }
            case "orderFilter": {
                dispatch(setChildShowFilter(!showFilter));
                handleFilter();
                break;
            }
            default: {
                break;
            }
        }
    };
    const handleIsEdited = () => {
        setEdited((prev) => !prev);
        toggleDrawer("right", false)();
    };
    const requiredFilter =
        searchParam.get("type") === "sales"
            ? [
                  ...filterState,
                  {
                      key: "account_manager_id",
                      label: "Account manager",
                      type: "select",
                      getData: filterManagers,
                      freeSolo: true
                  }
              ]
            : filterState;
    const tableHeader = [
        {key: "order_number", label: "Order #"},
        {key: "created_at", label: "Created"},
        {key: "delivery_date", label: "Delivery date"},
        {key: "customer", label: "Customer"},
        {key: "delivery_address", label: "Address"},
        {key: "service_type", label: "Service"},
        {key: "kitchen_location.market.market_name", label: "Market"},
        {key: "brand.brand_name", label: "Brand"},
        {key: "total_price_item", label: "Order items"},
        {key: "preferred_pricing", label: "Preferred pricing"},
        {key: "price_type_value", label: "Discounts"},
        {key: "order_price", label: "Net order items"},
        {key: "service_fee", label: "Service fee"},
        {key: "sales_tax", label: "Sales tax"},
        {key: "charge_fee_amount", label: "Cancellation fees"},
        {key: "refund", label: "Refunds"},
        {key: "total_price2", label: "Total"},
        {key: "payment_by", label: "Payment"},
        {key: "order.status", label: "Status"},
        {key: "action", label: "Action"}
    ];

    const columnData = [
        {key: "order_number", label: "Order #"},
        {key: "created_at", label: "Created"},
        {key: "delivery_date", label: "Delivery date"},
        {key: "customer", label: "Customer"},
        {key: "delivery_address", label: "Address"},
        {key: "service_type", label: "Service"},
        {key: "kitchen_location.market.market_name", label: "Market"},
        {key: "brand.brand_name", label: "Brand"},
        {key: "total_price_item", label: "Order items"},
        {key: "preferred_pricing", label: "Preferred pricing"},
        {key: "price_type_value", label: "Discounts"},
        {key: "order_price", label: "Net order items"},
        {key: "service_fee", label: "Service fee"},
        {key: "sales_tax", label: "Sales tax"},
        {key: "charge_fee_amount", label: "Cancellation fees"},
        {key: "refund", label: "Refunds"},
        {key: "total_price2", label: "Total"},
        {key: "payment_by", label: "Payment"},
        {key: "order.status", label: "Status"}
    ];

    const csvBuilder = (rows) => {
        const temp = [];
        temp.push(JSON.stringify(rows));

        new CsvBuilder("report_list.csv")
            .setColumns(columnData.map((col) => col.label))
            .addRows(
                temp.map((row) =>
                    columnData.map((col) => {
                        const arr = JSON.parse(row);

                        return Fields(col.key, arr);
                    })
                )
            )
            .exportFile();

        setSelectedCheckbox([]);
    };

    const handleCustomMenu = () => {
        setOpenCustomMenu((pre) => !pre);
    };

    const handleHouseAccountPayment = () => {
        getOrdersList();
    };


    const handlePrintModal = () => {
        setPrintOptionModal((pre) => !pre);
    };

    const handleSendReceipt = async (row) => {
        const instance = NetworkManager(API.ORDER.SENDRECEIPT(row?.order_id));
        const response = await instance.request();

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Order receipt sent successfully",
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handlePrintDownload = (price) => {
        handlePrintModal();
        window.open(`/print?orderId=${selectedRow.order_id}&price=${price}`, "_blank", "noreferrer");
    };

    const handleReorder = (orderId) => {
        navigate(`/orders?tab=scheduled&reOrder=reOrder&orderId=${orderId}`);
        toggleDilog("CreateOrder")();
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                navigation(`/customers/${row.user_id}/?tab=profile&customerName=${row?.full_name}&edit=true`);
                break;
            }
            case "Download": {
                csvBuilder(row);
                break;
            }
            case "Print": {
                handlePrintModal(row);
                break;
            }
            case "Add note": {
                toggleDrawer("right", "addNewNotes")(e);
                break;
            }
            case "Re-order": {
                handleReorder(row.order_id);
                break;
            }
            case "Send receipt": {
                handleSendReceipt(row);
                break;
            }
            case "Issue refund": {
                toggleDrawer("right", "refund")(e);
                break;
            }
            case "Mark as paid": {
                handleCustomMenu();
                break;
            }
            case "Edit manager":
                openEditManager();
                break;

            default:
                break;
        }
    };

    const statusIndex = tableHeader.findIndex((header) => header.key === "order.status");

    if (searchParam.get("type") === "sales" && statusIndex !== -1) {
        tableHeader.splice(statusIndex, 0, {key: "subAdmin", label: "Account manager"});
    }

    const requiredHeader = tableHeader;
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TableTopFilterWrapper
                        lessmarginOnTop
                        pageheader={
                            <PageHeader
                                title={orderName || "Reports"}
                                breadsCrumData={[
                                    {label: "Reports", href: "/Reports?tab=orders"},

                                    {label: orderName, isActive: true}
                                ]}
                            />
                        }
                        handleClick={handleHeaderActions}
                        handleSearch={handleSearch}
                        filterType="orderFilter"
                        orderFilter="orderFilter"
                        selectedCheckbox={selectedCheckbox}
                    >
                        <Filter
                            filterState={requiredFilter}
                            filterChips={filterChips}
                            setSearchedValue={setSearchedValue}
                            filterValue={filterValue}
                            showFilter={showFilter}
                            isChild
                        />

                        <FilterChips filterChips={filterChips} isChild getLabel={managers} filterState={requiredFilter} />
                        <CommonTable
                            loading={loading}
                            data={orderListing}
                            checkbox
                            header={requiredHeader}
                            handleActionBtn={handleActionBtn}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsChange}
                            handleSort={handleSort}
                            totalItems={totalItems}
                            rowsPerPage={rowsPerPage}
                            currentPage={currentPage - 1}
                            sortingLabel={["Order ID", "Created", "Delivery date", "Total"]}
                            setSelectedCheckbox={setSelectedCheckbox}
                            selectedCheckbox={selectedCheckbox}
                            isReport
                            actions={searchParam.get("type") === "sales" ? ["Edit manager"] : []}
                        />
                    </TableTopFilterWrapper>
                    <PrintOrderModal
                        open={printOptionModal}
                        buttonTitle="Print order"
                        title="Print order"
                        description="Select the type of order you would like to print"
                        handleClose={handlePrintModal}
                        handleSubmit={(price) => handlePrintDownload(price)}
                        noOnClose
                        selected={priceParam}
                        onSelect={(price) => {
                            setPriceParam(price);
                            setShowHeading(price === "price");
                        }}
                    />
                    <HouseAccountPayment
                        open={openCustomMenu}
                        buttonTitle="Submit"
                        title="How much is received?"
                        orderId={selectedRow?.order_id}
                        remainingAmount={selectedRow?.remaining_amount_to_be_paid}
                        handleClose={handleCustomMenu}
                        handleHouseAccountPayment={handleHouseAccountPayment}
                        noOnClose
                    />
                    {state.right === "refund" && (
                        <RefundForm
                            handleSelectedRow={handleSelectedRow}
                            customerId={selectedRow?.customer?.user_id}
                            orderId={selectedRow?.order_id}
                            orderNumber={selectedRow?.order_number}
                            refundMethod={selectedRow?.charged_by}
                            orderData={selectedRow}
                        />
                    )}
                    {state.right === "addNewNotes" && (
                        <AddNewNotes
                            userID={selectedRow?.customer?.user_id}
                            selectedData={selectedRow}
                            handleIsEdited={handleIsEdited}
                            handleSelectedRow={handleSelectedRow}
                            // setSelectedRow={setSelectedRow}
                        />
                    )}
                </Grid>
            </Grid>
            {open && (
                <ManagerModal
                    open={open}
                    buttonTitle={selectedRow?.accountManager ? "Update Manager" : "Assign manager"}
                    setRefetch={setRefetch}
                    title={selectedRow?.accountManager ? "Update manager" : "Assign a manager"}
                    managers={managers}
                    selectedRow={selectedRow}
                    handleClose={openEditManager}
                />
            )}
        </>
    );
};

export default OrderDetails;
