// Higher Order Class to make all network calls
import {Cookies} from "react-cookie";
import axios from "axios";
import {APIWithOfflineRouter, HTTP_METHODS} from "./httpHelper";
import {APIConfig} from "../config/serverConfig";
import {APIError, APIResponse} from "./responseParser";
import {refreshAuthToken} from "./tokenRefresher";
import {CookieKeys} from "constants/cookiesKeys";
import {APIAborter} from "./abortController";
import offlineManager from "./offlineManager";
import {HTTP_STATUS} from "./statusCode";
import {apiError, offlineNotation} from "./errorParser";
import {dispatch, store} from "../../store/index";
import {LOGIN, LOGOUT} from "store/actions";

// import { UserState } from "redux/dispatcher/UserState"

// ********************
// Create a new Instance of NetworkManager by passing APIRouter argument
// After creating instance, call `request` method to make network request
// Example:
// const payload = {email: "example@gmail.com", password: "123456"}
// const instance = NetworkManager(API.Auth.Login)
// const result = await instance.request(payload)
// --------------------
// You can also pass some id in the url as parameter
// If params are named params then pass an object, the key name must match the param name
// Eg. If the URL is like "https://example.com/login?type=regular", then request would look like below
// const result = await instance.request(payload, {type: "regular"})
// --------------------
// If the params are not named then pass an array of data
// Eg. If the URL is like "https://example.com/user/1/2", then request would look like below
// const result = await instance.request(payload, ["id1", "id2"])
// ********************

// Prepare endpoint url with params
function urlBuilder(router, params) {
    let uri = "";
    if (typeof router.version === "string") {
        uri = `/${router.version}`;
    }
    uri = uri.concat(router.endpoint);
    // all params in form of uri/id1/id2/id3
    if (Array.isArray(params)) {
        params.forEach((key) => {
            uri = uri.concat("/", key);
        });
    }
    return uri;
}

// Prepare endpoint body for no GET requests
function httpBodyBuilder(body, withFile) {
    if (withFile) {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
            if (body[key] instanceof FileList) {
                body[key].forEach((file) => {
                    formData.append(key, file);
                });
            } else {
                formData.append(key, body[key]);
            }
        });
        // for (const key in body) {
        //   if (body[key] instanceof FileList) {
        //     for (const file of body[key]) {
        //       formData.append(key, file)
        //     }
        //   } else {
        //     formData.append(key, body[key])
        //   }
        // }
        return formData;
    }
    return body;
}

export default function networkManager(router, withFile = false) {
    const {TIMEOUT, API_AUTH_HEADER, AUTH_TYPE, CONTENT_TYPE} = APIConfig;
    const REQ_CONTENT_TYPE = withFile ? CONTENT_TYPE.MULTIPART : CONTENT_TYPE.JSON;

    axios.defaults.baseURL = router.baseURL;
    axios.defaults.timeout = TIMEOUT;
    axios.defaults.headers.common["Content-Type"] = REQ_CONTENT_TYPE;
    axios.defaults.headers.common["Accept-Language"] = "en";
    axios.defaults.crossdomain = true
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = '*';

    const AppEnvIsDev = process.env.NODE_ENV === "development";

    let refreshCount = 0;

    async function request(body = {}, params = {} || []) {
        const cookie = new Cookies();
        const authToken = cookie.get(CookieKeys.Auth);

        if (authToken && authToken !== "undefined") {
            axios.defaults.headers.common[API_AUTH_HEADER] = `${authToken}`;
        } else {
            axios.defaults.headers.common[API_AUTH_HEADER] = "";
        }
        const url = urlBuilder(router, params);
        const getHttpMethod = router.method !== HTTP_METHODS.GET;
        const getArrayParams = !Array.isArray(params) && Object.keys(params)?.length;
        const httpBody = httpBodyBuilder(body, withFile);

        try {
            const result = await axios.request({
                signal: APIAborter.initiate().signal,
                url: url,
                method: router.method,
                ...(getHttpMethod && {data: httpBody}),
                ...(getArrayParams && {params: params})
            });
            // If token expired, get it refreshed
            const response = result.data;
            
            if(response?.error){
                return new APIError(response?.message, response.code);
            }
            // return new APIResponse(response.data, response.success=true, result.status, response.data?.message)
            if (response) {
                return new APIResponse(
                    response.data || response.modifierGroup || response.payment,
                    true,
                    result.status,
                    response.data?.message ? response.data?.message : response.message
                );
            }

            return new APIResponse(response, true, result.status, response.message);
        } catch (err) {
            // Catch all errors
            apiError(err?.response?.data?.message);
            const IsNetworkError = err.code === HTTP_STATUS.NETWORK_ERR;
            // const IsOfflineRouter = router instanceof APIWithOfflineRouter
            // // Check if develop sever running and API failed
            // if (IsOfflineRouter && (IsNetworkError || AppEnvIsDev)) {
            //   offlineNotation()
            //   return offlineManager(router.offlineJson)
            // }
            if (err.response?.status === 401) {
                if (refreshCount < APIConfig.MAX_REFRESH_ATTEMPTS) {
                    const refreshToken = cookie.get(CookieKeys.REFRESH_TOKEN);
                    const authToken = cookie.get(CookieKeys.Auth);
                    let resp = null;
                    if (refreshToken && authToken) {
                        resp = await refreshAuthToken(refreshToken, authToken);
                    }

                    if (resp?.status === 200) {
                        // save new token to auth cookies
                        cookie.set(CookieKeys.Auth, `Bearer ${resp.data.user.token}`);
                    }

                    if (resp?.error) {
                        // if error move to logout screen
                        cookie.remove(CookieKeys.Auth);
                        cookie.remove(CookieKeys.REFRESH_TOKEN);
                        cookie.remove(CookieKeys.DEVICE_TOKEN);
                        store.dispatch({
                            type: LOGOUT
                        });
                    }

                    // pass the control back to network manager
                    // return await request(body, params)
                    if (resp) {
                        refreshCount += 1;
                        return request(body, params);
                    }
                    return new APIError(err?.response?.data?.message, err.code);
                }
                // else {
                //   // UserState.observeLogout()
                // }
            } else if (err.code === HTTP_STATUS.NETWORK_ERR) {
                apiError("Internal server error!");
            }
            return new APIError(err?.response?.data?.message, err.code);
        }
    }
    return {
        request
    };
}
