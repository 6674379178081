import { Box, InputAdornment, Menu, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { enforceFormat, enforceFormatForNumber,commaFormattedNumber } from "utils/helperFunctions/helpers";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import { ErrorMessage } from "formik";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";

export const GuestCount = (props) => {
    const { Parentformik } = props;
    const { headerData } = Parentformik.values;

    const [guest, setGuest] = useState("");

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setGuest(headerData?.forHowMany);
    }, [headerData?.forHowMany]);

    return (
        <>
            <Stack
                spacing={0.5}
                nowrap
                sx={{ minWidth: 0, maxWidth: '227px', cursor: "pointer" }}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                className="muiStack_start"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <Typography variant="OrderSectionHeading">For how many?</Typography>

                <Stack direction="row" spacing={1} mt='0px !important' alignItems="center">
                    <Typography
                        variant="OrderSectionHeading"
                        fontWeight={400}
                        sx={{ whiteSpace: "nowrap" }}
                    >
                        {headerData?.forHowMany ? `${commaFormattedNumber(headerData?.forHowMany)} guests` : "Add guests"}
                    </Typography>
                    <KeyboardArrowDownIcon fontSize="small" />
                </Stack>
                <ErrorMessage name="headerData.forHowMany" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
            </Stack>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
            >
                <Box p={2} pb={0}>
                    <CustomTextField
                        sx={{ width: 190 }}
                        type="text"
                        onKeyDown={enforceFormatForNumber}
                        value={guest}
                        min={1}
                        onBlur={(e) => {
                            Parentformik.setTouched({ "headerData.forHowMany": true })
                        }}
                        onChange={(e) => {
                            setGuest(e.target.value)
                            Parentformik.setFieldValue("headerData.forHowMany", e.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" sx={{ color: "#212121" }}>
                                    guests
                                </InputAdornment>
                            ), inputProps: { min: 1,maxLength:10  }
                        }}
                    />

                    <Box p={2} pb={0} />


                </Box>
            </Menu>
        </>
    );
};
