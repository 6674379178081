import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";

const MenuProps = {
    PaperProps: {
        style: {
            zIndex: 1550
        }
    }
};
export default function CustomSelect(props) {
    const {label, fontStyles, labelProps, noNone, menuItems = [], numberValidation = false, ellipSizeNumber = 30, ...restprops} = props;
    return (
        <Box className="custom-select">
            <FormControl fullWidth>
                <InputLabel {...labelProps} id="demo-simple-select-label">
                    {label}
                </InputLabel>
                <Select
                    {...restprops}
                    IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={label}
                    // sx={{zIndex:1450}}
                    MenuProps={MenuProps}
                    sx={fontStyles ? {...fontStyles} : undefined}
                >
                    {!noNone && (
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                    )}
                    {!numberValidation &&
                        menuItems
                            ?.sort((a, b) => a?.label?.localeCompare(b?.label))
                            ?.map((obj, id) => (
                                <MenuItem key={id} value={obj.id} label={obj.label} kitchenList={obj?.kitchenList ?? []}>
                                    {ellipsizeText(obj.label, ellipSizeNumber)}
                                </MenuItem>
                            ))}
                    {numberValidation &&
                        menuItems?.map((obj, id) => (
                            <MenuItem key={id} value={obj.id} label={obj.label} kitchenList={obj?.kitchenList ?? []}>
                                {ellipsizeText(obj.label, ellipSizeNumber)}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </Box>
    );
}
