import {Breadcrumbs, Typography} from "@mui/material";
import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {Link, useLocation} from "react-router-dom";

const BreadsCrums = (props) => {
    const {data = []} = props;

    const {pathname} = useLocation();

    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" sx={{opacity: 0.5003}} />} aria-label="breadcrumb">
            {data.map((obj, i) => (
                <Typography
                    key={i}
                    variant="caption"
                    {...(obj?.href && {component: Link})}
                    {...(obj?.href && {to: obj?.href})}
                    sx={{
                        color: (theme) => theme.palette.secondary[800],
                        fontWeight: 500,
                        textDecoration: "none",
                        ...(!(pathname === obj.href || obj.isActive) && {opacity: 0.5003})
                    }}
                >
                    {obj.label}
                </Typography>
            ))}
        </Breadcrumbs>
    );
};

export default BreadsCrums;
