import { Stack, Box, Typography, Grid } from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import { ErrorMessage, Form, Formik } from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { NetworkManager, API } from "network/core";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";

const formSchema = {
    categoryName: "",
    categoryDescription: "",
    categoryType : []
};

const EditCategoryForm = (props) => {
    const { toggleDrawer } = useTempDrawer();
    const [initialValues, setInitialValues] = React.useState(formSchema);
    const dispatch = useDispatch();
    const { handleIsEdited, selectedRow, handleSelectedRow, handleChange, handleRefreshDataAfterAction } = props;

    useEffect(() => {
        if (selectedRow) {
            setInitialValues({
                categoryName: selectedRow.label,
                categoryDescription: selectedRow.description,
                categoryType : selectedRow?.menu_type ? selectedRow?.menu_type : []
            });
        } else {
            setInitialValues({
                categoryName: "",
                categoryDescription: "",
                categoryType : []
            });
        }
    }, [selectedRow]);

    const handleAddCategory = async (values) => {
        const instance = NetworkManager(API.CATEGORY.UPDATE(selectedRow.id));
        const payload = {
            categoryName: values.categoryName,
            description: values.categoryDescription,
            type : values.categoryType
        };

        const response = await instance.request(payload);

        if (response.success) {
            handleRefreshDataAfterAction(payload);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={yup.object({
                    categoryName: yup.string().required("Please enter category name"),
                    categoryDescription: yup.string().max(500, "Max character limit is 500"),
                    categoryType: yup.array().min(1, "Category type is required").required("Category type is required"),
                })}
                onSubmit={(val) => handleAddCategory(val)}
            >
                {(formik) => (
                    <Form sx={{width:'100%'}}>
                        <TemporaryDrawer sideBarStateName="EditNewCategory" handleSelectedRow={handleSelectedRow} resetForm={formik.resetForm} touched={formik.touched}>
                            <Stack sx={{ height: "100%" }} direction="column" justifyContent="space-between" padding={1}>
                                <Box>
                                    <Box sx={{ marginTop: "-8px" }}>
                                        <Typography sx={{ fontSize: "1.625rem", fontWeight: 700 }}>
                                            {selectedRow ? "Edit category" : "Create new category"}
                                        </Typography>
                                    </Box>

                                    <Grid container spacing={2}  sx={{ marginTop: "20px"}}>
                                        <Grid item xs={12} >
                                            <CustomTextField
                                                placeholder="Name"
                                                label="Name"
                                                sx={{width:'100%'}}
                                                inputProps={{ maxLength: 50 }}
                                                {...formik.getFieldProps("categoryName")}
                                            />
                                            <ErrorMessage name="categoryName" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item xs={12} >
                                        <CustomSelectWithCheckbox
                                            selectAll
                                            label="Category type"
                                            menuItems={[
                                                {id: "item", label: "Item", value: "item"},
                                                {id: "modifier", label: "Modifier", value: "modifier"}
                                            ]}
                                            values={formik.values.categoryType}
                                            onChange={(val) => formik.setFieldValue("categoryType", val)}
                                        />
                                        <ErrorMessage name="categoryType" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <CustomTextField
                                                placeholder="Description (optional)"
                                                multiline
                                                rows={3}
                                                sx={{width:'100%',mb:'40px'}}
                                                label="Description (optional)"
                                                inputProps={{ maxLength: 500 }}
                                                {...formik.getFieldProps("categoryDescription")}
                                            />
                                           
                                            <ErrorMessage
                                                name="categoryDescription"
                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                            />
                                        </Grid>
                                    </Grid>


                                </Box>

                                <ButtonGrouping
                                    btnprops={[
                                        {
                                            btnTitle: "Cancel",
                                            sx: (t) => ({ color: t.palette.error.dark }),
                                            onClick: (e) => {
                                                formik.resetForm();
                                                toggleDrawer("right", false)(e);
                                                handleSelectedRow(null);
                                            }
                                        },
                                        {
                                            btnTitle: "Save",
                                            variant: "contained",
                                            sx: (t) => ({ color: t.palette.background.paper }),
                                            onClick: (e) => {
                                                formik.handleSubmit();

                                                formik.validateForm().then((res) => {
                                                    // to close the sidebar if no field has validation error
                                                    if (Object.keys(res).length === 0) {
                                                        toggleDrawer("right", false)(e);
                                                        setTimeout(() => {
                                                            formik.resetForm();
                                                        }, 1500);
                                                    }
                                                });
                                            }
                                        }
                                    ]}
                                />
                            </Stack>
                        </TemporaryDrawer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default EditCategoryForm;
