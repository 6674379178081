import {Box, Button, CircularProgress, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import PlusIcon from "assets/images/icons/plus.png";
import ActionButton from "commonComponent/Apptable/ActionButton";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import {ErrorMessage, FieldArray, Form, Formik} from "formik";
import {API, NetworkManager} from "network/core";
import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import Loader from "ui-component/Loader";
import {FormatCurrency, commaFormattedNumber, enforceFormat, enforceFormatForNumber} from "utils/helperFunctions/helpers";
import * as Yup from "yup";
import useLocationController from "../locations/locations.controller";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";

const validationSchema = Yup.object().shape({
    serviceFee: Yup.number().required("Service fee is required").max(100, "Service fee must be 100 or less"),
    minimumServiceFee: Yup.string().required("Minimum service fee is required"),
    largeOrderMinimum: Yup.string().required("Large order minimum is required"),
    largeOrderFees: Yup.array().of(
        Yup.object().shape({
            hours: Yup.number().required("Hours are required"),
            fee: Yup.number().required("Fee is required").max(100, "Fee must be 100 or less")
        })
    ),
    smallOrderFees: Yup.array().of(
        Yup.object().shape({
            hours: Yup.number().required("Hours are required"),
            fee: Yup.number().required("Fee is required").max(100, "Fee must be 100 or less")
        })
    )
});

const Services = () => {
    const [searchParam] = useSearchParams();
    const [initialValues, setInitialValues] = useState({
        serviceFee: "",
        minimumServiceFee: "",
        largeOrderMinimum: "",
        largeOrderFees: [{hours: "", fee: ""}],
        smallOrderFees: [{hours: "", fee: ""}]
    });
    const [originalData, setOriginalData] = useState(null);
    const marketName = searchParam.get("marketName");
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [serviceFess, setserviceFess] = useState(0);

    const dispatch = useDispatch();

    const LC = useLocationController();

    function sortFeesByHoursDescending(fees) {
        return fees.sort((a, b) => b.hours - a.hours);
    }

    function sortOrderFees(data) {
        return {
            ...data,
            largeOrderFees: sortFeesByHoursDescending(data.largeOrderFees),
            smallOrderFees: sortFeesByHoursDescending(data.smallOrderFees)
        };
    }
    // const navigation = useNavigate();

    // const initialValues = {
    //     serviceFee: 15,
    //     minimumServiceFee: "$40.00",
    //     largeOrderMinimum: "{values.largeOrderMinimum}",
    //     largeOrderFees: [
    //         {hours: 96, fee: 50},
    //         {hours: 48, fee: 100}
    //     ],
    //     smallOrderFees: [
    //         {hours: 48, fee: 50},
    //         {hours: 24, fee: 100}
    //     ]
    // };

    const transformValuesToPayload = (values) => {
        return {
            cateringRate: {
                service_fee: values.serviceFee,
                minimum_service_fee: values.minimumServiceFee
            },
            cancellationPolicy: {
                large_order_minimum: values.largeOrderMinimum,
                greater_than_order_minimum: values.largeOrderFees.map((fee) => ({
                    hours_before_delivery: fee.hours,
                    cancellation_fee: fee.fee
                })),
                less_than_order_minimum: values.smallOrderFees.map((fee) => ({
                    hours_before_delivery: fee.hours,
                    cancellation_fee: fee.fee
                }))
            }
        };
    };

    const getCateringService = async () => {
        setLoading(true);
        const instance = NetworkManager(API.SERVICE.GET);
        const response = await instance.request();
        const data = response.data;
        if (data) {
            setLoading(false);
        }

        // console.log("DATA : ", data);

        // Transform the data to match Formik's expected initialValues structure
        const transformedData = {
            serviceFee: data.serviceFee.cateringRate.service_fee,
            minimumServiceFee: data.serviceFee.cateringRate.minimum_service_fee,
            largeOrderMinimum: data.serviceFee.cancellationPolicy.large_order_minimum,
            largeOrderFees: data.serviceFee.cancellationPolicy.greater_than_order_minimum.map((fee) => ({
                hours: fee.hours_before_delivery,
                fee: fee.cancellation_fee,
                isOld: true
            })),
            smallOrderFees: data.serviceFee.cancellationPolicy.less_than_order_minimum.map((fee) => ({
                hours: fee.hours_before_delivery,
                fee: fee.cancellation_fee,
                isOld: true
            })),
            isOld: true
        };
        setserviceFess(transformedData.serviceFee);

        const sortedData = sortOrderFees(transformedData);

        setInitialValues(sortedData);
        setOriginalData(sortedData); // Store the original data
    };

    useEffect(() => {
        getCateringService();
    }, []);

    const handleActionBtn = (index, arrayHelpers) => () => {
        arrayHelpers.remove(index);
    };
    const isChangable =
        WithPermission({module: "Services", permission: "update_permission"}) ||
        WithPermission({module: "Services", permission: "create_permission"});
    const isCreatable = WithPermission({module: "Services", permission: "create_permission"});
    const isEditable = WithPermission({module: "Services", permission: "update_permission"});
    const isDeletable = WithPermission({module: "Services", permission: "delete_permission"});
    const getFieldDisabled = (item) => {
        if (item?.isOld && isCreatable && !isEditable) {
            return true;
        } else if (!isChangable) {
            return true;
        } else {
            return false;
        }
    };
    const updateIntialValue = (newValues) => {
        const newInitvalues = {
            serviceFee: newValues.cateringRate.service_fee,
            minimumServiceFee: newValues.cateringRate.minimum_service_fee,
            largeOrderMinimum: newValues.cancellationPolicy.large_order_minimum,
            largeOrderFees: newValues.cancellationPolicy.greater_than_order_minimum.map((fee) => ({
                hours: fee.hours_before_delivery,
                fee: fee.cancellation_fee,
                isOld: true
            })),
            smallOrderFees: newValues.cancellationPolicy.less_than_order_minimum.map((fee) => ({
                hours: fee.hours_before_delivery,
                fee: fee.cancellation_fee,
                isOld: true
            })),
            isOld: true
        };
        console.log(newInitvalues,216532)
        setInitialValues(newInitvalues);
    };
console.log(initialValues,16521)
    return (
        <Grid container>
            <Grid item xs={12}>
                <TableTopFilterWrapper
                    lessmarginOnTop
                    pageheader={
                        <PageHeader
                            title={marketName || "Services"}
                            {...(LC.id && {
                                breadsCrumData: [
                                    {label: "Markets", href: "/markets"},
                                    {label: "Services", isActive: true}
                                ]
                            })}
                        />
                    }
                    // btnTitle={WithPermission({module: "Services", permission: "create_permission"}) && "Create location"}
                    // handleClick={LC.handleCreateLocation}
                    handleSearch={LC.handleSearch}
                    filterType="locationFilter"
                    onlyHeading
                >
                    {loading && <Loader />}
                    {/* {LC.openFilter && (
                                        <Filter
                                            filterState={LC.filterState}
                                            setFilterState={LC.setFilterChips}
                                            filterChips={LC.filterChips}
                                            setSearchedValue={LC.setSearchedValue}
                                            showFilter={LC.openFilter}
                                        />
                                    )} */}

                    {(!!originalData || !loading) && (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            enableReinitialize
                            onSubmit={async (values) => {
                                setLoading(true);
                                const payload = transformValuesToPayload(values);
                                const instance = NetworkManager(API.SERVICE.UPSERT);
                                const response = await instance.request(payload);
                                if (response) {
                                    updateIntialValue(payload);
                                    dispatch(
                                        openSnackbar({
                                            open: true,
                                            message: response.message,
                                            variant: "alert",
                                            alert: {
                                                color: "success"
                                            },
                                            close: false
                                        })
                                    );
                                    setLoading(false);
                                }
                            }}
                        >
                            {({values, handleChange, handleSubmit, handleBlur, errors, touched, resetForm}) => (
                                <Form
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        minHeight: "85vh"
                                    }}
                                >
                                    {console.log(values, "FORMIKVaLUE")}
                                    <Grid item xs={12} lg={9}>
                                        <Box
                                            sx={{
                                                p: 2,
                                                mb: 3,
                                                borderRadius: "4px",
                                                border: "1px solid #DBDBDB"
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: "#000",
                                                    fontFamily: "Inter",
                                                    fontSize: "18px",
                                                    fontStyle: "normal",
                                                    fontWeight: 600,
                                                    lineHeight: "normal",
                                                    letterSpacing: "-0.7px",
                                                    marginBottom: "27px"
                                                }}
                                                variant="h6"
                                            >
                                                Catering rates and fees
                                            </Typography>
                                            <Grid container item xs={12} md={6} columnGap={1} rowSpacing={1.5}>
                                                <Grid item xs>
                                                    <CustomTextField
                                                        label="Service fee %"
                                                        fullWidth
                                                        name="serviceFee"
                                                        value={values.serviceFee} // Use Formik's values here directly
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder="%"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="start" sx={{color: "#212121"}}>
                                                                    %
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        inputProps={{maxLength: 3}}
                                                        onKeyDown={enforceFormat}
                                                        disabled={values?.isOld && !isEditable}
                                                    />
                                                    <ErrorMessage
                                                        name="serviceFee"
                                                        render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <CustomTextField
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start" sx={{color: "#212121"}}>
                                                                    $
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        onKeyDown={enforceFormat}
                                                        label="Minimum service fee"
                                                        name="minimumServiceFee"
                                                        value={values.minimumServiceFee}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        inputProps={{maxLength: 10}}
                                                        error={touched.minimumServiceFee && Boolean(errors.minimumServiceFee)}
                                                        disabled={values?.isOld && !isEditable}
                                                    />
                                                    <ErrorMessage
                                                        name="minimumServiceFee"
                                                        render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} />
                                            </Grid>
                                        </Box>

                                        <Box
                                            sx={{
                                                p: 2,
                                                mb: 3,
                                                borderRadius: "4px",
                                                border: "1px solid #DBDBDB"
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: "#000",
                                                    fontFamily: "Inter",
                                                    fontSize: "18px",
                                                    fontStyle: "normal",
                                                    fontWeight: 600,
                                                    lineHeight: "normal",
                                                    letterSpacing: "-0.7px",
                                                    marginBottom: "27px"
                                                }}
                                                variant="h6"
                                            >
                                                Cancellation policy
                                            </Typography>
                                            <Grid container columnGap={1} rowSpacing={1.5}>
                                                <Grid item xs={10.75} sm={5.375} lg={2.62} xl={2.657} sx={{mb: 2}}>
                                                    <CustomTextField
                                                        label="Large order minimum"
                                                        fullWidth
                                                        name="largeOrderMinimum"
                                                        value={values.largeOrderMinimum}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        inputProps={{maxLength: 10}}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start" sx={{color: "#212121"}}>
                                                                    $
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        onKeyDown={enforceFormat}
                                                        disabled={values?.isOld && !isEditable}
                                                    />

                                                    <ErrorMessage
                                                        name="largeOrderMinimum"
                                                        render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={12} md={6}>
                                                    <Typography
                                                        sx={{
                                                            color: "#000",
                                                            fontFamily: "Inter",
                                                            fontSize: "14px",
                                                            fontStyle: "normal",
                                                            fontWeight: 600,
                                                            lineHeight: "normal",
                                                            letterSpacing: "-0.7px",
                                                            marginBottom: "1rem"
                                                        }}
                                                    >
                                                        Orders &ge; {FormatCurrency(values.largeOrderMinimum)}
                                                    </Typography>
                                                    <FieldArray
                                                        name="largeOrderFees"
                                                        render={(arrayHelpers) => (
                                                            <>
                                                                {values.largeOrderFees.map((fee, index) => (
                                                                    <Grid
                                                                        container
                                                                        columnGap={1}
                                                                        rowSpacing={1.5}
                                                                        key={index}
                                                                        sx={{mt: 1}}
                                                                        alignItems="flex-start"
                                                                    >
                                                                        {console.log(fee, 377)}
                                                                        <Grid item xs>
                                                                            <CustomTextField
                                                                                label="Hours before delivery"
                                                                                fullWidth
                                                                                name={`largeOrderFees.${index}.hours`}
                                                                                value={fee.hours}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                onKeyDown={enforceFormat}
                                                                                inputProps={{maxLength: 3}}
                                                                                disabled={getFieldDisabled(fee)}
                                                                            />
                                                                            <ErrorMessage
                                                                                name={`largeOrderFees.${index}.hours`}
                                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            <CustomTextField
                                                                                fullWidth
                                                                                name={`largeOrderFees.${index}.fee`}
                                                                                placeholder="%"
                                                                                label="Cancellation fee"
                                                                                InputProps={{
                                                                                    endAdornment: (
                                                                                        <InputAdornment
                                                                                            position="start"
                                                                                            sx={{color: "#212121"}}
                                                                                        >
                                                                                            %
                                                                                        </InputAdornment>
                                                                                    )
                                                                                }}
                                                                                inputProps={{maxLength: 3}}
                                                                                onKeyDown={enforceFormat}
                                                                                value={fee.fee}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                disabled={getFieldDisabled(fee)}
                                                                            />
                                                                            <ErrorMessage
                                                                                name={`largeOrderFees.${index}.fee`}
                                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={1} style={{paddingTop: "1.6rem"}}>
                                                                            {isDeletable && (
                                                                                <ActionButton
                                                                                    row={fee}
                                                                                    ActionArray={["Delete"]}
                                                                                    handleActionBtn={() =>
                                                                                        handleActionBtn(index, arrayHelpers)
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}
                                                                {isCreatable && (
                                                                    <Button
                                                                        sx={{marginTop: 2}}
                                                                        startIcon={
                                                                            <img
                                                                                src={PlusIcon}
                                                                                alt="plus-icon"
                                                                                width="15px"
                                                                                height="15px"
                                                                            />
                                                                        }
                                                                        onClick={() => arrayHelpers.push({hours: "", fee: ""})}
                                                                    >
                                                                        Add new fee
                                                                    </Button>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Typography
                                                        sx={{
                                                            color: "#000",
                                                            fontFamily: "Inter",
                                                            fontSize: "14px",
                                                            fontStyle: "normal",
                                                            fontWeight: 600,
                                                            lineHeight: "normal",
                                                            letterSpacing: "-0.7px",
                                                            marginBottom: "1rem"
                                                        }}
                                                    >
                                                        Orders &lt; {FormatCurrency(values.largeOrderMinimum)}
                                                    </Typography>
                                                    <FieldArray
                                                        name="smallOrderFees"
                                                        render={(arrayHelpers, remove) => (
                                                            <>
                                                                {values.smallOrderFees.map((fee, index) => (
                                                                    <Grid
                                                                        container
                                                                        columnGap={1}
                                                                        rowSpacing={1.5}
                                                                        key={index}
                                                                        sx={{mt: 1}}
                                                                        alignItems="flex-start"
                                                                    >
                                                                        <Grid item xs>
                                                                            <CustomTextField
                                                                                label="Hours before delivery"
                                                                                fullWidth
                                                                                name={`smallOrderFees.${index}.hours`}
                                                                                value={fee.hours}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                error={
                                                                                    touched.smallOrderFees?.[index]?.hours &&
                                                                                    Boolean(errors.smallOrderFees?.[index]?.hours)
                                                                                }
                                                                                onKeyDown={enforceFormat}
                                                                                inputProps={{maxLength: 3}}
                                                                                disabled={getFieldDisabled(fee)}
                                                                            />
                                                                            <ErrorMessage
                                                                                name={`smallOrderFees.${index}.hours`}
                                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            <CustomTextField
                                                                                fullWidth
                                                                                name={`smallOrderFees.${index}.fee`}
                                                                                placeholder="%"
                                                                                label="Cancellation fee"
                                                                                InputProps={{
                                                                                    endAdornment: (
                                                                                        <InputAdornment
                                                                                            position="start"
                                                                                            sx={{color: "#212121"}}
                                                                                        >
                                                                                            %
                                                                                        </InputAdornment>
                                                                                    )
                                                                                }}
                                                                                error={
                                                                                    touched.smallOrderFees?.[index]?.fee &&
                                                                                    Boolean(errors.smallOrderFees?.[index]?.fee)
                                                                                }
                                                                                inputProps={{maxLength: 3}}
                                                                                onKeyDown={enforceFormat}
                                                                                value={fee.fee}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                disabled={getFieldDisabled(fee)}
                                                                            />
                                                                            <ErrorMessage
                                                                                name={`smallOrderFees.${index}.fee`}
                                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={1} style={{paddingTop: "1.6rem"}}>
                                                                            {isDeletable && (
                                                                                <ActionButton
                                                                                    row={fee}
                                                                                    ActionArray={["Delete"]}
                                                                                    handleActionBtn={() =>
                                                                                        handleActionBtn(index, arrayHelpers)
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}
                                                                {isCreatable && (
                                                                    <Button
                                                                        sx={{marginTop: 2}}
                                                                        startIcon={
                                                                            <img
                                                                                src={PlusIcon}
                                                                                alt="plus-icon"
                                                                                width="15px"
                                                                                height="15px"
                                                                            />
                                                                        }
                                                                        onClick={() => arrayHelpers.push({hours: "", fee: ""})}
                                                                    >
                                                                        Add new fee
                                                                    </Button>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    {(isChangable || isDeletable || isCreatable) && (
                                        <Grid item xs={12}>
                                            <ButtonGrouping
                                                btnprops={[
                                                    {
                                                        btnTitle: "Cancel",
                                                        sx: (t) => ({color: t.palette.error.dark}),
                                                        onClick: () => {
                                                            // Reset the form to the original data
                                                            resetForm({values: originalData});
                                                        }
                                                    },
                                                    {
                                                        btnTitle: loadingSave ? <CircularProgress color="#ffffff" /> : "Save",
                                                        variant: "contained",
                                                        // disabled: formik.isSubmitting,
                                                        sx: (t) => ({color: t.palette.background.paper}),
                                                        onClick: handleSubmit
                                                    }
                                                ]}
                                            />
                                        </Grid>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    )}
                </TableTopFilterWrapper>
            </Grid>
        </Grid>
    );
};

export default Services;
