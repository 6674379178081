import React from "react";
import {Grid} from "@mui/material";
import MenuList from "../../menuList";

const Menu = (props) => {
    console.log("menu");
    return (
        <>
            <MenuList {...props} />
        </>
    );
};

export default Menu;
