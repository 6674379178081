import React, { useState, useEffect, useCallback } from "react";
import { Grid, Box, Typography, Divider, Button, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AnimateButton from "ui-component/extended/AnimateButton";
import { useNavigate } from "react-router-dom";
import CreateDriverForm from "../../Drivers/DriverData/createDriverForm";
import useTempDrawer from "hooks/useTempDrawer";
import { API, NetworkManager } from "network/core";
import { getHHMMWithTimeString, getformateDate, getFormattedTimeinAMPM } from "utils/app-dates/dates";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import { ellipsizeText } from "commonComponent/Apptable/textUtils";

function AvailableDrivers({ formik, marketList, isUpdated }) {
    const [expanded, setExpanded] = useState(null);
    const [isEdited, setIsEdited] = useState(false);
    const [unassignedDriverData, setUnassignedDriverData] = useState([]);
    const [assignedDriverData, setAssignDriverData] = useState([]);

    const navigate = useNavigate();
    const { toggleDrawer } = useTempDrawer();
    const { values } = formik;
    const market = values.market;
    const filterDate = values.date;

    const handleIsEdited = () => setIsEdited(!isEdited);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    const handleManageDriversClick = () => {
        navigate("/delivery?tab=drivers");
        window.location.reload();
    };

    const getAssignDriverData = async () => {
        const instance = NetworkManager(API.DELIVERIES.AVAILABLEDRIVERLIST);
        const params = {
            market_id: market,
            date: getformateDate(filterDate, "yyyy-mm-dd")
        };
        const res = await instance.request({}, params);
        if (res.success) {
            const fetchedData = res.data?.driver;
            const mapData = fetchedData.map((el) => ({
                name: `${el?.carrier_name}`,
                deliveries: el?.orders?.map((el) => ({ time: el?.delivery_time, date: el?.delivery_date, address: el?.delivery_address, brandName: el?.brand?.brand_name }))
            }));
            setAssignDriverData(mapData);
        }
    };

    const getUnassignedDriver = async () => {
        const instance = NetworkManager(API.DELIVERIES.UNAVAILABLEDRIVERLIST);
        const params = {
            market_id: market,
            date: getformateDate(filterDate, "yyyy-mm-dd")
        };
        const res = await instance.request({}, params);
        if (res.success) {
            const data = res.data?.driver?.map((el) => `${el?.name}`);
            setUnassignedDriverData(data);
        }
    };

    useEffect(() => {
        if (market && filterDate) {
            getUnassignedDriver();
            getAssignDriverData();
        }
    }, [market, filterDate, isEdited, isUpdated]);

    return (
        <>
            <Grid
                item
                sx={{
                    maxWidth: { xs: "100%", lg: "344px" },
                    minWidth: { xs: "100%", lg: "344px" },

                    marginTop: "23px",
                    "@media (min-width: 1536px)": {
                        minWidth: "343px !important",
                        maxWidth: "343px !important"
                    }
                }}
            >
                <Typography color="#000A12" sx={{ fontSize: "1.25rem", fontWeight: 700 }}>
                    Available drivers
                </Typography>
                <Box sx={{ marginTop: "27px" }}>
                    <Typography sx={{ fontSize: "0.75rem", fontWeight: 700, color: "#1B1C1C" }}>
                        Assigned ({assignedDriverData?.length})
                    </Typography>
                    <Box sx={{ padding: "11px" }}>
                        {assignedDriverData.map((driver, index) => (
                            <div key={index}>
                                <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>{driver.name}</Typography>
                                <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                                    <div className="accordion-icon">
                                        <AccordionSummary
                                            expandIcon={
                                                <ExpandMoreIcon
                                                    sx={{
                                                        fontSize: "13px",
                                                        width: "20px",
                                                        height: "20px",
                                                        marginTop: "3px"
                                                    }}
                                                />
                                            }
                                            aria-controls={`panel-${index}-content`}
                                            id={`panel-${index}-header`}
                                            sx={{
                                                padding: "0px !important",
                                                justifyContent: "left !important",
                                                margin: "0px !important",
                                                height: "18px",
                                                minHeight: "35px !important",
                                                "&.Mui-expanded": {
                                                    minHeight: "35px !important",
                                                    flexFlow: "0px !important"
                                                }
                                            }}
                                        >
                                            <Typography sx={{ fontSize: "0.938rem", fontWeight: 600, color: "#00ab3a" }}>
                                                {driver.deliveries.length} Delivery
                                            </Typography>
                                        </AccordionSummary>
                                    </div>
                                    <AccordionDetails sx={{ padding: "0px !important", marginTop: "-9px" }}>
                                        {driver.deliveries.map((delivery, deliveryIndex) => (
                                            <Box key={deliveryIndex}>
                                                <Typography
                                                    sx={{
                                                        fontSize: "0.938rem",
                                                        fontWeight: 500,
                                                        color: "#000A12",
                                                        opacity: 0.4501,
                                                        marginTop: "9px"
                                                    }}
                                                >
                                                    {/* {getHHMMWithTimeString(delivery.time)} */}
                                                    {getFormattedTimeinAMPM(delivery?.date)},{" "}{ellipsizeText(delivery?.brandName)}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: "0.938rem",
                                                        fontWeight: 500,
                                                        color: "#000A12",
                                                        opacity: 0.4501
                                                    }}
                                                >
                                                    {delivery.address}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                                <Box sx={{ margin: "12px 0px" }}>
                                    <Divider sx={{ borderColor: "#E0E2E6" }} />
                                </Box>
                            </div>
                        ))}
                        {assignedDriverData?.length === 0 && (
                            <Box>
                                <Typography sx={{ fontSize: "0.75rem", fontWeight: 600, color: "#000" }}>No data available</Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box sx={{ marginTop: "15px" }}>
                    <Typography sx={{ fontSize: "0.75rem", fontWeight: 700, color: "#1B1C1C" }}>
                        Unassigned ({unassignedDriverData?.length})
                    </Typography>
                    <Box sx={{ padding: "11px" }}>
                        {unassignedDriverData.map((name, index) => (
                            <Typography key={index} sx={{ fontSize: "1rem", fontWeight: 500 }}>
                                {name}
                            </Typography>
                        ))}
                        {unassignedDriverData?.length === 0 && (
                            <Box>
                                <Typography sx={{ fontSize: "0.75rem", fontWeight: 600, color: "#000" }}>No data available</Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box sx={{ marginTop: "42px" }}>
                    <Divider sx={{ borderColor: "#26272A" }} />
                </Box>
                <Box sx={{ display: "flex", gap: "18px", marginTop: "23px" }}>
                    {WithPermission({ module: "Drivers", permission: ["read_permission"] }) && (
                        <Box sx={{ width: "50%" }}>
                            <AnimateButton>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    size="large"
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontStyle: "normal",
                                        fontWeight: 500,

                                        minWidth: "152px",
                                        width: "100%"
                                    }}
                                    onClick={handleManageDriversClick}
                                >
                                    Manage drivers
                                </Button>
                            </AnimateButton>
                        </Box>
                    )}
                    {WithPermission({ module: "Drivers", permission: "create_permission" }) && (
                        <Box sx={{ width: "50%" }}>
                            <AnimateButton>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    onClick={toggleDrawer("right", true)}
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        bgcolor: "#00ab3a",

                                        minWidth: "173px",
                                        width: "100%",
                                        color: "#FFF",
                                        ":hover": {
                                            bgcolor: "#00ab3a"
                                        }
                                    }}
                                >
                                    Add new driver
                                </Button>
                            </AnimateButton>
                        </Box>
                    )}
                </Box>
            </Grid>

            <CreateDriverForm marketList={marketList} conditionalTitle="Add new driver" handleIsEdited={handleIsEdited} />
        </>
    );
}

export default AvailableDrivers;
