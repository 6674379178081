import {useState, useEffect, useLayoutEffect} from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import Profile from "./profile";
import Menu from "./menu";
import useTempDrawer from "hooks/useTempDrawer";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {API, NetworkManager} from "network/core";
import {sortFunction} from "utils/helperFunctions/helpers";
import {resetNestState, setNestShowFilter} from "store/slices/paginationSlice";
import {useDispatch, useSelector} from "react-redux";

export const useUpdateBrandController = () => {
    const [searchParams] = useSearchParams();
    const headerName = searchParams.get("brandName");
    const editable = searchParams.get("isEdit");
    const tab = searchParams.get("tab");
    const dispatch = useDispatch();
    const prm = useParams();
    const {pathname} = useLocation();
    const brand = pathname.split("/")[1];
    const {id} = prm;
    const [breadScrum, setBreadScrum] = useState("View brand");
    const [search, setSearch] = useState("");
    // const [showFilter, setShowFilter] = useState(false);
    const showFilter = useSelector((state) => state?.pagination?.nestShowFilter);
    const [value, setValue] = useState(tab || "profile");
    const [btnlabel, setBtnLebel] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const filterChips = useSelector((state) => state?.pagination?.nestFilterChips);
    const [brandList, setBrandList] = useState([]);
    const {toggleDrawer} = useTempDrawer();

    const navigate = useNavigate();

    const menuCreatePermission = WithPermission({module: "Menu", permission: "create_permission"});

    const handleButtonLabelandFilter = (type) => {
        switch (type) {
            case "menu":
                setBtnLebel(menuCreatePermission ? "Create menu" : null);

                break;

            default:
                setBtnLebel("Edit brand");
                break;
        }
    };
    const getBrandList = async () => {
        let tempData = [];
        const instance = NetworkManager(API.BRAND.GET);
        const params = {
            page: 1,
            pageSize: 5000,
            sortBy: "createdAt",
            orderBy: "desc"
        };

        const response = await instance.request({}, params);

        if (response.success) {
            const BrandData = response.data.brandList.map((el) => ({id: `${el.brand_id}`, label: el.brand_name}));
            tempData = [...BrandData];
        }
        setBrandList(sortFunction(tempData));
    };

    useLayoutEffect(() => {
        getBrandList();
    }, []);
    useEffect(() => {
        handleButtonLabelandFilter(tab);
        toggleDrawer("right", false)();
        setIsEdit(editable);
    }, [window.location.search]);

    useEffect(() => {
        navigate({
            pathname: window.location.pathname,
            search: headerName
                ? `?tab=${tab || "profile"}&brandName=${headerName}&isEdit=${editable}`
                : `?tab=${tab || "profile"}&isEdit=${editable}`
        });
    }, []);

    const handleSearch = (val) => {
        setSearch(val);
    };

    const handleHeaderActions = (btntype) => (e) => {
        switch (btntype) {
            case "Edit brand": {
                dispatch(setNestShowFilter(false));
                navigate(`/brand-detail/${id}?tab=profile&brandName=${headerName}&isEdit=true`);
                setIsEdit(true);
                break;
            }
            case "none": {
                dispatch(setNestShowFilter(!showFilter));
                break;
            }
            case "Create menu": {
                dispatch(setNestShowFilter(false));
                if (id) {
                    navigate(`/menus/menuDetail/?tab=menu&brandId=${id}&edit=yes&brandName=${headerName}`);
                } else {
                    navigate(`/menus/menuDetail/?tab=menu&add=yes&brandName=${headerName}`);
                }
                break;
            }
            default: {
                break;
            }
        }
    };

    const handleChange = (event, newValue) => {
        dispatch(resetNestState());
        setValue(newValue);
        switch (newValue) {
            case "menu": {
                setBreadScrum("View menus list");
                break;
            }
            default:
                setBreadScrum("View brand");
        }
        navigate({
            pathname: window.location.pathname,
            search: headerName ? `?tab=${newValue}&brandName=${headerName}&isEdit=${editable}` : `?tab=${newValue}&isEdit=${editable}`
        });
    };

    const dummyData = [
        {
            value: "profile",
            label: "Profile",
            comp: () => <Profile isEdit={isEdit !== "null"} />
        },
        {
            value: "menu",
            label: "Menus",
            comp: () => <Menu search={search} showFilter={showFilter} filterChips={filterChips} isChild />
        }
    ];

    return {
        headerName,
        id,
        handleChange,
        value,
        dummyData,
        breadScrum,
        btnlabel,
        handleHeaderActions,
        handleSearch,
        filterChips,
        // setFilterChips,
        showFilter,
        brandList,
        setBrandList,
        brand
    };
};
