import {Grid} from "@mui/material";
import React, {useState, useEffect} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import useTempDrawer from "hooks/useTempDrawerModifier";
import {generateUrl} from "utils/helperFunctions/helpers";
import Filters from "commonComponent/Filters";
import FilterChips from "commonComponent/FilterChipsComponent";
import NewAddModifierGroup from "commonComponent/ModifierComponent/AddModifierGroup/NewAddModifierGroup";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import { setPageNumber, setRowsPerPage, setShowFilter, setOrder, setOrderBy} from "store/slices/paginationSlice";

const ModifierGroupMasterList = (props) => {
    const {search, showFilter, setHeading = () => {}, filterChips} = props;
    const [open, setOpen] = React.useState(false);
    const [modifierGroupListing, setModifierGroupListing] = React.useState([]);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.order || "asc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "modifier_group_name");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    // const [filterChips, setFilterChips] = useState({});
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const ModifierGroupID = searchParams.get("id");
    const {toggleDrawer} = useTempDrawer();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handleStatus = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.MODIFIERGROUP.STATUS(values.modifier_group_id));

        let isActive = false;
        if (type === "Deactivate") {
            isActive = false;
        } else {
            isActive = true;
        }
        const payload = {
            status: isActive
        };

        const response = await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );

            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        setShowFilter(false);
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                toggleDrawer("right", "addModifierGroup")(e);
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            case "Deactivate": {
                handleStatus(row, type);
                break;
            }
            case "Activate": {
                handleStatus(row, type);
                break;
            }
            default:
                break;
        }
    };

    const getModifiersList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.MODIFIERGROUP.GET);
        const params = {
            page: currentPage,
            pageSize: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };
        if (currentOrderBy) {
            params.sortBy = currentOrderBy;
            params.orderBy = currentOrder;
        }
        // // while searching we dont need page number
        if (search === "") {
            params.page = currentPage;
        }

        if (search !== "") {
            params.modifier_group_name = search;
            setModifierGroupListing([]);
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);

        if (response.success) {
            setModifierGroupListing(response.data.modifierList);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.MODIFIERGROUP.DELETE(selectedRow.modifier_group_id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        handleIsEdited();
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        dispatch(setOrder(val1));
        dispatch(setOrderBy(val2));
    };

    useEffect(() => {
        if (search) {
            dispatch(setPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        getModifiersList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, ModifierGroupID, filterChips]);

    const filterState = [
        {
            key: "modifier_group_name",
            label: "Modifier group",
            type: "textfield"
        },
        {
            key: "modifier_name",
            label: "Modifiers",
            type: "textfield"
        },
        {
            key: "start_date",
            label: "Created",
            type: "createdAt"
        },
        {
            key: "is_active",
            label: "Status",
            type: "select",
            getData: () => [
                {id: "active", label: "Active"},
                {id: "inactive", label: "Inactive"}
            ]
        }
    ];

    // const handleApplyFilter = (Morefilters) => {
    //     setCurrentPage(1);
    //     // setFilterChips(Morefilters);
    // };

    return (
        <>
            <Grid container>
                <>
                    {showFilter ? (
                        {
                            /* <Filters
                            filterState={filterState}
                            filterChips={filterChips}
                            setFilterChips={setFilterChips}
                            handleApplyFilter={handleApplyFilter}
                        /> */
                        }
                    ) : (
                        <NewAddModifierGroup
                            selectedRow={selectedRow}
                            handleSelectedRow={handleSelectedRow}
                            handleIsEdited={handleIsEdited}
                        />
                    )}
                    <Grid item xs={12}>
                        {/* <FilterChips
                            filterChips={filterChips}
                            // setFilterChips={setFilterChips}
                        /> */}
                        <CommonTable
                            path={location}
                            loading={loading}
                            data={modifierGroupListing}
                            currentTab="brands"
                            header={[
                                {key: "modifier_group_name", label: "Modifier group"},
                                {key: "modifier_name", label: "Modifiers"},
                                {key: "display_name", label: "Category"},
                                {key: "menuModifierGroup.menu.menu_name", label: "Menus"},
                                {key: "createdAt", label: "Created"},
                                {key: "is_active", label: "Status"},
                                WithPermission({
                                    module: "Modifiers",
                                    permission: ["delete_permission", "update_permission"]
                                }) && {
                                    key: "action",
                                    label: "Action"
                                }
                            ].filter((el) => el)}
                            actions={[
                                WithPermission({module: "Modifiers", permission: "update_permission"}) && "Edit",

                                WithPermission({module: "Modifiers", permission: "delete_permission"}) && "Delete"
                            ]}
                            height="75vh"
                            handleActionBtn={handleActionBtn}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsChange}
                            handleSort={handleSort}
                            totalItems={totalItems}
                            rowsPerPage={rowsPerPage}
                            currentPage={currentPage - 1}
                            sortingLabel={["Modifier group", "Created", "Status", "Category"]}
                        />
                    </Grid>
                </>
            </Grid>

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this modifier group? "
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
        </>
    );
};

export default ModifierGroupMasterList;
