import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import React, {useEffect} from "react";
import NewItemDetail from "../ItemDetail/NewItemDetail";
import {Box, Typography} from "@mui/material";

const NewAddItem = (props) => {
    const {selectedRow = false} = props;
   
    return (
        <>
        
            <TemporaryDrawer sideBarStateName="itemEditSidebar">
                <Box>
                   
                    <Box sx={{mb: 2}}>
                        <Typography sx={{fontSize:"1.625rem", fontWeight: 700, marginBottom: "1.5rem"}}>
                            {selectedRow ? "Edit menu item" : "Add menu item"}
                        </Typography>
                    </Box>
                    <Box>
                        <NewItemDetail  inSideBar {...props} />
                    </Box>
                </Box>
            </TemporaryDrawer>
           
        </>
    );
};

export default NewAddItem;
