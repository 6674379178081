import {Grid} from "@mui/material";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {NetworkManager, API} from "network/core";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {useNavigate, useSearchParams, useParams} from "react-router-dom";
import FilterChips from "commonComponent/FilterChipsComponent";
import {generateUrl} from "utils/helperFunctions/helpers";
import useAuth from "hooks/useAuth";
import {customers} from "../../../Tabs/data";
import StorageManager from "storage/StorageManager";
import useTempDrawer from "hooks/useTempDrawer";
import PrintOrderModal from "commonComponent/DilogBox/PrintOrderModal";
import AddNewNotes from "views/pages/privatePages/customers/CreateCustomer/AddNewNoteForm";
import SendQuote from "../../../../orders/CreateOrder/createOrderFooter/SendQuote/SendQuote";
import useToggleDilog from "hooks/useToggleDilog";
import DriverModal from "commonComponent/DilogBox/DriverModal";
import RefundForm from "../../../../orders/CreateOrder/createOrderFooter/Refund";
import Filters from "commonComponent/Filters";
import {setChildPageNumber, setChildRowsPerPage} from "store/slices/paginationSlice";
import CancelModal from "commonComponent/DilogBox/CancelModal";
import CancellationSideout from "../../../../orders/CancellationSideout";

const Orders = (props) => {
    const {search, showFilter, setSelectedCheckbox, updateListCount, selectedCheckbox, filterChips, setFilterValue, setSearchedValue} =
        props;

    // console.log("filterChips 11 : ", filterChips);
    const [searchParams] = useSearchParams();
    const OrderId = searchParams.get("orderId");
    const {orderUpdate} = useAuth();
    const userId = StorageManager.get("orderId");
    const {state, toggleDrawer} = useTempDrawer();
    const {toggleDilog} = useToggleDilog();
    const [priceParam, setPriceParam] = useState("customer_order");

    const [open, setOpen] = React.useState(false);
    const [customerOrderListing, setCustomerOrderListing] = React.useState([]);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    const currentPage = useSelector((state) => state?.pagination?.childCurrentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.childRowsPerPage);

    const [orderBy, setOrderBy] = useState("created_at");
    const [order, setOrder] = useState("desc");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);

    const [printOptionModal, setPrintOptionModal] = useState(false);
    const [openCancelModal, setCancelModal] = useState(false);
    const [openQuoteModal, setOpenQuoteModal] = useState(false);
    const [btnTitle, setBtnTitle] = useState("Confirm");
    const [showHeading, setShowHeading] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [statusToUpdate, setStatus] = useState("");
    const [openDriverModal, setOpenDriverModal] = useState(false);
    const [driverListing, setDriverListing] = React.useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const prm = useParams();
    const CustomerOrderId = prm.type;
    const CustomerName = searchParams.get("customerName");

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const getDriverList = async () => {
        const instance = NetworkManager(API.DRIVER.LISTING);

        const response = await instance.request();

        if (response.success) {
            const driverList = response.data?.driver;
            // Modified array of objects with new property names
            const modifiedArray = driverList
                ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                ?.map((obj) => ({
                    phone: obj.phoneNumber,
                    name: obj.name,
                    ...obj
                }));
            setDriverListing(modifiedArray);
        }
    };

    const handleCancelModal = () => {
        setCancelModal((pre) => !pre);
    };

    const handleEditOrder = (orderId) => {
        navigate(`/orders?tab=scheduled&orderId=${orderId}`);
        toggleDilog("CreateOrder")();
    };

    React.useEffect(() => {
        if (OrderId) {
            handleEditOrder(OrderId);
        }
    }, [OrderId]);

    const handleDriverModal = () => {
        setOpenDriverModal((pre) => !pre);
    };

    const getCustomerOrderList = async (moreFilters = false) => {
        // console.log("moreFilters : ", moreFilters);
        setLoading(true);
        const instance = NetworkManager(API.ORDER.GET);
        const params = {
            offset: currentPage,
            limit: rowsPerPage,
            customer_id: CustomerOrderId,
            ...(moreFilters && {...moreFilters})
        };

        // Convert charged_by from an array to a string
        if (moreFilters && moreFilters.charged_by && Array.isArray(moreFilters.charged_by)) {
            params.charged_by = moreFilters.charged_by.join(",");
        }

        if (moreFilters && moreFilters.guest_count) {
            if (moreFilters.guestFilter === "equal" || moreFilters.guestFilter === undefined) {
                params.guest_count = moreFilters.guest_count;
            } else {
                delete params.guest_count;
                params[`guest_count${moreFilters.guestFilter}`] = moreFilters.guest_count;
            }
            delete params.guestFilter;
        }
        if (moreFilters && moreFilters.total_amount) {
            if (moreFilters.amountFilter === "equal" || moreFilters.amountFilter === undefined) {
                params.total_amount = moreFilters.total_amount;
            } else {
                delete params.total_amount;
                params[`total_amount${moreFilters.amountFilter}`] = moreFilters.total_amount;
            }
            delete params.amountFilter;
        }

        if (orderBy) {
            params.sort_by = orderBy;
            params.sort = order;
        }

        if (search === "") {
            params.offset = currentPage;
        }
        if (search !== "") {
            params.search = search;
            setCustomerOrderListing([]);
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);
        if (response.success) {
            setCustomerOrderListing(response.data.orderList);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
        toggleDrawer("right", false)();
    };

    const handleStatus = async (values, type) => {};

    const handleCancelOrder = async () => {
        const instance = NetworkManager(API.ORDER.STATUS(selectedRow.order_id, "Cancelled"));
        const payload = {
            link: `${process.env.REACT_APP_CUSTOMER_URL}account?tab=orders&orderId=${selectedRow.order_id}`
        };

        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleCancelModal();
        getCustomerOrderList();
    };

    const handleAcceptOrder = async () => {
        const instance = NetworkManager(API.ORDER.STATUS(selectedRow.order_id, "Accepted"));
        const payload = {
            link: `${process.env.REACT_APP_CUSTOMER_URL}account?tab=orders&orderId=${selectedRow.order_id}`
        };

        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleCancelModal();
        getCustomerOrderList();
    };

    const handlePrintModal = () => {
        setPrintOptionModal((pre) => !pre);
    };

    const handlePrintDownload = (price) => {
        handlePrintModal();
        window.open(`/print?orderId=${selectedRow.order_id}&price=${price}`, "_blank", "noreferrer");
    };

    const handleCreateorder = (orderId) => {
        navigate(`/orders?tab=unplaced&reOrder=reOrder&orderId=${orderId}`);
        toggleDilog("CreateOrder")();
    };

    const handleReorder = (orderId) => {
        navigate(`/orders?tab=scheduled&reOrder=reOrder&orderId=${orderId}`);
        toggleDilog("CreateOrder")();
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.ORDER.DELETE(selectedRow.order_id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getCustomerOrderList();
    };

    const handleSendReceipt = async (row) => {
        const instance = NetworkManager(API.ORDER.SENDRECEIPT(row?.order_id));
        const response = await instance.request();

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Order receipt sent successfully",
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                setOpenQuoteModal(false);
                handleEditOrder(row.order_id);
                break;
            }
            case "Issue refund": {
                toggleDrawer("right", "refund")(e);
                break;
            }
            case "Download": {
                window.open(`/print?orderId=${row.order_id}&price=customer_order`, "_blank", "noreferrer");
                break;
            }
            case "Re-order": {
                handleReorder(row.order_id);
                break;
            }
            case "Delete draft": {
                handleDeleteModal();
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }

            case "Cancel order": {
                setBtnTitle("Confirm");
                setModalMessage("Are you sure you want to cancel this order?");
                setStatus("Cancelled");
                handleCancelModal();
                break;
            }
            case "Accept": {
                setBtnTitle("Accept");
                setModalMessage("Are you sure you want to accept this order?");
                setStatus("Accepted");
                handleCancelModal();
                break;
            }
            case "Reject": {
                setBtnTitle("Reject");
                setModalMessage("Are you sure you want to reject this order?");
                setStatus("Rejected");
                handleCancelModal();
                break;
            }
            case "Send receipt": {
                handleSendReceipt(row);
                break;
            }
            case "Re-send quote": {
                setOpenQuoteModal(true);
                toggleDrawer("right", "SendQuote")(e);
                break;
            }

            case "Assign driver": {
                handleDriverModal(row);
                break;
            }
            case "Print": {
                handlePrintModal(row);
                break;
            }
            case "Add note": {
                toggleDrawer("right", "addNewNotes")(e);
                break;
            }
            default:
                break;
        }
    };

    const handlePageChange = (e, number) => {
        dispatch(setChildPageNumber(number + 1));
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        dispatch(setChildRowsPerPage(number));
        dispatch(setChildPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        setOrder(val1);
        setOrderBy(val2);
    };

    useEffect(() => {
        if (search) {
            dispatch(setChildPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        getDriverList();
        getCustomerOrderList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, order, orderBy, isEdited, filterChips, orderUpdate, updateListCount]);

    const handleNoFee = async () => {
        const instance = NetworkManager(API.ORDER.STATUS(selectedRow.order_id, statusToUpdate));
        let payload = null;

        if (statusToUpdate === "Cancelled") {
            payload = {
                link: `${process.env.REACT_APP_CUSTOMER_URL}account?tab=orders&orderId=${selectedRow.order_id}`
            };
        }

        const response = await instance.request(payload);
        if (response.success) {
            setCancelModal(false);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        setCancelModal(false);
        getCustomerOrderList();
    };

    const handleOpenCancelSidebar = () => {
        toggleDrawer("right", "cancelationFee")();
        setCancelModal(false);
    };

    const handleCancelFeeOrder = async () => {
        setSelectedRow(null);
        handleCancelModal();
    };

    return (
        <>
            {" "}
            <Grid container>
                <Grid item xs={12}>
                    <CommonTable
                        withCheckbox
                        loading={loading}
                        data={customerOrderListing}
                        checkbox
                        header={[
                            {key: "order_number", label: "Order #"},
                            {key: "created_at", label: "Created"},
                            {key: "delivery_date", label: "Delivery date"},
                            {key: "customer", label: "Customer"},
                            {key: "delivery_address", label: "Address"},
                            {key: "service_type", label: "Service"},
                            {key: "kitchen_location.market.market_name", label: "Market"},
                            {key: "brand.brand_name", label: "Brand"},
                            {key: "total_price", label: "Total"},
                            {key: "order.status", label: "Status"},
                            {key: "action", label: "Action"}
                        ]}
                        actions={["Edit", "Delete"]}
                        handleActionBtn={handleActionBtn}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage - 1}
                        setSelectedCheckbox={setSelectedCheckbox}
                        selectedCheckbox={selectedCheckbox}
                    />
                </Grid>
            </Grid>
            {state.right === "refund" && (
                <RefundForm
                    handleSelectedRow={handleSelectedRow}
                    customerId={selectedRow?.customer?.user_id}
                    orderId={selectedRow?.order_id}
                    orderNumber={selectedRow?.order_number}
                    refundMethod={selectedRow?.charged_by}
                    orderData={selectedRow}
                />
            )}
            <DriverModal
                open={openDriverModal}
                buttonTitle="Assign driver"
                orderId={selectedRow?.order_id}
                title="Assign a driver"
                driversData={driverListing}
                marketId={
                    selectedRow?.kitchen_location?.market?.market_id.length > 0 ? selectedRow?.kitchen_location?.market?.market_id : []
                }
                description="Select a driver to assign to order #B6T-E2G"
                handleClose={handleDriverModal}
                handleSubmit={handleDriverModal}
            />
            <PrintOrderModal
                open={printOptionModal}
                buttonTitle="Print order"
                title="Print order"
                description="Select the type of order you would like to print"
                handleClose={handlePrintModal}
                handleSubmit={(price) => handlePrintDownload(price)}
                noOnClose
                selected={priceParam}
                onSelect={(price) => {
                    setPriceParam(price);
                    setShowHeading(price === "price");
                }}
            />
            {state.right === "addNewNotes" && (
                <AddNewNotes
                    userID={selectedRow?.customer?.user_id}
                    selectedData={selectedRow}
                    handleIsEdited={handleIsEdited}
                    handleSelectedRow={handleSelectedRow}
                    // setSelectedRow={setSelectedRow}
                />
            )}
            <DeleteModal
                open={open}
                title="Are you sure you want to delete this order?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
            {state.right === "cancelationFee" && (
                <CancellationSideout
                    userID={selectedRow?.customer?.user_id}
                    selectedData={selectedRow}
                    handleIsEdited={handleIsEdited}
                    handleSelectedRow={handleSelectedRow}
                    setSelectedRow={setSelectedRow}
                />
            )}
            {/* <CancelModal
                open={openCancelModal}
                buttonTitle="Yes charge fee"
                title={`Cancel order #${selectedRow?.order_number}`}
                description="Would you like to charge a cancelation fee?"
                handleNoFee={handleNoFee}
                handleClose={handleCancelModal}
                handleSubmit={btnTitle === "Accept" ? handleAcceptOrder : handleCancelOrder}
            /> */}
            <CancelModal
                open={openCancelModal}
                buttonTitle="Yes charge fee"
                title={`Cancel order #${selectedRow?.order_number}`}
                description="Would you like to charge a cancelation fee?"
                handleNoFee={handleNoFee}
                handleClose={handleCancelModal}
                handleSubmit={handleOpenCancelSidebar}
            />
            {/* <DeleteModal
                open={openCancelModal}
                buttonTitle={btnTitle}
                title={modalMessage}
                description="This will permanently cancel the order."
                handleClose={handleCancelModal}
                handleSubmit={btnTitle === "Accept" ? handleAcceptOrder : handleCancelOrder}
            /> */}
            {selectedRow && openQuoteModal && (
                <SendQuote
                    orderId={{order_id: selectedRow?.order_id}}
                    buttonTitle="Re-send quote"
                    customerId={selectedRow?.customer?.email}
                />
            )}
        </>
    );
};

export default Orders;
