import {Grid, Typography, Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import Cards from "../Cards/Cards";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "react-redux";
import {useNavigate, useSearchParams, useParams} from "react-router-dom";
import Loader from "ui-component/Loader";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";

function UserView() {
    const dispatch = useDispatch();
    const [userData, setUserData] = useState([]);
    const [permissionData, setPermissionData] = useState([]);
    const [marketData, setMarketData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [urlSearchParams] = useSearchParams();

    const mrkName = urlSearchParams.get("userName");
    const editMode = urlSearchParams.get("edit");
    const prm = useParams();
    const Userid = prm.id;

    const getUserProfileData = async () => {
        const instance = NetworkManager(API.SUBADMIN.DETAIL(`${Userid}`));
        const response = await instance.request();
        console.log("response : ", response);
        if (response.success) {
            setUserData(response?.data?.subAdmin);
            const temp = [];
            response?.data?.subAdmin?.subAdminDetail?.subRole?.role_modules?.forEach((row) => {
                temp.push({label: "", value: row?.module?.module_name});
            });
            const marketTemp = [];
            response?.data?.subAdmin?.subAdminMarketName?.forEach((row) => {
                marketTemp.push({label: "", value: row});
            });

            setPermissionData(temp);
            setMarketData(marketTemp);
            setLoading(false);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    useEffect(() => {
        getUserProfileData();
    }, [Userid]);

    const accessData = [{label: "Markets", value: "All markets" || " "}];

    return (
        <>
            {/* {loading && <Loader />} */}
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{maxWidth: "80%"}}>
                        <Grid
                            container
                            sx={{
                                borderRadius: "4px",
                                border: "1px solid #DBDBDB",
                                padding: "16px"
                            }}
                        >
                            <Grid item xs={12}>
                                <Typography sx={{fontSize: "1.125rem", fontWeight: 500}} color="#020A11">
                                    User details
                                </Typography>
                                <Grid container mb={2}>
                                    <Grid item xs={12} sm={3} mt={1.5}>
                                        <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                                            First name
                                        </Typography>
                                        <Typography sx={{fontSize: "0.875rem", fontWeight: 400}} color="#212121">
                                            {userData?.subAdminDetail?.first_name ?? ""}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3} mt={1.5}>
                                        <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                                            Last name
                                        </Typography>
                                        <Typography sx={{fontSize: "0.875rem", fontWeight: 400}} color="#212121">
                                            {userData?.subAdminDetail?.last_name ?? ""}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3} mt={1.5}>
                                        <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                                            Email
                                        </Typography>
                                        <Typography sx={{fontSize: "0.875rem", fontWeight: 400}} color="#212121">
                                            {userData?.subAdminDetail?.email ?? ""}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3} lg={2.6} mt={1.5}>
                                        <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                                            Phone
                                        </Typography>
                                        <Typography sx={{fontSize: "0.875rem", fontWeight: 400}} color="#212121">
                                            {userData?.subAdminDetail?.phone ?? ""}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3} mt={1.5}>
                                        <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                                            Role
                                        </Typography>
                                        <Typography sx={{fontSize: "0.875rem", fontWeight: 400}} color="#212121">
                                            {userData?.subAdminDetail?.subRole?.role_name ?? ""}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3} mt={1.5}>
                                        <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                                            Designation
                                        </Typography>
                                        <Typography sx={{fontSize: "0.875rem", fontWeight: 400}} color="#212121">
                                            {userData?.subAdminDetail?.additional_details?.designation ?? ""}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3} mt={1.5}>
                                        <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                                            Calender link
                                        </Typography>
                                        <a
                                            href={userData?.subAdminDetail?.additional_details?.calendar_link}
                                            style={{textDecoration: "none"}}
                                        >
                                            <Typography sx={{fontSize: "0.875rem", fontWeight: 400}} color="#212121">
                                                {ellipsizeText(userData?.subAdminDetail?.additional_details?.calendar_link, 40) ?? ""}
                                            </Typography>
                                        </a>
                                    </Grid>
                                    <Grid item xs={12} sm={3} mt={1.5}>
                                        <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                                            Linkedin link
                                        </Typography>
                                        <a
                                            href={userData?.subAdminDetail?.additional_details?.linkedin_link}
                                            style={{textDecoration: "none"}}
                                        >
                                            <Typography sx={{fontSize: "0.875rem", fontWeight: 400}} color="#212121">
                                                {ellipsizeText(userData?.subAdminDetail?.additional_details?.linkedin_link, 40) ?? ""}
                                            </Typography>
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box sx={{display: "flex", gap: "16px", width: "100%", flexWrap: {xs: "wrap", sm: "nowrap"}}} mt={2}>
                            <Cards title="Access" data={marketData} singleGrid />
                            <Cards title="Permissions" data={permissionData} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default UserView;
