import {OrderBoxContext} from "contexts/OrderDetialPopupContext";
import {useContext} from "react";

// ==============================|| TEMP DRAWER HOOKS ||============================== //

const useOrderDialog = () => {
    const context = useContext(OrderBoxContext);

    if (!context) throw new Error("context must be use inside provider");

    return context;
};

export default useOrderDialog;
