import React from "react";
import {SubContainer} from "../../CreateLocationForm";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import {Typography, Grid, Box} from "@mui/material";
import {ErrorMessage} from "formik";
import CustomTimePicker from "commonComponent/Form/Fields/CustomTimePicker";
import TemporaryClosures from "./TemporaryClosures";
import StandardHours from "./StandardHours";
import HolidayClosures from "./HolidayClosures";
import CustomNewDateTimePicker from "commonComponent/newDateAndTimePicker/CustomNewDateTimePicker";

const HoursOfOperation = (props) => {
    const {formik, readOnly} = props;
    const {values, setFieldValue, getFieldProps} = formik;

    return (
        <Grid container spacing={2.5}>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item lg={6} xs={12}>
                        <SubContainer title="Standard hours" formik={formik}>
                            <StandardHours formik={formik} readOnly={readOnly} />
                        </SubContainer>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <SubContainer title="Holiday closures">
                            <HolidayClosures formik={formik} readOnly={readOnly} />
                        </SubContainer>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <SubContainer readOnly={readOnly} title="Temporary closures" switchBtn formik={formik}>
                    <TemporaryClosures formik={formik} readOnly={readOnly} />
                </SubContainer>
            </Grid>

            <Grid item xs={12}>
                <SubContainer title="Lead time">
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={8} sm={8} xs={12}>
                            <Box sx={{mb: 1}}>
                                <Typography variant="body4" fontSize="12px">
                                    How far in advance do you need to know about orders?
                                </Typography>
                            </Box>
                            <CustomSelect
                                disabled={readOnly}
                                label="Lead time"
                                numberValidation
                                menuItems={[
                                    {label: "Current day", id: "CurrentDay"},
                                    {label: "One day before", id: "TheDayBefore"},
                                    {label: "Two days before", id: "TwoDaysBefore"},
                                    {label: "Three days before", id: "ThreeDaysBefore"},
                                    {label: "Four days before", id: "FourDaysBefore"}
                                ]}
                                {...getFieldProps("leadTime.knowAboutOrders")}
                            />
                            <ErrorMessage name="leadTime.knowAboutOrders" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>

                        <Grid item lg={3} md={4} sm={8} xs={12}>
                            <Box sx={{mb: 1}}>
                                <Typography variant="body4" fontSize="0.75em">
                                    Cut-off time
                                </Typography>
                            </Box>
                            <CustomNewDateTimePicker
                                disabled={readOnly}
                                timepicker
                                placeholder="hh:mm"
                                value={values.leadTime.cuttOffTime || null}
                                onChange={(val) => setFieldValue(`leadTime.cuttOffTime`, val)}
                            />
                            <ErrorMessage name="leadTime.cuttOffTime" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>
                    </Grid>
                </SubContainer>
            </Grid>
        </Grid>
    );
};

export default HoursOfOperation;
