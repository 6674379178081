import React, {createContext} from "react";
import useRoleBaseAccessController from "./rbac.controller";

export const RbacContext = createContext();

const RBAC = (props) => {
    const rbac = useRoleBaseAccessController(props);
    const {children} = props;

    return (
        <>
            <RbacContext.Provider value={{permission: rbac?.permission, setPermission: rbac?.setPermission}}>
                {children}
            </RbacContext.Provider>
        </>
    );
};

export default RBAC;
