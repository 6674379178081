import React, {useState} from "react";
import AddCategoryForm from "./addCategoryForm";
import AddNewCategoryForm from "./addNewCategoryForm";

const AddCategory = (props) => {
    const {handleIsEdited, selectedRow, handleSelectedRow, updateCategory} = props;
    const [createNew, setCreateNew] = useState(false);

    const handleChange = (type) => {
        if (type === "new") {
            setCreateNew(true);
        } else {
            setCreateNew(false);
        }
    };

    const handleClose=()=>{
        setCreateNew(false);
    }

    return (
        <>
            {createNew ? (
                <AddNewCategoryForm
                    handleIsEdited={handleIsEdited}
                    selectedRow={selectedRow}
                    handleSelectedRow={handleSelectedRow}
                    handleChange={handleChange}
                    updateCategory={updateCategory}
                    handleClose={handleClose}
                />
            ) : (
                <AddCategoryForm
                    handleIsEdited={handleIsEdited}
                    selectedRow={selectedRow}
                    handleSelectedRow={handleSelectedRow}
                    handleChange={handleChange}
                    updateCategory={updateCategory}
                />
            )}
        </>
    );
};

export default AddCategory;
