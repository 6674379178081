import {DilogBoxContext} from "contexts/DilogBoxContext";
import {useContext} from "react";

// ==============================|| TEMP DRAWER HOOKS ||============================== //

const useToggleDilog = () => {
    const context = useContext(DilogBoxContext);

    if (!context) throw new Error("context must be use inside provider");

    return context;
};

export default useToggleDilog;
