import React, {useEffect, useState} from "react";
import {Stack, Typography, useMediaQuery, useTheme, Box, Grid} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {ErrorMessage, Formik, Form} from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import {enforceFormatHandleDecimal} from "utils/helperFunctions/helpers";
import {CancellationValidation} from "utils/validations/validations";
import {useNavigate} from "react-router-dom";

const formSchema = {
    cancellationFee: "",
    reason: ""
};

const CancellationModal = ({userID, selectedData, handleIsEdited, setTitle, handleSelectedRow, setSelectedRow, setShowFilter}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState(formSchema);
    const {toggleDrawer} = useTempDrawer();

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up("sm"));

    // useEffect(() => {
    //     if (selectedData) {
    //         setInitialValues({
    //             note: selectedData?.note
    //         });
    //     } else {
    //         setInitialValues(formSchema);
    //     }
    // }, [selectedData]);

    const isEditing = selectedData?.customer_note_id;

    const handleCanceltaionFee = async (values) => {
      
        const payload = {
            orderId: selectedData.order_id,
            customerId: selectedData?.customer?.user_id,
            amount: Number(values.cancellationFee).toFixed(2)
        };
        if (values.reason !== "") {
            payload.reason = values.reason;
        }

        const instance = NetworkManager(API.ORDER.UPDATECANCELFEE);

        const res = await instance.request(payload);

        if (res.success) {
            if (Object.keys(res.data).length === 0) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: res.message,
                        variant: "alert",
                        alert: {
                            color: "error"
                        },
                        close: true
                    })
                );
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: res.message,
                        variant: "alert",
                        alert: {
                            color: "success"
                        },
                        close: true
                    })
                );
            }

            handleIsEdited();
            setTimeout(() => navigate("/orders?tab=canceled"), 100);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
        setInitialValues(formSchema);
        setSelectedRow(null);
        handleSelectedRow(null);
    };
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={CancellationValidation}
                onSubmit={(val, {setSubmitting}) => {
                    handleCanceltaionFee(val, setSubmitting);
                }}
            >
                {(formik) => (
                    <Form>
                        <TemporaryDrawer
                            sideDrawerState="cancelationFee"
                            handleSelectedRow={handleSelectedRow}
                            resetForm={formik.resetForm}
                        >
                            <Stack sx={{height: "100%"}} direction="column" justifyContent="space-between" padding={1}>
                                <Box>
                                    <Box sx={{marginTop: "-8px"}}>
                                        <Typography variant="headingOne" fontSize="1.625rem" fontWeight={700}>
                                            Cancel order #{selectedData.order_number}
                                        </Typography>
                                    </Box>
                                    <Box sx={{mt: 3, mb: 1}}>
                                        <Typography variant="headingOne" fontSize="1.0625rem" fontWeight={700}>
                                            Cancelation fee
                                        </Typography>
                                    </Box>
                                    <Grid container sx={{marginTop: "16px"}}>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                fullWidth
                                                onKeyDown={enforceFormatHandleDecimal}
                                                label="Fee amount"
                                                InputProps={{
                                                    maxLength: 50,
                                                    startAdornment: (
                                                        <Typography variant="body1" style={{marginRight: 8}}>
                                                            $
                                                        </Typography>
                                                    ),
                                                    
                                                }}
                                                {...formik.getFieldProps("cancellationFee")}
                                            />
                                            <ErrorMessage
                                                name="cancellationFee"
                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} mt={2}>
                                            <CustomTextField
                                                fullWidth
                                                multiline
                                                inputProps={{
                                                    maxLength: 500
                                                }}
                                                minRows={4}
                                                sx={{"&": {height: "235px !important"}}}
                                                label="Reason for charging fee (optional)"
                                                {...formik.getFieldProps("reason")}
                                            />
                                            <ErrorMessage name="reason" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    sx={{
                                        position: "fixed",
                                        bottom: 40,
                                        height: "52px",
                                        width: !matchUpMd ? "290px" : "420px"
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box
                                                sx={{
                                                    position: "sticky",
                                                    bottom: 0,
                                                    mt: 2,
                                                    p: 2,
                                                    bgcolor: "#fff",
                                                    zIndex: 1
                                                }}
                                            >
                                                <ButtonGrouping
                                                    btnprops={[
                                                        {
                                                            btnTitle: "Cancel",
                                                            sx: (t) => ({color: t.palette.error.dark}),
                                                            onClick: () => {
                                                                formik.resetForm();
                                                                handleSelectedRow(null);
                                                                toggleDrawer("right", false)();
                                                            }
                                                        },
                                                        {
                                                            btnTitle: "Confirm cancelation",
                                                            variant: "contained",
                                                            disabled: formik.isSubmitting,
                                                            onClick: (e) => {
                                                               
                                                                formik.handleSubmit();
                                                                formik.validateForm().then((res) => {
                                                                    
                                                                    if (Object.keys(res).length === 0) {
                                                                        toggleDrawer("right", false)(e);
                                                                        setTimeout(() => {
                                                                            formik.resetForm();
                                                                        }, 1500);
                                                                    }
                                                                });
                                                            },
                                                            sx: (t) => ({
                                                                color: t.palette.background.paper,
                                                                width: "224px",
                                                                fontWeight: 600
                                                            })
                                                        }
                                                    ]}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>
                        </TemporaryDrawer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CancellationModal;
