// routing
import Routes from "routes";

// project imports
import Locales from "ui-component/Locales";
import NavigationScroll from "layout/NavigationScroll";
import RTLLayout from "ui-component/RTLLayout";
import Snackbar from "ui-component/extended/Snackbar";

import ThemeCustomization from "themes";

// auth provider
import { FirebaseProvider as AuthProvider } from "contexts/FirebaseContext";
import { DrawerContextProvider } from "contexts/DrawerContext";
import { DrawerContextModifierProvider } from 'contexts/DrawerContextModifier'
import { DilogBoxContextProvider } from "contexts/DilogBoxContext";
import { OrderBoxContextProvider } from "contexts/OrderDetialPopupContext";
import { FilterContextProvider } from "contexts/FilterContext";
import RBAC from "commonComponent/RBAC";
import { NAVIGATION_CONST } from "constant";
import StorageManager from "storage/StorageManager";

// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => (
    <RBAC modules={NAVIGATION_CONST} role={StorageManager.get("role")}>
        <ThemeCustomization>
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <DrawerContextProvider>
                                <DrawerContextModifierProvider>
                                    <DilogBoxContextProvider>
                                        <OrderBoxContextProvider>
                                            <FilterContextProvider>
                                            <>
                                                <Routes />
                                                <Snackbar />
                                            </>
                                            </FilterContextProvider>
                                        </OrderBoxContextProvider>

                                    </DilogBoxContextProvider>
                                </DrawerContextModifierProvider>
                            </DrawerContextProvider>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    </RBAC>
);

export default App;
