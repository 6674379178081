import {Reorder} from "commonComponent/Dragger/utils";
import {API, NetworkManager} from "network/core";
import {useState} from "react";
import {dispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";

const useModifierSectionController = (props) => {
    const {formik} = props;

    const {modifierGroupId} = formik.values;

    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleEditModifier = async (val, index, modifierGroupId) => {
        const instance = NetworkManager(API.MODIFIER.UPDATE(val.id));

        const payload = {
            name: val.name,
            price: val.price || 0,
            modifier_group_id: [modifierGroupId],
            modifier_dietary: val.dietary,
            series_no: index
        };

        await instance.request(payload);

        // if (response.success) {
        //     arrayHelper.replace(index, { ...dataObj, isEdit: false });

        // }
    };

    const onDragEnd = (formik) => (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        if (result.type === "QUESTIONS") {
            const quest = Reorder(formik.values.modifierOptions, result.source.index, result.destination.index);
            formik.setFieldValue(`modifierOptions`, quest);

            quest.forEach((row, index) => {
                handleEditModifier(row, index, formik.values.modifierGroupId);
            });
        } else {
            const answers = Reorder(
                formik.values.modifierOptions[parseInt(result.type, 10)].answers,
                result.source.index,
                result.destination.index
            );

            const quest1 = JSON.parse(JSON.stringify(formik.values.modifierOptions));
            quest1.forEach((row, index) => {
                handleEditModifier(row, index, formik.values.modifierGroupId);
            });

            quest1[result.type].answers = answers;

            formik.setFieldValue(`modifierOptions`, quest1);
        }
    };

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.MODIFIER.DELETEMODIFIERWITHGROUPID(`${selectedRow.id}/${modifierGroupId}`));
        const response = await instance.request();
        if (response.success) {
            const preData = [...formik.values.modifierOptions];
            const notdeleted = preData.filter((el) => el.id !== selectedRow.id);
            formik.setFieldValue("modifierOptions", [...notdeleted]);

            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
    };

    const handleDuplicateModifier = async (arrayHelper, index, row) => {
        const instance = NetworkManager(API.MODIFIER.DUPLICATE(`${row.id}?modifier_group_id=${modifierGroupId}`));
        const response = await instance.request({});
        if (response.success) {
            const data = response.data;

            const modifier = {
                isEdit: false,
                id: data.modifier_id,
                name: data.modifier_name,
                price: data.price,
                dietary: data.ModifierDietary?.map((obj) => obj?.modifier_dietary),
                minimum_value: data?.minimum_value
            };

            arrayHelper.insert(index, modifier);

            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            setSelectedRow(null);
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleActionBtn = (type, row, handleClose, arrayHelper, index) => (e) => {
        setSelectedRow({...row});
        handleClose();
        switch (type) {
            case "Edit": {
                arrayHelper.replace(index, {...row, isEdit: true});
                break;
            }
            case "Duplicate": {
                handleDuplicateModifier(arrayHelper, index, row);
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            default:
                break;
        }
    };

    return {
        open,
        handleDeleteModal,
        handleDelete,
        onDragEnd,
        handleActionBtn
    };
};

export default useModifierSectionController;
