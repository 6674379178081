import {Grid, Typography, Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import Cards from "../Cards/Cards";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "react-redux";
import {useNavigate, useSearchParams, useParams} from "react-router-dom";
import Loader from "ui-component/Loader";

function ViewBrand() {
    const dispatch = useDispatch();
    const [brandData, setBrandData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [urlSearchParams] = useSearchParams();
    // const CustomerId = urlSearchParams.get("customerid");
    const mrkName = urlSearchParams.get("customerName");
    const editMode = urlSearchParams.get("edit");
    const prm = useParams();
    const {id} = prm;

    const getProfileData = async () => {
        const instance = NetworkManager(API.BRAND.GETBYID(id));
        const response = await instance.request();

        if (response.success) {
            setBrandData(response?.data?.brand);
            setLoading(false);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    transition: "Fade",
                    close: true
                })
            );
        }
        setLoading(false);
    };
    useEffect(() => {
        getProfileData();
    }, []);

    function convertToTitleCase(label) {
        if (typeof label !== "string") {
            return label;
        }

        const words = label.split("_").map((word, index) => {
            if (word.length > 0) {
                if (index === 0) {
                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                } else {
                    return word.charAt(0).toLowerCase() + word.slice(1).toLowerCase();
                }
            }
            return word;
        });

        return words.join("-");
    }

    const brandDetailsData = [
        {label: "Brand", value: brandData?.brand_name || "-"},
        {label: "Abbreviation", value: brandData?.abbreviation || "-"},
        {label: "Owner", value: brandData?.owner || "-"},
        {label: "Cuisine", value: brandData?.cusine || "-"},
        {
            label: "Menus",
            value:
                brandData?.brandMenu?.length > 0
                    ? brandData.brandMenu.map((el, index) => <div key={index}>{el?.menu?.menu_name}</div>)
                    : "-"
        },
        {
            label: "Services",
            value: brandData?.brandServices?.map((el, index) => <div key={index}>{convertToTitleCase(el?.service_name)}</div>) || "-"
        }
    ];

    const getDefaultLocationID = () => {
        const defaultKitchenLocation = brandData?.brandKitchenLocation
            ?.filter((el) => el?.is_default)
            .map((el) => el?.kitchenLocation?.name);

        return defaultKitchenLocation;
    };

    const addressData = [
        {
            label: "Locations",
            value:
                brandData?.brandKitchenLocation?.sort((a,b) => a?.kitchenLocation?.name.localeCompare(b?.kitchenLocation?.name))?.map((address, index) => <div key={index}>{address?.kitchenLocation?.name}</div>) || "-"
        },
        {label: "Default Location", value: getDefaultLocationID()?.[0] || "-"}
        
    ];
    return (
        <>
            {loading && <Loader />}
            <Grid container mt={2}>
                <Grid item xs={12}>
                    <Box sx={{maxWidth: "80%"}}>
                        <Grid
                            container
                            sx={{
                                borderRadius: "4px",
                                border: "1px solid #DBDBDB",
                                padding: "16px"
                            }}
                        >
                            <Grid item xs={12}>
                                <Typography sx={{fontSize: "1.125rem", fontWeight: 500}} color="#020A11">
                                    Brand details
                                </Typography>
                                <Grid container mb={2}>
                                    {brandDetailsData.map((item, index) => (
                                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index} mt={1.5}>
                                            <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                                                {item.label}
                                            </Typography>
                                            <Typography sx={{fontSize: "0.875rem", fontWeight: 500}} color="#212121">
                                                {item.value}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box sx={{display: "flex", gap: "16px", width: "100%", flexWrap: {xs: "wrap", sm: "nowrap"}}} mt={2}>
                            <Cards title="Locations" data={addressData} />
                            {brandData?.brand_logo === null ? (
                                <Box sx={{display: "flex", width: "50%"}}>
                                    <Box
                                        sx={{
                                            borderRadius: "4px",
                                            border: "1px solid #DBDBDB",
                                            padding: "16px",
                                            width: {xs: "100%", md: "100%"}
                                        }}
                                    >
                                        <Typography sx={{fontSize: "1.125rem", fontWeight: 500}} color="#020A11">
                                            Brand logo
                                        </Typography>
                                        <Typography sx={{fontSize: "0.875rem", fontWeight: 500, marginTop: "1.5rem"}} color="#212121">
                                            No image available
                                        </Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <Box sx={{display: "flex", width: "50%"}}>
                                    <Box
                                        sx={{
                                            borderRadius: "4px",
                                            border: "1px solid #DBDBDB",
                                            padding: "16px",
                                            width: {xs: "100%", md: "100%"}
                                        }}
                                    >
                                        <Typography sx={{fontSize: "1.125rem", fontWeight: 500}} color="#020A11">
                                            Brand logo
                                        </Typography>
                                        <Box sx={{padding: "2rem 0rem", width: "100%", height: "100%"}}>
                                            {brandData?.brand_logo === null || brandData?.brand_logo === "" ? (
                                                "No image uploaded"
                                            ) : (
                                                <img
                                                    style={{width: "100%", height: "100%", objectFit: "contain"}}
                                                    src={brandData?.brand_logo}
                                                    alt="logo"
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default ViewBrand;
