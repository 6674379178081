import {Grid} from "@mui/material";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {NetworkManager, API} from "network/core";
import React, {useState, useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import Createitem from "../brandDetail/items/addItem";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Filters from "commonComponent/Filters";
import {generateUrl} from "utils/helperFunctions/helpers";
import FilterChips from "commonComponent/FilterChipsComponent";
import NewAddItem from "commonComponent/ItemComponent/Additem/NewAddItem";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import Filter from "commonComponent/FilterAccoridan";
import {setChildPageNumber, setChildRowsPerPage, setChildOrder, setChildOrderBy} from "store/slices/paginationSlice";

const ItemsListing = (props) => {
    const {search, menuId, showFilter, setSelectedCheckbox, updateListCount, selectedCheckbox, filterChips} = props;
    const [open, setOpen] = React.useState(false);
    const [itemListing, setItemListing] = React.useState([]);
    const currentPage = useSelector((state) => state?.pagination?.childCurrentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.childRowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.childOrder || "asc");
    const currentOrderBy = useSelector((state) => state?.pagination?.childOrderBy || "item_name");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const navigation = useNavigate();
    const param = useParams();
    const {menuid} = param;
    const [searchParams] = useSearchParams();
    const menuName = searchParams.get("menuName");

    const dispatch = useDispatch();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };
    console.log("THIS IS HERE");

    const getItemList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.ITEM.GET);
        const params = {
            page: currentPage,
            pageSize: rowsPerPage,
            menu_id: menuid,
            ...(moreFilters && {...moreFilters})
        };
        if (currentOrderBy) {
            params.sortBy = currentOrderBy;
            params.orderBy = currentOrder;
        }
        // while searching we dont need page number
        if (search === "") {
            params.page = currentPage;
        }
        if (search !== "") {
            params.item_name = search;
            params.page = 1;
            setItemListing([]);
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);
        if (response.success) {
            setItemListing(response.data.item);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handleStatus = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.ITEM.UPDATE(`/status/${values.item_id}`));
        let isActive = false;
        if (type === "Deactivate") {
            isActive = false;
        } else {
            isActive = true;
        }
        const payload = {
            status: isActive
        };
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleDuplicateRow = async (id) => {
        const instance = NetworkManager(API.ITEM.DUPLICATE(id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        getItemList();
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                navigation(
                    menuName
                        ? `/items/${menuId}/${row.item_id}?tab=itemDetails&edit=true&menuName=${menuName}`
                        : `/items/${menuId}/${row.item_id}?tab=itemDetails&edit=true`
                );
                break;
            }
            case "Duplicate": {
                handleDuplicateRow(row.item_id);
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            case "Deactivate": {
                handleStatus(row, type);
                break;
            }
            case "Activate": {
                handleStatus(row, type);
                break;
            }
            default:
                break;
        }
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.ITEM.DELETE(selectedRow.item_id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getItemList();
    };

    const handlePageChange = (e, number) => {
        dispatch(setChildPageNumber(number + 1));
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        dispatch(setChildRowsPerPage(number));
        dispatch(setChildPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        dispatch(setChildOrder(val1));
        dispatch(setChildOrderBy(val2));
    };

    useEffect(() => {
        if (search) {
            dispatch(setChildPageNumber(1));
        }
    }, [search]);
    console.log(filterChips,"FITE");
    useEffect(() => {
        getItemList(Object.keys(filterChips).length ? filterChips : "");
    }, [currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, filterChips, updateListCount, search]);

    const handleAddItemToCateogoryList = (item) => {
        // getItemList();
    };

    return (
        <>
            {showFilter ? null : (
                <NewAddItem handleIsEdited={handleIsEdited} menuid={menuid} isEditable />
                // <Createitem
                //     handleIsEdited={handleIsEdited}
                //     selectedRow={selectedRow}
                //     handleSelectedRow={handleSelectedRow}
                //     isEdit
                //     handleAddItemToCateogoryList={handleAddItemToCateogoryList}
                // />
            )}

            <Grid container>
                <Grid item xs={12}>
                    <CommonTable
                        withCheckbox
                        id={menuid}
                        loading={loading}
                        data={itemListing}
                        searchParam={menuName}
                        header={[
                            {key: "item_name", label: "Menu item name"},
                            {key: "menus", label: "Menu"},
                            {key: "services", label: "Service"},
                            {key: "Category_names", label: "Category"},
                            {key: "createdAt", label: "Created"},
                            {key: "status", label: "Status"},

                            WithPermission({
                                module: "Items",
                                permission: ["delete_permission", "create_permission", "update_permission"]
                            }) && {
                                key: "action",
                                label: "Action"
                            }
                        ].filter((el) => el)}
                        actions={[
                            WithPermission({module: "Items", permission: "update_permission"}) && "Edit",
                            WithPermission({module: "Items", permission: "create_permission"}) && "Duplicate",
                            WithPermission({module: "Items", permission: "delete_permission"}) && "Delete"
                        ]}
                        handleActionBtn={handleActionBtn}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage - 1}
                        sortingLabel={["Menu item name", "Menu", "Created", "Status"]}
                        setSelectedCheckbox={setSelectedCheckbox}
                        selectedCheckbox={selectedCheckbox}
                    />
                </Grid>
            </Grid>

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this item?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
        </>
    );
};

export default ItemsListing;
