import React, {useEffect, useState} from "react";
import {Box, Grid, Typography, Radio, RadioGroup, FormControlLabel, InputAdornment} from "@mui/material";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import {ErrorMessage, Form, Formik} from "formik";
import CustomeDatePicker from "commonComponent/Form/Fields/CustomeDatePicker";
import {useNavigate, useSearchParams, useParams} from "react-router-dom";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "react-redux";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import Loader from "ui-component/Loader";
import * as yup from "yup";
import PreferredPricingView from "./PreferredPricingView/PreferredPricingView";

const formSchema = {
    discount_type: "",
    discount_amount: "",
    discount_percentage: "",
    startDate: "",
    endDate: "",
    ends: false
};

function PreferredPricing() {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState(formSchema);
    const prm = useParams();
    const profileId = prm?.type;
    const [loading, setLoading] = useState(false);
    const [urlSearchParams] = useSearchParams();
    const editMode = urlSearchParams.get("edit");
    const isEditing = initialValues.discount_type !== "";
    const navigate = useNavigate();

    const handlePreferredPricing = async (values) => {
        const instance = NetworkManager(
            isEditing ? API.CUSTOMER.UPDATEPREFERREDPRICING(`${profileId}`) : API.CUSTOMER.CREATEPREFERREDPRICING(`${profileId}`)
        );

        const adjustToMidnightUTC = (dateString) => {
            const date = new Date(dateString);
            date.setUTCHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 00:00:00.000 in UTC
            return date.toISOString(); // Convert back to ISO string
        };

        const payload = {
            discount_value_type: values.discount_type,
            start_date: adjustToMidnightUTC(values.startDate),
            discount_value: values.discount_type === "DISCOUNT" ? values.discount_amount : values.discount_percentage
        };

        if (values.ends !== "never") {
            payload.end_date = adjustToMidnightUTC(values.endDate);
        }

        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            navigate("/customers?tab=customer");
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const getPreferredPricingData = async () => {
        setLoading(true);
        const instance = NetworkManager(API.CUSTOMER.GETPREFERREDPRICING(`${profileId}`));

        const response = await instance.request();
        if (response.success) {
            setInitialValues({
                discount_type: response?.data?.discount_value_type || "",
                discount_percentage: response?.data?.discount_value || "",
                discount_amount: response?.data?.discount_value || "",
                startDate: response?.data?.start_date || null,
                endDate: response?.data?.end_date || null,
                ends: response?.data?.end_date === null ? "never" : ""
            });
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setLoading(false);
    };

    useEffect(() => {
        getPreferredPricingData();
    }, []);

    return (
        <>
            {loading && <Loader />}
            {editMode ? (
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={yup.object({
                        discount_type: yup.string().required("Discount type is required").nullable().trim(),
                        discount_amount: yup.string().when("discount_type", {
                            is: "DISCOUNT",
                            then: yup.string().required("Discount amount is required").matches(/^\d+$/, "Discount amount must be a number"),
                            otherwise: yup.string().nullable().trim()
                        }),
                        discount_percentage: yup.string().when("discount_type", {
                            is: "PERCENTAGE",
                            then: yup
                                .string()
                                .required("Discount percentage is required")
                                .matches(/^\d+$/, "Discount percentage must be a number")
                                .max(100, "Discount percentage must be less than or equal to 100"),
                            otherwise: yup.string().nullable().trim()
                        }),
                        startDate: yup.string().required("Start date is required").nullable().trim()
                    })}
                    onSubmit={(val) => handlePreferredPricing(val)}
                >
                    {(formik) => (
                        <Grid container spacing={2}>
                            <Grid item xs={12} mt={2}>
                                <Typography sx={{fontSize: "1.375rem", fontWeight: 600, color: "#000"}}>Preferred pricing</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                                <CustomSelect
                                    noNone
                                    label="Discount type"
                                    fullWidth
                                    menuItems={[
                                        {label: "Discount", value: "DISCOUNT", id: "DISCOUNT"},
                                        {label: "Percentage", value: "PERCENTAGE", id: "PERCENTAGE"}
                                    ]}
                                    {...formik.getFieldProps("discount_type")}
                                    onChange={(e) => {
                                        const id = e.target.value;
                                        formik.setFieldValue("discount_type", id);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                                {formik.values.discount_type === "DISCOUNT" && (
                                    <>
                                        <CustomTextField
                                            placeholder="Discount amount"
                                            label="Discount amount"
                                            fullWidth
                                            InputProps={{
                                                maxLength: 50,
                                                startAdornment: (
                                                    <Typography variant="body1" style={{marginRight: 8}}>
                                                        $
                                                    </Typography>
                                                ),
                                                inputProps: {
                                                    onInput: (e) => {
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                                                    }
                                                }
                                            }}
                                            {...formik.getFieldProps("discount_amount")}
                                        />
                                        <ErrorMessage name="discount_amount" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </>
                                )}
                                {formik.values.discount_type === "PERCENTAGE" && (
                                    <>
                                        <CustomTextField
                                            placeholder="Discount percentage"
                                            label="Discount percentage"
                                            fullWidth
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                                            }}
                                            inputProps={{
                                                maxLength: 50,
                                                onInput: (e) => {
                                                    e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                                                    if (e.target.value > 100) e.target.value = "100"; // Limit to 100
                                                }
                                            }}
                                            {...formik.getFieldProps("discount_percentage")}
                                        />
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4} xl={3.5}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <CustomeDatePicker
                                                    label="Start date"
                                                    valueBasic={formik.values.startDate || null}
                                                    setValueBasic={(val) => formik.setFieldValue(`startDate`, val)}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <CustomeDatePicker
                                                    label="End date"
                                                    minDate={formik.values.startDate}
                                                    valueBasic={formik.values.endDate || null}
                                                    setValueBasic={(val) => formik.setFieldValue(`endDate`, val)}
                                                    disabled={formik.values.ends === "never"}
                                                />
                                                <Box>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        name="radio-buttons-group"
                                                        value={formik.values.ends === "never" ? "never" : ""}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(`ends`, formik.values.ends === "never" ? "" : "never");
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            sx={{marginBottom: "10px"}}
                                                            checked={formik.values.ends}
                                                            value={formik.values.ends}
                                                            control={<Radio disableRipple />}
                                                            label={
                                                                <Typography sx={{fontSize: "0.938rem", fontWeight: 400, color: "#25272B"}}>
                                                                    No end date
                                                                </Typography>
                                                            }
                                                        />
                                                    </RadioGroup>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} mt={4}>
                                        <ButtonGrouping
                                            btnprops={[
                                                {
                                                    btnTitle: "Cancel",
                                                    sx: (t) => ({color: t.palette.error.dark}),
                                                    onClick: (e) => {
                                                        formik.resetForm();
                                                        navigate("/customers?tab=customer");
                                                    }
                                                },
                                                {
                                                    btnTitle: "Save",
                                                    variant: "contained",
                                                    sx: (t) => ({color: t.palette.background.paper}),
                                                    onClick: (e) => {
                                                        formik.handleSubmit();
                                                    }
                                                }
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Formik>
            ) : (
                <PreferredPricingView />
            )}
        </>
    );
}

export default PreferredPricing;
