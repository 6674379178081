import {Grid} from "@mui/material";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import useTempDrawer from "hooks/useTempDrawer";
import React, {useState, useEffect} from "react";
import Createmarket from "./CreateMarket/Createmarket";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import CommonTable from "commonComponent/TableSection/CommonTable";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import {useDispatch, useSelector} from "store";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import Filters from "commonComponent/Filters";
import {generateUrl} from "utils/helperFunctions/helpers";
import FilterChips from "commonComponent/FilterChipsComponent";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import Filter from "commonComponent/FilterAccoridan";
import {setPageNumber, setRowsPerPage, setShowFilter, setOrder, setOrderBy} from "store/slices/paginationSlice";

const Markets = () => {
    const {toggleDrawer} = useTempDrawer();
    const [marketListData, setMarketListData] = useState([]);
    const [open, setOpen] = useState(false);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const [search, setSearch] = useState("");
    const currentOrder = useSelector((state) => state?.pagination?.order || "asc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "market_name");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const filterChips = useSelector((state) => state?.pagination?.filterChips);
    const openFilter = useSelector((state) => state?.pagination?.showFilter);

    const [searchedValue, setSearchedValue] = useState({});
    const [state, setState] = useState([]);
    const dispatch = useDispatch();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const handleCreatemarket = (btntype) => (e) => {
        switch (btntype) {
            case "Create market": {
                dispatch(setShowFilter(false));
                toggleDrawer("right", "createMarket")(e);
                break;
            }
            case "marketFilter": {
                dispatch(setShowFilter(!openFilter));

                // setOpenFilter((prev) => !prev);
                // toggleDrawer("right", "marketFilter")(e);
                break;
            }
            default:
                break;
        }
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };
    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handleStatus = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api

        const instance = NetworkManager(API.MARKET.UPDATE(`status/${values.market_id}`));
        let isActive = false;
        if (type === "Deactivate") {
            isActive = false;
        } else {
            isActive = true;
        }
        const payload = {
            status: isActive
        };

        const response = await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                dispatch(setShowFilter(false));
                toggleDrawer("right", "createMarket")(e);
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            case "Deactivate": {
                handleStatus(row, type);
                break;
            }
            case "Activate": {
                handleStatus(row, type);
                break;
            }
            default:
                break;
        }
    };

    const getMarketList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.MARKET.GET);
        const params = {
            page: currentPage,
            pageSize: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };
        if (currentOrderBy) {
            params.sortBy = currentOrderBy;
            params.orderBy = currentOrder;
        }
        // while searching we dont need page number
        if (search === "") {
            params.page = currentPage;
        }

        if (search !== "") {
            params.market_name = search;
            setMarketListData([]);
        }

        const url = generateUrl(params);
        const response = await instance.request({}, [url]);

        if (response.success) {
            const tempData = [];
            response.data.marketList.forEach((element) => {
                const obj = element;
                obj.cityName = element.city?.name;
                obj.stateName = element.state?.name;
                tempData.push(obj);
            });

            setMarketListData(tempData);
            setTotalItems(response.data.pagination.totalItems);
            // setCurrentPage(response.data.pagination.currentPage-1)

            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.MARKET.DELETE(selectedRow.market_id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Market Deleted Successfully",
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getMarketList();
    };

    const handleSearch = (val) => {
        setSearch(val);
        dispatch(setPageNumber(1));
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
        // setCurrentPage(number + 1);
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        // setRowsPerPage(number);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        dispatch(setOrder(val1));
        dispatch(setOrderBy(val2));
    };
    const getState = async () => {
        let tempData = [];
        const instance = NetworkManager(API.USER.STATE);
        const response = await instance.request();
        if (response.success) {
            const states = response.data.stateList
                .sort((a, b) => {
                    const labelA = a.name.toLowerCase(); // Ensure case-insensitive sorting
                    const labelB = b.name.toLowerCase();
                    return labelA.localeCompare(labelB);
                })
                .map((state) => ({
                    label: state.name,
                    value: state.name,
                    id: state.state_id
                }));
            tempData = states;
        }

        setState(tempData);
    };
    useEffect(() => {
        getMarketList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, filterChips]);
    useEffect(() => {
        getState();
    }, []);

    // const getCity = async () => {
    //     let tempData = [];
    //     const instance = NetworkManager(API.USER.CITY());
    //     const response = await instance.request();
    //     if (response.success) {
    //         const cities = response.data.cityList.map((city) => ({
    //             label: city.name,
    //             value: city.name,
    //             id: city.city_id
    //         }));
    //         tempData = cities;
    //     }
    //     return tempData;
    // };

    const filterState = [
        // {
        //     key: "market_name",
        //     label: "Market name",
        //     type: "textfield"
        // },
        // {
        //     key: "city",
        //     label: "City",
        //     type: "select",
        //     getData: getCity
        // },
        {
            key: "state",
            label: "State",
            type: "multiselect",
            getData: state
        }
    ];

    const handleApplyFilter = (Morefilters) => {
        // if (Morefilters?.market_ids) {
        //     Morefilters.market_ids = Morefilters.market_ids.join(",")
        // }
        dispatch(setShowFilter(false));
        dispatch(setPageNumber(1));
    };

    return (
        <>
            <Grid container>
                {/* <Filters
                    sideBarStateName="marketFilter"
                    filterState={filterState}
                    filterChips={filterChips}
                    setFilterChips={setFilterChips}
                    handleApplyFilter={handleApplyFilter}
                /> */}

                <Createmarket
                    sideBarStateName="createMarket"
                    handleIsEdited={handleIsEdited}
                    selectedRow={selectedRow}
                    handleSelectedRow={handleSelectedRow}
                />

                <Grid item xs={12}>
                    <TableTopFilterWrapper
                        lessmarginOnTop
                        pageheader={<PageHeader title="Markets" />}
                        btnTitle={WithPermission({module: "Markets", permission: "create_permission"}) && "Create market"}
                        handleClick={handleCreatemarket}
                        handleSearch={handleSearch}
                        filterType="marketFilter"
                    >
                        {openFilter && (
                            <Filter
                                filterState={filterState}
                                // setFilterState={setFilterChips}
                                filterChips={filterChips}
                                setSearchedValue={setSearchedValue}
                                showFilter={openFilter}
                            />
                        )}
                        <FilterChips
                            filterChips={filterChips}
                            // setFilterChips={setFilterChips}
                            filterState={filterState}
                        />
                        <CommonTable
                            loading={loading}
                            data={marketListData}
                            header={[
                                {key: "market_name", label: "Market"},
                                {key: "stateName", label: "State"},
                                {key: "locationCount", label: "Locations"},
                                {key: "createdAt", label: "Created"},
                                {key: "is_active", label: "Status"},

                                WithPermission({module: "Markets", permission: ["update_permission", "delete_permission"]}) && {
                                    key: "action",
                                    label: "Action"
                                }
                            ].filter((el) => el)}
                            actions={[
                                WithPermission({module: "Markets", permission: "update_permission"}) && "Edit",
                                WithPermission({module: "Markets", permission: "delete_permission"}) && "Delete"
                            ]}
                            handleActionBtn={handleActionBtn}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsChange}
                            handleSort={handleSort}
                            totalItems={totalItems}
                            rowsPerPage={rowsPerPage}
                            currentPage={currentPage - 1}
                            sortingLabel={["Market", "State", "Locations", "Status", "Created"]}
                        />
                    </TableTopFilterWrapper>
                </Grid>
            </Grid>

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this market?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
        </>
    );
};

export default Markets;
