import { NetworkManager, API } from "network/core";

export const useMenuModel = () => {
    const getBrandList = async (params) => {
        const instance = NetworkManager(API.BRAND.GET);
        const response = await instance.request({}, params);
        return response;
    };

    const getCategoryList = async (params) => {
        const instance = NetworkManager(API.CATEGORY.GET);
        const response = await instance.request({}, params);
        return response;
    };
    const getTemplateFilterList = async (params) => {
        const instance = NetworkManager(API.TEMPLATE.GET);
        const response = await instance.request({}, params);
        return response;
    };
    const getMenuList = async (params) => {
        const instance = NetworkManager(API.MENU.GET);
        const response = await instance.request({}, params);
        return response;
    };

    const getCuisine = async (page = 1, pageSize = 5000) => {
     
        const instance = NetworkManager(API.BRAND.GET);
        const params = {
            page: page,
            pageSize: pageSize,
            sortBy: "cusine",
            orderBy: "asc"
        };
        const response = await instance.request({}, params);
        return response
    };
    return { getBrandList, getCuisine, getTemplateFilterList, getMenuList,getCategoryList };
};
