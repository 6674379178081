import {Grid} from "@mui/material";
import React, {useState, useEffect} from "react";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import CustomizedTabs from "commonComponent/NewTab";
import useMenuDetailController from "./menuDetail.controlller";
import {useLocation, useSearchParams, useParams} from "react-router-dom";
import {CsvBuilder} from "filefy";
import {getFieldsForDownloadMenuItems} from "commonComponent/Apptable/getFieldsForDownload";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import FilterChips from "commonComponent/FilterChipsComponent";
import Filter from "commonComponent/FilterAccoridan";
import {setFilterChips} from "store/slices/paginationSlice";

const MenuDetail = () => {
    const MC = useMenuDetailController();
    const [searchParams] = useSearchParams();
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [updateListCount, setUpdateListCount] = useState(1);
    const location = useLocation();
    const ModifierGroupID = searchParams.get("id");
    const tabname = searchParams.get("tab");
    const brandId = searchParams.get("brandId");
    const menuName = searchParams.get("menuName");
    const isModifierTab = searchParams.get("tab") === "modifierGroup";
    const isItemTab = searchParams.get("tab") === "menuItems";
    const CanclePath = `${location.pathname}?tab=${tabname}`;
    const dispatch = useDispatch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const edit = urlSearchParams.get("edit");
    const add = urlSearchParams.get("add");
    const prm = useParams();
    const {menuid, type} = prm;
    const [breadScrumsFinalData, setBreadScrumsData] = useState([]);
    const generateBreadScrumData = () => {
        const breadScrumsData = [];
        if (brandId) {
            // breadScrumsData.push({label: "Brand", href: "/brands"});
            breadScrumsData.push({
                label: "Menus",
                href: `/brand-detail/${brandId}?tab=menu&brandName=${menuName}`
            });
            dispatch(setFilterChips({}));
        } else {
            breadScrumsData.push({label: "Menus", href: "/menus?tab=menu"});
        }

        if (isItemTab) {
            breadScrumsData.push({label: `View menu items`, isActive: true});
        }
        if (isModifierTab) {
            breadScrumsData.push({
                label: `View modifier groups`,
                ...(ModifierGroupID && {href: CanclePath}),
                isActive: !ModifierGroupID
            });
            if (ModifierGroupID) {
                breadScrumsData.push({label: "Modifier group detail", isActive: true});
            }
        } else if (!isItemTab) {
            if (MC.breadCrumb === "View menu" && edit === "yes") {
                breadScrumsData.push({label: "View menu", href: `/menus/menuDetail/${menuid}?tab=menu&menuName=${menuName}`});
                // breadScrumsData.push({label: `${MC.breadCrumb}`, isActive: true});
            } else if (add === "yes") {
                breadScrumsData.push({label: `Create menu`, isActive: true});
            } else {
                breadScrumsData.push({label: `${MC.breadCrumb}`, isActive: true});
            }
        }
        // if(add === "yes") {
        //     breadScrumsData.push({label: `Create menu`, isActive: true});
        // }
        if (edit === "yes") {
            breadScrumsData.push({label: `Edit menu`, isActive: true});
        }
        setBreadScrumsData([...breadScrumsData]);
    };

    const handleDeleteBulk = async () => {
        const instance = NetworkManager(API.ITEM.BULKDELETE);

        const payload = MC.selectedCheckbox.map((row) => {
            const list = JSON.parse(row);
            return list.item_id;
        });

        const response = await instance.request({itemId: payload.join(",")});
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            MC.setSelectedCheckbox([]);
            MC.setUpdateListCount((pre) => pre + 1);
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        MC.setSelectedCheckbox([]);
        // getUnplacedOrderList();
    };

    const columnData = [
        {key: "item_name", label: "Menu item name"},
        {key: "menus", label: "Menu"},
        {key: "services", label: "Service"},
        {key: "createdAt", label: "Created"},
        {key: "status", label: "Status"}
    ];

    const csvBuilder = () => {
        new CsvBuilder("item_list.csv")
            .setColumns(columnData.map((col) => col.label))
            .addRows(
                MC.selectedCheckbox.map((row) =>
                    columnData.map((col) => {
                        const arr = JSON.parse(row);
                        return getFieldsForDownloadMenuItems(col.key, arr);
                    })
                )
            )
            .exportFile();
        MC.setSelectedCheckbox([]);
    };

    useEffect(() => {
        generateBreadScrumData();
    }, [isItemTab, isModifierTab, brandId, edit]);
    return (
        <Grid container>
            <Grid item xs={12}>
                <TableTopFilterWrapper
                    {...((MC.value === "menu" || ModifierGroupID) && {
                        onlyBtn: true
                    })}
                    {...(MC.value === "menu" &&
                        MC.editMenuOrNot === "yes" && {
                            onlyHeading: true
                        })}
                    {...(add === "yes" && {
                        onlyHeading: true
                    })}
                    lessmarginOnTop
                    pageheader={
                        <PageHeader
                            title={MC.headerName}
                            {...(MC.value && {
                                breadsCrumData: breadScrumsFinalData
                            })}
                        />
                    }
                    btnTitle={MC.label}
                    handleClick={MC.handleCreateLocation}
                    handleSearch={MC.handleSearch}
                    filterType="none"
                    orderFilter="orderFilter"
                    selectedCheckbox={MC.selectedCheckbox}
                    handleDeleteBulk={WithPermission({module: "Items", permission: "delete_permission"}) && handleDeleteBulk}
                    deleteBulkTitle="items"
                    csvBuilder={csvBuilder}
                >
                    <Filter
                        filterState={MC.getFilterState(MC.value)}
                        filterChips={MC.filterChips}
                        // setFilterState={MC.setFilterChips}
                        showFilter={MC.showFilter}
                        isChild={type === "menuDetail"}
                    />
                    <FilterChips
                        filterChips={MC.filterChips}
                        // setFilterChips={MC.setFilterChips}
                        filterState={MC.getFilterState(MC.value)}
                        isChild={type === "menuDetail"}
                    />
                    <CustomizedTabs tabsInfo={MC.dummyData} value={MC.value} handleChange={MC.handleChange} />
                </TableTopFilterWrapper>
            </Grid>
        </Grid>
    );
};

export default MenuDetail;
