// eslint-disable-next-line import/no-unresolved
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import { firebasePublicKey } from "config";

const firebaseConfig = {
    apiKey: "AIzaSyARG8B9XJcQ1e2iSyl_sgemUnKd-63Z8c0",
    authDomain: "bite-time.firebaseapp.com",
    projectId: "bite-time",
    storageBucket: "bite-time.appspot.com",
    messagingSenderId: "973015315682",
    appId: "1:973015315682:web:b6dfb3804c94c64c601abb",
    measurementId: "G-KWBB3XDV8X"
  };

firebase.initializeApp(firebaseConfig);



const setupToken = async () => {
  try {
    const messaging = firebase.messaging();

    messaging.onMessage((payload) => {
      navigator.serviceWorker
        .getRegistration("/firebase-cloud-messaging-push-scope")
        .then((registration) => {
          registration.showNotification(payload.notification.title, {
            ...payload.notification,
            data: { url: payload.data.type },
          });
        });
    });
    const token = await messaging.getToken({ vapidKey: firebasePublicKey });
    if (token) {
      return token;
    }
  } catch (e) {
    console.log(e)
    return "";
  }
  return ""
};

const DeleteFirebaseToken = async () => {
  try {
    const messaging = firebase.messaging();
    messaging.deleteToken();
  } catch (e) {
    return null;
  }
  return null
};

setupToken();

let handleNotification;
const checkPermission = async(permissionData) => {
 
  if (permissionData.permission === "default") {
   
    try {window.safari.pushNotification.requestPermission(
      "https://api.dev.bitetime.foxlabs.in/push",
      "web.in.foxlabs.bitetime.admin.dev",
      {panel:"admin"},
      checkPermission
    )}
    catch(err){
      console.log(err)
      return ""
    }
  } else if (permissionData.permission === "denied") {
    console.log("denied")
    return ""
  } else if (permissionData.permission === "granted") {
    // console.log("granted")
    handleNotification(permissionData.deviceToken);
  }
  return ""
};


const handlePermission = (callback) => {
  handleNotification = callback;
  if (window.safari && window.safari.pushNotification) {
    
    const result = window.safari.pushNotification.permission(
      "web.in.foxlabs.bitetime.admin.dev"
    );
    
    checkPermission(result);
  }
  return null
};



export { setupToken, DeleteFirebaseToken, handlePermission, checkPermission };
