/* eslint-disable no-lonely-if */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import React from "react";
import {sorter} from "utils/helperFunctions/helpers";

const useMainSectionController = () => {
    const handleManualTotal = (hasManualChangeMap) => {
        return Array.from(hasManualChangeMap.values())
            .map((item) => Number(item?.quantity))
            .reduce((partialSum, a) => partialSum + a, 0);
    };

    const getSum = (jsMap) => {
        let totalSum = 0;
        jsMap.forEach((value) => {
            totalSum += Number(value?.quantity);
        });

        return totalSum;
    };

    const getNewValue = (newMod, minimumValue) => {
        let newValue;
        if (!minimumValue || (minimumValue && minimumValue <= newMod)) {
            newValue = newMod;
        } else if (minimumValue && minimumValue > newMod) {
            newValue = minimumValue;
        } else {
            newValue = 1;
        }
        return newValue;
    };

    const setter = (...params) => {
        let [restItem, itemIndex, average, difference, hasNotManualChangeMap, object, formik, manuallyChangedArray = [], isRemove = false] =
            params;
        /** @mehran-nickelfox
         * This Function is used to update the modifier selection and divide the value amongst the modifiers
         */

        const requiredMap = new Map();
        const hasNotArray = sorter(Array.from(hasNotManualChangeMap.values()), "sortByKey");
        hasNotArray.forEach((item) => {
            requiredMap.set(item?.modifierId, item);
        });
        for (const [key, value] of requiredMap) {
            if (difference > 0) {
                requiredMap.set(key, {...value, quantity: average > 0 ? average + 1 : 1});
                difference--;
            } else {
                const quantity = average > 0 ? average : 1;
                requiredMap.set(key, {...value, quantity: quantity});
            }
        }
        const sortedArr = sorter([...Array.from(requiredMap.values()), ...manuallyChangedArray], "sortByKey");

        formik.setFieldValue(`itemDetail[${itemIndex}].checkboxModifier`, [...sortedArr, ...restItem]);
    };

    const handleSplitting = (itemDetail, itemIndex, object, formik, currentRow) => {
        const selectedModifier = [];

        const total = itemDetail.quantity;
        const restItems = [];
        itemDetail.checkboxModifier.forEach((checkBoxSelection) => {
            if (checkBoxSelection.modifierGroupId === object?.modifier_group_id) {
                selectedModifier.push(checkBoxSelection);
            } else {
                restItems.push(checkBoxSelection);
            }
        });
        const hasManualChangeMap = new Map();
        const hasNotManualChangeMap = new Map();

        for (let i = 0; i < selectedModifier?.length; i++) {
            const item = selectedModifier[i];
            if (item?.manuallyChanged) {
                hasManualChangeMap.set(item?.modifierId, {...item, sortByKey: item?.series_no});
            } else {
                hasNotManualChangeMap.set(item?.modifierId, {...item, manuallyChanged: false, sortByKey: item?.series_no});
            }
        }
        const requiredObject = {
            modifierGroupId: object.modifier_group_id,
            modifierId: object.modifier_id,
            itemId: currentRow.item_id,
            quantity: itemDetail?.quantity ? itemDetail?.quantity : 1,
            price: object?.modifier?.price,
            modifierGroupName: currentRow.modifier_group_name,
            series_no: object?.modifier?.series_no,
            allowQuantityModification: currentRow?.modifierGroup?.allow_quantity_modification,
            splitModifier: true,
            modifier: {minimum_value: object?.modifier?.minimum_value ?? 1}
        };
        hasNotManualChangeMap.set(object?.modifier_id, requiredObject);

        let manuallyChangedQuantity = 0;

        if (hasManualChangeMap?.size > 0) {
            manuallyChangedQuantity = handleManualTotal(hasManualChangeMap);
        }
        let difference = 0;
        console.log(manuallyChangedQuantity, total, "105105");
        if (manuallyChangedQuantity === total) {
            const manuallyChangedArray = Array.from(hasManualChangeMap.values());
            const newTotal = total;
            const average = Math.floor(newTotal / (manuallyChangedArray.length + hasNotManualChangeMap?.size));
            difference = newTotal - average * (manuallyChangedArray.length + hasNotManualChangeMap?.size);
            const combinedArray = sorter([...manuallyChangedArray, ...Array.from(hasNotManualChangeMap.values())], "sortByKey");
            for (const entry of combinedArray) {
                if (difference > 0) {
                    entry.quantity = Number(average) + 1;
                    entry.manuallyChanged = false;
                    difference--;
                } else {
                    entry.quantity = Number(average);
                    entry.manuallyChanged = false;
                }
            }
            formik.setFieldValue(`itemDetail[${itemIndex}].checkboxModifier`, [...combinedArray, ...restItems]);

            return;
        }
        if (manuallyChangedQuantity === 0) {
            const average = Math.floor(total / hasNotManualChangeMap.size);
            /** @mehran-nickelfox
             * IF the modifiers hasnt been manually updated this blck will be used
             */
            difference = total - average * hasNotManualChangeMap.size;
            setter(restItems, itemIndex, average, difference, hasNotManualChangeMap, object, formik, []);
        } else {
            /** @mehran-nickelfox
             * IF the modifiers has been manually updated this blck will be used
             */
            const manuallyChangedArray = Array.from(hasManualChangeMap.values());
            const combinedArray = sorter([...manuallyChangedArray, ...Array.from(hasNotManualChangeMap.values())], "sortByKey");
            const combinedArraySum = combinedArray
                .map((item) => item?.quantity)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

            if (combinedArraySum > total) {
                const average = Math.floor(total / combinedArray?.length);
                difference = total - average * combinedArray?.length;
                for (const entry of combinedArray) {
                    if (difference > 0) {
                        entry.quantity = Number(average) + 1;
                        entry.manuallyChanged = false;
                        difference--;
                    } else {
                        entry.quantity = Number(average);
                        entry.manuallyChanged = false;
                    }
                }

                formik.setFieldValue(`itemDetail[${itemIndex}].checkboxModifier`, combinedArray);
            } else {
                if (hasNotManualChangeMap?.size > 0) {
                    const newTotal = total - manuallyChangedQuantity;
                    const average = Math.floor(newTotal / hasNotManualChangeMap.size);
                    difference = newTotal - average * hasNotManualChangeMap?.size;
                    setter(restItems, itemIndex, average, difference, hasNotManualChangeMap, object, formik, manuallyChangedArray);
                }
            }
        }
    };

    const calculateManuallyChangedArray = (...params) => {
        let [restItems, itemIndex, hasNotManualChangeMap, manuallyChangedArray, average, difference, object, formik, total] = params;
        if (hasNotManualChangeMap?.size === 0 && manuallyChangedArray?.length === 1) {
            manuallyChangedArray[0].quantity = total;
        } else if (hasNotManualChangeMap?.size === 0 && manuallyChangedArray?.length > 1) {
            average = Math.floor(total / manuallyChangedArray?.length);
            difference = total - average * manuallyChangedArray?.length;
            for (const entry of manuallyChangedArray) {
                if (difference > 0) {
                    entry.quantity = Number(average) + 1;
                    entry.manuallyChanged = false;
                    difference--;
                } else {
                    entry.quantity = Number(average);
                    entry.manuallyChanged = false;
                }
            }
        }
        // setter(restItems, itemIndex, average, difference, hasNotManualChangeMap, object, formik, manuallyChangedArray, true);
        setter(restItems, itemIndex, average, difference, hasNotManualChangeMap, object, formik, manuallyChangedArray);
    };

    const defineConditions = (...params) => {
        /** @mehran-nickelfox
         * Destructuring params to keep code clean
         */
        let [
            restItems,
            itemIndex,
            manuallyChangedQuantity,
            difference,
            total,
            average,
            hasNotManualChangeMap,
            hasManualChangeMap,
            object,
            formik
        ] = params;

        if (manuallyChangedQuantity === 0) {
            difference = total - average * hasNotManualChangeMap?.size;
            setter(restItems, itemIndex, average, difference, hasNotManualChangeMap, object, formik, [], true);
        } else if (manuallyChangedQuantity > 0) {
            const manuallyChangedArray = Array.from(hasManualChangeMap.values());
            const newTotal = total - manuallyChangedQuantity;
            difference = newTotal - average * hasNotManualChangeMap?.size;
            calculateManuallyChangedArray(
                restItems,
                itemIndex,
                hasNotManualChangeMap,
                manuallyChangedArray,
                average,
                difference,
                object,
                formik,
                total
            );
        }
    };

    const handleRemoveModifier = (itemDetail, itemIndex, object, formik) => {
        /**
         * @mehran-nickelfox: This is used to remove the selected modifiers affecting the calculation for not manually changed modifiers
         */
        const total = itemDetail.quantity;

        const selectedModifier = [];
        const restItems = [];
        itemDetail.checkboxModifier.forEach((checkBoxSelection) => {
            if (checkBoxSelection.modifierGroupId === object?.modifier_group_id) {
                selectedModifier.push(checkBoxSelection);
            } else {
                restItems.push(checkBoxSelection);
            }
        });
        const hasManualChangeMap = new Map();
        const hasNotManualChangeMap = new Map();
        for (let i = 0; i < selectedModifier?.length; i++) {
            const item = selectedModifier[i];
            if (item?.manuallyChanged) {
                hasManualChangeMap.set(item?.modifierId, {...item, sortByKey: item?.series_no});
            } else {
                hasNotManualChangeMap.set(item?.modifierId, {...item, sortByKey: item?.series_no});
            }
        }
        if (hasNotManualChangeMap.has(object?.modifier_id)) {
            /**
             * @mehran-nickelfox This code block runs when the id is in the modifers whose value hasnt been changed
             */
            let manuallyChangedQuantity = 0;
            if (hasManualChangeMap?.size > 0) {
                manuallyChangedQuantity = handleManualTotal(hasManualChangeMap);
            }
            const quantityToDivide = total - manuallyChangedQuantity;
            hasNotManualChangeMap.delete(object?.modifier_id);
            let average = Math.floor(quantityToDivide / hasNotManualChangeMap?.size);
            let difference = 0;

            defineConditions(
                restItems,
                itemIndex,
                manuallyChangedQuantity,
                difference,
                total,
                average,
                hasNotManualChangeMap,
                hasManualChangeMap,
                object,
                formik
            );
        } else if (hasManualChangeMap.has(object?.modifier_id)) {
            /**
             * @mehran-nickelfox This code block runs when the id is in the modifers whose value has been changed
             */

            hasManualChangeMap.delete(object?.modifier_id);
            let manuallyChangedQuantity = 0;
            if (hasManualChangeMap?.size > 0) {
                manuallyChangedQuantity = handleManualTotal(hasManualChangeMap);
            }
            const quantityToDivide = total - manuallyChangedQuantity;
            let average = Math.floor(quantityToDivide / hasNotManualChangeMap?.size);
            let difference = 0;
            defineConditions(
                restItems,
                itemIndex,
                manuallyChangedQuantity,
                difference,
                total,
                average,
                hasNotManualChangeMap,
                hasManualChangeMap,
                object,
                formik
            );
        }
    };

    const handleModifierQuantityChange = (e, itemDetail, itemIndex, object, formik, currentRow) => {
        const indexChangedSet = new Map();
        const selectedItems = [];
        const restItems = [];
        const thisIndexAllSelections = itemDetail?.checkboxModifier;

        const total = itemDetail.quantity;
        for (const item of thisIndexAllSelections) {
            if (object?.modifier_group_id === item?.modifierGroupId) {
                selectedItems.push(item);
            } else {
                restItems.push(item);
            }
        }

        for (const item of selectedItems) {
            if (item.manuallyChanged) {
                indexChangedSet.set(item?.modifierId, item);
            }
        }
        /**
         * @mehran-nickelfox
         * This Block will be executed when split_modifiers is tru
         * Purpose To Update the values of selected modifiers
         * How: See the steps below
         */

        /**
         * @Step1: This adds the current item to changed index set if value is being changed
         * Why? To persist changes if any other value of modifier is updated
         */

        const requiredObject = {
            modifierGroupId: object.modifier_group_id,
            modifierId: object.modifier_id,
            itemId: currentRow.item_id,
            quantity: e?.target?.value,
            price: object?.modifier?.price,
            modifierGroupName: currentRow.modifier_group_name,
            series_no: object?.modifier?.series_no,
            allowQuantityModification: currentRow?.modifierGroup?.allow_quantity_modification,
            manuallyChanged: true,
            modifier: {minimum_value: object?.modifier?.minimum_value ?? 1}
        };
        indexChangedSet.set(object?.modifier_id, requiredObject);

        /**
         * @Step2: This here gets the sum of all the persisited value.
         * Purpose: to calculate the difference from the total count.
         */
        const sum = getSum(indexChangedSet);
        const diff = total - Number(sum);
        /**
         * Step3: This here gets the array of all unupdated Values:
         * Purpose: So that the diff can be equally divided in the remaining modifiers.
         */
        const newSumArr = [];
        selectedItems.forEach((item, index) => {
            if (!indexChangedSet.has(item?.modifierId)) {
                newSumArr.push({
                    ...item,
                    id: index,
                    sortByKey: item?.modifier?.series_no,
                    quantity: item?.quantity,
                    manuallyChanged: item?.manuallyChanged || false
                });
            }
        });
        const requiredArr = sorter(newSumArr, "sortByKey");
        /**
         * @Step4: The new mod is the equal distributuion of the diff
         * And we are using Math.floor() because we want to get the whole number
         * And when we subtract the whole number from diff we can get difference or 0
         */
        const newMod = Math.floor(diff / requiredArr?.length) < 0 ? 0 : Math.floor(diff / requiredArr?.length);
        const lowerBound = newMod * requiredArr?.length;
        const difference = Number(diff) - Number(lowerBound);
        /**
         * @Step4: If difference is zero the we are just updating the value to new Mod bewing the whole number
         */
        if (difference === 0) {
            for (let i = 0; i < requiredArr?.length; i++) {
                const item = requiredArr[i];
                item.quantity = newMod <= 0 ? 1 : newMod;
            }
        } else {
            /**
             * IMPORTANT: @Step5: Now here what we are doing
             * If we get a difference the function will loop around the arrau equally distributing the difference to the modifiers whose index is less than
             * differnce. Making the total calculation go upto the forHowMany.i.e  the total actual quantity
             */
            let newDiff = difference;
            for (let i = 0; i < requiredArr?.length; i++) {
                const item = requiredArr[i];
                if (newDiff > 0) {
                    const modValue = newMod + 1;
                    item.quantity = modValue <= 0 ? 1 : modValue;
                    newDiff--;
                } else {
                    item.quantity = newMod <= 0 ? 1 : newMod;
                }
            }
        }
        // }
        const mapValueArr = Array.from(indexChangedSet.values());
        const updatedArray = sorter([...mapValueArr, ...requiredArr, ...restItems], "sortByKey");
        formik.setFieldValue(`itemDetail[${itemIndex}].checkboxModifier`, updatedArray);
    };

    const addModifier = (itemDetail, itemIndex, obbj, row2, formik) => {
        const tempValue = [...itemDetail[itemIndex].checkboxModifier];
        tempValue.push({
            modifierGroupId: obbj.modifier_group_id,
            modifierId: obbj.modifier_id,
            itemId: row2.item_id,
            quantity: itemDetail[itemIndex]?.quantity || 1,
            price: obbj?.modifier?.price,
            modifierGroupName: row2.modifier_group_name,
            series_no: obbj?.modifier?.series_no,
            allowQuantityModification: row2?.modifierGroup?.allow_quantity_modification
        });
        formik.setFieldValue(`itemDetail[${itemIndex}].checkboxModifier`, tempValue);
    };

    const blurValueSetter = (selectedItems, object, total, restItems, formik, itemIndex) => {
        const currentItemMap = new Map();

        const restItemMap = new Map();
        const halOfTotal = Number(total) / 2;
        for (let i = 0; i < selectedItems?.length; i++) {
            const item = selectedItems[i];
            if (item?.modifierId === object?.modifier_id) {
                currentItemMap.set(item?.modifierId, {...item, sortByKey: item?.series_no, splitModifier: true});
            } else {
                const newValue = item?.modifier?.minimum_value < item.quantity ? item?.quantity : item?.modifier?.minimum_value ?? 1;
                restItemMap.set(item?.modifierId, {
                    ...item,
                    quantity: newValue,
                    sortByKey: item?.series_no,
                    manuallyChanged: false
                });
            }
        }

        // Fix here

        const restItemAverage = Math.floor(halOfTotal / restItemMap.size);
        let restItemDiff = halOfTotal - restItemAverage * restItemMap.size;

        for (let [key, value] of restItemMap) {
            if (restItemDiff > 0) {
                value.quantity = restItemAverage + 1;
                restItemDiff--;
            } else {
                value.quantity = restItemAverage;
            }
        }

        currentItemMap.set(object?.modifier_id, {
            ...currentItemMap.get(object?.modifier_id),
            quantity: Math.floor(halOfTotal),
            manuallyChanged: false
        });
        const updatedArray = sorter(
            [...Array.from(restItemMap.values()), ...Array.from(currentItemMap.values()), ...restItems],
            "sortByKey"
        );
        formik.setFieldValue(`itemDetail[${itemIndex}].checkboxModifier`, updatedArray);
    };

    const handleBlurFunction = (itemDetail, itemIndex, object, formik) => {
        const selectedItems = [];
        const restItems = [];
        const thisIndexAllSelections = itemDetail?.checkboxModifier;
        const total = itemDetail.quantity;
        for (const item of thisIndexAllSelections) {
            if (object?.modifier_group_id === item?.modifierGroupId) {
                selectedItems.push(item);
            } else {
                restItems.push(item);
            }
        }
        const mappedArray = selectedItems.map((item) => {
            const checkValue = Number(item?.quantity);
            return {...item, quantity: Number.isNaN(checkValue) ? 1 : checkValue};
        });
        const getSumofMapppedArray = mappedArray
            .map((item) => item?.quantity)
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        let diff = getSumofMapppedArray - total;
        if (diff > 0) {
            blurValueSetter(selectedItems, object, total, restItems, formik, itemIndex);
        }
    };

    const handleSplitEqually = (itemDetail, itemIndex, formik, inputValue) => {
        const checkboxModifiers = itemDetail?.checkboxModifier;

        const splitArray = [];
        const restItemsArray = [];

        for (const item of checkboxModifiers) {
            if (item?.splitModifier) {
                splitArray?.push(item);
            } else {
                restItemsArray.push(item);
            }
        }
        const modifierGroupMap = new Map();
        splitArray.forEach((splitItem) => {
            if (!modifierGroupMap.has(splitItem?.modifierGroupId)) {
                modifierGroupMap.set(splitItem?.modifierGroupId, [splitItem]);
            } else {
                const previousArray = modifierGroupMap.get(splitItem?.modifierGroupId);
                modifierGroupMap.set(splitItem?.modifierGroupId, [...previousArray, splitItem]);
            }
        });

        if (inputValue !== "") {
            for (const [key, value] of modifierGroupMap) {
                let average;
                let difference;
                if (Number(inputValue) === 1) {
                    value.forEach((item) => {
                        item.quantity = 1;
                    });
                } else {
                    average = Math.floor(Number(inputValue) / value?.length);
                    const lowerBound = average * value?.length;
                    difference = Number(inputValue) - lowerBound;
                    for (const entry of value) {
                        if (difference > 0) {
                            entry.quantity = average + 1;
                            difference--;
                        } else {
                            entry.quantity = average;
                        }
                    }
                }
            }
        }
    };
    const getSplitCheck = (currentRow, itemDetail = []) => {
        const currentSelectArr = itemDetail.filter((item) => item?.modifierGroupId === currentRow?.modifierGroup?.modifier_group_id);
        if (currentRow?.modifierGroup?.split_modifiers)
            if (currentSelectArr?.length > 1) {
                return true;
            } else {
                return false;
            }
        else {
            return true;
        }
    };

    return {handleSplitting, handleRemoveModifier, handleModifierQuantityChange, handleBlurFunction, handleSplitEqually, getSplitCheck};
};

export default useMainSectionController;
