import {AppBar, Box, IconButton, Toolbar} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import DelivarySection from "./DelivarySection";

const HeaderSection = (props) => {
    const {drawerWidth, handleDrawerToggle, formik,orderId,  customerName, customerId} = props;

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    width: {sm: `calc(100% - ${drawerWidth}px)`},
                    ml: {sm: `${drawerWidth}px`},
                    boxShadow: "none",
                    borderBottom: "1px solid #E0E2E6",
                    backgroundColor:'#ffff'
                }}
            >
                <Toolbar sx={{bgcolor: "#fff"}}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => handleDrawerToggle()}
                        sx={{mr: 2, display: {sm: "none"}}}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Box
                        sx={{
                            minWidth: 0
                        }}
                    >
                        <DelivarySection formik={formik} orderId={orderId} customerName={customerName}
                                            customerId={customerId}  />
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default HeaderSection;
