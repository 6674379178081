import React, {useState, useEffect} from "react";
import {Grid, Typography, Box, Stack, Button, Divider} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Paid from "assets/PAID.svg";
import {NetworkManager, API} from "network/core";
import {useLocation, useSearchParams} from "react-router-dom";
import Loader from "ui-component/Loader";
import {FormatCurrency, checkforPerfectNumber, FormatPhoneNumber, commaFormattedNumber} from "utils/helperFunctions/helpers";
import {useStyles} from "../../../OrderQuoteDetail/OrderView/styles";
import Cancel from "assets/images/icons/cancel.svg";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";
import {truncate} from "lodash";
import logo from "assets/images/logo.svg";

function PrintOrder({orderDetailId, handleClose, handleOrderClick}) {
    const [quoteListData, setQuoteListData] = useState(null);
    const [bannerClr, setBannerClr] = useState("#5ADA92");
    const [bannerBackgroundClr, setBannerBackgroundClr] = useState("rgba(237, 255, 245, 0.5)");
    const [loading, setLoading] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParams] = useSearchParams();
    const [itemDetailSorted, setItemDetailSorted] = useState(null);
    const [secondaryDriver, setSecondaryDriver] = useState(null);
    const location = useLocation();
    const OrderId = orderDetailId;
    const PriceParam = searchParams.get("price");
    const DownloadTrue = searchParams.get("download");
    const [subTotal, setSubTotal] = useState("");

    const [error, setError] = useState(null);
    const onError = (err) => {
        setError(err);
    };
    const styles = useStyles();

    const getColorOfStatus = (status) => {
        switch (status) {
            case "Draft":
                return "#9F33F9";
            case "Complete":
                return "rgb(20, 201, 234)";
            case "Quote Sent":
                return "#24C4FB";
            case "QUOTE_REJECTED":
                return "#E10000";
            case "Cancelled":
                return "rgb(38, 39, 42)";
            case "Rejected":
                return "rgb(38, 39, 42)";
            case "Needs Driver":
                return "rgb(20, 201, 234)";
            case "Accepted":
                return "rgb(20, 201, 234)";
            case "New":
                return "rgb(20, 201, 234)";
            case "Active":
                return "rgb(20, 201, 234)";
            case "Driver Assigned":
                return "rgb(20, 201, 234)";
            case "ON_THE_WAY":
                return "rgb(2, 180, 108);";
            default:
                return "#26272A";
        }
    };

    const metersToMiles = (meters) => {
        const metersInOneMile = 1609.34;
        const miles = meters / metersInOneMile;
        return miles.toFixed(1);
    };

    useEffect(() => {
        if (quoteListData) {
            if (quoteListData?.order?.status === "Cancelled") {
                setBannerBackgroundClr("#F8EEEE");
            } else if (quoteListData?.order?.status === "Draft") {
                setBannerBackgroundClr("#F7EEFF");
            } else if (quoteListData?.order?.status === "Rejected") {
                setBannerBackgroundClr("#E3E3E3");
            }
        }
    }, [quoteListData]);

    const calculateTotalItemPrice = (orderItem) => {
        let totalPrice = parseFloat(orderItem?.price);

        orderItem?.orderItemsModifiers.forEach((row) => {
            totalPrice += row.total_price;
        });
        totalPrice += parseFloat(orderItem.packaging_price);

        return parseFloat(totalPrice).toFixed(2);
    };
    const getQuoteDetailList = async (id) => {
        setLoading(true);
        const instance = NetworkManager(API.ORDER.QUOTEDETAIL(id));
        const response = await instance.request();
        if (response.success) {
            setQuoteListData(response.data);
            const sortedItem = [];
            response.data.category.forEach((row) => {
                row.orderItems.forEach((col) => {
                    sortedItem.push(col);
                });
            });
            sortedItem.sort((a, b) => a.item_ordering - b.item_ordering);
            const subTotal = sortedItem.map((item) => parseFloat(calculateTotalItemPrice(item)));
            const sum = subTotal.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setSubTotal(sum.toFixed(2));
            setItemDetailSorted(sortedItem);
        }
        setLoading(false);
    };

    const getDriverListSecondary = async (orderId) => {
        const instance = NetworkManager(API.DRIVER.SECONDARYLISTING(orderId));
        const response = await instance.request();

        if (response.success) {
            const driverList = response.data?.driver;
            if (driverList?.length) {
                setSecondaryDriver(driverList);
            }
        }
    };

    React.useEffect(() => {
        if (OrderId) {
            getQuoteDetailList(OrderId);
            getDriverListSecondary(OrderId);
        }
    }, [OrderId]);

    const handleAdvanceString = () => {
        return quoteListData?.order?.advance_deposit_percent ? `${quoteListData?.order?.advance_deposit_percent}%` : "0%";
    };

    const handleSalesTaxString = () => `${checkforPerfectNumber(quoteListData?.order?.kitchen_location?.sales_tax_rate || "")}%`;

    const dietaryMap = {
        VEGETARIAN: "V",
        VEGAN: "VG",
        "GLUTEN-FREE": "GF",
        "DAIRY-FREE": "DF",
        SPICY: "S"
    };

    const dietarySequence = ["VEGETARIAN", "VEGAN", "GLUTEN-FREE", "DAIRY-FREE", "SPICY"];

    const sortAndMapDietary = (arr1) => {
        const sortedArr = [...arr1];
        sortedArr.sort((a, b) => dietarySequence.indexOf(a.dietary) - dietarySequence.indexOf(b.dietary));
        const mappedArr = sortedArr.map((obj) => dietaryMap[obj.dietary]);
        return mappedArr;
    };

    const sortAndMapModifierDietary = (arr1) => {
        const sortedArr = [...arr1];
        sortedArr.sort((a, b) => dietarySequence.indexOf(a.modifier_dietary) - dietarySequence.indexOf(b.modifier_dietary));
        const mappedArr = sortedArr.map((obj) => dietaryMap[obj.modifier_dietary]);
        return mappedArr;
    };

    function formatDateToAMPM(timestamp) {
        const date = new Date(timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "pm" : "am";

        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedHours}:${formattedMinutes}${ampm}`;
    }
    const showDietaryForItem = (orderItem) => {
        const temp = sortAndMapDietary(orderItem.item?.itemDietary);
        return temp.map((item) => {
            return (
                <Box
                    sx={{
                        width: "19px",
                        height: "19px",
                        border: "1px solid #000",
                        borderRadius: "50%",
                        fontSize: "7.5px",
                        fontWeight: 700,
                        lineHeight: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    {item}
                </Box>
            );
        });
    };
    const showDietaryForModifier = (orderItem) => {
        const temp = sortAndMapModifierDietary(orderItem?.modifierDietary);
        return temp.map((item) => {
            return (
                <Box
                    sx={{
                        width: "19px",
                        height: "19px",
                        border: "1px solid #000",
                        borderRadius: "50%",
                        fontSize: "7.5px",
                        fontWeight: 700,
                        lineHeight: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    {item}
                </Box>
            );
        });
    };

    const isPartialAmountPaid = () => {
        if (
            (quoteListData?.order?.status === "Complete" ||
                quoteListData?.order?.status === "Cancelled" ||
                quoteListData?.order?.status === "Rejected" ||
                quoteListData?.order?.status === "Draft" ||
                quoteListData?.order?.status === "Quote Sent" ||
                quoteListData?.order?.status === "Quote Rejected") &&
            !quoteListData?.order?.remaining_amount_to_be_paid > 0
        ) {
            return false;
        }
        if (quoteListData?.order?.advance_deposit_amount > 0 || quoteListData?.order?.remaining_amount_to_be_paid > 0) {
            return true;
        }
        return false;
    };

    const partialAmountPaid = () => {
        return FormatCurrency(Number(quoteListData?.order?.total_price) - Number(quoteListData?.order?.remaining_amount_to_be_paid));
    };

    const fullName = quoteListData?.order?.customer?.full_name;
    const lastName = quoteListData?.order?.customer?.last_name;
    const name = `${fullName} ${lastName}`.trim();
    const isPercent = quoteListData?.order?.service_fee_unit === "percent";
    const isUsd = quoteListData?.order?.service_fee_unit === "usd";
    const percentFee = (quoteListData?.order?.service_fee_unit_value * quoteListData?.order?.order_price) / 100;
    const showMinimum =
        quoteListData?.order?.apply_minimum_service_fee &&
        ((isPercent && percentFee < quoteListData?.order?.service_fee_value) ||
            (isUsd && quoteListData?.order?.service_fee_unit_value < quoteListData?.order?.service_fee_value));
    const feeText = isPercent
        ? `(${quoteListData?.order?.service_fee_unit_value}% with gratuity included)`
        : `($${quoteListData?.order?.service_fee_unit_value} with gratuity included)`;

    return (
        <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center"}} className="quote">
            <Box sx={{position: "absolute", right: "16px", top: "16px", cursor: "pointer", zIndex: "1999"}} onClick={handleClose}>
                <img src={Cancel} alt="plus-icon" width="12px" height="12px" />
            </Box>
            {loading && <Loader />}
            {quoteListData ? (
                <Box
                    id="divToPrint"
                    sx={{
                        border: "1px solid #f0f0f0",
                        padding: "16px",
                        borderRadius: "4px",
                        width: "816px",
                        minWidth: "777px",
                        maxWidth: "777px"
                    }}
                >
                    <Grid item xs={12}>
                        {/* <Grid item xs={12} sx={{textAlign: "left"}}> */}
                        <Grid mt={1} item xs={12} sx={{textAlign: "left"}}>
                            <Button
                                variant="outlined"
                                sx={{
                                    color: "#00AB3A",
                                    fontSize: "0.8125rem",
                                    width: "130px",
                                    height: "32px",
                                    fontWeight: 600,
                                    border: "1px solid #00AB3A"
                                }}
                                onClick={() => {
                                    handleOrderClick(quoteListData?.order?.order_id);
                                }}
                            >
                                Duplicate order
                            </Button>
                        </Grid>
                        {/* </Grid> */}
                        {/* <Typography variant="subDetail1" fontSize="0.813em" color="#020A11" fontWeight={500}> */}
                        <Stack
                            fontSize="0.813em"
                            color="#020A11"
                            fontWeight={500}
                            sx={[
                                styles?.titleContainer,
                                {
                                    overflow: PriceParam === "price" ? "auto" : "hidden"
                                }
                            ]}
                        >
                            <Grid item xs={12} py="12px" px={0}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontSize: "24px",
                                        fontWeight: 600,
                                        lineHeight: "31.2px",
                                        paddingBottom: "16px",
                                        color: "#020A11"
                                    }}
                                >
                                    {quoteListData?.order ? (
                                        <>
                                            Order #{quoteListData?.order?.order_number}{" "}
                                            {quoteListData?.order?.order_name && (
                                                <>
                                                    <span
                                                        style={{
                                                            color: "rgba(190, 190, 190, 1)",
                                                            fontSize: "24px",
                                                            fontWeight: 400
                                                        }}
                                                    >
                                                        |{" "}
                                                    </span>
                                                    <span style={{fontWeight: 400}}>
                                                        {truncate(quoteListData?.order?.order_name, {length: 20})}
                                                    </span>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </Typography>
                                <Typography sx={{fontSize: "20px", lineHeight: "28px", fontWeight: 500, color: "#000"}}>
                                    Delivery on&nbsp;
                                    {quoteListData?.order?.delivery_date ? (
                                        <>
                                            {new Date(quoteListData?.order?.delivery_date).toLocaleDateString("en-us", {
                                                month: "long",
                                                weekday: "long",
                                                day: "numeric"
                                            })}
                                            &nbsp;at&nbsp;
                                            {formatDateToAMPM(quoteListData?.order?.delivery_date)}
                                        </>
                                    ) : null}
                                </Typography>
                            </Grid>
                            <Grid sx={{mb: "6px", display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                                <Box sx={{width: "126px", height: "54px", position: "relative"}}>
                                    {quoteListData?.order?.brand?.brand_logo ? (
                                        <Box>
                                            <img
                                                src={quoteListData?.order?.brand?.brand_logo}
                                                alt={quoteListData?.order?.brand?.brand_name}
                                                style={styles?.image}
                                            />
                                        </Box>
                                    ) : null}
                                    <Box>
                                        <Typography sx={styles?.title}>
                                            {quoteListData?.order?.brand?.brand_logo ? "" : quoteListData?.order?.brand?.brand_name}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{minHeight: "max-content", minWidth: "max-content", mt: "0px"}}>
                                    <Typography sx={{fontSize: "20px", fontWeight: 700}}>
                                        Bitetime catering {quoteListData?.order?.charged_by === "HOUSE_ACCOUNT" ? "invoice" : "order"}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Stack>
                        {/* </Typography> */}
                    </Grid>

                    <Grid container py="8px">
                        <Grid
                            item
                            xs={5}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                // justifyContent: "center",
                                minHeight: "140px"
                            }}
                        >
                            <Stack>
                                <Typography
                                    sx={{fontSize: "20px", fontWeight: 700, color: "rgba(0, 10, 18, 1)", lineHeight: "24.2px", mt: "0px"}}
                                >
                                    Order details
                                </Typography>
                            </Stack>

                            <Grid container pb="12px" sx={{display: "flex", flexDirection: "row", justifyContent: "center", ml: "3px"}}>
                                <Grid
                                    item
                                    xs={12}
                                    p={0}
                                    sx={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        flexWrap: "wrap"
                                    }}
                                >
                                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "normal", mt: "4px"}}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: 700,
                                                color: "rgba(27, 28, 28, 1)",
                                                lineHeight: "14px",
                                                letterSpacing: "-0.5px",
                                                mt: "2px"
                                            }}
                                        >
                                            ORDER DATE
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "15px",
                                                fontWeight: 400,
                                                color: "rgba(0, 0, 0, 1)",
                                                lineHeight: "20px",
                                                pl: "7px",
                                                letterSpacing: "-0.5px",
                                                mt: "2px"
                                            }}
                                        >
                                            {new Date(quoteListData?.order?.created_at).toLocaleDateString("en-us", {
                                                month: "long",
                                                weekday: "long",
                                                year: "numeric",
                                                day: "numeric"
                                            })}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        flexWrap: "wrap"
                                    }}
                                >
                                    <Box sx={{display: "flex", alignItems: "flex-start", justifyContent: "normal", mt: "1px"}}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: 700,
                                                lineHeight: "14px",
                                                color: "rgba(27, 28, 28, 1)",
                                                letterSpacing: "-0.5px",
                                                minWidth: "77px",
                                                mt: "4px"
                                            }}
                                        >
                                            CUSTOMER
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "15px",
                                                fontWeight: 400,
                                                color: "rgba(0, 0, 0, 1)",
                                                lineHeight: "21px",
                                                letterSpacing: "-0.5px"
                                            }}
                                        >
                                            {truncate(name, {length: 25})}
                                            {quoteListData?.order?.address_details?.location_name ? (
                                                <>
                                                    <span style={{color: "rgba(190, 190, 190, 1)"}}> | </span>
                                                    {FormatPhoneNumber(quoteListData?.order?.address_details?.location_name)}
                                                </>
                                            ) : (
                                                ""
                                            )}{" "}
                                            <br /> {quoteListData?.order?.customer?.phone}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{display: "flex"}}>
                                    <Box sx={{display: "flex"}}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: 700,
                                                color: "rgba(27, 28, 28, 1)",
                                                lineHeight: "14px",
                                                minWidth: "77px",
                                                mt: "6px"
                                            }}
                                        >
                                            KITCHEN
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "15px",
                                                fontWeight: 400,
                                                lineHeight: "20px",
                                                color: "rgba(0, 0, 0, 1)",
                                                mt: "2px"
                                            }}
                                        >
                                            {quoteListData?.order?.kitchen_location ? (
                                                <>
                                                    {`${quoteListData?.order?.kitchen_location?.address_details?.street_line}`}
                                                    <br />
                                                    {`${quoteListData?.order?.kitchen_location?.address_details?.city}, ${
                                                        quoteListData?.order?.kitchen_location?.address_details?.state?.includes("TN")
                                                            ? "TN"
                                                            : quoteListData?.order?.kitchen_location?.address_details?.state
                                                    } ${quoteListData?.order?.kitchen_location?.address_details?.pincode}`}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    p={0}
                                    sx={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        flexWrap: "wrap"
                                    }}
                                >
                                    <Box sx={{display: "flex", alignItems: "center"}}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: 700,
                                                lineHeight: "14px",
                                                color: "rgba(27, 28, 28, 1)",
                                                minWidth: "77px"
                                            }}
                                        >
                                            BRAND
                                        </Typography>
                                        <Typography sx={{fontSize: "15px", fontWeight: 400, lineHeight: "20px", color: "rgba(0, 0, 0, 1)"}}>
                                            {quoteListData?.order?.brand ? quoteListData?.order?.brand?.brand_name : ""}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    p={0}
                                    sx={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        flexWrap: "wrap"
                                    }}
                                >
                                    <Box sx={{display: "flex", alignItems: "center"}}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: 700,
                                                lineHeight: "14px",
                                                color: "rgba(27, 28, 28, 1)",
                                                minWidth: "77px"
                                            }}
                                        >
                                            GUESTS
                                        </Typography>
                                        <Typography sx={{fontSize: "15px", fontWeight: 400, lineHeight: "20px", color: "rgba(0, 0, 0, 1)"}}>
                                            {new Intl.NumberFormat().format(quoteListData?.order?.no_of_guest)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={7} sx={{display: "flex", gap: "13px", minHeight: "140px"}}>
                            <Divider
                                orientation="vertical"
                                sx={{minHeight: "158px", ml: "4px", border: "0.7px solid rgba(230, 230, 230, 1)"}}
                            />
                            <Box sx={{display: "flex", flexDirection: "column"}}>
                                <Typography sx={{fontSize: "20px", fontWeight: 700, color: "rgba(0, 10, 18, 1)", lineHeight: "24.2px"}}>
                                    <Stack>Delivery details</Stack>
                                </Typography>
                                <Grid container xs={12}>
                                    <Grid item xs={12} sx={{display: "flex", flexDirection: "column", ml: "2px"}}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "normal",
                                                mt: "4px"
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: 700,
                                                    lineHeight: "14px",
                                                    color: "rgba(27, 28, 28, 1)",
                                                    width: "95px"
                                                }}
                                            >
                                                DELIVERY DATE
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: "15px",
                                                    fontWeight: 400,
                                                    lineHeight: "20px",
                                                    color: "rgba(0, 0, 0, 1)",
                                                    pl: "10px"
                                                }}
                                            >
                                                {new Date(quoteListData?.order?.delivery_date).toLocaleDateString("en-us", {
                                                    month: "long",
                                                    weekday: "long",
                                                    year: "numeric",
                                                    day: "numeric"
                                                })}
                                            </Typography>
                                        </Box>
                                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", mt: "3px"}}>
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: 700,
                                                    lineHeight: "14px",
                                                    color: "rgba(27, 28, 28, 1)",
                                                    width: "95px"
                                                }}
                                            >
                                                ARRIVAL TIME
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: "15px",
                                                    fontWeight: 400,
                                                    lineHeight: "20px",
                                                    color: "rgba(0, 0, 0, 1)",
                                                    pl: "10px"
                                                }}
                                            >
                                                {quoteListData?.order?.delivery_date
                                                    ? formatDateToAMPM(quoteListData?.order?.delivery_date)
                                                    : ""}
                                            </Typography>
                                        </Box>
                                        <Box sx={{display: "flex", justifyContent: "normal"}}>
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: 700,
                                                    lineHeight: "14px",
                                                    color: "rgba(27, 28, 28, 1)",
                                                    mt: "4px",
                                                    width: "95px"
                                                }}
                                            >
                                                LOCATION
                                            </Typography>

                                            <Typography
                                                sx={{
                                                    fontSize: "15px",
                                                    fontWeight: 400,
                                                    lineHeight: "20px",
                                                    color: "rgba(0, 0, 0, 1)",
                                                    pl: "10px",
                                                    maxWidth: "300px"
                                                }}
                                            >
                                                {FormatPhoneNumber(
                                                    quoteListData?.order?.address_details?.location_name
                                                        ? `${truncate(
                                                              FormatPhoneNumber(quoteListData?.order?.address_details?.location_name),
                                                              {length: 30}
                                                          )}`
                                                        : ""
                                                )}
                                            </Typography>
                                        </Box>
                                        <Box sx={{display: "flex", flexDirection: "row", justifyContent: "normal", mt: "3px"}}>
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: 700,
                                                    lineHeight: "14px",
                                                    color: "rgba(27, 28, 28, 1)",
                                                    pt: "3px",
                                                    width: "95px"
                                                }}
                                            >
                                                ADDRESS
                                            </Typography>

                                            <Typography
                                                sx={{
                                                    fontSize: "15px",
                                                    fontWeight: 400,
                                                    lineHeight: "20px",
                                                    color: "rgba(0, 0, 0, 1)",
                                                    pl: "10px",
                                                    maxWidth: "300px"
                                                }}
                                            >
                                                {quoteListData?.order?.address_details ? (
                                                    <>
                                                        {quoteListData?.order?.address_details?.street_line && (
                                                            <>
                                                                {quoteListData?.order?.address_details?.street_line} <br />
                                                            </>
                                                        )}
                                                        {quoteListData?.order?.address_details?.apartment_name && (
                                                            <>
                                                                {quoteListData?.order?.address_details?.apartment_name}
                                                                <br />
                                                            </>
                                                        )}
                                                        {quoteListData?.order?.address_details?.city && (
                                                            <>{`${quoteListData?.order?.address_details?.city}, `}</>
                                                        )}
                                                        {quoteListData?.order?.address_details?.state && (
                                                            <>
                                                                {quoteListData?.order?.address_details?.state.includes("TN")
                                                                    ? "TN"
                                                                    : quoteListData?.order?.address_details?.state}{" "}
                                                            </>
                                                        )}
                                                        {quoteListData?.order?.address_details?.pincode && (
                                                            <>{quoteListData?.order?.address_details?.pincode}</>
                                                        )}
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {console.log("HaAAA: ", quoteListData?.order)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{minHeight: "48px", mt: "2px", display: "flex", alignItems: "start", mr: "6px", ml: "2px"}}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: 700,
                                            lineHeight: "14px",
                                            minWidth: "80px",
                                            color: "rgba(27, 28, 28, 1)",
                                            mt: "2px"
                                        }}
                                    >
                                        INSTRUCTIONS
                                    </Typography>
                                    <Typography
                                        sx={{fontSize: "15px", fontWeight: 400, lineHeight: "20px", color: "rgba(0, 0, 0, 1)", pl: "24px"}}
                                    >
                                        {quoteListData?.order?.delivery_instructions?.length > 0
                                            ? quoteListData?.order?.delivery_instructions
                                            : ""}
                                    </Typography>
                                </Grid>{" "}
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid
                            container
                            sx={{
                                backgroundColor: "rgba(243, 243, 243, 1)",
                                height: "30.4px",
                                border: "1px solid #f0f0f0",
                                borderRadius: "2.9px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "8.7px 11.6px ",
                                width: "972px"
                            }}
                        >
                            <Grid item xs={1}>
                                <Typography sx={{fontSize: "12px", fontWeight: 700, lineHeight: "14px", color: "rgba(167, 167, 167, 1)"}}>
                                    {" "}
                                    Quantity
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography sx={{fontSize: "12px", fontWeight: 700, lineHeight: "14px", color: "rgba(167, 167, 167, 1)"}}>
                                    Items
                                </Typography>
                            </Grid>

                            {/* {PriceParam !== "price" && ( */}
                            <Grid item sx={{display: "flex", justifyContent: "flex-end", textAlign: "end"}} xs={3}>
                                <Typography sx={{fontSize: "12px", fontWeight: 700, lineHeight: "14px", color: "rgba(167, 167, 167, 1)"}}>
                                    {" "}
                                    Dietary
                                </Typography>
                            </Grid>
                            {/* )} */}

                            {/* {PriceParam !== "price" && ( */}
                            <Grid item sx={{display: "flex", justifyContent: "flex-end", textAlign: "end"}} xs={1.5}>
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        lineHeight: "14px",
                                        color: "rgba(167, 167, 167, 1)"
                                    }}
                                >
                                    {" "}
                                    Price
                                </Typography>
                            </Grid>
                            {/* )} */}

                            {/* {PriceParam !== "price" && ( */}
                            <Grid item sx={{display: "flex", justifyContent: "flex-end", textAlign: "end"}} xs={1.5}>
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        lineHeight: "14px",
                                        color: "rgba(167, 167, 167, 1)"
                                    }}
                                >
                                    {" "}
                                    Total
                                </Typography>
                            </Grid>
                            {/* )} */}
                        </Grid>
                        {itemDetailSorted?.map((orderItem, index) => {
                            const itemTotal = orderItem?.orderItemsModifiers
                                .map((item) => item.total_price)
                                .reduce((partialSum, a) => partialSum + a, 0);
                            const total = Number(orderItem.total_price) + Number(itemTotal);
                            console.log("order id: ", orderItem.order_id);
                            return (
                                <Grid
                                    container
                                    key={orderItem.order_id}
                                    sx={{padding: "0px 12px 8px 0px", borderBottom: "1px solid rgba(230, 230, 230, 1)", mt: "6px"}}
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Grid item xs={1} textAlign="center" height="100%" paddingTop="8px">
                                        <Typography
                                            variant="h5"
                                            sx={{fontSize: "14px", fontWeight: 400, lineHeight: "17.5px", color: "#000"}}
                                        >
                                            {commaFormattedNumber(orderItem?.quantity)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={11} display="flex" flexDirection="column">
                                        <Grid
                                            container
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{wordBreak: "break-word"}}
                                            padding="8px 0px 4px 10px"
                                        >
                                            <Grid item xs={6} display="flex" alignItems="center" height="100%">
                                                {PriceParam !== "price" && (
                                                    <Typography
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 600,
                                                            lineHeight: "17.5px",
                                                            color: "#000",
                                                            paddingBottom: "1px"
                                                        }}
                                                    >
                                                        {orderItem.item_name.trim()}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            {/* {PriceParam !== "price" && ( */}
                                            <Grid item xs={3} className={styles.titleDivision} display="flex" justifyContent="flex-end">
                                                <Typography
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "17.5px",
                                                        color: "#000",
                                                        textAlign: "right",
                                                        mr: "19px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "3px"
                                                    }}
                                                >
                                                    {showDietaryForItem(orderItem)}
                                                </Typography>
                                            </Grid>
                                            {/* )} */}
                                            {/* {PriceParam !== "price" && ( */}
                                            <Grid
                                                item
                                                xs={1.5}
                                                className={styles.titleDivision}
                                                display="flex"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "17.5px",
                                                        color: "#000",
                                                        textAlign: "right",
                                                        mr: "7px"
                                                    }}
                                                >
                                                    {FormatCurrency(Number(orderItem?.price) / Number(orderItem?.quantity))}
                                                </Typography>
                                            </Grid>
                                            {/* )} */}
                                            <Grid
                                                item
                                                xs={1.5}
                                                className={styles.titleDivision}
                                                display="flex"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 600,
                                                        lineHeight: "17.5px",
                                                        color: "#000"
                                                    }}
                                                >
                                                    {FormatCurrency(Number(total).toFixed(2))}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            // xs={PriceParam === "price" ? 5 : 7}
                                            xs={12}
                                            display="flex"
                                            justifyContent="center"
                                            sx={{wordBreak: "break-word"}}
                                            paddingLeft={1.5}
                                        >
                                            {orderItem?.orderItemsModifiers && (
                                                <Grid item xs={12}>
                                                    <Grid container display="flex" sx={{margin: 0}}>
                                                        {orderItem?.orderItemsModifiers
                                                            ?.sort((a, b) => {
                                                                if (a.item_modifier_group_series_no !== b.item_modifier_group_series_no) {
                                                                    return (
                                                                        a.item_modifier_group_series_no - b.item_modifier_group_series_no
                                                                    );
                                                                } else {
                                                                    return a.series_no - b.series_no;
                                                                }
                                                            })
                                                            ?.map((item) => {
                                                                return (
                                                                    <React.Fragment key={item.order_item_id}>
                                                                        <Grid
                                                                            item
                                                                            xs={6}
                                                                            sx={{paddingBottom: "4px", color: "#000"}}
                                                                            display="flex"
                                                                            pl="8px"
                                                                        >
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "18.62px",
                                                                                    color: "#000",
                                                                                    display: "inline-flex"
                                                                                }}
                                                                            >
                                                                                <span style={{marginRight: "8px", color: "#000"}}>•</span>
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "18.62px",
                                                                                    color: "#000"
                                                                                }}
                                                                            >
                                                                                {item.modifier_group?.display_name}:&nbsp;
                                                                                {item?.quantity > 0
                                                                                    ? `${commaFormattedNumber(item.quantity)} x `
                                                                                    : ""}{" "}
                                                                                {item.modifier?.modifier_name}{" "}
                                                                            </Typography>
                                                                        </Grid>

                                                                        {PriceParam !== "price" && (
                                                                            <Grid
                                                                                item
                                                                                xs={3}
                                                                                className={styles.titleDivision}
                                                                                display="flex"
                                                                                justifyContent="flex-end"
                                                                            >
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                        lineHeight: "18.62px",
                                                                                        color: "#000",
                                                                                        mr: "19px",
                                                                                        display: "flex",
                                                                                        alignItems: "flex-start",
                                                                                        gap: "3px"
                                                                                    }}
                                                                                >
                                                                                    {showDietaryForModifier(item)}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}

                                                                        {PriceParam !== "price" && (
                                                                            <Grid
                                                                                item
                                                                                xs={3}
                                                                                className={styles.titleDivision}
                                                                                display="flex"
                                                                                justifyContent="flex-end"
                                                                            >
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: 400,
                                                                                        lineHeight: "18.62px",
                                                                                        color: "#000",
                                                                                        mr: "90px"
                                                                                    }}
                                                                                >
                                                                                    {console.log("Price: ", item.price)}
                                                                                    {item.modifier_group?.display_name === "Sides"
                                                                                        ? ""
                                                                                        : PriceParam !== "price" && Boolean(item?.price)
                                                                                        ? `${FormatCurrency(item?.price)}`
                                                                                        : ""}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>{" "}
                                        {orderItem?.item?.customer_note && (
                                            <Grid pl="10px">
                                                <Typography
                                                    sx={{
                                                        color: "#000",
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "18.62px",
                                                        mt: "4px"
                                                    }}
                                                >
                                                    Includes:&nbsp;
                                                    {orderItem?.item?.customer_note}
                                                </Typography>
                                            </Grid>
                                        )}
                                        {orderItem?.special_instruction && (
                                            <Grid item xs={7} pt="2px" pl="10px">
                                                {orderItem?.item?.special_instruction && (
                                                    <Box sx={{display: "-webkit-box", flexDirection: "row"}}>
                                                        <Typography sx={{fontSize: "14px", fontWeight: 400, lineHeight: "18.62px"}}>
                                                            Includes:&nbsp;
                                                        </Typography>
                                                        <Typography sx={{fontSize: "14px", fontWeight: 400, lineHeight: "18.62px"}}>
                                                            &nbsp;{orderItem?.item?.special_instruction}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                {orderItem?.special_instruction && (
                                                    <Box
                                                        sx={{
                                                            display: "-webkit-box",
                                                            flexDirection: "row",
                                                            marginBottom: "2px",
                                                            width: "450px"
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                color: "#000",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                lineHeight: "18.62px"
                                                            }}
                                                        >
                                                            Special instructions:&nbsp;{" "}
                                                            {orderItem?.special_instruction.length > 0
                                                                ? orderItem?.special_instruction
                                                                : ""}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            );
                        })}
                        {quoteListData?.order?.tableware_cutlery === "true" || quoteListData?.order?.tableware_plates === true ? (
                            <Grid container padding="8px 12px">
                                <Grid item xs={12} display="flex" justifyContent="space-between">
                                    {" "}
                                    <Typography
                                        variant="h5"
                                        sx={{fontSize: "14px", fontWeight: 600, lineHeight: "17.5px", color: "rgba(0, 0, 0, 1)"}}
                                        paddingLeft="62px"
                                    >
                                        Tableware
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        sx={{fontSize: "14px", fontWeight: 600, lineHeight: "17.5px", color: "rgba(0, 0, 0, 1)"}}
                                    >
                                        INCLUDED
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} paddingTop="6px">
                                    <Typography
                                        variant="h5"
                                        sx={{fontSize: "14px", fontWeight: 400, lineHeight: "18.48px", color: "rgba(0, 0, 0, 1)"}}
                                        paddingLeft="62px"
                                    >
                                        Includes:&nbsp;
                                        {quoteListData?.order?.tableware_plates && "Plates, "}
                                        {!quoteListData?.order?.serving_utensils && "and "}
                                        {quoteListData?.order?.tableware_cutlery &&
                                        quoteListData?.order?.tableware_plates &&
                                        quoteListData?.order?.serving_utensils
                                            ? "cutlery with napkins, "
                                            : quoteListData?.order?.tableware_cutlery && quoteListData?.order?.tableware_plates
                                            ? "cutlery with napkins. "
                                            : "Cutlery with napkins,"}
                                        {(quoteListData?.order?.tableware_plates || quoteListData?.order?.tableware_cutlery) &&
                                            quoteListData?.order?.serving_utensils &&
                                            "and serving utensils"}
                                        {!quoteListData?.order?.tableware_plates &&
                                            !quoteListData?.order?.tableware_cutlery &&
                                            quoteListData?.order?.serving_utensils &&
                                            "Serving utensils"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            ""
                        )}
                        {(quoteListData?.order?.tableware_cutlery === "true" || quoteListData?.order?.tableware_plates === true) && (
                            <hr style={{width: "905px", border: "0.6px solid rgba(230, 230, 230, 1)"}} />
                        )}
                    </Grid>
                    <Grid
                        container
                        padding="8px 0px 6.5px 0px"
                        borderBottom={PriceParam !== "price" ? 0 : 0}
                        sx={{display: "flex", flexDirection: "column"}}
                    >
                        {PriceParam === "price" &&
                            (quoteListData?.order?.special_instructions?.length > 1 || quoteListData?.order?.kitchen_note?.length > 0) && (
                                <Grid container padding="8px 0px 8px 4px" sx={{display: "flex", flexDirection: "column"}}>
                                    {" "}
                                    {quoteListData?.order?.special_instructions?.length > 1 &&
                                        quoteListData?.order?.kitchen_note?.length > 0 && (
                                            <Grid container sx={{display: "flex", flexDirection: "column"}}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        overflow: "hidden",
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                        flexDirection: "column",
                                                        gap: "3px"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{fontSize: "12px", fontWeight: 700, lineHeight: "14px", color: "#1B1C1C"}}
                                                        paddingLeft={0}
                                                    >
                                                        SPECIAL INSTRUCTIONS
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "15px",
                                                            fontWeight: 400,
                                                            lineHeight: "20px",
                                                            color: "#000",
                                                            width: "902px"
                                                        }}
                                                    >
                                                        {quoteListData?.order?.special_instructions?.length > 1
                                                            ? quoteListData?.order?.special_instructions
                                                            : ""}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container sx={{padding: "2px 0px"}}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                overflow: "hidden",
                                                                display: "flex",
                                                                alignItems: "flex-start",
                                                                flexDirection: "column",
                                                                gap: "3px",
                                                                mt: "2px"
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "12px",
                                                                    fontWeight: 700,
                                                                    lineHeight: "14px",
                                                                    color: "#1B1C1C"
                                                                }}
                                                            >
                                                                KITCHEN NOTE
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "15px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "20px",
                                                                    color: "#000",
                                                                    width: "902px"
                                                                }}
                                                            >
                                                                {quoteListData?.order?.kitchen_note}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    {quoteListData?.order?.special_instructions?.length > 1 &&
                                        (quoteListData?.order?.kitchen_note?.length < 1 || quoteListData?.order?.kitchen_note === null) && (
                                            <Grid container padding="0 16px 0 0">
                                                <Grid item xs={12} sm={6} sx={{overflow: "hidden"}}>
                                                    <Typography sx={styles?.instructions} paddingLeft={0}>
                                                        Special instructions
                                                    </Typography>
                                                    <Typography sx={[styles?.detailTitle, styles?.color2]}>
                                                        {quoteListData?.order?.special_instructions?.length > 1
                                                            ? quoteListData?.order?.special_instructions
                                                            : ""}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    {(quoteListData?.order?.special_instructions?.length < 1 ||
                                        quoteListData?.order?.special_instructions === null) &&
                                        quoteListData?.order?.kitchen_note?.length > 0 && (
                                            <Grid container padding="0 16px 0 0">
                                                <Grid item xs={12} sm={6}>
                                                    <Typography sx={styles?.instructions} paddingLeft={0}>
                                                        Kitchen notes
                                                    </Typography>
                                                    <Typography sx={[styles?.detailTitle, styles?.color2]} paddingLeft="5px">
                                                        {quoteListData?.order?.kitchen_note}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                </Grid>
                            )}

                        {PriceParam !== "price" && (
                            <Grid container>
                                <Grid item xs={12} sm={6} className={styles.titleDivision}>
                                    <Grid container sx={{padding: "2px 0px"}}>
                                        <Grid item xs={12} sx={{overflow: "hidden"}} padding="0px 0 8px 2px">
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: 700,
                                                    lineHeight: "14px",
                                                    color: "rgba(27, 28, 28, 1)"
                                                }}
                                                paddingLeft={0}
                                            >
                                                SPECIAL INSTRUCTIONS
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: "15px",
                                                    fontWeight: 400,
                                                    lineHeight: "20px",
                                                    color: "rgba(0, 0, 0, 1)",
                                                    mt: "5px"
                                                }}
                                            >
                                                {quoteListData?.order?.special_instructions?.length > 1
                                                    ? quoteListData?.order?.special_instructions
                                                    : ""}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{textAlign: "right", display: "flex", flexDirection: "column"}}>
                                    <Grid container spacing={4} display="flex" justifyContent="flex-end">
                                        <Grid item xs={6} spacing={2}>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                    lineHeight: "20.44px",
                                                    color: "rgba(0, 0, 0, 1)"
                                                }}
                                            >
                                                Order items
                                            </Typography>
                                            {Number(quoteListData?.order?.service_fee_value) > 0 && (
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "20.44px",
                                                        color: "rgba(0, 0, 0, 1)",
                                                        minWidth: "max-content",
                                                        ml: "-70px"
                                                    }}
                                                >
                                                    Service fee {showMinimum ? "(Minimum)" : feeText}
                                                </Typography>
                                            )}{" "}
                                            {Number(quoteListData?.order?.preferred_price) > 0 && (
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "20.44px",
                                                        color: "#000"
                                                    }}
                                                >
                                                    Preferred pricing{" "}
                                                    {quoteListData?.order?.preferred_price_unit_type !== "DISCOUNT" &&
                                                    quoteListData?.order?.preferred_price_unit_type !== "" &&
                                                    quoteListData?.order?.preferred_price_unit_value
                                                        ? `(${Math.floor(Number(quoteListData?.order?.preferred_price_unit_value))}%)`
                                                        : ""}
                                                </Typography>
                                            )}
                                            {Number(quoteListData?.order?.price_type_value) > 0 &&
                                                quoteListData?.order?.price_type !== "Discount" && (
                                                    <Typography
                                                        variant="h5"
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            lineHeight: "20.44px",
                                                            color: "#000",
                                                            mr: "1px"
                                                        }}
                                                    >
                                                        {quoteListData?.order?.price_type && quoteListData?.order?.price_type}{" "}
                                                        {quoteListData?.order?.price_type_unit === "percent" &&
                                                            quoteListData?.order?.discount_or_fee_unit_value}
                                                        {quoteListData?.order?.price_type_unit === "percent" && "%"}{" "}
                                                        {quoteListData?.order?.discount_or_fee_description
                                                            ? `(${ellipsizeText(quoteListData?.order?.discount_or_fee_description, 25)})`
                                                            : null}
                                                    </Typography>
                                                )}
                                            {quoteListData?.order?.discount_or_fee_unit_value > 0 &&
                                                quoteListData?.order?.price_type === "Discount" && (
                                                    <Typography
                                                        variant="h5"
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            lineHeight: "20.44px",
                                                            color: "rgba(0, 0, 0, 1)",
                                                            minWidth: "max-content",
                                                            ml: "-36px"
                                                        }}
                                                    >
                                                        Promo code&nbsp;
                                                        {quoteListData?.order?.discount_or_fee_unit_value &&
                                                        quoteListData?.order?.price_type_unit === "percent"
                                                            ? `(${quoteListData.order.discount_or_fee_unit_value}%)`
                                                            : null}
                                                    </Typography>
                                                )}
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "20.44px",
                                                    color: "rgba(0, 0, 0, 1)"
                                                }}
                                            >
                                                Sales tax ({handleSalesTaxString()})
                                            </Typography>
                                            {quoteListData?.order?.advance_deposit_percent &&
                                                quoteListData?.order?.advance_deposit_amount > 0 &&
                                                (quoteListData?.order?.status === "Cancelled" ||
                                                    quoteListData?.order?.status === "Rejected") && (
                                                    <Typography
                                                        variant="h5"
                                                        color="rgba(0, 0, 0, 1)"
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            lineHeight: "20.44px",
                                                            color: "rgba(0, 0, 0, 1)"
                                                        }}
                                                    >
                                                        Advance payment
                                                    </Typography>
                                                )}
                                            <Typography
                                                variant="h5"
                                                color={quoteListData?.order?.status === "Cancelled" ? "#E10000" : "#00ab3a"}
                                                sx={{fontSize: "14px", fontWeight: 700, lineHeight: "20.44px"}}
                                            >
                                                Total
                                            </Typography>
                                            <>
                                                {" "}
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "20.44px",
                                                        color: "rgba(0, 0, 0, 1)"
                                                    }}
                                                >
                                                    Per person
                                                </Typography>
                                            </>
                                            {(quoteListData?.order?.status === "Cancelled" ||
                                                quoteListData?.order?.status === "Rejected") &&
                                                quoteListData?.order?.charge_fee_amount !== null && (
                                                    <Typography
                                                        color="#E10000"
                                                        sx={{fontSize: "14px", fontWeight: 700, lineHeight: "20.44px"}}
                                                    >
                                                        Cancelation fee
                                                    </Typography>
                                                )}{" "}
                                            {(quoteListData?.order?.is_full_refunded || quoteListData?.order?.is_partial_refunded) && (
                                                <Typography
                                                    variant="h5"
                                                    color="rgba(25, 185, 61, 1)"
                                                    sx={{fontSize: "14px", fontWeight: 700, lineHeight: "20.44px"}}
                                                >
                                                    Refund amount
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={2.8} pr="12px" display="flex" flexDirection="column" alignItems="flex-end">
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                    lineHeight: "20.44px",
                                                    color: "rgba(0, 0, 0, 1)"
                                                }}
                                            >
                                                {FormatCurrency(Number(subTotal).toFixed(2))}
                                            </Typography>
                                            {Number(quoteListData?.order?.service_fee_value) > 0 && (
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "20.44px",
                                                        color: "rgba(0, 0, 0, 1)"
                                                    }}
                                                >
                                                    {FormatCurrency(quoteListData?.order?.service_fee_value)}
                                                </Typography>
                                            )}
                                            {Number(quoteListData?.order?.preferred_price) > 0 && (
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "20.44px",
                                                        color: "rgba(0, 0, 0, 1)"
                                                    }}
                                                >
                                                    {Number(quoteListData?.order?.preferred_price) > 0 && "-"}
                                                    {FormatCurrency(quoteListData?.order?.preferred_price)}
                                                </Typography>
                                            )}

                                            {Number(quoteListData?.order?.price_type_value) > 0 &&
                                                quoteListData?.order?.price_type !== "Discount" && (
                                                    <Typography
                                                        variant="h5"
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            lineHeight: "20.44px",
                                                            color: "rgba(0, 0, 0, 1)"
                                                        }}
                                                    >
                                                        {quoteListData?.order?.price_type === "Discount" &&
                                                            Number(quoteListData?.order?.price_type_value) > 0 &&
                                                            "-"}{" "}
                                                        {FormatCurrency(Number(quoteListData?.order?.price_type_value))}
                                                    </Typography>
                                                )}
                                            {quoteListData?.order?.discount_or_fee_unit_value > 0 &&
                                                quoteListData?.order?.price_type === "Discount" && (
                                                    <Typography
                                                        variant="h5"
                                                        sx={{fontSize: "14px", fontWeight: 400, lineHeight: "20.44px", color: "#000"}}
                                                    >
                                                        -{FormatCurrency(quoteListData?.order?.price_type_value)}
                                                    </Typography>
                                                )}
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "20.44px",
                                                    color: "rgba(0, 0, 0, 1)"
                                                }}
                                            >
                                                {FormatCurrency(
                                                    quoteListData?.order?.tax_exempt ? "0.00" : quoteListData?.order?.sales_tax
                                                )}
                                            </Typography>
                                            {quoteListData?.order?.advance_deposit_percent &&
                                                quoteListData?.order?.advance_deposit_amount > 0 &&
                                                (quoteListData?.order?.status === "Cancelled" ||
                                                    quoteListData?.order?.status === "Rejected") && (
                                                    <Typography
                                                        variant="h5"
                                                        color="#000"
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            lineHeight: "20.44px",
                                                            color: "rgba(0, 0, 0, 1)"
                                                        }}
                                                    >
                                                        {FormatCurrency(quoteListData?.order?.advance_deposit_amount)}
                                                    </Typography>
                                                )}
                                            <Typography
                                                variant="h5"
                                                color={quoteListData?.order?.status === "Cancelled" ? "#E10000" : "#00ab3a"}
                                                sx={{
                                                    textDecoration:
                                                        quoteListData?.order?.status === "Cancelled" ||
                                                        quoteListData?.order?.status === "Rejected"
                                                            ? "line-through"
                                                            : "none",
                                                    ...styles?.pricing,
                                                    fontWeight: 700,
                                                    fontSize: "14px",
                                                    lineHeight: "20.44px"
                                                }}
                                            >
                                                {FormatCurrency(quoteListData?.order?.total_price)}
                                            </Typography>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "20.44px",
                                                    color: "rgba(0, 0, 0, 1)"
                                                }}
                                            >
                                                {quoteListData?.order?.no_of_guest > 0
                                                    ? FormatCurrency(
                                                          parseFloat(quoteListData?.order?.total_price) /
                                                              parseFloat(quoteListData?.order?.no_of_guest)
                                                      )
                                                    : FormatCurrency(0)}
                                            </Typography>

                                            {(quoteListData?.order?.status === "Cancelled" ||
                                                quoteListData?.order?.status === "Rejected") &&
                                                quoteListData?.order?.charge_fee_amount !== null && (
                                                    <Typography
                                                        sx={{fontSize: "14px", fontWeight: 700, lineHeight: "20.44px"}}
                                                        color="#E10000"
                                                    >
                                                        {FormatCurrency(parseFloat(quoteListData?.order?.charge_fee_amount))}
                                                    </Typography>
                                                )}
                                            {(quoteListData?.order?.is_full_refunded || quoteListData?.order?.is_partial_refunded) && (
                                                <Typography
                                                    variant="h5"
                                                    sx={{fontSize: "14px", fontWeight: 700, lineHeight: "20.44px", marginTop: "1px"}}
                                                    color="rgba(25, 185, 61, 1)"
                                                >
                                                    {FormatCurrency(
                                                        quoteListData?.order?.amount_charge -
                                                            quoteListData?.order?.remaining_amount_for_refund
                                                    )}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mb: "2px"}}>
                        <img src={logo} alt="logo" height="19px" width="106px" />
                        <Typography sx={{fontSize: "14px", fontWeight: 400, lineHeight: "21px", color: "rgba(0, 10, 18, 1)", pr: "12px"}}>
                            For help with your order, contact us at 1.844.986.2483 or hello@bitetime.com
                        </Typography>
                    </Box>
                </Box>
            ) : null}
        </Grid>
    );
}

export default PrintOrder;
