import {Box, Grid, InputAdornment, Typography} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {ErrorMessage, Formik} from "formik";
import React, {useState, useEffect} from "react";
import {enforceFormat, formatToPhone} from "utils/helperFunctions/helpers";
import * as yup from "yup";
import {API, NetworkManager} from "network/core";
import {useDispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";

const defaultInitialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company: "",
    companyId: ""
};

const AddNewCustomerForm = (props) => {
    const {handleClose, Parentformik} = props;
    const [existingCompany, setExistingCompany] = useState([]);
    const [companySearch, setCompanySearch] = useState("");
    const dispatch = useDispatch();

    const getExisitingCompany = async () => {
        const instance = NetworkManager(API.COMPANY.GET);
        const response = await instance.request({}, [`?company_name=${companySearch}`]);
        if (response.success) {
            const data = response.data.companyList;
            setExistingCompany(data);
        }
    };

    const createCompany = async (name) =>
        new Promise((resolve) => {
            const instance = NetworkManager(API.COMPANY.ADD);
            instance.request({company_name: name}).then((response) => {
                if (response.success) {
                    resolve(response.data?.company?.company_id);
                }
            });
        });

    useEffect(() => {
        if (companySearch) {
            getExisitingCompany();
        } else {
            setExistingCompany([]);
        }
    }, [companySearch]);

    const addNewCustomer = async (payload, setSubmitting) => {
        const instance = NetworkManager(API.CUSTOMER.ADD);

        const response = await instance.request(payload);
        if (response.success) {
            const {customer} = response?.data;
            const data = {
                customerName: customer?.full_name,
                name: customer?.full_name,
                email: customer?.email,
                phone: customer?.phone,
                company: customer?.company,
                customerAddresses: [],
                userId: customer?.user_id,
                companyId: payload?.company_id
            };
            Parentformik.setFieldValue("headerData.customer", data);
            Parentformik.setTouched({"headerData.customer": true});

            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        }
        handleClose();

        setSubmitting(false);
    };

    const handleNewCustomer = async (val, setSubmitting) => {
        setSubmitting(true);

        // if (addresslist.length > 5) {
        //     handleClose();
        //     alert("Can not add mora than 5 address");
        //     return;
        // }
        const fullName = `${val.first_name} ${val.last_name}`;

        const payload = {
            email: val.email,
            full_name: val.first_name,
            last_name: val.last_name,
            phone: val.phone
        };
        if (val.company !== "") {
            if (val.companyId !== "") {
                payload.company_id = val.companyId;
                addNewCustomer(payload, setSubmitting);
            } else {
                createCompany(val.company).then((resp) => {
                    payload.company_id = resp;
                    addNewCustomer(payload, setSubmitting);
                });
            }
        } else {
            addNewCustomer(payload, setSubmitting);
        }
    };

    const stopPropagationForTab = (event) => {
        if (event.key === "Tab") {
            event.stopPropagation();
        }
    };

    return (
        <Box p={2} sx={{maxWidth: 400}}>
            <Formik
                initialValues={defaultInitialValues}
                validationSchema={yup.object({
                    first_name: yup.string().required("First name is required").nullable().trim(),
                    last_name: yup.string().required("Last name is required").nullable().trim(),
                    email: yup.string().required("Email address is required").email("Please enter valid email").trim().nullable(),
                    phone: yup.string().required("Phone number is required").nullable().trim(),
                    company: yup.string().nullable().trim()
                })}
                onSubmit={(val, {setSubmitting}) => handleNewCustomer(val, setSubmitting)}
            >
                {(formik) => (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <CustomTextField
                                    fullWidth
                                    onKeyDown={stopPropagationForTab}
                                    label="First name"
                                    {...formik.getFieldProps("first_name")}
                                />
                                <ErrorMessage name="first_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextField
                                    fullWidth
                                    onKeyDown={stopPropagationForTab}
                                    label="Last name"
                                    {...formik.getFieldProps("last_name")}
                                />
                                <ErrorMessage name="last_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextField
                                    fullWidth
                                    onKeyDown={stopPropagationForTab}
                                    label="Email address"
                                    {...formik.getFieldProps("email")}
                                />
                                <ErrorMessage name="email" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>

                            <Grid item xs={12}>
                                <CustomTextField
                                    fullWidth 
                                    label="Phone number"
                                    onKeyDown={enforceFormat}
                                    onKeyUp={formatToPhone}
                                    inputProps={{maxLength: 14}}
                                    // {...(formik.values.phone && {
                                    //     InputProps: {
                                    //         startAdornment: <InputAdornment position="start">+1</InputAdornment>
                                    //     }
                                    // })}
                                    {...formik.getFieldProps("phone")}
                                />
                                <ErrorMessage name="phone" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>

                            <Grid item xs={12}>
                                {/* <CustomTextField fullWidth  onKeyDown={stopPropagationForTab} label="Company (optional)" {...formik.getFieldProps("company")} /> */}

                                <CustomTextField
                                    // {...props}
                                    fullWidth
                                    name="company"
                                    label="Company (optional)"
                                    placeholder=""
                                    onKeyDown={stopPropagationForTab}
                                    value={formik.values.company}
                                    {...formik.getFieldProps("company")}
                                    onChange={(e) => {
                                        setCompanySearch(e.target.value);
                                        formik.setFieldValue("company", e.target.value);
                                    }}
                                />
                                {!!existingCompany?.length && (
                                    <Box
                                        sx={{
                                            p: 1,
                                            boxShadow: "0px 0px 3px rgba(0,0,0,0.3)",
                                            borderRadius: 0.5,
                                            position: "absolute",
                                            width: "100%",
                                            bgcolor: "#fff",
                                            maxHeight: "200px",
                                            overflow: "auto",
                                            zIndex: 1380
                                        }}
                                    >
                                        {existingCompany?.map((obj, i) => (
                                            <Box
                                                key={i}
                                                sx={{p: 0.2, py: 0.2, cursor: "pointer", "&:hover": {bgcolor: "#Fafafa"}}}
                                                onClick={() => {
                                                    formik.setFieldValue("company", obj.company_name);
                                                    formik.setFieldValue("companyId", obj.company_id);
                                                    setExistingCompany([]);
                                                }}
                                            >
                                                <Typography variant="body3" fontSize="0.875rem" fontWeight={500}>
                                                    {obj?.company_name}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                            </Grid>
                        </Grid>

                        <Box mt={1}>
                            <ButtonGrouping
                                btnprops={[
                                    {
                                        btnTitle: "Cancel",
                                        sx: (t) => ({color: "#f0475c"}),
                                        onClick: handleClose
                                    },
                                    {
                                        btnTitle: formik.isSubmitting ? "Sending" : "Save",
                                        disabled: formik.isSubmitting,
                                        variant: "contained",
                                        onClick: () => {
                                            formik.handleSubmit();

                                            setTimeout(() => {
                                                formik.resetForm();
                                            }, 2500);
                                        },
                                        sx: (t) => ({color: t.palette.background.paper})
                                    }
                                ]}
                            />
                        </Box>
                    </>
                )}
            </Formik>
        </Box>
    );
};

export default AddNewCustomerForm;
