import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { openSnackbar } from "store/slices/snackbar";
import { NetworkManager, API } from "network/core";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "ui-component/Loader";

function PreferredPricingView() {
    const dispatch = useDispatch();
    const [preferredPricing, setPreferredPricing] = useState([]);
    const [loading, setLoading] = useState(true);
    const prm = useParams();
    const profileId = prm?.type;

    const getPreferredPricingData = async () => {
        setLoading(true);
        const instance = NetworkManager(API.CUSTOMER.GETPREFERREDPRICING(`${profileId}`));

        const response = await instance.request();
        if (response.success) {
            setPreferredPricing(response?.data);
            setLoading(false);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    useEffect(() => {
        getPreferredPricingData();
    }, []);

    function formatDateToMMDDYYYY(dateString) {
        if (!dateString) {
            return "";
        }
        const date = new Date(dateString);
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    }

    function capitalizeFirstLetterOnly(string) {
        if (!string) return string; // Return the string as is if it's empty or null.
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    const preferredPricingData = [
        { label: "Discount value type", value: capitalizeFirstLetterOnly(preferredPricing?.discount_value_type) || "-" },
        {
            label: "Discount value",
            value:
                preferredPricing?.discount_value ? (preferredPricing?.discount_value_type === "PERCENTAGE"
                    ? `${preferredPricing?.discount_value || "-"}%`
                    : preferredPricing?.discount_value_type === "DISCOUNT"
                        ? `$${preferredPricing?.discount_value || "-"}`
                        : "-") : "-"
        },
        { label: "Start date", value: formatDateToMMDDYYYY(preferredPricing?.start_date) || "-" },
        { label: "End date", value: formatDateToMMDDYYYY(preferredPricing?.end_date) || "-" }
    ];

    return (
        <>
            {loading && <Loader />}
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{ maxWidth: "80%" }}>
                        <Grid
                            container
                            sx={{
                                borderRadius: "4px",
                                border: "1px solid #DBDBDB",
                                padding: "16px"
                            }}
                        >
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: "1.125rem", fontWeight: 500 }} color="#020A11">
                                    Preferred Pricing
                                </Typography>
                                <Grid container mb={2}>
                                    {preferredPricingData.map((item, index) => (
                                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index} mt={1.5}>
                                            <Typography sx={{ fontSize: "0.625rem", fontWeight: 400 }} color="#9e9e9e">
                                                {item.label}
                                            </Typography>
                                            <Typography sx={{ fontSize: "0.875rem", fontWeight: 500 }} color="#212121">
                                                {item.value}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default PreferredPricingView;
