import {Button, Dialog, DialogContent, DialogTitle, Stack} from "@mui/material";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import React, {useState} from "react";
import AnimateButton from "ui-component/extended/AnimateButton";
import styles from "./Driver.module.scss";
import Cancel from "../../assets/images/icons/cancel.svg";
import {Box} from "@mui/system";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {useStyles} from "./modalStyles";

const ManagerModal = (props) => {
    const {managers, handleClose, open, title, buttonTitle, selectedRow, setRefetch} = props;
    const [selectedManager, setSelectedManager] = useState(selectedRow?.accountManagerId || "");
    const dispatch = useDispatch();
    const muiStyles = useStyles();
    const [loading, setLoading] = useState(false);
    const handleUpdateManager = async (managerId) => {
        setLoading(true);
        const instance = NetworkManager(API.SUBADMIN.ASSIGNMANAGER);
        const response = await instance.request({order_id: selectedRow?.order_id, account_manager_id: managerId});
        if (response?.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleClose();
            setRefetch((prev) => !prev);
            setLoading(false);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
            setLoading(false);
        }
    };
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            hideBackdrop={false}
            PaperProps={{sx: muiStyles.paperprops}}
        >
            <Box
                sx={[muiStyles.crossIcon]}
                onClick={() => {
                    handleClose();
                }}
            >
                <img src={Cancel} alt="plus-icon" width="12px" height="12px" />
            </Box>
            <DialogTitle id="alert-dialog-title" className={styles.title} sx={{letterSpacing: "0.001px", padding: "0px"}}>
                {title}
            </DialogTitle>
            <DialogContent sx={[muiStyles.dialogContent]}>
                <Stack sx={[muiStyles.dialogStack]}>
                    <CustomSelect
                        noNone
                        label="Select manager"
                        MenuProps={{
                            PaperProps: {
                                sx: muiStyles.selectMenuPaperProps
                            }
                        }}
                        fullWidth
                        menuItems={managers}
                        value={selectedManager}
                        onChange={(e) => {
                            const managerId = e.target.value;
                            setSelectedManager(managerId);
                        }}
                    />
                </Stack>
                {selectedRow?.accountManagerId && (
                    <Button variant="text" sx={[muiStyles.unAssignButton]} onClick={() => handleUpdateManager(null)} disabled={loading}>
                        Unassign
                    </Button>
                )}
            </DialogContent>
            <AnimateButton>
                <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    disabled={loading}
                    sx={muiStyles.updateButton}
                    onClick={() => handleUpdateManager(selectedManager)}
                >
                    {buttonTitle}
                </Button>
            </AnimateButton>
        </Dialog>
    );
};

export default ManagerModal;
