import {Stack, Box, Typography} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import {ErrorMessage, Form, Formik} from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import React, {useState, useEffect} from "react";
import * as yup from "yup";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";

const formSchema = {
    categoryName: "",
    categoryDescription: "",
    categoryType : []
};

const AddCategoryForm = (props) => {
    const {toggleDrawer} = useTempDrawer();
    const [initialValues, setInitialValues] = React.useState(formSchema);
    const dispatch = useDispatch();
    const {handleIsEdited, selectedRow, handleSelectedRow, handleChange, updateCategory,handleClose} = props;

    useEffect(() => {
        if (selectedRow) {
            setInitialValues({
                categoryName: selectedRow.categoryName,
                categoryDescription: selectedRow.categoryDescription,
                categoryType :  selectedRow?.menu_type ? selectedRow?.menu_type : []
            });
        } else {
            setInitialValues({
                categoryName: "",
                categoryDescription: "",
                categoryType : []
            });
        }
    }, [selectedRow]);

    const handleAddCategory = async (values) => {
        const instance = NetworkManager(selectedRow ? API.CATEGORY.UPDATE(selectedRow.market_id) : API.CATEGORY.ADD);
        const payload = {
            categoryName: values.categoryName,
            description: values.categoryDescription,
            type : values.categoryType

        };

        const response = await instance.request(payload);

        if (response.success) {
            const DC = response.data?.category;
            const resData = {
                label: DC?.category_name,
                value: DC?.category_name,
                id: DC?.category_id,
                description: DC?.description,
                item: []
            };
            updateCategory(resData);

            dispatch(
                openSnackbar({
                    open: true,
                    message: selectedRow ? "Category updated successfully" : "Category created successfully",
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={yup.object({
                    categoryName: yup.string().required("Please enter category name"),
                    categoryDescription: yup.string().max(500, "Max character limit is 500"),
                    categoryType: yup.array().min(1, "Category type is required").required("Category type is required"),
                })}
                onSubmit={(val) => handleAddCategory(val)}
            >
                {(formik) => (
                    <Form>
                        <TemporaryDrawer
                            sideBarStateName="AddNewCategory"
                            handleSelectedRow={handleSelectedRow}
                            resetForm={formik.resetForm}
                            touched={formik.touched}
                            closingFunction={handleClose}
                        >
                            <Stack sx={{height: "100%"}} justifyContent="space-between">
                                <Stack spacing={4}>
                                    <PageHeader fontSize='1.625rem' title={selectedRow ? "Edit category" : "Create new category"} showOnlyTitle />
                                    <Stack spacing={3}>
                                        <CustomTextField
                                            placeholder="Name"
                                            label="Name"
                                            inputProps={{maxLength: 50}}
                                            {...formik.getFieldProps("categoryName")}
                                        />
                                        <ErrorMessage name="categoryName" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        <CustomSelectWithCheckbox
                                            selectAll
                                            label="Category type"
                                            menuItems={[
                                                {id: "item", label: "Item", value: "item"},
                                                {id: "modifier", label: "Modifier", value: "modifier"}
                                            ]}
                                            values={formik.values.categoryType}
                                            onChange={(val) => formik.setFieldValue("categoryType", val)}
                                        />
                                        <ErrorMessage name="categoryType" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        <CustomTextField
                                            placeholder="Description (optional)"
                                            multiline
                                            rows={3}
                                            label="Description (optional)"
                                            inputProps={{maxLength: 500}}
                                            {...formik.getFieldProps("categoryDescription")}
                                        />
                                        <ErrorMessage
                                            name="categoryDescription"
                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                        />
                                    </Stack>
                                </Stack>

                                <ButtonGrouping
                                    btnprops={[
                                        {
                                            btnTitle: "Cancel",
                                            sx: (t) => ({color: t.palette.error.dark}),
                                            onClick: (e) => {
                                                formik.resetForm();
                                                // toggleDrawer("right", false)(e);
                                                handleSelectedRow();
                                                handleChange("old");
                                            }
                                        },
                                        {
                                            btnTitle: "Save",
                                            variant: "contained",
                                            sx: (t) => ({color: t.palette.background.paper}),
                                            onClick: (e) => {
                                                formik.handleSubmit();

                                                formik.validateForm().then((res) => {
                                                    // to close the sidebar if no field has validation error
                                                    if (Object.keys(res).length === 0) {
                                                        toggleDrawer("right", false)(e);
                                                        setTimeout(() => {
                                                            formik.resetForm();
                                                            handleChange("old");
                                                        }, 1500);
                                                    }
                                                });
                                            }
                                        }
                                    ]}
                                />
                            </Stack>
                        </TemporaryDrawer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddCategoryForm;
