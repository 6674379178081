import {Grid} from "@mui/material";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {NetworkManager, API} from "network/core";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {useNavigate, useSearchParams} from "react-router-dom";
import FilterChips from "commonComponent/FilterChipsComponent";
import {generateUrl} from "utils/helperFunctions/helpers";
import Filters from "commonComponent/Filters";
import useTempDrawer from "hooks/useTempDrawer";
import AddNewUser from "../../AddNewUser/AddNewUser";
import {settings} from "../../data";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {setPageNumber, setRowsPerPage} from "store/slices/paginationSlice";

// import CreateCustomerForm from "../../CreateCustomer/CreateCustomerForm";

const Roles = (props) => {
    const {toggleDrawer} = useTempDrawer();
    const {search, showFilter, setSelectedCheckbox, updateListCount, selectedCheckbox} = props;
    const [title, setTitle] = useState("Create Customer");

    const [open, setOpen] = React.useState(false);
    const [rolesListing, setRolesListing] = React.useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [settingListng, setSettingListing] = useState(settings);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const [orderBy, setOrderBy] = useState("role_name");
    const [order, setOrder] = useState("asc");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [loading, setLoading] = useState(false);
    const [filterChips, setFilterChips] = useState({});
    const [urlSearchParams] = useSearchParams();

    const navigation = useNavigate();
    const tab = urlSearchParams.get("tab");
    const modifierId = urlSearchParams.get("id");

    const dispatch = useDispatch();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const getRoleList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.ROLES.LISTING);
        const params = {
            offset: currentPage,
            limit: rowsPerPage,
            // page: currentPage,
            // pageSize: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };
        if (orderBy) {
            params.sortBy = orderBy;
            params.orderBy = order;
        }

        if (search === "") {
            params.page = currentPage;
        }
        if (search !== "") {
            params.role_name = search;
            setRolesListing([]);
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);

        if (response.success) {
            setRolesListing(response?.data?.roles);
            setTotalItems(response.data?.pagination?.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
        // setCurrentPage(number + 1);
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        // setRowsPerPage(number);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };

    const handleStatus = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.ROLES.STATUS(values.role_id));
        let isActive = false;
        if (type === "Deactivate") {
            isActive = false;
        } else {
            isActive = true;
        }
        const payload = {
            is_active: isActive,
            role_name: values.role_name
        };

        const response = await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );

            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                navigation(`/setting/role?tab=role&edit=true&roleID=${row.role_id}`);
                break;
            }
            case "Deactivate": {
                handleStatus(row, type);
                break;
            }
            case "Activate": {
                handleStatus(row, type);
                break;
            }

            case "Delete": {
                handleDeleteModal();
                break;
            }
            default:
                break;
        }
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.ROLES.DELETE(selectedRow.role_id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Role deleted successfully",
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getRoleList();
    };

    const handleSort = (val1, val2) => {
        setOrder(val1);
        setOrderBy(val2);
    };

    useEffect(() => {
        if (search) {
            dispatch(setPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        getRoleList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, order, orderBy, isEdited, filterChips, updateListCount]);

    const filterState = [
        {
            key: "full_name",
            label: "Username",
            type: "textfield"
        }
    ];

    // const handleApplyFilter = (Morefilters) => {
    //     setCurrentPage(1);
    //     setFilterChips(Morefilters);
    // };
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <FilterChips filterChips={filterChips} setFilterChips={setFilterChips} />
                    <CommonTable
                        loading={loading}
                        data={rolesListing}
                        notCaptilize
                        header={[
                            {key: "role_name", label: "Role"},
                            {key: "descriptioncat", label: "Description"},
                            {key: "permissions", label: "Permissions"},

                            WithPermission({module: "Roles", permission: ["update_permission", "delete_permission"]}) && {
                                key: "action",
                                label: "Action"
                            }
                        ].filter((el) => el)}
                        actions={[
                            WithPermission({module: "Roles", permission: ["update_permission"]}) && "Edit",
                            WithPermission({module: "Roles", permission: ["delete_permission"]}) && "Delete"
                        ]}
                        handleActionBtn={handleActionBtn}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage - 1}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                    />
                </Grid>
            </Grid>

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this Role?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
        </>
    );
};

export default Roles;
