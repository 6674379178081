import useTempDrawer from "hooks/useTempDrawer";
import {API, NetworkManager} from "network/core";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {dispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";

const defaultInitialState = {
    // item Details
    menuCaterogyMapping: {},
    is_active: true,
    name: "",
    sku: "",
    menus: [],
    category_id: [],
    price: "",
    description: "",
    dietary: [],
    label: "",
    customName: "",
    modifier_group_id: [],
    itemThumbnail: [],
    itemPersonalizationPhoto: [],

    // settings
    minimumOrderQuantity: "",
    servingSize: "",
    // portionSize: "",
    // unit: "",
    temperatureServed: ["HOT"],
    // packaging: ["tray"],
    // individual: "1.00",
    kitchenNote: "",
    customerNote: "",
    item_type: 1
};

const useNewItemDetailController = (props) => {
    const {
        setTitle = () => {},
        handleIsEdited = () => {},
        menuid = false,
        catId = false,
        handleAddItemToCateogoryList = () => {},
        handleItemDrawerClose = () => {},
        selectedRow = false,
        fromAddNewMenu
    } = props;

    const {toggleDrawer} = useTempDrawer();

    const navigation = useNavigate();
    const [searchparam] = useSearchParams();
    const menuName = searchparam.get("menuName");

    const prams = useParams();
    const {id, type} = prams;

    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState(defaultInitialState);
    const [labelList, setLabelList] = useState([]);

    const getLabel = async () => {
        const instance = NetworkManager(API.LABEL.GET);
        const response = await instance.request();
        if (response.success) {
            const states = response.data?.labelList?.map((state) => ({
                label: state.label_name?.toLowerCase(),
                value: state.label_id,
                id: state.label_id
            }));
            setLabelList([...states, {label: "Custom", value: "CUSTOM", id: "CUSTOM"}]);
        }
    };

    const createLabel = async (val) => {
        const instance = NetworkManager(API.LABEL.ADD);
        const payload = {
            label_name: val.toUpperCase()
        };
        const response = await instance.request(payload);
        if (response.success) {
            const temp = {
                label: response.data.label.label_name,
                value: response.data.label.label_id,
                id: response.data.label.label_id
            };
            setLabelList([...labelList, temp]);

            return response.data.label.label_id;
        }
        return null;
    };

    const handleAddItem = async (values, setSubmitting) => {
        setLoading(true);
        setSubmitting(true);
        const instance = NetworkManager(id || selectedRow?.item_id ? API.ITEM.UPDATE(id || selectedRow?.item_id) : API.ITEM.ADD);

        // const setPackaging = () => {
        //     let array = [];
        //     if (values.packaging.includes("individual") !== -1) {
        //         array = values.packaging.map((el) => ({
        //             packaging_name: el,
        //             ...(el === "individual" && {packaging_price: values.individual})
        //         }));
        //     } else {
        //         array = values.packaging.map((el) => ({
        //             packaging_name: el
        //         }));
        //     }
        //     return array;
        // };

        const createMenuCategory = (menuData, mapping, category) => {
            const arr = [];

            menuData.forEach((menuId) => {
                const obj = {
                    menu_id: menuId,
                    category_id: catId ? [catId] : mapping[menuId] && mapping[menuId]?.filter((el) => category.includes(el))
                };
                arr.push(obj);
            });
            return arr;
        };
        const payload = {
            name: values.name,
            ...(values.item_type === 1 && {menuCategory: createMenuCategory(values.menus, values.menuCaterogyMapping, values.category_id)}),
            price: values.price,
            sku: values.sku,
            description: values.description,
            ...(values.item_type === 1 && {dietary: values.dietary}),

            ...(values.item_type === 1 && {
                itemThumbnail: values.itemThumbnail.map((item) => ({
                    imagePath: item,
                    type: "thumbnail"
                }))
            }),
            ...(values.item_type === 1 && {
                itemPersonalizationPhoto: values.itemPersonalizationPhoto.map((item) => ({
                    imagePath: item,
                    type: "personalization"
                }))
            }),

            ...(values.item_type === 1 && {minimum_order_quantity: values.minimumOrderQuantity}),
            ...(values.item_type === 1 && {serving_size: values.servingSize}),
            // portion_size: values.portionSize,
            // unit: values.unit,
            ...(values.item_type === 1 && {temperature_served: values.temperatureServed[0]}),
            ...(values.item_type === 1 && {kitchen_note: values.kitchenNote}),
            ...(values.item_type === 1 && {customer_note: values.customerNote}),

            item_type: values.item_type
            // packaging: setPackaging()
        };
        if (values.item_type === 1) {
            if (values.label === "CUSTOM") {
                // const index = values.label.indexOf("CUSTOM");
                // if (index > -1) {
                //     values.label.splice(index, 1);
                // }
                const labelid = await createLabel(values.customName);
                payload.label = [labelid];
            } else {
                payload.label = values?.label !== "" ? [values.label] : [];
            }
            if (values.modifier_group_id.length > 0) {
                payload.modifier_group_id = values.modifier_group_id?.map((el, index) => {
                    return {series_no: index, modifier_group_id: el?.modifier_group_id};
                });
            } else {
                payload.modifier_group_id = [];
            }
        }
        const response = await instance.request(payload);
        if (response.success) {
            if (catId) {
                if (selectedRow) {
                    const Idetail = response.data.item.itemDetails;
                    console.log(Idetail, "185");
                    const dietary = response.data.item.itemDietary;
                    handleAddItemToCateogoryList({
                        item_id: Idetail?.item_id,
                        item_review: Idetail?.item_review,
                        is_active: Idetail?.is_active,
                        is_deleted: Idetail?.is_deleted,
                        item_name: Idetail?.item_name,
                        price: Idetail?.price,
                        sku: Idetail?.sku,
                        description: Idetail?.description,
                        minimum_order_quantity: Idetail?.minimum_order_quantity,
                        serving_size: Idetail?.serving_size,
                        // portion_size: Idetail?.portion_size,
                        // unit: Idetail?.unit,
                        temperature_served: Idetail?.temperature_served,
                        kitchen_note: Idetail?.kitchen_note,
                        customer_note: Idetail?.customer_note,
                        updatedAt: Idetail?.updatedAt,
                        createdAt: Idetail?.createdAt,
                        deleted_at: Idetail?.deleted_at,
                        menCategoryItem: Idetail.menuCategoryItem,
                        modifierGroup: Idetail.itemModifierGroup,
                        itemDietary: dietary,
                        label: Idetail?.itemLabel,
                        item_type: Idetail?.item_type,
                        thumbnailImage: [],
                        personalizationImage: []
                        // ItemPackaging: Idetail?.itemPackaging
                    });
                } else {
                    handleAddItemToCateogoryList(response.data.item);
                }
                handleItemDrawerClose();
            }
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    transition: "Fade",
                    close: true
                })
            );
            if (id) {
                navigation(menuName !== "null" ? `/menus/menuDetail/${type}/?tab=menuItems&menuName=${menuName}` : `/menus?tab=menuItems`);
            } else {
                handleIsEdited();
                toggleDrawer("right", false)();
            }
        } else {
            setInitialValues({...values});
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }

        setSubmitting(false);
        setLoading(false);
    };

    const getItemData = async () => {
        setLoading(true);
        const instance = NetworkManager(API.ITEM.GET);

        const response = await instance.request({}, [selectedRow ? selectedRow?.item_id : id]);
        if (response.success) {
            const item = response.data.item;
            setTitle(item.item_name);

            // const getIndividual = () => {
            //     const data = item.itemPackaging.filter((el) => el.packaging_name?.toLowerCase() === "individual");

            //     if (data.length && data[0].packaging_name?.toLowerCase() === "individual") {
            //         return data[0].packaging_price;
            //     }
            //     return "1.00";
            // };

            const payload = {
                fromAddNewMenu: fromAddNewMenu || false,
                is_active: item.is_active,
                name: item.item_name,
                price: item.price,
                sku: item.sku,
                menus: item.menuCategoryItem.map((obj) => obj?.menuCategory?.menu_id),
                category_id: item.menuCategoryItem.map((obj) => obj?.menuCategory?.category_id),
                description: item.description,
                item_type: item.item_type,
                dietary: item.itemDietary.map((el) => el.dietary),
                label: item.itemLabel.map((el) => el.label.label_id).join(""),
                modifier_group_id: item.itemModifierGroup
                    ?.sort((a, b) => a.series_no - b.series_no)
                    ?.map((el) => ({
                        id: el.modifierGroup.modifier_group_id,
                        label: el.modifierGroup.modifier_group_name,

                        ...el.modifierGroup,
                        series_no: el.series_no
                    })),
                itemThumbnail: item.itemImage.filter((el) => el.type === "thumbnail").map((el) => el.image),
                itemPersonalizationPhoto: item.itemImage.filter((el) => el.type === "personalization").map((el) => el.image),
                // settings Data

                minimumOrderQuantity: Math.floor(Number(item.minimum_order_quantity)),
                servingSize: Math.floor(Number(item.serving_size)),
                // portionSize: item.portion_size,
                // unit: item.unit,
                temperatureServed: item.temperature_served ? [item.temperature_served] : ["HOT"],
                // packaging: item.itemPackaging?.length
                //     ? item.itemPackaging.map((el) => (el.packaging_name?.toLowerCase() === "individual" ? "individual" : "tray"))
                //     : ["tray"],
                // individual: getIndividual(),
                kitchenNote: item.kitchen_note,
                customerNote: item?.customer_note
            };

            setInitialValues(payload);
            // handleSelectedRow(null);
        }
        setLoading(false);
    };

    useEffect(() => {
        getLabel();
        if (id || selectedRow) {
            getItemData();
        }
        if (menuid) {
            setInitialValues({
                ...defaultInitialState,
                menus: [menuid],
                ...(catId && {category_id: [catId]}),
                fromAddNewMenu: fromAddNewMenu || false
            });
        }
    }, []);

    return {id, type, loading, initialValues, labelList, handleAddItem};
};

export default useNewItemDetailController;
