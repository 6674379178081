import {ClickAwayListener, IconButton, Typography} from "@mui/material";
import {Stack} from "@mui/system";
import {useUserSession} from "hooks/userSession";
import React, {useEffect, useState} from "react";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import EditIcon from "../../../../../../assets/logo/editOrderIcon.svg";
import {useSelector, useDispatch} from "react-redux";
import {setCartOrderName} from "store/slices/ordername";

function OrderName({orderName, cartName, setCartName}) {
    // const headerData = useSelector((state) => state.headerdata);
    // let cartOrderName = headerData?.cartOrderName;

    // useEffect(() => {
    //     if (orderName) {
    //         cartOrderName = orderName;
    //     }
    // }, [orderName]);

    // console.log("Order Name : ",cartOrderName, orderName)

    const userSession = useUserSession();
    const dispatch = useDispatch();
    const [isEditable, setIsEditable] = useState(false);

    // console.log("cartName : ", cartName);

    useEffect(() => {
        setCartName(orderName || "Order name");
    }, [orderName]);

    const handleEditable = () => {
        setIsEditable(true);
        if (cartName === "Order name") {
            setCartName("");
        }
    };

    const handleClose = async () => {
        const nameToSave = cartName.trim() === "" ? "Order name" : cartName;

        // if (userSession.isValidSession() && orderId) {
        //     const instance = NetworkManager(API.ORDER.UPDATE(orderId));
        //     const response = await instance.request(cartPayload);
        //     console.log("Res : ",response)
        //     if (response.success) {
        //         // dispatch(setCartOrderName(nameToSave));
        //     }
        // } else {
        // dispatch(setCartOrderName(nameToSave));
        // }
        dispatch(setCartOrderName(nameToSave));

        setCartName(nameToSave);
        setIsEditable(false);
    };

    const handleChange = (e) => {
        const value = e.target.value;
        // Prevent leading whitespace
        if (value.length === 0 || value[0] !== " ") {
            setCartName(value);
        }
    };

    function shortenText(text) {
        if (typeof text !== "string") {
            return "";
        }

        // Extract the first 20 letters
        let shortenedText = text.substring(0, 20);

        // Append ellipsis if the original text is longer than 20 characters
        if (text.length > 20) {
            shortenedText += "...";
        }

        return shortenedText;
    }

    return isEditable ? (
        <Stack direction="row" alignItems="center" mt={0.2}>
            <ClickAwayListener onClickAway={handleClose} r>
                <CustomTextField
                    sx={{
                        width: 148,
                        height: 52
                    }}
                    value={cartName}
                    placeholder="Enter Order name"
                    onChange={handleChange}
                    inputProps={{
                        maxLength: 250
                    }}
                />
            </ClickAwayListener>
        </Stack>
    ) : (
        <Stack direction="row" alignItems="center" mt={0.2}>
            <Typography variant="h7">{shortenText(cartName)}</Typography>
            <IconButton disableRipple onClick={handleEditable}>
                <img src={EditIcon} alt="edit_icon" />
            </IconButton>
        </Stack>
    );
}

export default OrderName;
