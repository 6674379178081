import {Grid} from "@mui/material";
import React, {useEffect, useLayoutEffect, useState, useContext} from "react";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import CustomizedTabs from "commonComponent/NewTab";
import {useNavigate, useSearchParams} from "react-router-dom";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import Customer from "./Tabs/Customer/Customer";
import HouseAccounts from "./Tabs/HouseAccounts/HouseAccounts";
import useTempDrawer from "hooks/useTempDrawer";
import CreateCustomerForm from "./CreateCustomer/CreateCustomerForm";
import {NetworkManager, API} from "network/core";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {CsvBuilder} from "filefy";
import Fields from "commonComponent/Apptable/getFieldsForDownload";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {useCustomerController} from "./customers.controller";
import Filter from "commonComponent/FilterAccoridan";
import FilterChips from "commonComponent/FilterChipsComponent";
import {setShowFilter, resetState} from "store/slices/paginationSlice";
import {getManagers} from "utils/helperFunctions/helpers";
import { FilterContext } from "contexts/FilterContext";

const CustomerComp = () => {
    const {toggleDrawer} = useTempDrawer();
    const {getCustomerListing, filterState, getCompanyListing, getCustomerEmail, setCustomerEmail} = useCustomerController();
    const [value, setValue] = useState("customer");
    const [btnlabel, setBtnLebel] = useState("Create customer");
    const [heading, setHeading] = useState("Customers");
    const [search, setSearch] = useState("");
    const {handleFilter} = useContext(FilterContext);
    const filterChips = useSelector((state) => state?.pagination?.filterChips);
    const showFilter = useSelector((state) => state?.pagination?.showFilter);
    const [managers, setManagers] = useState([]);
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [updateListCount, setUpdateListCount] = useState(1);
    const [searchedValue, setSearchedValue] = useState({});
    const [filterValue, setFilterValue] = useState("");
    const [urlSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tab = urlSearchParams.get("tab");
    const OrderId = urlSearchParams.get("d");

    useLayoutEffect(() => {
        if (searchedValue?.search === "") {
            setManagers([]);
        } else if (searchedValue?.search) {
            getManagers(setManagers, () => {}, searchedValue?.search);
        }
    }, [searchedValue?.search]);

    const handleButtonLabelandFilter = (type) => {
        switch (type) {
            default:
                setBtnLebel("Create customer");
                break;
        }
    };
    useEffect(() => {
        if (tab === "customer" && searchedValue?.customerName) {
            getCustomerListing(searchedValue?.customerName);
        }
    }, [searchedValue]);
    useEffect(() => {
        if (searchedValue?.email === "") {
            setCustomerEmail([]);
        } else {
            // eslint-disable-next-line no-unused-expressions
            tab === "customer" && getCustomerEmail(searchedValue?.email);
        }
    }, [searchedValue?.email]);
    useEffect(() => {
        if (searchedValue?.companyName) {
            getCompanyListing(searchedValue?.companyName);
        }
    }, [searchedValue]);
    useEffect(() => {
        setSelectedCheckbox([]);
        handleButtonLabelandFilter(tab);
    }, [window.location.search]);

    useEffect(() => {
        setValue(tab);
        let search = `?tab=${tab}`;
        if (OrderId) {
            search = `?tab=${tab}&orderId=${OrderId}`;
        }
        navigate({
            pathname: window.location.pathname,
            search: search
        });
    }, []);

    useEffect(() => {
        // is user edits the url query then we need to redirect it to default tab

        if (tab == null || (tab !== "customer" && tab !== "house_accounts")) {
            setValue("customer");
            let search = `?tab=${"customer"}`;
            if (OrderId) {
                search = `?tab=${"customer"}&orderId=${OrderId}`;
            }
            navigate({
                pathname: window.location.pathname,
                search: search
            });
        }
    }, [tab]);
    // const getFilterState = () => {
    //     return tabFilterState(tab);
    // };

    const handleTabChange = (event, newValue) => {
        dispatch(resetState());
        setSearch("");
        setValue(newValue);

        let search = `?tab=${newValue}`;
        if (OrderId) {
            search = `?tab=${newValue}&orderId=${OrderId}`;
        }
        navigate({
            pathname: window.location.pathname,
            search: search
        });
    };

    const handleSearch = (val) => {
        const trimmedValue = val.trim();
        setSearch(trimmedValue);
    };

    const handleDeleteBulk = async () => {
        const instance = NetworkManager(API.CUSTOMER.BULKDELETE);
        const payload = selectedCheckbox.map((row) => {
            const list = JSON.parse(row);
            return list.user_id;
        });

        const response = await instance.request({customerIds: payload.join(",")});
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            setSelectedCheckbox([]);
            setUpdateListCount((pre) => pre + 1);
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedCheckbox([]);
        // getUnplacedOrderList();
    };
    const filteredFilterState =
        value === "house_accounts"
            ? filterState.filter((item) => item?.key === "company_name")
            : [
                  ...filterState,
                  {
                      key: "account_manager_id",
                      label: "Account manager",
                      type: "select",
                      getData: managers,
                      freeSolo: true
                  }
              ];

    const handleHeaderActions = (btntype) => (e) => {
        switch (btntype) {
            case "Create customer": {
                toggleDrawer("right", "createCustomer")(e);
                dispatch(setShowFilter(false));
                break;
            }
            case "none": {
                dispatch(setShowFilter(!showFilter));
                handleFilter();
                break;
            }
            default: {
                break;
            }
        }
    };

    const columnData =
        tab === "customer"
            ? [
                  {key: "full_name", label: "Customer"},
                  {key: "email", label: "Email"},
                  {key: "company.company_name", label: "Company"},
                  {key: "customerAddresses.delivery_address", label: "Address"},
                  {key: "orderCount", label: "Orders"},
                  {key: "lifeTimeValue", label: "LTV"},
                  {key: "averageOrderValue", label: "AOV"},
                  {key: "last_order", label: "Last order"},
                  {key: "isActivate", label: "Status"},
                  {key: "createdAt", label: "Created"}
              ]
            : [
                  {key: "full_name", label: "Customer"},
                  {key: "company.company_name", label: "Company"},
                  {key: "card.orders", label: "Orders"},
                  {key: "card.balance", label: "Balance"},
                  {key: "card.createdAt", label: "Created"}
              ];

    const csvBuilder = () => {
        new CsvBuilder("Customer_list.csv")
            .setColumns(columnData.map((col) => col.label))
            .addRows(
                selectedCheckbox.map((row) =>
                    columnData.map((col) => {
                        const arr = JSON.parse(row);

                        return Fields(col.key, arr);
                    })
                )
            )
            .exportFile();
        setSelectedCheckbox([]);
    };

    const TabsData = [
        {
            value: "customer",
            label: "Customers",
            comp: () => (
                <Customer
                    search={search}
                    filterChips={filterChips}
                    setHeading={setHeading}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                    setSearchedValue={setSearchedValue}
                    setFilterValue={setFilterValue}
                />
            )
        },
        {
            value: "house_accounts",
            label: "House accounts",
            comp: () => (
                <HouseAccounts
                    search={search}
                    setHeading={setHeading}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                    filterChips={filterChips}
                    setSearchedValue={setSearchedValue}
                    setFilterValue={setFilterValue}
                />
            )
        }
    ];

    return (
        <Grid container>
            <CreateCustomerForm sideBarStateName="createCustomer" />
            <Grid item xs={12}>
                <TableTopFilterWrapper
                    lessmarginOnTop
                    pageheader={<PageHeader title={heading} />}
                    btnTitle={WithPermission({module: "Customers", permission: "create_permission"}) && btnlabel}
                    // csvButton={csvButtonLabel}
                    handleClick={handleHeaderActions}
                    handleSearch={handleSearch}
                    filterType="none"
                    orderFilter="orderFilter"
                    selectedCheckbox={selectedCheckbox}
                    handleDeleteBulk={WithPermission({module: "Customers", permission: "delete_permission"}) && handleDeleteBulk}
                    csvBuilder={csvBuilder}
                    deleteBulkTitle="customers"
                >
                    <Filter
                        filterState={filteredFilterState}
                        // setFilterState={setFilterChips}
                        filterChips={filterChips}
                        setSearchedValue={setSearchedValue}
                        filterValue={filterValue}
                        showFilter={showFilter}
                    />
                    <FilterChips
                        filterChips={filterChips}
                        //  setFilterChips={setFilterChips}
                        filterState={filteredFilterState}
                        getLabel={managers}
                    />
                    <CustomizedTabs tabsInfo={TabsData} value={value} handleChange={handleTabChange} />
                </TableTopFilterWrapper>
            </Grid>
        </Grid>
    );
};

export default CustomerComp;
