import {NetworkManager, API} from "network/core";

export const useOrderModel = () => {
    const getMarketList = async (params) => {
        const instance = NetworkManager(API.MARKET.GET);
        const response = await instance.request({}, params);
        return response;
    };
    const getBrandList = async (params) => {
        const instance = NetworkManager(API.BRAND.GET);
        const response = await instance.request({}, params);
        return response;
    };
    const getCustomerListing = async (searchedVlaue) => {
        const instance = NetworkManager(API.CUSTOMER.GET);
        const response = await instance.request({}, [`?full_name=${searchedVlaue}`]);
        return response;
    };
    const getCompanyListing = async (searchedVlaue) => {
        const instance = NetworkManager(API.COMPANY.GET);
        const response = await instance.request({}, [`?company_name=${searchedVlaue}`]);
        return response;
    };
    return {
        getMarketList,
        getBrandList,
        getCustomerListing,
        getCompanyListing
    };
};
