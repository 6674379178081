import React from "react";
import {Button, Box, Dialog} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import warning from "./popup-warn.svg";
import styles from "./appTable.module.scss";
import {useStyles} from "./AppTableStyles";

const ConfrimDialog = ({title, open, handleClose, confirm}) => {
    const style = useStyles();
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{style: style?.confirmDialog.paper}}
            disableBackdropClick
            maxWidth="sm"
            fullWidth
        >
            <DialogContent style={style?.confirmDialog.dialog}>
                <Box className={styles.imageBox}>
                    <img src={warning} alt="." />
                </Box>
                <Box className={styles.content}>{title}</Box>
                <Box className={styles.footer}>
                    <Button variant="outlined" onClick={handleClose} style={style?.confirmDialog.button}>
                        Cancel
                    </Button>
                    <Button variant="outlined" onClick={confirm}>
                        Confirm
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ConfrimDialog;
