import React, { useState, useRef, useEffect } from 'react';
import Box from "@mui/material/Box";
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails, LinearProgress } from "@mui/material";
import SearchSection from "commonComponent/SearchSection";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NetworkManager, API } from "network/core";
import { getMonDateYear } from "utils/app-dates/dates";
import useOrderDialog from 'hooks/useOrderDialog';
import TemplateDetail from '../templateOrderDetail/index'
import { generateUrl,FormatCurrency, commaFormattedNumber } from "utils/helperFunctions/helpers";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import { ellipsizeText } from 'commonComponent/Apptable/textUtils';



const TemplateOrder = ({ formik }) => {
    const [expanded, setExpanded] = useState(false);
    const [templateList, setTemplateList] = useState(null);
    const [mainOrderList, setMainOrderList] = useState(null)
    const [visibleOrders, setVisibleOrders] = useState(3);
    const [orderDetailId, setOrderId] = useState(null)
    const [search, setSearch] = useState("");
    const { open, toggleOrderdetailDialog, handlePastOrder, pastOrderId, orderIngFromPast, touchFormikFields, setTouchFormikFields } = useOrderDialog();

    const contentRef = useRef(null);

    const getTemplateList = async (orderId) => {
        // while changing brand kitchen location should be emptied
        const instance = NetworkManager(API.ORDER.GETTEMPLATELIST);
        const params = {
            limit: 5000,
            offset: 1
        };

        if (search !== "") {
            params.search = search
        }

        if (formik.values.selectedBrand !== "") {
            params.brandIds = formik.values.selectedBrand
        }
        if (formik.values.selectedMenu !== "") {
            params.menuIds = formik.values.selectedMenu

        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);
        if (response.success) {
            const states = response.data?.orderTemplate?.map((state) => ({
                id: state.order_template_id,
                order_number: state.order_template_name,
                order_id: state.order_template_id,
                delivery_date: state.created_at,
                no_of_guest: state.no_of_guest,
                total_price: state.total_price
            }));
            setTemplateList(states);
            setMainOrderList(states)
        }

    };


    useEffect(() => {
        getTemplateList()
    }, [])

    useEffect(() => {

        getTemplateList()

    }, [search, formik.values.selectedBrand, formik.values.selectedMenu])

    const handleSeeMore = () => {
        setVisibleOrders(mainOrderList.length);
        setExpanded(pre => !pre)
    };

    const handleSeeLess = () => {
        setVisibleOrders(3);
        setExpanded(pre => !pre)
    };
    const handleTouch = (formik) => {
        const allFields = Object.keys(formik.values);
        const touchedFields = {};

        allFields.forEach((field) => {
            touchedFields[field] = true;
        });

        formik.setTouched(touchedFields);
        formik.setFieldTouched("headerData.deliveryTo", true)
        formik.setFieldTouched("headerData.forHowMany", true)
        formik.setFieldTouched('serviceFees.value', true)
        formik.setFieldTouched('drive_time', false)
    }

    useEffect(() => {
        if (touchFormikFields) {
            handleTouch(formik)
            setTouchFormikFields(false)
        }

    }, [touchFormikFields])

    const handleOrderClick = (orderId) => {

        handlePastOrder(orderId, 'templateOrder')
        toggleOrderdetailDialog(false)()
    }



    const handleOrderDetail = (orderId) => {
        toggleOrderdetailDialog("TemplateDetail")()
        setOrderId(orderId)
    }

    const handleClose = () => {
        toggleOrderdetailDialog(false)()

    }

    return (
        <>
            {open === "TemplateDetail" && <TemplateDetail orderDetailId={orderDetailId} handleClose={handleClose} handleOrderClick={handleOrderClick} />}

            <Accordion sx={{
                width: "250px", backgroundColor: "transparent",
                "&.MuiAccordion-root": {
                    "&.Mui-expanded": {
                        margin: '0px',
                    }
                },
            }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content" id="panel1a-header"
                    sx={{
                        padding: 0,

                        flexDirection: "row-reverse",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 0,
                        minHeight: { xs: "16px", md: "32px" },

                        "&.Mui-expanded": {
                            minHeight: { xs: "32px !important", md: "8px" },
                            margin: "0px"
                        },
                        "& .MuiAccordionSummary-content": {
                            margin: "0px"
                        }
                    }}>
                    <Typography variant="h4" fontWeight={600}>Templates</Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        rowGap: "10px",
                        columnGap: "10px",
                        padding: 0
                    }}>
                    <Box sx={{ maxHeight: '400px', overflowY: 'auto', pr: 1 }} ref={contentRef}>

                        {templateList && templateList?.length === 0 && (
                            <CustomErrorMessage errorMessage="No template available" />
                        )}
                        {templateList && templateList?.slice(0, visibleOrders)?.map((col, ind) => (
                            <Grid
                                sx={{
                                    mb: 1,
                                    padding: '6px',
                                    backgroundColor: "#ffff",
                                    borderRadius: "4px",
                                    border: "1px solid #C2C2C2",
                                    background: "#FAFAFA",
                                    cursor: "pointer",
                                    maxHeight: '58px',
                                }}
                                item
                                xs={12}
                                onClick={() => handleOrderClick(col.order_id)}
                                key={ind + col.order_id}
                            >
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    {" "}
                                    <Typography sx={{ cursor: "pointer" }} variant="subtitle1">{ellipsizeText(col.order_number, 24)}</Typography>
                                    <Typography onClick={(e) => {
                                        e.stopPropagation()
                                        handleOrderDetail(col.order_id)
                                    }} variant="subtitle3" sx={{ zIndex: 9999, color: '#00AB3A', fontWeight: 600 }}>View details</Typography>
                                </Box>
                                {/* <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                                    <Typography variant="orderItemDescription">
                                        {col.delivery_date && getMonDateYear(col.delivery_date)}
                                        <span style={{ color: '#A3A4AC', fontWeight: 600, marginLeft: '4px', marginRight: '4px', fontSize: 22 }} />
                                        {commaFormattedNumber(col.no_of_guest)} People
                                    </Typography>

                                </Box> */}
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography variant="orderItemDescription">
                                        {commaFormattedNumber(col.no_of_guest)} people
                                    </Typography>
                                    <Typography variant="subtitle3">{FormatCurrency(col?.total_price)}</Typography>
                                </Box>
                            </Grid>
                        ))}
                        {templateList && templateList?.length > 2 && (
                            <Box mt={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {expanded ? (
                                    <Typography
                                        onClick={handleSeeLess}
                                        sx={{
                                            fontSize: "0.73rem",
                                            fontWeight: 600,
                                            textDecoration: "none",
                                            cursor: 'pointer',
                                            color: '#00AB3A',
                                            fontFamily: "Inter",
                                            textDecorationLine: "underline",
                                        }}

                                    > See less</Typography>

                                ) : (
                                    <Typography
                                        onClick={handleSeeMore}
                                        sx={{
                                            fontSize: "0.73rem",
                                            fontWeight: 600,
                                            textDecoration: "none",
                                            cursor: 'pointer',
                                            color: '#00AB3A',
                                            fontFamily: "Inter",
                                            textDecorationLine: "underline",
                                        }}

                                    >See more</Typography>

                                )}
                            </Box>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default React.memo(TemplateOrder);