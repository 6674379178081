import {useContext} from "react";
import {RbacContext} from "..";

const WithPermission = (props) => {
    const getModuleWisePermission = useContext(RbacContext);
    if (props?.module) {
        if (typeof props.permission === "object") {
            const havePermission = props?.permission.some(
                (el) => getModuleWisePermission?.permission?.permission?.[props.module]?.[el] === true
            );
            const child = havePermission ? props.children || true : null;
            // console.log(`Permission for ${props.module}:`, havePermission);
            return child;
        }
        let child = null;

        if (typeof props.module === "object") {
            const havePermission = props.module.some(
                (el) => getModuleWisePermission?.permission?.permission?.[el]?.[props.permission] === true
            );
            child = havePermission ? props.children || true : null;
            // console.log(`Permission for ${props.module}:`, havePermission);
        } else {
            child = getModuleWisePermission?.permission?.permission?.[props.module]?.[props.permission] ? props.children || true : null;
            // console.log(`Permission for ${props.module}:`, getModuleWisePermission?.permission?.permission?.[props.module]?.[props.permission]);
        }
        return child;
    } else {
        return getModuleWisePermission?.permission?.permission;
    }
};

export default WithPermission;
