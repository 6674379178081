import React, { useMemo, useCallback, useEffect } from "react";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import moment from "moment";
import styles from "./createOrder.module.scss";
import { Box, InputAdornment } from "@mui/material";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import { ErrorMessage } from "formik";
import { enforceFormat2 } from "utils/helperFunctions/helpers";
import _debounce from "lodash/debounce";
import { getformateDateUS, getFormattedTimeinAMPM, convertToLocal } from "utils/app-dates/dates";
import { ellipsizeText } from 'commonComponent/Apptable/textUtils';

import CustomNewDateTimePicker from "commonComponent/newDateAndTimePicker/CustomNewDateTimePicker";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid #fff`,

    "&:not(:last-child)": {
        borderBottom: 0
    },
    "&:before": {
        display: "none"
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<KeyboardArrowDownIcon sx={{ fontSize: "1.3rem", color: "#000000" }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    minHeight: "5px",
    height: "2rem",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(180deg)"
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1.0)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1.5),
    marginTop: "-0.6rem",
    marginLeft: "34px"
}));

export default function CustomizedAccordions({ formik }) {
    const [expanded, setExpanded] = React.useState("panel1");
    const { values, getFieldProps } = formik;
    const [maxView, setMaxview] = React.useState(50)

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };





    return (
        <Box sx={{ marginTop: "10px" }}>

            <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" sx={{ backgroundColor: "#fff" }}>
                    <Typography className={styles.headerTitle}>Special instructions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={10}>
                            <CustomTextField
                                multiline
                                minRows={2.5}
                                value={values.specialInstruction}
                                sx={{ "&": { height: "fit-content !important", backgroundColor: "#A0A7B9", maxWidth: '596px' } }}
                                fullWidth
                                label="Special instructions"
                                {...formik.getFieldProps("specialInstruction")}
                                onChange={(e) => {
                                    formik.setFieldValue("specialInstruction", e.target.value);
                                }}
                                inputProps={{ maxLength: 250 }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" sx={{ backgroundColor: "#fff" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography className={styles.headerTitle}>Kitchen note</Typography>
                        <Typography className={styles.semiDetail}>(not visible to customer)</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={10}>
                            <CustomTextField
                                multiline
                                minRows={2.5}
                                sx={{ "&": { height: "fit-content !important", backgroundColor: "#A0A7B9", maxWidth: '596px' } }}
                                fullWidth
                                label="Kitchen note"
                                inputProps={{ maxLength: 250 }}
                                {...formik.getFieldProps("kitchenNote")}
                                value={values.kitchenNote}
                                onChange={(e) => {
                                    formik.setFieldValue("kitchenNote", e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>

        </Box>
    );
}
