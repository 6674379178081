import React, {useEffect, useState} from "react";
import {NetworkManager, API} from "network/core";

const profileFormSchema = {
    newMenuID: "",
    selectedMenu: [],
    menuName: "",
    cuisine: "",
    category: [],
    scheduleName: "",
    image: [],
    locations: [],
    frequency: [],
    startDate: null,
    endDate: null,
    noEndDate: false,
    repeatEvery: 1,
    recurring: "Day",
    recurringValues: [],
    ends: "never",
    end_value_on: null,
    end_value_after: "",
    start_value_on: null,
    start_value_after: "",
    ends_value_on: null,
    ends_value_after: "",
    cut_off_hour: "",
    is_active: "true"
};

const useMenuController = () => {
    const [initialValues, setInitialValues] = useState(profileFormSchema);
    const [isEdit, setIsEdit] = useState(false);
    const [existingItemList, setExistingItemList] = useState([]);
    const [categorlyItemlist, setCategoryItemList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const getItemListUnassigned = async (menuId, id) => {
        setLoading(true);
        // setCategoryItemList([]);
        const instance = NetworkManager(API.ITEM.GETUNASSIGNEDUPDATED(menuId, id));
        // const params = {
        //     // page: 1,
        //     // pageSize: 5000,
        //     // sortBy: "item_name",
        //     // orderBy: "asc",
        //     menuId: menuId,
        //     categoryId: id
        // };
        // while searching we dont need page number
        const response = await instance.request({});
        if (response.success) {
            const temp = [];
            response.data.menuList?.forEach((element, index) => {
                const obj = {};
                obj.id = element.item_id;
                obj.label = `${element.item_name}`;
                obj.item_dietary = element.itemDietary;
                temp.push(obj);
            });
            const sortedArr = [...temp];
            sortedArr?.sort((a, b) => a?.label?.localeCompare(b?.label));
            setCategoryItemList(sortedArr);
            setLoading(false);
        }
    };

    const getItemList = async () => {
        const instance = NetworkManager(API.ITEM.GET);
        const params = {
            page: 1,
            pageSize: 5000,
            sortBy: "item_name",
            orderBy: "asc"
        };
        // while searching we dont need page number
        const response = await instance.request({}, params);
        if (response.success) {
            const temp = [];
            response.data.item.forEach((element, index) => {
                const obj = {};
                obj.id = element.item_id;
                obj.label = `${element.item_name}`;
                obj.item_dietary = element.itemDietary;
                temp.push(obj);
            });
            setExistingItemList(temp);
            // getItemListUnassigned()
        }
    };

    useEffect(() => {
        setInitialValues(profileFormSchema);
        getItemList();
    }, []);

    const updateInitialValues = (schema) => {
        setInitialValues(schema);
    };

    const weekList = [
        {label: "Monday", value: "Monday"},
        {label: "Tuesday", value: "Tuesday"},
        {label: "Wednesday", value: "Wednesday"},
        {label: "Thursday", value: "Thursday"},
        {label: "Friday", value: "Friday"},
        {label: "Saturday", value: "Saturday"},
        {label: "Sunday", value: "Sunday"}
    ];

    return {
        initialValues,
        setInitialValues,
        isEdit,
        setIsEdit,
        weekList,
        updateInitialValues,
        profileFormSchema,
        existingItemList,
        getItemListUnassigned,
        categorlyItemlist,
        setCategoryItemList,
        isLoading
    };
};

export default useMenuController;
