import React, {useEffect, useState} from "react";
import {Stack, Typography, useMediaQuery, useTheme, Box, Grid, InputAdornment, FormControlLabel, Checkbox, Switch} from "@mui/material";
import {openSnackbar} from "store/slices/snackbar";
import {dispatch} from "store";
import {NetworkManager, API} from "network/core";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";

import CustomTextField from "commonComponent/Form/Fields/CustomTextField";

import {ErrorMessage, Formik, Form} from "formik";
import styles from "./payment.module.scss";
import {useSelector} from "react-redux";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {addNewHouseCardValidation} from "constants/validationConstant";
import CustomRadioButton from "commonComponent/Form/Fields/CustomRadioButton";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import {enforceFormat, formatToPhone, enforceNumericAndMaxLength, enforceDateAndMaxLength} from "utils/helperFunctions/helpers";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";

const AddNewHouseCardForm = (props) => {
    const {setNewHouseCardFlag, userID, UserName, UserCompany} = props;
    const [pincode, setPincode] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [street, setStreet] = useState("");
    const [country, setCountry] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [customerCardId, setCustomerCardId] = useState("");

    const headerData = useSelector((state) => state.headerData);
    // const [achFlag,setAchFlag] = useState(false)

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up("sm"));

    const handleSaveDetails = async (detail, setSubmitting) => {
        setSubmitting(true);
        let paylaod = {};

        if (detail.cardType === "ACH") {
            paylaod = {
                card_type: "HOUSE_ACCOUNT",
                payment_term: detail.paymentTerm,
                echeck_type: "ACH",
                address_details: {
                    street_line: street,
                    city: city,
                    state: state,
                    pincode: pincode,
                    country: country
                }
            };
        } else {
            paylaod = {
                card_type: "HOUSE_ACCOUNT",
                payment_term: detail.paymentTerm,
                billing_first_name: detail.first_name,
                echeck_type: "Check",

                check_number: detail.checkNumber,
                address_details: {
                    street_line: street,
                    city: city,
                    state: state,
                    pincode: pincode,
                    country: country
                }
            };
        }

        const instance = NetworkManager(API.CARD.HOUSEACCOUNT(userID));

        const res = await instance.request(paylaod);

        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
            setNewHouseCardFlag(false);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }

        setSubmitting(false);
    };

    const stopPropagationForTab = (event) => {
        if (event.key === "Tab") {
            event.stopPropagation();
        }
    };

    const onError = (status, clearSuggestions) => {
        clearSuggestions();
    };

    const parseAddressComponents = async (addressComponents) => {
        if (addressComponents) {
            let newPincode = "";
            let newCity = "";
            let newState = "";
            let newStreetLine = "";
            let newCountry = "";
            for (let i = 0; i < addressComponents.length; i += 1) {
                const component = addressComponents[i];
                if (component.types.includes("postal_code")) {
                    newPincode = component.long_name;
                } else if (component.types.includes("locality")) {
                    newCity = component.short_name;
                } else if (component.types.includes("administrative_area_level_2")) {
                    newState += component.short_name + " "; // eslint-disable-line prefer-template
                } else if (component.types.includes("administrative_area_level_1")) {
                    newState += component.short_name;
                } else if (component.types.includes("premise")) {
                    newStreetLine += component.short_name + ", "; // eslint-disable-line prefer-template
                } else if (component.types.includes("subpremise")) {
                    newStreetLine += component.short_name + ", "; // eslint-disable-line prefer-template
                } else if (component.types.includes("route")) {
                    newStreetLine += component.short_name;
                } else if (component.types.includes("street_number")) {
                    newStreetLine += component.short_name + " "; // eslint-disable-line prefer-template
                } else if (component.types.includes("point_of_interest")) {
                    newStreetLine += component.short_name + ", "; // eslint-disable-line prefer-template
                } else if (component.types.includes("country")) {
                    newCountry = component.short_name;
                }
            }
            setPincode(newPincode);
            setCity(newCity);
            setState(newState);
            setStreet(newStreetLine);
            setCountry(newCountry);
        }
    };

    return (
        <>
            <Formik
                enableReinitialize
                validationSchema={addNewHouseCardValidation}
                initialValues={{
                    name: UserName,
                    company: UserCompany,
                    cardType: "Check",
                    paymentTerm: "DUE_ON_RECEIPT",

                    checkNumber: "1111111111"
                }}
                onSubmit={(val, {setSubmitting}) => handleSaveDetails(val, setSubmitting)}
            >
                {(formik) => (
                    <Form>
                        <Box sx={{height: "100%"}} direction="column" justifyContent="space-between">
                            <Box>
                                <Typography variant="headingOne" fontSize="1.625rem" color="#000000" fontWeight={700}>
                                    Customer
                                </Typography>

                                <Grid container gap={0.5} mt={2.5}>
                                    <Grid item xs={5.5}>
                                        <CustomTextField
                                            disabled
                                            fullWidth
                                            label="Customer name"
                                            placeholder="Name on card"
                                            {...formik.getFieldProps("name")}
                                        />
                                        <ErrorMessage name="name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                    <Grid item xs={5.5}>
                                        <CustomTextField
                                            disabled
                                            fullWidth
                                            label="Company (optional)"
                                            placeholder="Company (optional)"
                                            {...formik.getFieldProps("company")}
                                        />
                                        <ErrorMessage name="company" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                    <Grid item xs={11} mt={1} mb={0.5}>
                                        <Typography variant="headingOne" fontSize="1.062rem" color="#000A12">
                                            Invoice settings
                                        </Typography>
                                        <Typography className={styles.houseDetail} mt={0.5}>
                                            Payment terms
                                        </Typography>
                                        <Typography className={styles.houseDetail} mt={0.5}>
                                            Days until payment is due, upon invoice generation
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <CustomRadioButton
                                            options={[
                                                {
                                                    label: "Due on receipt",

                                                    value: "DUE_ON_RECEIPT"
                                                },
                                                {label: "Net 10", value: "NET_10"},
                                                {label: "Net 15", value: "NET_15"},
                                                {label: "Net 30", value: "NET_30"},
                                                {label: "Net 60", value: "NET_60"}
                                            ]}
                                            value={formik.values.paymentTerm}
                                            onChange={(e) => formik.setFieldValue("paymentTerm", e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={11} mt={1.8}>
                                        <CustomSelect
                                            // disabled={marketName || readOnly}
                                            label="Payment type"
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        maxHeight: 200
                                                    }
                                                }
                                            }}
                                            fullWidth
                                            noNone
                                            menuItems={[
                                                {
                                                    label: "Check",
                                                    id: "Check"
                                                },
                                                {
                                                    label: "ACH",
                                                    id: "ACH"
                                                }
                                            ]}
                                            {...formik.getFieldProps("cardType")}
                                        />
                                    </Grid>
                                   
                                   
                                    {formik.values.cardType === "NOT REQUIRED" && (
                                        <Grid item xs={11} mb={1} mt={1}>
                                            <CustomTextField
                                                fullWidth
                                                label="Check number"
                                                type="number"
                                                placeholder="Check number"
                                                {...formik.getFieldProps("checkNumber")}
                                            />
                                            <ErrorMessage name="checkNumber" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                    )}
                            
                                </Grid>
                            </Box>
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item sx={{position: "absolute", bottom: 0}} xs={12}>
                                        <Box
                                            sx={{
                                                mt: 2,
                                                p: 2,
                                                bgcolor: "#fff",
                                                zIndex: 1,
                                                display: "flex",
                                                alignItems: "end",
                                                marginLeft: "9rem"
                                            }}
                                        >
                                            <ButtonGrouping
                                                btnprops={[
                                                    {
                                                        btnTitle: "Cancel",
                                                        sx: (t) => ({color: t.palette.error.dark}),
                                                        onClick: (e) => {
                                                            formik.resetForm();
                                                            setNewHouseCardFlag(false);
                                                        }
                                                    },
                                                    {
                                                        btnTitle: "Save",
                                                        variant: "contained",
                                                        disabled: formik.isSubmitting,
                                                        onClick: () => {
                                                            formik.handleSubmit();
                                                        },
                                                        sx: (t) => ({color: t.palette.background.paper})
                                                    }
                                                ]}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddNewHouseCardForm;
