import React from "react";

import OrderView from "./OrderView";

function OrderQuoteDetail() {
    return (
        <>
            <OrderView />
        </>
    );
}

export default OrderQuoteDetail;
