import {Box, Menu, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import CustomRadioButton from "commonComponent/Form/Fields/CustomRadioButton";
import { ErrorMessage} from "formik";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";

const OrderType = (props) => {
    const {Parentformik} = props;
    const {headerData} = Parentformik.values;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Stack
                nowrap
                sx={{minWidth: 0, cursor: "pointer", flexShrink: 20}}
                spacing={0.5}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                className="muiStack_start"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <Typography variant="OrderSectionHeading" sx={{whiteSpace: "nowrap"}}>
                    Order type
                </Typography>

                <Stack direction="row" spacing={1} mt='0px !important' alignItems="center">
                    <Typography
                        noWrap
                        variant="OrderSectionHeading"
                        fontWeight={400}
                        {...(!headerData?.orderType && {sx: (t) => ({color: t.palette.error.light})})}
                    >
                        {headerData?.orderType || "Add order type"}
                    </Typography>
                    <KeyboardArrowDownIcon fontSize="small" />
                </Stack>
                <ErrorMessage
                    name="headerData.orderType"
                    render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                />
            </Stack>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
            >
                <Box px={2}>
                    <CustomRadioButton
                        options={[{label: "Delivery", value: "Delivery"},{label: "Pickup", value: "Pickup"}]}
                        value={headerData.orderType}
                        onChange={(e) => {
                            Parentformik.setFieldValue("headerData.orderType", e.target.value)
                            handleClose()
                        }
                    }
                    />
                </Box>
            </Menu>
        </>
    );
};

export default OrderType;
