import * as React from "react";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, {TooltipProps, tooltipClasses} from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

const LightTooltip = styled(({className, ...props}) => <Tooltip {...props} classes={{popper: className}} />)(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#ffff",
        borderRadius: "4px",
        boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.09)",
        color: "#26272A",
        fontSize: "13px",
        fontWeight: 400,
        padding: 16,
        width: 500
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "rgba(0, 0, 0, 0.87)",
        "&::before": {
            backgroundColor: "#ffff",
            boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.09)"
        }
    }
}));

export default function CustomizedTooltips({children, placement, title, ...rest}) {
    return (
        <LightTooltip {...rest} title={title} arrow placement={placement}>
            {children}
        </LightTooltip>
    );
}
