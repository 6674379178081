// a little function to help us with reordering the result
export const Reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 6;
export const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: "15.3px",
    // margin: `0 0 ${grid}px 0`,
    textAlign: "left",

    // change background colour if dragging
    background: "#FFFFFF",
    border: "1px solid #bdbdbd",
    borderRadius: "4px",
    font: "Inter Medium",
    fontSize: "15px",
    color: "#26272A",
    fontWeight: "400",
    // styles we need to apply on draggables
    ...draggableStyle
});
export const getCategoryStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    textAlign: "left",

    // change background colour if dragging
    background: "#FFFFFF",
    border: "1px solid rgba(0, 0, 0, 0.07383)",
    borderRadius: "4px",
    font: "Inter Medium",
    fontSize: "22px",
    color: "#000A12",
    fontWeight: 600,
    // styles we need to apply on draggables
    ...draggableStyle
});

export const getOrderItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    width: "100%",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,
    textAlign: "left",

    // change background colour if dragging
    background: "#FFFFFF",
    // styles we need to apply on draggables
    ...draggableStyle
});

export const getQuestionListStyle = (isDraggingOver) => ({
    background: "#FFFFFF",
    padding: 8
});

export const getAnswerListStyle = (isDraggingOver) => ({
    background: "#FFFFFF",
    padding: "4px 20px"
});
