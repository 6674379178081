import React, {useEffect, useState} from "react";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {Reorder, getCategoryStyle} from "./utils";
import Items from "./item";
import {IconGripVertical} from "@tabler/icons";
import {Box} from "@mui/material";
import ActionButton from "commonComponent/Apptable/ActionButton";
import useTempDrawer from "hooks/useTempDrawer";
import {NetworkManager, API} from "network/core";
import NewAddItem from "commonComponent/ItemComponent/Additem/NewAddItem";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";
import {cloneDeep} from "lodash";

// fake data generator
const getQuestions = (count) =>
    Array.from({length: count}, (v, k) => k).map((k) => ({
        id: `Category-${k}`,
        content: `category ${k}`,
        answers: [`item-1`, `item-2`, `item-3`]
    }));

const Category = (props) => {
    const {
        menuid,
        isEdit,
        itemDrawer,
        setItemDrawer,
        categoryList: questions,
        handleAddItem,
        handleUpdateItem,
        handleRearrange,
        handleAddCategory,
        handleCateGoryDuplication,
        handleCateGoryDelete,
        handleCategoryItemDuplication,
        handleCategoryItemDelete,
        existingItemList,
        handleExistingItem,
        counter,
        getItemListUnassigned,
        categorlyItemlist,
        setCategoryItemList
    } = props;

    const [row, setRow] = useState(null);
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const {toggleDrawer} = useTempDrawer();
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [catId, setcatId] = useState(null);

    const handleCreateitem = (btntype, index, catId, itemInd, row) => (e) => {
        setSelectedCategoryIndex(index);
        setcatId(catId);

        switch (btntype) {
            case "Create item": {
                setSelectedItemIndex(null);
                setSelectedRow(null);
                setItemDrawer(true);
                toggleDrawer("right", "itemEditSidebar")(e);
                break;
            }
            case "Edit item": {
                setSelectedItemIndex(itemInd);
                setSelectedRow(row);
                setItemDrawer(true);
                toggleDrawer("right", "itemEditSidebar")(e);
                break;
            }
            default:
                break;
        }
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
        if (row === null) {
            setItemDrawer(false);
        }
    };
    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handleItemDrawerClose = () => {
        setItemDrawer(false);
    };

    const categoryDuplicate = async (row, ind) => {
        const instance = NetworkManager(API.CATEGORY.ADD);
        const label = row.label.split("copy")[0]?.trim();
        const filteredParent = questions.filter((col) => col.label.includes(label));
        const count = filteredParent.length ?? 0;
        const payload = {
            categoryName: `${label} copy ${count}`,
            description: row.description,
            type: row?.menu_type ? row?.menu_type : []
        };

        const response = await instance.request(payload);

        if (response.success) {
            const states = {
                label: response.data?.category?.category_name,
                value: response.data?.category?.category_name,
                id: response.data?.category?.category_id,
                description: response.data?.category?.description,
                item: row.item
            };
            handleCateGoryDuplication(states, ind);
        }
    };

    const handleActionBtn = (type, row, handleClose, ind) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                handleAddCategory("edit category", row, ind)(e);
                break;
            }
            case "Remove": {
                handleCateGoryDelete(row, ind);
                break;
            }
            case "Duplicate": {
                categoryDuplicate(row, ind);

                break;
            }
            case "Activate": {
                break;
            }
            default:
                break;
        }
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        if (result.type === "QUESTIONS") {
            const quest = Reorder(questions, result.source.index, result.destination.index);

            // setQuestions(quest)
            handleRearrange(quest);
        } else {
            const answers = Reorder(questions[parseInt(result.type, 10)].item, result.source.index, result.destination.index);

            const quest1 = cloneDeep(questions);

            quest1[result.type].item = answers;
            handleRearrange(quest1);
            // setQuestions(quest1)
        }
    };

    const handleAddItemToCateogoryList = (item) => {
        if (selectedRow) {
            handleUpdateItem(item, selectedCategoryIndex, selectedItemIndex);
            handleSelectedRow(null);
            setcatId(null);
            setSelectedCategoryIndex(null);
            setSelectedItemIndex(null);
        } else {
            handleAddItem(item, selectedCategoryIndex);
        }
    };

    const getItemById = async (id, index, catId) => {
        const instance = NetworkManager(API.ITEM.GET);
        const response = await instance.request({}, [id]);
        if (response.success) {
            handleExistingItem(response.data.item, index, catId);
        }
    };

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity

    return (
        <>
            <NewAddItem
                fromAddNewMenu
                catId={catId}
                handleIsEdited={handleIsEdited}
                menuid={menuid}
                handleAddItemToCateogoryList={handleAddItemToCateogoryList}
                handleItemDrawerClose={handleItemDrawerClose}
                selectedRow={selectedRow}
                isEditable
            />

            <DragDropContext
                onDragEnd={onDragEnd}
                key={counter + questions?.length}
                // onDragUpdate={onDragUpdate}
            >
                <Droppable droppableId="droppable" type="QUESTIONS">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            key={counter}
                            //   style={getQuestionListStyle(snapshot.isDraggingOver)}
                            style={{background: "#ffff"}}
                        >
                            {questions?.map((question, index) => (
                                <Draggable
                                    key={
                                        counter +
                                        index * 1989839 +
                                        question.id +
                                        index +
                                        question.description +
                                        question.label +
                                        question.item.length
                                    }
                                    draggableId={question.id}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            style={getCategoryStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                            <Box sx={{display: "flex", justifyContent: "space-between"}}>
                                                <Box sx={{fontWeight: 700}}>
                                                    {ellipsizeText(question.label, 35)}
                                                    <span {...provided.dragHandleProps}>
                                                        <IconGripVertical icon="grip-vertical" style={{float: "left", color: "#E0E2E7"}} />
                                                    </span>
                                                </Box>
                                                {isEdit && (
                                                    <ActionButton
                                                        row={question}
                                                        ind={index}
                                                        ActionArray={["Edit", "Duplicate", "Remove"]}
                                                        handleActionBtn={handleActionBtn}
                                                    />
                                                )}
                                            </Box>

                                            <Items
                                                isEdit={isEdit}
                                                existingItemList={existingItemList}
                                                questionNum={index}
                                                question={question}
                                                getItemById={getItemById}
                                                handleCreateitem={handleCreateitem}
                                                handleItemDrawerClose={handleItemDrawerClose}
                                                handleCategoryItemDuplication={handleCategoryItemDuplication}
                                                handleCategoryItemDelete={handleCategoryItemDelete}
                                                getItemListUnassigned={getItemListUnassigned}
                                                categorlyItemlist={categorlyItemlist}
                                                setCategoryItemList={setCategoryItemList}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
};

export default React.memo(Category);
