// material-ui
import { Alert, Button, Fade, Grow, IconButton, Slide } from "@mui/material";
import MuiSnackbar from "@mui/material/Snackbar";

// assets
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

// animation function
function TransitionSlideLeft(props) {
    return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props) {
    return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props) {
    return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props) {
    return <Slide {...props} direction="down" />;
}

function GrowTransition(props) {
    return <Grow {...props} />;
}

// animation options
const animation = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade
};

// ==============================|| SNACKBAR ||============================== //

const Snackbar = ({
    actionButton = false,
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'center'
    },
    alert = {
        color: 'primary'
    },
    close = true,
    message = '',
    open = false,
    transition = 'Fade',
    variant = 'default' }) => {
    // const dispatch = useDispatch();
    // const snackbar = useSelector((state) => state.snackbar);
    let snackbar=false;

    // const { 
    //     actionButton = initialState.action,
    //     anchorOrigin = initialState.anchorOrigin,
    //     alert = initialState.alert,
    //     close = initialState.close,
    //     message = initialState.message,
    //     open = initialState.open,
    //     transition = initialState.transition,
    //     variant = initialState.variant } = props;


    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        snackbar=false
    };

   


    return (
        <>
            {/* default snackbar */}
            {variant === "default" && (
                <MuiSnackbar
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={4000}
                    onClose={handleClose}
                    message={message}
                    TransitionComponent={animation[transition]}
                    action={
                        <>
                            <Button color="secondary" size="small" onClick={handleClose}>
                                UNDO
                            </Button>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} sx={{ mt: 0.25 }}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </>
                    }
                />
            )}

            {/* alert snackbar */}
            {variant === "alert" && (
                <MuiSnackbar
                    TransitionComponent={animation[transition]}
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        variant={alert.variant}
                        color={alert.color}
                        severity={alert.severity}
                        action={
                            <>
                                {actionButton !== false && (
                                    <Button size="small" onClick={handleClose} sx={{ color: "background.paper" }}>
                                        UNDO
                                    </Button>
                                )}
                                {close !== false && (
                                    <IconButton sx={{ color: "background.paper" }} size="small" aria-label="close" onClick={handleClose}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </>
                        }
                        sx={{
                            ...(alert.variant === 'outlined' && {
                                bgcolor: '#ffffff'
                            }),
                            background: '#ffffff',
                            color: '#00C853'

                        }}
                    >
                        {message}
                    </Alert>
                </MuiSnackbar>
            )}
        </>
    );
};

export default Snackbar;
