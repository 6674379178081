import React, { useState, useRef, useEffect } from 'react';
import Box from "@mui/material/Box";
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails, LinearProgress } from "@mui/material";
import SearchSection from "commonComponent/SearchSection";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NetworkManager, API } from "network/core";
import { getMonDateYear } from "utils/app-dates/dates";
import useOrderDialog from 'hooks/useOrderDialog';
import OrderDetail from '../orderDetail/index'
import { generateUrl, FormatCurrency,commaFormattedNumber } from "utils/helperFunctions/helpers";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import { ellipsizeText } from 'commonComponent/Apptable/textUtils';
import SearchIcon from 'assets/images/icons/magnifier-tool.svg'



const PastOrdersAccordion = ({ formik }) => {
    const [expanded, setExpanded] = useState(false);
    const [expandedAccord, setExpandedAccord] = useState(false);
    const [pastOrderList, setPastOrderList] = useState(null);
    const [mainOrderList, setMainOrderList] = useState(null)
    const [visibleOrders, setVisibleOrders] = useState(3);
    const [orderDetailId, setOrderId] = useState(null)
    const [search, setSearch] = useState("");
    const [showSearch, setShowSearch] = useState(false);
    const { open, toggleOrderdetailDialog, handlePastOrder, pastOrderId, orderIngFromPast, touchFormikFields, setTouchFormikFields } = useOrderDialog();

    const contentRef = useRef(null);

    const getPastOrderList = async (orderId) => {
        // while changing brand kitchen location should be emptied
        const instance = NetworkManager(API.ORDER.GETBYCUSTOMERID(orderId));
        const params = {
            limit: 5000,
            offset: 1
        };

        if (search !== "") {
            params.search = search
        }
        if (formik.values.selectedBrand !== "") {
            params.brandIds = formik.values.selectedBrand
        }
        if (formik.values.selectedMenu !== "") {
            params.menuIds = formik.values.selectedMenu

        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);
        if (response.success) {
            const states = response.data?.orders?.map((state) => ({
                id: state.order_id,
                order_number: state.order_number,
                order_id: state.order_id,
                price: state.total_price,
                delivery_date: state.created_at,

                no_of_guest: state.no_of_guest
            }));
            setPastOrderList(states);
            setMainOrderList(states)
        }

    };

    useEffect(() => {
        if (formik.values.headerData.customer?.userId) {
            getPastOrderList(formik.values.headerData.customer?.userId)
        }

    }, [formik.values.headerData.customer?.userId])


    const handleSeeMore = () => {
        setVisibleOrders(mainOrderList.length);
        setExpanded(pre => !pre)
    };

    const handleSeeLess = () => {
        setVisibleOrders(3);
        setExpanded(pre => !pre)
    };

    const handleTouch = (formik) => {
        const allFields = Object.keys(formik.values);
        const touchedFields = {};

        allFields.forEach((field) => {
            touchedFields[field] = true;
        });

        formik.setTouched(touchedFields);
        formik.setFieldTouched("headerData.customer.userId", true)
        formik.setFieldTouched("headerData.deliveryTo", true)
        formik.setFieldTouched("headerData.when", true)
        formik.setFieldTouched("headerData.forHowMany", true)
        formik.setFieldTouched('serviceFees.value', true)
    }

    useEffect(() => {
        if (touchFormikFields) {
            handleTouch(formik)
            setTouchFormikFields(false)
        }

    }, [touchFormikFields])

    const handleOrderClick = (orderId) => {

        // const menuCategories = {};
        //         const menuCategory = response1.data?.menuList;
        //         menuCategory?.sort((a, b) => a.order_by - b.order_by);
        //         menuCategory?.forEach((row) => {
        //             menuCategories[`${row?.menu_category_id}`] = row?.category?.category_name;
        //         });
        //         formik.setFieldValue("categories", menuCategories);
        handlePastOrder(orderId)
        toggleOrderdetailDialog(false)()
    }



    const handleOrderDetail = (orderId) => {
        toggleOrderdetailDialog("OrderDetail")()
        setOrderId(orderId)
    }

    const handleClose = () => {
        toggleOrderdetailDialog(false)()

    }

    const handleChange = (panel) => (e, isExpanded) => {
        if (e.target.id === "id1" || e.target.id === "id2") {
            setExpandedAccord(pre => !pre);
            return;
        }
        // setExpanded(false)
        console.log("expanded");
    };


    useEffect(() => {
        getPastOrderList(formik.values.headerData.customer?.userId)

    }, [search, formik.values.selectedBrand, formik.values.selectedMenu])

    return (
        <>
            {open === "OrderDetail" && <OrderDetail orderDetailId={orderDetailId} handleClose={handleClose} handleOrderClick={handleOrderClick} />}

            <Accordion
                expanded={expandedAccord}
                onChange={handleChange()}
                sx={{
                    width: "250px", backgroundColor: "transparent",
                    "&.MuiAccordion-root": {
                        "&.Mui-expanded": {
                            margin: '0px',
                        }
                    },
                }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon id="id2" sx={{ cursor: 'pointer' }} />}

                    aria-controls="panel1a-content" id="panel1a-header"
                    sx={{
                        padding: 0,
                        cursor: 'default',
                        flexDirection: "row-reverse",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 0,
                        minHeight: { xs: "16px", md: "32px" },

                        "&.Mui-expanded": {
                            minHeight: { xs: "32px !important", md: "8px" },
                            margin: "0px !important"
                        },
                        "& .MuiAccordionSummary-content": {
                            margin: "0px !important"
                        }
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography id="id1" sx={{ cursor: 'pointer' }} variant="h4" fontWeight={600}>Past orders</Typography>
                        <Box onClick={() => {
                            setShowSearch(pre => !pre)
                            setSearch('')
                        }}><img src={SearchIcon} style={{ height: '14px', width: '16px' }} alt='search' /></Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        rowGap: "10px",
                        columnGap: "10px",
                        padding: 0
                    }}>
                    <Box sx={{ maxHeight: '400px', overflowY: 'auto',pr:0.5 }} ref={contentRef}>
                        {showSearch && <Grid item xs={11} sx={{ mb: '12px' }} className="searchGrid">
                            <SearchSection
                                placeholder="Search order"
                                color="#000A12"
                                border="1px solid #C2C2C2"
                                backgroundColor="#FAFAFA"
                                handleUpdate={(value) => {
                                    setSearch(value);
                                }}
                            />
                        </Grid>}
                        {pastOrderList && pastOrderList?.length === 0 && (
                            <CustomErrorMessage errorMessage="No order available" />
                        )}
                        {pastOrderList && pastOrderList?.slice(0, visibleOrders)?.map((col, ind) => (
                            <Grid
                                sx={{
                                    mb: 1,
                                    padding: '6px',
                                    backgroundColor: "#ffff",
                                    borderRadius: "4px",
                                    border: "1px solid #C2C2C2",
                                    background: "#FAFAFA",
                                    cursor: "pointer",
                                    maxHeight: '58px',
                                }}
                                item
                                xs={12}
                                onClick={() => handleOrderClick(col.order_id)}
                                key={ind + col.order_id}
                            >
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    {" "}
                                    <Typography variant="subtitle1">Order #{col.order_number}</Typography>
                                    <Typography onClick={(e) => {
                                         e.stopPropagation()
                                        handleOrderDetail(col.order_id)
                                    }} variant="subtitle3" sx={{ zIndex: 9999, color: '#00AB3A', fontWeight: 600 }}>View details</Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                                    <Typography variant="orderItemDescription">
                                        {col.delivery_date && getMonDateYear(col.delivery_date)}
                                        <span style={{ color: '#A3A4AC', fontWeight: 600, marginLeft: '4px', marginRight: '4px', fontSize: 22 }}>.</span>
                                        {commaFormattedNumber(col.no_of_guest)} People
                                    </Typography>
                                    <Typography variant="subtitle3">{FormatCurrency(col.price)}</Typography>
                                </Box>
                            </Grid>
                        ))}
                        {pastOrderList && pastOrderList?.length > 3 && (
                            <Box mt={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {expanded ? (
                                    <Typography
                                        onClick={handleSeeLess}
                                        sx={{
                                            fontSize: "0.73rem",
                                            fontWeight: 600,
                                            textDecoration: "none",
                                            cursor: 'pointer',
                                            color: '#00AB3A',
                                            fontFamily: "Inter",
                                            textDecorationLine: "underline",
                                        }}

                                    > See less</Typography>

                                ) : (
                                    <Typography
                                        onClick={handleSeeMore}
                                        sx={{
                                            fontSize: "0.73rem",
                                            fontWeight: 600,
                                            textDecoration: "none",
                                            cursor: 'pointer',
                                            color: '#00AB3A',
                                            fontFamily: "Inter",
                                            textDecorationLine: "underline",
                                        }}

                                    >See more</Typography>

                                )}
                            </Box>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default React.memo(PastOrdersAccordion);