import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import { fontWeight } from "@mui/system";

const SymbolButton = (props) => {
    const { title } = props;
    return (
        <Stack direction="row" alignItems="center" spacing={1} sx={{ pt: 2, pb: 2 }}>
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={(t) => ({ width: "1rem", height: "1rem", bgcolor: t.palette.primary.main, borderRadius: "50%", color: "#FFF" })}
            >
                <Box id="symbol12" sx={(t) => ({ color: "#FFF", pl: '0px', pb: '2.4px', fontSize: "1.4rem", fontWeight: 400, cursor: "pointer" })}>+</Box>
            </Stack>
            <Typography id="symbol13" noWrap variant="checkBoxSelected" sx={(t) => ({ color: t.palette.primary.main, cursor: "pointer" })}>
                {title}
            </Typography>
        </Stack>
    );
};

export default SymbolButton;
