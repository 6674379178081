import React, {useEffect, useState} from "react";
import {Box, Grid, Typography, InputAdornment, Checkbox, FormControlLabel} from "@mui/material";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {enforceFormat, formatToPhone, handleBlur} from "utils/helperFunctions/helpers";
import {ErrorMessage, Formik} from "formik";
import * as yup from "yup";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import {useNavigate, useSearchParams, useParams} from "react-router-dom";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "react-redux";
import Loader from "ui-component/Loader";
import Checked from "assets/images/lightCheckBox.svg";
import UnChecked from "assets/images/lightCheckedBox.svg";
import useTempDrawer from "hooks/useTempDrawer";
import ResetPasswordForm from "../../ResetPassword/ResetPasswordForm";
import StorageManager from "storage/StorageManager";
import ImageCropper from "../../../../../../commonComponent/ImageCropper/imageCropper";
import useMenuController from "views/pages/privatePages/brands/brandDetail/menu/menuForm.controller";

const formSchema = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    textNotification: false,
    calendar_link: "",
    linkedin_link: "",
    image_url: "",
    designation: ""
};

function Account(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {toggleDrawer} = useTempDrawer();
    const [initialValues, setInitialValues] = useState(formSchema);
    const [loading, setLoading] = useState(false);
    const [accountData, setAccountData] = useState([]);
    const [urlSearchParams] = useSearchParams();
    const prm = useParams();
    const profileId = prm.type;
    const role = StorageManager.get("role");

    const PC = useMenuController();

    const {isEdit, setIsEdit} = PC;

    useEffect(() => {
        setIsEdit(true);
    }, []);

    const [emailRequiredChecked, setEmailRequiredChecked] = useState(true);

    const handleEmailRequiredChange = () => {
        if (emailRequiredChecked === true) {
            setEmailRequiredChecked(true);
        }
    };

    const handleKeyUp = (e) => {
        const {value} = e.target;

        // If the value length is <= 10, apply phone number formatting
        if (value.length <= 10) {
            formatToPhone(e);
        }
    };

    const getUserProfileData = async () => {
        setLoading(true);
        const instance = NetworkManager(API.ACCOUNT.GETACCOUNT);
        const response = await instance.request();
        console.log("response : ", response);

        if (response.success) {
            setAccountData(response?.data?.user);
            setInitialValues({
                first_name: response?.data?.user?.first_name || "",
                last_name: response?.data?.user?.last_name || "",
                email: response?.data?.user?.email || "",
                phone: response?.data?.user?.phone || "",
                textNotification: response?.data?.user?.is_text,
                calendar_link: response?.data?.user?.additional_details?.calendar_link || "",
                linkedin_link: response?.data?.user?.additional_details?.linkedin_link || "",
                designation: response?.data?.user?.additional_details?.designation || "",
                image_url: response?.data?.user?.additional_details?.image_url ? [response.data?.user?.additional_details.image_url] : []
            });
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setLoading(false);
    };
    useEffect(() => {
        getUserProfileData();
    }, [profileId]);

    const handleUpdateAccount = async (values) => {
        // console.log("VALUES : ", values);
        const instance = NetworkManager(API.ACCOUNT.UPDATEACCOUNT);
        const payload = {
            first_name: values.first_name,
            last_name: values.last_name,
            phone: values.phone,

            additional_details: {
                calendar_link: values?.calendar_link ? values?.calendar_link : "",
                linkedin_link: values?.linkedin_link ? values?.linkedin_link : "",
                designation: values?.designation ? values?.designation : "",
                image_url: values?.image_url ? values?.image_url[0] : ""
            }
            // is_text: values?.textNotification
        };
        if (values?.textNotification) {
            payload.is_text = true;
        }
        const response = await instance.request(payload);
        if (response.success) {
            const name = values.first_name ? `${values.first_name} ${values.last_name !== null ? values.last_name : ""}` : "BiteTime User";
            StorageManager.put("user", name);

            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };
    return (
        <>
            <ResetPasswordForm />
            {loading && <Loader />}
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={yup.object({
                    first_name: yup.string().required("First name is required").nullable().trim(),
                    last_name: yup.string().required("Last name is required").nullable().trim(),
                    phone: yup.string().required("Phone number is required").nullable().trim(),
                    linkedin_link: yup.string().matches(/^https?:\/\/(www\.)?linkedin\.com\/.*$/, "Enter a valid LinkedIn url"),
                    calendar_link: yup.string().url("Enter a valid URL")
                })}
                onSubmit={(values, actions) => handleUpdateAccount(values, actions)}
            >
                {(formik) => {
                    return (
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                                <CustomTextField
                                    fullWidth
                                    placeholder="First name"
                                    label="First name"
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                                    }}
                                    inputProps={{maxLength: 50}}
                                    {...formik.getFieldProps("first_name")}
                                />
                                <ErrorMessage name="first_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                                <CustomTextField
                                    fullWidth
                                    placeholder="Last name"
                                    label="Last name"
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                                    }}
                                    inputProps={{maxLength: 50}}
                                    {...formik.getFieldProps("last_name")}
                                />
                                <ErrorMessage name="last_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>

                            <Grid item xs={12} sx={{paddingTop: "0px !important"}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4} xl={3.5} mt={2}>
                                        <CustomTextField
                                            fullWidth
                                            placeholder="Email address"
                                            label="Email address"
                                            inputProps={{maxLength: 50}}
                                            {...formik.getFieldProps("email")}
                                            disabled
                                        />
                                        <ErrorMessage name="email" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} xl={3.5} mt={2}>
                                        <CustomTextField
                                            fullWidth
                                            label="Phone number"
                                            // onKeyDown={enforceFormat}
                                            onKeyUp={handleKeyUp}
                                            // inputProps={{maxLength: 14}}
                                            onBlur={handleBlur}
                                            {...formik.getFieldProps("phone")}
                                        />
                                        <ErrorMessage name="phone" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{paddingTop: "0px !important"}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4} xl={3.5} mt={2}>
                                        <CustomTextField
                                            fullWidth
                                            label="Position"
                                            // inputProps={{maxLength: 14}}
                                            {...formik.getFieldProps("designation")}
                                        />
                                        <ErrorMessage name="designation" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} xl={3.5} mt={2}>
                                        <CustomTextField
                                            fullWidth
                                            label="Calendar link"
                                            inputProps={{maxLength: 250}}
                                            {...formik.getFieldProps("calendar_link")}
                                        />
                                        <ErrorMessage name="calendar_link" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{paddingTop: "0px !important"}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4} xl={3.5} mt={2}>
                                        <CustomTextField
                                            fullWidth
                                            label="Linkedin link"
                                            inputProps={{maxLength: 250}}
                                            {...formik.getFieldProps("linkedin_link")}
                                        />
                                        <ErrorMessage name="linkedin_link" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} xl={3.5} mt={2}>
                                        <Box
                                            sx={{
                                                borderRadius: "4px",
                                                border: "1px solid #bdbdbd",
                                                p: 2,
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            <ImageCropper
                                                isEdit={isEdit}
                                                title="Add a photo"
                                                multiPhoto={false}
                                                image={formik.values.image_url}
                                                handleChange={(list) => {
                                                    formik.setFieldValue(`image_url`, list);
                                                }}
                                                handleError={(msg = "Minimum 155KB file size is required") => {
                                                    formik.setTouched({image_url: true});
                                                    setTimeout(() => formik.setErrors({image_url: msg}), 100);
                                                }}
                                            />
                                        </Box>
                                        <ErrorMessage name="image_url" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* 
                            <Grid item xs={12} sx={{paddingTop: "0px !important"}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4} xl={3.5} mt={2}>
                                        <CustomTextField
                                            fullWidth
                                            placeholder="Designation"
                                            label="Designation"
                                            inputProps={{maxLength: 50}}
                                            {...formik.getFieldProps("email")}
                                            disabled
                                        />
                                        <ErrorMessage name="email" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} xl={3.5} mt={2}>
                                        <CustomTextField
                                            fullWidth
                                            label="Phone number"
                                            onKeyDown={enforceFormat}
                                            onKeyUp={formatToPhone}
                                            inputProps={{maxLength: 14}}
                                            onBlur={handleBlur}
                                            // {...(formik.values.phone && {
                                            //     InputProps: {
                                            //         startAdornment: <InputAdornment position="start">+1</InputAdornment>
                                            //     }
                                            // })}
                                            {...formik.getFieldProps("phone")}
                                        />
                                        <ErrorMessage name="phone" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                </Grid>
                            </Grid> */}
                            <Grid item xs={12}>
                                <Typography
                                    fontSize="0.937rem"
                                    marginTop={2}
                                    ml={0.5}
                                    sx={{fontSize: "0.938rem", fontWeight: 600, color: "#00ab3a", cursor: "pointer", width: "120px"}}
                                    onClick={(e) => {
                                        toggleDrawer("right", "resetPasswordDrawer")(e);
                                    }}
                                >
                                    Reset password
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} xl={3.5} mt={3}>
                                <Box>
                                    <Typography fontSize="1.125rem" sx={{fontWeight: 600}}>
                                        Notifications
                                    </Typography>
                                    <Typography fontSize="0.87rem" sx={{fontWeight: 600}} marginTop={2}>
                                        Receive order status update via
                                    </Typography>
                                    <Grid container marginTop={2}>
                                        {role === "ADMIN" && (
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disableRipple
                                                            icon={<img src={Checked} alt="checkbox" width="18px" height="18px" />}
                                                            checkedIcon={<img src={UnChecked} alt="checkbox" width="18px" height="18px" />}
                                                            checked={emailRequiredChecked}
                                                            onChange={handleEmailRequiredChange}
                                                        />
                                                    }
                                                    label={<span style={{fontSize: "0.938rem", color: "#020A11"}}>Email (required)</span>}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12} mt={1}>
                                            <FormControlLabel
                                                checked={formik.values.textNotification}
                                                onChange={(e) => formik.setFieldValue("textNotification", e.target.checked)}
                                                control={
                                                    <Checkbox
                                                        disableRipple
                                                        icon={<img src={Checked} alt="checkbox" width="18px" height="18px" />}
                                                        checkedIcon={<img src={UnChecked} alt="checkbox" width="18px" height="18px" />}
                                                    />
                                                }
                                                label={
                                                    <span
                                                        style={{
                                                            fontSize: "0.938rem",
                                                            color: formik.values.textNotification ? "#020A11" : "#66676E"
                                                        }}
                                                    >
                                                        Text message
                                                    </span>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} mt={4}>
                                <ButtonGrouping
                                    btnprops={[
                                        {
                                            btnTitle: "Save",
                                            variant: "contained",
                                            sx: (t) => ({color: t.palette.background.paper, width: "92px"}),
                                            onClick: (e) => {
                                                formik.handleSubmit();
                                            }
                                        }
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    );
                }}
            </Formik>
        </>
    );
}

export default Account;
