import React from "react";
import CreateBrandForm from "./CreateBrandForm";

const CreateBrand = (props) => {
    const { handleIsEdited,selectedRow,handleSelectedRow } = props
    return (
        <>
            <CreateBrandForm handleIsEdited={handleIsEdited} selectedRow={selectedRow} handleSelectedRow={handleSelectedRow} />
        </>
    );
};

export default CreateBrand;
