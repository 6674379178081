import {Grid, Box, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import CheckMark from "../../../../../../../assets/images/icons/check-mark.svg";
import {API, NetworkManager} from "network/core";
import {getHHMMWithTimeString, getformateDate, getFormattedTimeinAMPM} from "utils/app-dates/dates";
import {Link} from "react-router-dom";
import {styled} from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

function CompletedDeliveries({formik, isUpdated, handleIsUpdated}) {
    const [page, setPage] = useState(0);
    const [completedDeliveryData, setCompletedDeliveryData] = useState([]);

    const {values} = formik;
    const market = values.market;
    const filterDate = values.date;
    const [expanded, setExpanded] = React.useState("panel1");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({theme}) => ({
        border: `1px solid #fff`,

        "&:not(:last-child)": {
            borderBottom: 0
        },
        "&:before": {
            display: "none"
        }
    }));

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary expandIcon={<KeyboardArrowDownIcon sx={{fontSize: "2rem", color: "#000000"}} />} {...props} />
    ))(({theme}) => ({
        backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
        flexDirection: "row-reverse",
        minHeight: "5px",
        height: "2rem",
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(180deg)"
        },
        "& .MuiAccordionSummary-content": {
            marginLeft: theme.spacing(1.0)
        }
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
        padding: theme.spacing(1.5),
        marginTop: "-0.6rem",
        marginLeft: "34px"
    }));

    const getCompletedDeliveries = async () => {
        const instance = NetworkManager(API.DELIVERIES.COMPLETEDORDERLIST);
        const params = {
            market_id: market,
            date: getformateDate(filterDate, "yyyy-mm-dd")
        };
        const res = await instance.request({}, params);

        if (res.success) {
            const fetchedData = res?.data;

            const formatFetchedData = fetchedData.map((el) => ({
                order_id: el?.order_id,
                orderNumber: el?.order_number,
                name: el?.brand?.brand_name,
                address: el?.kitchen_location?.address,
                date: el?.delivery_date,
                time: el?.delivery_time,
                customerName: el?.customer?.company?.company_name || el?.customer?.full_name,
                customerAddress: el.delivery_address,
                driver: {
                    name: `${el?.driver?.name}`,
                    viewLink: el?.order_id
                }
            }));
            setCompletedDeliveryData(formatFetchedData);
        }
    };

    useEffect(() => {
        if (market && filterDate) {
            getCompletedDeliveries();
        }
    }, [market, filterDate, isUpdated]);

    return (
        <Box sx={{marginTop: "32px"}}>
            <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    sx={{
                        backgroundColor: "#fff",
                        width: "209px",
                        "&": {
                            ".MuiAccordionSummary-content": {
                                alignItems: "center",
                                marginLeft: 0
                            }
                        }
                    }}
                >
                    <Typography color="#000A12" sx={{fontSize: "1.25rem", marginTop: "12.6px", fontWeight: 700, marginBottom: "14px"}}>
                        Completed ({completedDeliveryData.length})
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div
                        style={{
                            marginLeft: "-25px",

                            minWidth: {xs: "100% !important", lg: "70%"},
                            maxWidth: {xs: "100% !important", lg: "70%"},
                            "@media (min-width: 1536px)": {
                                minWidth: "777px !important",
                                maxWidth: "790px !important"
                            }
                        }}
                        className="custom-scrollbar"
                    >
                        {completedDeliveryData.map((delivery, index) => (
                            <Grid key={index} container>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        minWidth: {xs: "100% !important"},
                                        maxWidth: {xs: "100% !important"},
                                        border: "1px solid #E0E2E6",
                                        borderRadius: "4px",
                                        background: "#fff",
                                        padding: "26px 30px",
                                        marginTop: "14px",
                                        "@media (min-width: 1536px)": {
                                            minWidth: "778px !important",
                                            maxWidth: "778px !important"
                                        }
                                    }}
                                >
                                    <Box sx={{display: "flex", justifyContent: "space-between"}}>
                                        <Box>
                                            <Typography sx={{fontSize: "1.18rem", fontWeight: 600, color: "#020A11"}}>
                                                Order{" "}
                                                <Link style={{textDecoration: "none"}} to={`/orders/${delivery.order_id}`}>
                                                    {" "}
                                                    <span style={{color: "#00ab3a"}}>#{delivery.orderNumber}</span>
                                                </Link>
                                                &nbsp;&nbsp; <span style={{color: "#26272A", opacity: 0.3032}}> | </span> &nbsp;&nbsp;
                                                <span>
                                                    {delivery.name}
                                                    
                                                </span>
                                            </Typography>
                                        </Box>
                                        <Box sx={{display: "flex"}}>
                                            <Typography sx={{fontSize: "1.063rem", fontWeight: 600}}>
                                                {getFormattedTimeinAMPM(delivery.date).toLowerCase().split(" ").join("")}
                                            </Typography>
                                            <Box ml={0.5}>
                                                <img src={CheckMark} height="20px" width="20px" alt="checkmark" />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Grid container sx={{marginTop: "18px"}}>
                                        <Grid item xs={7.5} sx={{borderRight: "1px solid rgba(38, 39, 42, 0.32)"}}>
                                        <span style={{fontSize: "0.875rem", fontWeight: 500, opacity: 0.4501}}>
                                                        {delivery.address}
                                                    </span>
                                            <Typography sx={{fontSize: "0.938rem", fontWeight: 600,color: "#000",marginTop: "4px" }}>{delivery.customerName}</Typography>
                                            <Typography sx={{fontSize: "0.938rem", fontWeight: 400, marginTop: "4px"}}>
                                                {delivery.customerAddress}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4.5} sx={{paddingLeft: "24px"}}>
                                            <Box>
                                                <Typography sx={{fontSize: "1.125rem", fontWeight: 700, marginTop: "8px"}}>
                                                    {delivery.driver.name}
                                                </Typography>
                                                <Typography sx={{fontSize: "1rem", fontWeight: 500, marginTop: "6px"}}>
                                                        {delivery.driver.phone}
                                                    </Typography>

                                                {/* <Box sx={{marginTop: "9px"}}>
                                                    <Typography sx={{fontSize: "0.938rem", fontWeight: 600, color: "#00ab3a"}}>
                                                        View delivery information
                                                    </Typography>
                                                </Box> */}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        {completedDeliveryData?.length === 0 && (
                            <Box p={1}>
                                <Typography sx={{fontSize: "0.75rem", fontWeight: 600, color: "#000"}}>No data available</Typography>
                            </Box>
                        )}
                    </div>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

export default CompletedDeliveries;
