
// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    selectedItem : '',
};


// eslint-disable-next-line
const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UpdateItem': {
            const { id } = action.payload;
            const selectedItem = id
            return {
                ...state,
                selectedItem
            };
        }
        case 'RemoveItem':{
            const selectedItem = ''
            return {
                ...state,
                selectedItem
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
