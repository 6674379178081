import { textAlign } from "@mui/system";

const Typography = (theme, borderRadius, fontFamily) => ({
    fontFamily,
    h6: {
        fontWeight: 500,
        color: theme.palette.grey[600],
        fontSize: "0.75rem",
        letterSpacing: "-0.7px !important"
    },
    h5: {
        fontSize: "0.75rem",
        color: theme.palette.grey[600],
        fontWeight: 500,
        letterSpacing: "-0.7px !important"
    },
    h4: {
        fontSize: "1rem",
        color: theme.palette.grey[600],
        fontWeight: 500,
        letterSpacing: "-0.7px !important"
    },
    h3: {
        fontSize: "1.25rem",
        color: theme.palette.grey[600],
        fontWeight: 500,
        letterSpacing: "-0.7px !important"
    },
    h2: {
        fontSize: "1.9rem",
        color: theme.palette.grey[600],
        fontWeight: 600,
        letterSpacing: "-0.7px !important"
    },
    h1: {
        fontSize: "2.125rem",
        color: theme.palette.grey[600],
        fontWeight: 600,
        letterSpacing: "-0.7px !important"
    },
    h7: {
        fontSize: "1rem",
        color: "#020A11",
        fontWeight: 600,
        letterSpacing: "-0.7px !important"
    },
    h7a: {
        fontSize: "0.75rem",
        color: "#1B1C1C",
        fontWeight: 600,
        letterSpacing: "-0.7px !important"
    },
    newRefund: {
        fontSize: "0.75rem",
        fontFamily: "Inter",
        fontWeight: 400,
        lineHeight: "15px",
        letterSpacing: "-0.7px",
        color: "#26272A"
    },
    specify: {
        fontSize: "0.9rem",
        fontFamily: "Inter",
        fontWeight: 400,
        lineHeight: "15px",
        letterSpacing: "-0.7px",
        color: "#26272A"
    },

    subtitlebb: {
        fontSize: "0.75rem",
        fontFamily: "Inter",
        fontWeight: 400,
        lineHeight: "15px",
        letterSpacing: "-0.7px",
        color: "#FFF"
    },
    semiDetail: {
        color: "#000A12",
        fontFamily: "Inter",
        fontSize: "1.07rem",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        letterSpacing: "-0.7px !important"
    },
    subDetail1: {
        color: "#26272A",
        fontFamily: "Inter",
        fontSize: "1.27rem",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "2rem",
        letterSpacing: "-0.7px !important"
    },

    subtitle1: {
        fontSize: "0.875rem",
        fontWeight: 500,
        color: theme.palette.text.dark,
        letterSpacing: "-0.7px !important"
    },
    subtitle2: {
        fontSize: "0.75rem",
        fontWeight: 400,
        color: theme.palette.text.secondary,
        letterSpacing: "-0.7px !important"
    },
    subtitle3: {
        fontSize: "0.75rem",
        fontWeight: 400,
        color: "#020A11",
        letterSpacing: "-0.7px !important"
    },
    caption: {
        fontSize: "0.75rem",
        color: theme.palette.text.secondary,
        fontWeight: 500,
        letterSpacing: "-0.7px !important"
    },
    orderItemDescription: {
        color: "#A3A4AC",
        fontSize: "0.75rem",
        fontWeight: 400,
        letterSpacing: "-0.7px !important",
        textAlign: 'center'
    },
    body1: {
        fontSize: "0.875rem",
        fontWeight: 500,
        lineHeight: "1.334rem",
        letterSpacing: "-0.7px !important"
    },
    body2: {
        fontWeight: 400,
        lineHeight: "1.5rem",
        color: theme.palette.text.primary,
        letterSpacing: "-0.7px !important"
    },

    body23: {
        colorL: "#26272A !important",
        fontWeight: 400,
        lineHeight: "1.5rem",
        letterSpacing: "-0.7px !important"
    },

    body3: {
        fontSize: "0.69rem",
        fontWeight: 500,
        lineHeight: "1rem",
        color: theme.palette.secondary[800],
        letterSpacing: "-0.7px !important"
    },
    body4: {
        fontSize: "1.38rem",
        fontWeight: 500,
        lineHeight: "1rem",
        color: theme.palette.secondary[800],
        letterSpacing: "-0.7px !important"
    },
    checkBoxSelected: {
        fontSize: "0.93rem",
        fontWeight: 600,
        lineHeight: "1.2rem",
        color: theme.palette.secondary[800],
        letterSpacing: "-0.7px !important"
    },
    checkBoxUnSelected: {
        fontSize: "0.93rem",
        fontWeight: 400,
        lineHeight: "1rem",
        color: theme.palette.secondary[800],
        letterSpacing: "-0.7px !important"
    },
    secondaryDataText: {
        marginTop: "0px !important",
        color: "#212121",
        opacity: 0.603,
        letterSpacing: "-0.7px !important",
        fontFamily: "Inter"
    },
    headingOne: {
        fontSize: "1.25rem",
        fontWeight: 600,
        lineHeight: "normal",
        color: theme.palette.secondary.main,
        letterSpacing: "-0.7px !important"
    },
    OrderSectionHeading: {
        color: theme.palette.other.darkBlack,
        fontFamily: "Inter",
        fontSize: "0.9375rem",
        fontWeight: 700,
        letterSpacing: "-0.7px !important"
    },
    button: {
        textTransform: "capitalize",
        letterSpacing: "-0.7px !important"
    },
    customInput: {
        marginTop: 3,
        letterSpacing: "-0.7px !important"
    },
    mainContent: {
        backgroundColor: theme.palette.mode === "dark" ? theme.palette.dark[800] : theme.palette.grey[100],
        width: "100%",
        minHeight: "calc(100vh - 88px)",
        flexGrow: 1,
        padding: "20px",
        marginTop: "20px",
        marginRight: "20px",
        borderRadius: `${borderRadius}px`,
        letterSpacing: "-0.7px !important"
    },
    menuCaption: {
        fontSize: "0.875rem",
        fontWeight: 500,
        color: theme.palette.grey[600],
        padding: "6px",
        textTransform: "capitalize",
        marginTop: "10px",
        letterSpacing: "-0.7px !important"
    },
    subMenuCaption: {
        fontSize: "0.6875rem",
        fontWeight: 500,
        color: theme.palette.text.secondary,
        textTransform: "capitalize",
        letterSpacing: "-0.7px !important"
    },
    commonAvatar: {
        cursor: "pointer",
        borderRadius: "8px",
        letterSpacing: "-0.7px !important"
    },
    smallAvatar: {
        width: "22px",
        height: "22px",
        fontSize: "1rem",
        letterSpacing: "-0.7px !important"
    },
    mediumAvatar: {
        width: "34px",
        height: "34px",
        fontSize: "1.2rem",
        letterSpacing: "-0.7px !important"
    },
    largeAvatar: {
        width: "44px",
        height: "44px",
        fontSize: "1.5rem",
        letterSpacing: "-0.7px !important"
    },
    draft: {
        color: "#26272A",
        fontFamily: "Inter",
        fontSize: 12,
        fontStyle: "italic",
        fontWeight: 500,
        opacity: 0.3853,
        letterSpacing: "-0.7px !important"
    },
    outlined: {
        height: "35px",
        letterSpacing: "-0.7px !important"
    }
});

export default Typography;
