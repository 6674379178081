import CommonDilogBox from "commonComponent/CommonDilogBox/OrderDetailDialogBox";
import TemplateDetail from './templateDetail'

const App = ({orderDetailId,handleClose,handleOrderClick}) => {
    return <CommonDilogBox openState="TemplateDetail">
        <TemplateDetail orderDetailId={orderDetailId} handleClose={handleClose} handleOrderClick={handleOrderClick}/>


    </CommonDilogBox>
}

export default App;