import React from "react";
import ProfileForm from "./ProfileForm";
import {Box} from "@mui/material";
import ViewBrand from "./ViewBrand/ViewBrand";
import {useSearchParams} from "react-router-dom";

const Profile = (props) => {
    const [searchParams] = useSearchParams();
    const editable = searchParams.get("isEdit");
    return <Box sx={{mt: -2}}>{editable === "true" ? <ProfileForm {...props} /> : <ViewBrand />}</Box>;
};

export default Profile;
