import { useRoutes } from 'react-router-dom';

// routes
import AuthenticationRotes from './AuthenticationRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import useAuth from 'hooks/useAuth';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { isLoggedIn } = useAuth();
    let routeArray=[]
    if (isLoggedIn){
        routeArray=[LoginRoutes, AuthenticationRotes, MainRoutes]
    }
    else{
        routeArray=[LoginRoutes, AuthenticationRotes]
    }
    return useRoutes(routeArray);
}
