import React, { useState } from 'react';
import Tooltip from "commonComponent/Tooltip";
import Typography from '@mui/material/Typography';
import { ellipsizeText } from './textUtils';

const MenuDisplay = ({ menuData }) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const menuCategoryItems = []
    menuData.menucategoryitem.forEach(row => {
        row.menuCategory.forEach((item => {
            menuCategoryItems.push(item)
        }))
    });


    const count = menuCategoryItems.length;

    // Display the first 2 menu names for each menu category
    const initialMenuItems = menuCategoryItems.slice(0, 2).map(menu => ellipsizeText(menu.menu_name)).join(', ');

    // Display "View more" button and tooltip
    const tooltipContent = menuCategoryItems.slice(2).map(menu => menu.menu_name).join(', ');

    return (
        <Typography variant="body3" sx={{ fontWeight: 400, fontSize: "11px" }}>
            <div >
                {initialMenuItems}

                {tooltipContent && (
                    <Tooltip
                        title={
                            <Typography variant="body3" sx={{ fontWeight: 400, fontSize: "11px" }}>
                                {tooltipContent}
                            </Typography>
                        }
                        arrow

                        followCursor
                        open={isTooltipOpen}
                        onOpen={() => setIsTooltipOpen(true)}
                        onClose={() => setIsTooltipOpen(false)}
                    >
                        <small style={{ cursor: "pointer", color: "#00ab3a", fontWeight: 500, fontSize: "11px" }}>
                            &nbsp;+{count - 2} more
                        </small>
                    </Tooltip>
                )}
            </div>
        </Typography>
    );
};

export default MenuDisplay;