import {Box, Rating, Stack, Typography} from "@mui/material";

import {ellipsizeText, capitalizeFirstLetter} from "./textUtils";

import {Link} from "react-router-dom";
import {getformateDateUS, getFormattedTimeinAMPM} from "utils/app-dates/dates";
import {el} from "date-fns/locale";
import Tooltip from "commonComponent/Tooltip";

import {FormatCurrency, FormatPhoneNumber, calculateFinalAmount, commaFormattedNumber} from "utils/helperFunctions/helpers";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import MenuDisplay from "./MenuDisplay";
import ServiceDisplay from "./ServiceDisplay";
import CategoryDisplay from "./CategoryDisplay";
import AddressComponent from "commonComponent/AddressComponent";

const service = {
    HOME_MEALS: "Home Meals",
    CATERING: "Catering"
};
const dietaryMapping = {
    VEGAN: "VG",
    VEGETARIAN: "V",
    "GLUTEN-FREE": "GF",
    "DAIRY-FREE": "DF",
    SPICY: "S"
};

function formatDateToAMPM(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes}${ampm}`;
}

const getColorOfStatus = (status) => {
    switch (status) {
        case "Draft":
            return "#A952FF";
        case "Quote sent":
            return "#24C4FB";
        case "Cancelled":
            return "#EA3744";
        case "Needs driver":
            return "#FF226B";
        case "Accepted":
            return "#00ab3a";
        case "New":
            return "#ED9E05";
        // case "Active":
        //     return "#00ab3a";
        case "Active":
            return "#00ab3a";
        case "Driver assigned":
            return "#FF872A";
        case "ON_THE_WAY":
            return "#00ab3a";
        case "QUOTE_EXPIRED":
            return "#757575";
        case "QUOTE_REJECTED":
            return "#EA3744";
        default:
            return "#000000";
    }
};

const getBgOfStatus = (status) => {
    switch (status) {
        case "Draft":
            return "#FAF2FF";
        case "Quote sent":
            return "#E8FAFF";
        case "QUOTE_EXPIRED":
            return "#F2F2F2";
        case "Cancelled":
            return "#FFF2F2";
        case "Needs driver":
            return "#FFF2F2";
        case "Accepted":
            return "#E4FFE2";
        case "New":
            return "#FFF8E5";
        // case "Active":
        //     return "#F0FFF9";
        case "Active":
            return "#E4FFE2";
        case "Driver assigned":
            return "#FFEFE4";
        case "ON_THE_WAY":
            return "#E4FFE2";
        case "PUBLISHED":
            return "#E4FFE2";
        case "UNPUBLISHED":
            return "#F2F2F2";
        case "NEW":
            return "#FFF8E5";
        case "QUOTE_REJECTED":
            return "#FFF2F2";

        default:
            return "#F2F2F2";
    }
};
const removeDuplicateMenuItems = (menuList) => {
    // Create a Set to keep track of unique menu_ids
    const uniqueMenuIds = new Set();

    // Use filter to keep only items with unique menu_ids
    const uniqueMenuList = menuList.filter((item) => {
        if (!uniqueMenuIds.has(item.menu_id)) {
            uniqueMenuIds.add(item.menu_id);
            return true;
        }
        return false;
    });

    return uniqueMenuList;
};

const paymentText = (row) => {
    if (row?.status === "Cancelled") {
        return "Canceled";
    } else if (row?.status === "Rejected") {
        return "Rejected";
    } else if (!row?.is_payment_initiated) {
        return "Unpaid";
    } else if (
        row?.status === "Complete" &&
        (row?.is_full_refunded || row?.is_partial_refunded) &&
        Number(row?.total_price - row?.remaining_amount_for_refund) > 0
    ) {
        return "Refunded";
    } else if (Number(row?.remaining_amount_to_be_paid) <= 0) {
        return "Paid";
    } else if (
        Number(row?.total_price - (row?.remaining_amount_for_refund + row?.advance_deposit_amount)) > 0 &&
        row?.status !== "Cancelled" &&
        (row?.remaining_amount_for_refund > 0 || row?.advance_deposit_amount > 0) &&
        !row?.is_full_refunded &&
        !row?.is_partial_refunded
    ) {
        return "Partial";
    }

    return "Unpaid";
};
export function getFields(
    headerName,
    row,
    id = false,
    path = false,
    searchParam = false,
    handleReviewModal = () => {},
    handleHeaderActions = () => {},
    handleActionBtn = () => {},
    isMobile = false
) {
    const dateContent = row[headerName] ? getformateDateUS(row[headerName], "dd/mm/yyyy") : "N/A";
    const capitalizeFirstLetter = (name) => {
        if (!name) return "Unassigned";
        return name
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };

    const totalAmount = row?.charge_fee_amount ? row.charge_fee_amount : row?.total_price ? row.total_price : 0;
    const remainingAmount = totalAmount - (row?.refund || 0);

    const newOrderItemPrice = calculateFinalAmount(row?.sales_tax_unit_value, row?.service_fee_unit_value, remainingAmount);

    const checkForEdit = (row) => {
        if (
            row.status === "Active" ||
            row.status === "New" ||
            row.status === "Accepted" ||
            row.status === "Needs Driver" ||
            row.status === "Needs driver" ||
            row.status === "Driver assigned" ||
            row.status === "Driver Assigned" ||
            row.status === "ON_THE_WAY" ||
            row.status === "Draft" ||
            row.status === "Quote sent"
        ) {
            return true;
        }
        return false;
    };
    const publishedCheck = row?.status === "PUBLISHED" ? "#04D74B" : "#000000";
    const newColor = row?.status === "NEW" ? "#ED9E05" : publishedCheck;
    const isActiveCheck = row?.is_active ? "#E4FFE2" : "#f2f2f2";
    switch (headerName) {
        case "discount_code": {
            return (
                <Typography
                    variant="body3"
                    fontWeight={600}
                    sx={(t) => ({
                        textDecoration: "none",
                        fontSize: isMobile ? "10px" : "12px",
                        textTransform: "uppercase"
                    })}
                >
                    {row[headerName]}
                </Typography>
            );
        }
        case "description": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.description !== "" ? (
                        <>
                            {ellipsizeText(row.description, 30)}

                            {row.description.length > 30 && (
                                <Tooltip
                                    title={
                                        <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                                            {row.description}
                                        </Typography>
                                    }
                                    arrow
                                    followCursor
                                >
                                    <div style={{cursor: "pointer", color: "#00ab3a", fontWeight: 400, minWidth: "100px"}}>view more</div>
                                </Tooltip>
                            )}
                        </>
                    ) : (
                        <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                            N/A
                        </Typography>
                    )}
                </Typography>
            );
        }

        case "descriptioncat": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, display: "inline-block", fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.description !== "" ? (
                        <>
                            {ellipsizeText(row.description, 80)}

                            {row.description.length > 80 && (
                                <Tooltip
                                    title={
                                        <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                                            {row.description}
                                        </Typography>
                                    }
                                    arrow
                                    followCursor
                                >
                                    <span style={{cursor: "pointer", color: "#00ab3a", fontWeight: 500, minWidth: "100px"}}>
                                        &nbsp;view more
                                    </span>
                                </Tooltip>
                            )}
                        </>
                    ) : (
                        <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                            N/A
                        </Typography>
                    )}
                </Typography>
            );
        }
        case "service": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row.services.length ? row.services.sort().map((el, idx) => `${idx > 0 ? ", " : ""}${service[el] || ""}`) : "N/A"}
                </Typography>
            );
        }
        case "templateService": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row.services}
                </Typography>
            );
        }
        case "marketDetails": {
            const marketData = row.marketDetails?.sort((a, b) => a.market_name - b.market_name);
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {marketData?.length > 0 ? (
                        <>
                            {marketData[0]?.market_name || ""}
                            {marketData[1]?.market_name ? ", " : ""}
                            {marketData[1]?.market_name || ""}
                            {marketData?.length > 2 && (
                                <Tooltip
                                    title={
                                        <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                                            {row.marketDetails?.length > 0 &&
                                                row.marketDetails
                                                    ?.sort((a, b) => a.market_name - b.market_name)
                                                    ?.slice(2)
                                                    ?.map((el, idx) => `${idx > 0 ? ", " : ""}${el.market_name || "N/A"}`)}
                                        </Typography>
                                    }
                                    arrow
                                    followCursor
                                >
                                    <div style={{cursor: "pointer", color: "#00ab3a", fontWeight: 500, minWidth: "100px"}}>
                                        +{marketData.length - 2} more
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : (
                        <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                            N/A
                        </Typography>
                    )}
                </Typography>
            );
        }
        case "brandDetails": {
            const brandData = row.brandDetails?.sort((a, b) => a.brand_name - b.brand_name);

            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {brandData?.length > 0 ? (
                        <>
                            {brandData[0]?.brand_name || ""}
                            {brandData[1]?.brand_name ? ", " : ""}
                            {brandData[1]?.brand_name || ""}
                            {brandData.length > 2 && (
                                <Tooltip
                                    title={
                                        <Typography variant="body3" sx={{fontWeight: 400, fontSize: "12px"}}>
                                            {row.brandDetails?.length > 0 &&
                                                row.brandDetails
                                                    ?.sort((a, b) => a.brand_name - b.brand_name)
                                                    ?.slice(2)
                                                    ?.map((el, idx) => `${idx > 0 ? ", " : ""}${el.brand_name || "N/A"}`)}
                                        </Typography>
                                    }
                                    arrow
                                    followCursor
                                >
                                    <div style={{cursor: "pointer", color: "#00ab3a", fontWeight: 500}}>+{brandData.length - 2} more</div>
                                </Tooltip>
                            )}
                        </>
                    ) : (
                        <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                            N/A
                        </Typography>
                    )}
                </Typography>
            );
        }
        case "start_date": {
            return (
                <Stack spacing={1}>
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {getformateDateUS(row[headerName])}
                    </Typography>

                    <Typography
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.69em"
                        sx={(t) => ({
                            color: t.palette.secondary[600],
                            textDecoration: "none",
                            opacity: 0.6,
                            fontSize: "11px"
                        })}
                    >
                        {formatDateToAMPM(row[headerName])}
                    </Typography>
                </Stack>
            );
        }

        case "end_date": {
            return (
                <Stack spacing={1}>
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {getformateDateUS(row[headerName])}
                    </Typography>

                    <Typography
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.69em"
                        sx={(t) => ({
                            color: t.palette.secondary[600],
                            textDecoration: "none",
                            opacity: 0.6,
                            fontSize: "11px"
                        })}
                    >
                        {formatDateToAMPM(row[headerName])}
                    </Typography>
                </Stack>
            );
        }

        case "driver": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {capitalizeFirstLetter(row.carrier_name)}
                </Typography>
            );
        }

        case "redemptionCount": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.number_available + row?.redemptionCount}
                </Typography>
            );
        }
        case "currentRedemptions": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.redemptionCount}
                </Typography>
            );
        }
        case "end_promodate": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.end_date ? (row.no_end_date ? "N/A" : getformateDateUS(row.end_date)) : "N/A"}
                </Typography>
            );
        }
        case "market_name":
            return (
                <Typography
                    component={Link}
                    variant="body3"
                    to={`/marketLocations/${row.market_id}?marketName=${row[headerName]}`}
                    fontWeight={600}
                    ellepcise
                    sx={(t) => ({
                        color: t.palette.primary.main,
                        textDecoration: "none",
                        fontSize: isMobile ? "10px" : "12px"
                    })}
                >
                    {row[headerName] || "N/A"}
                </Typography>
            );

        case "menu_name":
            return WithPermission({module: "Menu", permission: "update_permission"}) ? (
                <Typography
                    component={Link}
                    variant="body3"
                    to={
                        !path
                            ? `/menus/menuDetail/${row.menu_id}/?tab=menu&menuName=${row[headerName]}`
                            : `/menus/menuDetail/${row.menu_id}/?tab=menu&edit=yes&menuName=${row?.menu_name}&${path}`
                    }
                    fontWeight={600}
                    sx={(t) => ({
                        color: t.palette.primary.main,
                        textDecoration: "none",
                        fontSize: isMobile ? "10px" : "12px"
                    })}
                >
                    {ellipsizeText(row[headerName], 30)}
                </Typography>
            ) : (
                <Typography
                    component={Link}
                    variant="body3"
                    to={`/menus/menuDetail/${row.menu_id}/?tab=menu&menuName=${row[headerName]}`}
                    fontWeight={600}
                    sx={(t) => ({
                        color: t.palette.primary.main,
                        textDecoration: "none",
                        fontSize: isMobile ? "10px" : "12px"
                    })}
                >
                    {ellipsizeText(row[headerName], 30)}
                </Typography>
            );
        case "templateMenuName":
            return (
                <Typography variant="body3" fontSize={isMobile ? "10px" : "12px"} fontWeight={400}>
                    {ellipsizeText(row.menu_name, 30)}
                </Typography>
            );
        case "guest":
            return (
                // <Stack spacing={1}>
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: "11px"}}>
                    {commaFormattedNumber(row.guest)}
                </Typography>
                // </Stack>
            );

        case "modifier_group_name":
            return WithPermission({module: "Modifiers", permission: ["update_permission"]}) ? (
                <Typography
                    // component={Link}
                    variant="body3"
                    onClick={(e) => {
                        handleActionBtn("Edit", row, () => {})(e);
                    }}
                    // to={`${path.pathname}${path.search}&id=${row.modifier_group_id}`}
                    fontWeight={600}
                    sx={(t) => ({
                        color: t.palette.primary.main,
                        textDecoration: "none",
                        fontSize: isMobile ? "10px" : "12px",
                        cursor: "pointer"
                    })}
                >
                    {ellipsizeText(row[headerName])}
                </Typography>
            ) : (
                <Typography
                    // component={Link}
                    variant="body3"
                    // to={`${path.pathname}${path.search}&id=${row.modifier_group_id}`}
                    fontWeight={600}
                    sx={(t) => ({
                        textDecoration: "none",
                        fontSize: isMobile ? "10px" : "12px"
                    })}
                >
                    {ellipsizeText(row[headerName])}
                </Typography>
            );

        case "name":
            return (
                <Typography
                    component={Link}
                    variant="body3"
                    to={id ? `/locationDetail/${row.kitchen_location_id}${id}` : `/locationDetail/${row.kitchen_location_id}`}
                    fontWeight={600}
                    sx={(t) => ({
                        color: t.palette.primary.main,
                        textDecoration: "none",
                        fontSize: isMobile ? "10px" : "12px"
                    })}
                >
                    {row[headerName].charAt(0).toUpperCase() + row[headerName].slice(1)}
                </Typography>
            );
        case "driverName":
            return (
                <Typography
                    variant="body3"
                    fontWeight={600}
                    sx={(t) => ({
                        textDecoration: "none",
                        fontSize: isMobile ? "10px" : "12px"
                    })}
                >
                    {row[headerName].charAt(0).toUpperCase() + row[headerName].slice(1)}
                </Typography>
            );
        case "item_name":
            return (
                <Stack>
                    <Typography
                        component={Link}
                        variant="body3"
                        to={`/items/${id || "masterList"}/${row.item_id}?tab=itemDetails${
                            searchParam ? `&menuName=${searchParam}` : ""
                        }&itemName=${row.item_name}`}
                        fontWeight={600}
                        sx={(t) => ({
                            color: t.palette.primary.main,
                            textDecoration: "none",
                            fontSize: isMobile ? "10px" : "12px"
                        })}
                    >
                        {ellipsizeText(row[headerName], 30)}
                    </Typography>
                    <Typography
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.687rem"
                        sx={{color: (t) => t.palette.secondary["800"], opacity: 0.603}}
                        component="div"
                    >
                        {row?.sku}
                    </Typography>
                </Stack>
            );
        case "market":
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.market?.market_name || "N/A"}
                </Typography>
            );

        case "state":
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row.market?.state?.name}
                </Typography>
            );
        case "stateName":
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row.state.name}
                </Typography>
            );
        case "city":
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row.market.city.name}
                </Typography>
            );
        case "brand_name":
            return (
                <Stack>
                    <Typography
                        component={Link}
                        to={`/brand-detail/${row.brand_id}?brandName=${row[headerName]}`}
                        variant="body3"
                        fontWeight={600}
                        sx={(t) => ({
                            color: t.palette.primary.main,
                            textDecoration: "none",
                            fontSize: isMobile ? "10px" : "12px"
                        })}
                    >
                        {ellipsizeText(row[headerName], 20)}
                    </Typography>
                    <Typography
                        component={Link}
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.69em"
                        to={`/brand-detail/${row.brand_id}`}
                        sx={(t) => ({
                            color: t.palette.secondary[600],
                            textDecoration: "none",
                            opacity: 0.6,
                            fontSize: "11px"
                        })}
                    >
                        {ellipsizeText(row.abbreviation, 20)}
                    </Typography>
                </Stack>
            );
        case "brandName":
            return (
                <Stack>
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: "11px"}}>
                        {ellipsizeText(row[headerName], 20)}
                    </Typography>
                    <Typography
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.69em"
                        sx={(t) => ({
                            color: t.palette.secondary[600],
                            textDecoration: "none",
                            opacity: 0.6,
                            fontSize: isMobile ? "10px" : "12px"
                        })}
                    >
                        {ellipsizeText(row.abbreviation, 20)}
                    </Typography>
                </Stack>
            );
        case "templateBrandName":
            return (
                <>
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {ellipsizeText(row?.brand_name, 20)}
                    </Typography>
                </>
            );
        case "template_name":
            return WithPermission({module: "Templates", permission: ["update_permission"]}) ? (
                <Typography
                    component={Link}
                    to={`/menus?tab=templates&templateId=${row.order_template_id}`}
                    onClick={() => {
                        handleHeaderActions("Create template")();
                        // templateId
                    }}
                    variant="body3"
                    fontWeight={600}
                    sx={(t) => ({
                        color: t.palette.primary.main,
                        textDecoration: "none",
                        fontSize: isMobile ? "10px" : "12px"
                    })}
                >
                    {ellipsizeText(row?.template_name, 80)}
                </Typography>
            ) : (
                <Typography
                    variant="body3"
                    fontWeight={600}
                    sx={(t) => ({
                        textDecoration: "none",
                        fontSize: isMobile ? "10px" : "12px"
                    })}
                >
                    {ellipsizeText(row?.template_name, 20)}
                </Typography>
            );
        case "revenue_share":
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {parseFloat(row.revenue_share).toFixed(1)}%
                </Typography>
            );

        case "createdAt":
            return (
                <Stack spacing={1}>
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {getformateDateUS(row[headerName])}
                    </Typography>
                    {row.customer_review_id ? (
                        <Typography
                            variant="secondaryDataText"
                            fontWeight={400}
                            fontSize="0.69em"
                            sx={(t) => ({
                                color: t.palette.secondary[600],
                                textDecoration: "none",
                                opacity: 0.6,
                                fontSize: "11px"
                            })}
                        >
                            {formatDateToAMPM(row[headerName])}
                        </Typography>
                    ) : (
                        ""
                    )}
                </Stack>
            );
        case "createdDate":
            return (
                <Stack spacing={1}>
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {getformateDateUS(row.createdAt)}
                    </Typography>
                    {/* <Typography
                            variant="secondaryDataText"
  
  fontWeight={400}                          fontSize="0.69em"
                            sx={(t) => ({
                                color: t.palette.secondary[600],
                                textDecoration: "none",
                                opacity: 0.6,
                                fontSize: "11px"
                            })}
                        >
                            {formatDateToAMPM(row[headerName])}
                        </Typography> */}
                </Stack>
            );
        case "status":
            return (
                <Box
                    sx={{
                        borderRadius: "40px",
                        // bgcolor: row?.status === "PUBLISHED" ? "#FFF8E5" : "#E4FFE2",
                        bgcolor:
                            row?.status === "NEW" || row?.status === "PUBLISHED" || row?.status === "UNPUBLISHED"
                                ? getBgOfStatus(row?.status)
                                : isActiveCheck,
                        width: "76px",
                        height: "min-content",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                        // padding: "4px 12px"
                    }}
                >
                    {" "}
                    {row?.review ? (
                        <Typography variant="body3" color={newColor} sx={{fontWeight: 400, fontSize: "10px"}}>
                            {row?.status === "NEW" && "New"}
                            {row?.status === "PUBLISHED" && "Published"}
                            {row?.status === "UNPUBLISHED" && "Unpublished"}
                        </Typography>
                    ) : (
                        <Typography variant="body3" color={row?.is_active ? "#04D74B" : "#000"} sx={{fontWeight: 400, fontSize: "10px"}}>
                            {row?.is_active ? "Active" : "Inactive"}
                        </Typography>
                    )}
                </Box>
            );

        case "Rating":
            return <Rating name="simple-controlled" sx={{color: "#00ab3a", fontWeight: 400, fontSize: "1.062rem"}} value={row?.rating} />;

        case "is_active":
            return (
                <Box
                    sx={{
                        borderRadius: "40px",
                        bgcolor: row?.is_active ? "#E4FFE2" : "#F2F2F2",
                        width: "76px",
                        height: "min-content",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                        // padding: "4px 12px"
                    }}
                >
                    <Typography variant="body3" color={row?.is_active ? "#04D74B" : "#000000"} sx={{fontWeight: 400, fontSize: "10px"}}>
                        {row?.is_active ? "Active" : "Inactive"}
                    </Typography>
                </Box>
            );
        case "brandServices.service_name":
            return row.brandServices?.length > 0 ? (
                row.brandServices?.map((item, index) => (
                    <Typography key={index} variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {`${index > 0 ? ", " : ""}${service[item.service_name]}`}{" "}
                    </Typography>
                ))
            ) : (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    N/A{" "}
                </Typography>
            );

        case "menus":
            return row.menucategoryitem?.length > 0 ? (
                <Box>
                    {row.menucategoryitem.map((item, index) =>
                        item?.menuCategory
                            ?.filter((el) => el.menu_id)
                            ?.map((el, i) =>
                                path ? (
                                    <Typography
                                        key={i}
                                        component={Link}
                                        variant="body3"
                                        to={`/menus/menuDetail/${el?.menu_id}/?tab=menu&menuName=${el?.menu_name}`}
                                        fontWeight={600}
                                        sx={(t) => ({
                                            color: t.palette.primary.main,
                                            textDecoration: "none",
                                            fontSize: isMobile ? "10px" : "12px"
                                        })}
                                    >
                                        {`${i > 0 ? ", " : ""}${ellipsizeText(el?.menu_name)}`}
                                    </Typography>
                                ) : (
                                    <Typography
                                        key={i}
                                        variant="body3"
                                        fontWeight={400}
                                        sx={(t) => ({
                                            textDecoration: "none",
                                            fontSize: isMobile ? "10px" : "12px"
                                        })}
                                    >
                                        {`${i > 0 ? ", " : ""}${ellipsizeText(el?.menu_name)}`}
                                    </Typography>
                                )
                            )
                    )}{" "}
                </Box>
            ) : (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    N/A{" "}
                </Typography>
            );

        // case 'menusItem':
        //    return <Typography variant="body3" sx={{ fontWeight: 400, fontSize: "11px" }}>
        //             {row.menucategoryitem?.length > 0 ? (
        //                 <div style={{ display: "flex", flexDirection: "row" }}>
        //                     {row.menucategoryitem[0]?.menuCategory?.menu_name || ""} {row.menucategoryitem[1]?.menuCategory?.menu_name ? ", " : ""}
        //                     {row.menucategoryitem[1]?.menuCategory?.menu_name || ""}
        //                     {row.menucategoryitem?.length > 2 && (
        //                         <Tooltip
        //                             title={
        //                                 <Typography variant="body3" sx={{ fontWeight: 400, fontSize: "11px" }}>
        //                                     {row.menucategoryitem
        //                                         ?.slice(2)
        //                                         ?.map((item, index) => `${index > 0 ? ", " : ""}${item?.menuCategory?.menu_name}`)}
        //                                 </Typography>
        //                             }
        //                             arrow
        //                             followCursor
        //                         >
        //                             <div style={{ cursor: "pointer", color: "#00ab3a", fontWeight: 500 }}>
        //                                 &nbsp;+{row.menucategoryitem?.length - 2} more
        //                             </div>
        //                         </Tooltip>
        //                     )}
        //                 </div>
        //             ) : (
        //                 <Typography variant="body3" sx={{ fontWeight: 400, fontSize: "11px" }}>
        //                     N/A
        //                 </Typography>
        //             )}
        //         </Typography>

        case "menusItem":
            return row.menucategoryitem?.length > 0 ? (
                <MenuDisplay menuData={row} />
            ) : (
                // row.menucategoryitem.map((item, index) =>
                //     item?.menuCategory?.map((el, i) =>
                //         path ? (
                //             <Typography
                //                 key={i}
                //                 variant="body3"
                //                 fontWeight={400}
                //                 sx={(t) => ({
                //                     fontSize: "11px"
                //                 })}
                //             >
                //                 {`${i > 0 ? ", " : ""}${el?.menu_name}`}{" "}
                //             </Typography>
                //         ) : (
                //             <Typography
                //                 key={i}
                //                 variant="body3"
                //                 fontWeight={400}
                //                 sx={(t) => ({
                //                     textDecoration: "none",
                //                     fontSize: "11px"
                //                 })}
                //             >
                //                 {`${i > 0 ? ", " : ""}${el?.menu_name}`}{" "}
                //             </Typography>
                //         )
                //     )
                // )
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    N/A{" "}
                </Typography>
            );
        case "driverMarkets":
            return row.market?.length > 0 ? (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    <>
                        {row.market[0]?.market_name || ""}
                        {row.market[1]?.market_name ? ", " : ""}
                        {row.market[1]?.market_name || ""}
                        {row.market.length > 2 && (
                            <Tooltip
                                title={row.market?.slice(2)?.map((item, index) => (
                                    <Typography key={index} variant="body3" sx={{fontWeight: 400, fontSize: "12px"}}>
                                        {`${index > 0 ? ", " : ""}${item?.market_name}`}
                                    </Typography>
                                ))}
                                arrow
                                followCursor
                            >
                                <div style={{cursor: "pointer", color: "#00ab3a", fontWeight: 500}}>+{row.market?.length - 2} more</div>
                            </Tooltip>
                        )}
                    </>
                </Typography>
            ) : (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    N/A{" "}
                </Typography>
            );
        case "Category_names":
            return row.menucategoryitem?.length > 0 ? (
                <CategoryDisplay menuData={row} />
            ) : (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    N/A{" "}
                </Typography>
            );
        case "category_name":
            return WithPermission({module: "Categories", permission: ["update_permission"]}) ? (
                <Typography
                    // component={Link}
                    variant="body3"
                    onClick={(e) => {
                        handleActionBtn("Edit", row, () => {})(e);
                    }}
                    // to={`/menus/menuDetail/${el?.menu_id}/?tab=menu&menuName=${el?.menu_name}`}
                    fontWeight={600}
                    sx={(t) => ({
                        color: t.palette.primary.main,
                        textDecoration: "none",
                        fontSize: isMobile ? "10px" : "12px",
                        cursor: "pointer"
                    })}
                >
                    {ellipsizeText(row.category_name || "N/A")}
                </Typography>
            ) : (
                <Typography
                    // component={Link}
                    variant="body3"
                    // to={`/menus/menuDetail/${el?.menu_id}/?tab=menu&menuName=${el?.menu_name}`}
                    fontWeight={600}
                    sx={(t) => ({
                        textDecoration: "none",
                        fontSize: isMobile ? "10px" : "12px"
                    })}
                >
                    {ellipsizeText(row.category_name || "N/A")}
                </Typography>
            );
        case "item_type":
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.item_type === 1 ? "Regular" : "Custom"}
                </Typography>
            );
        case "item_price":
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.price ? FormatCurrency(row.price) : "N/A"}
                </Typography>
            );

        case "item_minimum_order_quantity":
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.minimum_order_quantity ? parseInt(row?.minimum_order_quantity, 10) : "N/A"}
                </Typography>
            );

        case "menulist":
            return row.brandMenu?.length > 0 ? (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    <>
                        {ellipsizeText(row.brandMenu[0]?.menu_name || "")}
                        {row.brandMenu[1]?.menu_name ? ", " : ""}
                        {ellipsizeText(row.brandMenu[1]?.menu_name || "")}
                        {row.brandMenu?.length > 2 && (
                            <Tooltip
                                title={row.brandMenu?.slice(2)?.map((item, index) => (
                                    <Typography key={index} variant="body3" sx={{fontWeight: 400, fontSize: "12px"}}>
                                        {`${index > 0 ? ", " : ""}${ellipsizeText(item?.menu_name)}`}
                                    </Typography>
                                ))}
                                arrow
                                followCursor
                            >
                                <div style={{cursor: "pointer", color: "#00ab3a", fontWeight: 500, minWidth: "100px"}}>
                                    +{row.brandMenu?.length - 2} more
                                </div>
                            </Tooltip>
                        )}
                    </>
                </Typography>
            ) : (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: "11px"}}>
                    N/A{" "}
                </Typography>
            );
        case "permissions":
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row.role_modules?.length > 0 ? (
                        <div style={{display: "flex", flexDirection: "row"}}>
                            {row.role_modules[0]?.module?.module_name || ""}
                            {row.role_modules[1]?.module?.module_name ? ", " : ""}
                            {row.role_modules[1]?.module?.module_name || ""}
                            {row.role_modules?.length > 2 && (
                                <Tooltip
                                    title={
                                        <Typography variant="body3" sx={{fontWeight: 400, fontSize: "12px"}}>
                                            {row.role_modules
                                                ?.slice(2)
                                                ?.map((item, index) => `${index > 0 ? ", " : ""}${item?.module?.module_name}`)}
                                        </Typography>
                                    }
                                    arrow
                                    followCursor
                                >
                                    <div style={{cursor: "pointer", color: "#00ab3a", fontWeight: 500}}>
                                        &nbsp;+{row.role_modules?.length - 2} more
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    ) : (
                        <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                            N/A
                        </Typography>
                    )}
                </Typography>
            );

        case "services":
            return row.menucategoryitem?.length > 0 ? (
                <ServiceDisplay menuData={row} />
            ) : (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    N/A{" "}
                </Typography>
            );
        case "modifiers":
            return row.modifierGroupModifier?.length > 0 ? (
                row.modifierGroupModifier
                    ?.sort((a, b) => a?.modifier?.series_no - b?.modifier?.series_no)
                    ?.map((el, index) => (
                        <Typography key={index} variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                            {`${index > 0 ? ", " : ""}${el?.modifier?.modifier_name || "N/A"} 
                               
                        `}
                        </Typography>
                    ))
            ) : (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    N/A
                </Typography>
            );
        case "modifier_name": {
            const temp = row.modifierGroupModifier?.sort((a, b) => a?.modifier?.series_no - b?.modifier?.series_no);
            return row.modifierGroupModifier?.length > 0 ? (
                <>
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {ellipsizeText(temp[0]?.modifier?.modifier_name || "", 30)}
                        {temp[1]?.modifier?.modifier_name ? ", " : ""}
                        {ellipsizeText(temp[1]?.modifier?.modifier_name || "", 30)}
                        {row.modifierGroupModifier?.length > 2 && (
                            <Tooltip
                                title={row.modifierGroupModifier
                                    ?.sort((a, b) => a?.modifier?.series_no - b?.modifier?.series_no)
                                    ?.slice(2)
                                    .map((el, index) => (
                                        <Typography key={index} variant="body3" sx={{fontWeight: 400, fontSize: "12px"}}>
                                            {`${index > 0 ? ", " : ""}${el?.modifier?.modifier_name || "N/A"}`}
                                        </Typography>
                                    ))}
                                arrow
                                followCursor
                            >
                                <div style={{cursor: "pointer", color: "#00ab3a", fontWeight: 500, minWidth: "100px"}}>
                                    +{row.modifierGroupModifier.length - 2} more
                                </div>
                            </Tooltip>
                        )}
                    </Typography>
                </>
            ) : (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: "11px"}}>
                    N/A
                </Typography>
            );
        }
        case "usedIn":
            return row.menuModifierGroup?.length > 0 ? (
                row.menuModifierGroup.map((item, index) => (
                    <Typography key={index} variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {`${index > 0 ? ", " : ""}${ellipsizeText(item?.menu?.menu_name || "N/A")}`}{" "}
                    </Typography>
                ))
            ) : (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    N/A{" "}
                </Typography>
            );

        case "customers":
            return (
                <Stack spacing={1} sx={{minWidth: "100px"}}>
                    {" "}
                    <Typography variant="body3" sx={{color: "#212121", fontWeight: 600, fontSize: isMobile ? "10px" : "12px"}}>
                        {ellipsizeText(row?.Customer || row?.customer?.full_name ? row?.Customer : "N/A", 20)}
                    </Typography>
                    {row?.customer?.company?.company_name !== null ? (
                        <Typography
                            variant="secondaryDataText"
                            fontWeight={400}
                            fontSize="0.69em"
                            sx={(t) => ({
                                color: t.palette.secondary[600],
                                textDecoration: "none",
                                opacity: 0.6,
                                fontSize: "11px"
                            })}
                        >
                            {ellipsizeText(row?.customer?.company?.company_name !== null ? row?.customer?.company?.company_name : "", 20)}
                        </Typography>
                    ) : (
                        ""
                    )}
                </Stack>
            );

        case "customer":
            return (
                <Stack spacing={1} sx={{minWidth: "100px"}}>
                    {" "}
                    <Typography variant="body3" sx={{color: "#212121", fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {row?.customer
                            ? ellipsizeText(
                                  `${row?.customer?.full_name ?? ""} ${row?.customer?.last_name !== null ? row?.customer?.last_name : ""}`,
                                  20
                              )
                            : "N/A"}
                    </Typography>
                    {row?.customer?.company?.company_name && (
                        <Typography
                            variant="secondaryDataText"
                            fontWeight={400}
                            fontSize="0.69em"
                            sx={(t) => ({
                                color: t.palette.secondary[600],
                                textDecoration: "none",
                                opacity: 0.6,
                                fontSize: "11px"
                            })}
                        >
                            {row?.customer?.company?.company_name ? (
                                ellipsizeText(row?.customer?.company?.company_name !== null ? row?.customer?.company?.company_name : "", 20)
                            ) : (
                                <span>&nbsp;</span>
                            )}
                        </Typography>
                    )}
                </Stack>
            );
        case "service_type":
            return (
                <Stack spacing={1}>
                    {" "}
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {/* {ellipsizeText(row?.kitchen_location?.market?.market_name, 7)} */}
                        {ellipsizeText(row?.service_type ?? "N/A", 15)}
                    </Typography>
                    <Typography
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.69em"
                        sx={(t) => ({
                            color: t.palette.secondary[600],
                            textDecoration: "none",
                            opacity: 0.6,
                            fontSize: "11px"
                        })}
                    >
                        {row?.order_type && ellipsizeText(row?.order_type, 30)}
                    </Typography>
                </Stack>
            );
        case "kitchen_location.market.market_name":
            return (
                <Stack spacing={1}>
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {ellipsizeText(row?.kitchen_location?.market?.market_name ?? "N/A", 15)}
                    </Typography>
                    {/* <Typography
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.69em"
                        sx={(t) => ({
                            color: t.palette.secondary[600],
                            textDecoration: "none",
                            opacity: 0.6,
                            fontSize: "11px"
                        })}
                    >
                        &nbsp;
                    </Typography> */}
                </Stack>
            );

        case "order_number":
            return (
                <Stack spacing={1} sx={{minWidth: "80px"}}>
                    {WithPermission({module: "Orders", permission: ["read_permission"]}) ? (
                        <Typography
                            component={Link}
                            to={`/orders/${row.order_id}?isEdit=${checkForEdit(row)}`}
                            variant="body3"
                            sx={{color: "#00ab3a", textDecoration: "none", fontWeight: 600, fontSize: isMobile ? "10px" : "12px"}}
                        >
                            <Tooltip style={{width: 100}} title={row.order_name}>
                                <span>{row[headerName]}</span>
                            </Tooltip>
                        </Typography>
                    ) : (
                        <Typography
                            component={Link}
                            variant="body3"
                            sx={{color: "#00ab3a", textDecoration: "none", fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}
                        >
                            <Tooltip style={{width: 100}} title={row.order_name}>
                                {row[headerName]}
                            </Tooltip>
                        </Typography>
                    )}
                    <Typography
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.69em"
                        sx={(t) => ({
                            color: t.palette.secondary[600],
                            textDecoration: "none",
                            opacity: 0.6,
                            fontSize: "11px"
                        })}
                    >
                        {row.order_by === "ADMIN" ? "Direct order" : "Marketplace"}
                    </Typography>
                </Stack>
            );
        case "quote_order_number":
            return (
                <Stack spacing={1} sx={{minWidth: "60px", maxWidth: "60px"}}>
                    {WithPermission({module: "Orders", permission: ["read_permission"]}) ? (
                        <Typography
                            component={Link}
                            to={`/orders/${row.order_id}?isEdit=${checkForEdit(row)}`}
                            variant="body3"
                            sx={{color: "#00ab3a", textDecoration: "none", fontWeight: 600, fontSize: isMobile ? "10px" : "12px"}}
                        >
                            {row?.order_number}
                        </Typography>
                    ) : (
                        <Typography
                            component={Link}
                            variant="body3"
                            sx={{color: "#00ab3a", textDecoration: "none", fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}
                        >
                            {row?.order_number}
                        </Typography>
                    )}
                    <Typography
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.69em"
                        sx={(t) => ({
                            color: t.palette.secondary[600],
                            textDecoration: "none",
                            opacity: 0.6,
                            fontSize: "11px"
                        })}
                    >
                        {row.order_by === "ADMIN" ? "Direct order" : "Marketplace"}
                    </Typography>
                </Stack>
            );

        case "total_price":
            return (
                <Stack spacing={1}>
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {FormatCurrency(row[headerName])}
                    </Typography>
                    <Typography
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.69em"
                        sx={(t) => ({
                            color: t.palette.secondary[600],
                            textDecoration: "none",
                            opacity: 0.6,
                            fontSize: "11px"
                        })}
                    >
                        {commaFormattedNumber(row.no_of_guest)} guests
                    </Typography>
                </Stack>
            );

        case "payment_by":
            return (
                <Stack spacing={1} sx={{width: "80px"}}>
                    <Typography
                        variant="body3"
                        sx={{
                            fontWeight: 500,
                            color: "#212121",
                            fontSize: isMobile ? "10px" : "12px"
                        }}
                    >
                        {/* {row.charged_by === "CREDIT_CARD"
                            ? row.is_payment_initiated
                                ? "PAID"
                                : "UNPAID"
                            : row.is_payment_initiated_from_house_account
                                ? "PAID"
                                : "UNPAID"} */}
                        {paymentText(row)}
                    </Typography>
                    <Typography
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.69em"
                        sx={(t) => ({
                            color: t.palette.secondary[600],
                            textDecoration: "none",
                            opacity: 0.6,
                            fontSize: isMobile ? "10px" : "12px"
                        })}
                    >
                        {row.charged_by === "CREDIT_CARD" ? "Credit card" : "House account"}
                    </Typography>
                </Stack>
            );

        case "charge_fee_amount":
            return (
                <Stack spacing={1} sx={{minWidth: "100px"}}>
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {row.charge_fee_amount ? Number(row.charge_fee_amount).toFixed(2) : "$0.00"}
                    </Typography>
                </Stack>
            );
        case "delivery_address":
            return (
                <Stack spacing={1} sx={{minWidth: "123px"}}>
                    {" "}
                    <AddressComponent row={row} isMobile={isMobile} />
                </Stack>
            );
        case "reviews":
            return (
                <Stack spacing={1} sx={{cursor: "pointer"}} onClick={() => handleReviewModal(row)}>
                    {row.review ? (
                        row.review.length > 60 ? (
                            <>
                                <Typography
                                    variant="body3"
                                    sx={{
                                        color: "#1B1C1C",
                                        fontWeight: 400,
                                        fontSize: isMobile ? "10px" : "12px"
                                    }}
                                >
                                    {ellipsizeText(row.review.split(",")[0], 85)}
                                    <Typography
                                        noWrap
                                        style={{
                                            whiteSpace: "pre-line",
                                            fontWeight: 400,
                                            color: "#00ab3a",
                                            fontSize: "11px"
                                        }}
                                    >
                                        View more
                                    </Typography>
                                </Typography>
                            </>
                        ) : (
                            <Typography
                                variant="body3"
                                sx={{
                                    color: "#1B1C1C",
                                    fontWeight: 400,
                                    fontSize: isMobile ? "10px" : "12px"
                                }}
                            >
                                {row.review}
                            </Typography>
                        )
                    ) : (
                        "N/A"
                    )}
                </Stack>
            );
        case "brand.brand_name":
            return (
                <Stack spacing={1}>
                    <Typography variant="body3" sx={{color: "#212121", fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {ellipsizeText(row?.brand?.brand_name ?? "N/A", 20)}
                    </Typography>
                    <Typography
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.69em"
                        sx={(t) => ({
                            color: t.palette.secondary[600],
                            textDecoration: "none",
                            opacity: 0.6,
                            fontSize: "11px"
                        })}
                    >
                        {/* <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}> */}
                        {/* {row?.kitchen_location?.market?.market_name ? ellipsizeText(row?.kitchen_location?.market?.market_name, 7) : (
                            <span>&nbsp;</span>
                        )} */}
                        {/* </Typography> */}
                        {/* {ellipsizeText(row?.kitchen_location?.market?.market_name, 15)} */}
                        {ellipsizeText(row?.kitchen_location?.name, 15)}
                    </Typography>
                </Stack>
            );
        case "quote_brand.brand_name":
            return (
                <Stack spacing={1}>
                    <Typography variant="body3" sx={{color: "#212121", fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {ellipsizeText(row?.brand?.brand_name ?? row?.brandName ?? "N/A", 20)}
                    </Typography>
                    <Typography
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.69em"
                        sx={(t) => ({
                            color: t.palette.secondary[600],
                            textDecoration: "none",
                            opacity: 0.6,
                            fontSize: "11px"
                        })}
                    >
                        {/* <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}> */}
                        {row?.kitchen_location?.name ? (
                            ellipsizeText(row?.kitchen_location?.name, 7)
                        ) : row?.kitchen_location_name ? (
                            ellipsizeText(row?.kitchen_location_name, 15)
                        ) : (
                            <span>&nbsp;</span>
                        )}
                        {/* </Typography> */}
                        {/* {ellipsizeText(row?.kitchen_locion?.address, 15)} */}
                    </Typography>
                </Stack>
            );
        case "order.status":
            return (
                <Stack sx={{minWidth: "40px"}}>
                    <Box
                        sx={{
                            width: "76px",
                            height: "min-content",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "40px",
                            backgroundColor:
                                row?.status === "Quote sent" && row?.quote_expiry_time < new Date().toISOString()
                                    ? "#F2F2F2"
                                    : getBgOfStatus(row?.status.trim())
                        }}
                    >
                        {" "}
                        <Typography
                            variant="body3"
                            sx={{
                                fontWeight: 400,
                                fontSize: "10px",
                                color:
                                    row?.status === "Quote sent" && row?.quote_expiry_time < new Date().toISOString()
                                        ? "#757575"
                                        : getColorOfStatus(row?.status.trim())
                            }}
                        >
                            {ellipsizeText(
                                row?.status === "Cancelled"
                                    ? "Canceled"
                                    : row?.status === "QUOTE_REJECTED"
                                    ? "Quote rejected"
                                    : row?.status === "Driver assigned"
                                    ? "Active"
                                    : row?.status === "Needs Driver" || row?.status === "Needs driver"
                                    ? "Assign driver"
                                    : row?.status === "Active"
                                    ? "Active"
                                    : row?.status === "ON_THE_WAY"
                                    ? "On the way"
                                    : row?.status === "DELIVERED"
                                    ? "Delivered"
                                    : row?.status === "Quote sent" && row?.quote_expiry_time < new Date().toISOString()
                                    ? "Quote expired"
                                    : row?.status ?? "N/A",
                                20
                            )}
                        </Typography>
                    </Box>
                    {row?.status === "Complete" && (
                        <Box
                            sx={(t) => ({
                                width: "76px",
                                height: "min-content",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "40px",
                                // padding: "4px 12px",
                                backgroundColor: `#fff`
                                // mt: "2px"
                            })}
                        >
                            <Typography
                                variant="secondaryDataText"
                                fontWeight={400}
                                fontSize="0.69em"
                                sx={(t) => ({
                                    color: t.palette.secondary[600],
                                    textDecoration: "none",
                                    opacity: 0.6,
                                    fontSize: "11px"
                                })}
                            >
                                {row?.OrderReview ? (
                                    <Link to="/Reviews">
                                        <Rating
                                            name="simple-controlled"
                                            readOnly
                                            sx={{color: "#212121", opacity: "0.6", fontWeight: 400, fontSize: "1.09em"}}
                                            value={row?.OrderReview?.rating}
                                        />{" "}
                                    </Link>
                                ) : (
                                    "No review"
                                )}
                            </Typography>
                        </Box>
                    )}
                </Stack>
            );
        case "delivery_date":
            return (
                <Stack spacing={1} sx={{minWidth: "100px"}}>
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {getformateDateUS(row[headerName])} {row?.delivery_date === null && "N/A"}
                    </Typography>
                    {row?.[headerName] && (
                        <Typography
                            variant="secondaryDataText"
                            fontWeight={400}
                            fontSize="0.69em"
                            sx={(t) => ({
                                color: t.palette.secondary[600],
                                opacity: 0.6,
                                fontSize: "11px"
                            })}
                        >
                            {row?.[headerName] ? formatDateToAMPM(row[headerName]) : <span>&nbsp;</span>}
                        </Typography>
                    )}
                </Stack>
            );
        case "quote_delivery_date":
            return (
                <Stack spacing={1} sx={{minWidth: "60px", maxWidth: "60px"}}>
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {getformateDateUS(row?.delivery_date)} {row?.delivery_date === null && "N/A"}
                    </Typography>
                    <Typography
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.69em"
                        sx={(t) => ({
                            color: t.palette.secondary[600],
                            opacity: 0.6,
                            fontSize: "11px"
                        })}
                    >
                        {row?.delivery_date ? formatDateToAMPM(row?.delivery_date) : <span>&nbsp;</span>}
                    </Typography>
                </Stack>
            );

        case "created_at":
            return (
                <Stack spacing={1}>
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {getformateDateUS(row[headerName])}
                    </Typography>
                    <Typography
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.69em"
                        sx={(t) => ({
                            color: t.palette.secondary[600],
                            textDecoration: "none",
                            opacity: 0.6,
                            fontSize: "11px"
                        })}
                    >
                        {row?.[headerName] ? formatDateToAMPM(row[headerName]) : <span>&nbsp;</span>}
                    </Typography>
                </Stack>
            );

        case "full_name": {
            return (
                <Stack spacing={1}>
                    <Typography
                        variant="body3"
                        component={Link}
                        to={`/customers/${row.user_id}/?tab=profile&customerName=${
                            row?.full_name + (row?.last_name ? ` ${row?.last_name}` : "")
                        }`}
                        sx={{color: "#00ab3a", textDecoration: "none", fontWeight: 600, fontSize: isMobile ? "10px" : "12px"}}
                    >
                        {ellipsizeText(row?.full_name + (row?.last_name ? ` ${row?.last_name}` : "") || "N/A")}
                    </Typography>
                    <Typography
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.69em"
                        sx={(t) => ({
                            color: t.palette.secondary[600],
                            textDecoration: "none",
                            opacity: 0.6,
                            fontSize: "11px"
                        })}
                    >
                        {row?.email ? ellipsizeText(row?.email) : <span>&nbsp;</span>}
                    </Typography>
                </Stack>
            );
        }
        case "period": {
            return (
                <Stack spacing={1}>
                    <Typography
                        variant="body3"
                        component={Link}
                        to={`/reports/orderlist?PeriodName=${row?.period}&type=${row?.guests ? "sales" : "tax"}`}
                        sx={{color: "#00ab3a", textDecoration: "none", fontWeight: 600, fontSize: isMobile ? "10px" : "12px"}}
                    >
                        {ellipsizeText(row?.period) || "N/A"}
                    </Typography>
                </Stack>
            );
        }

        case "full_name_HouseAccount": {
            return (
                <Stack spacing={1}>
                    <Typography
                        variant="body3"
                        component={Link}
                        to={`/customers/${row.user_id}/?tab=houseAccount&customerName=${row?.full_name}`}
                        sx={{color: "#00ab3a", textDecoration: "none", fontWeight: 600, fontSize: isMobile ? "10px" : "12px"}}
                    >
                        {ellipsizeText(row?.full_name + (row?.last_name ? ` ${row?.last_name}` : "") || "N/A")}
                    </Typography>
                    <Typography
                        variant="secondaryDataText"
                        fontWeight={400}
                        fontSize="0.69em"
                        sx={(t) => ({
                            color: t.palette.secondary[600],
                            textDecoration: "none",
                            opacity: 0.6,
                            fontSize: "11px"
                        })}
                    >
                        {row?.email ? ellipsizeText(row?.email) : <span>&nbsp;</span>}
                    </Typography>
                </Stack>
            );
        }
        case "role_name": {
            return (
                <Stack spacing={1}>
                    {WithPermission({module: "Roles", permission: ["update_permission"]}) ? (
                        <Typography
                            variant="body3"
                            component={Link}
                            to={`/setting/role?tab=role&edit=true&roleID=${row.role_id}`}
                            sx={{color: "#00ab3a", textDecoration: "none", fontWeight: 600, fontSize: isMobile ? "10px" : "12px"}}
                        >
                            {ellipsizeText(row?.role_name ?? "N/A")}
                        </Typography>
                    ) : (
                        <Typography
                            variant="body3"
                            sx={{color: "#00ab3a", textDecoration: "none", fontWeight: 600, fontSize: isMobile ? "10px" : "12px"}}
                        >
                            {ellipsizeText(row?.role_name ?? "N/A")}
                        </Typography>
                    )}
                </Stack>
            );
        }
        case "review_ID": {
            return (
                <Stack spacing={1}>
                    <Typography
                        disabled
                        variant="body3"
                        component={Link}
                        to={`/orders/${row?.order_details?.order_id}?isEdit=${checkForEdit(row)}`}
                        sx={{
                            color: "#00ab3a",
                            textDecoration: "none",
                            fontWeight: 600,
                            fontSize: isMobile ? "10px" : "12px",
                            pointerEvents: row?.OrderID ? "auto" : "none"
                        }}
                    >
                        {ellipsizeText(row?.OrderID ?? "N/A")}
                    </Typography>
                </Stack>
            );
        }
        case "card.orders": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {ellipsizeText(row?.customerCard[0]?.orders ?? "N/A")}
                </Typography>
            );
        }
        case "menuModifierGroup.menu.menu_name": {
            const temp = removeDuplicateMenuItems(row?.menuModifierGroup);

            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {temp?.length > 0 ? (
                        <>
                            {ellipsizeText(temp[0]?.menu?.menu_name || "")}
                            {temp[1]?.menu?.menu_name ? ", " : ""}
                            {ellipsizeText(temp[1]?.menu?.menu_name || "")}
                            {temp?.length > 2 && (
                                <Tooltip
                                    title={
                                        <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                                            {temp?.slice(2)?.map((group, index) => (
                                                <span key={group.id}>
                                                    {ellipsizeText(group?.menu?.menu_name || "N/A")}
                                                    {index < row.menuModifierGroup.length - 1 ? ", " : ""}
                                                </span>
                                            ))}
                                        </Typography>
                                    }
                                    arrow
                                    followCursor
                                >
                                    <div style={{cursor: "pointer", color: "#00ab3a", fontWeight: 500, minWidth: "100px"}}>
                                        +{temp.length - 2} more
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : (
                        "N/A"
                    )}
                </Typography>
            );
        }
        case "menu_type": {
            const menuType = row?.menu_type;
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {menuType?.length > 0 ? (
                        <>
                            {menuType[0] && ellipsizeText(capitalizeFirstLetter(menuType[0]))}
                            {menuType.length > 1 && menuType[1] && `, ${ellipsizeText(capitalizeFirstLetter(menuType[1]))}`}
                        </>
                    ) : (
                        "N/A"
                    )}
                </Typography>
            );
        }
        case "card.balance": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {/* {row?.lifeTimeValue != null ? `$${row.lifeTimeValue}` : "N/A"} */}
                    {row?.customerCard[0]?.balance != null ? FormatCurrency(row?.customerCard[0]?.balance) : "N/A"}
                </Typography>
            );
        }
        case "card.createdAt": {
            return (
                <Typography sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {getformateDateUS(row?.customerCard[0]?.createdAt ?? "N/A")}
                </Typography>
            );
        }
        case "last_order":
            return (
                <Stack spacing={1} minWidth={80}>
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {row[headerName] !== null ? getformateDateUS(row[headerName]) : "N/A"}
                    </Typography>
                    {row[headerName] !== null && (
                        <Typography
                            variant="secondaryDataText"
                            fontWeight={400}
                            fontSize="0.69em"
                            sx={(t) => ({
                                color: t.palette.secondary[600],
                                textDecoration: "none",
                                opacity: 0.6,
                                fontSize: "11px"
                            })}
                        >
                            {row?.[headerName] ? formatDateToAMPM(row[headerName]) : <span>&nbsp;</span>}
                        </Typography>
                    )}
                </Stack>
            );
        case "company.company_name": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {ellipsizeText(row?.company?.company_name ?? "N/A")}
                </Typography>
            );
        }
        case "customerAddresses.delivery_address": {
            return (
                <Stack>
                    {row?.customerAddresses
                        .filter((address) => address.is_default)
                        .map((address, index) => (
                            <Stack spacing={1} key={index}>
                                <AddressComponent row={address} isMobile={isMobile} />
                            </Stack>
                        ))}
                    {row?.customerAddresses.filter((address) => address.is_default).length === 0 && (
                        <Typography variant="body3" sx={{fontWeight: 400, fontSize: "11px"}}>
                            N/A
                        </Typography>
                    )}
                </Stack>
            );
        }

        case "creator.first_name": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {`${row?.creator?.first_name} ${row?.creator?.last_name}` || "N/A"}
                </Typography>
            );
        }

        case "refund": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.refund ? `${FormatCurrency(row.refund)}` : "$0.00"}
                </Typography>
            );
        }
        case "price_type_value": {
            if (row?.charge_fee_amount || row?.refund) {
                return (
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        $0.00
                    </Typography>
                );
            }

            if (row?.price_type === "Discount") {
                return (
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {row?.price_type_value ? `${FormatCurrency(row?.price_type_value)}` : "$0.00"}
                    </Typography>
                );
            }

            return "$0.00";
        }

        case "cancellation": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.cancellation ? row?.cancellation : "N/A"}
                </Typography>
            );
        }
        case "cancellation_fee": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.cancellation_fee ? ` ${FormatCurrency(row?.cancellation_fee)}` : "0.00"}
                </Typography>
            );
        }
        case "sales": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.sales ? `${FormatCurrency(row.sales)}` : "$0.00"}
                </Typography>
            );
        }
        case "order_price": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.refund
                        ? `${FormatCurrency(newOrderItemPrice)}`
                        : row?.charge_fee_amount
                        ? "$0.00"
                        : row?.order_price
                        ? `${FormatCurrency(
                              row.order_price - row?.preferred_price - (row?.price_type === "Discount" ? row?.price_type_value : 0)
                          )}`
                        : "$0.00"}
                </Typography>
            );
        }
        case "total_price2": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.charge_fee_amount
                        ? `${FormatCurrency(row?.charge_fee_amount)}`
                        : row?.refund
                        ? `${FormatCurrency(remainingAmount)}`
                        : row?.charge_fee_amount
                        ? `${FormatCurrency(row.charge_fee_amount)}`
                        : row?.total_price
                        ? `${FormatCurrency(row.total_price)}`
                        : "$0.00"}
                </Typography>
            );
        }

        case "total_price_item": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.refund
                        ? `${FormatCurrency(newOrderItemPrice)}`
                        : row?.charge_fee_amount
                        ? "$0.00"
                        : row?.order_price
                        ? `${FormatCurrency(row.order_price)}`
                        : "$0.00"}
                </Typography>
            );
        }

        case "salesTax": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.salesTax ? `${FormatCurrency(row.salesTax)}` : "$0.00"}
                </Typography>
            );
        }

        case "serviceFee": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.serviceFee ? `${FormatCurrency(row.serviceFee)}` : "$0.00"}
                </Typography>
            );
        }

        case "netOrderItems": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.netOrderItems ? `${FormatCurrency(row.netOrderItems)}` : "$0.00"}
                </Typography>
            );
        }

        case "orderItems": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.orderItems ? `${FormatCurrency(row.orderItems)}` : "$0.00"}
                </Typography>
            );
        }
        case "preferredPrice": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.preferredPrice ? `-${FormatCurrency(row.preferredPrice)}` : "$0.00"}
                </Typography>
            );
        }
        case "preferred_pricing": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.refund
                        ? "$0.00"
                        : row?.charge_fee_amount
                        ? "$0.00"
                        : row?.preferred_price
                        ? `${FormatCurrency(row?.preferred_price)}`
                        : "$0.00"}
                </Typography>
            );
        }

        case "service_fee": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.refund
                        ? FormatCurrency((row.service_fee_unit_value * newOrderItemPrice) / 100)
                        : row?.charge_fee_amount
                        ? "$0.00"
                        : row?.service_fee_value
                        ? FormatCurrency(row?.service_fee_value)
                        : "$0.00"}
                </Typography>
            );
        }

        case "sales_tax": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.refund
                        ? `${FormatCurrency((row?.sales_tax_unit_value * newOrderItemPrice) / 100)}`
                        : row?.charge_fee_amount
                        ? "$0.00"
                        : row?.sales_tax
                        ? `${FormatCurrency(row?.sales_tax)}`
                        : "$0.00"}
                </Typography>
            );
        }

        case "discountss": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.discounts ? `-${FormatCurrency(row.discounts)}` : "-$0.00"}
                </Typography>
            );
        }

        case "taxExempt": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.taxExempt ? `${FormatCurrency(row.taxExempt)}` : "$0.00"}
                </Typography>
            );
        }

        case "lifeTimeValue": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.lifeTimeValue != null ? `${FormatCurrency(row.lifeTimeValue)}` : "N/A"}
                </Typography>
            );
        }
        case "averageOrderValue": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.averageOrderValue != null ? `${FormatCurrency(row.averageOrderValue)}` : "N/A"}
                </Typography>
            );
        }

        case "note": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px", minWidth: "400px"}}>
                    {row?.note ?? "N/A"}
                </Typography>
            );
        }
        case "display_name": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {ellipsizeText(row?.display_name ?? "N/A", 30)}
                </Typography>
            );
        }
        case "phone": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.phone != null ? `${FormatPhoneNumber(row?.phone)}` : "N/A"}
                </Typography>
            );
        }
        case "calendar_link": {
            return (
                <a href={row?.additional_details?.calendar_link} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}}>
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {row?.additional_details?.calendar_link != null
                            ? `${ellipsizeText(row?.additional_details?.calendar_link, 25)}`
                            : "N/A"}
                    </Typography>
                </a>
            );
        }
        case "linkedin_link": {
            return (
                <a href={row?.additional_details?.linkedin_link} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}}>
                    <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {row?.additional_details?.linkedin_link != null
                            ? `${ellipsizeText(row?.additional_details?.linkedin_link, 25)}`
                            : "N/A"}
                    </Typography>
                </a>
            );
        }
        case "designation": {
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row?.additional_details?.designation != null ? `${row?.additional_details?.designation}` : "N/A"}
                </Typography>
            );
        }
        case "isActivate": {
            return (
                <Box
                    sx={{
                        bgcolor: row?.isActivate ? "#E4FFE2" : "#FFF8E5",
                        width: "76px",
                        height: "min-content",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "40px"
                    }}
                >
                    <Typography
                        variant="body3"
                        color={row?.isActivate ? "#04D74B" : "#ED9E05"}
                        sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}
                    >
                        {row?.isActivate ? "Active" : "Pending"}
                    </Typography>
                </Box>
            );
        }
        case "first_name": {
            return (
                <Stack spacing={1}>
                    <Typography
                        variant="body3"
                        component={Link}
                        to={`/settings/userdetail/${row.user_id}/?tab=profile&userName=${row?.first_name}`}
                        sx={{
                            textTransform: "capitalize",
                            color: "#00ab3a",
                            textDecoration: "none",
                            fontWeight: 600,
                            fontSize: isMobile ? "10px" : "12px"
                        }}
                    >
                        {ellipsizeText(row?.first_name + (row?.last_name ? ` ${row?.last_name}` : "") || "N/A")}
                    </Typography>
                </Stack>
            );
        }
        case "role": {
            return (
                <Stack spacing={1}>
                    <Typography variant="body3" sx={{textDecoration: "none", fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                        {ellipsizeText(row?.sub_role_detail?.role_name ?? "N/A")}
                    </Typography>
                </Stack>
            );
        }
        case "user.status":
            return (
                <Box
                    sx={{
                        borderRadius: "40px",
                        bgcolor: row?.status === "ACTIVATE" ? "#E4FFE2" : "#f2f2f2",
                        width: "76px",
                        height: "min-content",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                        // padding: "4px 12px"
                    }}
                >
                    <Typography
                        variant="body3"
                        color={row?.status === "ACTIVATE" ? "#04D74B" : "#000"}
                        sx={{fontWeight: 400, fontSize: "10px"}}
                    >
                        {row?.status === "ACTIVATE" && "Active"}
                        {row?.status === "DEACTIVATE" && "Inactive"}
                        {row?.status === "INVITED" && "Invited"}
                    </Typography>
                </Box>
            );

        default:
            return (
                <Typography variant="body3" sx={{fontWeight: 400, fontSize: isMobile ? "10px" : "12px"}}>
                    {row[headerName] ? row[headerName] : "N/A"}
                </Typography>
            );
    }
}
