import SearchSection from "commonComponent/SearchSection";
import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import {Badge, Box, Button, Grid, IconButton, Typography, useMediaQuery, useTheme} from "@mui/material";
import AnimateButton from "ui-component/extended/AnimateButton";
import Download from "assets/images/icons/Download.svg";
import DeleteIcon from "assets/images/icons/Delete.svg";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import {API, NetworkManager} from "network/core";
import {useLocation} from "react-router-dom";

const TableTopFilterWrapper = (props) => {
    const {
        btnTitle,
        csvButton,
        handleClick,
        showBadge,
        children,
        handleSearch,
        filterType,
        onlySearch = false,
        pageheader = false,
        lessmarginOnTop = false,
        onlyHeading = false,
        onlyBtn = false,
        noAlignItem = false,
        orderFilter = null,
        onlyDownload = false,
        selectedCheckbox = [],
        handleDeleteBulk,
        deleteBulkTitle = "orders",
        csvBuilder,
        multipleQuote,
        handleMultipleQuote
    } = props;
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };
    const handleDelete = () => {
        handleDeleteBulk();
        handleDeleteModal();
    };

    const params = useLocation();

    const isQuotes = params?.search === "?tab=quotes";

    return (
        <Box>
            <Grid
                container
                wrap="nowrap"
                className="page-header"
                justifyContent="space-between"
                {...(!noAlignItem && {alignItems: "center"})}
                sx={{mt: lessmarginOnTop ? 0 : 4, mb: 2}}
            >
                {!isMobile && pageheader && (
                    <div>
                        <Typography>{pageheader}</Typography>
                    </div>
                )}

                {!onlyHeading && (
                    <Grid item>
                        <Grid container wrap="nowrap">
                            {!onlyBtn && (
                                <>
                                    {!isMobile && !onlySearch && (
                                        <Grid item sx={{display: "flex", justifyContent: "center", alignItems: "center"}} paddingRight={1}>
                                            <Badge
                                                color="primary"
                                                overlap="circular"
                                                badgeContent=" "
                                                variant="dot"
                                                invisible={!showBadge}
                                                onClick={handleClick(filterType)}
                                                sx={{cursor: "pointer"}}
                                            >
                                                <FilterListIcon />
                                            </Badge>
                                            {/* <IconButton onClick={handleClick(filterType)}>
                                                <FilterListIcon />
                                            </IconButton> */}
                                        </Grid>
                                    )}
                                    {orderFilter === "orderFilter" && selectedCheckbox.length > 0 && (
                                        <Grid item sx={{display: "flex", columnGap: 2}}>
                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    columnGap: 1,
                                                    fontSize: "0.875rem",
                                                    color: "#212121",
                                                    fontWeight: 500
                                                }}
                                            >
                                                {selectedCheckbox.length} Selected
                                            </Typography>
                                            {multipleQuote && (
                                                <AnimateButton>
                                                    <Button
                                                        onClick={handleMultipleQuote}
                                                        variant="outlined"
                                                        sx={{
                                                            color: (theme) => theme.palette.primary.main,
                                                            height: 35,
                                                            ml: 1.3,
                                                            minWidth: "152px",
                                                            boxShadow: "none",
                                                            textTransform: "initial"
                                                        }}
                                                    >
                                                        <Typography noWrap variant="body1" fontWeight={500}>
                                                            {isQuotes ? "Resend" : "Send"} multiple quotes
                                                        </Typography>
                                                    </Button>
                                                </AnimateButton>
                                            )}

                                            <Typography
                                                onClick={() => csvBuilder()}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    columnGap: 1,
                                                    fontSize: "0.875rem",
                                                    color: "#00ab3a",
                                                    fontWeight: 500,
                                                    cursor: "pointer"
                                                }}
                                                mr={0.5}
                                            >
                                                <img src={Download} alt="download" />
                                                Download
                                            </Typography>

                                            {handleDeleteBulk && (
                                                <Typography
                                                    onClick={() => {
                                                        handleDeleteModal();
                                                    }}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        columnGap: 1,
                                                        fontSize: "0.875rem",
                                                        color: "#00ab3a",
                                                        fontWeight: 500,
                                                        cursor: "pointer",
                                                        mr: 1
                                                    }}
                                                >
                                                    <img src={DeleteIcon} alt="delete" />
                                                    Delete
                                                </Typography>
                                            )}
                                        </Grid>
                                    )}

                                    <Grid item xs={9} md="auto">
                                        <SearchSection handleUpdate={handleSearch} />
                                    </Grid>
                                </>
                            )}

                            <Grid item>
                                <Box sx={{display: "flex", gap: 1}}>
                                    <Box>
                                        {csvButton && (
                                            <AnimateButton>
                                                <Button
                                                    onClick={handleClick(csvButton)}
                                                    variant="outlined"
                                                    sx={{
                                                        color: (theme) => theme.palette.primary.main,
                                                        height: 35,
                                                        ml: 1.3,
                                                        minWidth: "152px",
                                                        boxShadow: "none",
                                                        textTransform: "initial"
                                                    }}
                                                >
                                                    <Typography noWrap variant="body1" fontWeight={500}>
                                                        {csvButton}
                                                    </Typography>
                                                </Button>
                                            </AnimateButton>
                                        )}
                                    </Box>

                                    <Box>
                                        {btnTitle && (
                                            <AnimateButton>
                                                <Button
                                                    onClick={handleClick(btnTitle)}
                                                    variant="contained"
                                                    sx={{
                                                        color: (theme) => theme.palette.background.paper,
                                                        height: 35,
                                                        ml: 1.3,
                                                        minWidth: "152px",
                                                        boxShadow: "none",
                                                        textTransform: "initial"
                                                    }}
                                                >
                                                    <Typography noWrap variant="body1" fontWeight={500}>
                                                        {btnTitle}
                                                    </Typography>
                                                </Button>
                                            </AnimateButton>
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {onlyDownload && onlyHeading && orderFilter === "orderFilter" && selectedCheckbox.length > 0 && (
                    <Grid item sx={{display: "flex", columnGap: 2}}>
                        <Typography
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: 1,
                                fontSize: "0.875rem",
                                color: "#212121",
                                fontWeight: 500
                            }}
                        >
                            {selectedCheckbox.length} Selected
                        </Typography>

                        <Typography
                            onClick={() => csvBuilder()}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: 1,
                                fontSize: "0.875rem",
                                color: "#00ab3a",
                                fontWeight: 500,
                                cursor: "pointer"
                            }}
                            mr={0.5}
                        >
                            <img src={Download} alt="download" />
                            Download
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <DeleteModal
                open={open}
                title={`Are you sure you want to delete these ${deleteBulkTitle}?`}
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />

            <Box>{children}</Box>
        </Box>
    );
};

export default TableTopFilterWrapper;
