import {Grid} from "@mui/material";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {NetworkManager, API} from "network/core";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {useNavigate, useSearchParams} from "react-router-dom";
import FilterChips from "commonComponent/FilterChipsComponent";
import {generateUrl, getSubAdminName} from "utils/helperFunctions/helpers";
import Filters from "commonComponent/Filters";
import useTempDrawer from "hooks/useTempDrawer";
import CreateCustomerForm from "../../CreateCustomer/CreateCustomerForm";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";
import {setPageNumber, setRowsPerPage, setOrder, setOrderBy} from "store/slices/paginationSlice";

const Customer = (props) => {
    const {toggleDrawer} = useTempDrawer();
    const {search, showFilter, setSelectedCheckbox, updateListCount, selectedCheckbox, filterChips, setSearchedValue, setFilterValue} =
        props;
    const [title, setTitle] = useState("Create Customer");

    const [open, setOpen] = React.useState(false);
    const [customerListing, setCustomerListing] = React.useState([]);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.order || "asc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "full_name");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    // const [filterChips, setFilterChips] = useState({});
    const [urlSearchParams] = useSearchParams();

    const navigation = useNavigate();
    const tab = urlSearchParams.get("tab");
    const modifierId = urlSearchParams.get("id");

    const dispatch = useDispatch();
    console.log(currentPage);
    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };
    const getCustomerList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.CUSTOMER.GET);
        const params = {
            offset: currentPage,
            limit: rowsPerPage,
            // page: currentPage,
            // pageSize: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };
        if (moreFilters && moreFilters.ltv) {
            if (moreFilters.ltvFilter === "equal" || moreFilters.ltvFilter === undefined) {
                params.ltv = moreFilters.ltv;
            } else {
                delete params.ltv;
                params[`ltv${moreFilters.ltvFilter}`] = moreFilters.ltv;
            }
            delete params.ltvFilter;
        }
        if (moreFilters && moreFilters?.customer_name) {
            params.full_name = moreFilters.customer_name;
            delete params.customer_name;
        }
        if (moreFilters && moreFilters.aov) {
            if (moreFilters.aovFilter === "equal" || moreFilters.aovFilter === undefined) {
                params.aov = moreFilters.aov;
            } else {
                delete params.aov;
                params[`aov${moreFilters.aovFilter}`] = moreFilters.aov;
            }
            delete params.aovFilter;
        }
        if (moreFilters && moreFilters.order_count) {
            if (moreFilters.orderCountFilter === "equal" || moreFilters.orderCountFilter === undefined) {
                params.order_count = moreFilters.order_count;
            } else {
                delete params.order_count;
                params[`order_count${moreFilters.orderCountFilter}`] = moreFilters.order_count;
            }
            delete params.orderCountFilter;
        }
        if (moreFilters && moreFilters.email) {
            params.email = encodeURIComponent(moreFilters.email);
        }
        if (currentOrderBy) {
            params.sortBy = currentOrderBy;
            params.orderBy = currentOrder;
        }

        if (search === "") {
            params.page = currentPage;
        }
        if (search !== "") {
            params.full_name = search;
            setCustomerListing([]);
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);
        if (response.success) {
            const tempData = [];
            response.data?.customers.forEach((element) => {
                const obj = element;
                obj.customerUniqueID = "uniqueCustomerElement";
                obj.subAdmin = getSubAdminName(element?.accountManager);
                tempData.push(obj);
            });
            setCustomerListing(tempData);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
        setSearchedValue({});
        setFilterValue("");
        setLoading(false);
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handleStatus = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.DRIVER.UPDATE(`${values.driver_id}`));
        let isActive = false;
        if (type === "Deactivate") {
            isActive = false;
        } else {
            isActive = true;
        }
        const payload = {
            is_active: isActive
        };
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleResendInvite = async (row) => {
        const instance = NetworkManager(API.CUSTOMER.RESENDINVITE(row.user_id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        getCustomerList();
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                navigation(`/customers/${row.user_id}/?tab=profile&customerName=${row?.full_name}&edit=true`);
                break;
            }
            case "Resend invite": {
                handleResendInvite(row);
                break;
            }

            case "Delete": {
                handleDeleteModal();
                break;
            }
            default:
                break;
        }
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.CUSTOMER.BULKDELETE);
        const response = await instance.request({customerIds: selectedRow.user_id});
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getCustomerList();
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
        // setCurrentPage(number + 1);
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        // setRowsPerPage(number);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        if (val2 === "company.company_name") {
            const splitText = val2.split(".");
            dispatch(setOrder(val1));
            dispatch(setOrderBy(splitText[1]));
        } else if (val2 === "isActivate") {
            dispatch(setOrder(val1));
            dispatch(setOrderBy("is_active"));
        } else {
            dispatch(setOrder(val1));
            dispatch(setOrderBy(val2));
        }
    };

    useEffect(() => {
        if (search) {
            dispatch(setPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        getCustomerList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, filterChips, updateListCount]);

    const filterState = [
        {
            key: "full_name",
            label: "Customer",
            type: "nameTextfield"
        },
        {
            key: "company_name",
            label: "Company",
            type: "textfield"
        },
        {
            key: "ltv",
            label: "LTV",
            type: "numberfield",
            filterType: "Equals",
            filterKey: "ltvFilter"
        },
        {
            key: "aov",
            label: "AOV",
            type: "numberfield",
            filterType: "Equals",
            filterKey: "aovFilter"
        },
        {
            key: "order_count",
            label: "Order count",
            type: "numberfield",
            filterType: "Equals",
            filterKey: "orderCountFilter"
        },
        {
            key: "email",
            label: "Email",
            type: "textfield"
        },
        {
            key: "address",
            label: "Address",
            type: "textfield"
        },
        {
            key: "start_date",
            label: "Created from",
            type: "startDate"
        },

        {
            key: "end_date",
            label: "Created to",
            type: "startDate"
        },
        {
            key: "last_order_start_date",
            label: "Last order from",
            type: "startDate"
        },
        {
            key: "last_order_end_date",
            label: "Last order to",
            type: "startDate"
        }
    ];

    const handleApplyFilter = (Morefilters) => {
        dispatch(setPageNumber(1));
        // setFilterChips(Morefilters);
    };
    return (
        <>
            {showFilter ? (
                <Filters
                    filterState={filterState}
                    filterChips={filterChips}
                    // setFilterChips={setFilterChips}
                    handleApplyFilter={handleApplyFilter}
                />
            ) : (
                <CreateCustomerForm
                    selectedData={selectedRow}
                    setTitle={setTitle}
                    conditionalTitle={title}
                    handleIsEdited={handleIsEdited}
                />
            )}

            <Grid container>
                <Grid item xs={12}>
                    {/* <FilterChips
                        filterChips={filterChips}
                        //  setFilterChips={setFilterChips}
                    /> */}
                    <CommonTable
                        withCheckbox
                        loading={loading}
                        data={customerListing}
                        notCaptilize
                        header={[
                            {key: "full_name", label: "Customer"},
                            {key: "company.company_name", label: "Company"},
                            {key: "customerAddresses.delivery_address", label: "Address"},
                            {key: "orderCount", label: "Orders"},
                            {key: "lifeTimeValue", label: "LTV"},
                            {key: "averageOrderValue", label: "AOV"},
                            {key: "last_order", label: "Last order"},
                            {key: "subAdmin", label: "Account manager"},
                            {key: "createdAt", label: "Created"},
                            {key: "isActivate", label: "Status"},
                            {key: "action", label: "Action"}
                        ]}
                        height="75vh"
                        handleActionBtn={handleActionBtn}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        setSelectedCheckbox={setSelectedCheckbox}
                        selectedCheckbox={selectedCheckbox}
                        currentPage={currentPage - 1}
                        sortingLabel={["Customer", "Status", "Created", "Company", "Last order", "Orders", "LTV", "AOV"]}
                    />
                </Grid>
            </Grid>

            <DeleteModal
                open={open}
                title={`Are you sure you want to delete ${ellipsizeText(
                    selectedRow?.full_name + (selectedRow?.last_name ? ` ${selectedRow?.last_name}` : "") || "N/A"
                )}?`}
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
        </>
    );
};

export default Customer;
