export default class StorageManager {
  static put = (storageKey, value) => {
    localStorage.setItem(storageKey, value);
  }; 

  static get = (storageKey) =>  localStorage.getItem(storageKey);


  static removeItem = (storageKey) => localStorage.removeItem(storageKey);


  static clearStore = () =>  localStorage.clear();
}
 