import { Button, Dialog, DialogContent, DialogContentText, Typography, DialogTitle, Slide, Stack } from "@mui/material";
// import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import React, { useEffect, useState } from "react";
import AnimateButton from "ui-component/extended/AnimateButton";
import styles from "./Driver.module.scss";
import Cancel from "../../assets/images/icons/cancel.svg";
// import AnimateButton from "ui-component/extended/AnimateButton";
import { Box } from "@mui/system";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";
import { NetworkManager, API } from "network/core";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const DriverModal = (props) => {
    const {
        driversData,
        marketId,
        title,
        description,
        open,
        handleClose,
        handleSubmit,
        orderId = "",
        hideBackdrop = false,
        buttonTitle = "Delete",
        noOnClose = false,
        prefillDriverId = null,
        secondaryDriverPrefill = []
    } = props;
    const [selectedDriver, setSelectedDriver] = useState("");
    const [driverDatas, setDriverDatas] = useState(null);
    const [secondaryDriverList, setSeondaryDriverList] = useState(null);
    const [PrefillsecondaryDriver, setPrefillSecondaryDriver] = useState([])
    const [selectedSecondaryDriver, setSelectedSecondaryDriver] = useState([]);
    const [showSecondary, setShowSecondary] = useState(false)

    useEffect(() => {
        if (prefillDriverId) {
            setSelectedDriver(Number(prefillDriverId))
        }
        else {
            setSelectedDriver("");
        }

        if (driversData?.length > 0 && marketId?.length > 0) {
            const tempDriverArr = [];
            driversData.forEach((driver) => {
                if (driver?.market?.length > 0) {
                    const tempDriverData = driver?.market.filter((item) => item.market_id === marketId);
                    if (tempDriverData.length > 0) {
                        tempDriverArr.push(driver);
                    }
                }
            });
            if (tempDriverArr.length > 0) {
                setDriverDatas(tempDriverArr.map((item) => item));
            } else {
                setDriverDatas([]);
            }
        }
    }, [marketId, driversData]);

    // useEffect(() => {

    //     if (secondaryDriverPrefill?.length) {
    //         setSelectedSecondaryDriver(secondaryDriverPrefill)
    //         setShowSecondary(true)
    //     }

    // }, [secondaryDriverPrefill])

    useEffect(() => {
        if (selectedDriver === "" && driverDatas?.length > 0) {
            setSeondaryDriverList(driversData?.map((el) => ({
                label: `${el?.name}`,
                id: el?.id
            })))
        }
        else if (driverDatas) {
            const tempDriverArr = driverDatas.filter((item) => item.id !== selectedDriver)
            if (tempDriverArr?.length) {

                setSeondaryDriverList(tempDriverArr?.map((el) => ({
                    label: `${el?.name}`,
                    id: el?.id
                })))
            }
            else {
                setSeondaryDriverList([])
            }
            // if secondary driver list selected and primary driver updated
            // then remove the secondary driver if its selected at primary

            const tempArr = [...selectedSecondaryDriver]
            setSelectedSecondaryDriver([...tempArr.filter((item) => item !== selectedDriver)])

        }
    }, [selectedDriver, driverDatas])

    const getDriverListSecondary = async (orderId) => {
        const instance = NetworkManager(API.DRIVER.SECONDARYLISTING(orderId));
        const response = await instance.request();

        if (response.success) {
            const driverList = response.data?.driver;
            if (driverList?.length) {
                setSelectedSecondaryDriver(driverList.map((row)=>Number(row.id)));
                setShowSecondary(true)
            }
        }
    };

    useEffect(() => {
        if (orderId !== "" && orderId !== null) {
            getDriverListSecondary(orderId)
        }

    }, [orderId])

    const handleAssignDriver = async () => {
        if (selectedSecondaryDriver?.length) {
            const instance = NetworkManager(API.DRIVER.SECONDARYASSIGN);
            const response = await instance.request({
                orderId: orderId,
                carrierIds: selectedSecondaryDriver.map(item => `${item}`)
            });
        }
    };

    return (
        <Dialog
            open={open}
            // {...(!noOnClose && {onClose: handleClose})}
            // TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            hideBackdrop={hideBackdrop}
            PaperProps={{ sx: { boxShadow: "0px 0px 8px rgba(0,0,0,0.2)", width: "421px", position: "relative", padding: "33px 23px" } }}
        >
            <Box
                sx={{ position: "absolute", right: "16px", top: "16px", cursor: "pointer" }}
                onClick={() => {
                    setSelectedDriver("");
                    handleClose();
                    setShowSecondary(false);
                    setSeondaryDriverList(null);
                }}
            >
                <img src={Cancel} alt="plus-icon" width="12px" height="12px" />
            </Box>
            <DialogTitle id="alert-dialog-title" className={styles.title} sx={{ letterSpacing: "0.001px", padding: "0px" }}>
                {title}
            </DialogTitle>
            <DialogContent sx={{ padding: "0px" }}>
                <DialogContentText id="alert-dialog-description" className={styles.titleDivision} sx={{ color: "#000A12" }}>
                    {driverDatas?.length > 0 && description}
                </DialogContentText>
                <Stack sx={{ marginTop: "25px", marginBottom: "8px" }}>
                    {driverDatas?.length > 0 ? (
                        <>
                            <CustomSelect
                                noNone
                                label="Select driver"
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: 200
                                        }
                                    }
                                }}
                                fullWidth
                                menuItems={driverDatas?.map((el) => ({
                                    label: `${el?.name}`,
                                    id: el?.id
                                }))}
                                value={selectedDriver}
                                onChange={(e) => {
                                    const driverId = e.target.value;
                                    setSelectedDriver(driverId);
                                }}
                            />
                            {showSecondary ? <Stack
                                mb={1}
                                mt={2}

                            ><CustomSelectWithCheckbox

                                    selectAll

                                    label="Add more driver(s)"
                                    menuItems={secondaryDriverList}
                                    values={selectedSecondaryDriver}
                                    onChange={(val) => {
                                        setSelectedSecondaryDriver(val)

                                    }}
                                /></Stack> :
                                <Stack
                                    mb={1}
                                    mt={2}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}
                                >
                                    <Stack>
                                        <AddCircleIcon
                                            onClick={() =>
                                                setShowSecondary(pre => !pre)
                                            }
                                            sx={{
                                                fontSize: "1.125rem",
                                                color: "#00ab3a",
                                                cursor: "pointer"
                                            }}
                                        />
                                    </Stack>
                                    <Stack ml={0.5} >
                                        <Typography
                                            className={styles.discount}
                                            sx={{ color: "#00ab3a", cursor: "pointer", fontWeight: 600 }}
                                            onClick={() =>
                                                setShowSecondary(pre => !pre)
                                            }
                                        >
                                            Add more driver(s)
                                        </Typography>
                                    </Stack>
                                </Stack>}
                        </>
                    ) : (
                        <DialogContentText id="alert-dialog-description" className={styles.titleDivision}>
                            No driver available for the selected market.
                        </DialogContentText>
                    )}
                </Stack>
            </DialogContent>
            <AnimateButton>
                <Button
                    disabled={selectedDriver.length === 0}
                    fullWidth
                    variant="contained"
                    size="large"
                    sx={{
                        fontSize: "0.813rem",
                        fontStyle: "normal",
                        fontWeight: 600,
                        bgcolor: "#00ab3a",
                        height: "35px",
                        color: "#FFF",
                        ":hover": {
                            bgcolor: "#00ab3a"
                        }
                    }}
                    onClick={() => {
                        handleSubmit(selectedDriver);
                        handleAssignDriver();

                        setTimeout(() => {
                            setSelectedDriver("");
                            setShowSecondary(false);
                            setSeondaryDriverList(null);
                        }, "1000");
                    }}
                >
                    {buttonTitle}
                </Button>
            </AnimateButton>
        </Dialog>
    );
};

export default DriverModal;
