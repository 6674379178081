import React, {useState, useEffect} from "react";
import {Box, Grid, Typography} from "@mui/material";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {API, NetworkManager} from "network/core";

function CompanyDetails({formik, props}) {
    const [existingCompany, setExistingCompany] = useState([]);
    const [companySearch, setCompanySearch] = useState("");
    const dispatch = useDispatch();

    const getExistingCompany = async () => {
        const instance = NetworkManager(API.COMPANY.GET);
        const response = await instance.request({}, [`?company_name=${companySearch}`]);
        if (response.success) {
            const data = response.data.companyList;
            setExistingCompany(data);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Error fetching existing companies",
                    variant: "error",
                    close: true
                })
            );
        }
    };

    const createCompany = async (name) =>
        new Promise((resolve) => {
            const instance = NetworkManager(API.COMPANY.ADD);
            instance.request({company_name: name}).then((response) => {
                if (response.success) {
                    resolve(response.data?.company?.company_id);
                }
            });
        });

    useEffect(() => {
        if (companySearch) {
            getExistingCompany();
        } else {
            setExistingCompany([]);
        }
    }, [companySearch, dispatch]);

    const stopPropagationForTab = (event) => {
        if (event.key === "Tab") {
            event.stopPropagation();
        }
    };

    const handleCompanyClick = () => {
        getExistingCompany();
    };

    return (
        <Grid item xs={12}>
            <Box borderTop="1px solid #E0E2E6" borderBottom="1px solid #E0E2E6" sx={{margin: "1rem 0", padding: "2rem 0"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{fontSize: "1.375rem", fontWeight: 600, color: "#000"}}>Company details</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} mt={1} xl={3.5} sx={{position: "relative"}}>
                        <CustomTextField
                            {...props}
                            fullWidth
                            name="company"
                            label="Company (optional)"
                            placeholder=""
                            onKeyDown={stopPropagationForTab}
                            value={formik.values.company}
                            onClick={handleCompanyClick}
                            onChange={(e) => {
                                setCompanySearch(e.target.value);
                                formik.setFieldValue("company", e.target.value);
                            }}
                            onBlur={async () => {
                                if (companySearch) {
                                    const companyId = await createCompany(companySearch);
                                    formik.setFieldValue("companyId", companyId);
                                }
                            }}
                        />
                        {!!existingCompany?.length && (
                            <Box
                                sx={{
                                    p: 1,
                                    boxShadow: "0px 0px 3px rgba(0,0,0,0.3)",
                                    borderRadius: 0.5,
                                    position: "absolute",
                                    width: "96%",
                                    bgcolor: "#fff",
                                    maxHeight: "200px",
                                    overflow: "auto",
                                    zIndex: 1380
                                }}
                            >
                                {existingCompany?.map((obj, i) => (
                                    <Box
                                        key={i}
                                        sx={{p: 0.2, py: 0.2, cursor: "pointer", "&:hover": {bgcolor: "#Fafafa"}}}
                                        onClick={() => {
                                            formik.setFieldValue("company", obj.company_name);
                                            formik.setFieldValue("companyId", obj.company_id);
                                            setExistingCompany([]);
                                        }}
                                    >
                                        <Typography variant="body3" fontSize="0.875rem" fontWeight={500}>
                                            {obj?.company_name}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} mt={1} xl={3.5}>
                        <CustomTextField fullWidth label="Title / position (optional)" {...formik.getFieldProps("title")} />
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
}

export default CompanyDetails;
