import {useTheme} from "@mui/material";

export const useStyles = () => {
    const theme = useTheme();
    console.log(theme)
    return {
        inputs: {
            fontSize: "0.73em",
            fontWeight: 500,
            "&": {
                height: "40px !important",
                "& .MuiInputBase-root > input": {
                    bgcolor: "#F0F0F0"
                },
                "& .MuiOutlinedInput-root": {
                    bgcolor: "#F0F0F0",
                    height: "40px",
                    overflow: "hidden",
                    "& >input": {
                        fontWeight: 500
                    }
                }
            }
        },
        saveButton: {
            p: 0.8,
            color: theme.palette.common.white,
            bgcolor: theme.palette.primary.main,
            borderRadius: 0.5
        },
        hundredPercentWidth: {
            width: "100%"
        },
        addInputs: {
            fontSize: "0.73em",

            "&": {
                height: "40px !important",
                "& .MuiOutlinedInput-root": {
                    height: "40px",
                    overflow: "hidden",
                    "& >input": {
                        fontWeight: 500
                    }
                }
            }
        },
        existingModifier: {
            p: 1,
            boxShadow: "0px 0px 3px rgba(0,0,0,0.3)",
            borderRadius: 0.5,
            position: "absolute",
            width: "100%",
            bgcolor: theme.palette.common.white,
            maxHeight: "200px",
            overflow: "auto",
            zIndex: 1380
        },
        modItem: {p: 0.2, py: 0.2, cursor: "pointer", "&:hover": {bgcolor: "#Fafafa"}}
    };
};
