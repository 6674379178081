import { set } from "lodash";
import React, { useEffect, useState } from "react";

export const useAppTableController = (props) => {
    const { data, handleAction, handleSort, setSelectedCheckbox, selectedCheckbox } = props;
    const [selected, setSelected] = React.useState([]);
    const [confirmModal, setConfirmModal] = useState(false);
    const [orderBy, setOrderBy] = useState("");
    const [order, setOrder] = useState("asc");
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [isAllChecked, setAllChecked] = useState(false);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const names = updateData.map((rowObj) => JSON.stringify(rowObj));
            const List = new Set([...selected, ...names]);
            setSelected(Array.from(List));
            setSelectedCheckbox(Array.from(List));
            setAllChecked(true);
            return;
        }

        const names = updateData.map((rowObj) => JSON.stringify(rowObj));
        const List = new Set([...names]);
        const difference = selected.filter((x) => !List.has(x));
        setSelected(difference);
        setSelectedCheckbox(difference);
        setAllChecked(false);
    };

    // const isAllChecked =()=>{
    //     if(selected.length ===0){
    //         return false
    //     }

    //    const flag = updateData.every(v => selected.includes(v));
    //    return flag;
    // }

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setAllChecked(updateData.every((v) => newSelected.includes(JSON.stringify(v))));
      
        setSelected(newSelected);
        setSelectedCheckbox(newSelected);
    };

    const handleActions = () => {
        setConfirmModal(!confirmModal);
        handleAction();
    };

    const handleSorted = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        handleSort(isAsc ? "desc" : "asc", property);
    };

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const handleConfirmModal = () => {
        setConfirmModal(!confirmModal);
    };

    // To change in sorting request and data updation
    const handleDataChange = () => {
        setUpdateData(data);
        if (data?.length > 0) {
            setAllChecked(data.every((v) => selected.includes(JSON.stringify(v))));
        }

        setLoading(false);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    useEffect(() => {
        setLoading(true);
        handleDataChange();
    }, [searchText, data]);

    useEffect(() => {
        setSelected(selectedCheckbox);
        if (selectedCheckbox.length === 0) {
            setAllChecked(false);
        }
    }, [selectedCheckbox]);

    return {
        data,
        handleSorted,
        handleSearch,
        handleConfirmModal,
        handleDataChange,
        confirmModal,
        setConfirmModal,
        orderBy,
        setOrderBy,
        order,
        setOrder,
        searchText,
        setSearchText,
        loading,
        setLoading,
        updateData,
        setUpdateData,
        handleActions,
        handleSelectAllClick,
        handleClick,
        selected,
        isSelected,
        isAllChecked
    };
};
