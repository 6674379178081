import {Reorder} from "commonComponent/Dragger/utils";
import useTempDrawer from "hooks/useTempDrawerModifier";
import {API, NetworkManager} from "network/core";
import React, {useEffect, useState} from "react";

const useModifierGroupController = (props) => {
    const {formik} = props;

    const [modiferGroup, setModifierGroup] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const {toggleDrawer} = useTempDrawer();

    const handleSelectedRow = (val) => {
        setSelectedRow(val);
    };

    const handleIsEdited = () => {
        console.log("");
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            
            return;
        }
      

        if (result.type === "QUESTIONS") {
            const quest = Reorder(formik.values.modifier_group_id, result.source.index, result.destination.index);
            formik.setFieldValue("modifier_group_id", quest);
            // setModifierGroup(quest);
        } else {
            const answers = Reorder(
                formik.values.modifier_group_id[parseInt(result.type, 10)].answers,
                result.source.index,
                result.destination.index
            );

            const quest1 = JSON.parse(JSON.stringify(formik.values.modifier_group_id));

            quest1[result.type].answers = answers;
            formik.setFieldValue("modifier_group_id", quest1);
            // setModifierGroup(quest1);
        }
    };

    const handleActionBtn = (type, row, handleClose, arrayHelper, index) => (e) => {
        
        handleSelectedRow(row);
        setSelectedIndex(index);
        handleClose();
        switch (type) {
            case "Edit": {
                 toggleDrawer("right", "addModifierGroup")(e);
                break;
            }
            case "Remove": {
                arrayHelper.remove(index);
                break;
            }
            default:
                break;
        }
    };

    const getModifiersGroupList = async () => {
        const instance = NetworkManager(API.MODIFIERGROUP.GET);
        const params = {
            page: 1,
            pageSize: 5000,
            sortBy: "modifier_group_name",
            orderBy: "asc",
            is_active: true
        };

        const response = await instance.request({}, params);

        if (response.success) {
            const MG = response.data.modifierList.map((state) => ({
                label: state.modifier_group_name,
                id: state.modifier_group_id,
                ...state
            }));
            setModifierGroup(MG);
        }
    };

    useEffect(() => {
        getModifiersGroupList();
    }, []);

    return {
        modiferGroup,
        handleActionBtn,
        onDragEnd,
        selectedRow,
        handleSelectedRow,
        handleIsEdited,
        getModifiersGroupList,
        selectedIndex
    };
};

export default useModifierGroupController;
