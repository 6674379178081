import React from "react";
import CreateLocationForm from "./CreateLocationForm";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import {Grid, Stack, Typography} from "@mui/material";
import useCreateLocationController from "./CreateLocation.controller";
import Loader from "ui-component/Loader";
import CustomSwitch from "commonComponent/Form/Fields/CustomSwitch";
import {useSearchParams} from "react-router-dom";

const CreateLocations = () => {
    const [searchParam] = useSearchParams();
    const marketName = searchParam.get("marketName");
    const marketId = searchParam.get("marketId");
    const viewPage = searchParam.get("viewpage");

    const CLC = useCreateLocationController();

    const checkReadOnly = (condition, p1, p2) => (condition ? p1 : p2);

    const data = [];

    if (marketName) {
        data.push({label: "Market", href: "/markets"});
    }
    data.push(
        marketId
            ? {label: "Location", href: `/marketLocations/${marketId}?marketName=${marketName}`}
            : {label: "Location", href: "/locations"}
    );

    if (viewPage) {
        data.push({
            label: "View location",
            href: marketId ? `/locationDetail/${CLC.id}?marketId=${marketId}&marketName=${marketName}` : `/locationDetail/${CLC.id}`
        });
    }

    data.push({
        label: CLC.isEdit ? checkReadOnly(CLC.readOnly, "Location detail", "Edit location") : "Create location",
        href: CLC.isEdit ? `/locations/editLocation/${CLC.id}` : "/locations/addLocation",
        isActive: true
    });

    return (
        <>
            {CLC.loading && <Loader />}

            <Grid container>
                <Grid item xs={12}>
                    <PageHeader title={CLC.isEdit ? CLC.locationName : "Create location"} breadsCrumData={data} />
                </Grid>

                <Grid item xs={12}>
                    {CLC.readOnly && (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <CustomSwitch checked={!CLC.readOnly} onChange={() => CLC.setReadyOnly((pre) => !pre)} />
                            <Typography>Enable Editing</Typography>
                        </Stack>
                    )}
                    <CreateLocationForm CLC={CLC} />
                </Grid>
            </Grid>
        </>
    );
};

export default CreateLocations;
