import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, InputAdornment, Box, Typography } from "@mui/material";
import ActionButton from "commonComponent/Apptable/ActionButton";
import SymbolButton from "commonComponent/Buttons/SymbolButton";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import { ErrorMessage, FieldArray } from "formik";
import React from "react";
import { enforceFormat, onlyNumbersWithOutDecimal } from "utils/helperFunctions/helpers";

const Delivery = (props) => {
    const { formik, readOnly } = props;
    const { values, getFieldProps } = formik;
    const { delivery } = values;
    const header = [
        // {key: "deliveryFee", label: "Delivery fee"},
        { key: "minimumOrder", label: "Order minimum" },
        { key: "radius", label: "Delivery distance" },
        { key: "action", label: "" }
    ];

    const handleActionBtn = (type, arrayHelpers, arrIndex, obj) => () => {
        switch (type) {
            case "Duplicate": {
                arrayHelpers.insert(arrIndex + 1, { ...obj });
                break;
            }
            case "Delete": {
                arrayHelpers.remove(arrIndex);
                break;
            }
            default:
                break;
        }
    };

    const TableCellStyle = () => ({
        p: 0,
        pl: 1,
        pb: 2,
        border: "none",
        verticalAlign: "Top"
    });

    return (
        <>
            <Grid container spacing={2} sx={{ display: 'flex', flexDirection: "column" }}>
                <Grid item sm={10.8} md={7.5} lg={5.9} xl={6.3} xs={12}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {header.map((obj, i) => (
                                        <TableCell key={obj.key} sx={{ p: 0, pl: i === 0 ? 0 : 1, border: "none" }}>
                                            {obj.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FieldArray
                                    name="delivery"
                                    render={(arrayHelpers) => (
                                        <>
                                            {delivery?.length &&
                                                delivery?.map((obj, index) => (
                                                    <TableRow key={index}>

                                                        <TableCell sx={() => ({ ...TableCellStyle(), pl: 0, })}>
                                                            <Box sx={{
                                                                '@media (min-width: 1300px) and (max-width:1440px)': {
                                                                    maxWidth: '96.7%'
                                                                  },
                                                            }} >
                                                                <CustomTextField
                                                                    disabled={readOnly}
                                                                    fullWidth
                                                                    sx={{ minWidth: "155px" }}
                                                                    placeholder="Order minimum"
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start" sx={{ color: "#212121" }}>
                                                                                $
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                    onKeyDown={enforceFormat}

                                                                    {...getFieldProps(`delivery[${index}].minimumOrder`)}
                                                                />
                                                                <ErrorMessage
                                                                    name={`delivery[${index}].minimumOrder`}
                                                                    render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                />
                                                            </Box>


                                                        </TableCell>
                                                        <TableCell sx={() => ({ ...TableCellStyle(), })}>
                                                            <CustomTextField
                                                                fullWidth
                                                                disabled={readOnly}
                                                                sx={{ Width: "13.4em" }}
                                                                placeholder="Delivery distance"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <Typography color="#CCCFD6">miles from location</Typography>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                // onKeyDown={enforceFormat}
                                                                onKeyDown={onlyNumbersWithOutDecimal}
                                                                {...getFieldProps(`delivery[${index}].radius`)}
                                                            />
                                                            <ErrorMessage
                                                                name={`delivery[${index}].radius`}
                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={() => ({ ...TableCellStyle() })} />
                                                        <TableCell sx={() => ({ ...TableCellStyle() })} />
                                                        <TableCell sx={() => ({ ...TableCellStyle() })} />

                                                        <TableCell
                                                            align="right"
                                                            sx={() => ({ ...TableCellStyle(), verticalAlign: "middle" })}
                                                        >
                                                            {!readOnly && (
                                                                <ActionButton
                                                                    ActionArray={index === 0 ? ["Duplicate"] : ["Duplicate", "Delete"]}
                                                                    handleActionBtn={(type) => (e) =>
                                                                        handleActionBtn(type, arrayHelpers, index, obj)(e)}
                                                                />
                                                            )}
                                                        </TableCell>

                                                    </TableRow>
                                                ))}
                                            {!readOnly && (
                                                <TableRow>
                                                    <Box
                                                        sx={{ width: "120px" }}
                                                        onClick={() =>
                                                            arrayHelpers.push({
                                                                deliveryFee: "",
                                                                minimumOrder: "",
                                                                radius: ""
                                                            })
                                                        }
                                                    >
                                                        <SymbolButton title="Add another" />
                                                    </Box>
                                                </TableRow>
                                            )}
                                        </>
                                    )}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={4.5} md={6.3} lg={5.9} xl={5.9} >
                    <TableContainer>
                        <Table>

                            <TableBody>
                                <TableRow >
                                    <TableCell sx={() => ({ ...TableCellStyle(), pl: 0 })}  >
                                        <Box sx={{
                                            maxWidth: { md: '44.7%', lg: '42.7%', xl: '42.7%' }, "&": {
                                                '.MuiTextField-root': {
                                                    width: '100%',
                                                    mt: '6px'
                                                }
                                            }
                                        }} >
                                            <CustomTextField
                                                sx={{
                                                    "&": {
                                                        ".MuiOutlinedInput-root": {
                                                            minWidth: "155px",
                                                            width: '100%'
                                                        }
                                                    }
                                                }}
                                                label="Buffer time"
                                                {...(formik.values?.setTime && {
                                                    InputProps: {
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                <Typography color="#CCCFD6">mins</Typography>
                                                            </InputAdornment>
                                                        )
                                                    }
                                                })}
                                                onKeyDown={onlyNumbersWithOutDecimal}
                                                {...getFieldProps("setTime")}
                                            />
                                            <ErrorMessage name="setTime" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={() => ({ ...TableCellStyle() })} />
                                    <TableCell sx={() => ({ ...TableCellStyle() })} />
                                    <TableCell sx={() => ({ ...TableCellStyle() })} />
                                    <TableCell sx={() => ({ ...TableCellStyle() })} />
                                    <TableCell sx={() => ({ ...TableCellStyle() })} />

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>
            </Grid>
        </>
    );
};

export default Delivery;
