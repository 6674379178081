import React from "react";
import {DatePicker, DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import CustomNewDateTimePicker from "commonComponent/newDateAndTimePicker/CustomNewDateTimePicker";

const NewCustomDateTimePicker = (props) => {
    const {valueBasic, setValueBasic, ...restProps} = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="custom-date-picker">
                <CustomNewDateTimePicker
                    {...restProps}
                    format="M/dd/yyyy"
                    views={["day"]}
                    value={valueBasic}
                    onChange={(newValue) => {
                        setValueBasic(newValue);
                    }}
                    // differentAnchor={anchorEl}
                    disablePast
                    // setDifferentAnchor={setAnchorEl}
                    // value={headerData?.when || null}
                    // PaperProps={{
                    //     sx: {
                    //         "& .MuiPickersDay-root": {
                    //             "& .Mui-selected": {
                    //                 color: "white"
                    //             },
                    //             "& .Mui-selected:focus": {
                    //                 backgroundColor: "#00ab3a !important",
                    //                 background: "#00ab3a !important"
                    //             }
                    //         },
                    //         "& .MuiPickersDay-root:focus": {
                    //             backgroundColor: "#00ab3a",
                    //             background: "#00ab3a"
                    //         },
                    //         "& .Mui-selected:focus": {
                    //             backgroundColor: "#00ab3a !important",
                    //             background: "#00ab3a !important"
                    //         },
                    //         "& .MuiPickersDay-today": {
                    //             "& .Mui-selected": {
                    //                 color: "white"
                    //             },
                    //             "& .Mui-selected:focus": {
                    //                 backgroundColor: "#00ab3a !important",
                    //                 background: "#00ab3a !important"
                    //             }
                    //         },
                    //         "& .MuiPickersDay-root:not": {
                    //             "& .Mui-selected": {
                    //                 border: "1px solid #697586",
                    //                 color: "white"
                    //             }
                    //         }
                    //     }
                    // }}
                    renderInput={(props) => <CustomTextField fullWidth placeholder="mm/dd/yyyy" sx={{minWidth: "7em"}} {...props} />}
                    // onChange={(val) => {
                    //     Parentformik.setFieldValue("headerData.when", val);
                    //     // Uncomment these lines if necessary for additional logic
                    //     // if (Parentformik.values.itemDetail.length === 0) {
                    //     //     Parentformik.setFieldValue("headerData.when", val);
                    //     // }
                    //     // else if (Parentformik.values.itemDetail.length > 0) {
                    //     //     setDummyLocation(val)
                    //     //     getBrandList(val)
                    //     // }
                    // }}
                />
            </div>
        </LocalizationProvider>
    );
};

export default NewCustomDateTimePicker;
