import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    Radio,
    RadioGroup,
    Stack,
    Typography
} from "@mui/material";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import React from "react";
import {enforceFormat, enforceFormatForNumber} from "utils/helperFunctions/helpers";
import {ErrorMessage, FieldArray, Form, Formik} from "formik";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSwitch from "commonComponent/Form/Fields/CustomSwitch";
import useSettingSectionController from "./settingSection.controller";
import {BpRadio} from "commonComponent/Form/Fields/CustomRadioButton";
import Unchecked from "assets/images/icons/unchecked.svg";
import Checked from "assets/images/icons/lightCheckedBox.svg";

const SettingSection = (props) => {
    const {formik, isEdit = true} = props;
    const {getFieldProps} = formik;
    const STC = useSettingSectionController();

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{padding: "16px 0px"}}>
                        <Typography variant="headingOne">Settings</Typography>
                    </Box>
                </Grid>
                <Grid item container spacing={4} xs={12}>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <CustomTextField
                                disabled={!isEdit}
                                fullWidth
                                placeholder="Minimum order quantity"
                                label="Minimum order quantity"
                                inputProps={{maxLength: 7}}
                                onKeyDown={enforceFormatForNumber}
                                {...getFieldProps("minimumOrderQuantity")}
                            />
                            <ErrorMessage name="minimumOrderQuantity" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <CustomTextField
                                disabled={!isEdit}
                                fullWidth
                                placeholder="Serving size"
                                label="Serving size"
                                inputProps={{maxLength: 7}}
                                onKeyDown={enforceFormatForNumber}
                                {...getFieldProps("servingSize")}
                            />
                            <ErrorMessage name="servingSize" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>
                        {/* <Grid item sm={6} xs={12}>
                            <CustomTextField
                                disabled={!isEdit}
                                fullWidth
                                placeholder="Portion size"
                                label="Portion size"
                                inputProps={{maxLength: 250}}
                                onKeyDown={enforceFormat}
                                {...getFieldProps("portionSize")}
                            />
                            <ErrorMessage name="portionSize" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid> */}
                        {/* <Grid item sm={6} xs={12}>
                            <CustomSelect
                                disabled={!isEdit}
                                label="Unit"
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: 200
                                        }
                                    }
                                }}
                                menuItems={[{label: "OZ", id: "oz"}]}
                                {...getFieldProps("unit")}
                            />
                            <ErrorMessage name="unit" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid> */}
                    </Grid>
                    <Grid item spacing={2} container xs={12}>
                        <Grid item sm={6} xs={12}>
                            <FieldArray
                                name="temperatureServed"
                                render={() => (
                                    <FormControl>
                                        <Typography variant="checkBoxSelected" fontSize="13px" sx={{mb: 2}}>
                                            Temperature served
                                        </Typography>

                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={formik.values.temperatureServed[0] || ""}
                                            onChange={(e) => formik.setFieldValue("temperatureServed[0]", e.target.value)}
                                            name="radio-buttons-group"
                                        >
                                            {STC.RadioButtons?.map((obj, index) => (
                                                <FormControlLabel
                                                    sx={{height: 30}}
                                                    disabled={!isEdit}
                                                    key={index}
                                                    value={obj.value}
                                                    control={<BpRadio />}
                                                    label={
                                                        <Typography
                                                            fontSize="15px"
                                                            variant={
                                                                formik.values.temperatureServed[0] === obj.value
                                                                    ? "checkBoxSelected"
                                                                    : "checkBoxUnSelected"
                                                            }
                                                        >
                                                            {obj.lable}
                                                        </Typography>
                                                    }
                                                />
                                            ))}
                                        </RadioGroup>
                                        <ErrorMessage
                                            name="temperatureServed"
                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                        />
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        {/* <Grid item sm={6} xs={12}>
                            <FieldArray
                                name="packaging"
                                render={(arrayHelper) => (
                                    <FormControl>
                                        <Typography variant="checkBoxSelected" fontSize="13px" sx={{mb: 2}}>
                                            Packaging
                                        </Typography>

                                        <FormGroup>
                                            {STC.checkBoxs.map((obj, index) => (
                                                <Stack spacing={2}>
                                                    <FormControlLabel
                                                        sx={{height: 30}}
                                                        disabled={!isEdit}
                                                        checked={formik.values.packaging.includes(obj.value)}
                                                        key={index}
                                                        control={
                                                            <Checkbox
                                                                disableRipple
                                                                icon={
                                                                    <img
                                                                        src={Unchecked}
                                                                        style={{width: "18px", height: "18px"}}
                                                                        alt="checked"
                                                                    />
                                                                }
                                                                checkedIcon={
                                                                    <img
                                                                        src={Checked}
                                                                        alt="checkbox"
                                                                        style={{width: "18px", height: "18px"}}
                                                                    />
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            <Typography
                                                                fontSize="15px"
                                                                variant={
                                                                    formik.values.packaging.length &&
                                                                    formik.values.packaging.includes(obj.value)
                                                                        ? "checkBoxSelected"
                                                                        : "checkBoxUnSelected"
                                                                }
                                                            >
                                                                {obj.lable}
                                                            </Typography>
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                formik.values.packaging.length &&
                                                                formik.values.packaging.includes(obj.value)
                                                            ) {
                                                                formik.setFieldValue("individual", `1.00`);
                                                                const valIndex = formik.values.packaging.findIndex(
                                                                    (el) => el === obj.value
                                                                );
                                                                arrayHelper.remove(valIndex);
                                                            } else {
                                                                arrayHelper.push(obj.value);
                                                            }
                                                        }}
                                                    />
                                                  
                                                    {index !== 0 && (
                                                        <Stack pl={3}>
                                                            <CustomTextField
                                                                disabled={!formik.values.packaging.includes(obj.value)}
                                                                fullWidth
                                                                placeholder="Individual upcharge"
                                                                label="Individual upcharge"
                                                                onKeyDown={enforceFormat}
                                                                {...getFieldProps("individual")}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start" sx={{color: "#212121"}}>
                                                                            +$
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            />
                                                            <ErrorMessage
                                                                name="individual"
                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                            />
                                                        </Stack>
                                                    )}
                                                </Stack>
                                            ))}
                                        </FormGroup>
                                        <ErrorMessage name="packaging" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </FormControl>
                                )}
                            />
                        </Grid> */}
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12}>
                            <CustomTextField
                                disabled={!isEdit}
                                fullWidth
                                placeholder="Kitchen note (For kitchen staff only)"
                                label="Kitchen note (For kitchen staff only)"
                                inputProps={{maxLength: 250}}
                                {...getFieldProps("kitchenNote")}
                            />
                            <ErrorMessage name="kitchenNote" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>

                        <Grid style={{paddingTop: "16px"}} spacing={2} item xs={12}>
                            <CustomTextField
                                disabled={!isEdit}
                                fullWidth
                                placeholder="Customer note (For customers only)"
                                label="Customer note (For customers only)"
                                inputProps={{maxLength: 250}}
                                {...getFieldProps("customerNote")}
                            />
                            <ErrorMessage name="customerNote" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default SettingSection;
