import { Box, Grid, Stack } from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import { ErrorMessage, Form, Formik } from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { NetworkManager, API } from "network/core";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import DropDown from "commonComponent/Form/Fields/DropDown";
import { generateUrl } from "utils/helperFunctions/helpers";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";

const formSchema = {
    brandName: "",
    abbreviation: "",
    cuisine: "",
    owner: "Bitetime",
    services: ["CATERING"],
    defaultLocation: ""
};

const CreateBrandForm = (props) => {
    const { toggleDrawer } = useTempDrawer();
    const [initialValues, setInitialValues] = useState(formSchema);
    const dispatch = useDispatch();
    const { handleIsEdited, selectedRow, handleSelectedRow } = props;
    const [locations, setLocations] = useState([]);

    const getLocationData = async () => {
        const params = {
            page: 1,
            pageSize: 5000,
            sortBy: "createdAt",
            orderBy: "desc",
            is_active: true
        };

        const url = generateUrl(params);

        const instance = NetworkManager(API.LOCATION.GET);
        const response = await instance.request({}, [url]);

        if (response.success) {
            setLocations(response.data.kitchenLocationList);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    useEffect(() => {
        getLocationData();
        if (selectedRow) {
            const services = selectedRow.brandServices.map((item) => item.service_name);
            setInitialValues({
                brandName: selectedRow.brand_name,
                abbreviation: selectedRow.abbreviation,
                cuisine: selectedRow.cusine,
                owner: selectedRow.owner,
                services: services
            });
        } else {
            setInitialValues({
                brandName: "",
                abbreviation: "",
                cuisine: "",
                owner: "Bitetime",
                services: ["CATERING"],
                defaultLocation: ""
            });
        }
    }, [selectedRow]);

    const handleAddBrand = async (values) => {
        // if selectedRow is not null have to add PUT api else ADD api

        const instance = NetworkManager(selectedRow ? API.BRAND.UPDATE(selectedRow.brand_id) : API.BRAND.ADD);
        const payload = {
            brandName: values.brandName,
            abbreviation: values.abbreviation,
            cusine: values.cuisine,
            owner: values.owner,
            services: values.services,
            kitchen_location_id: [values.defaultLocation],
            default_kitchen_location: values.defaultLocation
        };

        const response = await instance.request(payload);

        if (response.success) {
            setInitialValues({
                brandName: "",
                abbreviation: "",
                cuisine: "",
                owner: "",
                services: "",
                defaultLocation: ""
            });
            dispatch(
                openSnackbar({
                    open: true,
                    message: selectedRow ? "Brand Updated Successfully" : "Brand Created Successfully",
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );

            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={yup.object({
                    brandName: yup.string().required("Brand is required"),
                    abbreviation: yup.string().required("Abbreviation is required"),
                    cuisine: yup.string().required("Cuisine is required"),
                    owner: yup.string().required("Owner is required"),
                    services: yup.array().min(1, "Services is required").required("Services is required"),
                    defaultLocation: yup.string().required("Default location is required")
                })}
                onSubmit={(val) => {
                    handleAddBrand(val);
                }}
            >
                {(formik) => (
                    <Form>

                        <TemporaryDrawer sideBarStateName='addNewBrand' handleSelectedRow={handleSelectedRow} resetForm={formik.resetForm} touched={formik.touched}>
                            <Stack sx={{ height: "100%" }} justifyContent="space-between" mt={-2}>
                                <Stack spacing={2}>
                                    <PageHeader fontSize="1.625rem" title={selectedRow ? "Edit brand" : "Create brand"} showOnlyTitle />
                                    <Stack spacing={3}>
                                        <CustomTextField
                                            placeholder="Brand"
                                            label="Brand"
                                            inputProps={{ maxLength: 50 }}
                                            {...formik.getFieldProps("brandName")}
                                        />
                                        <ErrorMessage name="brandName" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />

                                        <Box sx={{ flexGrow: 1 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <CustomTextField
                                                        fullWidth
                                                        placeholder="Abbreviation"
                                                        label="Abbreviation"
                                                        inputProps={{ maxLength: 50 }}
                                                        {...formik.getFieldProps("abbreviation")}
                                                    />
                                                    <ErrorMessage
                                                        name="abbreviation"
                                                        render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <CustomTextField
                                                        fullWidth
                                                        placeholder="Cuisine"
                                                        label="Cuisine"
                                                        inputProps={{ maxLength: 50 }}
                                                        {...formik.getFieldProps("cuisine")}
                                                    />
                                                    <ErrorMessage
                                                        name="cuisine"
                                                        render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <CustomTextField
                                            placeholder="Owner"
                                            label="Owner"
                                            inputProps={{ maxLength: 50 }}
                                            {...formik.getFieldProps("owner")}
                                        />
                                        <ErrorMessage name="owner" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        <CustomSelectWithCheckbox

                                            selectAll
                                            label="Services"
                                            menuItems={[
                                                { id: "CATERING", label: "Catering", value: "CATERING" },
                                                { id: "HOME_MEALS", label: "Home meals", value: "HOME_MEALS" }
                                            ]}
                                            values={formik.values.services}
                                            onChange={(val) => formik.setFieldValue("services", val)}
                                        />
                                        {/* <DropDown
                                            multiSelect
                                            withCheckBox
                                            placeHolder="Services"
                                            label="Services"
                                            options={[
                                                {id: 2, label: "Catering", value: "CATERING"},
                                                {id: 1, label: "Home meals", value: "HOME_MEALS"}
                                            ]}
                                            selectedItem={formik.values.services}
                                            handleChange={(val) => formik.setFieldValue("services", val)}
                                            MenuPaperProps={{sx: {width: "430px", boxShadow: "0px 0px 3px rgba(0,0,0,0.2)"}}}
                                            FormControlProps={{
                                                fullWidth: true
                                            }}
                                            optionsBoxProps={{sx: {maxHeight: 200, overflowY: "auto"}}}
                                        /> */}
                                        <ErrorMessage name="services" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />

                                        <CustomSelect
                                            label="Default location"
                                            menuItems={locations.map((el) => ({ label: el.name, id: el.kitchen_location_id }))}
                                            {...formik.getFieldProps("defaultLocation")}
                                        />
                                        <ErrorMessage name="defaultLocation" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Stack>
                                </Stack>

                                <ButtonGrouping
                                    btnprops={[
                                        {
                                            btnTitle: "Cancel",
                                            sx: (t) => ({ color: t.palette.error.dark }),
                                            onClick: (e) => {
                                                formik.resetForm();
                                                toggleDrawer("right", false)(e);
                                                handleSelectedRow();
                                            }
                                        },
                                        {
                                            btnTitle: "Save",
                                            variant: "contained",
                                            sx: (t) => ({ color: t.palette.background.paper }),
                                            onClick: (e) => {
                                                formik.handleSubmit({ e });

                                                formik.validateForm().then((res) => {
                                                    // to close the sidebar if no field has validation error
                                                    if (Object.keys(res).length === 0) {
                                                        toggleDrawer("right", false)(e);
                                                        setTimeout(() => {
                                                            formik.resetForm();
                                                        }, 1500);
                                                    }
                                                });
                                            }
                                        }
                                    ]}
                                />
                            </Stack>
                        </TemporaryDrawer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CreateBrandForm;
