import React, {useState} from "react";
import {Stack, Typography, useMediaQuery, useTheme, Box, Grid, InputAdornment, FormControlLabel, Checkbox} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {enforceFormat, formatToPhone} from "utils/helperFunctions/helpers";
import * as yup from "yup";
import {ErrorMessage, Formik, Form} from "formik";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import useTempDrawer from "hooks/useTempDrawer";
import {API, NetworkManager} from "network/core";
import {dispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import Unchecked from "assets/images/icons/unchecked.svg";

const defaultInitialValues = {
    delivery_address: "",
    location_name: "",
    event_name: "",
    delivery_contact_name: "",
    contact_name: "",
    is_default: true,
    contact: "",
    apartment_name: "",
    instruction: ""
};

const AddNewAddressForm = ({setIsAddAddressFormOpen, userID, selectedAddress, findSelectedAddress, buttonType, handleAddressUpdated}) => {
    const {toggleDrawer} = useTempDrawer();
    const [submitEvent, setSubmitEvent] = useState(null);
    const [pincode, setPincode] = useState(selectedAddress?.address_details?.pincode ? selectedAddress?.address_details?.pincode : "");
    const [city, setCity] = useState(selectedAddress?.address_details?.city ? selectedAddress?.address_details?.city : "");
    const [state, setState] = useState(selectedAddress?.address_details?.state ? selectedAddress?.address_details?.state : "");
    const [street, setStreet] = useState(
        selectedAddress?.address_details?.street_line ? selectedAddress?.address_details?.street_line : ""
    );
    const [country, setCountry] = useState(selectedAddress?.address_details?.country ? selectedAddress?.address_details?.country : "");

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up("sm"));

    const initialValues = buttonType === "editAddress" ? findSelectedAddress(selectedAddress) : defaultInitialValues;
    console.log(initialValues, "VALVAL");
    // const {handleClose, Parentformik, orderId} = props;
    // const {headerData} = Parentformik.values;

    const onError = (status, clearSuggestions) => {
        clearSuggestions();
    };
    const handleSubmit = async (val, setSubmitting, e) => {
        toggleDrawer("right", false);
        setSubmitting(true);

        const editPayload = {
            customer_id: userID,
            address_type: "DELIVERY",
            delivery_address: val?.delivery_address,
            location_name: val?.location_name,
            apartment_name: val?.apartment_name || "",
            contact_name: val?.contact_name,
            contact: val?.contact,
            is_default: val?.is_default,
            latitude: val.latitude,
            longitude: val.longitude,
            instruction: val?.instruction
        };

        const addresses = [
            {
                address_type: "DELIVERY",
                delivery_address: val?.delivery_address,
                location_name: val?.location_name,
                apartment_name: val?.apartment_name || "",
                contact_name: val?.contact_name,
                contact: val?.contact,
                is_default: val?.is_default,
                latitude: val.latitude,
                longitude: val.longitude,
                instruction: val?.instruction,
                address_details: {
                    street_line: street,
                    city: city,
                    state: state,
                    pincode: pincode
                }
            }
        ];

        const payload = {
            customer_id: userID,
            addressDetails: addresses
        };

        const instance =
            buttonType === "editAddress"
                ? NetworkManager(API.CUSTOMER.EDITADDRESS(`${selectedAddress}`))
                : NetworkManager(API.ADDRESS.CREATE);

        const response = buttonType === "editAddress" ? await instance.request(editPayload) : await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
            if (e) {
                toggleDrawer("right", false)(e);
            }
            handleAddressUpdated();
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }

        setSubmitting(false);
    };
    const stopPropagationForTab = (event) => {
        if (event.key === "Tab") {
            event.stopPropagation();
        }
    };

    const parseAddressComponents = async (addressComponents) => {
        if (addressComponents) {
            let newPincode = "";
            let newCity = "";
            let newState = "";
            let newStreetLine = "";
            let newCountry = "";
            for (let i = 0; i < addressComponents.length; i += 1) {
                const component = addressComponents[i];
                if (component.types.includes("postal_code")) {
                    newPincode = component.long_name;
                } else if (component.types.includes("locality")) {
                    newCity = component.short_name;
                } else if (component.types.includes("administrative_area_level_2")) {
                    newState += component.short_name + " "; // eslint-disable-line prefer-template
                } else if (component.types.includes("administrative_area_level_1")) {
                    newState += component.short_name;
                } else if (component.types.includes("premise")) {
                    newStreetLine += component.short_name + ", "; // eslint-disable-line prefer-template
                } else if (component.types.includes("subpremise")) {
                    newStreetLine += component.short_name + ", "; // eslint-disable-line prefer-template
                } else if (component.types.includes("route")) {
                    newStreetLine += component.short_name;
                } else if (component.types.includes("street_number")) {
                    newStreetLine += component.short_name + " "; // eslint-disable-line prefer-template
                } else if (component.types.includes("point_of_interest")) {
                    newStreetLine += component.short_name + ", "; // eslint-disable-line prefer-template
                } else if (component.types.includes("country")) {
                    newCountry = component.short_name;
                }
            }
            setPincode(newPincode);
            setCity(newCity);
            setState(newState);
            setStreet(newStreetLine);
            setCountry(newCountry);
        }
    };

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={yup.object({
                    contact_name: yup.string().required("*Contact name is required"),
                    contact: yup.string().required("*Contact phone is required").length(14, "Contact phone must contain 10 digits"),
                    location_name: yup.string().required("*Company or location name is required"),
                    delivery_address: yup.string().required("Street address is required").nullable().trim()
                })}
                onSubmit={(val, {setSubmitting}) => handleSubmit(val, setSubmitting, submitEvent)}
            >
                {(formik) => (
                    <Form>
                        <TemporaryDrawer sideDrawerState="addAddressForm">
                            <Stack sx={{height: "100%"}} direction="column" justifyContent="space-between" padding={1}>
                                <Box>
                                    <Typography sx={{fontSize: "1.625rem", fontWeight: 700, color: "#000"}}>
                                        {buttonType === "editAddress" ? "Edit Address" : "Add new address"}
                                    </Typography>
                                    <Grid container gap={2} sx={{marginTop: "28px"}}>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                fullWidth
                                                onKeyDown={stopPropagationForTab}
                                                label="Company or location name"
                                                {...formik.getFieldProps("location_name")}
                                            />
                                            <ErrorMessage
                                                name="location_name"
                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {window.google && (
                                                <PlacesAutocomplete
                                                    value={formik.values.delivery_address}
                                                    onChange={formik.handleChange(`delivery_address`)}
                                                    searchOptions={{
                                                        componentRestrictions: {country: "us"}
                                                    }}
                                                    onSelect={(value, placeId) => {
                                                        const setletlong = async () => {
                                                            const result = await geocodeByAddress(value);
                                                            await parseAddressComponents(result[0]?.address_components);
                                                            const ll = await getLatLng(result[0]);

                                                            const addressWithoutCountry = result[0].formatted_address.replace(
                                                                /,\sUSA$/,
                                                                ""
                                                            );
                                                            formik.setFieldValue(`delivery_address`, addressWithoutCountry);
                                                            formik.setFieldValue(`latitude`, ll.lat);
                                                            formik.setFieldValue(`longitude`, ll.lng);
                                                        };
                                                        setletlong();
                                                    }}
                                                    onError={onError}
                                                >
                                                    {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                                                        <div style={{position: "relative"}}>
                                                            <CustomTextField
                                                                fullWidth
                                                                label="Street address"
                                                                value={formik.values.delivery_address}
                                                                {...getInputProps({
                                                                    placeholder: "",
                                                                    className: "location-search-input",
                                                                    onKeyDown: stopPropagationForTab
                                                                })}
                                                                autoComplete="followmore"
                                                                id="falcon"
                                                                name={`random-name-${Math.random()}`}
                                                            />
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    zIndex: 1000,
                                                                    width: "100%",
                                                                    boxShadow: "0px 0px 5px rgba(0,0,0,0.5)"
                                                                }}
                                                            >
                                                                {loading && (
                                                                    <div style={{padding: "6px 12px", backgroundColor: "#ffffff"}}>
                                                                        Loading...
                                                                    </div>
                                                                )}
                                                                {suggestions.map((suggestion, index) => {
                                                                    const style = suggestion.active
                                                                        ? {
                                                                              cursor: "pointer",
                                                                              color: "#191919",
                                                                              fontSize: "16px",
                                                                              padding: "6px 16px",
                                                                              backgroundColor: "#F5F7FB"
                                                                          }
                                                                        : {
                                                                              backgroundColor: "#ffffff",
                                                                              color: "#191919",
                                                                              fontSize: "16px",
                                                                              padding: "6px 16px",
                                                                              cursor: "pointer"
                                                                          };
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                style
                                                                            })}
                                                                        >
                                                                            <span>{suggestion.description}</span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                            )}

                                            <ErrorMessage
                                                name="delivery_address"
                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <CustomTextField
                                                fullWidth
                                                onKeyDown={stopPropagationForTab}
                                                label="Apartment name (optional)"
                                                {...formik.getFieldProps("apartment_name")}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                fullWidth
                                                onKeyDown={stopPropagationForTab}
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                                                }}
                                                inputProps={{maxLength: 50}}
                                                label="Contact name"
                                                {...formik.getFieldProps("contact_name")}
                                            />
                                            <ErrorMessage name="contact_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                fullWidth
                                                label="Contact phone number"
                                                onKeyDown={enforceFormat}
                                                onKeyUp={formatToPhone}
                                                inputProps={{maxLength: 14}}
                                                // {...(formik.values.contact && {
                                                //     InputProps: {
                                                //         startAdornment: <InputAdornment position="start">+1</InputAdornment>
                                                //     }
                                                // })}
                                                {...formik.getFieldProps("contact")}
                                            />
                                            <ErrorMessage name="contact" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                fullWidth
                                                multiline
                                                minRows={2.5}
                                                label="Delivery instructions (optional)"
                                                inputProps={{maxLength: 250}}
                                                // {...(formik.values.phone && {
                                                //     InputProps: {
                                                //         startAdornment: <InputAdornment position="start">+1</InputAdornment>
                                                //     }
                                                // })}
                                                {...formik.getFieldProps("instruction")}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={formik.values.is_default}
                                                        icon={<img src={Unchecked} alt="checked" />}
                                                        onChange={(e) =>
                                                            e.target.checked
                                                                ? formik.setFieldValue("is_default", true)
                                                                : formik.setFieldValue("is_default", false)
                                                        }
                                                    />
                                                }
                                                label={
                                                    <Typography sx={{fontSize: "0.875rem", fontWeight: 600, color: "#25272B"}}>
                                                        Make this default address
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box sx={{position: "sticky", bottom: 0, mt: 2, p: 2, bgcolor: "#fff", zIndex: 1}}>
                                                <ButtonGrouping
                                                    btnprops={[
                                                        {
                                                            btnTitle: "Cancel",
                                                            sx: (t) => ({color: t.palette.error.dark, width: "92px"}),
                                                            onClick: (e) => {
                                                                setIsAddAddressFormOpen(false);
                                                                formik.resetForm();
                                                                toggleDrawer("right", false)(e);
                                                            }
                                                        },
                                                        {
                                                            btnTitle: "Save",
                                                            variant: "contained",
                                                            disabled: !formik?.isValid || formik.isSubmitting,
                                                            onClick: (e) => {
                                                                setSubmitEvent(e);
                                                                formik.handleSubmit();
                                                                formik.validateForm().then((res) => {
                                                                    // to close the sidebar if no field has validation error
                                                                    if (Object.keys(res).length === 0) {
                                                                        setTimeout(() => {
                                                                            formik.resetForm();
                                                                        }, 1500);
                                                                    }
                                                                });
                                                            },
                                                            sx: (t) => ({color: t.palette.background.paper, width: "92px"})
                                                        }
                                                    ]}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>
                        </TemporaryDrawer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddNewAddressForm;
