import CommonDilogBox from "commonComponent/CommonDilogBox/OrderDetailDialogBox";
import OrderDetail from './orderDetail'

const App = ({orderDetailId,handleClose,handleOrderClick}) => {
    return <CommonDilogBox openState="OrderDetail">
        <OrderDetail orderDetailId={orderDetailId} handleClose={handleClose} handleOrderClick={handleOrderClick}/>


    </CommonDilogBox>
}

export default App;